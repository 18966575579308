import { Box } from '@chakra-ui/react';
import { isEbill } from '@melio/ap-domain';
import { Group, Icon, IconButton, Text, Tooltip, useBreakpoint } from '@melio/penny';
import { BillSubscriptionIntervalTypeEnum, BillSubscriptionManagedBy, Payment } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

import { isPartialPayment } from '../../../utils/pay-flow/Payment.utils';

export const usePaymentAmountDetails = (payment: Payment) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const [isAutoPayEnabled] = useDevFeature(FeatureFlags.AutoPay, false);
  const { isExtraSmallScreen } = useBreakpoint();

  const getRecurringPaymentDescriptionLabel = (
    intervalType: BillSubscriptionIntervalTypeEnum,
    occurrenceNumber: number,
    managedBy: BillSubscriptionManagedBy | null,
    numOfOccurrences?: number | null
  ) =>
    managedBy !== BillSubscriptionManagedBy.Fiserv && numOfOccurrences
      ? formatMessage(`activities.payDashboard.paymentsTab.table.cells.amount.recurringPaymentText.${intervalType}`, {
          numOfOccurrences,
          occurrenceNumber,
        })
      : formatMessage(
          `activities.payDashboard.paymentsTab.table.cells.amount.recurringPaymentText.noEndDate.${intervalType}`
        );

  if (isAutoPayEnabled && payment.isAutoPayment) {
    return (
      <Group spacing="xs" alignItems="center">
        <Icon type="repeat" size="small" isReadOnly />
        <Text textStyle="body4" color="global.neutral.800">
          {formatMessage('activities.payDashboard.paymentsTab.table.cells.amount.autoPay.label')}
        </Text>
      </Group>
    );
  }

  if (isPartialPayment(payment) && payment.bills?.[0]?.amount) {
    const originalAmountDisclaimer = formatMessage(
      'activities.payDashboard.paymentsTab.table.cells.amount.partialPayment.tooltip.originalAmount',
      { originalAmount: formatCurrency(payment.bills[0].amount, payment.bills[0].currency) }
    );
    const remainingAmountDisclaimer = formatMessage(
      'activities.payDashboard.paymentsTab.table.cells.amount.partialPayment.tooltip.remainingAmount',
      { remainingAmount: formatCurrency(payment.bills[0].balance, payment.bills[0].currency) }
    );

    const [tooltipLabel, tooltipTitle] = !isEbill(payment.bills[0])
      ? [remainingAmountDisclaimer, originalAmountDisclaimer]
      : [originalAmountDisclaimer];
    return (
      <Group
        spacing="xxs"
        alignItems="center"
        data-testid={`payments-tab-row-${payment.id}-amount-cell-partial-payment-description`}
      >
        <Text textStyle="body4" color="global.neutral.800">
          {formatMessage('activities.payDashboard.paymentsTab.table.cells.amount.partialPayment.label')}
        </Text>
        {!isExtraSmallScreen && (
          <Tooltip
            data-testid="partial-payment-tooltip"
            content={
              <>
                <Box as="span" display="inline-flex" textStyle="body4Semi">
                  {tooltipTitle}
                </Box>
                {tooltipLabel}
              </>
            }
          >
            <IconButton
              data-testid="partial-payment-tooltip-icon-button"
              icon="info"
              aria-label="icon-button-with-tooltip"
              variant="naked"
              size="small"
            />
          </Tooltip>
        )}
      </Group>
    );
  }
  if (payment.subscriptionOccurrence?.billSubscription) {
    const {
      subscriptionOccurrence: {
        billSubscription: { intervalType, numOfOccurrences, managedBy },
        occurrenceNumber,
      },
    } = payment;

    const label = getRecurringPaymentDescriptionLabel(intervalType, occurrenceNumber, managedBy, numOfOccurrences);

    return (
      <Group spacing="xs" alignItems="center">
        <Icon type="repeat" size="small" isReadOnly aria-hidden />
        {label && (
          <Text textStyle="body4" color="global.neutral.800">
            {label}
          </Text>
        )}
      </Group>
    );
  }
  return null;
};
