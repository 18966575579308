/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useRecoilValue } from 'recoil';

import { appSelectedActiveFlowSelector } from '@/store/app/app.model';
import { ActiveFlowEnum } from '@/store/app/app.types';
import { useRouter } from './router.hooks';

export const useActiveFlowRoute = () => {
  const { goToPayDashboard, goToVendors, goToSettingsPaymentMethods, goToReceivingMethods } = useRouter();
  const activeFlow = useRecoilValue(appSelectedActiveFlowSelector);

  const navigateToActiveFlow = React.useCallback(
    (searchParams?: string) => {
      switch (activeFlow) {
        case ActiveFlowEnum.payDashboard: {
          goToPayDashboard();
          break;
        }
        case ActiveFlowEnum.settingsPaymentMethods: {
          goToSettingsPaymentMethods(searchParams);
          break;
        }
        case ActiveFlowEnum.receivingMethods: {
          goToReceivingMethods();
          break;
        }
        case ActiveFlowEnum.vendors: {
          goToVendors();
          break;
        }
        default:
          goToPayDashboard();
      }
    },
    [activeFlow],
  );

  return { navigateToActiveFlow };
};
