import { useModelApi, UseModelApiProps } from '@melio/api-client';
import {
  GetSubscriptionsSyncedPaymentsCountResponseData,
  SubscriptionSyncedPaymentsApiClient,
} from '@melio/platform-api-axios-client';

type QueryFn = typeof SubscriptionSyncedPaymentsApiClient.getSubscriptionsSyncedPayments;

export type UseSyncedPaymentsCountProps = UseModelApiProps<QueryFn, GetSubscriptionsSyncedPaymentsCountResponseData>;

export const useSyncedPaymentsCount = (props: UseSyncedPaymentsCountProps = {}) =>
  useModelApi({
    ...props,
    id: 'synced-payments-count',
    queryKey: ['SubscriptionSyncedPaymentsApi'],
    queryFn: () => SubscriptionSyncedPaymentsApiClient.getSubscriptionsSyncedPayments(),
    cacheTime: 0,
  });
