import { useGuestPayorFundingSources } from '@melio/ar-domain';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { PlaidAccountData } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

import { AddPlaidAccountScreen } from './screens';

export type AddPlaidAccountModalActivityProps = {
  onDone: (id: string) => void;
  onLoadToken?: (isLoading: boolean) => void;
  isOpen: boolean;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
};

export const AddPlaidAccountModalActivity = withAnalyticsContext<AddPlaidAccountModalActivityProps>(
  forwardRef(
    ({ onError: _onError, onLoadToken: onLoading, onDone, isOpen, onClose, setAnalyticsProperties, ...props }, ref) => {
      const { track } = useAnalytics();
      const { create: createFundingSource } = useGuestPayorFundingSources({ enabled: false });
      const onError = (error: ARPlatformError) => {
        track('PaymentRequest', 'Status', { StatusType: 'error', ErrorType: 'general-error' });
        onClose();
        _onError?.(error);
      };

      setAnalyticsProperties({
        PageName: 'connect-to-plaid',
        Intent: 'connect-to-plaid',
      });

      return isOpen ? (
        <AddPlaidAccountScreen
          onLoadToken={onLoading}
          onSuccess={(data: PlaidAccountData) =>
            createFundingSource({
              type: 'plaid',
              details: { plaidAccountId: data.accounts[0]?.id as string, plaidToken: data.public_token },
            })
              .then(({ id }) => {
                track('PaymentRequest', 'Status', { StatusType: 'success' });
                onDone(id);
              })
              .catch(onError)
          }
          onClose={onClose}
          onError={onError}
          {...props}
          ref={ref}
        />
      ) : null;
    }
  )
);
AddPlaidAccountModalActivity.displayName = 'AddPlaidAccountModalActivity';
