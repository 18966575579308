import { getStatesByCountry } from '@melio/ap-domain';
import { GoogleAddressInput, InternationalAddress as FormInternationalAddress } from '@melio/form-controls';
import { FieldValues, Form, UseMelioFormResults, useWatch } from '@melio/penny';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import _get from 'lodash/get';
import { ComponentProps, useState } from 'react';
import type { Path } from 'react-hook-form';

import { isFieldReadonly as _isFieldReadonly } from '../../utils';

export type InternationalAddressInputProps<T extends FieldValues = FieldValues> = {
  form: UseMelioFormResults<T>;
  addressLine1FieldName: Path<T>;
  cityFieldName: Path<T>;
  countryCodeFieldName: Path<T>;
  stateFieldName: Path<T>;
  postalCodeFieldName: Path<T>;
  restrictedCountry?: string[] | undefined;
  colSpan?: number;
  label?: MessageKey;
};
export const InternationalAddressInput = ({
  form,
  addressLine1FieldName,
  cityFieldName,
  countryCodeFieldName,
  stateFieldName,
  postalCodeFieldName,
  restrictedCountry,
  colSpan,
  label,
}: InternationalAddressInputProps) => {
  const { formatMessage } = useMelioIntl();
  const [showFullAddress, setShowFullAddress] = useState(!!form.getValues(addressLine1FieldName));
  const countryCode = useWatch({ control: form.control, name: countryCodeFieldName }) as string;

  const getIsStateRequired = (countryCode: string) => !!(countryCode && getStatesByCountry(countryCode)?.length);

  const isStateRequired = getIsStateRequired(countryCode);

  const isFieldReadonly = (fieldKey: string) =>
    _isFieldReadonly({
      form,
      fieldKey,
    });

  const getStates = (countryCode: string) => {
    if (getIsStateRequired(countryCode)) {
      return getStatesByCountry(countryCode);
    }
    return [];
  };

  const setValuesToAddress = (address: FormInternationalAddress) => {
    form.setValue(addressLine1FieldName, address.line1, { shouldDirty: true });
    form.setValue(cityFieldName, address.city.name, { shouldDirty: true });
    form.setValue(postalCodeFieldName, address.postalCode, { shouldDirty: true });
    form.setValue(countryCodeFieldName, address.country.code, { shouldDirty: true });
    const states = getStates(address.country.code);
    if (states?.length) {
      const stateCode = states.find((s) => s.name === address.state.name)?.stateCode || '';
      form.setValue(stateFieldName, stateCode, { shouldDirty: true });
    }
    setShowFullAddress(true);
  };

  const handleAddressChange: ComponentProps<typeof GoogleAddressInput>['onChange'] = ({ target: { value } }) => {
    setValuesToAddress(value as unknown as FormInternationalAddress);
  };

  const onCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setValue(stateFieldName, '');
    form.setValue(countryCodeFieldName, e.target.value);
  };

  const stateDetailsToOptions = () =>
    getStates(countryCode)?.map((option) => ({
      value: option.stateCode,
      label: option.name,
      testId: `state-${option.stateCode}`,
    }));

  const _halfColSpan = colSpan ? colSpan / 2 : 3;
  const _colSpan = colSpan ? colSpan : 6;

  return (
    <>
      <GoogleAddressInput
        restrictedCountry={restrictedCountry}
        {...form.registerField(addressLine1FieldName)}
        isViewMode={isFieldReadonly(addressLine1FieldName)}
        isRequired
        onChange={handleAddressChange}
        colSpan={_colSpan}
        labelProps={{
          label: formatMessage(
            label ||
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.internationalAddressField.line1.label'
          ),
        }}
        placeholder={formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.internationalAddressField.line1.placeholder'
        )}
        error={
          _get(form.formState.errors, addressLine1FieldName)
            ? {
                message: formatMessage(
                  'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.addressField.required'
                ),
              }
            : undefined
        }
      />
      {showFullAddress ? (
        <>
          <Form.TextField
            {...form.registerField(cityFieldName)}
            colSpan={_halfColSpan}
            labelProps={{
              label: formatMessage(
                `activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.internationalAddressField.city.label`
              ),
            }}
            isViewMode={isFieldReadonly(cityFieldName)}
          />
          {isStateRequired && (
            <Form.SelectNew
              colSpan={_halfColSpan}
              options={stateDetailsToOptions() || []}
              labelProps={{
                label: formatMessage(
                  `activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.internationalAddressField.state.label`
                ),
              }}
              {...form.registerField(stateFieldName)}
              isRequired={isStateRequired}
              isViewMode={isFieldReadonly(stateFieldName)}
            />
          )}
          <Form.TextField
            {...form.registerField(postalCodeFieldName)}
            colSpan={_halfColSpan}
            labelProps={{
              label: formatMessage(
                `activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.internationalAddressField.postalCode.label`
              ),
            }}
            isViewMode={isFieldReadonly(postalCodeFieldName)}
          />
          <Form.TextField
            {...form.registerField(countryCodeFieldName)}
            onChange={onCountryChange}
            colSpan={isStateRequired ? _halfColSpan : _colSpan}
            labelProps={{
              label: formatMessage(
                `activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.internationalAddressField.countryCode.label`
              ),
            }}
            isViewMode={isFieldReadonly(countryCodeFieldName)}
          />
        </>
      ) : null}
    </>
  );
};
