import { SectionBanner } from '@melio/penny';
import { useConfig, useMelioIntl } from '@melio/platform-provider';

import { SubscriptionOrganizationFailureState } from '../../../api';

export const SubscriptionSelectFundingSourceFeeFailedBanner = ({
  failureState,
}: {
  failureState: SubscriptionOrganizationFailureState;
}) => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      vex: { zdSupportFormUrl },
    },
  } = useConfig();

  return (
    <SectionBanner
      variant="critical"
      title={formatMessage(`activities.subscriptionPaymentMethod.paymentFeeFailedBanner.${failureState.reason}.title`)}
      description={formatMessage(
        `activities.subscriptionPaymentMethod.paymentFeeFailedBanner.${failureState.reason}.description`
      )}
      {...(failureState.reason === 'TechnicalError' && {
        label: formatMessage(
          'activities.subscriptionPaymentMethod.paymentFeeFailedBanner.TechnicalError.contactSupport'
        ),
        href: zdSupportFormUrl,
      })}
      data-testid="subscription-select-funding-source-failure-state-banner"
    />
  );
};
