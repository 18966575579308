/* eslint-disable max-lines */
import { useRecurringEndDateRestrictionYears } from '@melio/ap-domain';
import { Currency, CurrencyAmountLimits, getCurrencyAmountLimits } from '@melio/international-payments-utils';
import { Control, UseMelioFormResults, useWatch } from '@melio/penny';
import {
  AccountingPlatform,
  AccountingPlatformSlug,
  BillLineItemLabel,
  BillSubscriptionIntervalTypeEnum,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { addBusinessDays, isBusinessDay, useBoolean, useDateUtils, useUpdateEffect } from '@melio/platform-utils';
import { useSubscriptionFeature } from '@melio/subscriptions';
import Big from 'big.js';
import { isAfter, isSameDay, isToday } from 'date-fns';
import { isNil } from 'lodash';
import { useCallback, useEffect } from 'react';
import { FieldArray, UseFieldArrayUpdate } from 'react-hook-form';

import {
  AddBillV2DFormInitialValues,
  AddBillV2FormFrequency,
  AddBillV2FormLineItem,
  AddBillV2FormValues,
  AddBillV2FormValuesResult,
  BaseLineItem,
  BillLineItemType,
  CategoryBasedBillLineItem,
  FormatFormValuesProps,
  ItemBasedBillLineItem,
  XeroSyncedBillLineItem,
} from './types';

export const MAX_ALLOWED_OCCURRENCES: { [key in AddBillV2FormFrequency]: number } = {
  [AddBillV2FormFrequency.WEEKLY]: 260,
  [AddBillV2FormFrequency.MONTHLY]: 60,
  [AddBillV2FormFrequency.ONE_TIME]: -1, // Not supported
  [AddBillV2FormFrequency.YEARLY]: 5,
  [AddBillV2FormFrequency.EVERY_2_WEEKS]: 130,
  [AddBillV2FormFrequency.EVERY_4_WEEKS]: 65,
  [AddBillV2FormFrequency.TWICE_A_MONTH]: 120,
  [AddBillV2FormFrequency.EVERY_2_MONTHS]: 30,
  [AddBillV2FormFrequency.EVERY_3_MONTHS]: 20,
  [AddBillV2FormFrequency.EVERY_4_MONTHS]: 15,
  [AddBillV2FormFrequency.EVERY_6_MONTHS]: 10,
};

export const createEmptyUnsyncedLineItem = (overrides?: Partial<BaseLineItem>): BaseLineItem => ({
  amount: '',
  description: '',
  order: 0,
  ...overrides,
  type: BillLineItemType.CATEGORY,
});

export const createEmptyXeroSyncedLineItem = (overrides?: Partial<XeroSyncedBillLineItem>): XeroSyncedBillLineItem => ({
  amount: '',
  externalItemId: null,
  externalCategoryId: null,
  quantity: '1',
  unitPrice: '',
  description: '',
  order: 0,
  ...overrides,
  type: BillLineItemType.CATEGORY,
});

export const createEmptyCategoryLineItem = (
  overrides?: Partial<CategoryBasedBillLineItem>
): CategoryBasedBillLineItem => ({
  amount: '',
  description: '',
  externalCategoryId: '',
  externalLabelId: '',
  order: 0,
  ...overrides,
  type: BillLineItemType.CATEGORY,
});

export const createEmptyItemBasedLineItem = (overrides?: Partial<ItemBasedBillLineItem>): ItemBasedBillLineItem => ({
  amount: '',
  externalItemId: '',
  quantity: '1',
  unitPrice: '',
  description: '',
  externalLabelId: '',
  order: 0,
  ...overrides,
  type: BillLineItemType.ITEM,
});

const createEmptyBillLineItemLabel = (overrides?: Partial<BillLineItemLabel>): BillLineItemLabel => ({
  externalListId: '',
  externalLabelId: '',
  order: 0,
  ...overrides,
});

const combineLineItemLabels = (
  billLineItemLabelsIds?: string[],
  lineItem?: CategoryBasedBillLineItem | ItemBasedBillLineItem
): BillLineItemLabel[] => {
  if (!lineItem?.externalLabelId) {
    return [];
  }

  return billLineItemLabelsIds
    ? billLineItemLabelsIds.map((labelId, index) =>
        createEmptyBillLineItemLabel({
          externalListId: labelId,
          externalLabelId: lineItem?.externalLabelId,
          order: index + 1,
        })
      )
    : [];
};

type CombineLineItemsProps = {
  categoryBasedLineItems?: CategoryBasedBillLineItem[];
  itemBasedLineItems?: ItemBasedBillLineItem[];
  xeroSyncedLineItems?: XeroSyncedBillLineItem[];
  nonSyncedLineItems?: BaseLineItem[];
  billLineItemLabelsIds?: string[];
};

export const combineLineItems = ({
  categoryBasedLineItems,
  itemBasedLineItems,
  xeroSyncedLineItems,
  nonSyncedLineItems,
  billLineItemLabelsIds,
}: CombineLineItemsProps): AddBillV2FormLineItem[] => {
  const combinedLineItems: AddBillV2FormLineItem[] = [];
  categoryBasedLineItems?.forEach((lineItem) => {
    combinedLineItems.push({
      ...lineItem,
      description: lineItem.description,
      externalCategoryId: lineItem.externalCategoryId || null,
      type: BillLineItemType.CATEGORY,
      order: combinedLineItems.length + 1,
      labels: combineLineItemLabels(billLineItemLabelsIds, lineItem),
    });
  });

  xeroSyncedLineItems?.forEach((lineItem) => {
    combinedLineItems.push({
      ...lineItem,
      externalCategoryId: lineItem.externalCategoryId || null,
      externalItemId: lineItem.externalItemId || null,
      type: lineItem.externalItemId ? BillLineItemType.ITEM : BillLineItemType.CATEGORY,
      order: combinedLineItems.length + 1,
    });
  });

  itemBasedLineItems?.forEach((lineItem) => {
    combinedLineItems.push({
      ...lineItem,
      type: BillLineItemType.ITEM,
      order: combinedLineItems.length + 1,
      labels: combineLineItemLabels(billLineItemLabelsIds, lineItem),
    });
  });

  nonSyncedLineItems?.forEach((lineItem) => {
    combinedLineItems.push({
      ...lineItem,
      externalCategoryId: null,
      type: BillLineItemType.CATEGORY,
      order: combinedLineItems.length + 1,
    });
  });

  return combinedLineItems;
};

export const isRecurringFrequency = (frequency?: AddBillV2FormFrequency) =>
  frequency !== AddBillV2FormFrequency.ONE_TIME;

export const getTotalLineItemsLength = ({
  categoryBasedLineItems,
  lineItems,
  xeroSyncedLineItems,
  itemBasedLineItems,
}: {
  categoryBasedLineItems: CategoryBasedBillLineItem[];
  itemBasedLineItems: ItemBasedBillLineItem[];
  xeroSyncedLineItems: XeroSyncedBillLineItem[];
  lineItems: BaseLineItem[];
}) => categoryBasedLineItems.length + itemBasedLineItems.length + xeroSyncedLineItems.length + lineItems.length;

export const hasLineItems = (args: {
  categoryBasedLineItems: CategoryBasedBillLineItem[];
  itemBasedLineItems: ItemBasedBillLineItem[];
  lineItems: BaseLineItem[];
}) => {
  if (args.lineItems.length > 1) {
    return true;
  }

  return args.categoryBasedLineItems.length + args.itemBasedLineItems.length >= 1;
};

export const calculateTotalLineItemsAmount = (args: {
  categoryBasedLineItems: CategoryBasedBillLineItem[];
  itemBasedLineItems: ItemBasedBillLineItem[];
  xeroSyncedLineItems: XeroSyncedBillLineItem[];
  lineItems: BaseLineItem[];
}) => {
  if (
    !args.lineItems.length &&
    !args.categoryBasedLineItems.length &&
    !args.xeroSyncedLineItems.length &&
    !args.itemBasedLineItems.length
  ) {
    return;
  }
  const totalCatLineItemsAmount = calculateTotalAmountLines(args.categoryBasedLineItems);
  const totalItemLineItemsAmount = calculateTotalAmountLines(args.itemBasedLineItems);
  const totalXeroLineItemsAmount = calculateTotalAmountLines(args.xeroSyncedLineItems);
  const totalUnsyncedLineItemsAmount = calculateTotalAmountLines(args.lineItems);
  if (
    !totalCatLineItemsAmount &&
    !totalXeroLineItemsAmount &&
    !totalItemLineItemsAmount &&
    !totalUnsyncedLineItemsAmount
  ) {
    return;
  }

  const finalAmount =
    (totalCatLineItemsAmount || 0) +
    (totalItemLineItemsAmount || 0) +
    (totalXeroLineItemsAmount || 0) +
    (totalUnsyncedLineItemsAmount || 0);

  return finalAmount / 100;
};

export const calculateTotalLineItemsCount = (args: {
  values: AddBillV2FormValues;
}): {
  total: number;
  item: number;
  nonsynced: number;
  category: number;
  sync: number;
} => {
  const { values } = args;
  const itemBasedLineItemsLength = values.itemBasedLineItems?.length ?? 0;
  const nonSyncedLineItemsLength = values.nonSyncedLineItems?.length ?? 0;
  const categoryBasedLineItemsLength = values.categoryBasedLineItems?.length ?? 0;
  const xeroLineItemsLength = values.xeroSyncedLineItems?.length ?? 0;
  const totalLineItems =
    itemBasedLineItemsLength + nonSyncedLineItemsLength + categoryBasedLineItemsLength + xeroLineItemsLength;

  return {
    total: totalLineItems,
    item: itemBasedLineItemsLength,
    nonsynced: nonSyncedLineItemsLength,
    category: categoryBasedLineItemsLength,
    sync: xeroLineItemsLength,
  };
};

const calculateTotalAmountLines = (
  lineItems: CategoryBasedBillLineItem[] | ItemBasedBillLineItem[] | BaseLineItem[] | XeroSyncedBillLineItem[]
) => {
  let totalForLines: number | undefined;
  lineItems.forEach((currentLine: AddBillV2FormLineItem) => {
    const currentAmount = Math.round(parseFloat(currentLine?.amount ?? '') * 100);
    if (!Number.isNaN(currentAmount)) {
      totalForLines = (totalForLines || 0) + currentAmount;
    }
  });
  return totalForLines;
};

export const formFrequencyToIntervalType = (
  frequency?: AddBillV2FormFrequency
): BillSubscriptionIntervalTypeEnum | undefined => {
  if (frequency === AddBillV2FormFrequency.WEEKLY) {
    return BillSubscriptionIntervalTypeEnum.Weekly;
  }
  if (frequency === AddBillV2FormFrequency.MONTHLY) {
    return BillSubscriptionIntervalTypeEnum.Monthly;
  }
  if (frequency === AddBillV2FormFrequency.YEARLY) {
    return BillSubscriptionIntervalTypeEnum.Yearly;
  }
  if (frequency === AddBillV2FormFrequency.EVERY_2_WEEKS) {
    return BillSubscriptionIntervalTypeEnum.Every2Weeks;
  }
  if (frequency === AddBillV2FormFrequency.EVERY_4_WEEKS) {
    return BillSubscriptionIntervalTypeEnum.Every4Weeks;
  }
  if (frequency === AddBillV2FormFrequency.TWICE_A_MONTH) {
    return BillSubscriptionIntervalTypeEnum.TwiceAMonth;
  }
  if (frequency === AddBillV2FormFrequency.EVERY_2_MONTHS) {
    return BillSubscriptionIntervalTypeEnum.Every2Months;
  }
  if (frequency === AddBillV2FormFrequency.EVERY_3_MONTHS) {
    return BillSubscriptionIntervalTypeEnum.Every3Months;
  }
  if (frequency === AddBillV2FormFrequency.EVERY_4_MONTHS) {
    return BillSubscriptionIntervalTypeEnum.Every4Months;
  }
  if (frequency === AddBillV2FormFrequency.EVERY_6_MONTHS) {
    return BillSubscriptionIntervalTypeEnum.Every6Months;
  }

  return undefined;
};

export const getBillPaidAmount = (bill: Partial<AddBillV2DFormInitialValues>) =>
  !isNil(bill.balance) && !isNil(bill.amount) ? new Big(bill.amount).minus(bill.balance).toNumber() : 0;

export const getNewUnitPrice = (
  quantity: string,
  amount: string
): { quantityToUpdate?: string; unitPriceToUpdate?: string } => {
  const amountPriceForCalc = parseFloat(amount || '0');
  const qtyForCalc = parseFloat(quantity);
  if (qtyForCalc === 0) {
    return { quantityToUpdate: '1', unitPriceToUpdate: amountPriceForCalc as unknown as string };
  }
  const unitPriceCalculated = parseFloat((amountPriceForCalc / qtyForCalc).toFixed(2));
  if (!isNaN(unitPriceCalculated)) {
    return { unitPriceToUpdate: unitPriceCalculated as unknown as string };
  }
  return {};
};

export const getNewAmount = (quantity: string, unitPrice: string): string | undefined => {
  const totalAmount = parseFloat((parseFloat(unitPrice) * parseFloat(quantity)).toFixed(2));
  if (!isNaN(totalAmount)) {
    return totalAmount as unknown as string;
  }
  return;
};

export const getNewQuantity = (amount: string, unitPrice: string): string | undefined => {
  const quantityToUpdate = parseFloat((parseFloat(amount || '0') / parseFloat(unitPrice)).toFixed(2));
  if (!isNaN(quantityToUpdate)) {
    return quantityToUpdate as unknown as string;
  }
  return;
};

export const scrollToFirstError = (selector: string): boolean => {
  const errorElements: NodeListOf<HTMLElement> = document.querySelectorAll(selector);
  if (errorElements[0]) {
    const firstElementError = errorElements[0];
    firstElementError.scrollIntoView({ behavior: 'smooth', block: 'center' });
    firstElementError.focus({ preventScroll: true });
  }

  return !!errorElements[0];
};

export const formatFormValues = ({
  values,
  createDate,
  isAdvancedBillFlow,
  billLineItemLabels,
  activeAccountingPlatform,
}: FormatFormValuesProps): AddBillV2FormValuesResult => {
  let combinedLineItems;
  const billLineItemLabelsIds = billLineItemLabels?.map((label) => label.id) || [];
  const isXeroAccountingSoftwareConnected = activeAccountingPlatform?.accountingSlug === AccountingPlatformSlug.Xero;

  if (isAdvancedBillFlow) {
    combinedLineItems = combineLineItems({
      xeroSyncedLineItems: values?.xeroSyncedLineItems,
      itemBasedLineItems: values?.itemBasedLineItems,
      nonSyncedLineItems: values?.nonSyncedLineItems,
      categoryBasedLineItems: values?.categoryBasedLineItems,
      billLineItemLabelsIds,
    });
  } else {
    combinedLineItems = [
      isXeroAccountingSoftwareConnected
        ? createEmptyXeroSyncedLineItem({
            amount: values?.amount || '',
            order: 1,
            quantity: '1',
            unitPrice: values?.amount || '',
            externalCategoryId: values?.categoryId || '',
          })
        : createEmptyCategoryLineItem({
            amount: values?.amount || '',
            order: 1,
            externalCategoryId: values?.categoryId || '',
          }),
    ];
  }

  const isRecurring = isRecurringFrequency(values.frequency);

  const {
    itemBasedLineItems,
    nonSyncedLineItems,
    categoryBasedLineItems,
    xeroSyncedLineItems,
    dueDate,
    invoiceNumber,
    noteToSelf,
    externalLabelId,
    ...restValues
  } = values;

  const dueDateValue = dueDate ?? createDate();

  return {
    ...restValues,
    dueDate: isRecurring ? undefined : dueDateValue,
    invoiceNumber: invoiceNumber ?? '',
    noteToSelf: noteToSelf ?? '',
    lineItems: combinedLineItems,
    frequency: values.frequency,
    recurringEndBy: isRecurring ? values.recurringEndBy : null,
    recurringOccurrences: isRecurring ? values.recurringOccurrences : null,
    recurringEndDate: isRecurring ? values.recurringEndDate : null,
    recurringStartDate: isRecurring ? values.recurringStartDate : null,
    externalLabelId: isRecurring ? undefined : externalLabelId,
  };
};

export const useGetLabelForItemsPickerByAccountingSoftware = ({
  activeAccountingPlatform,
  index,
}: {
  activeAccountingPlatform?: AccountingPlatform;
  index: number;
}): { ariaLabel: string; label: string } => {
  const { formatMessage } = useMelioIntl();
  const defaultLabel = formatMessage('activities.addBillV2.lineItems.itemBased.itemPicker.placeholder');
  const defaultAriaLabelLabel = formatMessage('activities.addBillV2.lineItems.itemBased.itemPicker.ariaLabel', {
    index,
  });

  const quickbookSlugs: AccountingPlatformSlug[] = [
    AccountingPlatformSlug.QuickBooksDesktop,
    AccountingPlatformSlug.QuickBooksDesktopInApp,
    AccountingPlatformSlug.QuickBooksOnline,
  ];

  const label =
    activeAccountingPlatform && quickbookSlugs.includes(activeAccountingPlatform.accountingSlug)
      ? formatMessage('activities.addBillV2.lineItems.itemBased.itemPicker.placeholder.quickbooks')
      : defaultLabel;

  const ariaLabel =
    activeAccountingPlatform && quickbookSlugs.includes(activeAccountingPlatform.accountingSlug)
      ? formatMessage('activities.addBillV2.lineItems.itemBased.itemPicker.ariaLabel.quickbooks', { index })
      : defaultAriaLabelLabel;

  return {
    label,
    ariaLabel,
  };
};

export const useValidateRecurringStartDate = () => {
  const { createDate } = useDateUtils();

  const isRecurringStartDateValid = (startDate?: Date | null) => {
    if (!startDate) {
      return false;
    }

    if (!isBusinessDay(startDate)) {
      return false;
    }

    return isToday(startDate) || isAfter(startDate, createDate());
  };

  return { isRecurringStartDateValid };
};

export const useValidateRecurringEndDate = () => {
  const { createDate } = useDateUtils();
  const { getRecurringEndDateRestrictionYears } = useRecurringEndDateRestrictionYears();
  const isRecurringEndDateValid = (endDate?: Date | null, startDate?: Date | null) => {
    if (!endDate) {
      return false;
    }

    if (!isBusinessDay(endDate)) {
      return false;
    }

    if (!startDate) {
      return isToday(endDate) || isAfter(endDate, createDate());
    }

    const minDate = addBusinessDays(startDate, 1);
    const recurringEndDateRestrictionYears = getRecurringEndDateRestrictionYears(startDate);
    return (
      (isSameDay(minDate, endDate) || isAfter(endDate, minDate)) &&
      recurringEndDateRestrictionYears &&
      endDate <= recurringEndDateRestrictionYears
    );
  };

  return { isRecurringEndDateValid };
};

type LineItemFieldName = Extract<
  keyof AddBillV2FormValues,
  'categoryBasedLineItems' | 'itemBasedLineItems' | 'xeroSyncedLineItems'
>;

type UseClassesPaywallProps<T extends LineItemFieldName> = {
  formControl: Control<AddBillV2FormValues>;
  fieldName: T;
  index: number;
  initialExternalLabelId?: string | null;
  updateField: UseFieldArrayUpdate<AddBillV2FormValues, T>;
};

export const useLineItemClassesPaywall = <T extends LineItemFieldName>({
  formControl,
  fieldName,
  index,
  initialExternalLabelId = null,
  updateField,
}: UseClassesPaywallProps<T>) => {
  const [fields, externalLabelId] = useWatch({
    control: formControl,
    name: [fieldName, `${fieldName}.${index}.externalLabelId`],
  });

  const { tryUseFeature } = useSubscriptionFeature({ featureName: 'classesAndLocations' });
  useUpdateEffect(() => {
    if (externalLabelId && externalLabelId != initialExternalLabelId) {
      tryUseFeature({
        onFeatureIsBlocked: () => {
          const current = fields?.[index] as FieldArray<AddBillV2FormValues, T> | undefined;
          if (current) {
            updateField(index, { ...current, unitPrice: 1, externalLabelId: initialExternalLabelId || null });
          }
        },
      });
    }
  }, [externalLabelId]);
};

export const useLocationsPaywall = (
  { control, resetField }: UseMelioFormResults<AddBillV2FormValues>,
  defaultValues: AddBillV2FormValues
) => {
  const { tryUseFeature } = useSubscriptionFeature({ featureName: 'classesAndLocations' });
  const fieldName: keyof AddBillV2FormValues = 'externalLabelId';
  const defaultValue = defaultValues?.[fieldName];
  const value = useWatch({ control, name: fieldName });
  useUpdateEffect(() => {
    if (value && value !== defaultValue) {
      tryUseFeature({
        onFeatureIsBlocked: () => resetField(fieldName),
      });
    }
  }, [value]);
};

export const useLineItemsController = (form: UseMelioFormResults<AddBillV2FormValues>) => {
  const getHasLineItems = useGetHasLineItems(form.control);
  const hasLineItems = getHasLineItems();
  const { isEligible } = useSubscriptionFeature({ featureName: 'lineItems' });
  const [isExpanded, expanded] = useBoolean();

  useEffect(() => {
    hasLineItems && expanded.on();
  }, [hasLineItems, expanded]);

  return {
    isExpanded: isEligible && isExpanded,
    isEligible,
    toggleExpanded: expanded.on,
  };
};

type FilterLineItemBy = (
  lineItem: CategoryBasedBillLineItem | ItemBasedBillLineItem | XeroSyncedBillLineItem | BaseLineItem
) => boolean;

const isValidLineItem: FilterLineItemBy = (item) => !!(item?.amount || item?.description);

export const useGetHasLineItems = (control: UseMelioFormResults<AddBillV2FormValues>['control']) => {
  const listItemFieldValues = useWatch({
    control,
    name: ['categoryBasedLineItems', 'itemBasedLineItems', 'xeroSyncedLineItems', 'nonSyncedLineItems'],
  });
  return useCallback(
    (filter: FilterLineItemBy = isValidLineItem) => listItemFieldValues.filter(Boolean).some((li) => li?.some(filter)),
    [listItemFieldValues]
  );
};

export const getValidFxAmountLimitForQuery = (currency?: string, amount?: string) => {
  let limits: CurrencyAmountLimits | null;

  try {
    limits = getCurrencyAmountLimits(currency as Currency);
  } catch (error) {
    return amount;
  }

  if (!limits || !amount) {
    return amount;
  }

  const parsedAmount = parseFloat(amount);

  if (isNaN(parsedAmount)) {
    return amount;
  }

  const { minimumAmount, maximumAmount } = limits;

  if (parsedAmount > maximumAmount) {
    return maximumAmount.toString();
  }

  if (parsedAmount < minimumAmount) {
    return minimumAmount.toString();
  }

  return amount;
};
