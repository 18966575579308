import { Icon, NakedButton, SectionBanner } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useContext } from 'react';

import { useMelioIntl } from '../i18n';
import { useIsMobile } from '../useIsMobile';
import { UserActivationContext } from './UserActivationContext';

type TestModeMessageProps = {
  screen: 'dashboard' | 'edit-create-share';
};

export const TestModeMessage = forwardRef<TestModeMessageProps>(({ screen }, ref) => {
  const { isTestMode, onActivateAccount } = useContext(UserActivationContext);
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const isMobile = useIsMobile();

  const description =
    screen === 'dashboard'
      ? formatMessage('ar.domain.testModeMessage.screen.dashboard.description')
      : formatMessage('ar.domain.testModeMessage.screen.invoicing.description');

  const handelActivateAccount = () => {
    track(screen === 'dashboard' ? 'Dashboard' : 'Invoice', 'Click', {
      Cta: 'activate',
      Intent: 'activate-ar',
    });
    onActivateAccount();
  };
  if (!isTestMode) {
    return null;
  }

  return (
    <SectionBanner
      ref={ref}
      data-testid="test-mode-banner"
      description={description}
      variant="warning"
      isCompact={!isMobile}
      leftElement={screen === 'dashboard' ? <Icon type="notification" size="small" /> : undefined}
      action={
        <NakedButton
          onClick={handelActivateAccount}
          variant="secondary"
          label={formatMessage('ar.domain.testModeMessage.action.label')}
          data-testid="activate-account-button"
        />
      }
    />
  );
});
