import { Box } from '@chakra-ui/react';
import { NakedButton } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { TopNavLayoutTheme } from '@/cl/layouts/app-layout/topnav.layout.theme';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { useUserSignOut } from '@/hooks/useUserSignOut';

export const TopNavSignOutButton = () => {
  const styles = useAppTheme(TopNavLayoutTheme, {});
  const { signOut } = useUserSignOut();
  const { formatMessage } = useMelioIntl();

  return (
    <Box sx={styles['cta']}>
      <NakedButton
        onClick={signOut}
        data-testid="top-nav-sign-out-button"
        variant="secondary"
        label={formatMessage('app.topnav.signout')}
      />
    </Box>
  );
};
