import { PublicPayorApiClient } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

export type UseUpdatedPaymentRequestLinkProps = UseModelProps<typeof PublicPayorApiClient.getUpdatedPaymentRequestLink>;

export const useUpdatedPaymentRequestLink = (props?: UseUpdatedPaymentRequestLinkProps) =>
  useModel({
    queryKey: ['PublicPayorApi', 'getUpdatedPaymentRequestLink'],
    queryFn: PublicPayorApiClient.getUpdatedPaymentRequestLink,
    ...props,
  });

export type UpdatedPaymentRequestLinkModel = ReturnType<typeof useUpdatedPaymentRequestLink>;
