import { Link } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { PayDashboardEmptyState } from '../../PayDashboardEmptyState';

export const VendorGroupEmptyState = ({
  onClearFilter,
  onAddVendorsToGroupClick,
}: {
  onClearFilter: VoidFunction;
  onAddVendorsToGroupClick: VoidFunction;
}) => {
  const { formatMessage } = useMelioIntl();

  const handleClearFilter = (e: React.MouseEvent<HTMLLinkElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClearFilter();
  };

  return (
    <PayDashboardEmptyState
      dataTestId="vendors-tab-vendor-groups-empty-state"
      title={formatMessage('activities.payDashboard.vendorsTab.vendorGroup.emptyState.title')}
      description={formatMessage('activities.payDashboard.vendorsTab.vendorGroup.emptyState.description')}
      illustrationSrc="vendor-add"
      buttonProps={[
        {
          label: formatMessage('activities.payDashboard.vendorsTab.vendorGroup.emptyState.button'),
          onClick: onAddVendorsToGroupClick,
          'data-testid': 'vendors-tab-vendor-group-empty-state-primary-button',
        },
      ]}
      notice={formatMessage('activities.payDashboard.vendorsTab.vendorGroup.emptyState.notice', {
        clearFilter: (
          <Link
            data-testid="vendors-tab-vendor-group-empty-state-clear-filter-link"
            href="#"
            color="secondary"
            variant="standalone"
            label={formatMessage('activities.payDashboard.vendorsTab.vendorGroup.emptyState.notice.button')}
            onClick={handleClearFilter}
          />
        ),
      })}
    />
  );
};
