import { Box } from '@chakra-ui/react';
import { useAnalyticsView } from '@melio/platform-analytics';
import { PlaidAccountData } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

import { AddPlaidAccount } from '../components';

export type AddPlaidAccountScreenProps = {
  onClose: VoidFunction;
  onError: ARErrorFunction;
  onSuccess: (data: PlaidAccountData) => void;
  onLoadToken?: (isLoading: boolean) => void;
};

export const AddPlaidAccountScreen = forwardRef<AddPlaidAccountScreenProps>(
  ({ onClose, onError, onSuccess, onLoadToken, ...props }, ref) => {
    useAnalyticsView('PaymentRequest', true, true);

    return (
      <Box
        data-testid="add-plaid-funding-source-screen"
        data-component={AddPlaidAccountScreen.displayName}
        {...props}
        ref={ref}
      >
        <AddPlaidAccount onSuccess={onSuccess} onExit={onClose} onError={onError} onLoadToken={onLoadToken} />
      </Box>
    );
  }
);

AddPlaidAccountScreen.displayName = 'AddPlaidAccountScreen';
