import { VendorGroup } from '@melio/platform-api';

export const ALL_VENDORS_GROUP_ID = 'ALL_VENDORS';

type AllVendorGroupsOption = {
  id: typeof ALL_VENDORS_GROUP_ID;
  name: string;
};

export type VendorGroupFilterOption = AllVendorGroupsOption | VendorGroup;
