import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { abortRefreshAccessToken } from '@/api/apiClients';
import { switchOrganization as switchOrganizationRequest } from '@/api/auth.api';
import { useGetIsSwitchingOrganizationState, useSetSwitchingOrganizationState } from '@/store/app/app.model';
import { PayDashboardTabs } from '@/types/payDashboard.types';
import { getMeliocomOrgUrl } from '@/utils/generateMelioRedirectPath';
import { usePartnerConfig } from './partners/usePartnerConfig';
import { useRouter } from './router.hooks';
import { useResetAccessToken } from './session.hooks';

export const useSwitchOrganization = () => {
  const setAccessToken = useResetAccessToken();
  const { goToPayDashboardTabLinkAndRefresh, generateMelioClientsLink } = useRouter();
  const { partnerConfig } = usePartnerConfig();
  const [isMelioMigrationWhitelistToBlacklistEnabled] = useDevFeature(
    FeatureFlags.MelioMigrationWhitelistToBlacklist,
    false,
  );
  const setIsSwitchingOrganizationState = useSetSwitchingOrganizationState();
  const isSwitchingOrganizationState = useGetIsSwitchingOrganizationState();

  const switchAccessToken = async (organizationId: string) => {
    abortRefreshAccessToken();
    const { accessToken, refreshToken } = await switchOrganizationRequest(organizationId);
    setAccessToken(accessToken, refreshToken!);
    return accessToken;
  };

  const switchOrganization = async ({
    organizationId,
    isAccountingFirm,
    skipPayDashboardRedirect,
    switchAccessTokenOnly,
    loadingState = 'default',
  }: {
    organizationId: string;
    isAccountingFirm: boolean;
    skipPayDashboardRedirect?: boolean;
    switchAccessTokenOnly?: boolean;
    /**
     * Manages loading state to prevent data mismatches or errors.
     * Keep 'default' when you not perform full page reload after switching org and skipPayDashboardRedirect=true
     * Set 'always' when skipPayDashboardRedirect/switchAccessTokenOnly, and you're about performing full reload anyway
     */
    loadingState?: 'default' | 'always' | 'none';
  }) => {
    setIsSwitchingOrganizationState(loadingState !== 'none');
    const withBrowserRedirectHandlerWrapper = (browserRedirectHandler: VoidFunction) => {
      try {
        browserRedirectHandler();
        // keep isSwitching=true while browser handles redirect
      } catch (error) {
        setIsSwitchingOrganizationState(false);
        throw error;
      }
    };

    const accessToken = await switchAccessToken(organizationId);
    if (switchAccessTokenOnly) {
      setIsSwitchingOrganizationState(loadingState === 'always');
      return;
    }

    if (partnerConfig.supportLegacyOrganizations) {
      const url = await getMeliocomOrgUrl({ isMelioMigrationWhitelistToBlacklistEnabled });
      if (url) {
        withBrowserRedirectHandlerWrapper(() => window.location.replace(url));
        return;
      }
    }

    if (isAccountingFirm) {
      withBrowserRedirectHandlerWrapper(() => (location.href = generateMelioClientsLink(accessToken, true)!));
      return;
    }

    if (skipPayDashboardRedirect) {
      setIsSwitchingOrganizationState(loadingState === 'always');
      return;
    }

    withBrowserRedirectHandlerWrapper(() => goToPayDashboardTabLinkAndRefresh(PayDashboardTabs.Inbox));
  };

  return {
    isSwitching: isSwitchingOrganizationState,
    switchOrganization,
  };
};
