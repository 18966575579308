import { Box } from '@chakra-ui/react';
import {
  Container,
  Grid,
  Group,
  LargeFileUpload,
  SplitScreen,
  Text,
  useBreakpoint,
  useFormSubmissionController,
} from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useFile } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { PageTitle, SystemMessageDisplay } from '@melio/platform-utils';
import { useEffect } from 'react';

import { useUploadBillFile } from '../../../../utils/useUploadBillFile';
import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { EditableData, ExpandedBillSubscription } from '../../types';
import { EditBillSubscriptionForm } from './components/EditBillSubscriptionForm';
import { FeeSection } from './components/FeeSection/FeeSection';
import { EditBillSubscriptionFormFields } from './components/types';

type EditBillSubscriptionScreenProps = {
  formState: EditableData;
  isSaving?: boolean;
  isLoading?: boolean;
  onClose: VoidFunction;
  onDone: (data: EditBillSubscriptionFormFields) => void;
  onError?: ErrorFunction;
  onChangeField: (changes: EditableData) => void;
  onCreateFundingSource: VoidFunction;
  onCreateDeliveryMethod: VoidFunction;
} & Pick<ExpandedBillSubscription, 'nextOccurrence' | 'managedBy' | 'vendor' | 'id' | 'invoiceNumber'>;

export const EditBillSubscriptionScreen = withAnalyticsContext<EditBillSubscriptionScreenProps>(
  ({
    onCreateFundingSource,
    onCreateDeliveryMethod,
    formState,
    onChangeField,
    nextOccurrence,
    managedBy: billSubscriptionManagedBy,
    id: billSubscriptionId,
    invoiceNumber: billSubscriptionInvoiceNumber,
    vendor,
    onError,
    setAnalyticsProperties,
    isSaving,
    isLoading,
    onClose,
    onDone,
    ...props
  }) => {
    const { formatMessage } = useMelioIntl();

    const { onSubmissionStateChange, submitButtonProps, handleSubmit, getValues } =
      useFormSubmissionController<EditBillSubscriptionFormFields>();
    const { isExtraSmallScreen: isMobile } = useBreakpoint();

    const { data: initialFileInfo, isLoading: isLoadingInitialFile } = useFile({
      id: formState.fileId || undefined,
      enabled: !!formState.fileId,
    });

    const {
      setFile,
      isLoading: isFileLoading,
      fileValue,
      fileInfo,
    } = useUploadBillFile({
      initialFileInfo,
      onUploadFinished: (fileInfo) => {
        onChangeField({ fileId: fileInfo ? fileInfo.id : undefined });
      },
    });

    const uneditablePaymentsNumber = nextOccurrence && nextOccurrence.occurrenceNumber - 1;

    setAnalyticsProperties({
      PageName: 'edit-recurring-payments',
    });

    const { track } = useAnalytics();

    useEffect(() => {
      track('Bill', 'View');
    }, [track]);

    const trackSubmit = (button: 'save-changes' | 'exit') => {
      track('Bill', 'Click', {
        IsRecurring: true,
        Cta: button,
        PaymentFrequency: getValues?.('intervalType'),
        RecurringPaymentEndBy: getValues?.('endPolicy'),
        RecurringPaymentEndDate: getValues?.('endDate')?.toISOString(),
        RecurringPaymentStartDate: getValues?.('startDate')?.toISOString(),
        RecurringPaymentLastAmount: getValues?.('lastAmount'),
      });
    };

    const onSubmit = () => {
      trackSubmit('save-changes');
      handleSubmit?.()();
    };

    const shouldDisableEditFile = !!billSubscriptionManagedBy;

    // TODO: create a layout to use for all "add bill" flows. they share the same look
    return (
      <SplitScreen
        data-component="EditBillSubscriptionActivity.EditBillSubscriptionScreen"
        data-testid="edit-bill-subscription-activity-edit-bill-subscription-screen"
        isLoading={isLoading}
        variant="1:2"
        {...props}
        header={
          <NewSinglePaymentStepLayout.Header backgroundColor={{ xs: 'global.neutral.200', l: 'global.neutral.100' }}>
            <NewSinglePaymentStepLayout.CloseButton
              onClick={() => {
                trackSubmit('exit');
                onClose();
              }}
            />
          </NewSinglePaymentStepLayout.Header>
        }
        panelA={{
          maxWidth: 34,
          padding: { xs: 'xxl', m: 'xl' },
          content: (
            <LargeFileUpload
              data-testid="bill-subscription-file-upload"
              value={fileValue}
              onChange={setFile}
              isLoading={isLoadingInitialFile || isFileLoading}
              isReadOnly={shouldDisableEditFile}
              deleteFileButtonAriaLabel={formatMessage(
                'activities.editBillSubscription.screens.editBillSubscription.deleteFileButtonAriaLabel'
              )}
              replaceFileButtonAriaLabel={formatMessage(
                'activities.editBillSubscription.screens.editBillSubscription.replaceFileButtonAriaLabel'
              )}
            />
          ),
        }}
        panelB={{
          padding: 'none',
          content: (
            <Box backgroundColor="global.neutral.100" py={{ xs: 'xl', m: 'xxl' }} px="xxl" minH="100%">
              <Container paddingBottom="xs">
                <PageTitle textStyle="heading1Semi">
                  {formatMessage('activities.editBillSubscription.screens.editBillSubscription.title')}
                </PageTitle>
              </Container>
              <Container paddingBottom="l">
                <Text as="h2">
                  {formatMessage('activities.editBillSubscription.screens.editBillSubscription.description')}
                </Text>
              </Container>
              <Container paddingBottom="l">
                <SystemMessageDisplay data-testid="edit-bill-subscription-system-message" />
              </Container>
              <NewSinglePaymentStepLayout.Content>
                <Group variant="vertical" spacing="l">
                  <EditBillSubscriptionForm
                    onCreateFundingSource={onCreateFundingSource}
                    onCreateDeliveryMethod={onCreateDeliveryMethod}
                    defaultValues={formState}
                    billSubscriptionInvoiceNumber={billSubscriptionInvoiceNumber}
                    billSubscriptionId={billSubscriptionId}
                    billSubscriptionManagedBy={billSubscriptionManagedBy}
                    onSubmissionStateChange={onSubmissionStateChange}
                    onSubmit={(data) => onDone({ ...data, ...(fileInfo?.id && { fileId: fileInfo.id }) })}
                    isSaving={isSaving}
                    uneditablePaymentsNumber={uneditablePaymentsNumber}
                    vendor={vendor}
                    onChangeField={onChangeField}
                  />
                  {formState.startDate &&
                    formState.fundingSourceId &&
                    formState.deliveryMethodId &&
                    formState.amount && (
                      <Grid gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'} gap="m">
                        <Box gridColumnStart={isMobile ? 1 : 2}>
                          <FeeSection
                            vendorId={vendor.id}
                            onError={onError}
                            startDate={formState.startDate}
                            fundingSourceId={formState.fundingSourceId}
                            deliveryMethodId={formState.deliveryMethodId}
                            amount={formState.amount}
                            lastAmount={formState.lastAmount}
                            invoiceNumber={billSubscriptionInvoiceNumber}
                            billSubscriptionId={billSubscriptionId}
                          />
                        </Box>
                      </Grid>
                    )}
                </Group>
              </NewSinglePaymentStepLayout.Content>
            </Box>
          ),
          footer: (
            <Box
              justifyContent="flex-end"
              width="full"
              py="s"
              paddingX="m"
              backgroundColor="global.neutral.100"
              borderTop="basic.light"
            >
              <Group variant="vertical" alignItems="flex-end">
                <NewSinglePaymentStepLayout.NextButton
                  {...submitButtonProps}
                  onClick={onSubmit}
                  label={formatMessage('activities.editBillSubscription.screens.editBillSubscription.save')}
                />
              </Group>
            </Box>
          ),
        }}
      />
    );
  }
);

EditBillSubscriptionScreen.displayName = 'EditBillSubscriptionActivity.EditBillSubscriptionScreen';
