import { Checkbox, Container, Divider, Group } from '@melio/penny';

import { selectableListDataTestId } from './dataTestid';

export type ListItem = {
  id: string;
  label: string;
  checked: boolean;
};

export type SelectableList = {
  items: ListItem[];
  selectedCount: number;
};

type Props = {
  list: SelectableList;
  onSelect: (newList: SelectableList) => void;
  selectAllLabel?: string;
};

export const SelectableItemList = ({ list, onSelect, selectAllLabel }: Props) => {
  const handleSelect = (index: number) => {
    const newItems = [...list.items];
    const selectedItem = newItems[index];

    if (!selectedItem) {
      return;
    }

    selectedItem.checked = !selectedItem.checked;
    newItems[index] = selectedItem;

    onSelect({ items: newItems, selectedCount: newItems.filter((item) => item.checked).length });
  };

  const handleSelectAll = () => {
    const checked = list.items.length !== list.selectedCount;
    const newItems = list.items.map((item) => ({ ...item, checked }));

    onSelect({ items: newItems, selectedCount: checked ? list.items.length : 0 });
  };

  return (
    <Group
      width="full"
      data-testid={selectableListDataTestId.list}
      variant="vertical"
      justifyContent="center"
      spacing="m"
    >
      <Group variant="vertical" width="full">
        <Group.Item>
          <Container paddingX="l">
            <Checkbox
              data-testid={selectableListDataTestId.selectAll}
              size="small"
              isChecked={list.items.length === list.selectedCount}
              isIndeterminate={list.items.length !== list.selectedCount && list.selectedCount > 0}
              onChange={() => handleSelectAll()}
              label={selectAllLabel}
            />
          </Container>
        </Group.Item>
        <Group.Item>
          <Divider />
        </Group.Item>
      </Group>
      <Group variant="vertical" width="full" spacing="l">
        {list.items.map((item, index) => (
          <Group.Item key={index}>
            <Container paddingX="l">
              <Checkbox
                data-testid={`${selectableListDataTestId.item}${index}`}
                size="small"
                isChecked={item.checked}
                onChange={() => handleSelect(index)}
                label={item.label}
              />
            </Container>
          </Group.Item>
        ))}
      </Group>
    </Group>
  );
};
