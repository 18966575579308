import { withAnalyticsContext } from '@melio/platform-analytics';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useSearchParams } from 'react-router-dom';

import { AddBankAccountMicroDepositsActivity } from '../AddBankAccountMicroDeposits';
import { AddBankAccountPlaidActivity } from '../AddBankAccountPlaid';
import { BankIntegrationSelectionScreen } from './screens';
import { AddBankAccountActivityProps } from './types';
import { BANK_INTEGRATION_TO_STEP, useAddBankAccountActivityStep } from './useAddBankAccountActivityStep';

export const AddBankAccountActivity = withAnalyticsContext<AddBankAccountActivityProps>(
  ({ onBack: onFirstStepBack, onClose, onError, onDone, isReceivingMethodFlow }) => {
    const [isPlaidMicroDepositsGetPaidEnabled] = useDevFeature(FeatureFlags.PlaidMicroDepositsGetPaidEnabled, false, {
      shouldTrack: true,
    });
    const { currentStep, goToStep, goToPreviousStep } = useAddBankAccountActivityStep({
      onFirstStepBack,
      isReceivingMethodFlow,
    });

    //TODO: pass this as a props instead of searchParams - https://meliorisk.atlassian.net/browse/ME-69301
    const [searchParams] = useSearchParams();
    const shouldAllowNonInstantVerification = !(searchParams.get('integrationType') === 'plaid');

    const onCloseACHPlaidModal = () => {
      if (isReceivingMethodFlow && !isPlaidMicroDepositsGetPaidEnabled) {
        goToStep('ADD_ACH_MICRO_DEPOSITS');
      } else {
        onClose();
      }
    };

    switch (currentStep) {
      case 'ADD_ACH_INTEGRATION_SELECTION':
      default:
        return (
          <BankIntegrationSelectionScreen
            onBack={goToPreviousStep}
            onClose={onClose}
            onDone={(integrationType) => {
              goToStep(BANK_INTEGRATION_TO_STEP[integrationType]);
            }}
          />
        );

      case 'ADD_ACH_MICRO_DEPOSITS':
        return (
          <AddBankAccountMicroDepositsActivity
            onBack={goToPreviousStep}
            onClose={onClose}
            onDone={onDone}
            onError={onError}
            isReceivingMethodFlow={isReceivingMethodFlow}
          />
        );

      case 'ADD_ACH_PLAID':
        return (
          <AddBankAccountPlaidActivity
            onClose={onCloseACHPlaidModal}
            onBack={goToPreviousStep}
            onDone={onDone}
            onError={onError}
            options={{ isReceivingMethodFlow, shouldAllowNonInstantVerification }}
          />
        );
    }
  }
);

AddBankAccountActivity.displayName = 'AddBankAccountActivity';
