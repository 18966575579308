import { BillSubscriptionApprovalDecisionStatusEnum, Payment } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';
import { usePartnerFeature } from '@melio/platform-provider';

import { PaymentActions } from './types';

const disabledActions: PaymentActions = {
  cancel: false,
  edit: false,
  delete: false,
  cancelBillSubscription: false,
  isEditBillSubscriptionAllowed: false,
  markAsPaid: false,
  markAsUnpaid: false,
  resolveFailedToCollect: false,
  resolveFailedToDeliver: false,
  resolveRefund: false,
  resolveVoidAndRefund: false,
  resolveVoidAndResend: false,
  approvalDecision: false,
};

export const useFailedPaymentActions = (payment?: Payment) => {
  const [isRobinhoodRefundFlowEnabled] = useDevFeature(FeatureFlags.RobinhoodRefundFlow, false);
  const [isMarkAsPaidEnabled] = usePartnerFeature('MarkAsPaid', false);
  const { can } = usePermissions();

  if (!payment) {
    return {
      markAsPaid: false,
      delete: false,
      resolveFailedToCollect: false,
      resolveFailedToDeliver: false,
      resolveRefund: false,
    };
  }

  const canMarkAsPaid = can({
    subject: 'payment:markAsPaid',
    action: 'update',
    subjectData: {
      createdById: payment.createdById,
      fundingSourceId: payment.fundingSourceId,
      vendor: {
        createdById: payment.vendor?.createdById,
        managedBy: payment.vendor?.managedBy,
      },
      payment: {
        origin: payment.origin,
      },
    },
  });
  const canDeletePayment = can({
    subject: 'payment',
    action: 'delete',
    subjectData: {
      createdById: payment.createdById,
      fundingSourceId: payment.fundingSourceId,
      vendor: {
        createdById: payment.vendor?.createdById,
        managedBy: payment.vendor?.managedBy,
      },
      payment: {
        origin: payment.origin,
      },
    },
  });
  const canDeleteBills = payment.bills?.length
    ? payment.bills.every((bill) =>
        can({
          subject: 'bill',
          action: 'delete',
          subjectData: {
            createdById: bill.createdById,
            vendor: {
              createdById: payment.vendor?.createdById,
              managedBy: payment.vendor?.managedBy,
            },
          },
        })
      )
    : true;
  const hasRecoverPermission = can({
    subject: 'payment',
    action: 'recover',
    subjectData: {
      createdById: payment.createdById,
      fundingSourceId: payment.fundingSourceId,
      vendor: {
        createdById: payment.vendor?.createdById,
        managedBy: payment.vendor?.managedBy,
      },
      payment: {
        origin: payment.origin,
      },
    },
  });

  const isDeletable = !!payment.paymentActions?.delete.eligible;
  const isFailedToCollectEnabledFinal = hasRecoverPermission && payment.paymentActions?.retryCollect.eligible;
  const isFailedToDeliverEnabledFinal =
    !payment.isFinanced && hasRecoverPermission && payment.paymentActions?.retryDeliver.eligible;
  const isRefundEnabled =
    !payment.isFinanced && payment.paymentActions?.refund.eligible && isRobinhoodRefundFlowEnabled;
  const isRefundOrVoidCheck = payment?.metadata?.isRefund || payment?.metadata?.isVoidCheck;
  const isDeclinedBillSubscription =
    payment?.subscriptionOccurrence?.billSubscription?.approvalDecisionStatus ===
    BillSubscriptionApprovalDecisionStatusEnum.Declined;

  if (isRefundOrVoidCheck) {
    return disabledActions;
  }

  return {
    markAsPaid: isMarkAsPaidEnabled && isDeletable && canMarkAsPaid && canDeletePayment && !isDeclinedBillSubscription,
    delete: isDeletable && canDeletePayment && canDeleteBills,
    resolveFailedToCollect: !!isFailedToCollectEnabledFinal,
    resolveFailedToDeliver: !!isFailedToDeliverEnabledFinal,
    resolveRefund: !!isRefundEnabled,
  };
};
