import { atom, DefaultValue, selector } from 'recoil';

import { PayDashboardType, SelectedCard } from './PayDashboard.types';

export const payDashboardState = atom<PayDashboardType>({
  key: 'payDashboardState',
  default: {
    selectedCard: {},
    querySearch: '',
    forceSelectFirst: false,
    itemSelections: [],
  },
});

export const payDashboardSelectedCardSelector = selector<SelectedCard>({
  key: 'payDashboardSelectedCardSelector',
  get: ({ get }) => {
    const payDashboard = get(payDashboardState);
    return payDashboard.selectedCard;
  },
  set: ({ get, set }, selectedCard) => {
    if (selectedCard instanceof DefaultValue) {
      return;
    }
    set(payDashboardState, { ...get(payDashboardState), selectedCard });
  },
});

export const payDashboardForceSelectFirstSelector = selector<boolean>({
  key: 'payDashboardForceSelectFirstSelector',
  get: ({ get }) => {
    const payDashboard = get(payDashboardState);
    return payDashboard.forceSelectFirst;
  },
  set: ({ get, set }, forceSelectFirst) => {
    if (forceSelectFirst instanceof DefaultValue) {
      return;
    }
    set(payDashboardState, { ...get(payDashboardState), forceSelectFirst });
  },
});

export const mobileSideNavState = atom<boolean>({
  key: 'mobileSideNavState',
  default: false,
});
