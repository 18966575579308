import { VendorsApiClient } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

type UseVendorProps = UseModelProps<
  typeof VendorsApiClient.getVendorsVendorId,
  typeof VendorsApiClient.patchVendorsVendorId
>;

export const useVendor = (props: UseVendorProps = {}) =>
  useModel({
    ...props,
    queryKey: 'VendorsApi',
    queryFn: VendorsApiClient.getVendorsVendorId,
    updateFn: VendorsApiClient.patchVendorsVendorId,
  });
