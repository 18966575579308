import { useFormSubmissionController } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { SystemMessageDisplay } from '@melio/platform-utils';
import { useEffect } from 'react';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { OwnerDetailsFormWidget, OwnerDetailsFormWidgetFields } from './widgets';

export type OwnerDetailsScreenOnDone = (data: OwnerDetailsFormWidgetFields) => void;

type Props = {
  defaultFields?: Partial<OwnerDetailsFormWidgetFields>;
  companyName?: string;
  isSaving: boolean;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: OwnerDetailsScreenOnDone;
};

export const OwnerDetailsScreen = withAnalyticsContext<Props>(
  ({ defaultFields, companyName, isSaving, onBack, onClose, onDone, setAnalyticsProperties, ...props }) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    setAnalyticsProperties({
      PageName: 'add-business-owner-details',
      CompanyOwner: false,
    });

    const trackClick = (cta: string, ownerEmail?: string) =>
      track(`User`, 'Click', {
        Cta: cta,
        ...(ownerEmail ? { OwnerEmail: ownerEmail } : {}),
      });

    useEffect(() => {
      track('User', 'View');
    }, [track]);

    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
      useFormSubmissionController<OwnerDetailsFormWidgetFields>();

    const onSubmit = (formData: OwnerDetailsFormWidgetFields) => {
      trackClick('add-company', formData.contactEmail);
      onDone(formData);
    };

    const handleClose = () => {
      trackClick('exit');
      onClose();
    };

    return (
      <NewSinglePaymentStepLayout
        data-component="AddCompanyActivity.OwnerDetailsScreen"
        data-testid="add-company-activity-owner-details-screen"
        {...props}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.CloseButton onClick={handleClose} />
            <NewSinglePaymentStepLayout.ProgressBar currentStep={2} totalSteps={2} />
            <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
          </NewSinglePaymentStepLayout.Header>
        }
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            <NewSinglePaymentStepLayout.NextButton
              {...submitButtonProps}
              label={formatMessage('activities.addCompany.screens.ownerDetails.continue')}
            />
          </NewSinglePaymentStepLayout.Actions>
        }
      >
        <SystemMessageDisplay />
        <NewSinglePaymentStepLayout.Title>
          {formatMessage('activities.addCompany.screens.ownerDetails.title', { companyName })}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Description>
          {formatMessage('activities.addCompany.screens.ownerDetails.description')}
        </NewSinglePaymentStepLayout.Description>
        <NewSinglePaymentStepLayout.Content>
          <OwnerDetailsFormWidget
            defaultFields={defaultFields}
            onSubmit={onSubmit}
            onSubmissionStateChange={onSubmissionStateChange}
            isSaving={isSaving}
          />
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

OwnerDetailsScreen.displayName = 'AddCompanyActivity.OwnerDetailsScreen';
