import { ConversionUtils, PayorPaymentRequestDetails, useMelioIntl } from '@melio/ar-domain';
import { Group, LoadingContainer, SectionBanner } from '@melio/penny';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { FundingSourceSelection } from '../components';
import { GuestPayorFundingSourceTypes } from '../types';
import { BaseLayout } from './Base.layout';
import { InvoiceDetailsHeader, LegalDisclaimer, PaymentMethodTitle } from './components';
import { SharedPaymentLayoutProps } from './types';

export type PaymentLayoutProps = SharedPaymentLayoutProps & {
  supportedPaymentMethods: GuestPayorFundingSourceTypes[];
  companyName?: string;
  companyLogoUrl?: string;
  invoiceFileUrl?: string;
  invoiceTotalAmount?: number;
  invoiceDueDate?: Date;
  fees?: PayorPaymentRequestDetails['fees'];
  isLoading?: boolean;
  isLoadingContent?: boolean;
  isHidden?: boolean;
};

export const PaymentLayout = forwardRef<PaymentLayoutProps>(
  (
    {
      onViewInvoice,
      selectedFundingSource,
      onSelectFundingSource,
      isDisabled,
      isLoading,
      isLoadingContent,
      shouldHidePaymentMethodSelection,

      supportedPaymentMethods,
      companyName,
      companyLogoUrl,
      invoiceFileUrl,
      invoiceDueDate,
      invoiceTotalAmount,
      fees,

      children,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    // TODO extract "shouldShowGoodsReceivedBanner" to pay by card activity after refactoring
    const goodsReceivedAmountThreshold = ConversionUtils.fromDollarsToCents(
      useConfig().settings.goodsReceivedAmountThreshold
    );
    const shouldShowGoodsReceivedBanner =
      selectedFundingSource === 'card' && invoiceTotalAmount && invoiceTotalAmount > goodsReceivedAmountThreshold;

    return (
      <BaseLayout
        data-layout={PaymentLayout.displayName}
        isLoading={isLoading}
        data-disabled={!!isDisabled}
        {...props}
        ref={ref}
      >
        <Group variant="vertical">
          <Group variant="vertical" spacing="l" hasDivider>
            <InvoiceDetailsHeader
              companyName={companyName}
              companyLogoUrl={companyLogoUrl}
              invoiceDueDate={invoiceDueDate}
              invoiceTotalAmount={invoiceTotalAmount}
              invoiceFileUrl={invoiceFileUrl}
              onViewInvoice={isDisabled ? undefined : onViewInvoice}
            />
            <Group variant="vertical" spacing="l">
              {supportedPaymentMethods.length > 0 && !shouldHidePaymentMethodSelection && (
                <Group variant="vertical">
                  {shouldShowGoodsReceivedBanner ? (
                    <SectionBanner
                      data-testid="guest-payment-goods-received-banner"
                      description={formatMessage(
                        supportedPaymentMethods.length == 1
                          ? 'ar.guestPayment.paymentMethods.goodsReceivedBanner.card.description.text'
                          : 'ar.guestPayment.paymentMethods.goodsReceivedBanner.cardAndBank.description.text'
                      )}
                    />
                  ) : null}
                  {supportedPaymentMethods.length > 1 ? (
                    <FundingSourceSelection
                      fees={fees}
                      selectedFundingSource={selectedFundingSource}
                      onCardSelected={() => onSelectFundingSource?.('card')}
                      onBankSelected={() => onSelectFundingSource?.('bank')}
                      isDisabled={isDisabled || isLoading}
                    />
                  ) : supportedPaymentMethods.includes('card') ? (
                    <PaymentMethodTitle
                      title={formatMessage('ar.guestPayment.paymentMethods.payByCard.title.text')}
                      feeDetails={fees?.card}
                    />
                  ) : (
                    <PaymentMethodTitle
                      title={formatMessage('ar.guestPayment.paymentMethods.payByBank.title.text')}
                      feeDetails={fees?.ach}
                    />
                  )}
                </Group>
              )}
              <LoadingContainer isLoading={isLoadingContent}>{children} </LoadingContainer>
            </Group>
          </Group>
          {supportedPaymentMethods.length > 0 ? <LegalDisclaimer /> : null}
        </Group>
      </BaseLayout>
    );
  }
);

PaymentLayout.displayName = 'PaymentLayout';
