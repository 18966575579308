/* eslint-disable react-hooks/exhaustive-deps */
import { usePaymentProcessingInfo } from '@melio/ap-domain';
import { PaymentReviewLineItem } from '@melio/ap-widgets';
import { Container, Group } from '@melio/penny';
import { useAnalytics, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { Payment, PaymentFullyExpanded } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { usePaymentDescription } from '../../../hooks/usePaymentDescription';
import { PaymentDescription } from '../PaymentDescription';
import { PaymentDetailsTop } from '../PaymentDetailsTop';
import { NoteToSelfSection } from '../sections/NoteToSelfSection';
import { PaymentFailedPayFromSection } from '../sections/PaymentFailedPayFromSection';
import { SectionWrapper } from '../sections/SectionWrapper';

const getPageName = (paymentActions?: Payment['paymentActions']) => {
  if (paymentActions?.retryCollect?.eligible) {
    return 'payment-failed-to-collect';
  }
  if (paymentActions?.retryDeliver?.eligible) {
    return 'payment-failed-to-deliver';
  }
  return 'payment-failed';
};

export const PaymentFailed = withAnalyticsContext(({ payment }: { payment: PaymentFullyExpanded }) => {
  const { formatMessage } = useMelioIntl();

  const { track } = useAnalytics();
  const description = usePaymentDescription(payment);

  useAnalyticsContext({ globalProperties: { PaymentId: payment.id } });

  const { isPaymentProcessedByCapitalOne } = usePaymentProcessingInfo();
  const shouldDisplayMemoToVendor = !isPaymentProcessedByCapitalOne(payment.deliveryMethod?.type);

  useEffect(() => {
    if (payment) {
      track('Dashboard', 'View', {
        PageName: getPageName(payment.paymentActions),
        intent: 'payment-details',
        FailedDestination: 'deliver-failure',
      });
    }
  }, [payment]);

  return (
    <>
      {description ? <PaymentDescription description={description} /> : null}
      <Container data-testid="pay-dashboard-payment-failed">
        <Group variant="vertical" spacing="m">
          <SectionWrapper>
            <PaymentDetailsTop payment={payment} />
          </SectionWrapper>

          <NoteToSelfSection userNote={payment.userNote} />

          <SectionWrapper>
            <PaymentFailedPayFromSection payment={payment} />
          </SectionWrapper>

          {shouldDisplayMemoToVendor && (
            <SectionWrapper>
              <PaymentReviewLineItem
                labelProps={{ label: formatMessage('widgets.paymentDetails.vendorReceives.memoToVendor.label') }}
                mainLabelProps={{
                  label: payment.note
                    ? payment.note
                    : formatMessage('widgets.paymentDetails.vendorReceives.memoToVendor.empty'),
                }}
              />
            </SectionWrapper>
          )}
        </Group>
      </Container>
    </>
  );
});
