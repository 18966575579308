import { useAnalytics } from '@melio/platform-analytics';
/* eslint-disable no-restricted-imports */
import {
  AccountingFirmBillingFeesClient,
  PostAccountingFirmBillingFeesRequest,
  PutAccountingFirmBillingFeesRequest,
} from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage, useWizard, UseWizardArgs } from '@melio/platform-utils';
import { useEffect, useMemo, useState } from 'react';

import { FlowType, Steps } from '../types';
import { useBillingFeeSettingsData } from './useBillingFeeSettingsData';

const locationsMap: UseWizardArgs<Steps>['locationsMap'] = {
  ['funding-source']: 'funding-source',
  ['clients']: 'clients',
  ['success']: 'success',
};

const navigationMap: UseWizardArgs<Steps>['navigationMap'] = {
  ['funding-source']: () => 'funding-source',
  ['clients']: () => 'clients',
  ['success']: () => 'success',
};

const firstStep: Steps = 'funding-source';

const stepsOrder: Steps[] = ['funding-source', 'clients', 'success'];

type UseBillingFeeSettingsWizardProps = { flowType: FlowType };

export const useBillingFeeSettingsWizard = ({ flowType }: UseBillingFeeSettingsWizardProps) => {
  const [selectedFundingSourceId, setSelectedFundingSourceId] = useState<string | null>(null);
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();

  const {
    accountingFirmBillingFeeData,
    clientsList,
    createAccountingFirmBillingFee,
    updateAccountingFirmBillingFee,
    fundingSources,
    isError,
    isFetching,
    isMutating,
    isFetched,
    handleSelectClients,
    isClientListReady,
  } = useBillingFeeSettingsData();

  const { goNextMap, goBack, cancelFlow, currentStep, completeFlow } = useWizard<Steps, typeof navigationMap>({
    flowName: 'add-billing-fee-settings',
    firstStep,
    locationsMap,
    navigationMap,
    cancelUrlFallback: '/settings/billing',
    enableMidFlowEntry: true,
  });

  const fundingSourceIds = useMemo(() => fundingSources?.map((fs) => fs.id) ?? [], [fundingSources]);

  useEffect(() => {
    if (flowType === 'edit' && accountingFirmBillingFeeData?.accountingFirmBillingFee?.fundingSourceId) {
      setSelectedFundingSourceId(accountingFirmBillingFeeData?.accountingFirmBillingFee?.fundingSourceId);
    }
  }, [flowType, accountingFirmBillingFeeData?.accountingFirmBillingFee?.fundingSourceId, setSelectedFundingSourceId]);

  const missingBillingFeeSettingsOnEdit =
    isFetched && flowType === 'edit' && !accountingFirmBillingFeeData?.accountingFirmBillingFee;

  const handleSkipClients = () => handleSave({ skipClients: true });

  const handleSave = async ({ skipClients }: { skipClients?: boolean } = { skipClients: false }) => {
    try {
      const clients: AccountingFirmBillingFeesClient[] = skipClients
        ? []
        : clientsList.items.map((item) => ({
            organizationId: item.id,
            isActive: item.checked,
          }));

      if (flowType === 'edit') {
        if (!accountingFirmBillingFeeData?.accountingFirmBillingFee) {
          throw new Error('Accounting Firm Billing Fee not found');
        }

        await updateAccountingFirmBillingFee({
          id: accountingFirmBillingFeeData.accountingFirmBillingFee.id,
          data: { clients, fundingSourceId: selectedFundingSourceId } as PutAccountingFirmBillingFeesRequest,
        });
      } else {
        await createAccountingFirmBillingFee({
          fundingSourceId: selectedFundingSourceId,
          clients,
        } as PostAccountingFirmBillingFeesRequest);
      }
    } catch (e) {
      showMessage({
        type: 'error',
        title: formatMessage('activities.accountants.billingSettings.billingFeeSettings.error'),
      });
    }

    goNextMap['success']();
  };

  const handleNext = async () => {
    if (flowType === 'edit') {
      //eslint-disable-next-line @typescript-eslint/no-floating-promises
      await handleSave();
      return;
    }
    goNextMap['clients']();
  };

  const handleCancel = () => {
    track('PaymentMethod', 'Click', {
      FundingSourceId: accountingFirmBillingFeeData?.accountingFirmBillingFee?.fundingSourceId,
      Cta: 'close',
    });
    cancelFlow();
  };

  const handleComplete = () => {
    track('PaymentMethod', 'Click', {
      FundingSourceId: accountingFirmBillingFeeData?.accountingFirmBillingFee?.fundingSourceId,
      Cta: 'done',
    });
    completeFlow('/settings/billing');
  };

  return {
    fundingSourceIds,
    selectedFundingSourceId,
    setSelectedFundingSourceId,
    isFetching,
    isMutating,
    accountingFirmBillingFee: accountingFirmBillingFeeData?.accountingFirmBillingFee,
    handleSave,
    handleNext,
    handleSkipClients,
    handleCancel,
    handleComplete,
    isError: isError || missingBillingFeeSettingsOnEdit,
    currentStep,
    goBack,
    handleSelectClients,
    clientsList,
    stepsOrder,
    isClientListReady,
  };
};
