import { PublicPayorApiClient } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

export type UseGuestPayorUserDetailsByEmailProps = Omit<
  UseModelProps<typeof PublicPayorApiClient.getPublicPayorPaymentRequestUserDetailsByEmail>,
  'id'
> & {
  paymentRequestLink?: string;
  email?: string;
};

export const useGuestPayorUserDetailsByEmail = ({
  paymentRequestLink,
  email,
  enabled = true,
  ...props
}: UseGuestPayorUserDetailsByEmailProps) =>
  useModel({
    enabled: enabled && !!email && !!paymentRequestLink,
    id: paymentRequestLink,
    queryKey: ['PublicPayorApi', 'getPublicPayorPaymentRequestUserDetailsByEmail', email, paymentRequestLink],
    queryFn: () =>
      PublicPayorApiClient.getPublicPayorPaymentRequestUserDetailsByEmail(
        paymentRequestLink as string,
        email as string
      ),
    ...props,
  });

export type GuestPayorUserDetailsByEmailModel = ReturnType<typeof useGuestPayorUserDetailsByEmail>;
