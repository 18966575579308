import { Payment } from '@melio/platform-api';

import { sortPaymentsByCreatedAt, sortPaymentsByUpdatedAt } from '../utils/sort.utils';
import { useGetNewPayments } from './useGetNewPayments';

const financingFilterPaymentsFn = (payment: Payment) =>
  payment.loan?.status === 'active' || payment.loan?.status === 'completed';

export const useNewFinancingTabItems = () =>
  useGetNewPayments({
    preferenceKey: 'payDashboardFinancingTabLastSeen',
    sortPaymentsBy: sortPaymentsByCreatedAt,
    filterPaymentsFn: financingFilterPaymentsFn,
    paymentsParams: {
      expand: 'none',
      search: {
        'payment.isFinanced': true,
      },
      sort: [
        {
          field: 'createdAt',
          order: 'desc',
        },
      ],
    },
  });

const financingTabNewFailedLoansFilterFn = (payment: Payment) =>
  payment.loan?.status === 'failed' ||
  payment.loan?.status === 'handled-by-credit-key' ||
  payment.loan?.status === 'canceled';

export const useFinancingTabNewFailedLoans = () =>
  useGetNewPayments({
    preferenceKey: 'npeFinancingTabLastFailedBadge',
    filterPaymentsFn: financingTabNewFailedLoansFilterFn,
    sortPaymentsBy: sortPaymentsByUpdatedAt,
    paymentsParams: {
      expand: 'none',
      search: {
        'payment.isFinanced': true,
      },
      sort: [
        {
          field: 'updatedAt',
          order: 'desc',
        },
      ],
    },
  });
