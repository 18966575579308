import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useRouter } from '@/hooks/router.hooks';
import { useActiveScreen } from '@/hooks/useActiveScreen';
import { ActiveFlowEnum, ScreensEnum } from '@/store/app/app.types';

export const VendorsRoute = () => {
  const { generateNPEDashboardLink } = useRouter();
  useActiveScreen(ScreensEnum.vendors, ActiveFlowEnum.vendors);

  return (
    <Routes>
      <Route path={'/'} element={<Navigate replace to={generateNPEDashboardLink('vendors')} />}>
        <Route path={':vendorId'} element={<Navigate replace to={generateNPEDashboardLink('vendors/:vendorId')} />} />
      </Route>
    </Routes>
  );
};
