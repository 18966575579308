import { getFundingSourceIconType, getFundingSourceImageProps } from '@melio/ap-domain';
import { BrandSymbol, BrandSymbolKey, brandSymbolsMap, Icon, IconKey, Image } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';

export const FundingSourceIconOrImage = ({ fundingSource }: { fundingSource: FundingSource }) => {
  const imageProps = getFundingSourceImageProps(fundingSource);
  if (imageProps) {
    return <Image width={8} height={8} {...imageProps} />;
  }

  const iconType = getFundingSourceIconType(fundingSource);

  const isBrandSymbol = Object.keys(brandSymbolsMap).includes(iconType as BrandSymbolKey);

  return isBrandSymbol ? (
    <BrandSymbol type={iconType as BrandSymbolKey} />
  ) : (
    <Icon
      type={iconType as IconKey}
      color="inherit"
      role="img"
      aria-label={fundingSource.nickname || fundingSource.displayName}
    />
  );
};
