import { formatList } from '@melio/ap-domain';
import { Group, Text, Typography } from '@melio/penny';
import { useCollaborators, usePaymentPendingApprovers } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const PaymentPendingApprovers = ({ id }: { id: string }) => {
  const { data: pendingApprovers, isLoading: isLoadingPendingApprovers } = usePaymentPendingApprovers({
    id,
  });
  const { data: collaborators, isLoading: isLoadingCollaborators } = useCollaborators({
    expand: ['user'],
  });
  const { formatMessage, locale } = useMelioIntl();

  if (isLoadingPendingApprovers || isLoadingCollaborators || !pendingApprovers) {
    return null;
  }

  const { specificApproverIds, generalApproversCount } = pendingApprovers;

  const getStepApprovers = (stepUserIds: string[], isFirstStep: boolean) => {
    const approvers = stepUserIds.map((userId) => {
      const collaborator = collaborators?.find((collaborator) => collaborator.userId === userId)?.user;
      return collaborator ? `${collaborator.firstName} ${collaborator.lastName}` : userId;
    });

    if (generalApproversCount && isFirstStep) {
      approvers.push(
        formatMessage('widgets.paymentPendingApprovers.step.generalApprovers', { count: generalApproversCount })
      );
    }

    return formatList(approvers, locale, 'conjunction');
  };

  if (specificApproverIds.length === 0) {
    return (
      <Text textStyle="body3" data-testid="payment-pending-approvers">
        {formatMessage('widgets.paymentPendingApprovers.noSteps.approvers', { count: generalApproversCount })}
      </Text>
    );
  } else if (specificApproverIds.length === 1 && specificApproverIds[0]) {
    return (
      <Text textStyle="body3" data-testid="payment-pending-approvers">
        {formatMessage('widgets.paymentPendingApprovers.singleStep.approvers', {
          approvers: getStepApprovers(specificApproverIds[0], true),
        })}
      </Text>
    );
  } else {
    const steps = specificApproverIds.map((stepUserIds, stepIndex) => ({
      content: formatMessage('widgets.paymentPendingApprovers.multiSteps.approvers', {
        stepIndex,
        approvers: getStepApprovers(stepUserIds, stepIndex === 0),
      }),
    }));
    return (
      <Group variant="vertical" spacing="none" data-testid="payment-pending-approvers">
        <Text textStyle="body3">{formatMessage('widgets.paymentPendingApprovers.multiSteps.header')}</Text>
        <Typography.ParagraphList type="unordered" list={steps} />
      </Group>
    );
  }
};
