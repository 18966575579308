import { BrowserRouter, Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { PartnerName } from '@melio/platform-api';

import { ARRoutes } from '@/router/routes/ar/AR.route';
import { UserRoutes } from '@/router/routes/User.route';
import { VexAppRoutes } from '@/router/vex.routes';
import { appPathPrefixSelector } from '@/store/app/app.model';

const RootRoute = () => {
  const [searchParams] = useSearchParams();
  return <Navigate to={`auth?${searchParams.toString()}`} replace />;
};

performance.mark('LD_mark');

export const PartnerApp = ({ partnerName }: { partnerName: PartnerName }) => {
  const pathPrefix = useRecoilValue(appPathPrefixSelector);

  return (
    <BrowserRouter basename={`/${pathPrefix}`}>
      <Routes>
        <Route index element={<RootRoute />} />
        <Route path="/*" element={<UserRoutes partnerName={partnerName} />} />
        <Route path="ar/*" element={<ARRoutes partnerName={partnerName} />} />
        <Route path="vex/*" element={<VexAppRoutes partnerName={partnerName} />} />
      </Routes>
    </BrowserRouter>
  );
};
