import { Group, Icon, Text, Tooltip } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { cloneElement, HTMLProps, useState } from 'react';

type Props = {
  copyText: string;
  initialTooltipText: string;
  triggerAriaLabel: string;
  children: React.ReactElement;
  onCopy?: (value: string) => void;
};

export const CopyToClipboardWidget = ({ copyText, initialTooltipText, children, triggerAriaLabel, onCopy }: Props) => {
  const { formatMessage } = useMelioIntl();

  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const successTooltipText = formatMessage('widgets.copyToClipboard.tooltip.success');
  const errorTooltipText = formatMessage('widgets.copyToClipboard.tooltip.error');

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        setIsCopied(true);
        setIsError(false);
        onCopy?.(copyText);
      })
      .catch(() => {
        setIsCopied(true);
        setIsError(false);
      });
  };

  const getTooltipLabel = () => (
    <Group spacing="xs" aria-hidden>
      {isCopied && <Icon type="checked" color="inverse" size="small" aria-hidden />}
      <Text color="semantic.text.inverse" textStyle="body4" aria-hidden>
        {isCopied ? (isError ? errorTooltipText : successTooltipText) : initialTooltipText}
      </Text>
    </Group>
  );

  const getTriggerAriaLabel = () => {
    if (isCopied) {
      return isError ? errorTooltipText : successTooltipText;
    } else {
      return `${triggerAriaLabel} ${initialTooltipText}`;
    }
  };

  const handleOpenChange = (value: boolean) => {
    setIsOpen(value);
    setIsCopied(false);
  };

  const innerElement = cloneElement(children, {
    'aria-label': getTriggerAriaLabel(),
    onClick: () => handleCopyToClipboard(),
  } as HTMLProps<Element>);

  return (
    <Tooltip
      content={getTooltipLabel()}
      data-testid="copy-to-clipboard-widget-tooltip"
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
    >
      {innerElement}
    </Tooltip>
  );
};

CopyToClipboardWidget.displayName = 'CopyToClipboardWidget';
