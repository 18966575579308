import { ChangeEvent, FieldError, FieldErrorType } from '@basis-theory/basis-theory-js/types/elements';
import { useMelioIntl } from '@melio/platform-i18n';

import { ElementTypeName } from './types';

const getKeyString = (elementType: ElementTypeName) => {
  switch (elementType) {
    case 'cardNumber':
      return 'cardNumber';
    case 'expirationDate':
      return 'cardExpiration';
    case 'verificationCode':
      return 'cardVerificationCode';
  }
};

// take type of first error - we don't support multiple errors on form field
const getErrorType = (errors: FieldError[] | undefined): FieldErrorType | undefined =>
  errors && errors.length > 0 ? errors[0]?.type : undefined;

export const useErrorMessages = () => {
  const { formatMessage } = useMelioIntl();

  return {
    errorMessage: (elementType: ElementTypeName, event: ChangeEvent) => {
      if (event.complete) {
        return undefined;
      }
      const keyString = getKeyString(elementType);
      const errorType = getErrorType(event.errors);

      if (errorType) {
        if (event.empty) {
          return { message: formatMessage(`widgets.tbtForm.${keyString}.validation.required`) };
        }

        return { message: formatMessage(`widgets.tbtForm.${keyString}.validation.format`) };
      }

      return undefined;
    },
  };
};
