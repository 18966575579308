import { Form, FormSelectNewProps, VisuallyHidden } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = Omit<FormSelectNewProps, 'options'>;

export const CountrySelect = (props: Props) => {
  const { formatMessage } = useMelioIntl();

  // At the moment it will be the only available option for vendor
  const unitedStatesOption = {
    label: formatMessage('widgets.vendors.countryCode.unitedStates'),
    value: 'US',
  };

  return (
    <Form.SelectNew
      {...props}
      options={[unitedStatesOption]}
      isReadOnly
      labelProps={{
        tooltipProps: {
          content: (
            <>
              <VisuallyHidden>{formatMessage('widgets.vendors.countryCode.tooltip')} tooltip</VisuallyHidden>
              {formatMessage('widgets.vendors.countryCode.tooltip')}
            </>
          ),
        },
        label: formatMessage('widgets.vendors.countryCode.label'),
      }}
    />
  );
};
