import { PlaidEventData } from '@melio/ap-widgets';
import { useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  BankAccountDeliveryMethod,
  PlaidAccountData,
  useAccountingPlatforms,
  useDeliveryMethods,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { LinkBankAccountActivity } from '../../link-bank-account';
import { BankAccountCreatedScreen, BankIntegrationPlaidLinkingScreen } from './screens';
import { AddBankAccountPlaidActivityProps } from './types';
import { useAddBankAccountPlaidStep } from './useAddBankAccountPlaidStep';
import { useBankAccountPlaid } from './useBankAccountPlaid';

export const AddBankAccountPlaidActivity = withAnalyticsContext<AddBankAccountPlaidActivityProps>(
  ({
    onBack: onFirstStepBack,
    onClose,
    onError: _onError,
    onDone,
    fundingSourceId,
    deliveryMethodId,
    setAnalyticsProperties,
    options,
  }) => {
    const { currentStep, goToStep } = useAddBankAccountPlaidStep({ onFirstStepBack });
    const { hasAccountingPlatform } = useAccountingPlatforms();
    const { isReceivingMethodFlow, shouldAllowNonInstantVerification } = options || {};
    const { formatMessage } = useMelioIntl();
    const verificationSuccessMessage = formatMessage(
      'activities.microDepositsVerification.screens.verification.successToastMessage'
    );

    const { deliveryMethodId: updateDeliveryMethodId } = useParams();
    const { refetch: refetchDeliveryMethods } = useDeliveryMethods({ isGetOwn: true });
    const { toast } = useToast();
    const { track } = useAnalytics();

    const onError = useCallback(
      (error: PlatformError) => {
        if (fundingSource && error.code === '409') {
          return onDone(fundingSource);
        }
        track('PaymentMethod', 'Status', { Status: 'failure', ErrorType: error.message });
        if (_onError) {
          _onError(error);
        } else {
          toast({
            type: 'error',
            title: error.message,
          });
        }
        onClose();
      },

      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onClose, onDone, _onError, toast]
    );

    const { fundingSource, deliveryMethod, onSuccess, isLoading, linkData, linkError } = useBankAccountPlaid({
      onError,
      onClose,
      updateDeliveryMethodId,
      deliveryMethodIdToVerify: deliveryMethodId,
      fundingSourceId,
      options: { isReceivingMethodFlow, shouldAllowNonInstantVerification },
    });

    setAnalyticsProperties({
      Intent: isReceivingMethodFlow ? 'verify-delivery-method' : 'verify-payment-method',
      PaymentMethodType: 'bank-account',
    });

    useEffect(() => {
      if (linkError) {
        Logger.log(`Plaid link generation failed. error message: ${linkError}`, 'error');
        onError?.({
          code: '500',
          message: formatMessage(
            fundingSourceId || deliveryMethod?.id
              ? 'widgets.paymentMethods.bankAccount.verify.error'
              : 'activities.createFundingSource.screens.error'
          ),
        });
      }
    }, [linkError, onError, formatMessage, fundingSourceId, deliveryMethod?.id]);

    const onPlaidEvent = useCallback(
      ({ pageName, action, properties }: PlaidEventData) => {
        if (!isReceivingMethodFlow) {
          track('PaymentMethod', action, {
            PageName: pageName,
            ...properties,
          });
        }
      },
      [track, isReceivingMethodFlow]
    );

    useEffect(() => {
      if (fundingSourceId || updateDeliveryMethodId || deliveryMethodId) {
        onPlaidEvent({ action: 'View', pageName: 'enter-the-3-letter-code' });
      }
    }, [fundingSourceId, onPlaidEvent, updateDeliveryMethodId, deliveryMethodId]);

    const onPlaidDone = async (data: PlaidAccountData) => {
      const verificationFlowCompleted = fundingSource && data.account.verification_status === 'manually_verified';
      if (verificationFlowCompleted) {
        onDone(fundingSource);
      } else {
        await onSuccess(data);
        if (!deliveryMethodId) {
          goToStep('ADD_BANK_ACCOUNT_PLAID_CONFIRMATION');
        } else {
          toast({
            type: 'success',
            title: verificationSuccessMessage,
          });
        }
      }
    };

    switch (currentStep) {
      case 'ADD_BANK_ACCOUNT_PLAID':
      default:
        return (
          <BankIntegrationPlaidLinkingScreen
            onClose={onClose}
            onSuccess={onPlaidDone}
            onError={onError}
            onEvent={onPlaidEvent}
            isLoading={isLoading}
            plaidToken={linkData?.linkToken || ''}
          />
        );

      case 'LINK_BANK_ACCOUNT':
        return (
          <LinkBankAccountActivity
            onClose={() => {
              refetchDeliveryMethods().catch((err) => onError(err));
              deliveryMethod && onDone(deliveryMethod);
            }}
            onDone={() => {
              refetchDeliveryMethods().catch((err) => onError(err));
              deliveryMethod && onDone(deliveryMethod);
            }}
            onBack={() => goToStep('ADD_BANK_ACCOUNT_PLAID_CONFIRMATION')}
            onError={onError}
            deliveryMethodId={deliveryMethod?.id}
          />
        );

      case 'ADD_BANK_ACCOUNT_PLAID_CONFIRMATION':
        return (
          <BankAccountCreatedScreen
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onDone={() => {
              if (fundingSource?.id) {
                onDone(fundingSource);
              } else if (deliveryMethod?.id) {
                if (isReceivingMethodFlow && hasAccountingPlatform) {
                  goToStep('LINK_BANK_ACCOUNT');
                } else {
                  refetchDeliveryMethods().catch((err) => onError(err));
                  onDone(deliveryMethod);
                }
              }
            }}
            fundingSource={fundingSource}
            deliveryMethod={deliveryMethod as BankAccountDeliveryMethod}
            isLoading={isLoading}
            onClose={onClose}
            isReceivingMethodFlow={isReceivingMethodFlow}
          />
        );
    }
  }
);

AddBankAccountPlaidActivity.displayName = 'AddBankAccountPlaidActivity';
