import { useBoolean } from '@melio/platform-utils';
import { useState } from 'react';

export const useCancelErrorMessage = () => {
  const [showErrorMessage, toggleErrorMessage] = useBoolean();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const triggerErrorMessage = (message: string) => {
    setErrorMessage(message);
    toggleErrorMessage.on();
  };

  const closeErrorMessage = () => {
    toggleErrorMessage.off();
    setErrorMessage(null);
  };

  return {
    showErrorMessage,
    errorMessage,
    triggerErrorMessage,
    closeErrorMessage,
  };
};
