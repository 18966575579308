import { EmptyState, FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Button, Container, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

type DiscountsEmptyState = {
  onAdd: VoidFunction;
};

export const DiscountsEmptyState = forwardRef<DiscountsEmptyState>(({ onAdd }, ref) => {
  const { formatMessage } = useMelioIntl();

  return (
    <EmptyState
      ref={ref}
      illustrationType="no-items"
      content={
        <Container>
          <Text textStyle="body3" color="semantic.text.secondary">
            <FormattedMessage id="ar.settings.activities.discountsTable.components.discountsTable.emptyState.text" />
          </Text>
        </Container>
      }
      footer={
        <Button
          variant="secondary"
          label={formatMessage(
            'ar.settings.activities.discountsTable.components.discountsTableToolbar.addButton.label'
          )}
          onClick={onAdd}
          data-testid="empty-state-add-discount-button"
        />
      }
    />
  );
});

EmptyState.displayName = 'EmptyState';
