import { Group, Icon, Link } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Bill } from '@melio/platform-api';

import { useBillDetailsButtonProps } from './useBillDetailsButtonProps';

type BillDetailsButtonProps = { bill: Bill | undefined; onClick: VoidFunction; isDisabled: boolean };

export const BillDetailsButton = ({ bill, onClick, isDisabled }: BillDetailsButtonProps) => {
  const { track } = useAnalytics();
  const { icon, label } = useBillDetailsButtonProps({ bill });

  return (
    <Group width="fit-content" variant="horizontal" spacing="xxs" alignItems="center">
      <Icon
        type={icon}
        size="small"
        color={isDisabled ? 'default' : 'brand'}
        isDisabled={isDisabled}
        aria-hidden="true"
      />
      <Link
        data-testid="payment-flow-form-content-bill-button"
        color="secondary"
        variant="standalone"
        size="medium"
        href="#"
        label={label}
        isDisabled={isDisabled}
        onClick={(e) => {
          e.preventDefault();
          if (!isDisabled) {
            track('Bill', 'Click', {
              Cta: 'edit-bill-details',
              Intent: 'edit-bill-details',
            });
            onClick();
          }
        }}
        isBold
      />
    </Group>
  );
};
