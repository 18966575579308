import { Container, Grid, Illustration, Layout, Link, LoadingContainer, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { PageTitle } from '@melio/platform-utils';
import { identity } from 'lodash';

import { useIsSubscriptionsEnabled } from '../../../hooks';

export const OwnerShouldSetUpSubscriptionActivity = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const { formatMessage } = useMelioIntl();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { data: accountData, isLoading } = useAccount({ id: 'me', enabled: isSubscriptionsEnabled });

  setAnalyticsProperties({
    PageName: 'the-owner-needs-to-finish-setting-up',
    Flow: 'companies',
    Intent: 'add-company',
  });

  const { track } = useAnalytics();

  useAnalyticsView('User', true, true);

  const {
    settings: {
      vex: { zdSupportFormUrl },
    },
  } = useConfig();

  if (!isSubscriptionsEnabled) {
    return null;
  }

  const { contactFirstName, contactLastName } = accountData?.company || {};
  const ownerName = [contactFirstName, contactLastName].filter(identity).join(' ');

  return (
    <LoadingContainer isLoading={isLoading}>
      <Layout data-testid="owner-should-set-up-subscription-activity" maxWidth="600px">
        <Container height="full">
          <Grid alignContent="center" justifyItems="center" height="full" textAlign="center" gap="s">
            <Illustration size="large" type="blocked" />
            <Grid gap="s">
              <PageTitle textStyle="heading2Semi">
                {formatMessage('activities.subscription.ownerShouldSetUpSubscription.title')}
              </PageTitle>

              <Text color="global.neutral.900">
                {formatMessage('activities.subscription.ownerShouldSetUpSubscription.description', {
                  ownerName,
                  contactSupportLink: (
                    <Link
                      onClick={() => {
                        track('User', 'Click', {
                          Intent: 'contact-support',
                          Cta: 'contact-support',
                        });
                      }}
                      label={formatMessage(
                        'activities.subscription.ownerShouldSetUpSubscription.description.contactSupportLinkLabel'
                      )}
                      href={zdSupportFormUrl}
                      newTab
                    />
                  ),
                })}
              </Text>
            </Grid>
          </Grid>
        </Container>
      </Layout>
    </LoadingContainer>
  );
});
