import { FundingSourcesApiClient } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

const postFundingSourcesPlaidLinkToken = () => FundingSourcesApiClient.postFundingSourcesPlaidLinkToken();
export type UsePlaidLinkTokenProps = UseModelProps<typeof postFundingSourcesPlaidLinkToken>;

export const usePlaidLinkToken = (props: UsePlaidLinkTokenProps = {}) =>
  useModel({
    ...props,
    queryKey: 'FundingSourcesApi',
    id: 'PLAID_LINK_TOKEN_MODEL',
    queryFn: postFundingSourcesPlaidLinkToken,
    cacheTime: 0,
    staleTime: 0,
  });

export type PlaidLinkTokenModel = ReturnType<typeof usePlaidLinkToken>;
