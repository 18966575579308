import { sanitizeId } from '@melio/platform-analytics';
import { Payment } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';

export const usePaymentConfirmationNumber = () => {
  const {
    settings: {
      payment: { isConfirmationNumberEnabled },
    },
  } = useConfig();

  return {
    getConfirmationNumber: (payment: Payment): string =>
      isConfirmationNumberEnabled && payment.confirmationNumber
        ? payment.confirmationNumber
        : sanitizeId(payment.id).toString(),
  };
};
