import { Group, Icon, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { GetFundingSourceStatusIconResult } from '../../PaymentIntentsTable.screen.utils';
import { FundingSourceStatusIcon } from './FundingSourceStatusIcon';

type FundingSourceSelectTriggerProps = {
  item?: {
    label: string;
    description?: string;
  };
  isOpen: boolean;
  isInvalid?: boolean;
  isLoading?: boolean;
  isReadOnly?: boolean;
  statusIconProps?: GetFundingSourceStatusIconResult;
};

export const FundingSourceSelectTrigger = forwardRef<FundingSourceSelectTriggerProps, 'div'>(
  ({ item, isOpen, isLoading, isReadOnly, statusIconProps }: FundingSourceSelectTriggerProps, ref) => {
    const caretIcon = (
      <Group.Item grow={1}>
        <Group alignItems="center" justifyContent="flex-end" spacing="none" data-testid="funding-source-select-trigger">
          <Icon type={isOpen ? 'caret-up' : 'caret-down'} size="small" color="default" aria-hidden />
        </Group>
      </Group.Item>
    );
    const triggerRight = !isLoading && !isReadOnly && caretIcon;
    return (
      <Group width="full" alignItems="center" spacing="xs" ref={ref}>
        {item && (
          <Group
            variant="vertical"
            alignItems="flex-start"
            spacing="xxxs"
            width="fit-content"
            data-testid="funding-source-select-trigger-label"
          >
            <Text textStyle="body3" shouldSupportEllipsis color="inherit">
              {item.label}
            </Text>
            {item.description && (
              <Text textStyle="body3" shouldSupportEllipsis color="global.neutral.800">
                {item.description}
              </Text>
            )}
          </Group>
        )}
        {!isLoading && statusIconProps && <FundingSourceStatusIcon statusIconProps={statusIconProps} />}
        {triggerRight}
      </Group>
    );
  }
);
