import { AccountLimitations, useAccountMeLimitations } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMemo } from 'react';

import { MissingField } from '../CompleteRequiredDetailsForm';

const getMissingFields = (accountLimitations: AccountLimitations): Array<MissingField> => {
  const { user, company } = accountLimitations.entitiesInformation;
  const companyFields = company?.missingFields || [];
  const userFields = user?.missingFields || [];

  return [
    ...userFields.map<MissingField>((field) => ({ ...field, entity: 'user' })),
    ...companyFields.map<MissingField>((field) => ({ ...field, entity: 'company' })),
  ];
};

export const useCompleteComplianceInformation = ({
  isInitiator,
}: {
  isInitiator?: boolean;
} = {}) => {
  const [existingMtlKycEnabled] = useDevFeature(FeatureFlags.ExistingUserMtlKycUplift, false);
  const [newRiskParamsEnabled] = useDevFeature(FeatureFlags.RiskMtlStageAndInitiatorParams, false);

  const {
    data: accountLimitations,
    isLoading: _isLoadingLimitations,
    isFetching: isFetchingLimitations,
    error: fetchLimitationsError,
    invalidateQuery,
  } = useAccountMeLimitations({
    enabled: existingMtlKycEnabled,
    params: newRiskParamsEnabled && isInitiator !== undefined ? { stage: 'payment', isInitiator } : undefined,
  });
  const isLoadingLimitations = _isLoadingLimitations || isFetchingLimitations;
  const { isGracePeriodStarted, isGracePeriodEnded, numberOfPaymentsLeftInGracePeriod } = accountLimitations || {};

  // We're using useMemo to avoid re-rendering due to changed ref of missingFields
  const missingFields = useMemo(
    () => (accountLimitations ? getMissingFields(accountLimitations) : []),
    [accountLimitations]
  );

  return {
    missingFields,
    isLoading: isLoadingLimitations,
    error: fetchLimitationsError,
    isGracePeriodStarted,
    isGracePeriodEnded,
    numberOfPaymentsLeftInGracePeriod,
    invalidateQuery,
  };
};
