import { OrganizationBusinessType, TaxIdTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const useMtlMessages = () => {
  const { formatMessage } = useMelioIntl();

  const getEINRequiredText = (type?: OrganizationBusinessType) =>
    formatMessage('app.mtl.labels.einOnlyHelperText', {
      businessType: type ? formatMessage(`app.mtl.labels.businessType.option.${type}`) : '',
    });

  return {
    labels: {
      company: {
        address: formatMessage('app.mtl.labels.company.address'),
        suite: formatMessage('app.mtl.labels.company.suite'),
        legalAddress: formatMessage('app.mtl.labels.company.address.legal'),
        contactFirstName: formatMessage('app.mtl.labels.company.contactFirstName'),
        contactLastName: formatMessage('app.mtl.labels.company.contactLastName'),
        name: formatMessage('app.mtl.labels.company.name'),
        legalDateOfBirth: formatMessage('app.mtl.labels.company.legalDateOfBirth'),
        legalName: formatMessage('app.mtl.labels.company.name.legal'),
        legalNameNonEin: formatMessage('app.mtl.labels.company.name.legalNonEin'),
        phoneNumber: formatMessage('app.mtl.labels.company.phoneNumber'),
        industry: formatMessage('app.mtl.labels.company.industry'),
        businessType: formatMessage('app.mtl.labels.company.businessType'),
        businessTypeOption: (type?: OrganizationBusinessType) =>
          type ? formatMessage(`app.mtl.labels.businessType.option.${type}`) : '',
        taxIdType: formatMessage('app.mtl.labels.company.taxIdType'),
        taxIdTypeOption: (type?: TaxIdTypeEnum) =>
          type ? formatMessage(`app.mtl.labels.company.taxIdType.option.${type}`) : '',
        taxId: (type?: TaxIdTypeEnum) =>
          formatMessage(type ? `app.mtl.labels.company.taxId.${type}` : 'app.mtl.labels.company.taxId'),
        einOnlyHelperText: getEINRequiredText,
      },
      user: {
        dateOfBirth: formatMessage('app.mtl.labels.user.dateOfBirth'),
        email: formatMessage('app.mtl.labels.user.email'),
        firstName: formatMessage('app.mtl.labels.user.firstName'),
        lastName: formatMessage('app.mtl.labels.user.lastName'),
        mobileNumber: formatMessage('app.mtl.labels.user.mobileNumber'),
        role: formatMessage('app.mtl.labels.user.role'),
      },
    },
    placeholders: {
      firstName: formatMessage('app.mtl.placeholders.firstName'),
      lastName: formatMessage('app.mtl.placeholders.lastName'),
      personalFirstName: formatMessage('app.mtl.placeholders.personal.firstName'),
      personalLastName: formatMessage('app.mtl.placeholders.personal.lastName'),
      phoneNumber: formatMessage('app.mtl.placeholders.phoneNumber'),
      address: formatMessage('app.mtl.placeholders.address'),
      legalAddress: formatMessage('app.mtl.placeholders.address'),
      dateOfBirth: formatMessage('app.mtl.placeholders.dateOfBirth'),
      suite: formatMessage('app.mtl.placeholders.suite'),
      companyName: formatMessage('app.mtl.placeholders.companyName'),
      legalDateOfBirth: formatMessage('app.mtl.placeholders.legalDateOfBirth'),
      legalCompanyName: formatMessage('app.mtl.placeholders.legalCompanyName'),
      legalCompanyNameNonEin: formatMessage('app.mtl.placeholders.legalCompanyName.nonEin'),
      industry: formatMessage('app.mtl.placeholders.industry'),
      businessType: formatMessage('app.mtl.placeholders.businessType'),
      taxId: (type?: TaxIdTypeEnum) =>
        type ? formatMessage(`app.mtl.placeholders.taxId.${type}`) : formatMessage(`app.mtl.placeholders.taxId`),
    },
    viewModePlaceholders: {
      firstName: formatMessage('app.mtl.placeholders.firstName.viewMode'),
      lastName: formatMessage('app.mtl.placeholders.lastName.viewMode'),
      dateOfBirth: formatMessage('app.mtl.placeholders.dateOfBirth.viewMode'),
      mobileNumber: formatMessage('app.mtl.placeholders.mobileNumber.viewMode'),
      address: formatMessage('app.mtl.placeholders.address.viewMode'),
      suite: formatMessage('app.mtl.placeholders.suite.viewMode'),
      phoneNumber: formatMessage('app.mtl.placeholders.phoneNumber.viewMode'),
      companyName: formatMessage('app.mtl.placeholders.companyName.viewMode'),
      industry: formatMessage('app.mtl.placeholders.industry.viewMode'),
      businessType: formatMessage('app.mtl.placeholders.businessType.viewMode'),
      taxIdType: formatMessage('app.mtl.placeholders.taxIdType.viewMode'),
      taxId: formatMessage('app.mtl.placeholders.taxId.viewMode'),
    },
    emptyState: {
      businessType: formatMessage('app.mtl.labels.businessType.noOptions'),
    },
    validations: {
      required: {
        company: {
          einOnlyValidationText: getEINRequiredText,
        },
      },
    },
  };
};
