import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { FundingSource } from '@melio/platform-api';
import { useEffect } from 'react';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { SuccessBillingScreen } from '../screens/SuccessBilling/SuccessBilling.screen';

type Props = {
  fundingSource?: FundingSource;
  completeFlow: VoidFunction;
};

export const SuccessBillingStep = ({ fundingSource, completeFlow }: Props) => {
  const { track, createTrackHandler } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'back-to-billing-settings';
  }>('PaymentMethod', 'Click');

  useAnalyticsView('payment-method-for-check-fees-changed');

  useEffect(() => {
    track('PaymentMethod', 'View', {
      PageName: 'payment-method-added-for-fees',
      Flow: 'set-fs-for-fees',
      EntryPoint: 'settings-page',
      Intent: 'set-fs-for-fees',
      FundingSourceId: fundingSource?.id,
    });
  }, [fundingSource, track]);

  const analyticsProperties = {
    PageName: 'payment-method-added-for-fees',
    Flow: 'set-fs-for-fees',
    EntryPoint: 'settings-page',
    Intent: 'set-fs-for-fees',
    FundingSourceId: fundingSource?.id,
  };
  const onDone = () => {
    trackAndHandleClick({ ...analyticsProperties, Cta: 'back-to-billing-settings' }, () => completeFlow());
  };

  if (!fundingSource) {
    return <NewSinglePaymentStepLayout />;
  }

  return <SuccessBillingScreen fundingSource={fundingSource} onDone={onDone} />;
};
