import { SectionBanner } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useContext } from 'react';

import { useIsMobile } from '../useIsMobile';
import { SystemMessageContext } from './SystemMessageContext';

export const SystemMessage = forwardRef((_, ref) => {
  const { clear, message } = useContext(SystemMessageContext);

  const isMobile = useIsMobile();

  if (!message) {
    return null;
  }

  return (
    <SectionBanner
      data-testid="ar-system-message"
      description={message.title}
      variant={message.type}
      showCloseIcon
      isCompact={!isMobile}
      onClose={clear}
      ref={ref}
    />
  );
});
