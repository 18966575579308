import { addBusinessDays } from '@melio/melio-business-days';

export type FundingSourceType = 'card' | 'bank-account';

export const useEstimatedDeliveryDate = (
  scheduledDate: Date | null | undefined,
  fundingSourceType: FundingSourceType
) => {
  const mapFundingSourceTypeToDeliveryDuration = { card: 1, 'bank-account': 3 };
  return scheduledDate
    ? addBusinessDays(scheduledDate, mapFundingSourceTypeToDeliveryDuration[fundingSourceType])
    : undefined;
};
