import { Bill, EtaDetails, Payment, PaymentIntent, Vendor } from '@melio/platform-api';

import { PaymentFlowDoneAction } from '../types';

export type BatchPaymentsActivityStep =
  | 'FIRST_CREATE_FUNDING_SOURCE'
  | 'PAYMENT_INTENTS_TABLE'
  | 'COMPLETE_LEGAL_INFO'
  | 'PAYMENT_SCHEDULED'
  | 'CREATE_FUNDING_SOURCE'
  | 'CREATE_DELIVERY_METHOD'
  | 'ADD_ACH_INTEGRATION_SELECTION'
  | 'LOADING';

export type BatchPaymentsActivityProps = {
  billIds: Bill['id'][];
  onDone: (paymentIds: Payment['id'][], action: PaymentFlowDoneAction) => void;
  onBack: VoidFunction;
  onError?: ErrorFunction;
  externalOrigin?: string | null;
  isLoading?: boolean;
};

export type MissingVendorDirectoryDetailsBannerProps =
  | {
      vendorName: string;
      onEditClick: VoidFunction;
    }
  | undefined;

export type SchedulePaymentIntent = {
  paymentIntent: PaymentIntent;
  vendor: Vendor;
  bills: Bill[];
  etaDetails?: EtaDetails;
};

export enum DeliveryDateHeaderCellOption {
  EarliestPossible = 'earliest',
  ByDueDate = 'due-date',
  Multiple = 'multiple',
}
