import { StatusModal, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { useUserPreference } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { convertDateToStringRepresentation, useDateUtils } from '@melio/platform-utils';

import { usePlanInfo, useSubscription } from '../../../hooks';
import { useSubscriptionRouter } from '../../utils';

const PREFERENCE_KEY = 'paymentFeeFailedLastSeen';

export const SubscriptionPaymentFeeFailedModal = () => {
  const { createDate } = useDateUtils();
  const currentDate = convertDateToStringRepresentation(createDate());
  const {
    data: paymentFeeFailedLastSeen,
    create: mutate,
    isFetching,
    isMutating,
  } = useUserPreference({ id: PREFERENCE_KEY });
  const { goToPaymentMethodSelect } = useSubscriptionRouter();
  const { formatMessage } = useMelioIntl();
  const subscription = useSubscription();
  const { planName } = usePlanInfo(subscription?.planId);
  const { track } = useAnalytics();

  const shouldShowModal = !isFetching && !isMutating && paymentFeeFailedLastSeen?.value !== currentDate;

  const baseAnalyticsData = {
    PageName: 'couldnt-process-subscription',
    Flow: 'subscription-payment-error',
  };

  useAnalyticsView('Organization', shouldShowModal, true, {
    ...baseAnalyticsData,
    Intent: 'fix-subscription-payment-error',
  });

  if (!shouldShowModal) {
    return null;
  }

  const trackAction = (action: 'exit' | 'update') => {
    track('Organization', 'Click', {
      ...baseAnalyticsData,
      Intent: action === 'exit' ? 'exit' : 'update-details',
      Cta: action === 'exit' ? 'exit' : 'update-details',
    });
  };

  const onClose = () => {
    void mutate({ userPreference: { key: PREFERENCE_KEY, value: currentDate } });
  };

  const close = () => {
    trackAction('exit');
    onClose();
  };

  const onUpdatePaymentMethodClick = () => {
    trackAction('update');
    goToPaymentMethodSelect();
    onClose();
  };

  return (
    <StatusModal
      isOpen
      size="small"
      variant="alert"
      header={formatMessage('activities.subscription.paymentFeeFailed.modal.header')}
      primaryButton={{
        label: formatMessage('activities.subscription.paymentFeeFailed.modal.primaryButton.label'),
        variant: 'primary',
        onClick: onUpdatePaymentMethodClick,
      }}
      onClose={close}
      data-testid="subscription-payment-fee-failed-modal"
    >
      <Text>{formatMessage('activities.subscription.paymentFeeFailed.modal.description', { planName })}</Text>
    </StatusModal>
  );
};
