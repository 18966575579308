import { VendorGroup } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { orderBy } from 'lodash';

import { Filter } from '../../../../Filter/Filter';
import { ALL_VENDORS_GROUP_ID } from './types';

type Props = {
  vendorGroups: VendorGroup[];
  activeFilter?: string;
  onChange: (id: string) => void;
  onEditClick: (id: string) => void;
  onCreateClick: (id: string) => void;
};

export const VendorGroupFilterMobile = ({
  vendorGroups,
  activeFilter,
  onChange,
  onEditClick,
  onCreateClick,
}: Props) => {
  const { formatMessage } = useMelioIntl();

  const options = [
    {
      id: ALL_VENDORS_GROUP_ID,
      label: formatMessage('activities.payDashboard.vendorsTab.vendorGroups.filter.all'),
    },
    ...orderBy(vendorGroups, ({ name }) => name.toLowerCase(), 'asc').map(({ id, name }) => ({
      id,
      label: name,
    })),
  ];

  return (
    <Filter
      options={options}
      defaultFilter={ALL_VENDORS_GROUP_ID}
      activeFilter={activeFilter || ALL_VENDORS_GROUP_ID}
      title={formatMessage('activities.payDashboard.vendorsTab.vendorGroups.filter.title.mobile')}
      onChange={onChange}
      footerRightButton={{
        label: formatMessage('activities.payDashboard.vendorsTab.vendorGroups.filter.editGroup.mobile'),
        variant: 'tertiary',
        getAriaLabel: (selectedFilterLabel) =>
          formatMessage('activities.payDashboard.vendorsTab.vendorGroups.filter.editGroup.mobile.ariaLabel', {
            groupName: selectedFilterLabel,
          }),
        onClick: onEditClick,
        disabledOnDefault: true,
        testId: 'filter-edit-vendor-group',
      }}
      footerLeftButton={{
        label: formatMessage('activities.payDashboard.vendorsTab.vendorGroups.filter.footer.button.create.mobile'),
        variant: 'tertiary',
        getAriaLabel: () =>
          formatMessage('activities.payDashboard.vendorsTab.vendorGroups.filter.footer.button.create.mobile.ariaLabel'),
        onClick: onCreateClick,
        testId: 'filter-create-vendor-group',
      }}
    />
  );
};
