import { FormInputs, useMelioIntl } from '@melio/ar-domain';
import { Form, useMelioForm, UseMelioFormResults } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';
import { date, number, object, SchemaOf, string } from 'yup';

import { MarkAsPaidFormField, PaymentMethod } from './types';

type MarkInvoiceAsPaidFormProps = {
  isSaving?: boolean;
  isLoading?: boolean;
  onSubmit: (formValues: MarkAsPaidFormField) => void;
  defaultValues?: Partial<MarkAsPaidFormField>;
  onSubmissionStateChange: (submissionState: UseMelioFormResults<MarkAsPaidFormField>) => void;
};

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    paymentDate: date()
      .required(formatMessage('ar.dashboard.activities.markInvoiceAsPaid.form.paymentDate.required.label'))
      .typeError(formatMessage('ar.dashboard.activities.markInvoiceAsPaid.form.paymentDate.required.label')),
    paymentMethod: string()
      .optional()
      .oneOf(Object.values(PaymentMethod))
      .transform((value?: string | null, originalValue?: string | null) => (!originalValue ? undefined : value)),
    note: string(),
    referenceNumber: string(),
    amount: number().required(formatMessage('ar.dashboard.activities.markInvoiceAsPaid.form.amount.required.label')),
  }) as SchemaOf<MarkAsPaidFormField>;
};

export const MarkInvoiceAsPaidForm = forwardRef<MarkInvoiceAsPaidFormProps, 'form'>(
  ({ onSubmit, defaultValues, onSubmissionStateChange, isSaving, isLoading }, ref) => {
    const { registerField, formProps, setValue } = useMelioForm({
      schema: useSchema(),
      onSubmit,
      defaultValues: { ...defaultValues, paymentDate: undefined },
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
      isSaving,
      isLoading,
    });
    const { formatMessage } = useMelioIntl();

    const paymentMethodsOptions = [
      {
        label: formatMessage(`ar.dashboard.activities.markInvoiceAsPaid.paymentMethod.options.cash.label`),
        value: PaymentMethod.Cash,
      },
      {
        label: formatMessage(`ar.dashboard.activities.markInvoiceAsPaid.paymentMethod.options.ach.label`),
        value: PaymentMethod.Ach,
      },
      {
        label: formatMessage(`ar.dashboard.activities.markInvoiceAsPaid.paymentMethod.options.check.label`),
        value: PaymentMethod.Check,
      },
      {
        label: formatMessage(`ar.dashboard.activities.markInvoiceAsPaid.paymentMethod.options.card.label`),
        value: PaymentMethod.Card,
      },
      {
        label: formatMessage(`ar.dashboard.activities.markInvoiceAsPaid.paymentMethod.options.other.label`),
        value: PaymentMethod.Other,
      },
    ];

    useEffect(() => {
      if (defaultValues?.paymentDate) {
        setValue('paymentDate', defaultValues.paymentDate);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues?.paymentDate]);

    return (
      <Form size="small" columns={2} ref={ref} {...formProps}>
        <Form.DateField
          {...registerField('paymentDate')}
          labelProps={{ label: formatMessage('ar.dashboard.activities.markInvoiceAsPaid.form.paymentDate.label') }}
          colSpan={1}
          excludeHolidays={false}
          weekDays={[0, 1, 2, 3, 4, 5, 6]}
        />
        <FormInputs.AmountField
          {...registerField('amount')}
          isReadOnly
          labelProps={{ label: formatMessage('ar.dashboard.activities.markInvoiceAsPaid.form.amount.label') }}
          colSpan={1}
        />
        <FormInputs.Select
          {...registerField('paymentMethod')}
          options={paymentMethodsOptions}
          emptyState={{
            label: formatMessage(
              'ar.dashboard.activities.markInvoiceAsPaid.modal.inputs.paymentMethod.dropdown.emptyState.label'
            ),
          }}
          placeholder={formatMessage(
            'ar.dashboard.activities.markInvoiceAsPaid.modal.inputs.paymentMethod.dropdown.emptyState.label'
          )}
          labelProps={{ label: formatMessage('ar.dashboard.activities.markInvoiceAsPaid.form.paymentMethod.label') }}
          colSpan={2}
        />
        <Form.TextField
          {...registerField('referenceNumber')}
          placeholder={formatMessage(
            'ar.dashboard.activities.markInvoiceAsPaid.modal.inputs.referenceNumber.dropdown.emptyState.label'
          )}
          labelProps={{ label: formatMessage('ar.dashboard.activities.markInvoiceAsPaid.form.referenceNumber.label') }}
          colSpan={2}
        />
        <Form.TextArea
          {...registerField('note')}
          labelProps={{ label: formatMessage('ar.dashboard.activities.markInvoiceAsPaid.form.note.label') }}
          colSpan={2}
          maxChars={100}
          numberOfRows={3}
        />
      </Form>
    );
  }
);
