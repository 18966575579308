import { FieldValues, UseMelioFormResults } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { isEmpty, keys } from 'lodash';
import { useEffect } from 'react';

type UseTrackFormErrorProps<T extends FieldValues> = {
  form: UseMelioFormResults<T>;
  pageName: string;
  intent: string;
  viewContext: 'Vendor' | 'Organization';
};

export const useTrackFormError = <T extends FieldValues>({
  form,
  pageName,
  intent,
  viewContext,
}: UseTrackFormErrorProps<T>) => {
  const { track } = useAnalytics();
  const {
    formState: { errors },
  } = form;

  useEffect(() => {
    if (!isEmpty(errors)) {
      const errorKeys = keys(errors);
      errorKeys.forEach((errorKey) => {
        track(viewContext, 'Status', {
          Status: 'failure',
          PageName: pageName,
          Intent: intent,
          ErrorType: errors[errorKey]?.type === 'required' ? 'missing-information' : 'invalid-information',
          ErrorFields: errorKey,
        });
      });
    }
  }, [errors, pageName, intent, track, viewContext]);
};
