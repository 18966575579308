import { BillingFeeSetting, BillingFeeSettingBillingFeeTypeEnum, FeeType } from '@melio/platform-api-axios-client';

const hasBillingFeeMethod = (orgBillingFeeSettings: BillingFeeSetting[]) =>
  orgBillingFeeSettings?.some(
    ({ billingFeeType }) => billingFeeType === BillingFeeSettingBillingFeeTypeEnum.AchToCheck
  );

const hasAchToCheckFeeType = (feesBreakdown?: { type: string; amount: number }[]) =>
  feesBreakdown?.some(({ type }) => type === FeeType.AchToCheck);

const hasAchToAchFeeType = (feesBreakdown?: { type: string; amount: number }[]) =>
  feesBreakdown?.some(({ type }) => type === FeeType.AchToAch);

export const hasActiveBillingFeeMethod = (
  orgBillingFeeSettings: BillingFeeSetting[],
  feesBreakdown?: { type: string; amount: number }[]
) => {
  const existingBillingFeeSettings = hasBillingFeeMethod(orgBillingFeeSettings);
  const hasAchToCheckFee = hasAchToCheckFeeType(feesBreakdown);
  const hasAchToAchFee = hasAchToAchFeeType(feesBreakdown);

  return existingBillingFeeSettings && (hasAchToCheckFee || hasAchToAchFee);
};
