import { useMelioIntl } from '@melio/ar-domain';
import { Button, Container } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type DiscountsTableToolbarProps = {
  onAdd: VoidFunction;
};

export const DiscountsTableToolbar = forwardRef<DiscountsTableToolbarProps>(({ onAdd }, ref) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Container
      paddingX="xxs"
      paddingY="xxs"
      justifyContent="flex-end"
      ref={ref}
      data-component={DiscountsTableToolbar.displayName}
    >
      <Button
        variant="tertiary"
        label={formatMessage('ar.settings.activities.discountsTable.components.discountsTableToolbar.addButton.label')}
        onClick={onAdd}
        data-testid="add-discount-button"
      />
    </Container>
  );
});
DiscountsTableToolbar.displayName = 'DiscountsTableToolbar';
