import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMemo } from 'react';

export const usePlaidAuth = ({ isReceivingMethodFlow }: { isReceivingMethodFlow?: boolean }) => {
  const [isPlaidMicroDepositsEnabled] = useDevFeature(FeatureFlags.PlaidMicroDeposits, false, { shouldTrack: true });
  const [isPlaidMicroDepositsGetPaidEnabled] = useDevFeature(FeatureFlags.PlaidMicroDepositsGetPaidEnabled, false, {
    shouldTrack: true,
  });
  const enabled = useMemo(
    // micro deposits depend on FF for each flow (funding source or delivery method)
    () => (isReceivingMethodFlow ? isPlaidMicroDepositsGetPaidEnabled : isPlaidMicroDepositsEnabled),
    [isPlaidMicroDepositsEnabled, isPlaidMicroDepositsGetPaidEnabled, isReceivingMethodFlow]
  );
  return { enabled };
};
