import { FilesApiClient, PostFilesResponse } from '@melio/ar-api-axios-client';
import { createRawUploadRequest } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { AxiosPromise } from 'axios';

import { useCollection, UseCollectionProps } from '../../api-client';

const postFile = (file: File) => FilesApiClient.postFiles(file);

export type UseFilesProps = UseCollectionProps<typeof FilesApiClient.getFiles, typeof postFile>;

export const useFiles = (props: UseFilesProps = {}) => {
  const [isNewFileUploadEnabled] = useDevFeature(FeatureFlags.NewFileUploadEnabled, false);
  const createFn = (file: File): AxiosPromise<PostFilesResponse> => {
    if (isNewFileUploadEnabled) {
      return createRawUploadRequest(file);
    }

    return postFile(file);
  };

  return useCollection({
    ...props,
    queryKey: 'FilesApi',
    queryFn: FilesApiClient.getFiles,
    createFn,
  });
};

export type FileCollection = ReturnType<typeof useFiles>;
