import { Container, Group, Icon, Typography, useTheme } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const StpAutoPaymentProcessingBanner = () => {
  const { formatMessage } = useMelioIntl();
  const { borderRadii } = useTheme();

  return (
    <Container
      data-testid="stp-auto-payment-processing-banner"
      backgroundColor="light"
      justifyContent="flex-start"
      borderRadius={borderRadii.global['200']}
      border="none"
      paddingX="s"
      // @ts-expect-error by @DinaAronsky's command ME-44852
      paddingY="12px"
    >
      <Group spacing="xs">
        <Icon type="info" size="small" />
        <Typography.Description label={formatMessage('activities.addBillV2.billForm.invoiceNumber.stpBanner')} />
      </Group>
    </Container>
  );
};
