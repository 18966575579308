/* eslint-disable react-hooks/exhaustive-deps */
import { useDisclosure } from '@chakra-ui/react';
import { usePaymentActions, usePaymentsLateApprovalsEnabled } from '@melio/ap-domain';
import { Drawer } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  FundingSourceType,
  FxQuote,
  Payment,
  PaymentDate,
  PaymentFullyExpanded,
  PostApprovalDecisionEnum,
  usePaymentApprovalDecisions,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { SystemMessageProvider } from '@melio/platform-utils';
import { compact, isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MonitoredAction } from '../../monitoring';
import { MicroDepositModalRefHandlers } from '../funding-sources';
import { MicroDepositModalWrapper } from '../funding-sources/MicroDepositsVerification/microDepositModalWrapper';
import { PaymentApprovalWorkflowDeclineModalActivity } from '../payment-approval-workflow';
import { PaymentsLateApprovalModalActivity } from '../payments-late-approval-modal';
import { Body } from './components/Body';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { useBatchPaymentApprovalDecision } from './hooks/useBatchPaymentApprovalDecision';
import { usePaymentDrawerData } from './paymentDrawer.utils';
import { PaymentDrawerContext } from './PaymentDrawerContext';
import { usePaymentDrawerAnalytics } from './usePaymentDrawerAnalytics';

export type PaymentDrawerActivityProps = {
  onClose: VoidFunction;
  onRetryFailedToDeliverPayment: (paymentId: Payment['id']) => void;
  onRetryFailedToCollectPayment: (paymentId: Payment['id']) => void;
  onRefundPayment: (paymentId: Payment['id']) => void;
  onVoidAndRefundPayment: (paymentId: Payment['id']) => void;
  onVoidAndResendPayment: (paymentId: Payment['id']) => void;
  onEditPayment: (paymentId: Payment['id']) => void;
  onMarkAsUnpaid: (payment: Payment) => void;
  onApprovalDecision: (ids: string[], decision: PostApprovalDecisionEnum) => void;
  onEditBillSubscription?: (billSubscriptionId: Payment['id']) => void;
};

const paymentLateModalAnalytics = { name: 'Payment' };

export const PaymentDrawerActivity = (props: PaymentDrawerActivityProps) => (
  <SystemMessageProvider>
    <PaymentDrawer {...props} />
  </SystemMessageProvider>
);

const PaymentDrawer = withAnalyticsContext(
  ({
    onClose,
    onRetryFailedToDeliverPayment,
    onRetryFailedToCollectPayment,
    onRefundPayment,
    onVoidAndRefundPayment,
    onVoidAndResendPayment,
    onEditPayment,
    onMarkAsUnpaid,
    onApprovalDecision,
    onEditBillSubscription,
  }: PaymentDrawerActivityProps) => {
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();
    const { startAction, endAction } = useMonitoring<MonitoredAction>();
    const { paymentId } = useParams();
    const getPaymentsLateApprovalsEnabled = usePaymentsLateApprovalsEnabled();

    const shouldReturnFocus = useRef(!!document.activeElement && document.activeElement !== document.body);
    const [closed, setClosed] = useState(false);

    const billDetailsRef = useRef<HTMLDivElement>(null);
    const microDepositModalRef = useRef<MicroDepositModalRefHandlers>(null);

    const { data, isLoading: isPaymentLoading } = usePaymentDrawerData(paymentId);
    const { isLoading: isApprovalDecisionsLoading } = usePaymentApprovalDecisions({
      paymentId,
    });

    const payment = data as PaymentFullyExpanded;

    const isLoading = isPaymentLoading || isApprovalDecisionsLoading;

    const {
      isOpen: isDeclinePaymentModalOpen,
      onOpen: onDeclinePaymentModalOpen,
      onClose: onDeclinePaymentModalClose,
    } = useDisclosure();
    const [approvePaymentModalData, setApprovePaymentModalData] = useState<
      { approvedPayments: Payment[]; latePayments: Payment[]; fxPayments: Payment[] } | undefined
    >(undefined);

    const paymentActions = usePaymentActions(payment);

    usePaymentDrawerAnalytics({ payment });
    useEffect(() => {
      if (isLoading || !data) {
        return;
      }
      const paymentsLateApprovalsEnabled = getPaymentsLateApprovalsEnabled({ payments: [data] });
      const isLatePayment = paymentsLateApprovalsEnabled[data.id]?.isEnabled;
      track('Payment', 'View', { Intent: 'payment-details', LatePayment: isLatePayment });
    }, [isLoading, data]);

    const { batchApprovalDecision, isMutating } = useBatchPaymentApprovalDecision();

    const triggerClose = () => {
      setClosed(true);
    };

    const handleApprovalDecision = async (
      decision: PostApprovalDecisionEnum,
      overrides?: Record<Payment['id'], { quote?: FxQuote; date?: PaymentDate }>
    ) => {
      if (decision === PostApprovalDecisionEnum.Approved && paymentId) {
        startAction('payment_approve');
        try {
          const ids = [paymentId];
          const { approvedPayments, latePayments, fxPayments } = await batchApprovalDecision({
            payments: [payment],
            decision: PostApprovalDecisionEnum.Approved,
            overrides,
          });
          if (latePayments.length || fxPayments.length) {
            setApprovePaymentModalData({ approvedPayments, latePayments, fxPayments });
            endAction('payment_approve');
            return;
          }
          onApprovalDecision(ids, PostApprovalDecisionEnum.Approved);
          triggerClose();
          endAction('payment_approve');
        } catch (err) {
          track('Payment', 'Status', {
            Intent: 'payment-approval',
            Status: 'error',
          });
        }
      } else if (decision === PostApprovalDecisionEnum.Declined) {
        onDeclinePaymentModalOpen();
      }
    };

    const areActionsExist = !isEmpty(compact(Object.values(paymentActions.actions)));
    const shouldShowFooter = payment && areActionsExist;

    return (
      <>
        <PaymentDrawerContext.Provider
          value={{
            onRetryFailedToDeliverPayment,
            onRetryFailedToCollectPayment,
            onRefundPayment,
            onVoidAndRefundPayment,
            onVoidAndResendPayment,
            onEditPayment,
            onMarkAsUnpaid,
            onEditBillSubscription,
          }}
        >
          <Drawer
            data-testid="pay-dashboard-payments-tab-drawer"
            shouldReturnFocus={shouldReturnFocus.current}
            isOpen={!closed}
            aria-modal={false}
            onClose={triggerClose}
            onCloseComplete={onClose}
            closeButtonAriaLabel={formatMessage('activities.payDashboard.drawer.header.payment.closeButtonAriaLabel')}
            header={<Header />}
            body={
              paymentId ? (
                <Body
                  paymentId={paymentId}
                  billDetailsRef={billDetailsRef}
                  microDepositModalRef={microDepositModalRef}
                />
              ) : null
            }
            footer={
              shouldShowFooter ? (
                <>
                  <Footer
                    payment={payment}
                    onClose={triggerClose}
                    onApprovalDecision={handleApprovalDecision}
                    isMutating={isMutating}
                  />
                  {isDeclinePaymentModalOpen && paymentId && (
                    <PaymentApprovalWorkflowDeclineModalActivity
                      isOpen
                      paymentsIds={[paymentId]}
                      onClose={onDeclinePaymentModalClose}
                      onSuccess={() => {
                        triggerClose();
                        onApprovalDecision([paymentId], PostApprovalDecisionEnum.Declined);
                      }}
                    />
                  )}
                  {approvePaymentModalData && (
                    <PaymentsLateApprovalModalActivity
                      lateApprovalPayments={approvePaymentModalData.latePayments}
                      approvedPayments={approvePaymentModalData.approvedPayments}
                      fxPayments={approvePaymentModalData.fxPayments}
                      analytics={paymentLateModalAnalytics}
                      isOpen
                      isMutating={isMutating}
                      onClose={() => setApprovePaymentModalData(undefined)}
                      onSuccess={async ({ overrides }) => {
                        setApprovePaymentModalData(undefined);
                        await handleApprovalDecision(PostApprovalDecisionEnum.Approved, overrides);
                      }}
                    />
                  )}
                </>
              ) : null
            }
          />
        </PaymentDrawerContext.Provider>
        {payment?.fundingSource?.type === FundingSourceType.BankAccount && (
          <MicroDepositModalWrapper ref={microDepositModalRef} fundingSourceId={payment.fundingSourceId} />
        )}
      </>
    );
  }
);
