import { Form, FormSize, useBreakpointValue } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { FocusEventHandler, useEffect, useState } from 'react';
import { object, SchemaOf, string } from 'yup';

import { useVendorUniqueNameEnabled } from '../../../../../../../../hooks/useVendorUniqueNameEnabled.hook';
import { useVendorUniqueNameUpdate } from '../../../../../../../../hooks/useVendorUniqueNameUpdate.hook';
import { ZipCodeField } from '../../../../../../../form-controls';
import { useVendorForm } from '../../../../../../useVendorForm';
import {
  useAccountNumberSchema,
  useNicknameSchema,
  useUniqueNameSchema,
  useZipCodeSchema,
} from '../../../../../../VendorDetailsBaseSchema';
import { VendorNameInQBOModal } from '../../../../../../VendorNameInQBOModal';
import { AddVendorFormFields } from '../../../../types';
import { CompanyField } from '../../../CompanyField';
import { AddManagedVendorFormProps } from '../../types';
import { getCreateVendorPayload } from './utils';

export type AddFiservManagedVendorDetailsFormFields = Pick<
  AddVendorFormFields,
  'companyName' | 'nickname' | 'accountNumber' | 'confirmAccountNumber' | 'postalCode' | 'uniqueName'
>;

const useSchema = (isZipCodeNeeded?: boolean) => {
  const { formatMessage } = useMelioIntl();

  const accountNumberSchema = useAccountNumberSchema();
  const nicknameSchema = useNicknameSchema();
  const zipCodeSchema = useZipCodeSchema(isZipCodeNeeded);
  const uniqueNameSchema = useUniqueNameSchema({ isManaged: true });

  return object()
    .shape({
      companyName: string().required(formatMessage('widgets.vendors.companyName.validation.required')).nullable(),
    })
    .concat(zipCodeSchema)
    .concat(nicknameSchema)
    .concat(uniqueNameSchema)
    .concat(accountNumberSchema) as SchemaOf<AddFiservManagedVendorDetailsFormFields>;
};

export const AddFiservManagedVendorForm = ({
  onClearCompanyNameSearchField,
  inlineApiErrorCodes,
  bannerApiErrorCodes,
  onSelectCompanyName,
  onSubmit,
  onSubmissionStateChange,
  shouldHideCompanyNameField,
  managed,
  defaultValues,
  isVendorSelected,
  isSaving,
  titlesAs,
  ...props
}: AddManagedVendorFormProps) => {
  const { formatMessage } = useMelioIntl();
  const config = useConfig();
  const {
    shouldUseTooltipsForManagedVendorForm: shouldShowTooltips,
    shouldUseZipCodeMask: shouldShowZipCodeFieldTips,
  } = config.settings.vendor.forms;
  const isVendorUniqueNameEnabled = useVendorUniqueNameEnabled();
  const vendorUniqueNameUpdate = useVendorUniqueNameUpdate({
    isVendorUniqueNameEnabled,
  });

  const handleSubmit = (target?: string) => {
    const data = getValues();
    onSubmit(getCreateVendorPayload(data, managed), target);
    vendorUniqueNameUpdate.isOpen && vendorUniqueNameUpdate.onClose();
  };

  const formSize = useBreakpointValue<FormSize | undefined>({ xs: 'small', s: 'large' });
  const { formProps, registerField, setValue, reset, getValues } =
    useVendorForm<AddFiservManagedVendorDetailsFormFields>({
      inlineApiErrorCodes,
      bannerApiErrorCodes,
      onSubmit: (formData, _e, target) => {
        if (vendorUniqueNameUpdate.shouldShowModal(formData)) {
          vendorUniqueNameUpdate.onOpen();
        } else {
          handleSubmit(target);
        }
      },
      schema: useSchema(managed.isZipCodeNeeded),
      defaultValues,
      isSaving,
      onSubmissionStateChange,
    });

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    reset(defaultValues);
    setIsInitialized(true);
  }, [defaultValues, reset]);

  const handleBlurCompanyField: FocusEventHandler<HTMLInputElement> = (event) => {
    const companyName = event.target?.value;
    if (!companyName && isInitialized) {
      setValue('companyName', null);
      onClearCompanyNameSearchField?.();
    }
  };

  const handleClearCompanyField = () => {
    if (isInitialized) {
      setValue('companyName', null);
      onClearCompanyNameSearchField?.();
    }
  };

  return (
    <>
      <Form
        rowGap="m"
        columnGap="m"
        data-component="AddVendorFormWidget"
        {...props}
        {...formProps}
        onSubmit={(e) => {
          e.stopPropagation();
          formProps.onSubmit(e);
        }}
        columns={2}
        size={formSize}
      >
        {!shouldHideCompanyNameField && (
          <CompanyField
            colSpan={2}
            onSelectCompanyName={onSelectCompanyName}
            {...registerField('companyName')}
            isBusiness
            isVendorSelected={isVendorSelected}
            onBlur={handleBlurCompanyField}
            onClearSearchField={handleClearCompanyField}
            autoFocus
          />
        )}
        <Form.TextField
          colSpan={2}
          {...registerField('nickname')}
          labelProps={{ label: formatMessage('widgets.vendors.nickname.label') }}
          placeholder={formatMessage('widgets.vendors.nickname.placeholder')}
          autoFocus={shouldHideCompanyNameField}
        />
        <Form.TextField
          {...registerField('accountNumber')}
          data-private
          labelProps={{
            tooltipProps: shouldShowTooltips
              ? {
                  content: formatMessage('widgets.vendors.accountNumber.labelTooltip'),
                }
              : undefined,
            label: formatMessage('widgets.vendors.accountNumber.label'),
          }}
          helperTextProps={{
            label: formatMessage('widgets.vendors.accountNumber.helperText'),
          }}
        />
        <Form.TextField
          data-private
          {...registerField('confirmAccountNumber')}
          labelProps={{
            label: formatMessage('widgets.vendors.confirmAccountNumber.label'),
          }}
        />
        {managed.isZipCodeNeeded ? (
          <ZipCodeField
            colSpan={2}
            labelProps={{
              label: formatMessage('widgets.vendors.postalCode.label.managed'),
              tooltipProps: shouldShowTooltips
                ? {
                    content: formatMessage('widgets.vendors.postalCode.labelTooltip'),
                  }
                : undefined,
            }}
            {...registerField('postalCode')}
            placeholder={
              shouldShowZipCodeFieldTips ? formatMessage('widgets.vendors.postalCode.placeholder') : undefined
            }
            helperTextProps={
              shouldShowZipCodeFieldTips
                ? {
                    label: formatMessage('widgets.vendors.postalCode.description'),
                  }
                : undefined
            }
          />
        ) : null}
        <Form.TextField
          isHidden
          colSpan={2}
          {...registerField('uniqueName')}
          labelProps={{ label: formatMessage('widgets.vendorDetails.form.uniqueName.label') }}
          viewModePlaceholder={formatMessage('widgets.vendorDetails.form.uniqueName.viewModePlaceholder')}
          helperTextProps={{
            label: formatMessage('widgets.vendorDetails.form.uniqueName.unmanagedVendorHelperText'),
          }}
        />
      </Form>
      <VendorNameInQBOModal
        mode={vendorUniqueNameUpdate.mode}
        isManaged
        value={vendorUniqueNameUpdate.value}
        isLoading={vendorUniqueNameUpdate.isLoading || !!isSaving}
        isOpen={vendorUniqueNameUpdate.isOpen}
        onClose={vendorUniqueNameUpdate.onClose}
        setExternalFormValue={(value: string) => setValue('uniqueName', value)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

AddFiservManagedVendorForm.displayName = 'AddVendorFormWidget';
