import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useState } from 'react';

import { getVendorNameForNotificationMessage } from '../../../utils';
import { ShowInvalidBankAccountModal } from '../types';
import { InvalidVendorBankAccountDetails } from './types';

export const useInvalidBankAccountModal = () => {
  const [invalidBankAccountModalData, setInvalidBankAccountModalData] = useState<
    InvalidVendorBankAccountDetails | undefined
  >();
  const { showMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();

  const showInvalidBankAccountModal: ShowInvalidBankAccountModal = ({
    selectedVendor,
    submittedBankAccountNumber,
    vendorAccountNumber,
  }) => {
    setInvalidBankAccountModalData({
      vendorId: selectedVendor.id,
      vendorName: selectedVendor.name,
      fullVendorName: getVendorNameForNotificationMessage({
        ...selectedVendor,
        accountNumber: vendorAccountNumber,
      }),
      bankAccountNumber: submittedBankAccountNumber,
    });
  };

  const onInvalidVendorBankAccountModalClose = () => {
    setInvalidBankAccountModalData(undefined);
  };

  const onInvalidVendorBankAccountModalSuccess = (vendorName: string) => {
    showMessage({
      type: 'success',
      title: formatMessage('widgets.invalidVendorBankAccountModal.success', {
        vendorName,
      }),
    });

    onInvalidVendorBankAccountModalClose();
  };

  return {
    showInvalidBankAccountModal,
    invalidBankAccountModalData,
    onInvalidVendorBankAccountModalClose,
    onInvalidVendorBankAccountModalSuccess,
  };
};
