import { Vendor } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';
import { useState } from 'react';

import { useVendorDirectoryInfoComplete } from '../../../hooks/vendorDirectoryInfoComplete.hook';
import { MissingVendorInfoDrawerWidgetProps } from '../MissingVendorInfoDrawer';
import { ShowInvalidBankAccountModal } from '../types';

export const useMissingVendorInfo = ({
  selectedVendor,
  showInvalidBankAccountModal,
  onDoneAnalytics,
  onCloseAnalytics,
}: {
  selectedVendor?: Vendor;
  showInvalidBankAccountModal: ShowInvalidBankAccountModal;
  onDoneAnalytics: VoidFunction;
  onCloseAnalytics: VoidFunction;
}) => {
  const [missingVendorInfoDrawerVisible, setMissingVendorInfoDrawerVisible] = useState(false);
  const [isInvalidBankAccountModalEnabled] = usePartnerFeature('InvalidVendorBankAccountModal', false);

  const isVendorDirectoryInfoCompleted = useVendorDirectoryInfoComplete(selectedVendor);

  const shouldShowMissingVendorInfoBanner = !isVendorDirectoryInfoCompleted && !!selectedVendor;
  const shouldShowMissingVendorInfoDrawer = missingVendorInfoDrawerVisible && !!selectedVendor;

  const handleMissingInfoVendorDrawerClose = () => {
    setMissingVendorInfoDrawerVisible(false);
    onCloseAnalytics();
  };

  const handleMissingInfoVendorDrawerDone: MissingVendorInfoDrawerWidgetProps['onDone'] = ({
    isBankAccountNotCreated,
    submittedBankAccountNumber,
    vendorAccountNumber,
  }) => {
    setMissingVendorInfoDrawerVisible(false);

    const shouldShowInvalidBankAccountModal =
      isInvalidBankAccountModalEnabled && isBankAccountNotCreated && submittedBankAccountNumber && selectedVendor;

    if (shouldShowInvalidBankAccountModal) {
      showInvalidBankAccountModal({
        selectedVendor,
        submittedBankAccountNumber,
        vendorAccountNumber,
      });
    }

    onDoneAnalytics();
  };

  const showMissingVendorInfoDrawer = () => setMissingVendorInfoDrawerVisible(true);

  return {
    shouldShowMissingVendorInfoBanner,
    shouldShowMissingVendorInfoDrawer,
    handleMissingInfoVendorDrawerDone,
    handleMissingInfoVendorDrawerClose,
    showMissingVendorInfoDrawer,
  };
};
