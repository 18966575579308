import { FormattedMessage } from '@melio/ar-domain';
import { Group, Illustration, Text } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { BaseLayout } from '../../../layout';
import { ErrorType } from '../../../types';

export type GuestPaymentRequestErrorScreenProps = {
  errorType?: ErrorType;
  isLoading?: boolean;
};

export const GuestPaymentRequestErrorScreen = forwardRef<GuestPaymentRequestErrorScreenProps>(
  ({ errorType, isLoading, ...props }, ref) => {
    useAnalyticsView(
      'PaymentRequest',
      !isLoading,
      true,
      errorType == 'invoice-cancelled'
        ? { Intent: 'pay-invoice', PageName: 'payment-request-cancelled' }
        : { Intent: 'pay-invoice', PageName: 'network-error', ErrorType: errorType, Status: 'failure' }
    );

    return (
      <BaseLayout
        data-component={GuestPaymentRequestErrorScreen.displayName}
        isLoading={isLoading}
        data-error-type={errorType}
        data-testid="guest-payment-request-error-screen"
        {...props}
        ref={ref}
      >
        <Group variant="vertical" spacing="s" alignItems="flex-start">
          <Illustration type={errorType === 'invoice-cancelled' ? 'declined' : 'network-error'} />
          <Group variant="vertical" spacing="none">
            <Text textStyle="heading3Semi">
              <FormattedMessage
                id={
                  errorType == 'invoice-cancelled'
                    ? 'ar.guestPayment.errors.invoiceCancelled.title.text'
                    : 'ar.guestPayment.errors.generalMessage.title.text'
                }
              />
            </Text>
            <Text textStyle="body3">
              <FormattedMessage
                id={
                  errorType == 'invoice-cancelled'
                    ? 'ar.guestPayment.errors.invoiceCancelled.description.text'
                    : 'ar.guestPayment.errors.generalMessage.title.description'
                }
              />
            </Text>
          </Group>
        </Group>
      </BaseLayout>
    );
  }
);

GuestPaymentRequestErrorScreen.displayName = 'GuestPaymentRequestErrorScreen';
