import { BaseModal, Container, Grid, Group, Text } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { usePermissions } from '@melio/platform-permissions';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Plan } from '../../../api';
import { SubscriptionContextualPaywallProps, useSubscriptionContext } from '../../../context';
import {
  useIsFirmOnClientOrganization,
  useIsSubscriptionBillingPayorIsFirm,
  usePartnerGroups,
  usePlanInfo,
  usePlansTiers,
  useSubscription,
} from '../../../hooks';
import { RequestPlanChangeFromAccountantModalScreen } from '../RequestPlanChangeFromAccountant';
import { RequestSubscriptionUpgradeModal } from '../RequestSubscriptionUpgradeModal';
import { SubscriptionUpgradeModalActivity } from '../SubscriptionUpgradeModal';
import { ContextualPlanCard } from './components';
import { useContextualPlans } from './hooks/useContextualPlans';

type ContextualPaywallModalProps = SubscriptionContextualPaywallProps & {
  handleUpgrade: (newPlanId: Plan['id']) => void;
  handleDismiss: VoidFunction;
};

const ContextualPaywallModalScreen = withAnalyticsContext<ContextualPaywallModalProps>(
  ({ handleUpgrade, handleDismiss, setAnalyticsProperties, ...feature }) => {
    const contextualPlans = useContextualPlans(feature);
    const subscription = useSubscription();
    const { isFiservPartner } = usePartnerGroups();
    const { planName: upgradePlanName } = usePlanInfo(contextualPlans.upgradePlan.id);
    const titleAs = contextualPlans.description ? 'h4' : 'h3';

    const { track } = useAnalytics();
    setAnalyticsProperties({
      PageName: 'plan-selection',
      Flow: 'subscription',
    });

    useEffect(() => {
      track('Organization', 'View', {
        Intent: 'choose-plan',
        PlanId: subscription?.planId || 'post-onboarding',
        CurrentPlan: 'post-onboarding',
        EntryPoint: feature.featureName,
      });
    }, [track, feature.featureName, subscription?.planId]);

    const onUpgrade = () => {
      const planId = contextualPlans.upgradePlan.id;

      track('Organization', 'Click', {
        Intent: 'subscribe',
        Cta: 'subscribe',
        PlanChosenId: planId,
        PlanChosen: upgradePlanName,
      });

      handleUpgrade(planId);
    };

    const onDismiss = () => {
      track('Organization', 'Status', {
        PageName: 'plan-selection',
        Intent: subscription ? 'exit' : 'free-tier',
        Flow: 'exit-subscription',
        Cta: 'exit',
        Plan: subscription ? undefined : 'free-tier',
      });

      handleDismiss();
    };

    const CurrentPlanCard = () => (
      <ContextualPlanCard
        isCurrentPlan
        upsellContext={contextualPlans.currentContextualPlan}
        onClick={onDismiss}
        plan={contextualPlans.currentPlan}
        titleAs={titleAs}
      />
    );

    const UpgradePlanCard = () => (
      <ContextualPlanCard
        upsellContext={contextualPlans.upgradeContextualPlan}
        onClick={onUpgrade}
        plan={contextualPlans.upgradePlan}
        ariaHasPopup={isFiservPartner && ('dialog' as const)}
        titleAs={titleAs}
      />
    );

    return (
      <BaseModal isOpen onClose={handleDismiss} size="medium" data-testid="contextual-paywall-modal-screen">
        <BaseModal.Header>
          <Group variant="vertical" spacing="xs">
            <Text textStyle="heading2Semi" as="h2">
              {contextualPlans.title}
            </Text>
            {contextualPlans.description && (
              <Text textStyle="body3" color="global.neutral.900" as="h3">
                {contextualPlans.description}
              </Text>
            )}
          </Group>
        </BaseModal.Header>
        <BaseModal.Body>
          <Container paddingBottom="m" paddingTop="none" overflow="auto">
            <Grid gap="s" gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))">
              <CurrentPlanCard />
              <UpgradePlanCard />
            </Grid>
          </Container>
        </BaseModal.Body>
      </BaseModal>
    );
  }
);

export const ContextualPaywallModalActivity = () => {
  const {
    contextualPaywall: { paywallProps, hidePaywall },
  } = useSubscriptionContext();
  const { isFiservPartner } = usePartnerGroups();

  const [upgradeModalPlanId, setUpgradeModalPlanId] = useState<Plan['id']>();

  const { can } = usePermissions();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const subscription = useSubscription();
  const { getFirstPlanWithFeature } = usePlansTiers();

  const isSubscriptionPaidByFirm = useIsSubscriptionBillingPayorIsFirm();
  const { isLoading: isLoadingIsFirmOnClientOrganization, isFirmOnClientOrganization } =
    useIsFirmOnClientOrganization();

  if (!paywallProps) {
    return null;
  }

  const handleDismiss = () => {
    hidePaywall();
    paywallProps.onDismiss?.();
  };

  const handleUpgrade = (newPlanId: Plan['id']) => {
    if (isFiservPartner) {
      return setUpgradeModalPlanId(newPlanId);
    }

    hidePaywall();

    navigate(
      { pathname: '/subscription/checkout', search: `plan=${newPlanId}` },
      { state: { returnUrl: paywallProps.returnUrl || pathname } }
    );
  };

  const canUpdateSubscription = can({
    subject: 'subscriptions:me',
    action: 'update',
  });

  if (!canUpdateSubscription) {
    return <RequestSubscriptionUpgradeModal isOpen onDismiss={handleDismiss} />;
  }

  if (isSubscriptionPaidByFirm && isLoadingIsFirmOnClientOrganization) {
    return <BaseModal isLoading isOpen onClose={handleDismiss} data-testid="loading-modal" />;
  }

  const isClientWithPlanManagedByFirm = isSubscriptionPaidByFirm && !isFirmOnClientOrganization;

  if (isClientWithPlanManagedByFirm) {
    return <RequestPlanChangeFromAccountantModalScreen isOpen onClose={handleDismiss} />;
  }

  if (upgradeModalPlanId && subscription?.planId) {
    return (
      <SubscriptionUpgradeModalActivity
        isOpen
        planUpgradeProps={{
          billingCycle: 'monthly',
          returnUrl: pathname,
          planId: upgradeModalPlanId,
          currentPlanId: subscription?.planId,
        }}
        onDismiss={paywallProps.onDismiss}
        onClose={() => {
          setUpgradeModalPlanId(undefined);
          hidePaywall();
        }}
      />
    );
  }

  if (subscription?.isFreeTrial) {
    const firstPlanWithFeature = getFirstPlanWithFeature(paywallProps);

    return (
      <SubscriptionUpgradeModalActivity
        isOpen
        planUpgradeProps={{
          billingCycle: 'monthly',
          returnUrl: pathname,
          planId: firstPlanWithFeature.id,
          currentPlanId: subscription?.planId,
        }}
        onDismiss={paywallProps.onDismiss}
        onClose={hidePaywall}
      />
    );
  }

  return <ContextualPaywallModalScreen handleDismiss={handleDismiss} handleUpgrade={handleUpgrade} {...paywallProps} />;
};
