import { useMelioIntl, useServiceChargeCatalogs, useSystemMessage } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { ServiceChargeFormDialogScreen, ServiceChargeFormFields } from './screens';

type AddServiceChargeDialogActivityProps = {
  onDone: (id: string) => unknown;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
};

export const AddServiceChargeDialogActivity = forwardRef<AddServiceChargeDialogActivityProps>(
  ({ onDone: onSuccess, onClose, onError, ...props }, ref) => {
    const { create, isCreating } = useServiceChargeCatalogs({ enabled: false });

    const { formatMessage } = useMelioIntl();
    const { triggerMessage } = useSystemMessage();

    const onSubmit = (values: ServiceChargeFormFields) =>
      create(values)
        .then((serviceCharge) => {
          onSuccess(serviceCharge.id);
          triggerMessage({
            type: 'success',
            title: formatMessage('ar.settings.activities.addServiceCharge.toast.onAdd.label', {
              name: serviceCharge.name,
            }),
          });
        })
        .catch(onError);

    return (
      <ServiceChargeFormDialogScreen
        data-testid="add-service-charge-dialog"
        isOpen
        variant="add"
        isSaving={isCreating}
        onClose={onClose}
        onSubmit={onSubmit}
        {...props}
        ref={ref}
      />
    );
  }
);
AddServiceChargeDialogActivity.displayName = 'AddServiceChargeActivity';
