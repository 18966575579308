import React from 'react';
import { useNavigate } from 'react-router-dom';

import { routes } from './routes';

export const useAccountantsRoutes = () => {
  const navigate = useNavigate();

  return React.useMemo(
    () => ({
      goToClientCreationOptions: () => navigate(routes.CREATION_OPTIONS),
      goToAddNewClient: () => navigate(routes.ADD_NEW_CLIENT),
      goToInvitationSent: () => navigate(routes.INVITATION_SENT, { replace: true }),
      goToInviteExistingClient: () => navigate(routes.INVITE_EXISTING_CLIENT),
      goToClientCreatedSuccessScreen: (state: { invited: boolean; company: string; orgId: string }) => {
        const params = new URLSearchParams({ ...state, invited: JSON.stringify(state.invited) });
        return navigate(`${routes.CLIENT_CREATED}?${params.toString()}`);
      },
      goToAssignClientPlan: ({ accountingClientId, refresh }: { accountingClientId: string; refresh?: boolean }) => {
        navigate(routes.getClientAssignPlanRoute(accountingClientId));
        if (refresh) {
          navigate(0);
        }
      },
      goToAssignClientPlanSuccessScreen: ({
        accountingClientId,
        activated,
      }: {
        accountingClientId: string;
        activated: boolean;
      }) => {
        const params = new URLSearchParams({ activated: JSON.stringify(activated) });
        return navigate(`${routes.getClientAssignPlanSuccessRoute(accountingClientId)}?${params.toString()}`);
      },
      goToClientAccountingSoftwareRedirect: (orgId: string | null) => {
        const params = new URLSearchParams({ orgId: orgId || '' });
        return navigate(`${routes.CLIENT_ACCOUNTING_SOFTWARE_REDIRECT}?${params.toString()}`);
      },
      goToSupportPage: () => navigate('/settings/support'),
      goToBillingMethodSetting: () => navigate('/settings/billing'),
      goToAddBankAccount: ({ returnUrl }: { returnUrl: string }) =>
        navigate(`/payment-methods/bank/select?integrationType=plaid&returnUrl=${encodeURIComponent(returnUrl)}`),
      goToAddCard: ({ returnUrl }: { returnUrl: string }) =>
        navigate('/payment-methods/card', { state: { returnUrl } }),
    }),
    [navigate]
  );
};
