import { Box, Flex } from '@chakra-ui/react';
import { IconButton, Text, Theme, useTheme } from '@melio/penny';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';

import { UploadFile } from '../scheduled/types';

type DocumentsUploadIndicatorTitleProps = {
  onToggle: () => void;
  onClose: () => void;
  isMinimized: boolean;
  files: UploadFile[];
};

export const DocumentsUploadIndicatorTitleComponent = ({
  onToggle,
  onClose,
  isMinimized,
  files,
}: DocumentsUploadIndicatorTitleProps) => {
  const { formatMessage } = useMelioIntl();
  const style = useTheme();

  const isUploading = files.some((file) => file.status === 'processing');

  const getTitleText = (): MessageKey => {
    let id = 'uploads';

    if (isUploading) {
      id = 'uploading';
    }

    return `widgets.documentsUploadIndicator.${id}` as MessageKey;
  };

  return (
    <Box
      data-testid="documents-upload-indicator-title"
      sx={documentUploadIndicatorTitleTheme(style).baseStyle.uploadIndicatorTitle}
      justifyContent="space-between"
    >
      <Text>{formatMessage(getTitleText(), { count: files.length })}</Text>
      <Flex direction="row" alignItems="center">
        <IconButton
          icon={isMinimized ? 'chevron-up' : 'chevron-down'}
          variant="naked"
          onClick={onToggle}
          aria-label="button"
          size="large"
        />
        <IconButton icon="close" variant="naked" onClick={onClose} aria-label="button" size="small" />
      </Flex>
    </Box>
  );
};

const documentUploadIndicatorTitleTheme = (currentTheme: Theme) => ({
  baseStyle: {
    uploadIndicatorTitle: {
      height: '56px',
      width: '100%',
      padding: '16px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: currentTheme.colors.global.neutral['100'],

      p: {
        width: '100%',
      },
      button: {
        color: currentTheme.colors.global.neutral['1000'],
        marginLeft: '15px',
      },
    },
  },
});
