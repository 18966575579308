import {
  PostPlaidLinkTokenRegistrationRequest,
  PostPlaidLinkTokenVerificationRequest,
  useDeliveryMethodPlaidLinkToken,
  useFundingSourcePlaidLinkToken,
} from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { useEffect } from 'react';

export const usePlaidLinkToken = ({
  fundingSourceId,
  deliveryMethodId,
  options,
}: {
  deliveryMethodId?: string;
  fundingSourceId?: string;
  options?: { isReceivingMethodFlow?: boolean; shouldAllowNonInstantVerification?: boolean };
}) => {
  const {
    settings: { partnerProductName },
  } = useConfig();
  const { isReceivingMethodFlow, shouldAllowNonInstantVerification } = options || {};
  const params = fundingSourceId
    ? ({
        type: 'verification',
        details: { fundingSourceId },
      } as PostPlaidLinkTokenVerificationRequest)
    : ({
        type: 'registration',
        details: { allowNonInstant: shouldAllowNonInstantVerification, partnerProductName },
      } as PostPlaidLinkTokenRegistrationRequest);

  const {
    data: fundingSourceTokenLink,
    remove,
    error: fundingSourceError,
    isLoading: isFundingSourceTokenLoading,
  } = useFundingSourcePlaidLinkToken({
    params,
    enabled: !isReceivingMethodFlow,
  });

  const {
    data: deliveryMethodTokenLink,
    error: deliveryMethodsError,
    mutate: generateDeliveryMethodTokenLink,
    isLoading: isDeliveryMethodTokenLoading,
  } = useDeliveryMethodPlaidLinkToken();

  useEffect(() => {
    const requestType = deliveryMethodId ? 'verification' : 'registration';
    const requestDetails =
      requestType === 'registration' ? { allowNonInstant: shouldAllowNonInstantVerification } : { deliveryMethodId };
    if (isReceivingMethodFlow) {
      void generateDeliveryMethodTokenLink({
        type: requestType,
        details: requestDetails,
      });
    }
  }, [deliveryMethodId, generateDeliveryMethodTokenLink, isReceivingMethodFlow, shouldAllowNonInstantVerification]);

  useEffect(() => remove, [remove]);
  return {
    linkError: fundingSourceError?.message || deliveryMethodsError?.message,
    data: fundingSourceTokenLink || deliveryMethodTokenLink?.data,
    isLoading: isFundingSourceTokenLoading || isDeliveryMethodTokenLoading,
  };
};
