import { TabsProps } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';

import { useIsMobile } from '../../../utils/viewport-utils/useIsMobile';
import { PayDashboardTabs } from '../types';
import { usePayDashboardTabIndication } from './usePayDashboardTabIndication';
import { usePayDashboardTabsToShow } from './usePayDashboardTabsToShow';

const tabsIdPrefix = 'pay-dashboard-tab-';

const tabsOrder = [
  PayDashboardTabs.Vendors,
  PayDashboardTabs.Bills,
  PayDashboardTabs.Approvals,
  PayDashboardTabs.Payments,
  PayDashboardTabs.Financing,
];

export const usePayDashboardTabs = () => {
  const { formatMessage } = useMelioIntl();
  const resolvedPathUrl = useResolvedPath('');
  const match = useMatch({ path: `${resolvedPathUrl.pathname}/:tab/*`, end: true });
  const tab = match?.params.tab as PayDashboardTabs;
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const isMobile = useIsMobile();
  const { shouldShow, isLoading } = usePayDashboardTabsToShow();
  const indication = usePayDashboardTabIndication(tab);

  const tabs: TabsProps['tabs'] = tabsOrder
    .filter((tab) => shouldShow[tab])
    .map((tab) => {
      const badges = isMobile ? indication.counters[tab] : indication.badges[tab];
      const tabLabel = formatMessage(`activities.payDashboard.tabs.${tab}`);

      return {
        label: tabLabel,
        name: tab,
        pills: badges,
        'data-tourid': `pay-dashboard-tab-${tab}`,
        id: `${tabsIdPrefix}${tab}`,
      };
    });

  const handleTabChange = (tab: string) => {
    track('Dashboard', 'Click', { Cta: tab });
    navigate(tab);
  };

  return { tabs, handleTabChange, selectedTab: tab, shouldShow, isLoading, tabsIdPrefix };
};
