import { Container, Group, Illustration, Layout, Text } from '@melio/penny';

export type UnderMaintenancePageParams = {
  title: string;
  message?: string;
};

export const UnderMaintenancePage = ({ title, message }: UnderMaintenancePageParams) => {
  return (
    <Layout maxWidth="600px">
      <Group height="full" data-testid="under-maintenance" spacing="xxl" variant="vertical" justifyContent="center">
        <Container justifyContent="center">
          <Illustration size="large" type="page-not-found" />
        </Container>
        <Container justifyContent="center">
          <Group variant="vertical" textAlign="center" spacing="m">
            <Text data-testid="under-maintenance-title" textStyle="display2Semi">
              {title}
            </Text>
            <Text data-testid="under-maintenance-message" textStyle="body1">
              {message}
            </Text>
          </Group>
        </Container>
      </Group>
    </Layout>
  );
};
