import { Container, Group, IconButton, Tooltip, useBreakpoint } from '@melio/penny';
import { AccountingPlatform } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { PageTitle } from '@melio/platform-utils';

import { useActivitiesNavigate } from '../../../../utils';
import { BillsTabProps } from '../BillsTab/BillsTab';
import { MobileActionsMenuNBE } from '../MobileActionsMenuNBE/MobileActionsMenuNBE';
import { SchedulePaymentMainButton } from './SchedulePaymentMainButton';
import { SyncNowButton } from './SyncNowButton';
import { TodosWidgetButton } from './TodosWidgetButton';

type Props = {
  accountingPlatform?: AccountingPlatform;
  analyticsProperties: Record<string, string>;
  onClickSchedulePayment: VoidFunction;
  goToSettingsAccountSoftware: VoidFunction;
  onAddNewPayment: VoidFunction;
  onAddNewBillUpload: BillsTabProps['onAddNewBillUpload'];
  onAddNewBillManual: BillsTabProps['onAddNewBillManual'];
  onAccountingPlatformConnect: BillsTabProps['onAccountingPlatformConnect'];
};

export const DashboardHeader = ({
  accountingPlatform,
  analyticsProperties,
  onClickSchedulePayment,
  goToSettingsAccountSoftware,
  onAddNewPayment,
  onAddNewBillUpload,
  onAddNewBillManual,
  onAccountingPlatformConnect,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const [isSchedulePaymentCTAEnabled] = useDevFeature<boolean>(FeatureFlags.NewDashboardSchedulePaymentCTA, false);
  const [isAccountingSoftwareEnabled] = useDevFeature(FeatureFlags.IsAccountingSoftwareEnabled, false);
  const { navigateToSettingsPage } = useActivitiesNavigate();
  const { isExtraSmallScreen } = useBreakpoint();
  const {
    settings: { isEmbeddedExperience, hideHeaderNavigation },
  } = useConfig();

  const renderTodosWidgetButton = () => <TodosWidgetButton />;

  const renderSyncNowButton = () =>
    isAccountingSoftwareEnabled ? (
      <SyncNowButton
        accountingPlatform={accountingPlatform}
        analyticsProperties={analyticsProperties}
        goToSettingsAccountSoftware={goToSettingsAccountSoftware}
      />
    ) : null;

  const renderPaymentButton = () => {
    if (isExtraSmallScreen) {
      return (
        <MobileActionsMenuNBE
          onAddNewPayment={onAddNewPayment}
          onAddNewBillUpload={onAddNewBillUpload}
          onAddNewBillManual={onAddNewBillManual}
          onAccountingPlatformConnect={onAccountingPlatformConnect}
        />
      );
    }
    if (isSchedulePaymentCTAEnabled) {
      return <SchedulePaymentMainButton onClick={onClickSchedulePayment} />;
    }

    return null;
  };

  const renderSettingsButton = () => {
    if (isEmbeddedExperience && hideHeaderNavigation) {
      return (
        <Tooltip content={formatMessage('activities.payDashboard.header.nav.settings.label')}>
          <IconButton
            aria-label={formatMessage('activities.payDashboard.header.nav.settings.label')}
            variant={isExtraSmallScreen ? 'naked' : 'primary'}
            size="large"
            icon="settings"
            data-testid="settings-button"
            onClick={navigateToSettingsPage}
          />
        </Tooltip>
      );
    }
    return null;
  };
  return (
    <Container
      paddingTop={isExtraSmallScreen ? (isEmbeddedExperience ? 'none' : 'm') : undefined}
      paddingX={isExtraSmallScreen ? 'm' : undefined}
      overflow="initial"
    >
      <Group justifyContent="space-between" spacing="m">
        <PageTitle textStyle={isExtraSmallScreen ? 'heading2Semi' : 'heading1Semi'}>
          {formatMessage('activities.payDashboard.title')}
        </PageTitle>
        <Group variant="horizontal" spacing={isExtraSmallScreen ? 'xs' : 's'} alignItems="center">
          {renderTodosWidgetButton()}
          {renderSyncNowButton()}
          {renderSettingsButton()}
          {renderPaymentButton()}
        </Group>
      </Group>
    </Container>
  );
};
