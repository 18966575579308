import { object, SchemaOf } from 'yup';

import { useInternationalAddressSchema } from '../../../../international-address';
import { VendorDetailsFormFields, VendorDetailsRequiredFormField } from '../types';
import { useFieldsSchemas } from './useFieldsSchemas.hook';

export const useSchema = (
  requiredFields: { [K in VendorDetailsRequiredFormField]: boolean },
  mccGroupsSupported: boolean
) => {
  const schemas = useFieldsSchemas(mccGroupsSupported);
  const { addressSchema } = useInternationalAddressSchema();

  return object().shape({
    ...(requiredFields.mcc && mccGroupsSupported ? { group: schemas.group } : {}),
    ...(requiredFields.mcc ? { mcc: schemas.mcc } : {}),
    ...(requiredFields.email ? { email: schemas.email } : {}),
    ...(requiredFields.phone ? { phone: schemas.phone } : {}),
    ...(requiredFields.address
      ? {
          address: addressSchema,
        }
      : {}),
  }) as SchemaOf<VendorDetailsFormFields>;
};
