import { Box, useBoolean } from '@chakra-ui/react';
import { LoadingContainer } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

export type FiservPaymentWidgetProps = {
  paymentWidgetUrl?: string;
};

export const FiservPaymentWidget = forwardRef<FiservPaymentWidgetProps>(({ paymentWidgetUrl, ...props }, ref) => {
  const [isLoaded, loadedState] = useBoolean(false);
  useEffect(() => loadedState.off(), [loadedState, paymentWidgetUrl]);
  return (
    <LoadingContainer isLoading={!paymentWidgetUrl || !isLoaded} {...props} ref={ref}>
      {paymentWidgetUrl ? <Box as="iframe" src={paymentWidgetUrl} onLoad={loadedState.on} /> : <></>}
    </LoadingContainer>
  );
});

FiservPaymentWidget.displayName = 'FiservPaymentWidget';
