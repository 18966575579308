import { Icon, Popover, StatusIconSolid, Tooltip } from '@melio/penny';

import { GetFundingSourceStatusIconResult } from '../../PaymentIntentsTable.screen.utils';

const FundingSourceIconRenderer = ({
  statusIconProps,
  testId,
}: {
  statusIconProps: GetFundingSourceStatusIconResult;
  testId: string;
}) => {
  if (statusIconProps.iconProps) {
    return <Icon data-testid={testId} {...statusIconProps.iconProps} />;
  }
  return <StatusIconSolid data-testid={testId} {...statusIconProps.statusIconSolidProps} />;
};

export const FundingSourceStatusIcon = ({ statusIconProps }: { statusIconProps: GetFundingSourceStatusIconResult }) => {
  const { popoverProps, tooltipProps } = statusIconProps;
  return (
    <>
      {popoverProps && (
        <Popover {...popoverProps}>
          <FundingSourceIconRenderer statusIconProps={statusIconProps} testId="funding-source-select-popover-icon" />
        </Popover>
      )}
      {tooltipProps && (
        <Tooltip {...tooltipProps} shouldAddTriggerFocus>
          <FundingSourceIconRenderer statusIconProps={statusIconProps} testId="funding-source-select-tooltip-icon" />
        </Tooltip>
      )}
    </>
  );
};
