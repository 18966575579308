/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import { Box, useDisclosure } from '@chakra-ui/react';
import { isFXCurrency, isVendorDirectoryDetailsCompleted, useNavigationWithQueryParams } from '@melio/ap-domain';
import { getVendorNameForNotificationMessage } from '@melio/ap-widgets';
import { Button, Container, Group, Icon, SortingState, useBreakpoint, useTable } from '@melio/penny';
import { OriginFlow, useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useVendorGroups, Vendor, VendorGroup } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { useSystemMessage } from '@melio/platform-utils';
import { useSubscriptionFeature } from '@melio/subscriptions';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useActivitiesNavigate } from '../../../../utils';
import { useRecommendedVendors } from '../../../recommended-vendors';
import { CreateVendorsGroupModal, DeleteVendorsGroupModal, EditVendorsGroupModal } from '../../../vendor-groups';
import { ROW_HEIGHT } from '../../consts';
import { emitFocusEvent, FocusEvents } from '../../FocusSkipToComponent';
import { useLoadingState } from '../../hooks/useLoadingState';
import { useSearchTerm } from '../../hooks/useSearchTerm';
import { PayDashboardSortingProvider, usePayDashboardSorting } from '../../PayDashboardSortingProvider';
import { PayDashboardTabs } from '../../types';
import { APTable } from '../APTable';
import { MobileSortMenu } from '../MobileSortMenu/MobileSortMenu';
import { PayDashboardPagination, PayDashboardPaginationProvider, usePayDashboardPagination } from '../Pagination';
import { EmptySearchResult, SearchBar } from '../Search';
import { DeleteVendorModal } from './components/DeleteVendor/DeleteVendorModal';
import { VendorGroupEmptyState } from './components/VendorGroupEmptyState';
import { ALL_VENDORS_GROUP_ID } from './components/VendorGroupsFilter/types';
import { VendorGroupFilterContainer } from './components/VendorGroupsFilter/VendorGroupFilterContainer';
import { VendorGroupFilterMobile } from './components/VendorGroupsFilter/VendorGroupFilterMobile';
import { VendorsBatchPaymentsModalActivity } from './components/VendorsBatchPaymentsModalActivity/VendorsBatchPaymentsModal-activity';
import { VendorsSelectionFooter } from './components/VendorsSelectionFooter';
import { VendorsTabEmptyState } from './components/VendorsTabEmptyState';
import { VendorsTabListItem } from './components/VendorsTabListItem';
import { usePayVendor } from './hooks/usePayVendor';
import { CELLS_IDS, VendorsTableMeta, VendorTabFilters } from './types';
import { useVendorsTabAnalytics } from './useVendorsTabAnalytics';
import { useVendorsTabData } from './useVendorsTabData';
import { useVendorsTableColumns } from './useVendorsTableColumns';
import { Selection, useVendorsTabSelection } from './useVendorsTabSelection';
import { useVendorsTabSortableColumns } from './useVendorsTabSortableColumns';
import { useAriaLabelContextForVendor } from './utils';

export type VendorsTabProps = {
  onAddNewPayment: (params?: { vendorId?: Vendor['id']; amount?: string; returnUrl?: string }) => void;
  onAddNewBill: (params: {
    returnUrl: string;
    vendorId?: Vendor['id'];
    amount?: string;
    originFlow?: OriginFlow;
  }) => void;
};

export const VENDORS_TAB_DEFAULT_SORT: SortingState = { id: CELLS_IDS.LAST_PAID, sortDirection: 'desc' };

export const VendorsTabComponent = withAnalyticsContext(({ onAddNewPayment, onAddNewBill }: VendorsTabProps) => {
  const { searchTerm } = useSearchTerm();
  const { isExtraSmallScreen } = useBreakpoint();
  const { getAriaLabelContextForVendor } = useAriaLabelContextForVendor();
  const [isVendorGroupsEnabled] = usePartnerFeature('VendorGroups', false);
  const [isMobileSortEnabled] = useDevFeature<boolean>(FeatureFlags.NpeSortMobile, false);
  const [isVendorsBatchPaymentsEnabled] = useDevFeature<boolean>(
    FeatureFlags.PlatformVendorsBatchNewPaymentsEnabled,
    false,
    {
      shouldTrack: true,
    }
  );
  const [isPlatformBatchPaymentsEnabled] = useDevFeature<boolean>(FeatureFlags.PlatformBatchPayments, false);

  const [isCollectMissingVendorInfoEnabled] = useDevFeature<boolean>(
    FeatureFlags.CollectMissingVendorInfoEnabled,
    false
  );
  const {
    settings: {
      vendor: { requiredDirectoryFields },
      payDashboardSearchLabelDisabled,
    },
  } = useConfig();
  const { navigateToNewVendor } = useActivitiesNavigate();
  const { sortableColumns: vendorsTabSortableItems } = useVendorsTabSortableColumns();
  const sorting = usePayDashboardSorting();
  const { showMessage } = useSystemMessage();
  const { resetToFirstPage } = usePayDashboardPagination();
  const [selectedVendorGroupIds, setSelectedVendorGroupIds] = useState<
    NonNullable<VendorTabFilters['vendorsGroupIds']>
  >([]);
  const [editedVendorsGroupId, setEditedVendorsGroupId] = useState<string>();
  const [vendorsGroupIdToDelete, setVendorsGroupIdToDelete] = useState<string>();
  const { unpaidBills, ...paginationResponse } = useVendorsTabData({
    searchTerm,
    selectedVendorGroupIds,
    sorting: sorting?.sortingState || null,
    enabled: !!sorting?.isLoaded,
  });
  const {
    data: vendorsResult,
    isLoading: isLoadingVendors,
    isFetched: isVendorsFetched,
    isFetching,
    isPreviousData,
  } = paginationResponse;
  const { data: vendorGroups } = useVendorGroups({ enabled: isVendorGroupsEnabled && isExtraSmallScreen });
  const { data: vendors = [], pagination } = vendorsResult ?? {};
  const { isLoading: isRecommendedVendorsLoading } = useRecommendedVendors({
    shouldFetch: isVendorsFetched && !vendors.length,
  });
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();
  const {
    isOpen: isVendorsBatchPaymentsAmountsModalOpen,
    onOpen: onVendorsBatchPaymentsAmountsModalOpen,
    onClose: onVendorsBatchPaymentsAmountsModalClose,
  } = useDisclosure();

  const {
    isOpen: isCreateVendorGroupsModalOpen,
    onOpen: onOpenCreateVendorGroupModal,
    onClose: onCloseCreateVendorGroupsModal,
  } = useDisclosure();

  const {
    isOpen: isEditVendorGroupsModalOpen,
    onOpen: onOpenEditVendorGroupModal,
    onClose: onCloseEditVendorGroupsModal,
  } = useDisclosure();

  const {
    isOpen: isDeleteVendorGroupsModalOpen,
    onOpen: onOpenDeleteVendorGroupModal,
    onClose: onCloseDeleteVendorGroupsModal,
  } = useDisclosure();

  const { pathname } = useLocation();
  const { formatMessage } = useMelioIntl();
  const { handleNavigationWithQueryParams } = useNavigationWithQueryParams();
  const { routeReady } = useMonitoring();
  const { tryUseFeature: tryUseBatchPayments } = useSubscriptionFeature({ featureName: 'batchPayments' });

  const { track } = useAnalytics();

  const isSelectedVendorGroupEmpty = isVendorGroupsEnabled && selectedVendorGroupIds && vendors.length === 0;

  const handleCreateVendorGroupSuccess = (newVendorGroupId: VendorGroup['id']) => {
    track('Dashboard', 'Status', { Status: 'success', ErrorType: 'vendor-group-added' });
    setSelectedVendorGroupIds([newVendorGroupId]);
  };

  const {
    isEmptyState,
    isEmptySearchResult,
    isInitialLoading,
    isTableLoading,
    shouldShowPaginationControls,
    shouldShowTabHeader,
  } = useLoadingState({
    isLoading: isLoadingVendors || !sorting?.isLoaded || isRecommendedVendorsLoading,
    searchTerm,
    isFiltered: isSelectedVendorGroupEmpty,
    items: vendors,
    paginationConfig: {
      isFetching,
      isPreviousData,
      totalCount: pagination?.totalCount,
    },
  });

  useEffect(() => {
    if (isTableLoading) {
      return;
    }
    track('Dashboard', 'View', {
      Intent: 'view-vendors',
      IsSuggestedVendorsShown: null,
      isEmptyState,
    });
  }, [isTableLoading]);

  const handleEditVendorsGroup = (vendorsGroupId: VendorGroup['id']) => {
    track('Dashboard', 'Click', { Cta: 'edit-group' });
    setEditedVendorsGroupId(vendorsGroupId);
    onOpenEditVendorGroupModal();
  };
  const handleEditVendorsGroupFilterClick = (vendorsGroupId: VendorGroup['id']) => {
    track('Dashboard', 'Click', { Cta: 'edit-group' });
    handleEditVendorsGroup(vendorsGroupId);
  };
  const handleEditVendorsGroupEmptyStateClick = (vendorsGroupId: VendorGroup['id']) => {
    track('Dashboard', 'Click', { Cta: 'add-vendors-to-group' });
    handleEditVendorsGroup(vendorsGroupId);
  };

  const handleNewPaymentClick = (vendorId: string, amount?: string) => {
    onAddNewPayment({ vendorId, amount, returnUrl: pathname });
  };

  const handleAddNewBill = (vendorId: Vendor['id']) => {
    onAddNewBill({ returnUrl: pathname, vendorId, originFlow: OriginFlow.VendorsFlow });
  };

  const handleDeleteVendorClick = (vendor: Vendor) => {
    setSelectedVendor(vendor);
    onDeleteModalOpen();
  };

  const handleEditVendor = (vendorId: Vendor['id']) => {
    handleNavigationWithQueryParams({ newPath: `vendor/${vendorId}`, newUrlQueryParam: { edit: 'true' } });
  };

  const handleViewVendor = (vendorId: Vendor['id']) => {
    handleNavigationWithQueryParams({ newPath: `vendor/${vendorId}` });
  };

  const handleSelectionFooterReviewAndPay = () => {
    onVendorsBatchPaymentsAmountsModalOpen();
  };

  const handleCreateVendorGroupsClick = () => {
    track('Dashboard', 'Click', { Cta: 'create-group' });
    onOpenCreateVendorGroupModal();
  };

  const handleDeleteVendorsGroupClick = (vendorsGroupId: string) => {
    setVendorsGroupIdToDelete(vendorsGroupId);
    onCloseEditVendorGroupsModal();
    onOpenDeleteVendorGroupModal();
  };

  const handleDeleteVendorsGroupSuccess = () => {
    track('Dashboard', 'Status', { Status: 'success', ErrorType: 'vendor-group-deleted' });

    onCloseDeleteVendorGroupsModal();
    setSelectedVendorGroupIds([]);
  };

  const handleDeleteVendorsGroupClose = () => {
    onCloseDeleteVendorGroupsModal();
  };

  const columns = useVendorsTableColumns({
    onSortChange: resetToFirstPage,
    filters: { groups: selectedVendorGroupIds },
  });
  const mobileSortMenuOptions = Object.values(vendorsTabSortableItems);

  const onClickRow = (rowData: Vendor) => {
    if (rowSelections.length) {
      return;
    }
    track('Dashboard', 'Click', {
      Intent: 'view-vendor',
      Cta: 'vendor-details',
      VendorId: rowData.id,
    });
    handleNavigationWithQueryParams({ newPath: `vendor/${rowData.id}` });
  };

  const mobileRowRenderer = useCallback(
    (row: Vendor, meta?: VendorsTableMeta) => (
      <VendorsTabListItem
        vendor={row}
        unpaidBills={meta?.unpaidBills.filter((bill) => bill.vendorId === row.id) ?? []}
      />
    ),
    []
  );
  const onSelectionChange = useCallback(
    (rowSelections: string[], lastSelection: Selection) => {
      const multiVendorSelected = rowSelections.length > 1;
      const onlyOneVendorSelected = rowSelections.length === 1 && lastSelection?.type === 'row';
      const multiUnpaidBillsForSelectedVendor =
        onlyOneVendorSelected && unpaidBills.filter((bill) => bill.vendorId === lastSelection.rowData.id).length > 1;
      if (multiVendorSelected || multiUnpaidBillsForSelectedVendor) {
        tryUseBatchPayments({ onFeatureIsBlocked: () => uncheckSelection(lastSelection) });
      }
    },
    [unpaidBills]
  );

  const {
    rowSelections,
    selectedRows,
    rowSelectionTooltips,
    onAllRowsSelectionChange,
    onRowSelectionChange,
    areAllSelected,
    uncheckSelection,
  } = useVendorsTabSelection({ vendorsData: vendors, onChange: onSelectionChange });
  const { amounts, onAmountChange } = usePayVendor({ onRowSelectionChange });

  useVendorsTabAnalytics({
    vendors,
    unpaidBills,
    areAllSelected,
    filters: isVendorGroupsEnabled
      ? { vendorsGroupIds: selectedVendorGroupIds.length ? selectedVendorGroupIds : [ALL_VENDORS_GROUP_ID] }
      : undefined,
  });

  const isRowSelectionDisabled = (row: Vendor) => {
    const isVendorDirectoryInfoCompleted = isVendorDirectoryDetailsCompleted({
      vendor: row,
      isCollectMissingVendorInfoEnabled,
      requiredDirectoryFields,
    });
    return !isVendorDirectoryInfoCompleted || isFXCurrency(row.currency);
  };

  const tableProps = useTable({
    isLoading: isTableLoading,
    data: vendors,
    columns,
    getRowId: (row) => row.id,
    onRowClick: ({ rowData }) => onClickRow(rowData),
    rowSelectionTooltips,
    selectedRows,
    onRowSelectionChange: isPlatformBatchPaymentsEnabled ? onRowSelectionChange : undefined,
    disableRowSelection: isRowSelectionDisabled,
    onAllRowsSelectionChange: isPlatformBatchPaymentsEnabled ? onAllRowsSelectionChange : undefined,
    ...sorting,
    headerVariant: 'dark',
    mobileRowRenderer,
    getRowSelectionAriaLabel: useCallback(
      (vendor) => getAriaLabelContextForVendor(vendor, { unpaidBills }),
      [unpaidBills]
    ),
    hideHeaderWhileLoading: isInitialLoading,
    allRowsSelectionAriaLabel: '',
    captionId: formatMessage(`activities.payDashboard.tabs.vendors.caption`),
    meta: {
      unpaidBills,
      onNewPaymentClick: handleNewPaymentClick,
      onDeleteVendorClick: handleDeleteVendorClick,
      onAddNewBillClick: handleAddNewBill,
      onEditVendorClick: handleEditVendor,
      onViewVendorClick: handleViewVendor,
      onAmountChange,
      amounts,
      getItemAriaLabelContext: (vendor) => getAriaLabelContextForVendor(vendor, { unpaidBills }),
      rowSelections,
    },
  });

  const handleSelectVendorGroupFilter = ({ vendorGroupIds }: { vendorGroupIds: VendorGroup['id'][] }) => {
    setSelectedVendorGroupIds(vendorGroupIds);
    resetToFirstPage();
  };

  // this should be removed and replaced by `handleSelectVendorGroupFilter` once we support multi select in the filter
  // for now the function declaration has to be different since we're using a shared Filter.tsx component
  const handleSelectVendorGroupFilterMobile = (vendorGroupId: VendorGroup['id']) => {
    setSelectedVendorGroupIds(vendorGroupId === ALL_VENDORS_GROUP_ID ? [] : [vendorGroupId]);
    resetToFirstPage();
  };

  const handleVendorNewVendor = () => {
    track('Dashboard', 'Click', {
      Intent: 'add-vendor',
      Cta: 'add-vendor',
    });
    navigateToNewVendor({ returnUrl: pathname, originFlow: OriginFlow.VendorsFlow });
  };
  const handleDeleteModalClose = () => {
    setSelectedVendor(null);
    onDeleteModalClose();
  };
  const handleVendorDeleted = (vendor: Vendor) => {
    const vendorName = getVendorNameForNotificationMessage(vendor);

    showMessage({
      type: 'informative',
      title: formatMessage('activities.payDashboard.deleteVendorModal.toast.success', { vendorName }),
      dataTestId: 'vendor-deleted',
    });
  };

  const renderTableContent = () => (
    <>
      <Group alignItems="flex-end" variant="vertical" spacing={shouldShowPaginationControls ? 'm' : undefined}>
        <APTable
          {...tableProps}
          captionLabel={
            shouldShowTabHeader ? formatMessage('activities.payDashboard.tabs.vendors.captionLabel') : undefined
          }
        />
        {isVendorGroupsEnabled && isSelectedVendorGroupEmpty && !isTableLoading && (
          <VendorGroupEmptyState
            onClearFilter={() => setSelectedVendorGroupIds([])}
            onAddVendorsToGroupClick={() => handleEditVendorsGroupEmptyStateClick(selectedVendorGroupIds[0] || '')}
          />
        )}
        {isEmptySearchResult && !isFetching ? (
          <EmptySearchResult onClear={() => emitFocusEvent(FocusEvents.TAB_TOP_SEARCH)} />
        ) : null}
        <>
          <PayDashboardPagination paginatedCollection={paginationResponse} isVisible={shouldShowPaginationControls} />
        </>
      </Group>
      {/* Fills space on the DOM equals to gap required by pagination controls in order for ActionBar footer to respect it due to it's fixed position  */}
      {rowSelections?.length ? <Box height={`${ROW_HEIGHT}px`} /> : null}
    </>
  );

  const renderContent = () => {
    if (isEmptySearchResult || !isEmptyState || selectedVendorGroupIds.length) {
      return renderTableContent();
    }
    return <VendorsTabEmptyState onAddNewVendor={handleVendorNewVendor} />;
  };

  const renderFilter = () => {
    if (isExtraSmallScreen) {
      // this should be replaced once we support multi select in the filter since filter component is for single select by design
      return (
        <VendorGroupFilterMobile
          vendorGroups={vendorGroups ?? []}
          activeFilter={selectedVendorGroupIds[0]}
          onChange={handleSelectVendorGroupFilterMobile}
          onEditClick={handleEditVendorsGroupFilterClick}
          onCreateClick={handleCreateVendorGroupsClick}
        />
      );
    }
    return (
      <VendorGroupFilterContainer
        onSelectFilter={handleSelectVendorGroupFilter}
        onCreateClick={handleCreateVendorGroupsClick}
        selectedVendorGroupIds={selectedVendorGroupIds}
        onEditVendorsGroupClick={handleEditVendorsGroupFilterClick}
      />
    );
  };

  const hideSearchLabel = isExtraSmallScreen || payDashboardSearchLabelDisabled;

  return (
    <>
      <Container data-testid="pay-dashboard-vendors-tab" overflow="initial">
        {!isLoadingVendors && <span ref={routeReady} />}

        <Group variant="vertical" width="full" spacing={isExtraSmallScreen ? 's' : 'm'}>
          <Container overflow="initial" paddingX={isExtraSmallScreen ? 'm' : undefined}>
            {shouldShowTabHeader && (
              <Group justifyContent="space-between" width="full" alignItems="center">
                <Group spacing="s" alignItems="center" width="full">
                  <SearchBar
                    onSearchSubmitted={resetToFirstPage}
                    placeholderOnFocus="activities.payDashboard.vendorsTab.searchPlaceholder"
                    label={
                      hideSearchLabel ? undefined : formatMessage('activities.payDashboard.vendorsTab.searchLabel')
                    }
                    pagination={!paginationResponse.isFetching ? paginationResponse.data?.pagination : undefined}
                    focusOnEvent={FocusEvents.TAB_TOP_SEARCH}
                    placeholder={hideSearchLabel ? 'activities.payDashboard.vendorsTab.search.placeholder' : undefined}
                  />
                  {isExtraSmallScreen && isMobileSortEnabled && (
                    <MobileSortMenu
                      items={mobileSortMenuOptions}
                      onSortChange={resetToFirstPage}
                      title="activities.payDashboard.vendorsTab.sort.title"
                      showTriggerText={false}
                    />
                  )}
                  {isVendorGroupsEnabled ? renderFilter() : null}
                </Group>
                {!isExtraSmallScreen ? (
                  <Button
                    data-testid="vendors-tab-add-vendor-button"
                    onClick={handleVendorNewVendor}
                    label={formatMessage('activities.payDashboard.vendorsTab.addVendorButton')}
                    variant="tertiary"
                    leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
                  />
                ) : null}
              </Group>
            )}
          </Container>
          <Container overflow="initial">{renderContent()}</Container>
        </Group>

        {selectedVendor && (
          <DeleteVendorModal
            vendor={selectedVendor}
            isOpen={isDeleteModalOpen}
            onClose={handleDeleteModalClose}
            onSuccess={handleVendorDeleted}
          />
        )}
      </Container>
      {isVendorsBatchPaymentsEnabled && isPlatformBatchPaymentsEnabled && rowSelections.length > 0 && (
        <VendorsSelectionFooter
          vendorIds={rowSelections}
          totalCount={pagination?.totalCount ?? 0}
          onCancel={() => onAllRowsSelectionChange?.(false)}
          onReviewAndPay={handleSelectionFooterReviewAndPay}
          vendorsAmountsMap={amounts}
        />
      )}
      {isVendorsBatchPaymentsEnabled && isPlatformBatchPaymentsEnabled && rowSelections.length > 0 && (
        <VendorsBatchPaymentsModalActivity
          selectedVendorIds={rowSelections}
          vendorsAmountsMap={amounts}
          isOpen={isVendorsBatchPaymentsAmountsModalOpen}
          onClose={onVendorsBatchPaymentsAmountsModalClose}
        />
      )}
      {isVendorGroupsEnabled && isCreateVendorGroupsModalOpen && (
        <CreateVendorsGroupModal
          isOpen={isCreateVendorGroupsModalOpen}
          onClose={onCloseCreateVendorGroupsModal}
          onSuccess={handleCreateVendorGroupSuccess}
        />
      )}
      {isVendorGroupsEnabled && editedVendorsGroupId && (
        <EditVendorsGroupModal
          isOpen={isEditVendorGroupsModalOpen}
          onClose={onCloseEditVendorGroupsModal}
          vendorsGroupId={editedVendorsGroupId}
          onDeleteVendorsGroupClick={handleDeleteVendorsGroupClick}
          onSuccess={() => track('Dashboard', 'Status', { Status: 'success', ErrorType: 'vendor-group-updated' })}
        />
      )}
      {isVendorGroupsEnabled && vendorsGroupIdToDelete && (
        <DeleteVendorsGroupModal
          isOpen={isDeleteVendorGroupsModalOpen}
          onClose={handleDeleteVendorsGroupClose}
          onSuccess={handleDeleteVendorsGroupSuccess}
          vendorsGroupId={vendorsGroupIdToDelete}
        />
      )}
    </>
  );
});

export const VendorsTab = (props: VendorsTabProps) => (
  <PayDashboardPaginationProvider>
    <PayDashboardSortingProvider defaultSort={VENDORS_TAB_DEFAULT_SORT} tableId={PayDashboardTabs.Vendors}>
      <VendorsTabComponent {...props} />
    </PayDashboardSortingProvider>
  </PayDashboardPaginationProvider>
);
