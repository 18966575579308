import { useIsAccountingClient, useIsAccountingFirm } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';

type UseLearnMoreLinkProps = {
  useV1AccountingFirmLink: boolean;
};

export const useLearnMoreLink = (props?: UseLearnMoreLinkProps) => {
  const {
    settings: { subscriptions },
  } = useConfig();
  const isAccountingFirm = useIsAccountingFirm();
  const isAccountingClient = useIsAccountingClient();
  const learnMoreLinks = subscriptions?.links.learnMore;

  const accountingFirmLink = props?.useV1AccountingFirmLink
    ? learnMoreLinks?.accountingFirmsV1
    : learnMoreLinks?.accountingFirmsV2;

  const learnMoreLink = isAccountingFirm
    ? accountingFirmLink
    : isAccountingClient
    ? learnMoreLinks?.accountingClients
    : learnMoreLinks?.smb;

  return learnMoreLink || '';
};
