import {
  DeliveryMethod,
  DeliveryMethodType,
  DeliveryMethodTypeOption,
  InternationalAccountIdentifierTypeEnum,
  UnsupportedDeliveryMethodTypeReason,
} from '@melio/platform-api';

export const shouldDisplayInternationalAccount = (
  isInternationalEnabled: boolean,
  deliveryMethods: DeliveryMethod[],
  deliveryMethodTypeOptions: DeliveryMethodTypeOption[] | undefined,
) => {
  return (
    isInternationalEnabled ||
    (vendorHasInternationalAccountDM(deliveryMethods) &&
      isOrgAddressNotValidForInternationalPayments(deliveryMethodTypeOptions))
  );
};

export const shouldDisplayFXInternationalAccount = (
  isInternationalFXEnabled: boolean,
  deliveryMethods: DeliveryMethod[],
  deliveryMethodTypeOptions: DeliveryMethodTypeOption[] | undefined,
) => {
  return (
    isInternationalFXEnabled ||
    (vendorHasFXInternationalAccountDM(deliveryMethods) &&
      isOrgAddressNotValidForInternationalPayments(deliveryMethodTypeOptions))
  );
};

export const vendorHasInternationalAccountDM = (deliveryMethods: DeliveryMethod[]): boolean => {
  return !!deliveryMethods.find(({ type, details }) => {
    const isInternationalAccount = type === DeliveryMethodType.InternationalAccount;
    if (isInternationalAccount) {
      return details.identifierType !== InternationalAccountIdentifierTypeEnum.V2;
    }
    return isInternationalAccount;
  });
};

export const vendorHasFXInternationalAccountDM = (deliveryMethods: DeliveryMethod[]): boolean => {
  return !!deliveryMethods.find(({ type, details }) => {
    const isInternationalAccount = type === DeliveryMethodType.InternationalAccount;
    if (isInternationalAccount) {
      return details.identifierType === InternationalAccountIdentifierTypeEnum.V2;
    }
    return false;
  });
};

const isOrgAddressNotValidForInternationalPayments = (
  deliveryMethodTypeOptions: DeliveryMethodTypeOption[] | undefined,
): boolean => {
  const reasons = [
    UnsupportedDeliveryMethodTypeReason.DeliveryMethodNotSupportedForState,
    UnsupportedDeliveryMethodTypeReason.AccountAddressNotValid,
  ];
  return !!deliveryMethodTypeOptions?.find(
    ({ type, reason }) => type === DeliveryMethodType.InternationalAccount && reasons.includes(reason),
  );
};
