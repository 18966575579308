import { withAnalyticsContext } from '@melio/platform-analytics';
import { ReactElement } from 'react';

import { useIsTestMode } from '../useIsTestMode';
import { UserActivationContext } from './UserActivationContext';

type UserActivationProviderProps = {
  children: ReactElement;
  onActivateAccount: VoidFunction;
};

export const UserActivationProvider = withAnalyticsContext<UserActivationProviderProps>(
  ({ children, onActivateAccount, setAnalyticsProperties }) => {
    const isTestMode = useIsTestMode();

    setAnalyticsProperties({
      IsTestInvoice: isTestMode,
    });

    return (
      <UserActivationContext.Provider value={{ isTestMode, onActivateAccount }}>
        {children}
      </UserActivationContext.Provider>
    );
  }
);
