import { useGuestPayorPaymentRequestDetails } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { usePaymentRequestStatus } from '../../hooks';
import { InvoicePaidScreen } from './screens';

export type InvoicePaidActivityProps = {
  paymentRequestLink: string;
  onViewInvoice: VoidFunction;
  onError?: ARErrorFunction;
};

export const InvoicePaidActivity = forwardRef<InvoicePaidActivityProps>(
  ({ paymentRequestLink, onViewInvoice, onError }, ref) => {
    const { data: paymentRequestDetails, isLoading } = useGuestPayorPaymentRequestDetails({
      paymentRequestLink,
      onError,
    });

    const { status } = usePaymentRequestStatus(paymentRequestLink);
    useEffect(() => {
      if (status && status != 'paid') onError?.({ code: 'UNKNOWN_ERROR', message: 'Unknown error' });
    }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <InvoicePaidScreen
        isLoading={isLoading || !paymentRequestDetails?.receivablePaymentDetails}
        amount={paymentRequestDetails?.invoice.totalAmount}
        invoicePreviewUrl={paymentRequestDetails?.invoice.fileInfo?.previewUrls[0]}
        isMarkedAsPaid={paymentRequestDetails?.receivablePaymentDetails?.source === 'manual'}
        onViewInvoice={onViewInvoice}
        ref={ref}
      />
    );
  }
);

InvoicePaidActivity.displayName = 'InvoicePaidActivity';
