import { useDownloadInvoice, useInvoice, useInvoicePreviewPDF } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { PreviewInvoiceScreen } from './screens';

export type PreviewInvoiceActivityProps = {
  onError?: ARErrorFunction;
  onClose: VoidFunction;
  invoiceId: string;
};

export const PreviewInvoiceActivity = forwardRef<PreviewInvoiceActivityProps>(
  ({ invoiceId, onError, ...props }, ref) => {
    const { data, isLoading, error } = useInvoicePreviewPDF({ id: invoiceId });
    const { data: invoice, isLoading: isInvoiceLoading } = useInvoice({ id: invoiceId, onError });
    const { downloadFile } = useDownloadInvoice();

    useEffect(() => {
      if (error) {
        onError?.(error);
      }
    }, [onError, error]);

    const onDownloadInvoice = () => {
      if (invoice?.fileId) {
        downloadFile(invoice.fileId);
      }
    };

    return (
      <PreviewInvoiceScreen
        isLoading={isLoading || isInvoiceLoading}
        onDownload={onDownloadInvoice}
        previewContent={data || ''}
        ref={ref}
        error={error}
        {...props}
      />
    );
  }
);

PreviewInvoiceActivity.displayName = 'PreviewInvoiceActivity';
