import { ActionsDropdownMenu, Card, Group, Icon, Skeleton, SkeletonCircle, Text } from '@melio/penny';
import { DeliveryMethod, DeliveryMethodType, useVendor, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useBoolean } from '@melio/platform-utils';

import { PaymentFlowOnChangeHandlers } from '../../../../../../types';
import { useDeliveryMethodCardMenuItems } from './useDeliveryMethodCardMenuItems';
import { useDeliveryMethodCardProps } from './useDeliveryMethodCardProps';

type SelectedDeliveryMethodCardProps = {
  vendorId: Vendor['id'];
  deliveryMethodId: DeliveryMethod['id'] | undefined;
  isRecurring: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  onDeliveryMethodChange: PaymentFlowOnChangeHandlers['onDeliveryMethodChange'];
  onEditDeliveryMethod: (deliveryMethodType: DeliveryMethodType) => void;
};

export const SelectedDeliveryMethodCard = ({
  vendorId,
  deliveryMethodId,
  isRecurring,
  isDisabled,
  isLoading,
  onDeliveryMethodChange,
  onEditDeliveryMethod,
}: SelectedDeliveryMethodCardProps) => {
  const { formatMessage } = useMelioIntl();
  const [isActionMenuOpen, actionMenu] = useBoolean(false);

  const { data: vendor } = useVendor({ id: vendorId });
  const selectedDeliveryMethod = vendor?.deliveryMethods.find(
    (deliveryMethod) => deliveryMethod.id === deliveryMethodId
  );

  const {
    title,
    description,
    icon,
    isLoading: isCardPropsLoading,
  } = useDeliveryMethodCardProps({
    vendor,
    deliveryMethod: selectedDeliveryMethod,
  });

  const menuItems = useDeliveryMethodCardMenuItems({
    selectedDeliveryMethod,
    deliveryMethods: vendor?.deliveryMethods || [],
    isRecurring,
    onDeliveryMethodChange,
    onEditDeliveryMethod,
  });

  const isContentLoading = ((!vendor || !selectedDeliveryMethod) && isLoading) || isCardPropsLoading;

  const descriptionMarkup = isContentLoading ? (
    <div style={{ width: 180, paddingTop: 4, paddingBottom: 4 }}>
      <Skeleton height="12px" />
    </div>
  ) : (
    <Text data-testid="payment-flow-form-delivery-method-card-description" textStyle="body3" color="global.neutral.800">
      {description}
    </Text>
  );

  const titleMarkup = isContentLoading ? (
    <div style={{ width: 130, paddingTop: 4, paddingBottom: 4 }}>
      <Skeleton height="16px" />
    </div>
  ) : (
    <Text textStyle="body2Semi" data-testid="payment-flow-form-delivery-method-card-title">
      {title}
    </Text>
  );

  const iconMarkup = isContentLoading ? (
    <div>
      <SkeletonCircle diameter="24px" />
    </div>
  ) : (
    <Icon type={icon} />
  );

  return (
    <Card
      data-testid={`payment-flow-form-delivery-method-card-${selectedDeliveryMethod?.id || ''}`}
      variant="flat"
      paddingX="s"
      paddingY="s"
      isDisabled={isDisabled}
    >
      <Group width="full" variant="horizontal" alignItems="center">
        {iconMarkup}
        <Group width="full" variant="vertical" spacing="xxxs">
          {titleMarkup}
          {descriptionMarkup}
        </Group>
        {!isDisabled && (
          <ActionsDropdownMenu
            size="large"
            variant="default"
            isOpen={isActionMenuOpen}
            onOpenChange={actionMenu.toggle}
            items={menuItems}
            isDisabled={isContentLoading}
            trigger={
              <Icon
                data-testid="payment-flow-form-delivery-method-card-actions-icon"
                aria-label={formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.actions.aria')}
                type="more-vertical"
                size="small"
              />
            }
          />
        )}
      </Group>
    </Card>
  );
};
