import { useBankAccountFundingSources, useOnboarding } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { useOnboardingStepsTracker } from '../../components';
import { ConfirmReceivingAccountScreen } from './screens';
import { ReceivingAndDebitAccountFormValues } from './types';

export type ConfirmReceivingAccountActivityProps = {
  onClose: VoidFunction;
  onDone: VoidFunction;
  onBack: VoidFunction;
  onError?: ARErrorFunction;
};

export const ConfirmReceivingAccountActivity = forwardRef<ConfirmReceivingAccountActivityProps>(
  ({ onDone, onError, ...props }, ref) => {
    const { isLoading: isLoadingOnBoarding, isMutating: isSavingOnBoarding, update } = useOnboarding();
    const { data: fundingSources = [], isLoading: isFundingSourcesLoading } = useBankAccountFundingSources();
    const { steps, currentStepIndex, goToStepById } = useOnboardingStepsTracker();

    useEffect(() => {
      goToStepById('receiving-account');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStepIndex]);

    const onSubmit = async (
      accountDetails: Omit<ReceivingAndDebitAccountFormValues, 'isReceivingAccountEqualDebitAccount'>
    ) => {
      await update({ ...accountDetails })
        .then(() => {
          onDone();
        })
        .catch(onError);
    };

    return (
      <ConfirmReceivingAccountScreen
        {...props}
        ref={ref}
        steps={steps}
        currentStepIndex={currentStepIndex}
        fundingSources={fundingSources}
        isSaving={isSavingOnBoarding}
        isLoading={isLoadingOnBoarding || isFundingSourcesLoading}
        onSubmit={onSubmit}
      />
    );
  }
);

ConfirmReceivingAccountActivity.displayName = 'ConfirmReceivingAccountActivity';
