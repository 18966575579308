import { useBreakpointValue } from '@chakra-ui/react';
import { Group, SelectionCard, Typography } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { AccountingPlatform, AccountingPlatformSlug } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { useIsSubscriptionsEnabled, useSubscriptionFeature, useSyncedPaymentsCount } from '@melio/subscriptions';
import { isFunction } from 'lodash';
import { useRef, useState } from 'react';

import { UploadFilesWidget } from '../UploadBillFiles';
import { AccountingPlatformCard } from './AccountingPlatformCard/AccountingPlatformCard.component';

export type AddBillMethodSelectionWidgetProps = {
  isAccountingPlatformConnecting?: boolean;
  includeSyncOption?: boolean;
  includePlatformsToSync?: AccountingPlatform[];
  onAddManualBill: VoidFunction;
  onUploadABill: (files: File[]) => void;
  onConnectAccountingPlatform?: (accountingPlatformSlug: AccountingPlatformSlug) => void;
};

export const AddBillMethodSelectionWidget = forwardRef<AddBillMethodSelectionWidgetProps, 'div'>(
  (
    {
      isAccountingPlatformConnecting,
      includeSyncOption,
      includePlatformsToSync,
      onAddManualBill,
      onUploadABill,
      onConnectAccountingPlatform,
      ...props
    },
    ref
  ) => {
    const isSubscriptionsEnabled = useIsSubscriptionsEnabled();

    const { data: syncedPaymentsCountData, isLoading: isLoadingSyncedPaymentsNumber } = useSyncedPaymentsCount({
      enabled: isSubscriptionsEnabled,
    });
    const numberOfSyncedPayments = syncedPaymentsCountData?.count || 0;

    const { tryUseFeature: tryUseSyncedPaymentsFeature } = useSubscriptionFeature({
      featureName: 'syncedPayments',
      requirements: { totalUnits: numberOfSyncedPayments + 1 },
    });

    const [isMultipleBillsUploadEnabled] = useDevFeature<boolean>(FeatureFlags.PlatformMultipleBillsUpload, false, {
      shouldTrack: true,
    });
    const [accountingPlatformToConnect, setAccountingPlatformToConnect] = useState<AccountingPlatformSlug>();
    const { formatMessage } = useMelioIntl();
    const fileInput = useRef<HTMLInputElement>(null);
    const isMobile = useBreakpointValue({ xs: true, s: false }, { ssr: false } as never);
    const uploadTextSection = isMultipleBillsUploadEnabled ? 'uploadBills' : 'uploadABill';

    const onUploadFileClick = () => {
      fileInput.current?.click();
    };

    const { track } = useAnalytics();
    const onSelection = (cta: string, callback?: VoidFunction | unknown) => {
      track('Bill', 'Click', {
        Cta: cta,
      });
      if (isFunction(callback)) {
        callback();
      }
    };

    const onConnectClicked = (cta: string, accountingPlatformSlug: AccountingPlatformSlug) => {
      if (
        accountingPlatformSlug === AccountingPlatformSlug.QuickBooksOnline ||
        accountingPlatformSlug === AccountingPlatformSlug.Xero
      ) {
        return tryUseSyncedPaymentsFeature({
          onFeatureIsEligible: () => connectAccountingPlatform(cta, accountingPlatformSlug),
        });
      }
    };

    const connectAccountingPlatform = (cta: string, accountingPlatformSlug: AccountingPlatformSlug) => {
      track('Bill', 'Click', {
        Cta: cta,
      });
      setAccountingPlatformToConnect(accountingPlatformSlug);
      onConnectAccountingPlatform?.(accountingPlatformSlug);
    };

    return (
      <Group
        variant="vertical"
        data-testid="add-bill-options-container"
        data-component="AddBillMethodSelectionWidget"
        {...props}
        ref={ref}
      >
        <UploadFilesWidget onUploadFiles={onUploadABill} ref={fileInput} />
        <SelectionCard
          data-component="EnterBillManuallyCard"
          data-testid="enter-bill-manually-card"
          icon="tablet-edit"
          mainLabelProps={{ label: formatMessage('widgets.addBillMethodSelection.enterBillManually.content') }}
          descriptionProps={{ label: formatMessage('widgets.addBillMethodSelection.enterBillManually.helperText') }}
          onClick={() => onSelection('enter-bill-manually', onAddManualBill)}
        />
        {isMobile && (
          <SelectionCard
            data-component="ScanABillCard"
            data-testid="scan-a-bill-card"
            icon="camera"
            mainLabelProps={{ label: formatMessage('widgets.addBillMethodSelection.scanABill.content') }}
            descriptionProps={{ label: formatMessage('widgets.addBillMethodSelection.scanABill.helperText') }}
            onClick={() => onSelection('upload-bill', onUploadFileClick)}
          />
        )}
        <SelectionCard
          data-component="UploadABillCard"
          data-testid="upload-a-bill-card"
          icon="upload"
          mainLabelProps={{ label: formatMessage(`widgets.addBillMethodSelection.${uploadTextSection}.content`) }}
          descriptionProps={{ label: formatMessage(`widgets.addBillMethodSelection.${uploadTextSection}.helperText`) }}
          onClick={() => onSelection('upload-bill', onUploadFileClick)}
        />

        {includeSyncOption && includePlatformsToSync && (
          <>
            {includePlatformsToSync.length > 1 ? (
              <Typography.SectionLabel
                data-testid="connect-accounting-platform-title"
                label={formatMessage('activities.addBill.screens.addBill.accountingSoftware.description')}
              />
            ) : null}
            {includePlatformsToSync.map((accountingPlatform) => (
              <AccountingPlatformCard
                key={accountingPlatform.id}
                accountingPlatform={accountingPlatform}
                isLoading={
                  isLoadingSyncedPaymentsNumber ||
                  (isAccountingPlatformConnecting && accountingPlatform.accountingSlug === accountingPlatformToConnect)
                }
                onConnect={onConnectClicked}
              />
            ))}
          </>
        )}
      </Group>
    );
  }
);

AddBillMethodSelectionWidget.displayName = 'AddBillMethodSelectionWidget';
