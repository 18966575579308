import { Box } from '@chakra-ui/react';
import { Icon, Tooltip } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const LockedVendorIcon = () => {
  const { formatMessage } = useMelioIntl();
  return (
    <Tooltip
      content={formatMessage('widgets.lockedVendorIcon.tooltip.label')}
      data-testid="vendor-avatar-widget-readonly-lock-tooltip"
    >
      <Box as={Icon} type="lock" size="small" data-testid="vendor-avatar-widget-readonly-lock-icon" tabIndex={0} />
    </Tooltip>
  );
};
