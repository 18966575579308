export enum ScreensEnum {
  error = 'error',
  onboarding = 'onboarding',
  Clients = 'clients',
  Team = 'team',
  payDashboard = 'payDashboard',
  getPaidDashboard = 'getPaidDashboard',
  vendors = 'vendors',
  customers = 'customers',
  settings = 'settings',
  paymentsClassification = 'paymentsClassification',
  schedulePayment = 'schedulePayment',
  batchPayments = 'batchPayments',
  accountingPlatform = 'accountingPlatform',
  accounts = 'accounts',
  justPay = 'justPay',
  ARDashboard = 'ARDashboard',
  expenses = 'expenses',
}

export type Toast = {
  type: 'success' | 'error' | 'informative';
  title: string;
};

export enum ActiveFlowEnum {
  onboarding = 'onboarding',
  payDashboard = 'payDashboard',
  vendors = 'vendors',
  settingsPaymentMethods = 'settings/payment-methods',
  schedulePayment = 'schedulePayment',
  batchPayments = 'batchPayments',
  accounts = 'accounts',
  receivingMethods = 'settings/receiving-methods',
}
export type AppModelType = {
  activeScreen?: ScreensEnum;
  activeFlow?: ActiveFlowEnum;
  toast?: Toast;
  accessToken?: string;
  pathPrefix?: string;
  refreshToken?: string;
  accountUrl?: string;
  isSwitchingOrganization: boolean;
};
