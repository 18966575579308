import { AccountAddress } from '@melio/platform-api-axios-client';

import { PreviewSubscriptionBillingItem, SubscriptionPreview } from '../../../../api';
import { SubscriptionCheckoutFreeTrialTracker } from './SubscriptionCheckoutFreeTrialTracker';
import { SubscriptionDetailedSubtotal } from './SubscriptionDetailedSubtotal';

type SubscriptionDetailedTotalProps = {
  preview: SubscriptionPreview;
  paidPeriodStartDate: string;
  freeTrialPeriodData?: {
    chargingAmount?: number;
    freeBillingItem?: PreviewSubscriptionBillingItem;
  };
  legalAddress: AccountAddress | null;
};

export const SubscriptionDetailedTotal = ({
  paidPeriodStartDate,
  preview,
  freeTrialPeriodData,
  legalAddress,
}: SubscriptionDetailedTotalProps) => {
  if (freeTrialPeriodData?.chargingAmount) {
    return (
      <SubscriptionCheckoutFreeTrialTracker
        startChargingDate={paidPeriodStartDate}
        chargingAmount={freeTrialPeriodData.chargingAmount}
      />
    );
  }

  return <SubscriptionDetailedSubtotal preview={preview} legalAddress={legalAddress} />;
};
