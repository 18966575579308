import { ApiQueryKey, useMelioQueryClient, useNonEntityMutation, useUpdateMutation } from '@melio/api-client';
import { ARInvoice, InvoicesApiClient, InvoicesApiInstance } from '@melio/ar-api-axios-client';

export const useInvoiceMutations = (queryKey: ApiQueryKey) => {
  const onSuccessCreator = useOnSuccessCreatorFactory();
  const onSuccess = (data: ARInvoice) => onSuccessCreator(data.id)(data);

  return {
    shareMutation: useNonEntityMutation<typeof InvoicesApiInstance.sharedInvoicesInvoiceId>(
      (params) => InvoicesApiInstance.sharedInvoicesInvoiceId(params).then(onSuccessCreator(params.id)),
      queryKey
    ),
    cancelMutation: useUpdateMutation<typeof InvoicesApiClient.putInvoiceCancel>(
      InvoicesApiClient.putInvoiceCancel,
      queryKey,
      { onSuccess }
    ),
    markInvoiceAsPaid: useUpdateMutation<typeof InvoicesApiClient.postMarkAsPaidDetails>(
      InvoicesApiClient.postMarkAsPaidDetails,
      queryKey,
      { onSuccess }
    ),
    markInvoiceAsUnpaid: useUpdateMutation<typeof InvoicesApiClient.postMarkAsUnpaid>(
      InvoicesApiClient.postMarkAsUnpaid,
      queryKey,
      { onSuccess }
    ),
  };
};

const useOnSuccessCreatorFactory = () => {
  const queryClient = useMelioQueryClient();
  return (id: string) =>
    async <T>(target: T): Promise<T> => {
      await queryClient.invalidateQueries('CustomersDashboardApi', 'paginated-collection');
      await queryClient.invalidateQueries('InvoiceExpandedApi', 'model', id);
      await queryClient.invalidateQueries('InvoiceTimelineApi', 'model', id);
      await queryClient.invalidateQueries('DeliveryMethodsApi', 'collection');
      // for non entity mutations we need to also invalidate the model query
      await queryClient.invalidateQueries('InvoicesApi', 'paginated-collection');
      await queryClient.invalidateQueries('InvoicesApi', 'model', id);
      return target;
    };
};
