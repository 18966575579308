import { Address } from '@melio/platform-api';
import { identity, pickBy } from 'lodash';

import { AddVendorFormFields } from '../../../../types';

export const getCreateVendorPayload = (data: AddVendorFormFields) => {
  const { companyName, email, phone, fullName, accountNumber, countryCode } = data;

  const address: Address | null = countryCode
    ? {
        line1: '',
        city: '',
        state: '',
        postalCode: '',
        countryCode,
      }
    : null;

  const contact = pickBy(
    {
      name: fullName,
      email,
      phoneNumber: phone,
      address,
    },
    identity
  );

  return {
    name: companyName ?? '',
    contact,
    accountNumber: accountNumber || undefined,
  };
};
