import { useCollectionApi, UseCollectionApiProps, useCreateMutation, useMelioQueryClient } from '@melio/api-client';
import { PaymentsApiClient, PostPaymentApprovalDecision } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';

import { CreateFn, DeleteFn, ListFn, Payment, PaymentsListParams, UpdateFn } from './types';
import {
  convertPaymentsCentsToDollars,
  getPayments,
  prepareBatchCreatePayload,
  prepareCreateParams,
  prepareUpdateParams,
} from './utils';

export { PostApprovalDecisionEnum } from '@melio/platform-api-axios-client';

export type UsePaymentsProps = UseCollectionApiProps<typeof PaymentsApiClient.getPayments, Payment> & {
  params?: PaymentsListParams;
  convertCurrency?: boolean;
};

export const usePayments = ({ params, convertCurrency = true, ...props }: UsePaymentsProps = {}) => {
  const query = useCollectionApi<ListFn, CreateFn, UpdateFn, DeleteFn, Payment>({
    ...props,
    queryKey: ['PaymentsApi', params],

    // data handling
    queryFn: () => getPayments(params),
    select: convertCurrency ? convertPaymentsCentsToDollars : undefined,

    // create handling
    createFn: PaymentsApiClient.postPayment,
    prepareCreateParams: (data) => prepareCreateParams(data, convertCurrency),

    // update handling
    updateFn: PaymentsApiClient.updatePaymentsPaymentId,
    prepareUpdateParams,

    // delete handling
    deleteFn: PaymentsApiClient.deletePaymentsPaymentId,
  });

  // batch create handling
  const queryClient = useMelioQueryClient();

  const batchCreateMutation = useCreateMutation(PaymentsApiClient.postPaymentsBulk, query.queryKey, {
    prepareData: (data) => prepareBatchCreatePayload(data, convertCurrency),
    onSuccess: () => queryClient.invalidateQueries('PaymentsApi', 'collection'),
  });

  const batchApprovalDecision = useMutation(
    query.queryKey,
    ({
      approvalPayments,
      approvalDecision,
    }: {
      approvalPayments: { id: string; quoteId?: string }[];
      approvalDecision: PostPaymentApprovalDecision;
    }) => PaymentsApiClient.postPaymentsBatchApprovalDecision({ payments: approvalPayments, approvalDecision }, 'none'),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(query.queryKey[0], 'collection');
        void queryClient.invalidateQueries(query.queryKey[0], 'paginated-collection');
      },
    }
  );

  return {
    ...query,
    batchCreate: batchCreateMutation.mutateAsync,
    batchApprovalDecision: batchApprovalDecision.mutateAsync,
    _mutations: {
      ...query._mutations,
      batchCreate: batchCreateMutation,
      batchApprovalDecision,
    },
  };
};

export type PaymentCollection = ReturnType<typeof usePayments>;
