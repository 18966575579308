// eslint-disable-next-line import/no-deprecated
import { useMelioIntl } from '@melio/ar-domain';
import { _createFormFieldInput, Search, SearchOption, SearchProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { ChangeEvent } from 'react';

export type TaxRateSelectWidgetProps = Omit<SearchProps, 'options' | 'creatableOption' | 'emptyState'> & {
  onCreateOption: (value: string) => string;
  onChangeOption?: (value: string) => void;
  selectOptions: SearchOption[];
  isLoading?: boolean;
};

export const TaxRateSelectWidget = _createFormFieldInput(
  forwardRef<TaxRateSelectWidgetProps, 'input'>(
    ({ onChange, onCreateOption, onChangeOption, selectOptions, placeholder, ...props }, ref) => {
      const { formatMessage } = useMelioIntl();
      const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange?.(event);
        onChangeOption?.(event.target.value);
      };

      const handleCreateOption = (inputValue: string) => {
        const newOptionId = onCreateOption(inputValue);
        onChange?.({ target: { value: newOptionId } } as never);
      };

      return (
        <Search
          aria-label="Select Tax Rate"
          data-component="TaxRateSelect"
          ref={ref}
          {...props}
          options={selectOptions}
          onChange={handleChange}
          placeholder={placeholder}
          creatableOption={{
            label: formatMessage('ar.modals.activities.taxRate.formDialog.fields.name.dropdown.options.addNew.label'),
            onClick: handleCreateOption,
          }}
          shouldShowPresetOptions
          emptyState={{ label: 'No options' }}
        />
      );
    }
  )
);

TaxRateSelectWidget.displayName = 'TaxRatesSelectWidget';
