import { Container, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useScreenLabels } from '../utils';

export const FormPanelHeaderDesktop = forwardRef((_, ref) => {
  const { formHeaderText } = useScreenLabels();

  return (
    <Container paddingLeft="xxl" paddingTop="xxl" data-component={FormPanelHeaderDesktop.displayName} ref={ref}>
      <Text textStyle="heading3Semi">{formHeaderText}</Text>
    </Container>
  );
});
FormPanelHeaderDesktop.displayName = 'FormPanelHeaderDesktop';
