import { Account, Vendor, VendorManagedByEnum } from '@melio/platform-api';
import { get } from 'lodash';

export const isVendorManaged = (vendor: Vendor) => vendor.isManaged;
export const isVendorCreatedByAccount = ({ vendor, account }: { vendor: Vendor; account: Account }): boolean =>
  vendor.createdById === account.userId;

export const isVendorManagedByFiserv = (vendor: Vendor) =>
  isVendorManaged(vendor) && vendor.managedBy === VendorManagedByEnum.Fiserv;

export const isVendorDirectoryDetailsCompleted = ({
  vendor,
  isCollectMissingVendorInfoEnabled,
  requiredDirectoryFields,
}: {
  vendor?: Vendor;
  isCollectMissingVendorInfoEnabled: boolean;
  requiredDirectoryFields: string[];
}) =>
  !getVendorDirectoryMissingFields({
    vendor,
    isCollectMissingVendorInfoEnabled,
    requiredDirectoryFields,
  }).length;

export const getVendorDirectoryMissingFields = ({
  vendor,
  isCollectMissingVendorInfoEnabled,
  requiredDirectoryFields,
}: {
  vendor?: Vendor;
  isCollectMissingVendorInfoEnabled: boolean;
  requiredDirectoryFields: string[];
}) => {
  if (!isCollectMissingVendorInfoEnabled || !requiredDirectoryFields.length || !vendor || vendor.managedBy !== null) {
    return [];
  }

  return requiredDirectoryFields.filter((requiredFieldName) => !get(vendor, requiredFieldName));
};
