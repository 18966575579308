import { useMelioIntl } from '@melio/platform-i18n';

import { useIsFeatureInSubscription } from '../../../../hooks';

export const useAccountingSoftwareSyncDescription = () => {
  const hasSyncedPaymentsFeature = useIsFeatureInSubscription({
    featureName: 'syncedPayments',
    requirements: { totalUnits: Infinity },
  }).isEligible;

  const { formatMessage } = useMelioIntl();

  const getAccountingSoftwareSyncDescription = () => {
    if (hasSyncedPaymentsFeature) {
      return formatMessage(
        'activities.settings.editablePlanCardFiserv.description.accountingSoftwareSync.quickBooksOrXero'
      );
    }
    return;
  };

  return getAccountingSoftwareSyncDescription();
};
