import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { noop } from 'lodash';
import { PaymentFlowDoneAction, ScheduleSinglePaymentActivity } from '@melio/ap-activities';
import { useBlockPaymentsForOrganizations } from '@melio/ap-domain';
import { useToast } from '@melio/penny';
import { OriginFlow, useAnalytics } from '@melio/platform-analytics';
import { useBill } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useNavigate } from '@melio/platform-utils';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { usePaymentFlowIntuitConnect } from '@/hooks/accountingPlatforms.hooks';
import { SchedulePaymentRouteParamsType, useRouter } from '@/hooks/router.hooks';
import { PayDashboardTabs } from '@/types/payDashboard.types';
import { BlockedInternationalPaymentsForState } from '../blocked-international-payments/BlockedInternationalPaymentsForState.screen';

type PaymentDoneActionParams = { paymentId: string; isPendingApproval: boolean };

export const SchedulePaymentScreen = ({ billId, returnUrl }: { billId: string; returnUrl?: string }) => {
  const { replaceToPayDashboardTab, goToAddNewBill, generateNPEDashboardLink, goToPayDashboard } = useRouter();
  const navigate = useNavigate();
  const { formatMessage } = useMelioIntl();
  const { state } = useLocation();
  const { track } = useAnalytics();
  const { toast } = useToast();
  const { loginToAccountPlatformAuth, isLoginToAccountPlatformAuthLoading } = usePaymentFlowIntuitConnect({
    redirectMethod: 'replace',
  });
  const { shouldBlockInternationalPayments, getBlockReason } = useBlockPaymentsForOrganizations();

  const { deliveryMethodId } = (state as SchedulePaymentRouteParamsType) || {};

  const handleClose = useCallback(() => {
    if (returnUrl) {
      return navigate(returnUrl);
    }
    replaceToPayDashboardTab(PayDashboardTabs.Inbox, billId);
  }, [billId, replaceToPayDashboardTab, navigate, returnUrl]);

  const handleGoToDashboard = ({ isPendingApproval, paymentId }: PaymentDoneActionParams) => {
    if (returnUrl) {
      isPendingApproval
        ? toast({
            type: 'informative',
            title: formatMessage('screens.scheduleSinglePayment.toast.success.single.pending.title'),
            action: {
              type: 'button',
              text: formatMessage('screens.scheduleSinglePayment.toast.success.single.action'),
              onAction: (closeToast) => {
                navigate(generateNPEDashboardLink('payments'));
                track('Dashboard', 'Click', {
                  PageName: 'payment-pending-approval',
                  Flow: 'dashboard',
                  Intent: 'view-approvals',
                  Status: 'success',
                  Cta: 'view-payment',
                  PaymentId: paymentId,
                });
                closeToast();
              },
            },
          })
        : toast({
            type: 'success',
            title: formatMessage('screens.scheduleSinglePayment.toast.success.single.title'),
            action: {
              type: 'button',
              text: formatMessage('screens.scheduleSinglePayment.toast.success.single.action'),
              onAction: (closeToast) => {
                navigate(generateNPEDashboardLink('payments'));
                closeToast();
              },
            },
          });
      return navigate(returnUrl, { state: { keepToast: true } });
    }
    replaceToPayDashboardTab(PayDashboardTabs.Scheduled, paymentId);
  };

  const handleCreateNewPayment = () => {
    goToAddNewBill({ originFlow: OriginFlow.PaymentScheduled });
  };

  const handleDone = (paymentId: string, action: PaymentFlowDoneAction, isPendingApproval: boolean) => {
    const handlers: Record<PaymentFlowDoneAction, (params: PaymentDoneActionParams) => void> = {
      goToDashboard: handleGoToDashboard,
      createNewPayment: handleCreateNewPayment,
      syncQBOAccountingPlatform: loginToAccountPlatformAuth,
      openInNewTabDashboard: noop,
      resolveFailedPayments: noop,
    };

    handlers[action]({ paymentId, isPendingApproval });
  };

  const { data: bill, isLoading } = useBill({ id: billId });

  if (isLoading) {
    return <Loader isAbsoluteCenter />;
  }
  const deliveryMethods = bill?.vendor?.deliveryMethods ?? [];
  if (shouldBlockInternationalPayments(deliveryMethods)) {
    return (
      <BlockedInternationalPaymentsForState
        onClose={() => goToPayDashboard()}
        blockReason={getBlockReason(deliveryMethods)}
      />
    );
  }

  return (
    <ScheduleSinglePaymentActivity
      billId={billId}
      onBack={handleClose}
      onClose={handleClose}
      onDone={handleDone}
      deliveryMethodId={deliveryMethodId}
      isLoading={isLoginToAccountPlatformAuthLoading}
    />
  );
};
