import { Company, OrganizationBusinessType, TaxIdTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';
import { number, object, string } from 'yup';

import { hasRequiredAddressFields, isPOBox } from '../address-utils';
import { useMtlMessages } from './useMtlMessages';

export const MIN_AGE = 18;
export const MAX_AGE = 120;
export const MIN_PHONE_LENGTH = 10;
export const NAME_MIN_LENGTH = 2;
export const COMPANY_NAME_MIN_LENGTH = 3;
export const NAME_FORMAT = /^[a-zA-Z\s-]*$/;
export const DATE_FORMAT = 'MM/dd/yyyy'; // i.e 12/30/1995
export const DATE_REGEX = /\d{2}\/\d{2}\/\d{4}/; // i.e 12/30/1995
export const TAX_ID_FORMAT = /(\d{9})|(\d{3}-\d{2}-\d{4})|(\d{2}-\d{7})/; // i.e 987654321 OR 987-65-4321 OR 98-7654321

export const useMtlDateUtils = () => useDateUtils(DATE_FORMAT);

export const useMtlSchemaValidations = () => {
  const { formatMessage } = useMelioIntl();
  const { isValidAge, isValidPastDate, parseDate } = useMtlDateUtils();
  const {
    validations: {
      required: {
        company: { einOnlyValidationText },
      },
    },
  } = useMtlMessages();

  const transformEmptyStringToNull = (value: string, originalValue: string): string | null =>
    originalValue.trim() === '' ? null : value;

  const name = (type: 'firstName' | 'lastName') =>
    string()
      .transform(transformEmptyStringToNull)
      .nullable()
      .required(formatMessage(`app.mtl.validations.${type}.required`))
      .matches(NAME_FORMAT, formatMessage('app.mtl.validations.onlyLetters'))
      .min(
        NAME_MIN_LENGTH,
        formatMessage('app.mtl.validations.length', {
          min: NAME_MIN_LENGTH,
        })
      );

  const companyName = () =>
    string()
      .transform(transformEmptyStringToNull)
      .nullable()
      .required(formatMessage(`app.mtl.validations.companyName.required`))
      .min(
        COMPANY_NAME_MIN_LENGTH,
        formatMessage('app.mtl.validations.length', {
          min: COMPANY_NAME_MIN_LENGTH,
        })
      );

  const phoneNumber = () =>
    string()
      .transform(transformEmptyStringToNull)
      .nullable()
      .required(formatMessage('app.mtl.validations.phoneNumber.required'))
      .min(MIN_PHONE_LENGTH, formatMessage('app.mtl.validations.phoneNumber.minimumLength'));

  const dateOfBirth = () =>
    string()
      .transform(transformEmptyStringToNull)
      .nullable()
      .required(formatMessage('app.mtl.validations.dateOfBirth.required'))
      .matches(
        DATE_REGEX,
        formatMessage('app.mtl.validations.dateOfBirth.format', { format: DATE_FORMAT.toLocaleUpperCase() })
      )
      .test(
        'isValidPastDate',
        formatMessage('app.mtl.validations.dateOfBirth.format', { format: DATE_FORMAT.toLocaleUpperCase() }),
        (value) => !value || (!!value && DATE_REGEX.test(value) && isValidPastDate(parseDate(value)))
      )
      .test(
        'validMinAge',
        formatMessage(`app.mtl.validations.dateOfBirth.minAge`, {
          age: MIN_AGE,
        }),
        (v) => !v || isValidAge(v, MIN_AGE, 'min')
      )
      .test(
        'validMaxAge',
        formatMessage(`app.mtl.validations.dateOfBirth.maxAge`, {
          age: MAX_AGE,
        }),
        (v) => !v || isValidAge(v, MAX_AGE, 'max')
      );

  const address = (type: 'operating-address' | 'legal-address') =>
    object({
      line1: string(),
      line2: string(),
      city: string(),
      postalCode: string(),
      state: string(),
      aptNumber: string(),
    })
      .nullable()
      .required(formatMessage('app.mtl.validations.address.required'))
      .default(null)
      .test(
        'hasRequiredAddressFields',
        formatMessage(`app.mtl.validations.address.required`),
        (address: Partial<Company['address']> | null) => hasRequiredAddressFields(address)
      )
      .test(
        'isNotPoBox',
        formatMessage(`app.mtl.validations.address.poBox`),
        (address: Partial<Company['address']> | null) =>
          type === 'operating-address' ? !isPOBox(address?.line1) : true
      );

  const industry = () =>
    object({
      name: string(),
      naicsCode: number().nullable(),
    })
      .required(formatMessage('app.mtl.validations.industry.required'))
      .nullable()
      .default(null);

  const businessType = () =>
    string()
      .nullable()
      .required(formatMessage('app.mtl.validations.businessType.required'))
      .test('is-valid-value', formatMessage('app.mtl.validations.businessType.invalid'), (value) => {
        // Required above covers null case and this is to allow
        // setting "nonRequired" by a downstream validation
        if (value == null) {
          return true;
        }
        return Object.values(OrganizationBusinessType).includes(value as OrganizationBusinessType);
      });

  const taxIdType = () =>
    string().oneOf(Object.values(TaxIdTypeEnum)).required(formatMessage('app.mtl.validations.taxInfo.type.required'));

  const taxId = (taxIdTypeFieldName: string, forceIsEinTaxType?: boolean) =>
    string()
      .required(formatMessage('app.mtl.validations.taxInfo.identifier.required'))
      .optional()
      .when(taxIdTypeFieldName, (taxIdType: TaxIdTypeEnum) => {
        taxIdType = forceIsEinTaxType ? TaxIdTypeEnum.Ein : taxIdType;
        return taxIdType
          ? string()
              .when('businessType', (businessType: OrganizationBusinessType) =>
                string()
                  .required(
                    taxIdType === TaxIdTypeEnum.Ein && businessType
                      ? einOnlyValidationText(businessType)
                      : formatMessage(`app.mtl.validations.taxInfo.identifier.${taxIdType}.required`)
                  )
                  .matches(TAX_ID_FORMAT, formatMessage(`app.mtl.validations.taxInfo.identifier.${taxIdType}.invalid`))
              )
              .matches(TAX_ID_FORMAT, formatMessage(`app.mtl.validations.taxInfo.identifier.${taxIdType}.invalid`))
          : string().nullable();
      });

  const legalDateOfBirth = () =>
    dateOfBirth().when('taxIdType', {
      is: TaxIdTypeEnum.Ein,
      then: () => string().optional().nullable(),
    });

  return {
    name,
    companyName,
    phoneNumber,
    dateOfBirth,
    address,
    industry,
    businessType,
    taxIdType,
    taxId,
    legalDateOfBirth,
  };
};
