// eslint-disable-next-line import/no-deprecated
import { Container, useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { SubscriptionBillingCycleEnum, useSubscriptionMe } from '../../../api';
import { usePlansTiers, useSubscription, useSubscriptionPlan } from '../../../hooks';
import { CancelOrDowngradeFlowActivity, SwitchSubscriptionBillingCycleModalScreen } from '../../activities';
import { SubscriptionCancelOrDowngradeOriginEnum } from '../../constants';
import { EditablePlanCard } from './EditablePlanCard';
import { FreePlanCard } from './FreePlanCard';
import { VipPlanCard } from './VipPlanCard';

type SubscriptionPlansSelectProps = {
  isClientWithPlanManagedByFirm?: boolean;
};
export const SubscriptionPlansSelect = ({ isClientWithPlanManagedByFirm }: SubscriptionPlansSelectProps) => {
  const { isVip } = useSubscriptionPlan();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isSwitchModalOpen, setIsSwitchModalOpen] = useState<boolean>(false);
  const [switchBillingCycleError, setSwitchBillingCycleError] = useState(false);
  const { track } = useAnalytics();
  // eslint-disable-next-line import/no-deprecated
  const { toast } = useToast();
  const { formatMessage } = useMelioIntl();

  const subscription = useSubscription();
  const { update: updateSubscription, isMutating: isUpdatingSubscription } = useSubscriptionMe({
    enabled: false,
  });
  const { getFreePlan } = usePlansTiers();
  const freePlan = getFreePlan();

  const isFreePlan = subscription && subscription.planId === freePlan?.id;
  const newCycle =
    subscription?.planCyclePeriod === SubscriptionBillingCycleEnum.Monthly
      ? SubscriptionBillingCycleEnum.Annual
      : SubscriptionBillingCycleEnum.Monthly;
  const onOpenCancelModal = () => setIsCancelModalOpen(true);
  const onCloseCancelModal = () => setIsCancelModalOpen(false);
  const onOpenSwitchModal = () => {
    setSwitchBillingCycleError(false);
    setIsSwitchModalOpen(true);
  };
  const onCloseSwitchModal = () => setIsSwitchModalOpen(false);
  const onSwitchBillingCycle = () => {
    void updateSubscription({ planId: subscription?.planId, planCyclePeriod: newCycle })
      .then(() => {
        track('Organization', 'Status', {
          Intent: 'upgrade-plan',
        });
        toast({
          id: 'subscription-switch-billing-cycle-success',
          type: 'success',
          title: formatMessage(`activities.settings.subscriptionPlans.switchBillingCycle.toast.${newCycle}.success`),
        });
        onCloseSwitchModal();
      })
      .catch(() => {
        setSwitchBillingCycleError(true);
      });
  };

  return (
    <Container>
      {isVip ? (
        <VipPlanCard />
      ) : isFreePlan ? (
        <FreePlanCard />
      ) : (
        <EditablePlanCard
          isClientWithPlanManagedByFirm={isClientWithPlanManagedByFirm}
          onOpenCancelModal={onOpenCancelModal}
          onOpenSwitchBillingCycleModal={onOpenSwitchModal}
        />
      )}
      {freePlan && (
        <CancelOrDowngradeFlowActivity
          isOpen={isCancelModalOpen}
          newPlanId={freePlan.id}
          onClose={onCloseCancelModal}
          origin={SubscriptionCancelOrDowngradeOriginEnum.Settings}
        />
      )}
      {subscription && (
        <SwitchSubscriptionBillingCycleModalScreen
          isOpen={isSwitchModalOpen}
          onClose={onCloseSwitchModal}
          onChange={onSwitchBillingCycle}
          switchBillingCycleError={switchBillingCycleError}
          subscription={subscription}
          isSubscribing={isUpdatingSubscription}
        />
      )}
    </Container>
  );
};
