import { partition } from 'lodash';

import { SubscriptionPreview } from '../api';

export type UseSubscriptionPreviewItemsProps = {
  numberOfFreeUsers: number;
  subscriptionPreview?: SubscriptionPreview;
};

export const useSubscriptionPreviewItems = ({
  numberOfFreeUsers,
  subscriptionPreview,
}: UseSubscriptionPreviewItemsProps) => {
  const [[freeBillingItem], chargedItems] = partition(subscriptionPreview?.billingItems, { chargeType: 'freeTrial' });
  const [[seatBillingItem], [basePriceBillingItem]] = partition(chargedItems, { chargeType: 'measurementUnit' });

  const numberOfSeats = (seatBillingItem?.measurementInfo?.quantity || 0) - numberOfFreeUsers;

  return {
    ...(freeBillingItem && {
      freeTrialData: {
        freeBillingItem,
        chargingAmount: subscriptionPreview?.amount,
      },
    }),
    paidPeriodStartDate: basePriceBillingItem?.serviceStartDate,
    paidSeatsCount: Math.max(numberOfSeats, 0),
  };
};
