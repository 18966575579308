import {
  PaymentWidgetFundingSourceType,
  useGuestPayorPaymentRequestDetails,
  usePaymentWidgetUrl,
} from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { GuestPayorFundingSourceTypes } from '../../types';
import { FiservPaymentScreen } from './screens';

type FiservPaymentActivityProps = {
  onViewInvoice: VoidFunction;
  fundingSourceType: GuestPayorFundingSourceTypes;
  onSelectFundingSourceType: (type: GuestPayorFundingSourceTypes) => void;
  paymentRequestLink: string;
  onError?: ARErrorFunction;
};

const guestPayorToWidgetTypes: Record<GuestPayorFundingSourceTypes, PaymentWidgetFundingSourceType> = {
  bank: 'bank',
  card: 'card',
};

export const FiservPaymentActivity = forwardRef<FiservPaymentActivityProps>(
  ({ onError, onViewInvoice, paymentRequestLink, onSelectFundingSourceType, fundingSourceType }, ref) => {
    const { data: paymentRequestDetails, isLoading: isLoadingPaymentRequestDetails } =
      useGuestPayorPaymentRequestDetails({
        paymentRequestLink,
        onError,
      });

    const shouldAllowFundingSourceSelection = !!(
      paymentRequestDetails?.invoice.paymentOptions.isAchAllowed &&
      paymentRequestDetails.invoice.paymentOptions.isCardAllowed
    );

    const paymentWidgetFundingSourceType = guestPayorToWidgetTypes[fundingSourceType];

    const { data: paymentWidgetUrlData } = usePaymentWidgetUrl({
      fundingSourceType: paymentWidgetFundingSourceType,
      paymentRequestLink,
    });

    return (
      <FiservPaymentScreen
        onViewInvoice={onViewInvoice}
        isLoading={isLoadingPaymentRequestDetails}
        paymentRequestDetails={paymentRequestDetails}
        paymentWidgetUrl={paymentWidgetUrlData?.paymentWidgetUrl}
        onSelectFundingSourceType={onSelectFundingSourceType}
        selectedFundingSource={fundingSourceType}
        shouldAllowFundingSourceSelection={shouldAllowFundingSourceSelection}
        ref={ref}
      />
    );
  }
);

FiservPaymentActivity.displayName = 'FiservPaymentActivity';
