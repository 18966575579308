import { CreatePaymentParameters, PaymentBillDetails, PaymentCreationResult, usePayments } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { convertDateTimeToDateString } from '@melio/platform-utils';
import { useState } from 'react';

import { getDefaultMemo } from '../BatchPayments.activity.utils';
import { SchedulePaymentIntent } from '../types';
import {
  PaymentSchedulingOutcome,
  PaymentSchedulingOutcomeError,
  PaymentSchedulingOutcomeSuccess,
  PaymentSchedulingResult,
} from './types';

const getCreatePaymentPayload = (
  extendedPaymentIntent: SchedulePaymentIntent,
  shouldIncludeDeliveryDate: boolean
): CreatePaymentParameters | undefined => {
  const { paymentIntent, vendor } = extendedPaymentIntent;

  const vendorId = vendor.id;
  const {
    fundingSourceId,
    deliveryMethodId,
    noteToVendor,
    effectiveScheduledDate: scheduledDate,
    effectiveDeliveryDate: deliveryDate,
    billPayments,
    selectedDeliveryPreferenceType: deliveryPreference,
    requireWithdrawApproval,
    requireDepositApproval,
    paymentPurpose,
  } = paymentIntent;

  if (!fundingSourceId || !billPayments || !deliveryMethodId || !deliveryPreference || !scheduledDate || !vendorId) {
    return undefined;
  }

  const paymentBillsInfo = billPayments.map(
    (billPayment): PaymentBillDetails => ({
      id: billPayment.billId,
      paymentAmount: billPayment.amount,
    })
  );

  const createPaymentParameters: CreatePaymentParameters = {
    scheduledDate,
    deliveryDate: shouldIncludeDeliveryDate ? deliveryDate?.toISOString() : undefined,
    deliveryMethodId,
    fundingSourceId,
    vendorId,
    noteToVendor: noteToVendor || getDefaultMemo(extendedPaymentIntent),
    deliveryPreference,
    paymentBillsInfo,
    requireWithdrawApproval,
    requireDepositApproval,
    paymentPurpose,
    etaDetails: extendedPaymentIntent.etaDetails,
  };
  return createPaymentParameters;
};

const isPaymentCreationSuccess = (result: PaymentSchedulingOutcome): result is PaymentSchedulingOutcomeSuccess =>
  result.paymentCreationResult.status === 'success';

const isPaymentCreationFailed = (result: PaymentSchedulingOutcome): result is PaymentSchedulingOutcomeError =>
  result.paymentCreationResult.status === 'error';

const parseSchedulingResults = (results: PaymentSchedulingOutcome[]): PaymentSchedulingResult => {
  const confirmedPaymentIntents = results.filter(isPaymentCreationSuccess);
  const confirmedPaymentIds = confirmedPaymentIntents.map(({ paymentCreationResult }) => paymentCreationResult.data.id);

  const failedPaymentIntents = results.filter(isPaymentCreationFailed);

  const allFailed = results.length > 0 && failedPaymentIntents.length === results.length;

  return {
    confirmedPaymentIds,
    failedPaymentIntents,
    containsSuccessfulPaymentsScheduling: confirmedPaymentIds.length > 0 && !allFailed,
    allFailed,
  };
};

export const useSubmitBatchPayments = () => {
  const [paymentSchedulingResult, setPaymentSchedulingResult] = useState<PaymentSchedulingResult>(
    parseSchedulingResults([])
  );

  const { batchCreate: createBatchPayments, isCreating } = usePayments({ enabled: false });
  const [addDeliveryDateToCreateAndUpdate] = useDevFeature<boolean>(
    FeatureFlags.AddDeliveryDateToCreateAndUpdatePayment,
    false
  );
  const createPayments = async ({
    paymentIntentsWithDerivatives = [],
  }: {
    paymentIntentsWithDerivatives?: SchedulePaymentIntent[];
  }) => {
    const isDefined = (value: unknown): value is CreatePaymentParameters => !!value;
    const prepareSchedulingDates = (data: CreatePaymentParameters) =>
      convertDateTimeToDateString(data, ['scheduledDate', 'deliveryDate']);

    const payments = paymentIntentsWithDerivatives
      .map((paymentIntentWithDerivatives) =>
        getCreatePaymentPayload(paymentIntentWithDerivatives, addDeliveryDateToCreateAndUpdate)
      )
      .filter(isDefined)
      .map(prepareSchedulingDates);

    const batchCreatedPaymentsResult: PaymentCreationResult[] = await createBatchPayments({ payments });
    const paymentIntentsSchedulingResponses: PaymentSchedulingOutcome[] = paymentIntentsWithDerivatives.map(
      (paymentIntentWithDerivatives, index) => {
        const paymentCreationResult = batchCreatedPaymentsResult[index] || ({} as PaymentCreationResult);

        return {
          paymentIntentWithDerivatives,
          paymentCreationResult,
        };
      }
    );

    const paymentSchedulingResult = parseSchedulingResults(paymentIntentsSchedulingResponses);
    setPaymentSchedulingResult(paymentSchedulingResult);
    return { ...paymentSchedulingResult };
  };
  return { createPayments, paymentResults: { ...paymentSchedulingResult }, isCreating };
};
