import { BaseModal, Button, Container, Group, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { CopyOwnerEmailToClipboradButton } from '../../components/CopyOwnerEmailToClipboradButton';

type RequestSubscriptionUpgradeModaProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

export const RequestSubscriptionUpgradeModal = withAnalyticsContext<RequestSubscriptionUpgradeModaProps>(
  ({ isOpen, onDismiss, setAnalyticsProperties }) => {
    setAnalyticsProperties({
      PageName: 'request-upgrade',
      Flow: 'existing-user-transition',
      Intent: 'contact-company-owner-for-subscribing',
    });

    const { track } = useAnalytics();
    const { isExtraSmallScreen } = useBreakpoint();
    const { formatMessage } = useMelioIntl();
    const { data: dataAccountMe, isLoading: isAccountLoading } = useAccount({ id: 'me' });
    const owner = dataAccountMe?.organizationOwner;

    useAnalyticsView('Organization', isOpen);

    const onExit = () => {
      track('Organization', 'Click', {
        Intent: 'exit',
        Cta: 'exit',
      });
      onDismiss();
    };

    const onClose = () => {
      track('Organization', 'Click', {
        Intent: 'got-it',
        Cta: 'got-it',
      });
      onDismiss();
    };

    return (
      <BaseModal
        isOpen={isOpen}
        onClose={onExit}
        size="small"
        data-testid="request-subscription-upgrade-modal"
        isLoading={isAccountLoading}
      >
        <BaseModal.Body>
          <Container
            paddingX={isExtraSmallScreen ? 'xs' : 'none'}
            paddingY={isExtraSmallScreen ? 'xs' : 's'}
            overflow="auto"
          >
            <Group variant="vertical" spacing="l">
              <Text textStyle="heading2Semi" as="h1">
                {formatMessage('activities.subscription.requestSubscriptionUpgradeModal.title')}
              </Text>
              {owner?.firstName && owner?.lastName && (
                <Text data-testid="request-subscription-upgrade-modal-description">
                  {formatMessage('activities.subscription.requestSubscriptionUpgradeModal.description', {
                    companyName: dataAccountMe?.company?.name,
                  })}
                </Text>
              )}

              {owner?.email && (
                <Group variant="horizontal" spacing="xxxs" alignItems="center">
                  <CopyOwnerEmailToClipboradButton ownerEmail={owner?.email} />
                  <Text data-testid="request-subscription-upgrade-modal-owner-email">{owner.email}</Text>
                </Group>
              )}

              <Group spacing="none" variant="vertical" width="full" alignItems="flex-end">
                <Button
                  label={formatMessage('activities.subscription.requestSubscriptionUpgradeModal.cta')}
                  onClick={onClose}
                  data-testid="request-subscription-upgrade-modal-cta"
                />
              </Group>
            </Group>
          </Container>
        </BaseModal.Body>
      </BaseModal>
    );
  }
);
