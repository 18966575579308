import { Tourtip, TourtipStep, TourtipSteps, useBreakpoint } from '@melio/penny';
import { useUserPreference, useUserPreferences } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { compact } from 'lodash';
import { useCallback, useEffect } from 'react';

import { createTestIdSelector } from '../../../../utils';
import { usePayDashboardTabsToShow } from '../../hooks/usePayDashboardTabsToShow';
import { PayDashboardTabs } from '../../types';

type TooltipInfo = TourtipStep & {
  onDismiss: () => void;
};

type Props = {
  activeTab?: string;
};

const convertToStep = ({ onDismiss, ...rest }: TooltipInfo): TourtipStep => rest;

export const NewFeaturesTour = (props: Props) => {
  const { activeTab } = props;

  const toolTipsItems = useGetNewFeaturesTourSteps(activeTab);

  const handleClose = useCallback(() => {
    toolTipsItems.forEach((item) => item.onDismiss());
  }, [toolTipsItems]);

  if (!toolTipsItems || toolTipsItems.length == 0) {
    return null;
  }

  const steps = toolTipsItems.map((item) => convertToStep(item));

  return (
    <Tourtip
      data-testid="tabs-tooltips"
      steps={steps as TourtipSteps}
      onTourDismiss={handleClose}
      onTourComplete={handleClose}
      defaultLabels={{
        nextButtonLabel: 'Got it',
        prevButtonLabel: '',
      }}
    />
  );
};

const preferenceKeys: Partial<Record<PayDashboardTabs, string>> = {
  [PayDashboardTabs.Financing]: 'isDismissedFinancingTooltip',
};

const useGetNewFeaturesTourSteps = (activeTab?: string): TooltipInfo[] => {
  const { formatMessage } = useMelioIntl();
  const { create } = useUserPreferences({ enabled: false });
  const { shouldShow } = usePayDashboardTabsToShow();
  const { isExtraSmallScreen } = useBreakpoint();

  const [isFinancingToolTipEnabledFF] = useDevFeature(FeatureFlags.FinancingTabToolTipEnabled, false);

  const { data: _isDismissedFinancingTooltip } = useUserPreference({
    id: preferenceKeys.financing,
  });

  const handleTooltipDismiss = useCallback(
    (id: PayDashboardTabs) => {
      const key = preferenceKeys[id];
      if (key) {
        create({ userPreference: { key, value: 'true' } });
      }
    },
    [create]
  );

  const isDismissedFinancingTooltip = _isDismissedFinancingTooltip
    ? _isDismissedFinancingTooltip.value === 'true'
    : true;

  const shouldShowFinancingTooltip =
    isFinancingToolTipEnabledFF && !isDismissedFinancingTooltip && shouldShow[PayDashboardTabs.Financing];

  useEffect(() => {
    if (activeTab === PayDashboardTabs.Financing && shouldShowFinancingTooltip) {
      handleTooltipDismiss(PayDashboardTabs.Financing);
    }
  }, [activeTab, handleTooltipDismiss, shouldShowFinancingTooltip]);

  return compact([
    shouldShowFinancingTooltip &&
      activeTab !== PayDashboardTabs.Financing && {
        target: createTestIdSelector('pay-dashboard-activity-pay-dashboard-tabs-list-tab-financing'),
        title: formatMessage('activities.payDashboard.tabs.financing.tooltip.title'),
        nextButtonLabel: 'Got it',
        prevButtonLabel: '',
        description: formatMessage('activities.payDashboard.tabs.financing.tooltip.description'),
        placement: isExtraSmallScreen ? 'bottom-start' : 'bottom-end',
        onDismiss: () => handleTooltipDismiss(PayDashboardTabs.Financing),
      },
  ]);
};
