import { ActionBar, ActionBarProps, Button, NakedButton } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { sumBy, values } from 'lodash';
import { useCallback, useMemo } from 'react';

import { VENDORS_SELECTION_FOOTER_REVIEW_AND_PAY_BUTTON_ID } from '../consts';
import { VendorTabAmountCellAmounts } from '../types';
import { useVendorsBatchActionsData } from '../useVendorsBatchActionsData';
import { useVendorsBatchPayments } from '../useVendorsBatchPayments';

type VendorsSelectionFooterProps = {
  vendorIds: string[];
  totalCount: number;
  onReviewAndPay: () => void;
  onCancel: VoidFunction;
  vendorsAmountsMap: VendorTabAmountCellAmounts;
};

export const VendorsSelectionFooter = ({
  vendorIds,
  totalCount,
  onCancel,
  onReviewAndPay,
  vendorsAmountsMap,
}: VendorsSelectionFooterProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { track } = useAnalytics();

  const { selectedUnpaidBills, selectedVendorsIdsWithEmptyAmounts, selectedVendorsAmountsMap, isLoadingBills } =
    useVendorsBatchActionsData({
      selectedVendorIds: vendorIds,
      vendorsAmountsMap,
    });

  const { isCreatingBills, handleGoToBatchPayments } = useVendorsBatchPayments({
    amountsToPay: selectedVendorsAmountsMap,
    unpaidBills: selectedUnpaidBills,
  });

  const totalOpenBalance = useMemo(() => {
    const totalBalanceSelectedUnpaidBills = sumBy(selectedUnpaidBills, (bill) => bill.balance);
    const totalBalanceSelectedInputAmounts = sumBy(values(selectedVendorsAmountsMap), (value) => Number(value));
    return totalBalanceSelectedInputAmounts + totalBalanceSelectedUnpaidBills;
  }, [selectedUnpaidBills, selectedVendorsAmountsMap]);

  const summaryItems: ActionBarProps['summaryItems'] = [
    {
      label: formatMessage('activities.payDashboard.vendorsTab.selectionFooter.allVendors'),
      value: totalCount.toString(),
      testId: 'vendors-selection-footer-all-vendors-count',
    },
    {
      label: formatMessage('activities.payDashboard.vendorsTab.selectionFooter.selectedVendors'),
      value: vendorIds.length.toString(),
      testId: 'vendors-selection-footer-vendors-count',
    },
    {
      label: formatMessage('activities.payDashboard.vendorsTab.selectionFooter.totalAmount'),
      value: formatCurrency(totalOpenBalance),
      testId: 'vendors-selection-footer-total-amount',
    },
  ];

  const handleReviewAndPay = useCallback(() => {
    track('Dashboard', 'Click', {
      Cta: 'review-and-pay',
      VendorsSelected: vendorIds.length,
      VendorsAmount: totalOpenBalance,
    });

    selectedVendorsIdsWithEmptyAmounts.length > 0 ? onReviewAndPay() : handleGoToBatchPayments();
  }, [handleGoToBatchPayments, onReviewAndPay, selectedVendorsIdsWithEmptyAmounts, totalOpenBalance, vendorIds, track]);

  const handleCancel = useCallback(() => {
    track('Dashboard', 'Click', {
      Cta: 'cancel',
      VendorsSelected: vendorIds.length,
      VendorsAmount: totalOpenBalance,
    });

    onCancel();
  }, [onCancel, totalOpenBalance, vendorIds, track]);

  return (
    <ActionBar
      id="vendors-selection-footer"
      isOpen={vendorIds.length > 0}
      isLoading={isLoadingBills}
      maxWidth={1600}
      actions={[
        {
          component: NakedButton,
          props: {
            label: formatMessage('activities.payDashboard.vendorsTab.selectionFooter.cancel.label'),
            'aria-label': formatMessage('activities.payDashboard.vendorsTab.selectionFooter.cancel.ariaLabel'),
            onClick: handleCancel,
            variant: 'invert',
          },
        },
        {
          component: Button,
          props: {
            id: VENDORS_SELECTION_FOOTER_REVIEW_AND_PAY_BUTTON_ID,
            label: formatMessage('activities.payDashboard.vendorsTab.selectionFooter.reviewAndPay.label'),
            onClick: handleReviewAndPay,
            isLoading: isCreatingBills,
            'aria-label': formatMessage('activities.payDashboard.vendorsTab.selectionFooter.reviewAndPay.ariaLabel', {
              totalAmount: formatCurrency(totalOpenBalance),
              vendorsCount: vendorIds.length,
            }),
          },
        },
      ]}
      summaryItems={summaryItems}
    />
  );
};
