import {
  InvalidVendorBankAccountModal,
  MissingVendorInfoBannerWidget,
  MissingVendorInfoDrawer,
  useInvalidBankAccountModal,
  useMissingVendorInfo,
} from '@melio/ap-widgets';
import { useAnalytics } from '@melio/platform-analytics';
import { Vendor } from '@melio/platform-api';

type Props = {
  selectedVendor?: Vendor;
  linkToUnpaidBills?: string;
};

export const VendorBannersOnPayFlow = ({ selectedVendor, linkToUnpaidBills }: Props) => {
  const {
    invalidBankAccountModalData,
    onInvalidVendorBankAccountModalClose,
    onInvalidVendorBankAccountModalSuccess,
    showInvalidBankAccountModal,
  } = useInvalidBankAccountModal();

  const { track } = useAnalytics();

  const {
    shouldShowMissingVendorInfoBanner,
    shouldShowMissingVendorInfoDrawer,
    handleMissingInfoVendorDrawerClose,
    handleMissingInfoVendorDrawerDone,
    showMissingVendorInfoDrawer,
  } = useMissingVendorInfo({
    selectedVendor,
    showInvalidBankAccountModal,
    onDoneAnalytics: () => {
      track('Payment', 'Status', {
        Status: 'success',
        ErrorType: 'vendor-details-update',
        VendorId: selectedVendor?.id,
      });
    },
    onCloseAnalytics: () => {
      track('Payment', 'Click', {
        ErrorType: 'vendor-details-update',
        VendorId: selectedVendor?.id,
        Cta: 'exit',
      });
    },
  });

  const handleEditMissingVendorDetailsClick = () => {
    track('Payment', 'Click', {
      UserMessage: 'missing-vendor-details',
      Cta: 'edit-vendor-details',
    });
    showMissingVendorInfoDrawer();
  };

  return (
    <>
      {shouldShowMissingVendorInfoBanner && selectedVendor ? (
        <MissingVendorInfoBannerWidget
          vendorName={selectedVendor.name}
          onEditClick={handleEditMissingVendorDetailsClick}
        />
      ) : null}
      {shouldShowMissingVendorInfoDrawer && selectedVendor ? (
        <MissingVendorInfoDrawer
          onClose={handleMissingInfoVendorDrawerClose}
          vendorId={selectedVendor.id}
          linkToUnpaidBills={linkToUnpaidBills}
          onDone={handleMissingInfoVendorDrawerDone}
        />
      ) : null}
      {invalidBankAccountModalData ? (
        <InvalidVendorBankAccountModal
          isOpen
          onClose={onInvalidVendorBankAccountModalClose}
          invalidVendorBankAccountDetails={invalidBankAccountModalData}
          onSuccess={onInvalidVendorBankAccountModalSuccess}
        />
      ) : null}
    </>
  );
};
