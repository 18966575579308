import { Form, Group, Text, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { identity } from 'lodash';
import { date, number, object, SchemaOf, string } from 'yup';

import { PaymentRequestDetailsFormFields, PaymentRequestDetailsWidgetProps } from '../types';

export type PaymentRequestDetailsFormProps = {
  paymentRequest: Partial<PaymentRequestDetailsWidgetProps['paymentRequest']>;
};

export const getSchema = () =>
  object().shape({
    vendorId: string(),
    totalAmount: number(),
    invoiceNumber: string().nullable(),
    creationDate: date(),
    dueDate: date().nullable(),
    customerNote: string().nullable(),
  }) as SchemaOf<PaymentRequestDetailsFormFields>;

export const PaymentRequestDetailsForm = forwardRef<PaymentRequestDetailsFormProps, 'form'>(
  ({ paymentRequest, ...props }, ref) => {
    const defaultValues = {
      vendorId: paymentRequest?.vendorId,
      totalAmount: paymentRequest?.totalAmount,
      invoiceNumber: paymentRequest?.invoiceNumber ?? '',
      creationDate: paymentRequest?.history?.createdAt,
      dueDate: paymentRequest?.dueDate,
      customerNote: paymentRequest?.customerNote ?? '',
    };

    const { formatMessage } = useMelioIntl();
    const { formProps, registerField } = useMelioForm<PaymentRequestDetailsFormFields>({
      onSubmit: () => undefined,
      schema: getSchema(),
      defaultValues,
      isSaving: false,
      onSubmissionStateChange: () => undefined,
      subscribeToDefaultValuesChanges: true,
    });
    const vendorDescription = [paymentRequest?.vendor?.nickname, paymentRequest?.vendor?.accountNumber]
      .filter(identity)
      .join(' | ');

    return (
      <Form
        data-component="PaymentRequestDetailsForm"
        isViewMode
        isReadOnly
        size="small"
        {...props}
        {...formProps}
        ref={ref}
      >
        <Group spacing="xxs" variant="vertical">
          <Form.TextField
            labelProps={{ label: formatMessage('widgets.paymentRequestDetails.form.vendorName.label') }}
            placeholder={formatMessage('widgets.paymentRequestDetails.form.vendorName.placeholder')}
            viewModePlaceholder={formatMessage('widgets.paymentRequestDetails.form.vendorName.viewModePlaceholder')}
            {...registerField('vendorId')}
            value={paymentRequest?.vendor?.name}
          />
          {vendorDescription && (
            <Text textStyle="body4" color="global.neutral.800">
              {vendorDescription}
            </Text>
          )}
        </Group>
        <Form.AmountField
          labelProps={{ label: formatMessage('widgets.paymentRequestDetails.form.amount.label') }}
          viewModePlaceholder={formatMessage('widgets.paymentRequestDetails.form.amount.viewModePlaceholder')}
          {...registerField('totalAmount')}
        />
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.paymentRequestDetails.form.invoiceNumber.label') }}
          placeholder={
            paymentRequest?.invoiceNumber ??
            formatMessage('widgets.paymentRequestDetails.form.invoiceNumber.placeholder')
          }
          viewModePlaceholder={formatMessage('widgets.paymentRequestDetails.form.invoiceNumber.viewModePlaceholder')}
          {...registerField('invoiceNumber')}
        />
        <Form.DateField
          labelProps={{ label: formatMessage('widgets.paymentRequestDetails.form.creationDate.label') }}
          {...registerField('creationDate')}
        />
        <Form.DateField
          labelProps={{ label: formatMessage('widgets.paymentRequestDetails.form.dueDate.label') }}
          placeholder={formatMessage('widgets.paymentRequestDetails.form.dueDate.placeholder')}
          viewModePlaceholder={formatMessage('widgets.paymentRequestDetails.form.dueDate.viewModePlaceholder')}
          {...registerField('dueDate')}
        />
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.paymentRequestDetails.form.note.label') }}
          placeholder={formatMessage('widgets.paymentRequestDetails.form.note.placeholder')}
          viewModePlaceholder={formatMessage('widgets.paymentRequestDetails.form.note.viewModePlaceholder')}
          {...registerField('customerNote')}
          data-private
        />
      </Form>
    );
  }
);

PaymentRequestDetailsForm.displayName = 'PaymentRequestDetailsForm';
