import { Group } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Dictionary } from 'lodash';

import { FundingSourceCardList } from './FundingSourceCardList';

export const FundingSourceGroupList = ({
  fundingSourcesGroupedByType,
  onSelected,
  selectedId,
  onAddBank,
  onAddCard,
  currentFundingSourceId,
}: {
  fundingSourcesGroupedByType: Dictionary<FundingSource[]>;
  onSelected: (fundingSource: FundingSource) => void;
  selectedId: string | null;
  onAddBank: VoidFunction;
  onAddCard: VoidFunction;
  currentFundingSourceId?: string;
}) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical" width="full">
      <FundingSourceCardList
        fundingSourceType="bank-account"
        fundingSources={fundingSourcesGroupedByType['bank-account'] || []}
        onCardClick={onSelected}
        selectedId={selectedId}
        onAddClick={onAddBank}
        addLabel={formatMessage('activities.subscription.checkout.fundingSourceSelection.bank.addAnother')}
        currentFundingSourceId={currentFundingSourceId}
      />
      <FundingSourceCardList
        fundingSourceType="card"
        fundingSources={fundingSourcesGroupedByType['card'] || []}
        onCardClick={onSelected}
        selectedId={selectedId}
        onAddClick={onAddCard}
        addLabel={formatMessage('activities.subscription.checkout.fundingSourceSelection.card.addAnother')}
        currentFundingSourceId={currentFundingSourceId}
      />
    </Group>
  );
};
