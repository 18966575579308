import { OnboardingApiClient, PatchOnBoardingRequest } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getOnboarding = (_id: string) => OnboardingApiClient.getOnboarding();

const updateOnboarding = (_id: string, data: PatchOnBoardingRequest) => OnboardingApiClient.patchOnboarding(data);

export type UseOnboardingProps = UseModelProps<typeof getOnboarding>;

export const useOnboarding = (props: UseOnboardingProps = {}) => {
  const { data, ...query } = useModel({
    ...props,
    id: 'onboarding-api',
    queryKey: 'OnboardingApi',
    queryFn: getOnboarding,
    createFn: OnboardingApiClient.postOnboarding,
    updateFn: updateOnboarding,
  });

  return {
    ...query,
    data,
  };
};

export type OnboardingModel = ReturnType<typeof useOnboarding>;
