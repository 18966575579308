import { Button, LoadingContainer } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { FundingSource, useAccount, useFundingSources } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useMemo } from 'react';

import { useAccountingFirmBillingFees } from '../../../../api/entities';
import { FullScreenLayout } from '../../../../components/FullScreenLayout/FullScreenLayout';
import { getFundingSourceDetails } from '../../../../utils/fundingSources';

type BankOrCardFundingSourceTypes = Extract<FundingSource['type'], 'bank-account' | 'card'>;
const descriptionByFundingType: Record<BankOrCardFundingSourceTypes, MessageKey> = {
  'bank-account': 'activities.accountants.BillingFeeCreatedSuccessfully.bank.subHeader.label',
  card: 'activities.accountants.BillingFeeCreatedSuccessfully.card.subHeader.label',
};

const getFundingSourceTypeDescription = (type?: FundingSource['type']): MessageKey => {
  if (type && type in descriptionByFundingType) {
    return descriptionByFundingType[type as BankOrCardFundingSourceTypes];
  }
  return 'activities.accountants.BillingFeeCreatedSuccessfully.general.subHeader.label';
};

type Props = {
  onDone: VoidFunction;
};

export const BillingFeeCreatedSuccessfully = ({ onDone }: Props) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const { data: accountingFirmBillingFee, isLoading: isAccountingFirmBillingFeesLoading } =
    useAccountingFirmBillingFees();
  const { data: fundingSources, isLoading: isFundingSourceLoading } = useFundingSources();
  const { data: me, isLoading: isAccountMeLoading } = useAccount({ id: 'me' });

  const isLoading = isFundingSourceLoading || isAccountingFirmBillingFeesLoading || isAccountMeLoading;

  const pageTitle = formatMessage(`activities.accountants.BillingFeeCreatedSuccessfully.header.label`);

  const currentBillingFundingSource = fundingSources?.find(
    (fundingSource) => fundingSource.id === accountingFirmBillingFee?.accountingFirmBillingFee?.fundingSourceId
  );
  const fundingSourceDetails = currentBillingFundingSource
    ? getFundingSourceDetails(currentBillingFundingSource)
    : null;

  const description = getFundingSourceTypeDescription(currentBillingFundingSource?.type);
  const descriptionData = {
    last4Digits: fundingSourceDetails?.lastFourDigits,
    firmName: me?.company.name,
  };

  const analyticsProperties = useMemo(
    () => ({
      PageName: 'payment-method-added-for-fees',
      Flow: 'set-fs-for-fees',
      EntryPoint: 'settings-page',
      Intent: 'set-fs-for-fees',
      FundingSourceId: currentBillingFundingSource?.id,
    }),
    [currentBillingFundingSource]
  );

  useEffect(() => {
    track('PaymentMethod', 'View', {
      ...analyticsProperties,
    });
  }, [analyticsProperties, track]);

  const handleDone = () => {
    track('PaymentMethod', 'View', {
      ...analyticsProperties,
      Cta: 'done',
    });
    onDone();
  };

  return (
    <LoadingContainer isLoading={isLoading}>
      <FullScreenLayout
        title={pageTitle}
        content={description ? formatMessage(description, descriptionData) : ''}
        illustration="success"
        actions={
          <>
            <Button
              isFullWidth={false}
              data-testid="billing-fee-created-successfully-done"
              variant="primary"
              label={formatMessage('activities.accountants.BillingFeeCreatedSuccessfully.button.label')}
              onClick={handleDone}
            />
          </>
        }
      />
    </LoadingContainer>
  );
};
