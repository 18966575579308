import { useDebounceCallback } from '@melio/platform-utils';
import { parse, stringify as _stringify } from 'qs';
import { SetStateAction, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { NavigateOptions, useSearchParams as _useSearchParams } from 'react-router-dom';

type Dispatch<A> = (value: A, options?: NavigateOptions) => void;
type Dispatcher<T> = Dispatch<SetStateAction<Partial<T>>>;

export const useSearchParams = <T extends Record<string, unknown>>(): [Partial<T>, Dispatcher<T>] => {
  const seSearchParamsResult = _useSearchParams();

  const urlSearchParams = toObject<T>(seSearchParamsResult[0]);

  const setSearchParams: Dispatcher<T> = (arg1, options) => {
    if (typeof arg1 === 'function') {
      seSearchParamsResult[1]((current) => {
        const next = arg1(toObject<T>(current));
        return toSearchParams(next);
      }, options);
    } else {
      seSearchParamsResult[1](toSearchParams(arg1), options);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return [useMemo(() => urlSearchParams, [stringify(urlSearchParams)]), useDebounceCallback(setSearchParams, 5)];
};

export function stringify(obj: Record<string, unknown>) {
  return _stringify(obj, {
    sort: (a: string, b: string) => a.localeCompare(b),
    arrayFormat: 'repeat', // default is 'brackets' which serializes arrays as arr[]=4&arr[]=5 and we want the arrays to serialize as ?arr=4&arr=5 (common with React Query)
  });
}

export const toObject = <T>(searchParams: URLSearchParams) => parse(searchParams.toString()) as Partial<T>;

export const toSearchParams = <T extends Record<string, unknown>>(obj: T) => new URLSearchParams(stringify(obj));
