import { useNonEntityMutation } from '@melio/api-client';
import { ApiKeys, SubscriptionsApiClient } from '@melio/platform-api-axios-client';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

type QueryFN = (_?: string) => ReturnType<typeof SubscriptionsApiClient.acceptDowngrade>;

export const useAcceptSubscriptionDowngrade = () => {
  const queryClient = useQueryClient();
  const [downgradeCompleted, setDowngradeCompleted] = useState(false);

  const acceptDowngrade = useNonEntityMutation<QueryFN, void>(
    SubscriptionsApiClient.acceptDowngrade,
    [ApiKeys.SubscriptionsApi, 'collection', 'acceptDowngrade'],
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('SubscriptionsApi');
        setDowngradeCompleted(true);
      },
    }
  );

  return {
    acceptDowngrade: acceptDowngrade.mutateAsync,
    isLoading: acceptDowngrade.isLoading,
    isError: acceptDowngrade.isError,
    isSuccess: acceptDowngrade.isSuccess,
    isDowngradeCompleted: downgradeCompleted,
  };
};
