import { MouseEventHandler } from 'react';
import { MenuItem, useStyleConfig } from '@chakra-ui/react';
import { forwardRef } from '@melio/platform-utils';

// Since DropdownMenuItem is also the type we expose outside we're not using the Props/Type ending fo this type to not confuse devs
export type DropdownMenuItem = {
  label: string;
  variant?: 'default' | 'danger';
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const DropdownMenuItemComponent = forwardRef<DropdownMenuItem, 'button'>(({ label, variant, ...props }, ref) => {
  const styles = useStyleConfig('DropdownMenuItem', { variant });

  return (
    <MenuItem data-component="DropdownMenuItem" sx={styles} ref={ref} {...props}>
      {label}
    </MenuItem>
  );
});
