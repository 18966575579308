import { number, object, SchemaOf } from 'yup';
import { Button, Form, FormSelectNewOption, useFormSubmissionController, useMelioForm } from '@melio/penny';
import { OAuthOrganizations } from '@melio/platform-api';

import { FullScreenContainer } from '@/cl/components/FullScreenContainer/FullScreenContainer.component';
import { usePlatformIntl } from '@/translations/Intl';

const useSchema = () => {
  const { formatMessage } = usePlatformIntl();

  return object().shape({
    selectedOrganizationId: number().required(
      formatMessage('widgets.onboarding.selectOrganizationForm.email.validation.required'),
    ),
  }) as SchemaOf<SelectOrganizationFormFields>;
};

export type SelectOrganizationFormFields = {
  selectedOrganizationId?: number;
};

type SelectOrganizationFormParams = {
  organizations: OAuthOrganizations;
  onDone: (selectedOrganizationId: number) => void;
};

export const SelectOrganizationForm = ({ organizations = [], onDone }: SelectOrganizationFormParams) => {
  const { formatMessage } = usePlatformIntl();

  const defaultValues = {};

  const onSubmit = async (data: SelectOrganizationFormFields) => {
    try {
      const { selectedOrganizationId } = data;
      onDone(selectedOrganizationId!);
    } catch (e) {
      reset(defaultValues);
      console.error(e);
    }
  };

  const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<SelectOrganizationFormFields>();

  const { formProps, registerField, reset } = useMelioForm<SelectOrganizationFormFields>({
    defaultValues,
    onSubmit,
    onSubmissionStateChange,
    schema: useSchema(),
  });

  return (
    <FullScreenContainer
      title={formatMessage('widgets.onboarding.selectOrganizationForm.title')}
      subtitle={formatMessage('widgets.onboarding.selectOrganizationForm.subtitle')}
    >
      <Form data-component="SelectOrganizationForm" size="large" {...formProps}>
        <Form.SelectNew
          options={
            organizations.map(({ name = '', id }) => ({
              label: name,
              value: id,
            })) as unknown as FormSelectNewOption[]
          }
          shouldHideClearButton
          autoFocus
          {...registerField('selectedOrganizationId')}
          labelProps={{ label: formatMessage('widgets.onboarding.selectOrganizationForm.email.label') }}
          placeholder={formatMessage('widgets.onboarding.selectOrganizationForm.email.placeholder')}
        />
      </Form>
      <Button
        variant={'primary'}
        size={'large'}
        {...submitButtonProps}
        label={formatMessage('widgets.onboarding.selectOrganizationForm.button.text')}
      />
    </FullScreenContainer>
  );
};
