import { Group } from '@melio/penny';
import { ApprovalDecision, PaymentFullyExpanded } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { RefObject } from 'react';

import { MicroDepositModalRefHandlers } from '../../../../funding-sources';
import { RefundCompletedSectionBanner } from '../completed/RefundCompleted';
import { RefundInProgressSectionBanner } from '../in-progress/RefundInProgress';
import { PaymentApprovalPendingAlert } from '../scheduled/PaymentApprovalPendingAlert';
import { PaymentPendingVerificationAlert } from '../scheduled/PaymentPendingVerificationAlert';
import { PaymentScheduledActionAlert } from '../scheduled/PaymentScheduledActionAlert';
import { usePaymentDetailsSectionType } from '../utils';
import { PaymentFailedSectionBanner } from './PaymentFailedSectionBanner';

type Props = {
  payment: PaymentFullyExpanded;
  approvalDecisions?: ApprovalDecision[];
  microDepositModalRef?: RefObject<MicroDepositModalRefHandlers>;
};

export const PaymentBannerSection = ({ payment, approvalDecisions, microDepositModalRef }: Props) => {
  const [isSecureFileUploadEnabled] = useDevFeature<boolean>(FeatureFlags.SecureFileUpload, false);
  const isPendingVerification = isSecureFileUploadEnabled && payment.paymentPending?.uploadDocuments;
  const paymentStatus = usePaymentDetailsSectionType(payment, approvalDecisions);
  switch (paymentStatus) {
    case 'payment-scheduled':
      return isPendingVerification ? (
        <PaymentPendingVerificationAlert paymentId={payment.id} />
      ) : payment.approvalDecisionStatus === 'pending' ? (
        <Group variant="vertical" spacing="m">
          <PaymentApprovalPendingAlert payment={payment} />

          <PaymentScheduledActionAlert payment={payment} microDepositModalRef={microDepositModalRef} />
        </Group>
      ) : (
        <PaymentScheduledActionAlert payment={payment} microDepositModalRef={microDepositModalRef} />
      );
    case 'refund-in-progress':
      return <RefundInProgressSectionBanner payment={payment} />;
    case 'refund-completed':
      return <RefundCompletedSectionBanner payment={payment} />;
    case 'payment-failed':
      return <PaymentFailedSectionBanner payment={payment} />;
    default:
      return null;
  }
};
