import { Modal, Text, useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { JoinOrganizationRequest, useJoinOrganizationRequest } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

type Props = {
  joinOrganizationRequest: JoinOrganizationRequest;
  onClose: VoidFunction;
};

export const ResendJoinOrganizationRequestModal = withAnalyticsContext<Props>(
  ({ joinOrganizationRequest, onClose, setAnalyticsProperties }) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    const { toast } = useToast();
    const { resendJoinOrganizationRequestAccount, isMutating } = useJoinOrganizationRequest({
      id: joinOrganizationRequest.id,
      enabled: false,
    });

    setAnalyticsProperties({
      PageName: 'resend-request',
      Intent: 'resend-request',
    });

    useEffect(() => {
      track('User', 'View');
    }, [track]);

    const handleConfirmClick = async () => {
      track('User', 'Click', {
        Cta: 'resend-request',
      });

      try {
        await resendJoinOrganizationRequestAccount();
        toast({
          type: 'success',
          title: formatMessage('activities.accountants.firmDashboard.resendJoinOrganizationRequestModal.toast.success'),
        });
        track('User', 'Status', {
          PageName: 'clients-dashboard',
          Status: 'success',
        });
        onClose();
      } catch (error) {
        toast({
          type: 'error',
          title: formatMessage('activities.accountants.firmDashboard.resendJoinOrganizationRequestModal.toast.error'),
        });
        track('User', 'Status', {
          PageName: 'clients-dashboard',
          Status: 'failure',
        });
        onClose();
      }
    };

    const handleClose = () => {
      track('User', 'Click', {
        Cta: 'exit',
      });
      onClose();
    };

    return (
      <Modal
        isOpen
        header={formatMessage('activities.accountants.firmDashboard.resendJoinOrganizationRequestModal.title')}
        primaryButton={{
          label: formatMessage('activities.accountants.firmDashboard.resendJoinOrganizationRequestModal.confirm'),
          onClick: () => void handleConfirmClick(),
          variant: 'primary',
          isLoading: isMutating,
        }}
        onClose={handleClose}
        data-testid="resend-join-organization-request-modal"
      >
        <Text>
          {formatMessage('activities.accountants.firmDashboard.resendJoinOrganizationRequestModal.description', {
            companyName: joinOrganizationRequest.companyName,
            email: joinOrganizationRequest.email,
          })}
        </Text>
      </Modal>
    );
  }
);
