/* eslint-disable @typescript-eslint/no-misused-promises */
import { Layout, LoadingContainer } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Header } from './components/Header';
import { WizardStep } from './components/WizardStep';
import { useBillingFeeSettingsWizard } from './hooks/useBillingFeeSettingsWizard';
import { BillingFeeCreatedSuccessfully } from './steps/BillingFeeCreatedSuccessfully/BillingFeeCreatedSuccessfully.activity';
import { SelectClients } from './steps/SelectClients';
import { SelectPaymentMethod } from './steps/SelectPaymentMethod/SelectPaymentMethod.activity';
import { FlowType } from './types';

type AddBillingFeeSettingsWizardProps = { flowType: FlowType };

export const AddBillingFeeSettingsWizard = withAnalyticsContext<AddBillingFeeSettingsWizardProps>(
  ({ flowType, setAnalyticsProperties }) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();

    const {
      fundingSourceIds,
      isFetching,
      accountingFirmBillingFee,
      isError,
      currentStep,
      goBack,
      handleCancel,
      handleSave,
      handleSkipClients,
      handleNext,
      selectedFundingSourceId,
      setSelectedFundingSourceId,
      handleSelectClients,
      isMutating,
      clientsList,
      handleComplete,
      stepsOrder,
      isClientListReady,
    } = useBillingFeeSettingsWizard({
      flowType,
    });

    const handleSelectPaymentMethodLoad = () => {
      setAnalyticsProperties({
        PageName: 'choose-payment-method-to-use-for-fees',
        Flow: 'set-fs-for-fees',
        EntryPoint: 'settings-page',
        FundingSourceIdShown: fundingSourceIds,
        IsFsForFeesSet: !!accountingFirmBillingFee?.fundingSourceId,
        Intent: flowType === 'edit' ? 'edit-fs-for-fees' : 'add-fs-for-fees',
      });
      track('PaymentMethod', 'View');
    };

    const handleSelectClientsLoad = () => {
      setAnalyticsProperties({
        PageName: 'select-clients-to-cover-their-fees',
        Flow: 'set-fs-for-fees',
        EntryPoint: 'settings-page',
        FundingSourceIdShown: fundingSourceIds,
        IsFsForFeesSet: !!accountingFirmBillingFee?.fundingSourceId,
        Intent: 'select-clients-to-cover-fees',
        NumClientsShown: clientsList.items.length,
        NumClientsSelected: clientsList.selectedCount,
        IsAllClientsSelected: clientsList.items.length === clientsList.selectedCount,
      });
      track('Settings', 'View');
    };

    if (isError) {
      return <Navigate to="/error" />;
    }

    return (
      <Layout
        isLoading={isFetching || isMutating}
        data-testid="add-billing-fee-settings-wizard"
        maxWidth="600px"
        paddingContent="s"
        header={{
          content: <Header currentStep={currentStep} steps={stepsOrder} onBack={goBack} onCancel={handleCancel} />,
        }}
      >
        <Routes>
          <Route
            path="funding-source"
            element={
              <WizardStep
                title={formatMessage('activities.accountants.BillingFeePaymentMethod.header.label')}
                description={formatMessage('activities.accountants.BillingFeePaymentMethod.subHeader.label')}
                onNext={handleNext}
                isNextDisabled={!selectedFundingSourceId}
                nextButtonLabel={formatMessage('activities.accountants.BillingFeePaymentMethod.ok.button.label')}
                onLoad={isClientListReady ? handleSelectPaymentMethodLoad : undefined}
              >
                <SelectPaymentMethod
                  onSelectFundingSource={setSelectedFundingSourceId}
                  selectedFundingSourceId={selectedFundingSourceId}
                />
              </WizardStep>
            }
          />
          <Route
            path="clients"
            element={
              <WizardStep
                title={formatMessage('activities.accountants.billingSettings.clientSelection.title')}
                onNext={handleSave}
                nextButtonLabel={formatMessage('activities.accountants.billingSettings.clientSelection.button.save')}
                onSkip={flowType === 'add' ? handleSkipClients : undefined}
                onLoad={handleSelectClientsLoad}
              >
                <LoadingContainer isLoading={!isClientListReady}>
                  <SelectClients onSelectClients={handleSelectClients} clientsList={clientsList} />
                </LoadingContainer>
              </WizardStep>
            }
          />
          <Route path="success/*" element={<BillingFeeCreatedSuccessfully onDone={handleComplete} />} />
        </Routes>
      </Layout>
    );
  }
);

AddBillingFeeSettingsWizard.displayName = 'AddBillingFeeSettingsWizard';
