import { SectionBanner } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import {
  Account,
  DeliveryMethodByPayor,
  DeliveryMethodType,
  FreeChecksData,
  FundingSource,
  SupportedDeliveryMethodTypeOption,
  Vendor,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useCallback, useMemo, useState } from 'react';

import { MonitoredAction } from '../../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { DeliveryMethodSelectionContent } from './components/DeliveryMethodSelectionContent';

export type DeliveryMethodSelectionScreenProps = {
  data: DeliveryMethodByPayor[];
  fundingSource?: FundingSource;
  freeChecks?: FreeChecksData;
  isLoading?: boolean;
  selectedId?: DeliveryMethodByPayor['id'];
  step?: number;
  totalSteps?: number;
  vendor: Vendor;
  onAdd: (onAddArgs: { type: DeliveryMethodByPayor['type']; isInternationalFx?: boolean }) => void;
  onEdit?: (type: Omit<DeliveryMethodByPayor['type'], 'virtual-account'>) => void;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (deliveryMethodId: DeliveryMethodByPayor['id']) => void;
  domesticDeliveryMethodTypeOptions: SupportedDeliveryMethodTypeOption[];
  internationalDeliveryMethodTypeOptions: SupportedDeliveryMethodTypeOption[];
  onSkip?: VoidFunction;
  account?: Account;
};

export const DeliveryMethodSelectionScreen = ({
  data,
  fundingSource,
  freeChecks,
  isLoading,
  selectedId,
  domesticDeliveryMethodTypeOptions,
  internationalDeliveryMethodTypeOptions,
  step,
  vendor,
  totalSteps,
  onEdit,
  onAdd,
  onBack,
  onClose,
  onDone,
  onSkip,
  account,
  ...props
}: DeliveryMethodSelectionScreenProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const [isCloverFreeAchPromotion] = useDevFeature<boolean>(FeatureFlags.CloverFreeAchPromotion, false);

  const deliveryMethods = [...domesticDeliveryMethodTypeOptions, ...internationalDeliveryMethodTypeOptions];

  const shouldShowBankRestrictedPaymentMethodsDescription = deliveryMethods.some(
    (dm) => !dm.supported && dm.reason === 'vendorIsBank'
  );
  const isBankAccountDeliveryMethodAvailable = deliveryMethods.some(
    (dm) => dm.type === DeliveryMethodType.BankAccount && dm.supported
  );

  const [selectedDeliveryMethodId, setSelectedDeliveryMethodId] = useState<DeliveryMethodByPayor['id'] | undefined>(
    selectedId
  );

  const selectedDeliveryMethod = useMemo(
    () => data.find((dm) => dm.id === selectedDeliveryMethodId),
    [data, selectedDeliveryMethodId]
  );

  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const endActionMonitoring = useCallback(() => {
    endAction('funding_source_selection');
    endAction('goods_received_flow');
    endAction('vendor_missing_details_flow');
    endAction('mcc_flow');
    endAction('reconciliation_flow');
  }, [endAction]);

  useAnalyticsView('DeliveryMethod', !isLoading, true, {
    PageName: 'choose-delivery-method',
    Intent: 'choose-delivery-method',
    Flow: 'add-vendor',
    PreSelectedDeliveryMethod: selectedDeliveryMethod?.type || null,
  });

  const handleSkip = () => {
    track('AddDeliveryMethod', 'Chose', {
      Cta: 'skip-for-now',
    });
    onSkip?.();
  };

  return (
    <NewSinglePaymentStepLayout
      data-component="DeliveryMethodSelectionActivity.DeliveryMethodSelectionScreen"
      data-testid="delivery-method-selection-activity-delivery-method-selection-screen"
      ref={endActionMonitoring}
      {...props}
      isLoading={isLoading}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.BackButton onClick={onBack} />
          {step != undefined && totalSteps && (
            <NewSinglePaymentStepLayout.ProgressBar currentStep={step} totalSteps={totalSteps} />
          )}
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        data.length > 0 ? (
          <NewSinglePaymentStepLayout.Actions>
            <NewSinglePaymentStepLayout.NextButton
              isDisabled={!selectedDeliveryMethodId}
              onClick={() => {
                if (selectedDeliveryMethodId) {
                  startAction('delivery_method_selection');
                  onDone(selectedDeliveryMethodId);
                }
              }}
              label={formatMessage('activities.deliveryMethodSelection.screens.deliveryMethodSelection.continue')}
            />
          </NewSinglePaymentStepLayout.Actions>
        ) : (
          onSkip && (
            <NewSinglePaymentStepLayout.Actions>
              <NewSinglePaymentStepLayout.NakedNextButton
                onClick={handleSkip}
                label={formatMessage('activities.deliveryMethodSelection.screens.deliveryMethodSelection.skip')}
              />
            </NewSinglePaymentStepLayout.Actions>
          )
        )
      }
    >
      <NewSinglePaymentStepLayout.Title aria-live="polite">
        {formatMessage('activities.deliveryMethodSelection.screens.deliveryMethodSelection.title', {
          name: vendor?.name || '',
        })}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        {formatMessage(
          shouldShowBankRestrictedPaymentMethodsDescription
            ? 'activities.deliveryMethodSelection.screens.deliveryMethodSelection.bankRestriction.description'
            : 'activities.deliveryMethodSelection.screens.deliveryMethodSelection.description'
        )}
      </NewSinglePaymentStepLayout.Description>

      {isBankAccountDeliveryMethodAvailable && isCloverFreeAchPromotion && !onSkip && (
        <NewSinglePaymentStepLayout.Content>
          <SectionBanner
            data-testid="free-ach-promotion-dm-section-banner-info"
            title={formatMessage(
              'activities.deliveryMethodSelection.screens.deliveryMethodSelection.feeAchPromotion.title'
            )}
            description={formatMessage(
              'activities.deliveryMethodSelection.screens.deliveryMethodSelection.feeAchPromotion.description'
            )}
            variant="informative"
          />
        </NewSinglePaymentStepLayout.Content>
      )}
      <NewSinglePaymentStepLayout.Content>
        <DeliveryMethodSelectionContent
          account={account}
          data={data}
          fundingSource={fundingSource}
          freeChecks={freeChecks}
          selectedId={selectedDeliveryMethodId}
          onSelect={setSelectedDeliveryMethodId}
          vendor={vendor}
          domesticDeliveryMethodTypeOptions={domesticDeliveryMethodTypeOptions}
          internationalDeliveryMethodTypeOptions={internationalDeliveryMethodTypeOptions}
          onAdd={onAdd}
          onEdit={onEdit}
        />
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
};

DeliveryMethodSelectionScreen.displayName = 'DeliveryMethodSelectionActivity.DeliveryMethodSelectionScreen';
