import { Box } from '@chakra-ui/react';
import { accountingSoftwareSlugToBrandSymbolType, SyncNowIconButton } from '@melio/ap-widgets';
import { BrandSymbol, Button, Tooltip, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { AccountingPlatform } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';

import {
  useFirstAccountingPlatformSlug,
  useShouldPromoteAccountingSoftwareSync,
} from '../../../promote-accounting-software-sync';

type Props = {
  accountingPlatform?: AccountingPlatform;
  analyticsProperties: Record<string, string>;
  onAccountingPlatformConnect: VoidFunction;
};

const tooltipTestId = 'accounting-platform-connect-button-tooltip';

export const AccountingPlatformConnectButton = ({ analyticsProperties, onAccountingPlatformConnect }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { isExtraSmallScreen } = useBreakpoint();
  const tooltipTitle = formatMessage('activities.payDashboard.accountingPlatformConnect.tooltip.title');

  const promoteAccountingSoftwareSync = useShouldPromoteAccountingSoftwareSync();
  const { accountingPlatformName, accountingSlug } = useFirstAccountingPlatformSlug();

  const onClick = useCallback(() => {
    track('SyncAccountingSoftware', 'Click', {
      ...analyticsProperties,
      Cta: 'sync',
    });
    onAccountingPlatformConnect();
  }, [onAccountingPlatformConnect, track, analyticsProperties]);

  if (promoteAccountingSoftwareSync) {
    return (
      <Tooltip
        data-testid={tooltipTestId}
        content={
          <>
            {tooltipTitle ? (
              <Box as="span" display="inline-flex" textStyle="body4Semi">
                {formatMessage('activities.payDashboard.accountingPlatformConnect.promotionTooltip.title', {
                  accountingPlatformName,
                })}
              </Box>
            ) : null}
            {formatMessage('activities.payDashboard.accountingPlatformConnect.promotionTooltip.label', {
              accountingPlatformName,
            })}
          </>
        }
        placement="bottom-end"
      >
        <Button
          label={formatMessage('activities.payDashboard.accountingPlatformConnect.promotionCta')}
          leftElement={
            accountingSlug ? <BrandSymbol type={accountingSoftwareSlugToBrandSymbolType[accountingSlug]} /> : undefined
          }
          variant="tertiary"
          onClick={onClick}
        />
      </Tooltip>
    );
  }

  return (
    <SyncNowIconButton
      tooltipTestId={tooltipTestId}
      tooltipTitle={tooltipTitle}
      tooltipLabel={formatMessage('activities.payDashboard.accountingPlatformConnect.tooltip.label')}
      placement="bottom"
      onClick={onClick}
      isDisabled={false}
      variant={isExtraSmallScreen ? 'naked' : undefined}
      ariaLabel={tooltipTitle}
    />
  );
};
