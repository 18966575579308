import { FloatingMenu, SearchBar, SearchBarProps, useMenuItem } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const SearchBarHeader = ({ searchProps }: { searchProps: SearchBarProps }) => {
  const { formatMessage } = useMelioIntl();
  const { ref } = useMenuItem();
  return (
    <FloatingMenu.Header>
      <SearchBar
        {...searchProps}
        ref={ref}
        placeholder={formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.search.placeholder'
        )}
        aria-label="search"
      />
    </FloatingMenu.Header>
  );
};
