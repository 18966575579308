import { Button, Container, Group, Illustration, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { useRouter } from '@/hooks/router.hooks';

type BlockedInternationalPaymentsForStateContentProps = {
  blockReason: string;
};

export const BlockedInternationalPaymentsForStateContent = ({
  blockReason,
}: BlockedInternationalPaymentsForStateContentProps) => {
  const { formatMessage } = useMelioIntl();
  const { goToPayDashboard } = useRouter();
  return (
    <Group height="full" variant="vertical" justifyContent="center" alignItems="center">
      <Group spacing="m" variant="vertical" justifyContent="center">
        <Container justifyContent="center">
          <Illustration size="large" type="blocked" />
        </Container>
        <Container textAlign="center" justifyContent="center" maxWidth="400px">
          <Text textStyle="body2">{blockReason}</Text>
        </Container>
        <Container justifyContent="center">
          <Button
            label={formatMessage('app.blockedInternationalPayments.error.cta')}
            onClick={() => goToPayDashboard()}
            variant="tertiary"
          />
        </Container>
      </Group>
    </Group>
  );
};
