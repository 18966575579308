import { PaymentPendingApprovers } from '@melio/ap-activities';
import { isFXCurrency, usePaymentsLateApprovals, usePaymentsLateApprovalsEnabled } from '@melio/ap-domain';
import { Group, SectionBanner, Text } from '@melio/penny';
import {
  BillSubscriptionApprovalDecisionStatusEnum,
  FundingSourceType,
  PaymentFullyExpanded,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { useDateUtils } from '@melio/platform-utils';

export const PaymentApprovalPendingAlert = ({ payment }: { payment: PaymentFullyExpanded }) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { createDate } = useDateUtils();
  const getPaymentsLateApprovalsEnabled = usePaymentsLateApprovalsEnabled();
  const getPaymentsLateApprovals = usePaymentsLateApprovals();

  const [isPendingApproversEnabled] = usePartnerFeature('ApprovalWorkflowPendingApprovers', false);
  const paymentsLateApprovalsEnabled = getPaymentsLateApprovalsEnabled({ payments: [payment] });
  const paymentsLateApprovals = getPaymentsLateApprovals({ payments: [payment] });
  const paymentLateApprovalEnabled = !!paymentsLateApprovalsEnabled[payment.id]?.isEnabled;
  const paymentLateApproval = paymentsLateApprovals[payment.id];

  const isRecurringPaymentPendingApproval =
    payment.subscriptionOccurrence?.billSubscription?.approvalDecisionStatus ===
    BillSubscriptionApprovalDecisionStatusEnum.Pending;
  const isFXPayment = isFXCurrency(payment.currency) && !!payment.foreignAmount;

  const getAlertDescription = () => {
    if (paymentLateApprovalEnabled) {
      if (payment.fundingSource.type === FundingSourceType.BankAccount) {
        return paymentLateApproval?.isDebitDateAfterToday
          ? formatMessage(
              'widgets.paymentDetails.approvalPending.actionRequired.description.lateDebitDate.bank-account',
              {
                date: formatDate(createDate(payment.scheduledDate.toISOString()), { dateStyle: 'medium' }),
                isFXPayment,
              }
            )
          : formatMessage(
              'widgets.paymentDetails.approvalPending.actionRequired.description.debitDateToday.bank-account',
              {
                date: formatDate(createDate(payment.scheduledDate.toISOString()), { dateStyle: 'medium' }),
                isFXPayment,
              }
            );
      } else if (payment.fundingSource.type === FundingSourceType.Card) {
        return paymentLateApproval?.isDebitDateAfterToday
          ? formatMessage('widgets.paymentDetails.approvalPending.actionRequired.description.lateDebitDate.card', {
              date: formatDate(createDate(payment.scheduledDate.toISOString()), { dateStyle: 'medium' }),
              isFXPayment,
            })
          : formatMessage('widgets.paymentDetails.approvalPending.actionRequired.description.debitDateToday.card', {
              date: formatDate(createDate(payment.scheduledDate.toISOString()), { dateStyle: 'medium' }),
              isFXPayment,
            });
      }
      return paymentLateApproval?.isDebitDateAfterToday
        ? formatMessage('widgets.paymentDetails.approvalPending.actionRequired.description.lateDebitDate.default', {
            date: formatDate(createDate(payment.scheduledDate.toISOString()), { dateStyle: 'medium' }),
            isFXPayment,
          })
        : formatMessage('widgets.paymentDetails.approvalPending.actionRequired.description.debitDateToday.default', {
            date: formatDate(createDate(payment.scheduledDate.toISOString()), { dateStyle: 'medium' }),
            isFXPayment,
          });
    }

    return formatMessage('widgets.paymentDetails.approvalPending.actionRequired.description', {
      date: formatDate(createDate(payment.scheduledDate.toISOString()), { dateStyle: 'medium' }),
      recurringPaymentAlert: isRecurringPaymentPendingApproval
        ? formatMessage('widgets.paymentDetails.approvalPending.actionRequired.recurringPayment.description')
        : null,
      isFXPayment,
    });
  };

  return (
    <SectionBanner
      data-testid="payment-pending-approval-alert"
      variant="warning"
      description={
        isPendingApproversEnabled ? (
          <Group variant="vertical" spacing="none">
            <Text textStyle="body3">{getAlertDescription()}</Text>
            <PaymentPendingApprovers id={payment.id} />
          </Group>
        ) : (
          getAlertDescription()
        )
      }
      title={formatMessage('widgets.paymentDetails.approvalPending.actionRequired.title')}
    />
  );
};
