import { startsWith } from 'lodash';
import { useParams } from 'react-router-dom';

import { BillDrawer } from './BillDrawer';
import { PaymentRequestDrawer } from './PaymentRequestDrawer';
import { ScannedInvoiceDrawer } from './ScannedInvoiceDrawer';

export const BillsTabDrawers = ({
  onClose,
  refetchBillItems,
  onEditBill,
  onRejectClick,
  onDeleteBillClick,
  onReviewScannedInvoiceClick,
  onMarkAsPaidClick,
}: {
  onClose: () => void;
  refetchBillItems: () => void;
  onRejectClick: (id: string) => void;
  onDeleteBillClick: ({ id }: { id: string }) => void;
  onReviewScannedInvoiceClick: (id: string) => void;
  onMarkAsPaidClick: ({ id }: { id: string }) => void;
  onEditBill: ({ id }: { id: string; returnUrl?: string }) => void;
}) => {
  const { billItemId } = useParams();

  if (!billItemId) {
    return null;
  }

  return (
    <>
      {startsWith(billItemId, 'bill') && (
        <BillDrawer
          id={billItemId}
          onClose={onClose}
          onSuccess={refetchBillItems}
          onDeleteBillClick={onDeleteBillClick}
          onMarkAsPaidClick={onMarkAsPaidClick}
          onEditBill={onEditBill}
        />
      )}
      {startsWith(billItemId, 'scn') && (
        <ScannedInvoiceDrawer id={billItemId} onClose={onClose} onReviewScannedInvoice={onReviewScannedInvoiceClick} />
      )}
      {startsWith(billItemId, 'pymntreq') && (
        <PaymentRequestDrawer id={billItemId} onClose={onClose} onRejectClick={onRejectClick} />
      )}
    </>
  );
};
