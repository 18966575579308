import { Box } from '@chakra-ui/react';
import { forwardRef, HTMLTag } from '@melio/platform-utils';
import { ComponentType } from 'react';

type WithHiddenProps = {
  isHidden?: boolean;
};

export function withHidden<T, TTag extends HTMLTag = 'div'>(UI: ComponentType<T>) {
  return forwardRef<T & WithHiddenProps, TTag>(({ isHidden, ...props }, ref) => (
    <Box hidden={isHidden} height="full" width="full">
      <UI {...(props as T)} ref={ref} />
    </Box>
  ));
}
