import { TableColumnDef } from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

import { VendorsGroupTableVendorCell } from './VendorsGroupTableVendorCell';

type VendorGroupTableCellMeta = {
  isVendorSelectionDisabled: (vendor: Vendor) => boolean;
};

export const useVendorsGroupTableColumns = () => {
  const { formatMessage } = useMelioIntl();

  return useMemo<TableColumnDef<Vendor, VendorGroupTableCellMeta>[]>(() => {
    const vendorCell: TableColumnDef<Vendor, VendorGroupTableCellMeta> = {
      id: 'vendorName',
      size: 'm',
      header: formatMessage('activities.vendorsGroupForm.table.headers.vendor'),
      cell: ({ row, meta }) => (
        <VendorsGroupTableVendorCell vendor={row} isVendorSelectionDisabled={meta?.isVendorSelectionDisabled} />
      ),
    };

    return [vendorCell];
  }, [formatMessage]);
};
