import { useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { useImportFromQuickbooksButtonProps } from '../../../../promote-accounting-software-sync/useImportFromQuickbooksButtonProps';
import {
  RecommendedVendorsCards,
  RecommendedVendorsModalCta,
  useIsRecommendedVendorsCardsDismissed,
  useRecommendedVendors,
} from '../../../../recommended-vendors';
import { PayDashboardEmptyState } from '../../PayDashboardEmptyState';
import { PayDashboardClearedState } from '../../PayDashboardEmptyState/PayDashboardClearedState';

type Props = {
  onAddNewVendor: VoidFunction;
};

export const VendorsTabEmptyState = ({ onAddNewVendor }: Props) => {
  const { featureFlag: recommendedVendorsFeatureFlag, recommendedVendors } = useRecommendedVendors({
    shouldFetch: false,
  });

  const { isDismissed: isVendorsCardsDismissed, dismissCards } = useIsRecommendedVendorsCardsDismissed();
  const importFromQuickbooksButtonProps = useImportFromQuickbooksButtonProps({});

  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { isExtraSmallScreen } = useBreakpoint();

  const [isShowingRecommendedVendorCardExperiment, toggleRecommendedVendorCardExperiment] = useState(
    recommendedVendorsFeatureFlag === 'cards' && !isVendorsCardsDismissed
  );

  const handleAddNewVendor = () => {
    track('Dashboard', 'Click', {
      Intent: 'add-vendor',
      Cta: 'add-a-vendor',
    });
    onAddNewVendor();
  };

  if (isShowingRecommendedVendorCardExperiment && recommendedVendors && recommendedVendors.length >= 2) {
    return (
      <RecommendedVendorsCards
        onClose={() => {
          dismissCards();
          toggleRecommendedVendorCardExperiment(false);
        }}
      />
    );
  }

  return isExtraSmallScreen ? (
    <PayDashboardClearedState
      title={formatMessage('activities.payDashboard.vendorsTab.emptyState.mobile.title')}
      description={formatMessage('activities.payDashboard.vendorsTab.emptyState.mobile.description')}
      illustrationSrc="vendor-add"
    />
  ) : (
    <PayDashboardEmptyState
      description={formatMessage('activities.payDashboard.vendorsTab.emptyState.description')}
      animationSrc="https://platform-static.meliopayments.com/assets/melio/add-vendor.lottie.json"
      customCTAComponent={recommendedVendorsFeatureFlag === 'modal' ? <RecommendedVendorsModalCta /> : null}
      buttonProps={[
        ...(importFromQuickbooksButtonProps ? [importFromQuickbooksButtonProps] : []),
        {
          label: formatMessage('activities.payDashboard.vendorsTab.emptyState.primaryButton'),
          onClick: handleAddNewVendor,
          'data-testid': 'vendors-tab-empty-state-primary-button',
        },
      ]}
    />
  );
};
