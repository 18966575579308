import { usePaymentsLateApprovalsEnabled } from '@melio/ap-domain';
import { useAnalyticsContext } from '@melio/platform-analytics';
import { Payment } from '@melio/platform-api';
import { useSearchParams } from 'react-router-dom';

import { createPaymentStatusCountMapObject } from '../../utils/payment-analytics.utils';
import { PaymentsTabFilter } from './types';

export const usePaymentsTabAnalytics = ({
  payments,
  activeFilter,
}: {
  payments: Payment[];
  activeFilter: PaymentsTabFilter;
}) => {
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('search');
  const getPaymentsLateApprovalsEnabled = usePaymentsLateApprovalsEnabled();

  const paymentsLateApprovalsEnabled = getPaymentsLateApprovalsEnabled({ payments });
  const latePaymentCount = Object.entries(paymentsLateApprovalsEnabled).filter(
    ([, { isEnabled }]) => !!isEnabled
  ).length;

  useAnalyticsContext({
    globalProperties: {
      PageName: 'dashboard-payments',
      EntryPoint: 'dashboard-payments',
      Flow: 'dashboard',
      CountInTab: payments?.length,
      SearchValue: searchTerm,
      TPV: payments?.reduce((total, payment) => total + payment.amount, 0),
      FilterValue: activeFilter,
      Status: createPaymentStatusCountMapObject(payments),
      RecurringPayments: payments?.filter((payment) => !!payment.subscriptionOccurrence).length,
      AutoPayPayments: payments?.filter((payment) => payment.isAutoPayment).length,
      LatePayments: latePaymentCount,
    },
  });
};
