import { Box } from '@chakra-ui/react';
import { CircularProgress, Group, Text, Tooltip, useToast } from '@melio/penny';
import { SyncMigrationStatusEnum, useSyncMigration } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback, useEffect, useRef, useState } from 'react';

const MINUTE_IN_MILLISECONDS = 60000;
const MAX_TIMEOUT = 3 * MINUTE_IN_MILLISECONDS;
export const RUNNING_INTERVAL = MINUTE_IN_MILLISECONDS / 6;
export const ERROR_INTERVAL = 60 * MINUTE_IN_MILLISECONDS;

export const SyncMigrationProgressIndication = () => {
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();

  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);
  const { data: syncData } = useSyncMigration({ refetchInterval });

  const [infoToastDismissed, setInfoToastDismissed] = useState(false);
  const infoToastTriggered = useRef(false);
  const successToastTriggered = useRef(false);

  const [tooltipTitle, setTooltipTitle] = useState<string>(
    formatMessage('widgets.syncMigrationIndication.topNav.tooltip.title')
  );
  const [tooltipText, setTooltipText] = useState<string>(
    formatMessage('widgets.syncMigrationIndication.topNav.tooltip.text')
  );

  const setTooltipDelayText = useCallback(() => {
    setTooltipTitle(formatMessage('widgets.syncMigrationIndication.topNav.tooltip.delay.title'));
    setTooltipText(formatMessage('widgets.syncMigrationIndication.topNav.tooltip.delay.text'));
  }, [formatMessage]);

  const triggerInfoToast = useCallback(() => {
    // wait MAX_TIMEOUT from the time sync created
    const timeToWait = syncData?.createdAt ? syncData.createdAt.getTime() + MAX_TIMEOUT - Date.now() : MAX_TIMEOUT;

    if (timeToWait > 0) {
      setTimeout(() => {
        setTooltipDelayText();
      }, timeToWait);

      toast({
        type: 'informative',
        title: formatMessage('widgets.syncMigrationIndication.toast.info.title'),
        onCloseComplete: () => {
          setInfoToastDismissed(true);
        },
      });
    } else {
      setTooltipDelayText();
      setInfoToastDismissed(true);
    }
  }, [syncData, toast, formatMessage, setTooltipDelayText]);

  const triggerSuccessToast = useCallback(() => {
    toast({
      type: 'success',
      title: formatMessage('widgets.syncMigrationIndication.toast.success.title'),
    });
  }, [toast, formatMessage]);

  // set up interval for fetching sync data
  // when sync is running, we want to update progress every RUNNING_INTERVAL, and find out if it is done
  // when sync is not running, we don't want to wait for updates
  useEffect(() => {
    switch (syncData?.status) {
      case SyncMigrationStatusEnum.Running:
        setRefetchInterval(RUNNING_INTERVAL);
        break;
      case SyncMigrationStatusEnum.Error:
      case SyncMigrationStatusEnum.Success:
      case SyncMigrationStatusEnum.NotTriggered:
      default:
        setRefetchInterval(false);
        break;
    }
  }, [syncData?.status]);

  // trigger toasts when sync is running or successful (only once after info toast is dismissed)
  useEffect(() => {
    if (syncData?.status === SyncMigrationStatusEnum.Running && !infoToastTriggered.current) {
      infoToastTriggered.current = true;
      triggerInfoToast();
    } else if (
      syncData?.status === SyncMigrationStatusEnum.Success &&
      infoToastDismissed &&
      !successToastTriggered.current
    ) {
      successToastTriggered.current = true;
      triggerSuccessToast();
    }
  }, [infoToastDismissed, infoToastTriggered, syncData?.status, triggerInfoToast, triggerSuccessToast]);

  // show progress indication when sync is running and info toast is dismissed
  return infoToastDismissed &&
    syncData?.status === SyncMigrationStatusEnum.Running &&
    syncData.progress !== undefined ? (
    <Tooltip
      placement="bottom"
      content={
        <>
          <Box as="span" display="inline-flex" textStyle="body4Semi">
            {tooltipTitle}
          </Box>
          {tooltipText}
        </>
      }
    >
      <Box as={Group} spacing="xs" alignItems="center" data-testid="sync-migration-indication-top-nav" tabIndex={0}>
        <CircularProgress percentage={syncData.progress} />
        <Text color="global.neutral.800" textStyle="body4Semi">
          {formatMessage('widgets.syncMigrationIndication.topNav.title')}
        </Text>
      </Box>
    </Tooltip>
  ) : (
    <></>
  );
};
