import { PayorPaymentRequestDetails, useMelioIntl } from '@melio/ar-domain';
import { Button, Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { PaymentRequestDetailsLayout } from '../../../layout';
import { CustomPaymentInstructions } from '../components';

export type NoPaymentOptionsScreenProps = {
  isLoading?: boolean;
  paymentRequestDetails?: PayorPaymentRequestDetails;
  onViewInvoice: VoidFunction;
};

export const NoPaymentOptionsScreen = forwardRef<NoPaymentOptionsScreenProps>(
  ({ onViewInvoice, isLoading, paymentRequestDetails, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const customPayInstructions = paymentRequestDetails?.invoice.paymentOptions.customPayInstructions?.trim();

    return (
      <PaymentRequestDetailsLayout
        data-component={NoPaymentOptionsScreen.displayName}
        data-testid="no-payment-options-screen"
        paymentRequestDetails={paymentRequestDetails}
        isLoading={isLoading}
        onViewInvoice={onViewInvoice}
        {...props}
        ref={ref}
      >
        <Group variant="vertical" spacing="l">
          {customPayInstructions && <CustomPaymentInstructions paymentInstructions={customPayInstructions} />}
          <Button
            data-testid="view-invoice-button"
            variant="tertiary"
            onClick={onViewInvoice}
            label={formatMessage('ar.guestPayment.previewInvoice.button.label')}
          />
        </Group>
      </PaymentRequestDetailsLayout>
    );
  }
);
NoPaymentOptionsScreen.displayName = 'NoPaymentOptionsScreen';
