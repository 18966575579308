import { useParams } from 'react-router-dom';
import { AddBankAccountPlaidActivity } from '@melio/ap-activities';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { useRouter } from '@/hooks/router.hooks';
import { useReceivingMethodActivityEvents } from '@/hooks/useReceivingMethodActivityEvents';

export const EditPlaidBankAccountScreen = () => {
  const [isPlaidMicroDepositsGetPaidEnabled] = useDevFeature(FeatureFlags.PlaidMicroDepositsGetPaidEnabled, false, {
    shouldTrack: true,
  });
  const { onBack, onDone, onClose: onClosePlaidIframe } = useReceivingMethodActivityEvents();

  const { deliveryMethodId: updateDeliveryMethodId } = useParams();

  const { goToCantFindBankAccountScreen } = useRouter();

  const onClose = () => {
    if (isPlaidMicroDepositsGetPaidEnabled) {
      onClosePlaidIframe();
    } else if (updateDeliveryMethodId) {
      goToCantFindBankAccountScreen(updateDeliveryMethodId);
    }
  };

  return (
    <AddBankAccountPlaidActivity
      onClose={onClose}
      onBack={onBack}
      onDone={onDone}
      options={{ shouldAllowNonInstantVerification: true, isReceivingMethodFlow: true }}
    />
  );
};
