import { ARIndustry, useMelioIntl } from '@melio/ar-domain';
import { Group, useFormSubmissionController } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { Footer, OnboardingLayout } from '../../../layouts';
import { BusinessDetailsFormState, UseBusinessDetailsFormProps } from '../../../utils';
import { BusinessDetailsForm, BusinessDetailsHeader } from '../components';
import { BusinessDetailsFormFields } from '../types';

export type BusinessDetailsScreenProps = {
  onSubmit: (data: BusinessDetailsFormFields) => unknown;
  defaultValues: UseBusinessDetailsFormProps['defaultValues'];
  isLoading: boolean;
  isSaving: boolean;
  onBack: VoidFunction;
  industries: ARIndustry[];
  onFormStateChange?: (state: BusinessDetailsFormState) => void;
  error?: ARPlatformError;
};

export const BusinessDetailsScreen = forwardRef<BusinessDetailsScreenProps>(
  ({ isLoading, isSaving, onSubmit, onBack, industries, defaultValues, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<BusinessDetailsFormFields>();

    return (
      <OnboardingLayout
        isLoading={isLoading}
        screenId="business-details"
        headerContent={formatMessage('ar.onboarding.layout.header.text')}
        footerContent={
          <Footer
            primaryButtonProps={{
              label: formatMessage('ar.onboarding.layout.buttons.continue.label'),
              ...submitButtonProps,
            }}
            secondaryButtonProps={{
              label: formatMessage('ar.onboarding.layout.buttons.back.label'),
              onClick: onBack,
              isDisabled: isSaving,
            }}
            tertiaryButtonProps={{
              label: formatMessage('ar.onboarding.layout.buttons.cancel.label'),
              isDisabled: isSaving,
            }}
          />
        }
        isStickyFooter
        data-component={BusinessDetailsScreen.displayName}
        data-testid="business-details-screen"
      >
        <Group variant="vertical" width="full" spacing="xl">
          <BusinessDetailsHeader />
          <BusinessDetailsForm
            defaultValues={defaultValues}
            industries={industries}
            onSubmissionStateChange={onSubmissionStateChange}
            onSubmit={onSubmit}
            isSaving={isSaving}
            {...props}
            ref={ref}
          />
        </Group>
      </OnboardingLayout>
    );
  }
);
BusinessDetailsScreen.displayName = 'BusinessDetailsScreen';
