import { NakedButton, SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { useSubscriptionRouter } from '../../../utils';

export const SubscriptionPaymentFeeFailedBanner = () => {
  const { formatMessage } = useMelioIntl();
  const { goToPaymentMethodSelect } = useSubscriptionRouter();

  return (
    <SectionBanner
      title={formatMessage('widgets.subscription.paymentFeeFailedBanner.title')}
      description={formatMessage('widgets.subscription.paymentFeeFailedBanner.description')}
      variant="critical"
      action={
        <NakedButton
          label={formatMessage('widgets.subscription.paymentFeeFailedBanner.action')}
          onClick={goToPaymentMethodSelect}
        />
      }
      data-testid="subscription-payment-fee-failed-banner"
    />
  );
};
