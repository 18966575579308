/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { LocalStorageKeys } from '@melio/local-storage';
import { useSendMessage } from '@melio/partner-bridge';
import { useAnalytics } from '@melio/platform-analytics';

import { removePartnerTokens } from '@/api/utilities';
import { useOAuthQueryParams } from '@/hooks/auth.hooks';
import { usePartnerConfig } from '@/hooks/partners';
import { usePartnerLocalStorage } from '@/hooks/partners/usePartnerLocalStorage';
import { useRouter } from '@/hooks/router.hooks';
import { useResetAccessToken } from '@/hooks/session.hooks';
import { useAppRedirectData } from '@/hooks/useAppRedirect.hooks';
import { OAuthCallbackScreen } from '@/screens/onboarding/OAuthCallback.screen';
import { OAuthRegisterScreen } from '@/screens/onboarding/OAuthRegister.screen';
import { decodeAccessToken } from '@/utils/getAccessTokenData.utils';
import { PostMessageTypes } from '@/utils/partnerBridge.utils';
import { OnboardingError } from '@/widgets/onboarding/components/OnboardingError';

export const AuthRoute = () => {
  const [step, setStep] = React.useState<'callback' | 'register' | null>(null);
  const [error, setError] = React.useState<AxiosError<unknown, unknown> | boolean | null>(null);
  const [encryptedProviderData, setEncryptedProviderData] = React.useState<string>('');

  const { state } = useLocation();
  const { token, redirectUrl } = useOAuthQueryParams();
  const { track } = useAnalytics();
  const resetAccessToken = useResetAccessToken();
  const { getAppRedirectData, setAppRedirectData } = useAppRedirectData();
  const { goToAppRedirect, goToPayDashboard, goToStart, generateBackToPartnerUrl } = useRouter();
  const localStorage = usePartnerLocalStorage();
  const { partnerConfig } = usePartnerConfig();
  const backToPartnerUrl = generateBackToPartnerUrl();
  const sendMessage = useSendMessage();

  const onConsentClose = () => {
    sendMessage(PostMessageTypes.AUTHENTICATION_ERROR, { reason: 'DID_NOT_CONSENT' });
    if (backToPartnerUrl && partnerConfig.config.redirectOnConsentScreenReject) {
      window.location.href = backToPartnerUrl;
    }
  };

  const onAuthCallbackSuccess: Parameters<typeof OAuthCallbackScreen>[0]['onSuccess'] = React.useCallback(
    (data) => {
      const { targetAction, targetId, target } = data.metadata;
      setAppRedirectData({ targetAction, targetId, redirectUrl, target });

      if ('authData' in data) {
        const { accessToken, refreshToken } = data.authData;
        resetAccessToken(accessToken, refreshToken);
        goToAppRedirect();
      } else {
        setEncryptedProviderData(data.encryptedProviderData);
        setStep('register');
      }
    },
    [redirectUrl, setAppRedirectData, resetAccessToken, goToAppRedirect, setEncryptedProviderData, setStep],
  );

  const onOAuthRegisterSuccess: Parameters<typeof OAuthRegisterScreen>[0]['onSuccess'] = React.useCallback(
    (data) => {
      const { accessToken, refreshToken } = data;
      const { organization, user } = decodeAccessToken(accessToken);
      track('AccountCreated', 'Succeeded', {
        HaveMelioAccount: false, // data segregation - we always create a new account for new login
        OrgId: organization?.id,
        UserId: user?.id,
      });

      setAppRedirectData({ ...getAppRedirectData(), isFirstTimeLogin: true });
      resetAccessToken(accessToken, refreshToken);
      goToAppRedirect();
    },
    [decodeAccessToken, track, resetAccessToken, getAppRedirectData, setAppRedirectData, goToAppRedirect],
  );

  const authRouteInit = React.useCallback(() => {
    localStorage.removeItem(LocalStorageKeys.freeAchPromotionModalSeen);
    const oAuthToken = localStorage.getItem(LocalStorageKeys.oAuthToken);
    if (token && token !== oAuthToken) {
      localStorage.setItem(LocalStorageKeys.oAuthToken, token);
      removePartnerTokens();
      setStep('callback');
    } else if (localStorage.getItem(LocalStorageKeys.accessToken)) {
      goToPayDashboard();
    } else if (partnerConfig.oAuth.required && partnerConfig.oAuth.redirectWhenMissingToken) {
      goToStart({ redirectUrl: state?.redirectUrl });
    } else {
      setError(true);
    }
  }, [localStorage, token, partnerConfig, state, setStep, goToPayDashboard, goToStart, setError]);

  useEffect(() => {
    authRouteInit();
  }, []);

  if (error) {
    return (
      <OnboardingError
        backToPartnerUrl={backToPartnerUrl}
        isBackToPartner={partnerConfig.showOnboardingBackToPartner}
        error={error}
        productName={partnerConfig.partnerProductName}
      />
    );
  }

  if (step === 'callback') {
    return <OAuthCallbackScreen onSuccess={onAuthCallbackSuccess} onError={setError} />;
  }

  if (step === 'register') {
    return (
      <OAuthRegisterScreen
        encryptedProviderData={encryptedProviderData}
        onSuccess={onOAuthRegisterSuccess}
        onError={setError}
        onClose={onConsentClose}
      />
    );
  }

  return <></>;
};
