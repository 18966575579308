import { FundingSource, useUserPreference } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';

type Props = {
  fundingSources: FundingSource[];
};

const preferenceKey = 'dismissedNoCreditCardBanner';

export const useShowCreditCardBanner = ({ fundingSources }: Props) => {
  const [featureEnabled] = usePartnerFeature('showNoCreditCardBanner', false);
  const hasActiveCreditCard = fundingSources.some((source) => source.type === 'card' && source.isVerified);

  const {
    data: { value: dismissed } = {},
    isLoading,
    create,
  } = useUserPreference({
    id: preferenceKey,
    enabled: featureEnabled && !hasActiveCreditCard,
  });

  const hasDismissed = !isLoading && dismissed === 'true';

  return {
    showBanner: featureEnabled && !hasActiveCreditCard && !hasDismissed,
    dismissBanner: () => create({ userPreference: { key: preferenceKey, value: 'true' } }),
  };
};
