import { Box } from '@chakra-ui/react';
import { Link, SectionBanner } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

export const UndepositedCheckBanner = ({ payment }: { payment: Payment }) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { undepositedCheckLearnMore } = useConfig().links;

  const description = formatMessage('widgets.paymentDetails.completed.undepositedCheck.description', {
    date: formatDate(payment.processedTimeline?.inTransitDate, { dateStyle: 'medium' }),
    learnMoreLink: (
      <Link
        href={undepositedCheckLearnMore}
        label={formatMessage('widgets.paymentDetails.completed.undepositedCheck.learnMore')}
        newTab
      />
    ),
  });

  return (
    <Box pb="s">
      <SectionBanner description={description} variant="warning" data-testid="undeposited-check-alert" />
    </Box>
  );
};
