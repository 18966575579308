import {
  EmailPreviewer,
  PDFPreviewer,
  useInvoicePreviewEmail,
  useInvoicePreviewPDF,
  useMelioIntl,
} from '@melio/ar-domain';
import { Card, Container, Group, Link, SectionBanner } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { TabsNames } from '../types';
import { GeneralError } from './GeneralError';

type PreviewTabsProps = {
  invoiceId: string;
  activeTab: TabsNames;
  onUploadCompanyLogo?: VoidFunction;
  shouldDisplayUploadCompanyLogoBanner: boolean;
  customerName?: string;
};

export const PreviewTabs = forwardRef<PreviewTabsProps>(
  ({ invoiceId, activeTab, shouldDisplayUploadCompanyLogoBanner, customerName, onUploadCompanyLogo }, ref) => {
    const { data: previewEmailContent, isLoading: isLoadingEmailPreview } = useInvoicePreviewEmail({
      id: invoiceId,
    });
    const {
      data: previewPDFContent,
      isFetching: isFetchingPDFContent,
      error: pdfError,
    } = useInvoicePreviewPDF({ id: invoiceId });
    const uploadLogoBannerDescription = useUploadLogoBannerDescription(onUploadCompanyLogo);

    const tabs: Record<TabsNames, JSX.Element> = {
      PDF: pdfError ? (
        <Container paddingTop="xxl" data-testid="pdf-error-container">
          <GeneralError ref={ref} />
        </Container>
      ) : (
        <PDFPreviewer previewContent={previewPDFContent} isLoadingContent={isFetchingPDFContent} ref={ref} />
      ),
      Email: (
        <Card paddingX="none" paddingY="none">
          <EmailPreviewer
            previewContent={previewEmailContent?.html}
            isLoading={isLoadingEmailPreview}
            emailHeaderData={
              customerName && previewEmailContent?.subject
                ? { emailSubject: previewEmailContent.subject, customerName }
                : undefined
            }
            ref={ref}
          />
        </Card>
      ),
    };

    return (
      <Group variant="vertical" spacing="s" ref={ref}>
        {shouldDisplayUploadCompanyLogoBanner ? (
          <SectionBanner
            description={uploadLogoBannerDescription}
            variant="informative"
            data-testid="upload-logo-banner"
          />
        ) : null}
        {tabs[activeTab]}
      </Group>
    );
  }
);

const useUploadLogoBannerDescription = (onUploadCompanyLogo?: VoidFunction) => {
  const { formatMessage } = useMelioIntl();
  return formatMessage('ar.invoiceLifecycle.activities.issueInvoice.uploadLogoBanner.description.text', {
    link: (
      <Link
        href="#"
        data-testid="upload-logo-banner-action-button"
        label={formatMessage('ar.invoiceLifecycle.activities.issueInvoice.uploadLogoBanner.description.link')}
        onClick={(e) => {
          e.preventDefault();
          onUploadCompanyLogo?.();
        }}
      />
    ),
  });
};
