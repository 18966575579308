import { useGuestPayorPaymentRequestDetails, useMelioIntl, useSystemMessage } from '@melio/ar-domain';
import { useEffect } from 'react';

export const useUpdatedInvoiceSystemMessage = (paymentRequestLink: string) => {
  const { data } = useGuestPayorPaymentRequestDetails({
    paymentRequestLink,
  });
  const { triggerMessage } = useSystemMessage();
  const { formatMessage, formatDate } = useMelioIntl();

  useEffect(() => {
    if (data && !data.receivablePaymentDetails && data.invoice.editedAt) {
      triggerMessage({
        title: formatMessage('ar.guestPayment.notifications.updatedAt.description.text', {
          updatedAtDate: formatDate(data.invoice.editedAt),
          companyName: data.payeeDetails.companyName,
        }),
        type: 'informative',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
};
