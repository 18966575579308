import { VendorAvatar } from '@melio/ap-widgets';
import { Group, Table, Text, VisuallyHidden } from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { identity } from 'lodash';

import { displayAccountNumber } from '../../PayDashboard/components/VendorsTab/utils';

export const VendorsGroupTableVendorCell = ({
  vendor,
  isVendorSelectionDisabled,
}: {
  vendor: Vendor;
  isVendorSelectionDisabled?: (vendor: Vendor) => boolean;
}) => {
  const { formatMessage } = useMelioIntl();
  const { name: vendorName, externalLogoUrl: vendorExternalLogoUrl, isManaged: isVendorManaged } = vendor;
  const description = [vendor.nickname, displayAccountNumber(vendor.accountNumber)].filter(identity).join(' | ');

  return (
    <Table.Cell data-testid={`vendors-group-${vendor.id}-vendor-name-cell`}>
      <Group alignItems="center">
        <VendorAvatar name={vendorName} logoUrl={vendorExternalLogoUrl} isManaged={isVendorManaged} />
        <Group variant="vertical" spacing="xxs">
          <Text>{vendor.name}</Text>
          <Text textStyle="body4" color="global.neutral.800">
            {description}
          </Text>
          {isVendorSelectionDisabled?.(vendor) ? (
            <Group alignItems="center">
              <VisuallyHidden
                id={`selection-disabled-${vendor.id}`}
                aria-label={formatMessage('activities.vendorsGroupForm.table.row.disabled.ariaLabel', {
                  vendorName: vendor.name,
                })}
              />
              <Text color="semantic.text.secondary" textStyle="body4">
                {formatMessage('activities.vendorsGroupForm.table.row.disabled.description', {
                  groupName: vendor?.groups?.[0]?.name,
                })}
              </Text>
            </Group>
          ) : null}
        </Group>
      </Group>
    </Table.Cell>
  );
};
