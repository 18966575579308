import { isSinglePaymentFields } from '@melio/ap-activities';
import { AddBillFormWidgetFields } from '@melio/ap-widgets';
import { Vendor } from '@melio/platform-api';
import { SystemMessage, useNavigate, useSystemMessage } from '@melio/platform-utils';

import { useRouter } from '@/hooks/router.hooks';
import { PayDashboardTabs } from '@/types/payDashboard.types';

export const useAddNewBillEvents = ({
  deliveryMethodId,
  returnUrl,
  waitingForMessage,
  vendorId,
}: {
  deliveryMethodId?: string;
  returnUrl?: string;
  waitingForMessage?: boolean;
  vendorId?: Vendor['id'];
}) => {
  const navigate = useNavigate();
  const { goToPayDashboard, generateViewVendorLink, replaceToPayDashboardTab, navigateToSchedulePayment } = useRouter();
  const { showMessage } = useSystemMessage();
  const shouldRedirectToVendorDetails = returnUrl === `${generateViewVendorLink(vendorId)}?`;

  const onDone = (
    data: AddBillFormWidgetFields,
    billId?: string,
    target?: 'continue' | 'close' | 'markAsPaid',
    message?: SystemMessage,
  ) => {
    if (isSinglePaymentFields(data) && billId) {
      switch (target) {
        case 'close':
          if (returnUrl) {
            navigate(returnUrl, { state: { message } });
          } else replaceToPayDashboardTab(PayDashboardTabs.Inbox, billId, { keepToast: true });
          break;
        case 'continue':
          navigateToSchedulePayment({ billId, deliveryMethodId, returnUrl });
          break;
      }
      if (message && !shouldRedirectToVendorDetails && !waitingForMessage) {
        showMessage(message);
      }
    }
  };

  const onBack = () => {
    if (returnUrl) {
      return navigate(returnUrl);
    }
    goToPayDashboard();
  };

  const onClose = () => {
    if (returnUrl) {
      return navigate(returnUrl);
    }
    goToPayDashboard();
  };

  return { onDone, onBack, onClose };
};
