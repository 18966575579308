import { Container, Group, Layout, Text, useToast } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';

import { useRequestAccountAccess } from '../../api/entities/client/useRequestAccountAccess';
import { FlowHeader } from '../../components/FlowHeader/FlowHeader.component';
import { useAccountantsRoutes } from '../../utils/useAccountantsRoutes';
import { InviteFirmClientForm } from './InviteFirmClientForm';
import { InviteFirmClientFormFields } from './types';

type InviteFirmClientProps = {
  onClose: VoidFunction;
};
export const InviteFirmClient = withAnalyticsContext<InviteFirmClientProps>(({ onClose, setAnalyticsProperties }) => {
  const { goToInvitationSent } = useAccountantsRoutes();
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();
  const { track } = useAnalytics();
  const { mutateAsync: requestAccountAccess, isLoading: isLoadingRequestAccountAccess } = useRequestAccountAccess({
    onSuccess: () => {
      track('Organization', 'Status', { StatusType: 'success' });

      goToInvitationSent();
    },
    onError(error) {
      track('Organization', 'Status', { StatusType: 'failure', ErrorType: error.message });

      toast({
        type: 'error',
        title: formatMessage('activities.accountants.activities.existingClientAccount.requestAccess.error'),
      });
      Logger.log(`Error while trying to send a join organization request. ${error.message}`);
    },
  });

  setAnalyticsProperties({
    PageName: 'invite-a-client',
    Flow: 'invite-existing-client',
    Intent: 'connect-with-existing-client',
  });

  useAnalyticsView('Organization', true, true);

  const handleSubmit = async (data: InviteFirmClientFormFields) => {
    const { clientEmailAddress, clientFirstName, clientLastName, companyName, includeMyEmailInCC } = data;

    track('Organization', 'Click', { Cta: 'send-invite', IsIncludeEmailChecked: includeMyEmailInCC });

    await requestAccountAccess({
      user: {
        email: clientEmailAddress,
        firstName: clientFirstName,
        lastName: clientLastName,
      },
      company: {
        name: companyName,
      },
      invitationOptions: {
        includeInviterInCC: includeMyEmailInCC,
      },
    });
  };

  const onHandleClose = (action: string) => {
    track('Organization', 'Click', { Cta: action });

    onClose();
  };

  return (
    <Layout>
      <Container justifyContent="center" height="full">
        <Group spacing="m" variant="vertical" width={{ xs: '100%', s: '800px' } as never}>
          <FlowHeader
            title={formatMessage('activities.accountants.inviteClient.form.header.text')}
            leaveConfirmationModal={{
              title: formatMessage('activities.accountants.inviteClient.leaveConfirmation.title'),
              description: formatMessage('activities.accountants.inviteClient.leaveConfirmation.description'),
              cancelButtonText: formatMessage(
                'activities.accountants.inviteClient.leaveConfirmation.cancelButton.text'
              ),
            }}
            onClose={() => onHandleClose('exit')}
          />
          <Text textStyle="heading3Semi">
            {formatMessage('activities.accountants.inviteClient.form.subheader.text')}
          </Text>
          <Text textStyle="body2">{formatMessage('activities.accountants.inviteClient.form.description.text')}</Text>
          <InviteFirmClientForm
            onSubmit={handleSubmit}
            isSaving={isLoadingRequestAccountAccess}
            onClose={() => onHandleClose('back')}
          />
        </Group>
      </Container>
    </Layout>
  );
});
