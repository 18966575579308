import { Box } from '@chakra-ui/react';
import { Group, Link, Text } from '@melio/penny';
import { FormattedMessage, useMelioIntl } from '@melio/platform-provider';

import { PciIcon, PrivacyIcon } from './icons';

export const PCIComplienceInfo: React.VFC = () => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group alignItems="center" data-testid="pci-legal-info">
      <Box as="span" color="legalInfo.icon.color">
        <PrivacyIcon />
      </Box>
      <Text as="p" textStyle="caption1">
        <FormattedMessage
          id="widgets.legal.info"
          values={{
            link: (
              <Link href="https://www.meliopayments.com/security" label={formatMessage('widgets.legal.link')} newTab />
            ),
          }}
        />
      </Text>
      <PciIcon />
    </Group>
  );
};
