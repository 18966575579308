import { Group, StatusIconOutlined, Text, Tooltip } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const TaxPayerInfoSectionTitle = () => {
  const { formatMessage } = useMelioIntl();
  return (
    <Group spacing="xs" alignItems="center">
      <Text textStyle="body4Semi" color="global.neutral.800">
        {formatMessage('widgets.taxPayerInfo.form.title')}
      </Text>
      <Tooltip content={formatMessage('widgets.taxPayerInfo.form.title.tooltip')}>
        <StatusIconOutlined variant="neutral" size="small" />
      </Tooltip>
    </Group>
  );
};
