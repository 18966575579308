export const generateDeepLinkToPaymentRequestPayDashboard = ({
  paymentRequestId,
  paymentRequestLink,
  vendorName,
}: {
  paymentRequestId: string;
  paymentRequestLink?: string;
  vendorName?: string;
}) => {
  const isDev = /^localhost$|^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(window.location.hostname);
  const envName = isDev ? '' : window.location.hostname.split('.')[1];
  const isProd = envName === 'melio';
  const basePath = isProd ? 'https://app.meliopayments.com' : envName ? `https://app.${envName}.meliopayments.com` : '';
  const payload = btoa(
    JSON.stringify({ paymentRequestDetails: { ref: paymentRequestLink }, vendorDetails: { name: vendorName } })
  );
  return `${basePath}/orgs/payment-request/${paymentRequestId}/pay?payload=${payload}`;
};
