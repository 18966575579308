import { useMelioIntl } from '@melio/platform-i18n';
import { boolean, object, SchemaOf } from 'yup';

import { CancelPaperEBillSubscriptionFormFields } from './types';

export const useSchema = (): SchemaOf<CancelPaperEBillSubscriptionFormFields> => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    acceptedTerms: boolean()
      .required()
      .oneOf([true], formatMessage('activities.ebillCancelPaperSubscription.form.errors.terms')),
  }) as SchemaOf<CancelPaperEBillSubscriptionFormFields>;
};
