import { useVipWelcomeModalPreference } from '@melio/in-app-marketing';
import { Group, Icon, IconProps, Link, Modal, Text, useBreakpointValue, Video } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { CompanyType, useIsAccountingFirm } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { useLearnMoreLink, useSubscriptionPlan } from '../../../hooks';

export const VipWelcomeModalActivity = () => {
  const isAccountingFirm = useIsAccountingFirm();
  const { isVip } = useSubscriptionPlan();
  const isMobile = useBreakpointValue({ xs: true, s: false });
  const isMobileOrTablet = useBreakpointValue({ s: true, m: false });
  const learnMoreLink = useLearnMoreLink();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  const {
    isVipWelcomeModalViewed,
    setVipWelcomeModalPreferenceViewed,
    isVipWelcomeModalPreferenceFetching,
    isVipWelcomeModalPreferenceMutating,
  } = useVipWelcomeModalPreference({
    enabled: isVip,
  });

  const shouldHideModal =
    !isVip || isVipWelcomeModalPreferenceFetching || isVipWelcomeModalPreferenceMutating || !!isVipWelcomeModalViewed;

  const userType = isAccountingFirm ? CompanyType.AccountingFirm : CompanyType.Smb;
  const analyticsFlow = isAccountingFirm ? 'subscription-accountant' : 'subscription';

  useEffect(() => {
    if (!shouldHideModal) {
      track('Organization', 'View', {
        PageName: 'congrats-you’ve-been-upgraded-for-free',
        Intent: 'update-firm-for-free-tier',
        Flow: analyticsFlow,
      });
    }
  }, [analyticsFlow, shouldHideModal, track]);

  if (shouldHideModal) {
    return null;
  }

  const onClose = (action: 'exit' | 'got-it') => {
    track('Organization', 'Click', {
      PageName: 'congrats-you’ve-been-upgraded-for-free',
      Intent: action,
      Flow: analyticsFlow,
      Cta: action,
    });
    void setVipWelcomeModalPreferenceViewed();
  };

  const onLearnMoreClick = () => {
    track('Organization', 'Click', {
      PageName: 'congrats-you’ve-been-upgraded-for-free',
      Intent: 'learn-more',
      Cta: 'learn-more',
      Flow: analyticsFlow,
    });
  };

  const whatsNewList: { label: string; iconType: IconProps['type'] }[] = [
    {
      label: formatMessage(`activities.subscription.vip.welcomeModal.${userType}.whatsNewList.item.first`),
      iconType: userType === CompanyType.AccountingFirm ? 'crown' : 'file-move',
    },
    {
      label: formatMessage(`activities.subscription.vip.welcomeModal.${userType}.whatsNewList.item.second`),
      iconType: userType === CompanyType.AccountingFirm ? 'contacts' : 'checked-circle',
    },
    {
      label: formatMessage(`activities.subscription.vip.welcomeModal.${userType}.whatsNewList.item.third`, {
        gracePeriodEnd: 'December 31, 2024',
      }),
      iconType: userType === CompanyType.AccountingFirm ? 'favourite' : 'stopwatch-fast',
    },
  ];

  const { photoOrder, textOrder } = isMobileOrTablet
    ? { photoOrder: 1, textOrder: 2 }
    : { photoOrder: 2, textOrder: 1 };

  return (
    <Modal
      isOpen
      size="medium"
      header={formatMessage(`activities.subscription.vip.welcomeModal.${userType}.header`)}
      onClose={() => onClose('exit')}
      primaryButton={{
        label: formatMessage(`activities.subscription.vip.welcomeModal.primaryButton.label`),
        variant: 'primary',
        onClick: () => onClose('got-it'),
      }}
      data-testid="vip-welcome-modal"
    >
      <Group variant={isMobileOrTablet ? 'vertical' : 'horizontal'}>
        <Group.Item basis="50%" order={textOrder}>
          <Group variant="vertical" spacing="l">
            <Text>
              {formatMessage(`activities.subscription.vip.welcomeModal.${userType}.description`, {
                gracePeriodEnd: 'December 31, 2024',
              })}
            </Text>
            <Group variant="vertical">
              <Text textStyle="body3">
                {formatMessage(`activities.subscription.vip.welcomeModal.${userType}.whatsNewList.header`)}
              </Text>
              {whatsNewList.map(({ label, iconType }) => (
                <Group key={label}>
                  <Icon type={iconType} />
                  <Text textStyle="body3">{label}</Text>
                </Group>
              ))}
              {isAccountingFirm && (
                <Text textStyle="body3">
                  <Link
                    href={learnMoreLink}
                    data-testid="vip-learn-more-link"
                    color="secondary"
                    label={formatMessage(`activities.subscription.vip.welcomeModal.learnMoreLink`)}
                    onClick={onLearnMoreClick}
                    newTab
                  />
                </Text>
              )}
            </Group>
          </Group>
        </Group.Item>
        <Group.Item basis="50%" order={photoOrder} alignSelf="center">
          <Video
            src="https://s3.amazonaws.com/platform-static.meliopayments.com/assets/melio/illustrations/welcome_premium.mp4"
            hideControls
            autoPlay
            loop
            height={isMobile ? '320px' : '360px'}
            width="100%"
          />
        </Group.Item>
      </Group>
    </Modal>
  );
};
