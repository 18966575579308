import { FieldValues, UseMelioFormResults } from '@melio/penny';
import {
  AccountingPlatform,
  AccountingPlatformBillLineItemLabel,
  BillLineItemLabel,
  Vendor,
} from '@melio/platform-api';

// eslint-disable-next-line import/no-extraneous-dependencies
export type { Path } from 'react-hook-form';
export type RegisterFieldFn<T extends FieldValues = AddBillV2FormValues> = UseMelioFormResults<T>['registerField'];
export type SubmitButtonProps<T extends FieldValues = AddBillV2FormValues> =
  UseMelioFormResults<T>['submitButtonProps'];
export type RegisterFieldResult = ReturnType<RegisterFieldFn>;
export type AddBillV2SetValue<T extends FieldValues = AddBillV2FormValues> = UseMelioFormResults<T>['setValue'];

export type BaseLineItem = {
  id?: string;
  lineItemId?: string;
  type: BillLineItemType;
  description?: string | null;
  amount?: string | null;
  order?: number;
  quantity?: string;
  unitPrice?: string;
  externalItemId?: string;
  externalLabelId?: string;
  externalCategoryId?: string | null;
  labels?: BillLineItemLabel[];
};

export enum AddBillV2FormFrequency {
  ONE_TIME = 'oneTime',
  WEEKLY = 'weekly',
  EVERY_2_WEEKS = 'every_2_weeks',
  EVERY_4_WEEKS = 'every_4_weeks',
  TWICE_A_MONTH = 'twice_a_month',
  MONTHLY = 'monthly',
  EVERY_2_MONTHS = 'every_2_months',
  EVERY_3_MONTHS = 'every_3_months',
  EVERY_4_MONTHS = 'every_4_months',
  EVERY_6_MONTHS = 'every_6_months',
  YEARLY = 'yearly',
}

export enum BillLineItemType {
  ITEM = 'item',
  CATEGORY = 'category',
}

export type ItemBasedBillLineItem = BaseLineItem & {
  type: BillLineItemType.ITEM;
  externalItemId: string;
  quantity: string;
  unitPrice: string;
  externalLabelId?: string;
};

export type CategoryBasedBillLineItem = BaseLineItem & {
  type: BillLineItemType.CATEGORY;
  externalCategoryId?: string | null;
  externalLabelId?: string;
};

export type XeroSyncedBillLineItem = {
  id?: string;
  lineItemId?: string;
  description?: string | null;
  order?: number;
  type: BillLineItemType;
  externalItemId?: string | null;
  externalCategoryId?: string | null;
  amount: string;
  quantity: string;
  unitPrice: string;
  externalLabelId?: string;
  labels?: BillLineItemLabel[];
};

export enum AddBillV2RecurringEndBy {
  DATE = 'end-date',
  OCCURRENCES = 'num-of-occurrences',
  NO_END_DATE = 'no-end-date',
}

export type AddBillV2FormDefaultValues = UseMelioFormResults<AddBillV2FormValues>['formState']['defaultValues'];

export type AddBillV2FormValues = {
  vendorId?: string;
  amount?: string;
  lastAmount?: string;
  balance?: number;
  amountInUSD?: string;
  currency?: string;
  frequency?: AddBillV2FormFrequency;
  categoryId?: string | null;
  recurringEndBy?: AddBillV2RecurringEndBy | null;
  recurringStartDate?: Date | null;
  recurringEndDate?: Date | null;
  recurringOccurrences?: number | null | ''; // empty string for controlled input, TODO: https://meliorisk.atlassian.net/browse/ME-385
  dueDate?: Date;
  invoiceDate?: Date;
  externalLabelId?: string;
  noteToSelf?: string;
  invoiceNumber?: string;
  nonSyncedLineItems?: BaseLineItem[];
  categoryBasedLineItems?: CategoryBasedBillLineItem[];
  itemBasedLineItems?: ItemBasedBillLineItem[];
  xeroSyncedLineItems?: XeroSyncedBillLineItem[];
};

export type AddBillV2FormLineItem =
  | BaseLineItem
  | CategoryBasedBillLineItem
  | ItemBasedBillLineItem
  | XeroSyncedBillLineItem;
export type AddBillV2FormValuesResult = Omit<
  AddBillV2FormValues,
  'nonSyncedLineItems' | 'categoryBasedLineItems' | 'itemBasedLineItems' | 'xeroSyncedLineItems'
> & {
  lineItems: AddBillV2FormLineItem[];
  fileId?: string;
  file?: File;
  vendor?: Vendor;
};
export type AddBillV2DFormInitialValues = {
  id?: string;
  vendorId?: string;
  amount?: string;
  categoryId?: string | null;
  balance?: number;
  currency?: string;
  dueDate?: Date;
  invoiceDate?: Date;
  externalLabelId?: string;
  noteToSelf?: string;
  invoiceNumber?: string;
  frequency?: AddBillV2FormFrequency;
  lineItems?: AddBillV2FormLineItem[];
  fileId?: string | null;
};

export enum AddBillV2SubmitType {
  SAVE_AND_CLOSE = 'close',
  CONTINUE_TO_PAY = 'continue',
  MARK_AS_PAID = 'markAsPaid',
}

export type CustomFooterProps = {
  onSubmitForm: (submitType: AddBillV2SubmitType) => VoidFunction;
  onClose?: VoidFunction;
  isLoading?: boolean;
};

export type FxCurrencyExchangeRate = {
  usdToForeignRate: number;
  foreignCurrency: string;
};

export type FormatFormValuesProps = {
  values: AddBillV2FormValues;
  createDate: () => Date;
  isAdvancedBillFlow?: boolean;
  billLineItemLabels?: AccountingPlatformBillLineItemLabel[];
  activeAccountingPlatform?: AccountingPlatform;
};

export enum AddBillV2OriginEnum {
  New = 'new',
  Edit = 'edit',
  ScannedInvoice = 'scannedInvoice',
}
