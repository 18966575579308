import { FinancingEligibilityStatus } from '@melio/platform-api';

import { PayDashboardEmptyState } from '../../PayDashboardEmptyState';
import { PayDashboardEmptyStateProps } from '../../PayDashboardEmptyState/PayDashboardEmptyState';
import { useFinancingTabEmptyStateContent } from '../hooks/useFinancingTabEmptyStateContent.hook';

export type FinancingTabEmptyStateProps = {
  limit: number;
  isAppliedToProvider: boolean;
  onAddNewPayment: VoidFunction;
  onFinancingApplicationApply: VoidFunction;
  eligibilityStatus: FinancingEligibilityStatus | undefined;
};

export const FinancingTabEmptyState = ({
  limit,
  isAppliedToProvider,
  onAddNewPayment,
  onFinancingApplicationApply,
  eligibilityStatus,
}: FinancingTabEmptyStateProps) => {
  const { title, description, footer, primaryButtonLabel, onPrimaryButtonClick } = useFinancingTabEmptyStateContent({
    limit,
    isAppliedToProvider,
    onAddNewPayment,
    onFinancingApplicationApply,
    eligibilityStatus,
  });

  const buttonProps: PayDashboardEmptyStateProps['buttonProps'] =
    primaryButtonLabel && onPrimaryButtonClick
      ? [
          {
            label: primaryButtonLabel,
            onClick: onPrimaryButtonClick,
            'data-testid': 'financing-tab-empty-state-primary-button',
          },
        ]
      : undefined;

  return (
    <PayDashboardEmptyState
      title={title}
      description={description}
      {...(footer ? { footer } : {})}
      buttonProps={buttonProps}
      animationSrc="https://platform-static.meliopayments.com/assets/melio/finance.lottie.json"
    />
  );
};
