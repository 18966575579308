import React, { ComponentType } from 'react';

import { forwardRef, HTMLTag } from '../react-utils';
import { FormBannerAnnouncementProvider } from './FormBannerAnnouncement';

const getDisplayName = <T,>(component: ComponentType<T>) => component.displayName || component.name || 'Component';

export const withFormBannerAnnouncementProvider = <T, S extends HTMLTag = 'div'>(UI: ComponentType<T>) => {
  const FormBannerAnnouncementProviderWrapper = forwardRef<T, S>((props: T, ref) => (
    <FormBannerAnnouncementProvider>
      <UI {...props} ref={ref} />
    </FormBannerAnnouncementProvider>
  ));
  FormBannerAnnouncementProviderWrapper.displayName = `WithFormBannerAnnouncementProvider(${getDisplayName(UI)})`;
  return FormBannerAnnouncementProviderWrapper;
};
