import { Box } from '@chakra-ui/react';
import { Group } from '@melio/penny';
import { CardBrand, useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { keys, pickBy } from 'lodash';

import { CardBrandLogo } from './components';

export type CardBrandLogosWidgetProps = {
  selectedBrand: CardBrand;
  contentAlignment?: 'center' | 'left';
};

export const CardBrandLogosWidget = forwardRef<CardBrandLogosWidgetProps, 'div'>(
  ({ selectedBrand, contentAlignment, ...props }, ref) => {
    const { services } = useConfig();
    const supportedCardProviders = pickBy<typeof services.tbt.supportedCardProviders>(
      services.tbt.supportedCardProviders
    );

    return (
      <Box data-component="CardBrandLogosWidget" {...props} ref={ref}>
        <Group justifyContent={contentAlignment === 'left' ? 'flex-start' : 'center'} spacing="xs">
          {keys(supportedCardProviders).map((brand) => (
            <CardBrandLogo
              key={brand}
              brand={brand as CardBrand}
              isSelected={selectedBrand === brand || selectedBrand === 'unknown'}
            />
          ))}
        </Group>
      </Box>
    );
  }
);

CardBrandLogosWidget.displayName = 'CardBrandLogosWidget';
