import { useMelioIntl } from '@melio/ar-domain';
import { _createFormFieldInput, Button, Container, DateField, DateFieldProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { FundingSourceType, useEstimatedDeliveryDate } from './hooks';

type ScheduledDateFieldProps = DateFieldProps & {
  fundingSourceType: FundingSourceType;
};

export const ScheduledDateField = _createFormFieldInput(
  forwardRef<ScheduledDateFieldProps, 'input'>(({ fundingSourceType, onChange, defaultIsOpen, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const [isCalendarOpen, setIsCalendarOpen] = useState(defaultIsOpen);
    const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(props.value);

    useEffect(() => {
      if (props.isReadOnly || props.isDisabled) setIsCalendarOpen(false);
    }, [isCalendarOpen, props.isReadOnly, props.isDisabled]);

    const testId = props['data-testid'] ?? 'guest-payment-scheduled-date-field';

    const CalendarFooter = (
      <Container justifyContent="space-between" paddingX="s" paddingY="s">
        <Button
          label={formatMessage('ar.guestPayment.activities.scheduledDate.footer.close.label')}
          variant="tertiary"
          onClick={() => setIsCalendarOpen(false)}
          data-testid={`${testId}-close-button`}
        />
        <Button
          label={formatMessage('ar.guestPayment.activities.scheduledDate.footer.apply.label')}
          onClick={() => {
            onChange?.(selectedDate);
            setIsCalendarOpen(false);
          }}
          data-testid={`${testId}-apply-button`}
        />
      </Container>
    );

    return (
      <DateField
        {...props}
        data-testid={testId}
        hideClear
        isOpen={isCalendarOpen}
        selectedDate={selectedDate}
        secondarySelectedDate={useEstimatedDeliveryDate(selectedDate, fundingSourceType)}
        onSelectDate={setSelectedDate}
        onOpenChange={(isOpen) => {
          if (isOpen) {
            setSelectedDate(props.value);
            setIsCalendarOpen(true);
          }
        }}
        legendItems={[
          {
            label: formatMessage('ar.guestPayment.activities.scheduledDate.calendar.legend.today'),
            variant: 'today',
          },
          {
            label: formatMessage('ar.guestPayment.activities.scheduledDate.calendar.legend.debit'),
            variant: 'primary',
          },
          {
            label: formatMessage('ar.guestPayment.activities.scheduledDate.calendar.legend.delivery'),
            variant: 'secondary',
          },
        ]}
        footer={CalendarFooter}
        ref={ref}
      />
    );
  })
);

ScheduledDateField.displayName = 'ScheduledDateField';
