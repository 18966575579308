import { isFXCurrency } from '@melio/ap-domain';
import { Tooltip, TooltipProps } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

type Props = {
  children: React.ReactNode;
  currency: string | null | undefined;
} & Omit<TooltipProps, 'content'>;
export const CurrencyTooltip = ({ children, currency, ...tooltipProps }: Props) => {
  const { getCurrencyName, formatMessage } = useMelioIntl();
  const currencyName = getCurrencyName(currency ?? 'USD');

  const isEnabled = currency && isFXCurrency(currency);
  return (
    <Tooltip
      placement="top-start"
      data-testid={`currency-tooltip-${currency || 'USD'}`}
      isEnabled={!!isEnabled}
      {...tooltipProps}
      content={formatMessage('activities.payDashboard.vendorsTab.table.headers.openBalance.currency-helper', {
        currencySymbol: currency,
        currencyName,
      })}
    >
      {children}
    </Tooltip>
  );
};
