import { Box } from '@chakra-ui/react';
import { Button, Group, useBreakpoint, UseMelioFormResults, useTheme } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React, { ReactElement } from 'react';

import { AddBillV2FormValues, AddBillV2SubmitType, CustomFooterProps } from '../types';

type Props = {
  isDisabled: boolean;
  isLoading: boolean;
  withSecondaryButton: boolean;
  onSubmit: UseMelioFormResults<AddBillV2FormValues>['handleSubmit'];
  onClose: VoidFunction;
  renderCustomFooter?: (props: CustomFooterProps) => ReactElement;
};

export const Footer = ({
  isDisabled,
  isLoading,
  withSecondaryButton,
  renderCustomFooter,
  onSubmit,
  onClose,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const theme = useTheme();
  const { isExtraSmallScreen: isMobile } = useBreakpoint();

  const handleSaveAndClose = () => onSubmit(AddBillV2SubmitType.SAVE_AND_CLOSE);
  const handleSaveAndContinue = () => onSubmit(AddBillV2SubmitType.CONTINUE_TO_PAY);

  return (
    <Box
      width="full"
      padding="s"
      justifyContent="flex-end"
      background={theme.colors.global.neutral['100']}
      borderTop="basic.light"
    >
      <Group variant="vertical" alignItems="flex-end">
        {!!renderCustomFooter && renderCustomFooter({ isLoading, onSubmitForm: onSubmit, onClose })}

        {!renderCustomFooter && (
          <Group width="full" justifyContent={withSecondaryButton ? 'space-between' : 'flex-end'}>
            {!isMobile && (
              <Button
                data-testid="add-bill-v2-cancel-button"
                variant="tertiary"
                label={formatMessage('activities.addBillV2.footer.buttons.cancel')}
                onClick={onClose}
              />
            )}
            <Group width={isMobile ? 'full' : undefined}>
              {withSecondaryButton && (
                <Button
                  data-testid="add-bill-v2-secondary-button"
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  variant="secondary"
                  isFullWidth
                  label={formatMessage('activities.addBillV2.footer.buttons.saveAndClose')}
                  onClick={handleSaveAndClose()}
                />
              )}
              <Button
                data-testid="add-bill-v2-primary-button"
                isDisabled={isDisabled}
                isLoading={isLoading}
                variant="primary"
                isFullWidth
                label={formatMessage('activities.addBillV2.footer.buttons.saveAndPay')}
                onClick={handleSaveAndContinue()}
              />
            </Group>
          </Group>
        )}
      </Group>
    </Box>
  );
};
