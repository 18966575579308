import { InvoicesApiClient, InvoicesApiInstance } from '@melio/ar-api-axios-client';

import { useCollection, UseCollectionProps } from '../../api-client';
import { useInvoiceMutations } from './mutations';
import { PostShareInvoiceRequest } from './types';

export type UseInvoicesProps = UseCollectionProps<
  typeof InvoicesApiInstance.getInvoices,
  typeof InvoicesApiClient.postInvoices
>;

export const useInvoices = (props: UseInvoicesProps = {}) => {
  const query = useCollection({
    ...props,
    isPaginated: true,
    queryKey: 'InvoicesApi',
    queryFn: InvoicesApiInstance.getInvoices,
    createFn: InvoicesApiClient.postInvoices,
    deleteFn: InvoicesApiClient.deleteInvoicesInvoiceId,
  });

  const { cancelMutation, shareMutation } = useInvoiceMutations(query.queryKey);

  return {
    ...query,
    share: (id: string, params: PostShareInvoiceRequest) =>
      shareMutation.mutateAsync({ id, postShareInvoiceRequest: params }),
    cancel: cancelMutation.update,
  };
};

export type InvoiceCollection = ReturnType<typeof useInvoices>;
