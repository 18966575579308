import { VisuallyHidden } from '@chakra-ui/react';
import { TableColumnDef, useBreakpoint } from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import _compact from 'lodash/compact';
import { useMemo } from 'react';

import { useTableColumnsSort } from '../../hooks/useTableColumnsSort';
import { usePayDashboardSorting } from '../../PayDashboardSortingProvider';
import { VendorsTabActionButtonCell } from './components/VendorsTabActionButtonCell';
import { VendorsTabGroupsCell } from './components/VendorsTabGroupsCell';
import { VendorsTabLastPaidCell } from './components/VendorsTabLastPaidCell';
import { VendorsTabMoreActionsCell } from './components/VendorsTabMoreActionsCell';
import { VendorsTabOpenBalanceCell } from './components/VendorsTabOpenBalanceCell';
import { VendorsTabUnpaidBillsCell } from './components/VendorsTabUnpaidBillsCell';
import { VendorsTabVendorNameCell } from './components/VendorsTabVendorNameCell';
import { CELLS_IDS, VendorsTableMeta, VendorsTabSortableCells } from './types';
import { useVendorsTabSortableColumns } from './useVendorsTabSortableColumns';

export const useVendorsTableColumns = ({
  onSortChange,
  filters,
}: {
  onSortChange: VoidFunction;
  filters?: {
    groups?: string[];
  };
}) => {
  const { formatMessage } = useMelioIntl();
  const sorting = usePayDashboardSorting();
  const { getSortableHeader } = useTableColumnsSort<VendorsTabSortableCells>({
    sorting,
    onSortChange,
  });
  const { sortableColumns } = useVendorsTabSortableColumns();
  const { breakpoint } = useBreakpoint();

  const isLargeScreen = ['l', 'xl'].includes(breakpoint);

  return useMemo<TableColumnDef<Vendor, VendorsTableMeta>[]>(() => {
    const vendorCell: TableColumnDef<Vendor, VendorsTableMeta> = {
      id: CELLS_IDS.VENDOR_NAME,
      isPinnedToLeft: true,
      size: 'm',
      header: getSortableHeader(sortableColumns[CELLS_IDS.VENDOR_NAME]),
      cell: ({ row, meta }) =>
        meta ? (
          <VendorsTabVendorNameCell
            vendor={row}
            rowSelectedCount={meta.rowSelections.length}
            onEditClick={() => meta.onEditVendorClick(row.id)}
          />
        ) : null,
    };
    const groupsCell: TableColumnDef<Vendor, VendorsTableMeta> = {
      id: CELLS_IDS.GROUPS,
      size: 's',
      header: formatMessage('activities.payDashboard.vendorsTab.table.headers.groups'),
      cell: ({ row }) => <VendorsTabGroupsCell vendor={row} filteredGroups={filters?.groups} />,
    };
    const unpaidBillsCell: TableColumnDef<Vendor, VendorsTableMeta> = {
      id: CELLS_IDS.UNPAID_BILLS,
      size: 120,
      header: formatMessage('activities.payDashboard.vendorsTab.table.headers.unpaidBills'),
      cell: ({ row, meta }) =>
        meta && (
          <VendorsTabUnpaidBillsCell
            vendor={row}
            unpaidBills={meta.unpaidBills.filter((bill) => bill.vendorId === row.id)}
          />
        ),
    };
    const lastPaidCell: TableColumnDef<Vendor, VendorsTableMeta> = {
      id: CELLS_IDS.LAST_PAID,
      size: 's',
      header: getSortableHeader(sortableColumns[CELLS_IDS.LAST_PAID]),
      cell: ({ row }) => <VendorsTabLastPaidCell vendor={row} />,
    };
    const openBalance: TableColumnDef<Vendor, VendorsTableMeta> = {
      id: CELLS_IDS.OPEN_BALANCE,
      size: 180,
      isPinnedToRight: isLargeScreen,
      header: getSortableHeader({ textAlign: 'end', ...sortableColumns[CELLS_IDS.OPEN_BALANCE] }),
      cell: ({ row, meta }) => {
        if (!meta) {
          return;
        }
        return (
          <VendorsTabOpenBalanceCell
            vendor={row}
            unpaidBills={meta.unpaidBills.filter((bill) => bill.vendorId === row.id)}
            value={meta.amounts[row.id]}
            onAmountChange={meta.onAmountChange}
          />
        );
      },
    };
    const actionButtonColumn: TableColumnDef<Vendor, VendorsTableMeta> = {
      id: CELLS_IDS.ACTION_BUTTON,
      size: 140,
      isPinnedToRight: isLargeScreen,
      header: formatMessage('activities.payDashboard.table.headers.action'),
      textAlign: 'end',
      cell: ({ row, meta }) =>
        meta ? (
          <VendorsTabActionButtonCell
            vendor={row}
            unpaidBills={meta.unpaidBills.filter((bill) => bill.vendorId === row.id)}
            newPaymentAmount={meta.amounts[row.id]}
            onNewPaymentClick={meta.onNewPaymentClick}
            onViewVendorClick={meta.onViewVendorClick}
            isDisabled={!!meta.rowSelections.length}
          />
        ) : null,
    };
    const moreActionsColumn: TableColumnDef<Vendor, VendorsTableMeta> = {
      id: CELLS_IDS.MORE_ACTIONS,
      size: 'xs',
      isPinnedToRight: isLargeScreen,
      header: <VisuallyHidden>{formatMessage('activities.payDashboard.table.headers.options')}</VisuallyHidden>,
      cell: ({ row, meta }) =>
        meta ? (
          <VendorsTabMoreActionsCell
            vendor={row}
            unpaidBills={meta.unpaidBills.filter((bill) => bill.vendorId === row.id)}
            onAddNewBillClick={meta.onAddNewBillClick}
            onEditVendorClick={meta.onEditVendorClick}
            onDeleteClick={meta.onDeleteVendorClick}
            onNewPaymentClick={meta.onNewPaymentClick}
            onViewVendorClick={meta.onViewVendorClick}
            ariaLabel={formatMessage('activities.payDashboard.vendorsTab.table.moreActions.ariaLabel', {
              context: meta.getItemAriaLabelContext(row),
            })}
            isDisabled={!!meta.rowSelections.length}
          />
        ) : null,
    };

    return _compact([
      vendorCell,
      filters?.groups?.length ? groupsCell : null,
      lastPaidCell,
      unpaidBillsCell,
      openBalance,
      actionButtonColumn,
      moreActionsColumn,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatMessage, sorting?.sortingState, filters, isLargeScreen]);
};
