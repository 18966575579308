import { Button, Popover, Tooltip } from '@melio/penny';
import noop from 'lodash/noop';

import { PayWithFinancingCardComponent } from './PayWithFinancingCardContent';
import { PayWithFinancingCardProps } from './types';

type DisabledPayWithFinancingCardProps = {
  disabledReason: string;
  isDisabled?: never;
} & PayWithFinancingCardProps;

export const DisabledPayWithFinancingCard: React.VFC<DisabledPayWithFinancingCardProps> = (props) => {
  const { onClick = noop, disabledReason, isSelected = false, testId, availableCredit } = props;
  if (props.learnMore) {
    return (
      <Popover
        description={disabledReason}
        actionRenderer={({ onClose, ...actionProps }) => (
          <Button
            {...actionProps}
            size="small"
            variant="secondary-inverse"
            label={props.learnMore?.label}
            onClick={() => {
              onClose();
              props.learnMore?.onClick();
            }}
          />
        )}
        data-testid={`${testId ?? 'pay-with-financing-card'}-popover`}
      >
        <PayWithFinancingCardComponent
          onClick={onClick}
          isSelected={isSelected}
          isDisabled
          availableCredit={availableCredit}
          testId="financing-card-error-state"
        />
      </Popover>
    );
  }

  return (
    <Tooltip
      content={disabledReason}
      data-testid={`${testId ?? 'pay-with-financing-card'}-tooltip`}
      shouldAddTriggerFocus
    >
      <PayWithFinancingCardComponent
        isDisabled
        onClick={onClick}
        isSelected={isSelected}
        availableCredit={availableCredit}
        testId="financing-card-error-state"
      />
    </Tooltip>
  );
};
