import { DeliveryMethod, FundingSource, useBillSubscription, usePayment, Vendor } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { useState } from 'react';

import { PaymentScheduledNotifyVendorModalActivity } from '../../PaymentScheduledNotifyVendor';
import { PaymentFlowDoneAction } from '../../types';
import { RecurringPaymentScheduledLoadingScreen } from '../screens';
import { BillSubscriptionDescriptionProps } from '../screens/RecurringPaymentScheduled/RecurringPaymentScheduled.types';
import { RecurringPaymentScheduledSuccessfullyScreen } from './screens/RecurringPaymentScheduled';
import { RecurringPaymentScheduledSuccessfullyActivityProps } from './types';

export const RecurringPaymentScheduledSuccessfullyActivity: React.VFC<
  RecurringPaymentScheduledSuccessfullyActivityProps
> = ({ onClose, onError, onDone, billSubscriptionId }) => {
  const [isNotifyVendorModalOpen, setIsNotifyVendorModalOpen] = useState<boolean>(false);

  const { data: billSubscription, isFetching } = useBillSubscription({ id: billSubscriptionId });
  const { data: payment, isFetching: isFetchingPayment } = usePayment({
    id: billSubscription?.nextOccurrence?.paymentId || undefined,
  });

  const {
    settings: {
      payment: { allowNotifyingVendor },
    },
  } = useConfig();

  if (isFetching || isFetchingPayment || !billSubscription || !billSubscription?.vendor || !payment) {
    return <RecurringPaymentScheduledLoadingScreen onClose={onClose} />;
  }

  const descriptionProps: BillSubscriptionDescriptionProps = {
    billSubscriptionId,
    amount: billSubscription.amount,
    currency: billSubscription.currency,
    vendor: billSubscription.vendor as Vendor,
    startDate: billSubscription.startDate,
    intervalType: billSubscription.intervalType,
    approvalDecisionStatus: billSubscription.approvalDecisionStatus,
    endDate: billSubscription.endDate,
    scheduledDate: billSubscription.startDate,
    fundingSource: billSubscription.fundingSource as FundingSource,
    deliveryMethod: billSubscription.deliveryMethod as DeliveryMethod,
    paymentAmount: billSubscription.amount,
    numOfOccurrences: billSubscription.numOfOccurrences,
    lastPaymentAmount: billSubscription.lastAmount,
    firstPaymentId: billSubscription.nextOccurrence?.paymentId,
    firstPaymentDeliveryDate: payment.estimatedDelivery,
    firstPaymentMaxDeliveryDate: payment.maxEstimatedDelivery,
    firstPaymentDebitDate: payment.scheduledDate,
    note: payment.note,
  };

  const vendorEmailExists = !!billSubscription.vendor?.contact.email;
  const isManagedAccountDeliveryMethod = billSubscription.deliveryMethod?.type === 'managed-account';

  return (
    <>
      <RecurringPaymentScheduledSuccessfullyScreen
        onClose={() => onDone('goToDashboard')}
        subscriptionDetails={descriptionProps}
        notifyVendor={!vendorEmailExists && !isManagedAccountDeliveryMethod && allowNotifyingVendor}
        onNotifyVendors={() => setIsNotifyVendorModalOpen(true)}
        onDone={(action: PaymentFlowDoneAction) => onDone(action)}
      />
      <PaymentScheduledNotifyVendorModalActivity
        onError={onError}
        isOpen={isNotifyVendorModalOpen}
        onClose={() => setIsNotifyVendorModalOpen(false)}
        onDone={() => {
          setIsNotifyVendorModalOpen(false);
          onDone('goToDashboard');
        }}
        vendorsIds={vendorEmailExists ? [] : [billSubscription.vendorId]}
      />
    </>
  );
};
