import { ApiError } from '@melio/api-client';
import { Link, SectionBanner } from '@melio/penny';
import { useConfig, useMelioIntl } from '@melio/platform-provider';

import { SubscriptionErrorCode } from '../../../api';

type SubscriptionCheckoutErrorBannerProps = {
  error: ApiError | null;
};

type ErrorBanner = {
  errorCode: SubscriptionErrorCode;
  message: string;
  title?: string;
  action?: {
    label: string;
    href: string;
  };
};

export const SubscriptionCheckoutErrorBanner = ({ error }: SubscriptionCheckoutErrorBannerProps) => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      vex: { zdSupportFormUrl },
    },
  } = useConfig();

  const errorOptions: ErrorBanner[] = [
    {
      errorCode: 'ZipCodeDoesntMatchState',
      message: formatMessage('activities.subscription.checkout.errorBanner.zipCodeMismatchState.description'),
      action: {
        label: formatMessage('activities.subscription.checkout.errorBanner.zipCodeMismatchState.action'),
        href: zdSupportFormUrl,
      },
    },
  ];

  const defaultErrorMessage = {
    title: formatMessage('activities.subscription.checkout.errorBanner.unknownError.title'),
    message: formatMessage('activities.subscription.checkout.errorBanner.unknownError.description'),
  } as ErrorBanner;

  const errorCode = error?.errorCode as SubscriptionErrorCode;
  const errorBanner = errorOptions.find((option) => option.errorCode === errorCode) || defaultErrorMessage;

  return (
    <>
      {error && (
        <SectionBanner
          data-testid="subscription-checkout-error-banner"
          variant="critical"
          isCompact
          title={errorBanner.title}
          description={errorBanner.message}
          {...(errorBanner.action && {
            action: <Link newTab href={zdSupportFormUrl} size="medium" label={errorBanner.action.label} />,
          })}
        />
      )}
    </>
  );
};
