import { Container, Group, Layout, useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { useSubscription } from '@melio/subscriptions';
import { useSubscriptionMe } from '@melio/subscriptions/src/api';
import { useParams } from 'react-router-dom';

import { usePatchAccountantFirmClient } from '../../api/entities/client/usePatchAccountantFirmClient';
import { FlowHeader } from '../../components/FlowHeader/FlowHeader.component';
import { SubscriptionBillingPayor } from '../../types';
import { useAccountantsRoutes } from '../../utils/useAccountantsRoutes';
import { AssignFirmClientPlanForm } from './components';
import { AssignFirmClientPlanFormFields } from './types';

export const AssignFirmClientPlan = withAnalyticsContext<{ onClose: VoidFunction }>(
  ({ onClose, setAnalyticsProperties }) => {
    setAnalyticsProperties({
      PageName: 'assign-client-plan',
      Flow: 'assign-client-plan',
    });
    const { id: accountingClientId = '' } = useParams<{ id: string }>();
    const { formatMessage } = useMelioIntl();
    const firmSubscription = useSubscription();
    const { update: updateSubscription } = useSubscriptionMe({ enabled: false });

    const { mutateAsync: updateAccountantFirmClient, isLoading: isLoadingUpdateAccountFirmClient } =
      usePatchAccountantFirmClient();

    const { toast } = useToast();
    const { goToAssignClientPlanSuccessScreen } = useAccountantsRoutes();
    const { track } = useAnalytics();

    const handleSubmit = async (data: AssignFirmClientPlanFormFields) => {
      const { subscriptionPlanId, fundingSourceId, whoPays, subscriptionPromoCode } = data;

      if (!subscriptionPlanId) {
        return;
      }

      const eventProps = {
        PlanId: subscriptionPlanId,
        FundingSourceId: fundingSourceId,
        BillingCycle: 'monthly',
      };

      const promoCode = subscriptionPromoCode || undefined;

      try {
        const isFirmBilled = whoPays === SubscriptionBillingPayor.AccountingFirm;
        if (isFirmBilled && fundingSourceId && !firmSubscription?.fundingSourceId) {
          await updateSubscription({ fundingSourceId, promoCode });
        }
        const response = await updateAccountantFirmClient({
          id: accountingClientId,
          data: {
            subscription: {
              planId: subscriptionPlanId,
              fundingSourceId: isFirmBilled ? fundingSourceId : undefined,
              promoCode,
            },
          },
        });
        track('Organization', 'Status', {
          StatusType: 'success',
          ...eventProps,
        });
        goToAssignClientPlanSuccessScreen({
          accountingClientId: response.data.organizationId,
          activated: isFirmBilled,
        });
      } catch (error: unknown) {
        track('Organization', 'Status', {
          StatusType: 'failure',
          ...eventProps,
        });
        toast({
          type: 'error',
          title: formatMessage('activities.accountants.activities.assignPan.update.error'),
        });
        Logger.log(`Error while trying to create new client.`);
      }
    };

    return (
      <Layout>
        <Container justifyContent="center" height="full" data-testid="assign-plan-container">
          <Group spacing="m" variant="vertical" width={{ xs: '100%', s: '800px' } as never}>
            <FlowHeader title={formatMessage('activities.accountants.assignPlan.form.header.text')} onClose={onClose} />
            <AssignFirmClientPlanForm
              onSubmit={handleSubmit}
              isSaving={isLoadingUpdateAccountFirmClient}
              organizationId={accountingClientId}
            />
          </Group>
        </Container>
      </Layout>
    );
  }
);
