import { CheckAccountFormModel } from '@melio/ap-widgets';
import { Traits, useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { PaperCheckDeliveryMethod, useDeliveryMethods, useVendor } from '@melio/platform-api';
import { useSystemMessage } from '@melio/platform-utils';

import { AddVendorCheckDetailsScreen } from './screens';
import { AddVendorCheckDetailsActivityProps, SubmissionTargetType } from './types';

export const AddVendorCheckDetailsActivity = withAnalyticsContext<AddVendorCheckDetailsActivityProps>(
  ({ vendorId, onBack: onFirstStepBack, onClose, onError, onDone, origin = 'payment', defaultValues }) => {
    const collection = useDeliveryMethods({ vendorId, enabled: false });
    const vendorModel = useVendor({ id: vendorId });
    const { showMessage } = useSystemMessage();

    const handleFail = (error: PlatformError) => {
      showMessage({ type: 'error', title: error.message });
      onError?.(error);
    };

    const { createTrackHandler, setTraits } = useAnalytics();
    const submitHandler = createTrackHandler<{ Status: 'failed' | 'succeeded' }>('AddVendorCheckDetails', 'Saved');
    const setDeliveryMethodTraits = () => {
      const traits: Traits = { added_delivery_method: true };

      setTraits(traits);
    };

    const createDeliveryMethod = (data: CheckAccountFormModel) => {
      const { printName, ...address } = data;
      return collection.create({ type: 'paper-check', details: { printName, address } });
    };

    const handleDone = (data: CheckAccountFormModel, target?: SubmissionTargetType) => {
      createDeliveryMethod(data)
        .then((data) => {
          submitHandler({ Status: 'succeeded' });
          setDeliveryMethodTraits();
          onDone(data as PaperCheckDeliveryMethod, target);
        })
        .catch((error) => submitHandler({ Status: 'failed' }, handleFail(error)));
    };

    return (
      <AddVendorCheckDetailsScreen
        isLoading={vendorModel.isLoading}
        onBack={onFirstStepBack}
        onClose={onClose}
        onDone={(data, _event, target) => handleDone(data, target)}
        isSaving={collection.isMutating}
        origin={origin}
        defaultValues={{ printName: vendorModel.data?.name || defaultValues?.printName, ...defaultValues }}
      />
    );
  }
);

AddVendorCheckDetailsActivity.displayName = 'AddVendorCheckDetailsActivity';
