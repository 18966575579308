import { ActionBar, Button } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Payment, PostApprovalDecisionEnum, usePayments } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { sumBy } from '@melio/platform-utils';

import { PAYMENT_APPROVAL_SELECTION_FOOTER_APPROVE_BUTTON_ID } from '../consts';

export type PaymentsApprovalSelectionFooterProps = {
  paymentIds: Payment['id'][];
  onApprove: (payments: Payment[], approvalDecisionStatus: PostApprovalDecisionEnum) => void;
  onDecline: () => void;
  onCancel: () => void;
  isMutating: boolean;
};

export const PaymentsApprovalSelectionFooter = ({
  paymentIds,
  onApprove,
  onDecline,
  onCancel,
  isMutating,
}: PaymentsApprovalSelectionFooterProps) => {
  const { track } = useAnalytics();
  const { formatMessage, formatCurrency } = useMelioIntl();

  const { data: payments = [], isLoading } = usePayments({
    enabled: paymentIds.length > 0,
    params: { search: { 'payment.id': paymentIds }, expand: 'vendor' },
  });

  const totalAmount = sumBy(payments, (payment) => payment.amount);

  const handleApprove = () => {
    track('Dashboard', 'Click', { Cta: 'approve', PaymentAmount: totalAmount });
    onApprove(payments, PostApprovalDecisionEnum.Approved);
  };

  const handleDecline = () => {
    track('Dashboard', 'Click', { Cta: 'decline', PaymentAmount: totalAmount });
    onDecline();
  };

  const handleCancel = () => {
    track('Dashboard', 'Click', { Cta: 'Cancel', PaymentAmount: totalAmount });
    onCancel();
  };

  return (
    <ActionBar
      id="payments-selection-footer"
      isLoading={isLoading}
      maxWidth={1600}
      isOpen={paymentIds.length > 0}
      summaryItems={[
        {
          label: formatMessage('activities.payDashboard.approvalsTab.selectionFooter.payments'),
          value: payments.length.toString(),
          testId: 'approval-selection-footer-approvals-count',
        },
        {
          label: formatMessage('activities.payDashboard.approvalsTab.selectionFooter.totalAmount'),
          value: formatCurrency(totalAmount),
          testId: 'approval-selection-footer-total-amount',
        },
      ]}
      actions={[
        {
          component: Button,
          testId: 'approval-selection-footer-cancel-btn',
          props: {
            label: formatMessage('activities.payDashboard.approvalsTab.selectionFooter.cancel'),
            variant: 'invert',
            onClick: handleCancel,
            'aria-label': formatMessage('activities.payDashboard.approvalsTab.selectionFooter.cancel.ariaLabel'),
          },
        },
        {
          component: Button,
          testId: 'approval-selection-footer-decline-btn',
          props: {
            label: formatMessage('activities.payDashboard.approvalsTab.selectionFooter.decline'),
            variant: 'secondary-inverse',
            onClick: handleDecline,
            'aria-label': formatMessage('activities.payDashboard.approvalsTab.selectionFooter.decline.ariaLabel', {
              totalAmount: formatCurrency(totalAmount),
              numberOfPayments: payments.length,
            }),
            isLoading: isMutating,
          },
        },
        {
          component: Button,
          testId: 'approval-selection-footer-approve-btn',
          props: {
            id: PAYMENT_APPROVAL_SELECTION_FOOTER_APPROVE_BUTTON_ID,
            label: formatMessage('activities.payDashboard.approvalsTab.selectionFooter.approve'),
            onClick: handleApprove,
            'aria-label': formatMessage('activities.payDashboard.approvalsTab.selectionFooter.approve.ariaLabel', {
              totalAmount: formatCurrency(totalAmount),
              numberOfPayments: payments.length,
            }),
            isLoading: isMutating,
          },
        },
      ]}
    />
  );
};
