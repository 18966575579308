import { CheckAccountFormModel } from '@melio/ap-widgets';
import { useAnalytics } from '@melio/platform-analytics';
import { PaperCheckDeliveryMethod, useDeliveryMethod, useDeliveryMethods } from '@melio/platform-api';
import { useSystemMessage } from '@melio/platform-utils';
import { isEqual } from 'lodash';
import { useRef } from 'react';

import {
  EffectedPaymentsDisclaimerModal,
  useEffectedPaymentsDisclaimerModal,
} from '../components/EffectedPaymentsDisclaimerModal';
import { EditVendorCheckDetailsScreen } from './screens';
import { EditVendorCheckDetailsActivityProps } from './types';

export const EditVendorCheckDetailsActivity = ({
  deliveryMethodId,
  vendorId,
  onBack: onFirstStepBack,
  onClose,
  onError,
  onDone,
  banner,
  title,
}: EditVendorCheckDetailsActivityProps) => {
  const deliveryMethod = useDeliveryMethod({ id: deliveryMethodId });
  const deliveryMethodCollection = useDeliveryMethods({ vendorId });
  const effectedPaymentsDisclaimerModal = useEffectedPaymentsDisclaimerModal(vendorId);
  const formData = useRef<CheckAccountFormModel>();

  const currentDetails = deliveryMethod.data?.details as PaperCheckDeliveryMethod['details'];

  const { showMessage } = useSystemMessage();

  const handleFail = (error: PlatformError) => {
    showMessage({ type: 'error', title: error.message });
    onError?.(error);
  };

  const { createTrackHandler } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{ Cta: 'continue'; IsSameAddress: boolean }>('Payment', 'Click');
  const submitHandler = createTrackHandler<{
    Status: 'failed' | 'succeeded';
    DeliveryMethodChosen: 'paper-check';
    Cta?: 'confirm';
  }>('DeliveryMethodEdited', 'Saved', { DeliveryMethodChosen: 'paper-check' });

  const updateDeliveryMethod = (data: CheckAccountFormModel) => {
    const { printName, ...address } = data;

    deliveryMethodCollection
      .create({
        type: 'paper-check',
        details: {
          printName,
          address,
        },
      })
      .then((data) =>
        submitHandler({ Status: 'succeeded', Cta: 'confirm' }, () => onDone(data as PaperCheckDeliveryMethod))
      )
      .catch((...args) => submitHandler({ Status: 'failed' }, () => handleFail(...args)));
  };

  const handleSubmit = (data: CheckAccountFormModel) => {
    const isSameAddress = isEqual(
      { state: data.state, city: data.city, line1: data.line1, postalCode: data.postalCode },
      {
        state: currentDetails.address.state,
        city: currentDetails.address.city,
        line1: currentDetails.address.line1,
        postalCode: currentDetails.address.postalCode,
      }
    );

    if (effectedPaymentsDisclaimerModal.showDisclaimer) {
      formData.current = data;
      trackAndHandleClick({ Cta: 'continue', IsSameAddress: isSameAddress }, effectedPaymentsDisclaimerModal.onOpen());
    } else {
      trackAndHandleClick({ Cta: 'continue', IsSameAddress: isSameAddress }, updateDeliveryMethod(data));
    }
  };

  const handleConfirm = () => {
    if (formData.current) {
      updateDeliveryMethod(formData.current);
      effectedPaymentsDisclaimerModal.onClose();
    }
  };

  return (
    <>
      <EditVendorCheckDetailsScreen
        defaultValues={currentDetails ? { ...currentDetails.address, printName: currentDetails.printName } : {}}
        onBack={onFirstStepBack}
        onClose={onClose}
        onDone={handleSubmit}
        isSaving={deliveryMethodCollection.isMutating}
        isLoading={deliveryMethod.isLoading}
        banner={banner}
        title={title}
      />
      <EffectedPaymentsDisclaimerModal
        isOpen={effectedPaymentsDisclaimerModal.isOpen}
        onClose={effectedPaymentsDisclaimerModal.onClose}
        onConfirm={handleConfirm}
      />
    </>
  );
};

EditVendorCheckDetailsActivity.displayName = 'EditVendorCheckDetailsActivity';
