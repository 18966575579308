import { BrandSymbol, BrandSymbolProps, NakedButton, SectionBanner } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { ShowPaywallForIneligibleFeatureOptions } from '@melio/subscriptions/src/hooks/useSubscriptionFeature';

type PlanRemainingPaymentsSyncSectionBannerProps = {
  description: string;
  accountingPlatformBrandSymbol: BrandSymbolProps;
  showPaywallForSyncedPaymentsFeature: (options?: ShowPaywallForIneligibleFeatureOptions) => void;
};

export const PlanRemainingPaymentsSyncSectionBanner = ({
  description,
  accountingPlatformBrandSymbol,
  showPaywallForSyncedPaymentsFeature,
}: PlanRemainingPaymentsSyncSectionBannerProps) => {
  const { formatMessage } = useMelioIntl();

  const { track } = useAnalytics();

  const handleUpgrade = () => {
    track('Payment', 'Click', { Cta: 'upgrade-plan', Intent: 'upgrade-to-sync', PageName: 'payment-scheduled' });

    showPaywallForSyncedPaymentsFeature({
      returnUrl: '/pay-dashboard',
    });
  };

  return (
    <SectionBanner
      data-testid="plan-remaining-payments-sync-section-banner"
      variant="secondary"
      description={description}
      leftElement={<BrandSymbol size="medium" aria-hidden {...accountingPlatformBrandSymbol} />}
      action={
        <NakedButton
          data-testid="plan-remaining-payments-sync-section-banner-action-button"
          onClick={handleUpgrade}
          label={formatMessage(
            'activities.paymentScheduled.screens.paymentScheduled.planRemainingPaymentsSync.upgradePlan'
          )}
        />
      }
    />
  );
};
