import { BaseModal, Container, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useLocation } from '@melio/platform-utils';

import {
  useFreeTrialEligibility,
  useIsSubscriptionsEnabled,
  useRedirects,
  useSubscribeToFreePlan,
  useSubscription,
} from '../../../hooks';
import { isRouteAllowedForNotSubscribedOrg } from '../../utils';
import { SubscriptionPlansActivity, SubscriptionPlansVariant } from '../SubscriptionPlans';

export const OnboardingPaywallModalActivity = () => {
  const { formatMessage } = useMelioIntl();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const subscription = useSubscription();
  const { isExtraSmallScreen } = useBreakpoint();
  const { pathname } = useLocation();
  const { subscribeToFreePlan, isSubscribingToFreePlan } = useSubscribeToFreePlan();
  const { goToReturnUrlOrDashboard } = useRedirects();
  const { isEligibleForFreeTrial, isFetching: isFetchingFreeTrialEligibilityData } = useFreeTrialEligibility();
  const { track } = useAnalytics();

  const modalProps = { maxW: '1100px' };

  const handleOnClose = () => {
    if (isSubscribingToFreePlan) {
      return;
    }

    if (!subscription) {
      track('Organization', 'Click', {
        PageName: 'plan-selection',
        Flow: 'subscription',
        Intent: 'exit',
        Cta: 'exit',
        PlanChosen: 'free',
      });

      void subscribeToFreePlan();
    } else {
      goToReturnUrlOrDashboard();
    }
  };

  if (!isSubscriptionsEnabled) {
    return null;
  }

  const isOpen = !subscription && !isRouteAllowedForNotSubscribedOrg(pathname);

  return (
    <BaseModal
      {...modalProps}
      isOpen={isOpen}
      onClose={handleOnClose}
      data-testid="onboarding-paywall-activity"
      size="large"
      isLoading={isFetchingFreeTrialEligibilityData}
    >
      <BaseModal.Header>
        <Container paddingTop={isExtraSmallScreen ? 'none' : 'xxs'}>
          <Text as="h2" textStyle="heading2Semi">
            {formatMessage(
              isEligibleForFreeTrial
                ? 'activities.subscription.plans.titleWhenEligibleForFreeTrial'
                : 'activities.subscription.plans.title'
            )}
          </Text>
        </Container>
      </BaseModal.Header>
      <BaseModal.Body>
        <SubscriptionPlansActivity
          returnUrl={pathname}
          variant={SubscriptionPlansVariant.MODAL}
          isSubscribingToFreePlan={isSubscribingToFreePlan}
        />
      </BaseModal.Body>
    </BaseModal>
  );
};
