import { useEffect, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useIsSubscriptionsEnabled, useRedirects, useWizardParams } from '../../../hooks';
import { SubscriptionWizardScreen } from './screens/SubscriptionWizard.screen';

export const SubscriptionWizardActivity = () => {
  const isFirstRouteInWizard = useIsFirstRouteInWizard();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { goToReturnUrlOrDashboard } = useRedirects();

  const { returnUrl, planId } = useWizardParams();

  const handleOnClose = () => {
    goToReturnUrlOrDashboard();
  };

  if (!isSubscriptionsEnabled || !planId) {
    return <Navigate to="/error" />;
  }

  return (
    <SubscriptionWizardScreen
      returnUrl={returnUrl}
      planId={planId}
      onClose={handleOnClose}
      onBackOverride={isFirstRouteInWizard ? handleOnClose : undefined}
    />
  );
};

const useIsFirstRouteInWizard = () => {
  const location = useLocation();
  const prevLocationsRef = useRef<string[]>([]);
  useEffect(() => {
    if (prevLocationsRef.current.length <= 1) {
      prevLocationsRef.current.push(location.pathname);
    }
  }, [location]);

  return prevLocationsRef.current.length <= 1;
};
