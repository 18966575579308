import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CreateApprovalWorkflow, UpdateApprovalWorkflowActivity } from '@melio/ap-activities';
import { LoadingContainer, useBreakpoint } from '@melio/penny';
import { FeatureFlags } from '@melio/platform-feature-flags';
import { useDevFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';

import { useRouter } from '@/hooks/router.hooks';

export function ApprovalWorkflowsRoute() {
  const { goToSettingsWorkflows } = useRouter();
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const [isEditApprovalWorkflowEnabled] = useDevFeature(FeatureFlags.EditApprovalWorkflowEnabled, false);

  const { can } = usePermissions();
  const canCreateWorkflows = can({ action: 'create', subject: 'approvalWorkflow' });
  const canEditWorkflows = can({ action: 'update', subject: 'approvalWorkflow' }) && isEditApprovalWorkflowEnabled;

  if (isMobile) return null;
  return (
    <Routes>
      {canEditWorkflows && (
        <Route
          path="/:id"
          element={
            <UpdateApprovalWorkflowActivity
              onBack={() => goToSettingsWorkflows()}
              onClose={() => goToSettingsWorkflows()}
              onDone={() => {
                goToSettingsWorkflows({ newWorkflowCreated: false });
              }}
            />
          }
        />
      )}
      {canCreateWorkflows && (
        <Route
          path="/new"
          element={
            <Suspense fallback={<LoadingContainer isLoading />}>
              <CreateApprovalWorkflow
                onBack={() => goToSettingsWorkflows()}
                onClose={() => goToSettingsWorkflows()}
                onDone={({ workflowId }) => {
                  goToSettingsWorkflows({ newWorkflowCreated: true, workflowId });
                }}
              />
            </Suspense>
          }
        />
      )}
    </Routes>
  );
}
