import { Button, Icon, Loader, Menu } from '@melio/penny';
import { useVendorGroups, VendorGroup } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { orderBy } from 'lodash';
import { useState } from 'react';

import { ALL_VENDORS_GROUP_ID, VendorGroupFilterOption } from './types';

export type VendorGroupsFilterProps = {
  selectedVendorGroupIds: VendorGroup['id'][];
  onSelectFilter: ({ vendorGroupIds }: { vendorGroupIds: VendorGroup['id'][] }) => void;
  onCreateClick: VoidFunction;
};

export const VendorGroupsFilter = ({
  onSelectFilter,
  onCreateClick,
  selectedVendorGroupIds,
}: VendorGroupsFilterProps) => {
  const { formatMessage } = useMelioIntl();
  const allVendorsGroupName = formatMessage('activities.payDashboard.vendorsTab.vendorGroups.filter.all');
  const [isOpen, setIsOpen] = useState(false);
  const { data: vendorGroups, isLoading: isLoadingVendorGroups } = useVendorGroups({ cacheTime: 0 });

  const vendorGroupsFilterOptions: VendorGroupFilterOption[] = vendorGroups?.length
    ? [
        { id: ALL_VENDORS_GROUP_ID, name: allVendorsGroupName },
        ...orderBy(vendorGroups, (group) => group.name.toLowerCase(), 'asc'),
      ]
    : [];

  const isGroupSelected = (vendorGroupId: VendorGroupFilterOption['id']) =>
    selectedVendorGroupIds.includes(vendorGroupId) ||
    (vendorGroupId === ALL_VENDORS_GROUP_ID && !selectedVendorGroupIds.length);

  const handleSelectGroup = ({ vendorGroupId }: { vendorGroupId: VendorGroupFilterOption['id'] }) => {
    const selectedVendorGroupIds = vendorGroupId === ALL_VENDORS_GROUP_ID ? [] : [vendorGroupId];
    onSelectFilter({ vendorGroupIds: selectedVendorGroupIds });
    handleToggleMenu();
  };

  const handleToggleMenu = () => setIsOpen(() => !isOpen);

  const getMenuLabel = () => {
    const selectedGroup = vendorGroups?.find((group) => selectedVendorGroupIds.includes(group.id));
    return selectedGroup ? selectedGroup.name : allVendorsGroupName;
  };

  const handleCreateGroupClick = () => {
    onCreateClick();
  };

  return (
    <Menu
      trigger={
        <Button
          label={getMenuLabel()}
          data-testid="vendor-groups-filter-menu"
          aria-label={formatMessage('activities.payDashboard.vendorsTab.vendorGroups.filter.button.aria-label', {
            groupName: getMenuLabel(),
          })}
          variant="tertiary"
          rightElement={<Icon size="small" type="caret-down" color="inherit" aria-hidden />}
        />
      }
      isOpen={isOpen}
      onOpenChange={handleToggleMenu}
      data-testid="vendor-groups-filter-menu-dropdown"
      footer={{
        label: formatMessage('activities.payDashboard.vendorsTab.vendorGroups.filter.footer.button.create'),
        onClick: handleCreateGroupClick,
        children: (
          <Button
            variant="secondary"
            size="small"
            label={formatMessage('activities.payDashboard.vendorsTab.vendorGroups.filter.footer.button.create')}
            leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
            isFullWidth
            data-testid="vendor-groups-filter-create-group-button"
            role="menuitem"
          />
        ),
      }}
    >
      {isLoadingVendorGroups ? (
        <Loader />
      ) : vendorGroupsFilterOptions.length ? (
        vendorGroupsFilterOptions.map((option) => (
          <Menu.Item
            key={option.id}
            isSelected={isGroupSelected(option.id)}
            onClick={() => handleSelectGroup({ vendorGroupId: option.id })}
            data-testid={`vendor-groups-filter-menu-item-${option.id}`}
            label={option.name}
            aria-checked={isGroupSelected(option.id)}
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            role="menuitemradio"
          />
        ))
      ) : (
        <NoVendorGroupsMenuItem />
      )}
    </Menu>
  );
};

export const NoVendorGroupsMenuItem = () => {
  const { formatMessage } = useMelioIntl();
  return (
    <Menu.Item
      textStyle="body3"
      disabled={{ isDisabled: true }}
      data-testid="vendor-groups-filter-no-groups-message"
      label={formatMessage('activities.payDashboard.vendorsTab.vendorGroups.filter.option.empty')}
    />
  );
};
