import { VisuallyHidden } from '@chakra-ui/react';
import { Button, Container, Divider, Group, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { FundingSource } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';

import { useSubscriptionMe } from '../../../api';
import {
  usePartnerGroups,
  useSubscription,
  useSubscriptionOrganizationFailureState,
  useSubscriptionPlan,
} from '../../../hooks';
import { useSubscriptionRouter } from '../../utils';

type SubscriptionSelectFundingSourceFooterProps = {
  fundingSources?: FundingSource[];
  selectedFundingSourceId: string;
  isDisabled: boolean;
};

export const SubscriptionSelectFundingSourceFooter = ({
  fundingSources,
  selectedFundingSourceId,
  isDisabled,
}: SubscriptionSelectFundingSourceFooterProps) => {
  const { formatMessage } = useMelioIntl();
  const {
    goToSubscriptionSelectFundingSourceSuccess,
    goToSubscriptionPlansSettings,
    goToSubscriptionSelectFundingSourceError,
  } = useSubscriptionRouter();
  const { currentPlan } = useSubscriptionPlan();
  const subscription = useSubscription();
  const { update: updateSubscription, isMutating: isUpdatingSubscription } = useSubscriptionMe({ enabled: false });
  const { isFiservPartner } = usePartnerGroups();
  const { isExtraSmallScreen } = useBreakpoint();
  const shouldDisableSaveButton = (!isFiservPartner && fundingSources?.length == 0) || isDisabled;
  const { failureState } = useSubscriptionOrganizationFailureState();
  const { track } = useAnalytics();

  const baseAnalyticsData = {
    Cta: 'continue',
    IsSamePm: subscription?.fundingSourceId === selectedFundingSourceId,
    PMType: fundingSources?.find((f) => f.id === selectedFundingSourceId)?.type,
  };

  const trackUpdateStatus = (status: 'success' | 'failure', errorType?: string) => {
    track('PaymentMethod', 'Status', {
      ...baseAnalyticsData,
      StatusType: status,
      ...(status === 'failure' && { ErrorType: errorType }),
    });
  };

  const updateFundingSource = (fundingSourceId: string) => {
    track('PaymentMethod', 'Click', baseAnalyticsData);

    if (!currentPlan) {
      return;
    }

    if (fundingSourceId === subscription?.fundingSourceId && !failureState) {
      goToSubscriptionPlansSettings();
      return;
    }

    void updateSubscription({ fundingSourceId })
      .then(() => {
        trackUpdateStatus('success');
        goToSubscriptionSelectFundingSourceSuccess();
      })
      .catch(() => {
        trackUpdateStatus('failure', formatMessage('activities.subscription.selectPaymentMethod.error.title'));
        goToSubscriptionSelectFundingSourceError();
      });
  };

  const SaveButton = ({ isFullWidth = false }: { isFullWidth?: boolean }) => (
    <Button
      size="medium"
      variant="primary"
      data-testid="subscription-payment-method-select-save-button"
      isDisabled={shouldDisableSaveButton}
      isLoading={isUpdatingSubscription}
      label={formatMessage('activities.subscription.paymentMethod.select.save')}
      onClick={() => updateFundingSource(selectedFundingSourceId)}
      isFullWidth={isFullWidth}
    />
  );

  return isFiservPartner ? (
    <>
      <Divider />
      <VisuallyHidden role="status" aria-live="polite">
        {isUpdatingSubscription && formatMessage('activities.addBillV2.footer.saving')}
      </VisuallyHidden>
      <Container paddingX="m" paddingY="m">
        <Group justifyContent="space-between">
          {!isExtraSmallScreen && (
            <Button
              size="medium"
              data-testid="add-bill-v2-cancel-button"
              variant="tertiary"
              label={formatMessage('activities.addBillV2.footer.buttons.cancel')}
              onClick={() => goToSubscriptionPlansSettings()}
            />
          )}
          <SaveButton isFullWidth={isExtraSmallScreen} />
        </Group>
      </Container>
    </>
  ) : (
    <Container width="full" paddingX="m" paddingY="m" justifyContent="center">
      <SaveButton />
    </Container>
  );
};
