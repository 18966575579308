import { getEbillAmount, isEbill, isFXCurrency } from '@melio/ap-domain';
import {
  InboxItemBillTypeEnum,
  InboxItemPaymentRequestTypeEnum,
  InboxItemScannedInvoiceTypeEnum,
  Vendor,
} from '@melio/platform-api';

import { BillsTabItem } from './types';

export const isPartiallyPaid = (billsTabItem: BillsTabItem) => {
  if (billsTabItem.type === InboxItemBillTypeEnum.Bill) {
    const { balance, amount } = billsTabItem.payload;
    return 0 < balance && balance < amount;
  }
  return false;
};

export const getBillsTabItemVendorId = (billsTabItem: BillsTabItem) => {
  switch (billsTabItem.type) {
    case InboxItemBillTypeEnum.Bill:
      return billsTabItem.payload.vendorId;
    default:
      return null;
  }
};

export const getBillsTabItemAmount = (billsTabItem: BillsTabItem) => {
  switch (billsTabItem.type) {
    case InboxItemBillTypeEnum.Bill:
      return isEbill(billsTabItem.payload) ? getEbillAmount(billsTabItem.payload) : billsTabItem.payload.amount;
    case InboxItemScannedInvoiceTypeEnum.ScannedInvoice:
      return billsTabItem.payload.amount;
    case InboxItemPaymentRequestTypeEnum.PaymentRequest:
      return billsTabItem.payload.totalAmount;
  }
};

export const getInboxItemVendorName = (billsTabItem: BillsTabItem) => {
  switch (billsTabItem.type) {
    case InboxItemBillTypeEnum.Bill:
      return billsTabItem.payload.vendor?.name;
    case InboxItemPaymentRequestTypeEnum.PaymentRequest:
      return billsTabItem.payload.vendor?.name;
    case InboxItemScannedInvoiceTypeEnum.ScannedInvoice:
      return billsTabItem.payload.vendorName;
  }
};

export const getInboxItemExternalVendorLogo = (billsTabItem: BillsTabItem) => {
  if (
    billsTabItem.type === InboxItemBillTypeEnum.Bill ||
    billsTabItem.type === InboxItemPaymentRequestTypeEnum.PaymentRequest
  ) {
    return billsTabItem.payload.vendor?.externalLogoUrl;
  }
  return undefined;
};

export const getIsManagedVendor = (billsTabItem: BillsTabItem) => {
  if (
    billsTabItem.type === InboxItemBillTypeEnum.Bill ||
    billsTabItem.type === InboxItemPaymentRequestTypeEnum.PaymentRequest
  ) {
    return billsTabItem.payload.vendor?.isManaged;
  }
  return false;
};

export const getBillsTabItemVendor = (billsTabItem: BillsTabItem) => {
  switch (billsTabItem.type) {
    case InboxItemBillTypeEnum.Bill:
    case InboxItemPaymentRequestTypeEnum.PaymentRequest:
      return billsTabItem.payload.vendor as Vendor | undefined;
    default:
      return undefined;
  }
};

export const getBillsTabItemCurrency = (billItem: BillsTabItem) =>
  billItem.type === InboxItemBillTypeEnum.Bill || billItem.type === InboxItemPaymentRequestTypeEnum.PaymentRequest
    ? billItem.payload?.currency
    : undefined;

export const isFxBillsTabItem = (billItem: BillsTabItem) => isFXCurrency(getBillsTabItemCurrency(billItem));
