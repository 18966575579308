import { OrganizationPreference } from '@melio/platform-api';

import {
  VendorCreatedHandler,
  VendorDeletedHandler,
  VendorDeliveryMethodsUpdatedHandler,
  VendorUpdatedHandler,
} from '@/queries/event-handlers/vendors.handlers';

export const enum APP_EVENTS {
  VENDOR_UPDATED = 'VENDOR_UPDATED',
  VENDOR_CREATED = 'VENDOR_CREATED',
  VENDOR_DELETED = 'VENDOR_DELETED',
  VENDOR_DELIVERY_METHODS_UPDATED = 'VENDOR_DELIVERY_METHODS_UPDATED',
  INVALIDATE_FUNDING_SOURCE = 'INVALIDATE_FUNDING_SOURCE',
  ACCOUNTING_PLATFORM_SYNC_TRIGGERED = 'ACCOUNTING_PLATFORM_SYNC_TRIGGERED',
  ACCOUNTING_PLATFORM_DISCONNECTED = 'ACCOUNTING_PLATFORM_DISCONNECTED',
  ACCOUNTING_PLATFORM_SYNC_STATUS_DONE = 'ACCOUNTING_PLATFORM_SYNC_STATUS_DONE',
  SCHEDULE_PAYMENT_FLOW_CLOSED = 'SCHEDULE_PAYMENT_FLOW_CLOSED',
  PAYMENT_METHOD_FLOW_CLOSED = 'PAYMENT_METHOD_FLOW_CLOSED',
  LOGO_URL = 'LOGO_URL',
}

export type DeletedEventPayloadType = {
  deletedId: string;
};
export type LogoUrlUpdatedEventPayloadType = {
  accountId?: string;
  logoUrl?: string;
};
export type PreferenceUpdatedEventPayloadType = {
  preference: OrganizationPreference;
};
export type InvalidateFundingSourceEventPayloadType = {
  fundingSourceId: string;
};
export type AccountingPlatformSyncTriggeredEventPayloadType = {
  accountingPlatformId: string;
};
export type AccountingPlatformDisconnectEventPayloadType = {
  accountingPlatformId: string;
};
export type AccountingPlatformSyncNowEventPayloadType = {
  accountingPlatformId: string;
};

export interface EventPayloadTypes {
  [APP_EVENTS.VENDOR_CREATED]: VendorCreatedHandler;
  [APP_EVENTS.VENDOR_UPDATED]: VendorUpdatedHandler;
  [APP_EVENTS.VENDOR_DELETED]: VendorDeletedHandler;
  [APP_EVENTS.VENDOR_DELIVERY_METHODS_UPDATED]: VendorDeliveryMethodsUpdatedHandler;
  [APP_EVENTS.SCHEDULE_PAYMENT_FLOW_CLOSED]: () => void;
  [APP_EVENTS.PAYMENT_METHOD_FLOW_CLOSED]: () => void;
  [APP_EVENTS.LOGO_URL]: ({ accountId, logoUrl }: LogoUrlUpdatedEventPayloadType) => void;
  [APP_EVENTS.INVALIDATE_FUNDING_SOURCE]: ({ fundingSourceId }: InvalidateFundingSourceEventPayloadType) => void;
  [APP_EVENTS.ACCOUNTING_PLATFORM_SYNC_TRIGGERED]: ({
    accountingPlatformId,
  }: AccountingPlatformSyncTriggeredEventPayloadType) => void;
  [APP_EVENTS.ACCOUNTING_PLATFORM_DISCONNECTED]: ({
    accountingPlatformId,
  }: AccountingPlatformDisconnectEventPayloadType) => void;
  [APP_EVENTS.ACCOUNTING_PLATFORM_SYNC_STATUS_DONE]: ({
    accountingPlatformId,
  }: AccountingPlatformSyncNowEventPayloadType) => void;
}
