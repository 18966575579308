import { useInvalidFundingSources } from '@melio/ap-domain';
import { useFundingSourcesIconKeys } from '@melio/ap-widgets';
import { SelectableDropdownMenuItem, Table } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { getFundingSourceLabel } from '../utils';
import { useFundingSourceHeaderDescription } from './FundingSourceHeaderSelectCell.utils';

export type FundingSourceHeaderSelectCellProps = {
  fundingSources: FundingSource[]; // there will always be at least 1 funding source
  selectedFundingSourceId?: FundingSource['id'];
  onSelect: (selectFundingSource: FundingSource) => void;
  onAddFundingSource: VoidFunction;
};

export const FundingSourceHeaderSelectCell = forwardRef<FundingSourceHeaderSelectCellProps, 'div'>(
  ({ fundingSources, onSelect, onAddFundingSource, selectedFundingSourceId, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { getFundingSourceTooltip, getIsValidFundingSource } = useInvalidFundingSources();
    const fundingSourcesAndIcons = useFundingSourcesIconKeys(fundingSources);
    const [isCombinedPaymentsSupported] = useDevFeature(FeatureFlags.CombinedPayments, false);

    const { getDescription } = useFundingSourceHeaderDescription();
    const options: SelectableDropdownMenuItem[] = fundingSourcesAndIcons.map(({ fundingSource, icon }) => ({
      label: getFundingSourceLabel(fundingSource, formatMessage),
      value: fundingSource.id,
      icon,
      disabled: {
        isDisabled: !getIsValidFundingSource(fundingSource),
        message: getFundingSourceTooltip(fundingSource),
      },
    }));

    const handleSelect = (selectedFundingSourceId: FundingSource['id']) => {
      const selectedFundingSource = fundingSources.find((fs) => fs.id === selectedFundingSourceId);
      selectedFundingSource && onSelect(selectedFundingSource);
    };

    const description = isCombinedPaymentsSupported
      ? getDescription(fundingSources, selectedFundingSourceId)
      : undefined;

    return (
      <Table.SelectHeaderCell
        {...props}
        data-testid="funding-source-header-select-cell"
        options={options}
        label={formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.fundingSourceHeaderSelectCell.label'
        )}
        aria-label={formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.fundingSourceHeaderSelectCell.label'
        )}
        description={description}
        footerAction={{
          label: formatMessage(
            'activities.batchPayments.screens.paymentIntentsTable.fundingSourceHeaderSelectCell.footerAction.label'
          ),
          onClick: onAddFundingSource,
          dataTestId: 'add-another-delivery-method',
        }}
        onSelect={handleSelect}
        value={selectedFundingSourceId}
        ref={ref}
      />
    );
  }
);

FundingSourceHeaderSelectCell.displayName = 'FundingSourceHeaderSelectCell';
