import { FormInputs, useMelioIntl } from '@melio/ar-domain';
import { Container } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useInvoiceFormContext } from '../../../utils';

type InvoiceItemFormProps = {
  index: number;
  onCreate: VoidFunction;
  onDelete: (isDeletedByUser: boolean) => void;
  onError?: (type: 'general' | 'sizeExceeded') => void;
  isSaving: boolean;
};

const MAX_ATTACHMENT_FILE_SIZE_10MB = 10 * 1024 * 1024;
const BUFFER_SIZE = 500 * 1024; // 500 KB

const MAX_ATTACHMENTS_ALLOWED = 10;

export const InvoiceFileAttachmentsForm = forwardRef<InvoiceItemFormProps>(
  ({ isSaving, onError, onCreate, onDelete, index }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { filesFieldArray, registerField, getValues } = useInvoiceFormContext();
    const field = filesFieldArray.fields[index];
    const { attachments } = getValues();
    const currentFilesSize = attachments?.reduce((total, attachment) => total + (attachment.size || 0), 0) || 0;
    const remainingFilesSize = (MAX_ATTACHMENT_FILE_SIZE_10MB - currentFilesSize) / (1024 * 1024);
    const formattedLeftSize = Number(remainingFilesSize.toFixed(1));

    return (
      <Container ref={ref}>
        <FormInputs.MultipleAttachmentSelect
          maxFilesSize={MAX_ATTACHMENT_FILE_SIZE_10MB - BUFFER_SIZE}
          currentFilesSize={currentFilesSize}
          data-testid={field?.id}
          {...(!attachments?.[index]?.fileName && {
            helperTextProps: {
              label: formatMessage(
                index === MAX_ATTACHMENTS_ALLOWED - 1
                  ? 'ar.invoiceLifecycle.activities.createInvoice.inputs.attachments.singular.helperText'
                  : 'ar.invoiceLifecycle.activities.createInvoice.inputs.attachments.plural.helperText',
                {
                  attachmentIndex: 10 - index,
                  leftSizeOfAttachments:
                    formattedLeftSize % 1 === 0 ? formattedLeftSize.toFixed(0) : formattedLeftSize.toFixed(1),
                }
              ),
            },
          })}
          onError={onError}
          isReadOnly={isSaving}
          labelProps={{
            label:
              index === 0
                ? formatMessage(
                    'ar.invoiceLifecycle.activities.createInvoice.inputs.attachments.withoutNumberSign.label'
                  )
                : formatMessage(
                    'ar.invoiceLifecycle.activities.createInvoice.inputs.attachments.withNumberSign.label',
                    {
                      index: index + 1,
                    }
                  ),
          }}
          onChangeState={(data, isDeletedByUser = false) => {
            if (data) {
              onCreate();
            } else {
              onDelete(isDeletedByUser);
            }
          }}
          acceptTypes={['pdf']}
          placeholder={formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.attachments.placeholder')}
          {...registerField(`attachments.${index}`)}
        />
      </Container>
    );
  }
);
InvoiceFileAttachmentsForm.displayName = 'InvoiceAttachmentForm';
