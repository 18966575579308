import { useAnalyticsContext, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';

import { getDefaultValuesFromAccount } from '../BusinessLegalInfo.utils';
import { useInternationalAddBusinessInfo } from './AddInternationalBusinessInfo.activity.utils';
import { AddInternationalBusinessInfoScreen } from './screens';
import { AddInternationalBusinessInfoActivityProps } from './types';

export const AddInternationalBusinessInfoActivity = withAnalyticsContext(
  ({ onBack, onClose, onDone, origin }: AddInternationalBusinessInfoActivityProps) => {
    const { currentAccount, handleDone, isLoading, isUpdating, missingLegalInfoFields, taxIdType } =
      useInternationalAddBusinessInfo({
        onDone,
      });

    const { legalCompanyName, legalAddress, taxInfoType, taxInfoIdentifier, businessType } =
      getDefaultValuesFromAccount(currentAccount);

    useAnalyticsContext({
      globalProperties: {
        PageName: 'add-your-business-details',
        Intent: 'add-delivery-method',
      },
    });
    useAnalyticsView('Organization', !isLoading, true);

    if (missingLegalInfoFields?.length === 0) {
      return null;
    }

    return (
      <AddInternationalBusinessInfoScreen
        missingLegalInfoFields={missingLegalInfoFields}
        taxIdType={taxIdType}
        isLoading={isLoading}
        isSaving={isUpdating}
        defaultValues={{
          legalCompanyName,
          legalAddress,
          taxInfoType,
          taxInfoIdentifier,
          businessType,
        }}
        onBack={onBack}
        onClose={onClose}
        onDone={(data, _event, target) => handleDone(data, target)}
        origin={origin}
      />
    );
  }
);
