import { _createFormFieldInput, AmountField as _AmountInput, AmountFieldProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ConversionUtils } from '../conversion-utils';
import { isValidValue } from './utils';

export const AmountFieldInput = _createFormFieldInput(
  forwardRef<AmountFieldProps, 'input'>(({ onChange, value: _value, ...props }, ref) => {
    const value = useMemo(() => {
      if (!isValidValue(_value)) {
        return '';
      } else if (_value.toString().endsWith('.')) {
        return ConversionUtils.fromCentsToDollars(_value).toString() + '.';
      } else {
        return ConversionUtils.fromCentsToDollars(_value);
      }
    }, [_value]);

    const [inputValue, setInputValue] = useState<string | number>(value);
    useEffect(() => setInputValue(value), [value]);

    props;
    return (
      <_AmountInput
        {...props}
        value={inputValue}
        onChange={useCallback<NonNullable<typeof onChange>>((e) => {
          let value: number | string = e.target.value;
          setInputValue(value);
          if (value) {
            value = ConversionUtils.fromDollarsToCents(value);
          }
          onChange?.({
            ...e,
            target: { ...e.target, value: value === '' ? '' : (Number(value) as never) },
          });
        }, [])} // eslint-disable-line react-hooks/exhaustive-deps
        ref={ref as never}
      />
    );
  })
);
