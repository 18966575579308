import { useCreateMutation, useModelApi, UseModelApiProps } from '@melio/api-client';
import { InternationalApiClient, PostInternationalQuoteRequest } from '@melio/platform-api-axios-client';

const postQuote = (vendorId: string, foreignCurrency: string, foreignAmount: number) =>
  InternationalApiClient.postInternationalQuote({ vendorId, foreignCurrency, foreignAmount });

const postQuotes = (quotes: UseIQuoteProps[]) => InternationalApiClient.postInternationalQuotes({ quotes });

export type UseIQuoteProps = UseModelApiProps<typeof postQuote> & PostInternationalQuoteRequest;

export const useInternationalQuote = (props: UseIQuoteProps) =>
  useModelApi({
    ...props,
    staleTime: 0,
    id: `QuoteApi-${props.vendorId}`,
    queryKey: ['InternationalApi', props.vendorId],
    queryFn: () => postQuote(props.vendorId, props.foreignCurrency, props.foreignAmount),
  });

export const useInternationalQuotes = () =>
  useCreateMutation(postQuotes, ['InternationalApi', 'collection', 'default']);
