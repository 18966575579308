import { forwardRef, HTMLTag } from '@melio/platform-utils';
import React, { ComponentType, isValidElement, ReactElement } from 'react';

import { SystemMessageProvider } from './system-message';
import { HOCReturnType } from './types';

export function withSystemMessageProvider<T, TTag extends HTMLTag = 'div'>(
  UI: ComponentType<T>
): HOCReturnType<T, TTag>;
export function withSystemMessageProvider(UI: ReactElement): ReactElement;

export function withSystemMessageProvider<T, TTag extends HTMLTag = 'div'>(UI: ComponentType<T> | ReactElement) {
  if (isValidElement(UI)) {
    return addSystemMessageProvider(UI);
  }
  return forwardRef<T, TTag>((props, ref) => addSystemMessageProvider(<UI {...props} ref={ref} />));
}

function addSystemMessageProvider(UI: ReactElement): ReactElement {
  return <SystemMessageProvider>{UI}</SystemMessageProvider>;
}
