import { useRoleText } from '@melio/ap-domain';
import { Group, ListItem } from '@melio/penny';
import { ApprovalDecision, PostApprovalDecisionEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import _groupBy from 'lodash/groupBy';
import { Fragment } from 'react';

import { SectionBlock } from './SectionBlock';

type Props = { approvalDecisions: ApprovalDecision[] };
export const PaymentApprovalDecisionSection = ({ approvalDecisions }: Props) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { getRoleText } = useRoleText();

  const renderDecisonBySection = (approvalDecision: ApprovalDecision) => {
    const { user, history, reason, decision } = approvalDecision;

    return (
      <Fragment key={history.updatedAt.getTime()}>
        <Group variant="vertical" spacing="xxs">
          <SectionBlock.Body
            label={formatMessage('widgets.paymentDetails.scheduledBy.name', {
              firstName: user.firstName,
              lastName: user.lastName,
            })}
            badgeProps={{ label: getRoleText(user.role), type: 'secondary', status: 'neutral' }}
          />

          <SectionBlock.Description
            label={formatMessage('widgets.paymentDetails.approvalDecision.subtitle', {
              date: formatDate(history.updatedAt, { dateStyle: 'medium' }),
            })}
          />

          {decision === PostApprovalDecisionEnum.Declined && reason && (
            <ListItem
              labelProps={{ label: formatMessage('widgets.paymentDetails.approvalDecision.note.declined') }}
              mainLabelProps={{ label: reason }}
            />
          )}
        </Group>
      </Fragment>
    );
  };

  const renderApprovalDecisionTypeSection = (decisionsByType: ApprovalDecision[], type: PostApprovalDecisionEnum) =>
    decisionsByType.length > 0 && (
      <Group variant="vertical" spacing="xxs" data-testid={`payment-approval-decision-section-${type}`}>
        <Group.Item>
          <SectionBlock.Title
            label={
              type === PostApprovalDecisionEnum.Approved
                ? formatMessage('widgets.paymentDetails.approvalDecision.title.approved')
                : formatMessage('widgets.paymentDetails.approvalDecision.title.declined')
            }
          />
        </Group.Item>

        <Group.Item>
          <Group variant="vertical" spacing="s">
            {decisionsByType.map(renderDecisonBySection)}
          </Group>
        </Group.Item>
      </Group>
    );

  const groupedByDecision = _groupBy(approvalDecisions, 'decision');
  const approvedDecisions = groupedByDecision[PostApprovalDecisionEnum.Approved] ?? [];
  const declinedDecisions = groupedByDecision[PostApprovalDecisionEnum.Declined] ?? [];

  return (
    <Group variant="vertical" spacing="s" data-testid="payment-approval-decision-section">
      {renderApprovalDecisionTypeSection(approvedDecisions, PostApprovalDecisionEnum.Approved)}
      {renderApprovalDecisionTypeSection(declinedDecisions, PostApprovalDecisionEnum.Declined)}
    </Group>
  );
};
