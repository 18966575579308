import { Group, LoadingContainer } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { FundingSource, useFundingSources } from '@melio/platform-api';
import { FundingSourceGroupList } from '@melio/subscriptions/src/ui';
import { groupBy } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { createSearchParams, useLocation } from 'react-router-dom';

import { PCIComplienceInfo } from '../../components/PCIComplienceInfo/PCIComplienceInfo.widget';
import { useAccountantsRoutes } from '../../utils/useAccountantsRoutes';

export type FundingSourceSelectionProps = {
  fundingSourceId: string | null;
  setFundingSourceId: (id: string) => void;
};

export const FundingSourceSelection = withAnalyticsContext<FundingSourceSelectionProps>(
  ({ fundingSourceId, setFundingSourceId }) => {
    const { goToAddBankAccount, goToAddCard } = useAccountantsRoutes();
    const { track } = useAnalytics();
    const location = useLocation();

    const { data: fundingSources, isFetching: isFetchingFundingSources } = useFundingSources();
    const fundingSourcesGroupedByType = useMemo(() => groupBy(fundingSources, 'type'), [fundingSources]);

    const handleSelectFundingSource = useCallback(
      (fundingSource: FundingSource) => {
        track('PaymentMethod', 'Click', {
          FundingSourceId: fundingSource.id,
          Cta: 'continue',
        });
        setFundingSourceId(fundingSource.id);
      },
      [track, setFundingSourceId]
    );

    const fundingSource = useMemo(
      () => fundingSources?.find((x) => x.id === fundingSourceId) || fundingSources?.[0],
      [fundingSourceId, fundingSources]
    );

    useEffect(() => {
      if (fundingSource) {
        handleSelectFundingSource(fundingSource);
      }
    }, [fundingSource, handleSelectFundingSource]);

    const navigateToAddBankAccount = () => {
      track('PaymentMethod', 'Click', {
        Intent: 'add-pfsm-for-fees',
        FundingSourceId: fundingSource?.id,
        Cta: fundingSourcesGroupedByType['bank-account']?.length ? 'add-another-bank-account' : 'add-bank-account',
      });

      const searchParams = createSearchParams({
        ...(fundingSourceId && { fundingSourceId }),
      });
      const searchParamsString = searchParams.toString();
      const returnUrl = searchParamsString ? `${location.pathname}?${searchParamsString}` : location.pathname;

      return goToAddBankAccount({ returnUrl });
    };

    const navigateToAddCard = () => {
      track('PaymentMethod', 'Click', {
        Intent: 'add-pfsm-for-fees',
        FundingSourceId: fundingSource?.id,
        Cta: fundingSourcesGroupedByType['card']?.length ? 'add-another-card' : 'add-card',
      });

      const searchParams = createSearchParams({
        ...(fundingSourceId && { fundingSourceId }),
      });
      const searchParamsString = searchParams.toString();
      const returnUrl = searchParamsString ? `${location.pathname}?${searchParamsString}` : location.pathname;

      return goToAddCard({ returnUrl });
    };

    if (isFetchingFundingSources) {
      return <LoadingContainer isLoading={isFetchingFundingSources} />;
    }

    return (
      <Group variant="vertical" width="full" spacing="xl">
        <FundingSourceGroupList
          fundingSourcesGroupedByType={fundingSourcesGroupedByType}
          selectedId={fundingSourceId}
          onSelected={handleSelectFundingSource}
          onAddBank={navigateToAddBankAccount}
          onAddCard={navigateToAddCard}
        />
        <PCIComplienceInfo />
      </Group>
    );
  }
);
