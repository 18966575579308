import {
  PromoCode,
  PromoCodeUpToPeriodsUpToPeriodsTypeEnum,
  SubscriptionBillingCycleEnum,
} from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';

export type PromoCodeUpToPeriodsProps = {
  currentCycle: SubscriptionBillingCycleEnum;
  promoCode?: PromoCode;
};

export const usePromoCodeUpToPeriodsTitle = ({ currentCycle, promoCode }: PromoCodeUpToPeriodsProps) => {
  const { formatMessage } = useMelioIntl();

  const upToPeriods = promoCode?.upToPeriods;
  const upToPeriodsAmount = upToPeriods?.upToPeriodsAmount;
  const discountPercentage = promoCode?.discountPercentage;
  let upToPeriodsType: PromoCodeUpToPeriodsUpToPeriodsTypeEnum | null = null;

  if (!upToPeriodsAmount || !upToPeriods?.upToPeriodsType || !discountPercentage) {
    return null;
  }

  switch (upToPeriods.upToPeriodsType) {
    case 'billingPeriods': {
      upToPeriodsType = currentCycle === SubscriptionBillingCycleEnum.Annual ? 'years' : 'months';
      break;
    }
    case 'days':
      upToPeriodsType = 'days';
      break;
    case 'weeks':
      upToPeriodsType = 'weeks';
      break;
    case 'months':
      upToPeriodsType = 'months';
      break;
    case 'years':
      upToPeriodsType = 'years';
      break;
    default:
      return null;
  }

  const title = formatMessage(
    `activities.subscription.checkout.summary.promocode.subTitle.expiration.${upToPeriodsType}`,
    {
      promoCodeDiscountPercentage: discountPercentage,
      upToPeriods: upToPeriodsAmount,
    }
  );

  return title;
};
