import { Box } from '@chakra-ui/react';
import { Grid, Group, Icon, Pill, Text, Tooltip } from '@melio/penny';
import { BillingFeeSetting } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { SchedulePaymentIntent } from '../../../../types';
import { getBalanceSum, getFeesSum, useAmountHeaderFees, useFreeChecks, useGetFeesTooltip } from './AmountHeader.utils';
import { FeesBreakdownByType } from './FeesBreakdownByType';

export type AmountHeaderProps = {
  paymentIntentsWithDerivatives: SchedulePaymentIntent[];
  orgBillingFeeSettings: BillingFeeSetting[];
};

export const AmountHeader = forwardRef<AmountHeaderProps, 'div'>(
  ({ orgBillingFeeSettings, paymentIntentsWithDerivatives, ...props }, ref) => {
    const { formatCurrency } = useMelioIntl();
    const [shouldUseFeesBreakdownApi] = useDevFeature(FeatureFlags.CheckFeesPromotionEnabled, false);

    const balanceSum = getBalanceSum(paymentIntentsWithDerivatives);

    return (
      <Group variant="vertical" alignItems="flex-end" spacing="xs" data-component="AmountHeader" {...props} ref={ref}>
        <Text textStyle="heading1Semi">{formatCurrency(balanceSum)}</Text>
        {shouldUseFeesBreakdownApi ? (
          <FeesBreakdown
            orgBillingFeeSettings={orgBillingFeeSettings}
            paymentIntentsWithDerivatives={paymentIntentsWithDerivatives}
          />
        ) : (
          <PaymentsFees paymentIntentsWithDerivatives={paymentIntentsWithDerivatives} />
        )}
      </Group>
    );
  }
);

const PaymentsFees = ({
  paymentIntentsWithDerivatives,
}: {
  paymentIntentsWithDerivatives: SchedulePaymentIntent[];
}) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  const totalFees = getFeesSum(paymentIntentsWithDerivatives) || 0;

  return (
    <Grid
      gap="xxs"
      gridTemplateColumns="auto auto"
      justifyItems="flex-end"
      color={totalFees ? 'global.neutral.900' : 'global.neutral.400'}
    >
      <Text color="inherit" textStyle="body4Semi">
        {formatMessage('activities.batchPayments.screens.paymentIntentsTable.amountHeader.totalFees.label', {
          amount: totalFees,
          formattedAmount: formatCurrency(totalFees),
        })}
      </Text>
      {totalFees ? (
        <Tooltip
          content={formatMessage('activities.batchPayments.screens.paymentIntentsTable.amountHeader.totalFees.tooltip')}
          shouldAddTriggerFocus
        >
          <Icon type="info" size="small" color="inherit" data-testid="batch-payments-total-fees-tooltip-icon" />
        </Tooltip>
      ) : undefined}
    </Grid>
  );
};

const FeesBreakdown = ({
  paymentIntentsWithDerivatives,
  orgBillingFeeSettings,
}: {
  paymentIntentsWithDerivatives: SchedulePaymentIntent[];
  orgBillingFeeSettings: BillingFeeSetting[];
}) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const {
    settings: { batchPayments },
  } = useConfig();

  const { feesBreakdown } = useAmountHeaderFees(paymentIntentsWithDerivatives);
  const { freeChecksToApply } = useFreeChecks(paymentIntentsWithDerivatives);

  const totalFees = feesBreakdown?.feesToPay || 0;
  const shouldShowFees = totalFees > 0 || batchPayments.showZeroFees;
  const tooltip = useGetFeesTooltip({ availableFreeChecks: freeChecksToApply, feesBreakdown, orgBillingFeeSettings });

  return (
    <Group variant="vertical" spacing="xs" alignItems="flex-end">
      {freeChecksToApply ? (
        <Pill
          status="informative"
          data-testid="batch-payments-amount-header-free-checks-badge"
          label={formatMessage(
            'activities.batchPayments.screens.paymentIntentsTable.amountHeader.freeCheckBadge.label',
            {
              amount: freeChecksToApply,
            }
          )}
        />
      ) : null}
      {batchPayments.showFeesBreakdown && <FeesBreakdownByType feesBreakdown={feesBreakdown} />}
      {!batchPayments.showFeesBreakdown && (
        <Grid
          gap="xxs"
          gridTemplateColumns="auto auto"
          justifyItems="flex-end"
          data-testid="batch-payments-amount-header-total-fees"
        >
          {shouldShowFees && (
            <Text textStyle="body4Semi" color={totalFees ? 'global.neutral.900' : 'global.neutral.400'}>
              {formatMessage('activities.batchPayments.screens.paymentIntentsTable.amountHeader.totalFees.label', {
                amount: totalFees,
                formattedAmount: formatCurrency(totalFees),
              })}
            </Text>
          )}
          {totalFees || feesBreakdown?.discounts ? (
            <Tooltip content={tooltip} data-testid="batch-payments-amount-header-total-fees-tooltip">
              <Box
                as={Icon}
                type="info"
                size="small"
                color="inherit"
                tabIndex={0}
                data-testid="batch-payments-amount-header-total-fees-tooltip-trigger"
              />
            </Tooltip>
          ) : undefined}
        </Grid>
      )}
    </Group>
  );
};

AmountHeader.displayName = 'AmountHeader';
