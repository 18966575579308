import { CheckAccountFormModel, VendorCheckDetailsPayorForm } from '@melio/ap-widgets';
import { Group, SectionBanner, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef, SystemMessageDisplay } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { DeliveryMethodFlowOrigin } from '../../../types';

export type AddVendorCheckDetailsScreenProps = {
  isLoading?: boolean;
  isSaving?: boolean;
  defaultValues?: Partial<CheckAccountFormModel>;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (
    data: CheckAccountFormModel,
    event?: React.MouseEvent<HTMLButtonElement>,
    target?: 'continue' | 'close'
  ) => void;
  origin: DeliveryMethodFlowOrigin;
};

export const AddVendorCheckDetailsScreen = forwardRef<AddVendorCheckDetailsScreenProps, 'div'>(
  ({ isLoading, isSaving, defaultValues, onBack, onClose, onDone, origin, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { line1, line2, city, postalCode, printName, state } = defaultValues ?? {};
    const showPreFilledBanner = !!(line1 || line2 || city || postalCode || state);
    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps, handleSubmit } =
      useFormSubmissionController<CheckAccountFormModel>();

    useAnalyticsView('AddVendorCheckDetails');

    return (
      <NewSinglePaymentStepLayout
        isLoading={isLoading}
        data-component="AddVendorCheckDetailsActivity.AddVendorCheckDetailsScreen"
        data-testid="add-vendor-check-details-activity-add-vendor-check-details-screen"
        {...props}
        ref={ref}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
            <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
          </NewSinglePaymentStepLayout.Header>
        }
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            {origin === 'vendor' ? (
              <Group variant="vertical" spacing="m" width="full">
                <NewSinglePaymentStepLayout.NextButton
                  {...submitButtonProps}
                  onClick={handleSubmit?.('continue')}
                  label={formatMessage(
                    'activities.addVendorCheckDetails.screens.addVendorCheckDetails.actions.vendorOrigin.continue'
                  )}
                />
                <NewSinglePaymentStepLayout.SecondaryNextButton
                  {...submitButtonProps}
                  onClick={handleSubmit?.('close')}
                  variant="tertiary"
                  label={formatMessage(
                    'activities.addVendorCheckDetails.screens.addVendorCheckDetails.actions.vendorOrigin.close'
                  )}
                />
              </Group>
            ) : (
              <NewSinglePaymentStepLayout.NextButton
                {...submitButtonProps}
                onClick={handleSubmit?.('continue')}
                label={formatMessage(
                  'activities.addVendorCheckDetails.screens.addVendorCheckDetails.actions.paymentOrigin.continue'
                )}
              />
            )}
          </NewSinglePaymentStepLayout.Actions>
        }
      >
        {showPreFilledBanner ? (
          <SectionBanner
            variant="informative"
            description={formatMessage('activities.addVendorCheckDetails.screens.addVendorCheckDetails.sectionBanner')}
          />
        ) : null}
        <NewSinglePaymentStepLayout.Title>
          {formatMessage('activities.addVendorCheckDetails.screens.addVendorCheckDetails.title')}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Content>
          <Group variant="vertical">
            <SystemMessageDisplay data-testid="add-vendor-check-details-activity-system-message-display" />
            <VendorCheckDetailsPayorForm
              onSubmit={onDone}
              isSaving={isSaving}
              defaultValues={{ line1, line2, city, postalCode, printName, state }}
              onSubmissionStateChange={onSubmissionStateChange}
            />
          </Group>
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

AddVendorCheckDetailsScreen.displayName = 'AddVendorCheckDetailsActivity.AddVendorCheckDetailsScreen';
