import { InvoiceDiscountsApiClient } from '@melio/ar-api-axios-client';

import { useCollection, UseCollectionProps } from '../../api-client';

export type UseInvoiceDiscountsProps = UseCollectionProps<
  typeof InvoiceDiscountsApiClient.getInvoiceDiscounts,
  typeof InvoiceDiscountsApiClient.postInvoiceDiscount,
  typeof InvoiceDiscountsApiClient.updateInvoiceDiscountById
>;

export const useInvoiceDiscounts = (props: UseInvoiceDiscountsProps = {}) =>
  useCollection({
    ...props,
    queryKey: 'InvoiceDiscountsApi',
    queryFn: InvoiceDiscountsApiClient.getInvoiceDiscounts,
    createFn: InvoiceDiscountsApiClient.postInvoiceDiscount,
    deleteFn: InvoiceDiscountsApiClient.deleteInvoiceDiscountById,
    updateFn: InvoiceDiscountsApiClient.updateInvoiceDiscountById,
    populateModels: true,
  });

export type InvoiceDiscountCollection = ReturnType<typeof useInvoiceDiscounts>;
