import { CalculatedField, InvoiceServiceCharge, InvoiceTimeline } from '@melio/ar-domain';

export const getMappedServiceCharges = ({
  summaryInvoiceServiceCharges,
  invoiceServiceCharges,
}: {
  summaryInvoiceServiceCharges: Array<CalculatedField>;
  invoiceServiceCharges: Array<InvoiceServiceCharge>;
}) =>
  invoiceServiceCharges.map((charge) => {
    const matchingSummaryCharge = summaryInvoiceServiceCharges.find((summaryCharge) => charge.id === summaryCharge.id);

    return {
      name: charge.name,
      id: charge.id,
      rate: charge.type === 'percentage' ? charge.value : undefined,
      calculateValue: matchingSummaryCharge?.calculateValue || 0,
    };
  });

export const getLastSentEmails = (invoiceTimeline?: InvoiceTimeline) => {
  const sortedEmails = invoiceTimeline?.emails.sort((a, b) => b.sentAt.getTime() - a.sentAt.getTime());

  return {
    lastSentEmail: sortedEmails?.[0],
    lastSentReminder: sortedEmails?.find((email) => email.type.startsWith('ar-invoice-reminder')),
  };
};
