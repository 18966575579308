import { Bill, DeliveryMethod, FundingSource, PaymentFee, usePayments, Vendor } from '@melio/platform-api';
import { useState } from 'react';

import { usePaymentConfirmationNumber } from '../../../utils/pay-flow/usePaymentConfirmationNumber';
import { PaymentScheduledNotifyVendorModalActivity } from '../../PaymentScheduledNotifyVendor';
import { PaymentFlowDoneAction } from '../../types';
import { getVendorsIdsWithoutEmail } from '../PaymentScheduled.activity.utils';
import { PaymentScheduledLoadingScreen } from '../screens/PaymentsScheduled';
import { PaymentsScheduledSuccessfullyScreen } from './screens';
import { PaymentDetails } from './screens/PaymentsScheduled/types';
import { PaymentScheduledSuccessfullyActivityProps } from './types';

export const PaymentScheduledSuccessfullyActivity = ({
  onClose,
  onError,
  onDone,
  paymentsIds,
  externalOrigin,
  isEditPaymentFlow,
  failedPaymentsSchedulingCount,
  updatedFields,
}: PaymentScheduledSuccessfullyActivityProps) => {
  const paymentsCollection = usePayments({
    params: {
      search: { 'payment.id': paymentsIds },
      expand: [
        'vendor',
        'bills',
        'fees',
        'deliveryMethod',
        'fundingSource',
        'subscriptionOccurrence',
        'subscriptionOccurrence.billSubscription',
      ],
    },
  });

  const [isNotifyVendorModalOpen, setIsNotifyVendorModalOpen] = useState<boolean>(false);
  const { getConfirmationNumber } = usePaymentConfirmationNumber();

  if (!paymentsCollection.data) {
    return <PaymentScheduledLoadingScreen onClose={onClose} />;
  }

  const paymentsDetails: PaymentDetails[] = paymentsCollection.data.map((payment) => ({
    id: payment.id,
    scheduledDate: payment.scheduledDate,
    deliveryDate: payment.estimatedDelivery,
    maxDeliveryDate: payment.maxEstimatedDelivery,
    paymentAmount: payment.amount,
    vendor: payment.vendor as Vendor,
    deliveryPreferenceType: payment.deliveryPreferenceType,
    approvalDecisionStatus: payment.approvalDecisionStatus,
    bills: payment.bills as Bill[],
    fundingSource: payment.fundingSource as FundingSource,
    deliveryMethod: payment.deliveryMethod as DeliveryMethod,
    fees: payment.fees as PaymentFee[],
    subscriptionOccurrence: payment.subscriptionOccurrence,
    updatedFields,
    foreignAmount: payment.foreignAmount,
    note: payment.note,
    userNote: payment.userNote,
    confirmationNumber: getConfirmationNumber(payment),
    currency: payment.currency,
  }));

  const vendorsIdsWithoutEmail = getVendorsIdsWithoutEmail(paymentsDetails);

  return (
    <>
      <PaymentsScheduledSuccessfullyScreen
        onClose={() => onDone('goToDashboard')}
        paymentsDetails={paymentsDetails}
        failedPaymentsSchedulingCount={failedPaymentsSchedulingCount}
        vendorsIdsWithoutEmail={vendorsIdsWithoutEmail}
        onNotifyVendors={() => setIsNotifyVendorModalOpen(true)}
        onDone={(action: PaymentFlowDoneAction) => onDone(action)}
        externalOrigin={externalOrigin}
        isEditPaymentFlow={isEditPaymentFlow ?? false}
        updatedFields={updatedFields}
      />
      <PaymentScheduledNotifyVendorModalActivity
        onError={onError}
        isOpen={isNotifyVendorModalOpen}
        onClose={() => setIsNotifyVendorModalOpen(false)}
        onDone={() => {
          setIsNotifyVendorModalOpen(false);
          onDone('goToDashboard');
        }}
        vendorsIds={vendorsIdsWithoutEmail}
      />
    </>
  );
};
