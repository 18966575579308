import {
  InboxItemBillTypeEnum,
  InboxItemPaymentRequestTypeEnum,
  InboxItemScannedInvoiceTypeEnum,
  useInboxItems,
} from '@melio/platform-api';

import { ExpandedSortingState } from '../../types';
import { usePayDashboardPagination } from '../Pagination';
import { BillsTabItem } from './types';
import { convertBillsTabSortToApiSort } from './utils';

export const useBillsTabItems = ({
  enabled,
  searchTerm,
  scope,
  sort,
}: {
  enabled: boolean;
  searchTerm?: string | null;
  scope?: string;
  sort: ExpandedSortingState | null;
}) => {
  const { pageSize } = usePayDashboardPagination();

  return useInboxItems<BillsTabItem>({
    enabled,
    cacheTime: 0,
    staleTime: 0,
    scope,
    params: {
      limit: pageSize,
      sort: sort ? convertBillsTabSortToApiSort(sort) : undefined,
      inboxItemTypes: [
        InboxItemBillTypeEnum.Bill,
        InboxItemPaymentRequestTypeEnum.PaymentRequest,
        InboxItemScannedInvoiceTypeEnum.ScannedInvoice,
      ],
      ...(searchTerm ? { searchTerm } : {}),
    },
  });
};
