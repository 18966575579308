import { TabItem } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { compact } from 'lodash';

import { usePayDashboardPagination } from '../components/Pagination';
import { PayDashboardTabs } from '../types';
import { usePaymentsTabNewFailedPayments } from './useGetNewFailedPayments';
import { useNewApprovalsTabItems } from './useNewApprovalsTabItems';
import { useNewBillsTabItems } from './useNewBillsTabItems';
import { useFinancingTabNewFailedLoans, useNewFinancingTabItems } from './useNewFinancingTabItems';

export type Indication = {
  counters: {
    [key in PayDashboardTabs]?: TabItem['pills'];
  };
  badges: {
    [key in PayDashboardTabs]?: TabItem['pills'];
  };
};

export const usePayDashboardTabIndication = (tab: string | undefined): Indication => {
  const { formatMessage } = useMelioIntl();

  const { newPayments: newFailedPayments } = usePaymentsTabNewFailedPayments({
    limit: 1,
  });
  const { newPayments: newFailedLoans } = useFinancingTabNewFailedLoans();
  const { newBillsTabItems } = useNewBillsTabItems();
  const { newPayments: newLoans } = useNewFinancingTabItems();
  const { newPayments: newApprovalsTabItems } = useNewApprovalsTabItems();

  const { pageSize } = usePayDashboardPagination();

  const getBadgeAmount = (amount: number) => (amount >= pageSize ? `${pageSize}+` : `${amount}`);

  // bills
  const newBillsNumber = getBadgeAmount(newBillsTabItems.length);
  const newBillsLabel = formatMessage('activities.payDashboard.billsTab.newBillsIndication', {
    newBillsNumber,
  });
  const billsTabCounters: TabItem['pills'] =
    newBillsTabItems.length > 0 && tab !== PayDashboardTabs.Bills
      ? [{ status: 'brand', label: newBillsNumber, 'aria-label': newBillsLabel }]
      : [];
  const billsTabBadges: TabItem['pills'] =
    newBillsTabItems.length > 0 && tab !== PayDashboardTabs.Bills ? [{ label: newBillsLabel, status: 'brand' }] : [];

  //payments
  const newFailedPaymentsNumber = getBadgeAmount(newFailedPayments.length);
  const newFailedPaymentsLabel = formatMessage('activities.payDashboard.paymentsTab.failedIndication', {
    num: newFailedPaymentsNumber,
  });
  const paymentsTabCounter: TabItem['pills'] =
    newFailedPayments.length > 0
      ? [{ status: 'critical', label: newFailedPaymentsNumber, 'aria-label': newFailedPaymentsLabel }]
      : [];
  const paymentsTabBadges: TabItem['pills'] =
    newFailedPayments.length > 0 ? [{ label: newFailedPaymentsLabel, status: 'critical' }] : [];

  //financing
  const newFailedLoansNumber = getBadgeAmount(newFailedLoans.length);
  const newLoansNumber = getBadgeAmount(newLoans.length);
  const newFailedLoansLabel = formatMessage('activities.payDashboard.financingTab.failedIndication', {
    num: newFailedLoansNumber,
  });
  const newLoansLabel = formatMessage('activities.payDashboard.financingTab.newLoansIndication', {
    num: newLoansNumber,
  });
  const financingTabCounter: TabItem['pills'] = compact([
    newFailedLoans.length > 0
      ? { status: 'critical', label: newFailedLoansNumber, 'aria-label': newFailedLoansLabel }
      : undefined,
    newLoans.length > 0 ? { status: 'brand', label: newLoansNumber, 'aria-label': newLoansLabel } : undefined,
  ]);
  const financingTabBadges: TabItem['pills'] = compact([
    newFailedLoans.length > 0 ? { label: newFailedLoansLabel, status: 'critical' } : undefined,
    newLoans.length > 0 ? { label: newLoansLabel, status: 'brand' } : undefined,
  ]);

  //approvals
  const newApprovalsNumber = getBadgeAmount(newApprovalsTabItems.length);
  const newApprovalsLabel = formatMessage('activities.payDashboard.approvalsTab.newPendingApprovalIndication', {
    newPendingApprovalNumber: newApprovalsNumber,
  });
  const approvalTabCounter: TabItem['pills'] =
    newApprovalsTabItems.length > 0 && tab !== PayDashboardTabs.Approvals
      ? [{ status: 'brand', label: newApprovalsNumber, 'aria-label': newApprovalsLabel }]
      : [];
  const approvalsTabBadges: TabItem['pills'] =
    newApprovalsTabItems.length > 0 && tab !== PayDashboardTabs.Approvals
      ? [{ label: newApprovalsLabel, status: 'brand' }]
      : [];

  return {
    counters: {
      [PayDashboardTabs.Bills]: billsTabCounters,
      [PayDashboardTabs.Payments]: paymentsTabCounter,
      [PayDashboardTabs.Financing]: financingTabCounter,
      [PayDashboardTabs.Approvals]: approvalTabCounter,
    },
    badges: {
      [PayDashboardTabs.Bills]: billsTabBadges,
      [PayDashboardTabs.Payments]: paymentsTabBadges,
      [PayDashboardTabs.Financing]: financingTabBadges,
      [PayDashboardTabs.Approvals]: approvalsTabBadges,
    },
  };
};
