import { useMelioQueryClient, useNonEntityMutation } from '@melio/api-client';
import { PayorApiClient } from '@melio/ar-api-axios-client';

import { PromiseFunctionReturnType, useModel, UseModelProps } from '../../api-client';
import { PostPayorOnboardingRequest } from './types';

const postPayorOnboarding = (paymentRequestLink: string) => PayorApiClient.postPayorOnboarding({ paymentRequestLink });

export type UseGuestPayorOnboardingProps = UseModelProps<typeof postPayorOnboarding> & {
  email?: string;
};

export const useGuestPayorOnboarding = ({ email, enabled = true, ...props }: UseGuestPayorOnboardingProps = {}) => {
  const queryClient = useMelioQueryClient();

  const query = useModel({
    ...props,
    enabled: enabled && !!email,
    queryKey: ['PayorApi', 'postPayorOnboarding', email],
    queryFn: postPayorOnboarding,
  });

  type MutationFn = typeof PayorApiClient.postPayorOnboarding;

  const onboarding = useNonEntityMutation<
    MutationFn,
    PostPayorOnboardingRequest,
    PromiseFunctionReturnType<MutationFn>
  >(PayorApiClient.postPayorOnboarding, query.queryKey, {
    onSuccess: (data) => void queryClient.setQueryData(query.queryKey, data),
  });

  return {
    ...query,
    onboarding: onboarding.mutateAsync,
  };
};
