import {
  _createFormFieldInput,
  BrandSymbol,
  BrandSymbolKey,
  brandSymbolsMap,
  FormSelectNewOption,
  Group,
  Icon,
  IconKey,
  Image,
  Text,
} from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useMemo } from 'react';

import { FundingSource } from '../api-hooks';
import { _SelectInput, SelectInputProps } from './Select.input';

export type RenderOptionType = FormSelectNewOption<string>;
export type MultiLineOptionType = RenderOptionType & { description: string };

export const getFundingSourceIconType = (fundingSource: FundingSource): IconKey | BrandSymbolKey => {
  const { type: fundingSourceType } = fundingSource;
  if (fundingSourceType === 'bank-account') return 'bank';
  if (fundingSourceType === 'flex-account') return 'flex';
  const { network, type: cardType } = fundingSource.details;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (network) {
    const supportedCardCompaniesTypes: BrandSymbolKey[] = ['visa', 'amex-logo', 'mastercard', 'discover'];
    return supportedCardCompaniesTypes.find((item) => network.includes(item)) || 'credit-card';
  }
  return cardType == 'debit' ? 'debit-card' : 'credit-card';
};

const getFundingSourceIconOrImage = (fundingSource: FundingSource) => {
  const imageProps = getFundingSourceImageProps(fundingSource);
  if (imageProps) {
    return <Image width={8} height={8} {...imageProps} />;
  }

  const iconType = getFundingSourceIconType(fundingSource);

  const isBrandSymbol = Object.keys(brandSymbolsMap).includes(iconType as BrandSymbolKey);

  return isBrandSymbol ? (
    <BrandSymbol type={iconType as BrandSymbolKey} />
  ) : (
    <Icon type={iconType as IconKey} color="inherit" aria-hidden="true" />
  );
};

const verifyValidBase64Logo = (logo: string) => {
  const splitRes = logo.split(',');

  return splitRes.length === 2 && splitRes[1] !== 'default';
};

const getFundingSourceImageProps = (fundingSource: FundingSource) => {
  if (fundingSource.type === 'bank-account' && fundingSource.logo && verifyValidBase64Logo(fundingSource.logo)) {
    return { src: fundingSource.logo, alt: 'Account provider logo' };
  }
  return undefined;
};

const generateFundingSourcesIcons = (fundingSources: FundingSource[]) =>
  fundingSources.map((fs) => ({
    id: fs.id,
    icon: getFundingSourceIconOrImage(fs),
  }));

export type BankAccountSelectInputProps = Omit<SelectInputProps, 'options'> & {
  options: Array<FundingSource>;
};

export const BankAccountSelectInput = _createFormFieldInput(
  forwardRef<BankAccountSelectInputProps, 'input'>(({ options: fundingSources, ...props }, ref) => {
    const generatedIcons = useMemo(() => generateFundingSourcesIcons(fundingSources), [fundingSources]);
    const getIcon = (option: RenderOptionType) => generatedIcons.find((iconsFs) => option.value === iconsFs.id)?.icon;

    const MultiLineOption = ({ option }: { option: RenderOptionType }) => (
      <Group alignItems="center">
        {getIcon(option)}
        <Group variant="vertical" alignItems="flex-start" spacing="none">
          <Text textStyle="body3Semi" shouldSupportEllipsis color="inherit">
            {option.label}
          </Text>
        </Group>
      </Group>
    );

    const valueRenderer = (option: RenderOptionType) => <MultiLineOption option={option} />;

    const optionRenderer = (option: RenderOptionType) => <MultiLineOption option={option} />;

    const options = fundingSources.map((fs) => ({
      value: fs.id,
      label: fs.displayName,
    }));

    return (
      <_SelectInput
        {...props}
        options={options}
        optionRenderer={optionRenderer}
        shouldHideClearButton
        valueRenderer={valueRenderer}
        ref={ref}
      />
    );
  })
);

BankAccountSelectInput.displayName = 'BankAccountSelectInput';
