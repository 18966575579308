import { FundingSource, useMelioIntl } from '@melio/ar-domain';
import { Group, useFormSubmissionController } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { Footer, OnboardingLayout } from '../../../layouts';
import { TrackerStepData } from '../../../utils';
import {
  ConfirmReceivingAccountDescription,
  ConfirmReceivingAccountHeader,
  ReceivingAndDebitAccountForm,
} from '../components';
import { ReceivingAndDebitAccountFormValues } from '../types';

export type ConfirmReceivingAccountScreenProps = {
  isSaving: boolean;
  onSubmit: (data: Omit<ReceivingAndDebitAccountFormValues, 'isReceivingAccountEqualDebitAccount'>) => void;
  onBack: VoidFunction;
  onClose: VoidFunction;
  steps: TrackerStepData[];
  currentStepIndex: number;
  isLoading: boolean;
  fundingSources: FundingSource[];
};

export const ConfirmReceivingAccountScreen = forwardRef<ConfirmReceivingAccountScreenProps>(
  ({ onSubmit, onClose, onBack, isSaving, fundingSources, isLoading, steps, currentStepIndex, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { onSubmissionStateChange, submitButtonProps } =
      useFormSubmissionController<ReceivingAndDebitAccountFormValues>();

    return (
      <OnboardingLayout
        {...props}
        screenId="receiving-account"
        data-component={ConfirmReceivingAccountScreen.displayName}
        headerContent={formatMessage('ar.onboarding.layout.header.label')}
        isLoading={isLoading}
        footerContent={
          <Footer
            primaryButtonProps={{
              label: formatMessage('ar.onboarding.layout.buttons.continue.label'),
              isLoading: isSaving,
              ...submitButtonProps,
            }}
            secondaryButtonProps={{
              label: formatMessage('ar.onboarding.layout.buttons.back.label'),
              onClick: onBack,
            }}
            tertiaryButtonProps={{
              label: formatMessage('ar.onboarding.layout.buttons.cancel.label'),
              onClick: onClose,
            }}
          />
        }
        ref={ref}
        isStickyFooter
      >
        <Group variant="vertical" spacing="l" width="full">
          <Group variant="vertical" spacing="xs">
            <ConfirmReceivingAccountHeader />
            <ConfirmReceivingAccountDescription />
          </Group>
          <ReceivingAndDebitAccountForm
            fundingSources={fundingSources}
            onSubmissionStateChange={onSubmissionStateChange}
            onSubmit={onSubmit}
          />
        </Group>
      </OnboardingLayout>
    );
  }
);

ConfirmReceivingAccountScreen.displayName = 'ConfirmReceivingAccountScreen';
