import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { usePaymentRequestStatus } from '../../hooks';
import { PaymentRequestDetailsLayout } from '../../layout';
import { PaymentRequestStatus } from '../../types';

export type PaymentRequestTriageActivityProps = {
  paymentRequestLink: string;
  onDone: (next: PaymentRequestStatus, paymentRequestLink: string) => unknown;
};

export const PaymentRequestTriageActivity = forwardRef<PaymentRequestTriageActivityProps>(
  ({ paymentRequestLink, onDone, ...props }, ref) => {
    const { isLoading, status, paymentRequestLink: link } = usePaymentRequestStatus(paymentRequestLink);

    useEffect(() => {
      if (!isLoading) onDone(status, link);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, status, link]);

    return (
      <PaymentRequestDetailsLayout
        data-component={PaymentRequestTriageActivity.displayName}
        isLoading
        onViewInvoice={() => null}
        {...props}
        ref={ref}
      />
    );
  }
);
PaymentRequestTriageActivity.displayName = 'PaymentRequestTriageActivity';
