import { LockedVendorIcon } from '@melio/ap-widgets';
import { Container, Group, Text, Typography } from '@melio/penny';
import { FxQuote, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePaymentsTabListItem } from '../../PayDashboard/components/PaymentsTab/components/usePaymentsTabListItem';
import { DebitDateTooltip, DeliveryDateTooltip, FxAmountTooltip } from './utils';

export const PendingPaymentListItem = ({
  payment,
  newDebitDate,
  newDeliveryDate,
  fxQuote,
}: {
  payment: Payment;
  newDebitDate?: Date;
  newDeliveryDate?: Date;
  fxQuote?: FxQuote;
}) => {
  const { formatCurrency, formatDate, formatMessage } = useMelioIntl();
  const { isVendorNotPermitted, formattedAmount, deliveryDate, vendorDescription } = usePaymentsTabListItem(payment);

  return (
    <Container data-testid={`pending-payment-list-item-${payment.id}`} overflow="initial" paddingX="m">
      <Group variant="horizontal" justifyContent="space-between">
        <Group variant="vertical" spacing="xxs">
          <Group variant="horizontal" spacing="xxs" alignItems="center">
            {isVendorNotPermitted ? <LockedVendorIcon /> : null}
            <Typography.MainLabel
              label={payment.vendor?.name ?? ''}
              variant="bold"
              aria-label={payment.vendor?.name ?? ''}
            />
          </Group>
          <Text shouldSupportEllipsis color="global.neutral.900" textStyle="body3">
            {vendorDescription}
          </Text>
          <Group variant="horizontal" spacing="xxs">
            <Text color="global.neutral.900" textStyle="body3">
              {formatMessage('activities.payDashboard.paymentsTab.listItem.debitDate', {
                debitDate: formatDate(payment.scheduledDate, { dateStyle: 'medium' }),
              })}
            </Text>
            <DebitDateTooltip payment={payment} debitDate={newDebitDate} />
          </Group>
          <Group variant="horizontal" spacing="xxs">
            {payment.estimatedDelivery && (
              <Text color="global.neutral.900" textStyle="body3">
                {deliveryDate}
              </Text>
            )}
            <DeliveryDateTooltip payment={payment} deliveryDate={newDeliveryDate} />
          </Group>
        </Group>
        <Group variant="vertical" spacing="xxs">
          <Text textStyle="body2Semi">{formattedAmount}</Text>
          {fxQuote ? (
            <Group variant="horizontal" spacing="xxs">
              <Text textStyle="body4" color="global.neutral.700">
                {formatCurrency(fxQuote.amount)}
              </Text>
              <FxAmountTooltip fxQuote={fxQuote} />
            </Group>
          ) : null}
        </Group>
      </Group>
    </Container>
  );
};
