import { useMelioIntl } from '@melio/ar-domain';
import { Form, GridItem, Group, Icon, NakedButton, SectionBanner, SectionBannerProps, Tooltip } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { useEffect, useRef } from 'react';

import { useInvoiceFormContext } from '../../../utils';
import { FormSection } from '../../FormSection';
import { PayOptionCard } from './PayOptionCard';

export type PayOptionsFormSectionProps = {
  sectionBannerProps?: SectionBannerProps;
  shouldExpandCustomPayOptions?: boolean;
};

export const PayOptionsFormSection = forwardRef<PayOptionsFormSectionProps>(
  ({ sectionBannerProps, shouldExpandCustomPayOptions: externalCustomExpanded, ...props }, ref) => {
    const { registerField, defaultValues } = useInvoiceFormContext();
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    const [internalCustomExpanded, expandCustom] = useBoolean(!!defaultValues?.customPayInstructions);
    const textArea = useRef<HTMLInputElement>(null);
    const shouldDisplayCustomExpanded = internalCustomExpanded || externalCustomExpanded;
    const isDisabled = Boolean(sectionBannerProps);

    useEffect(() => {
      if (shouldDisplayCustomExpanded && !defaultValues?.customPayInstructions) {
        textArea.current?.focus();
      }
    }, [shouldDisplayCustomExpanded, defaultValues?.customPayInstructions]);

    return (
      <FormSection
        title={formatMessage('ar.invoiceLifecycle.activities.createInvoice.form.sections.payOptions.label')}
        data-component={PayOptionsFormSection.displayName}
        data-testid="form-section-payment-options"
        {...props}
        ref={ref}
      >
        {sectionBannerProps && (
          <GridItem colSpan={[1, 1, 1, 2]}>
            <SectionBanner data-testid="pay-options-form-section-banner" {...sectionBannerProps} />
          </GridItem>
        )}
        <GridItem>
          <PayOptionCard payOptionType="bankTransfer" isDisabled={isDisabled} />
        </GridItem>
        <GridItem>
          <PayOptionCard payOptionType="card" isDisabled={isDisabled} />
        </GridItem>
        {!shouldDisplayCustomExpanded ? (
          <GridItem colSpan={[1, 1, 1, 2]}>
            <NakedButton
              data-testid="add-custom-instructions-button"
              onClick={() => {
                expandCustom.on();
                track('Invoice', 'Click', {
                  Intent: 'add-custom-payment-method',
                  Cta: 'add-custom-payment-details',
                });
              }}
              label={formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.payOptions.options.custom.add')}
              variant="secondary"
            />
          </GridItem>
        ) : (
          <GridItem colSpan={[1, 1, 1, 2]}>
            <Form.TextArea
              {...registerField('customPayInstructions')}
              labelProps={{
                label: formatMessage(
                  'ar.invoiceLifecycle.activities.createInvoice.inputs.payOptions.options.custom.title'
                ),
              }}
              placeholder={formatMessage(
                'ar.invoiceLifecycle.activities.createInvoice.inputs.payOptions.options.custom.description'
              )}
              helperTextProps={{
                label: formatMessage(
                  'ar.invoiceLifecycle.activities.createInvoice.inputs.payOptions.options.custom.helperText'
                ),
              }}
              data-private
              maxChars={300}
              ref={textArea}
            />
          </GridItem>
        )}
        <GridItem colSpan={[1, 1, 1, 2]}>
          <Group variant="horizontal" spacing="xs" alignItems="center">
            <Form.Checkbox
              {...registerField('isAutoRemindersEnabled')}
              isDisabled={isDisabled}
              isChecked={isDisabled ? false : undefined}
              label={formatMessage(
                'ar.invoiceLifecycle.activities.createInvoice.payOptions.form.fields.isAutoRemindersEnabled.label.text'
              )}
              aria-label={formatMessage(
                'ar.invoiceLifecycle.activities.createInvoice.payOptions.form.fields.isAutoRemindersEnabled.ariaLabel.text'
              )}
            />
            <Tooltip
              content={formatMessage(
                'ar.invoiceLifecycle.activities.createInvoice.payOptions.form.fields.isAutoRemindersEnabled.tooltip.text'
              )}
            >
              <Icon aria-label="Info" data-testid="send-auto-reminders-tooltip-trigger" type="info" size="small" />
            </Tooltip>
          </Group>
        </GridItem>
      </FormSection>
    );
  }
);
PayOptionsFormSection.displayName = 'PayOptionsFormSection';
