import { useLocation } from 'react-router-dom';
import { AccountingPlatformSyncConnectionErrorEnum } from '@melio/ap-activities';
import { useAccountingPlatformName } from '@melio/ap-widgets';
import { Link } from '@melio/penny';
import { useAccountingPlatform } from '@melio/platform-api';

import { FullScreenContainer } from '@/cl/components/FullScreenContainer/FullScreenContainer.component';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { usePartnerConfig } from '@/hooks/partners';
import { AccountingPlatformErrorStateType } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { AccountingPlatformErrorMessage } from '@/widgets/accounting-platforms/AccountingPlatformErrorMessage/AccountingPlatformErrorMessage.widget';

export const AccountingSoftwareErrorContainer = () => {
  const { state } = useLocation();
  const { formatMessage } = usePlatformIntl();
  const { syncError, flowToReturn, accountingPlatformId } = state as AccountingPlatformErrorStateType;
  const { partnerConfig } = usePartnerConfig();
  const { isFetching, data } = useAccountingPlatform({ id: accountingPlatformId });
  const accountingPlatformName = useAccountingPlatformName(data?.accountingSlug);

  const messageIdPrefix = 'widgets.accountingPlatform.errorMessage';
  const getErrorMessageProps = () => {
    switch (syncError) {
      case AccountingPlatformSyncConnectionErrorEnum.AccountPlatformConnectToDifferentMelioAccount: {
        return {
          errorTitle: formatMessage(`${messageIdPrefix}.type.accountAlreadyExists.title`),
          errorMessage: formatMessage(`${messageIdPrefix}.type.accountAlreadyExists.description`, {
            accountingPlatformName,
            supportByMail: (
              <Link
                href={`mailto:${partnerConfig.supportEmail}`}
                label={formatMessage(`${messageIdPrefix}.links.supportByMail`)}
                newTab
              />
            ),
          }),
        };
      }
      case AccountingPlatformSyncConnectionErrorEnum.InvalidSubscription: {
        return {
          errorTitle: formatMessage(`${messageIdPrefix}.type.invalidSubscription.title`),
          errorMessage: formatMessage(`${messageIdPrefix}.type.invalidSubscription.description`, {
            accountingPlatformName,
          }),
        };
      }
      case AccountingPlatformSyncConnectionErrorEnum.AccessDenied:
      case AccountingPlatformSyncConnectionErrorEnum.GeneralError:
      default: {
        return {
          withTryAgain: true,
          errorTitle: formatMessage(`${messageIdPrefix}.type.generalError.title`),
          errorMessage: formatMessage(`${messageIdPrefix}.type.generalError.description`, {
            support: (
              <Link
                href={`mailto:${partnerConfig.supportEmail}`}
                label={formatMessage(`${messageIdPrefix}.links.support`)}
                newTab
              />
            ),
          }),
        };
      }
    }
  };

  return (
    <FullScreenContainer>
      <WithLoading isLoading={isFetching} isAbsoluteCenter>
        <AccountingPlatformErrorMessage
          flowToReturn={flowToReturn}
          accountingPlatformId={accountingPlatformId}
          {...getErrorMessageProps()}
        />
      </WithLoading>
    </FullScreenContainer>
  );
};
