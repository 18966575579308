import { Box } from '@chakra-ui/react';
import {
  Container,
  Divider,
  Form,
  Group,
  Layout,
  LoadingContainer,
  Text,
  useFormSubmissionController,
  useMelioForm,
} from '@melio/penny';
import { ApprovalWorkflowRequest } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef, SystemMessageDisplay } from '@melio/platform-utils';
import { Suspense } from 'react';
import type { DefaultValues } from 'react-hook-form';

import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { FormContext } from '../FormContext';
import { schema } from '../schema';
import { AmountCondition } from './AmountCondition';
import { ApprovalAction } from './ApprovalAction';
import { PaymentSchedulerCondition } from './PaymentSchedulerCondition';

export type ApprovalWorkflowFormProps = {
  onClose: (workflow: ApprovalWorkflowRequest) => void;
  onBack: (workflow: ApprovalWorkflowRequest) => void;
  isSaving: boolean;
  isLoading?: boolean;
  defaultValues: DefaultValues<ApprovalWorkflowRequest>;
  title: string;
  onSubmit: (data: ApprovalWorkflowRequest) => void;
};

export const ApprovalWorkflowForm = forwardRef<ApprovalWorkflowFormProps>((props, ref) => {
  const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<ApprovalWorkflowRequest>();

  const form = useMelioForm<ApprovalWorkflowRequest>({
    onSubmit: (res) => props.onSubmit(res),
    schema,
    mode: 'onSubmit',
    isSaving: props.isSaving,
    isLoading: props.isLoading,
    defaultValues: props.defaultValues,
    onSubmissionStateChange,
  });
  const { formatMessage } = useMelioIntl();

  return (
    <FormContext.Provider value={form}>
      <Layout
        data-testid="approval-workflow-form"
        isLoading={props.isLoading}
        header={{
          content: (
            <>
              <NewSinglePaymentStepLayout.Header>
                <NewSinglePaymentStepLayout.CloseButton onClick={() => props.onClose(form.getValues())} />
                <NewSinglePaymentStepLayout.BackButton onClick={() => props.onBack(form.getValues())} />
              </NewSinglePaymentStepLayout.Header>
              <Divider />
            </>
          ),
        }}
        footer={{
          isSticky: true,
          content: (
            <Box width="full">
              <Divider variant="horizontal" />
              <Container justifyContent="flex-end" width="full" paddingX="m" paddingY="m">
                <Group variant="horizontal">
                  <NewSinglePaymentStepLayout.SecondaryNextButton
                    {...form.cancelButtonProps}
                    size="medium"
                    label={formatMessage('activities.create-approval-workflows.cancel')}
                    onClick={() => props.onClose(form.getValues())}
                    data-testid="cancel-button"
                  ></NewSinglePaymentStepLayout.SecondaryNextButton>
                  <NewSinglePaymentStepLayout.NextButton
                    {...submitButtonProps}
                    size="medium"
                    data-testid="submit-approval-workflow"
                    label={formatMessage('activities.create-approval-workflows.save')}
                  />
                </Group>
              </Container>
            </Box>
          ),
        }}
      >
        <Container width="full" justifyContent="center" alignItems="center">
          <Suspense fallback={<LoadingContainer isLoading />}>
            <Box width="768px" ref={ref}>
              <Group spacing="m" variant="vertical">
                <SystemMessageDisplay data-testid="workflow-form-system-message" />
                <Container paddingBottom="xxl">
                  <Text textStyle="heading1Semi">{props.title}</Text>
                </Container>
              </Group>
              <Form size="small" {...form.formProps}>
                <Group variant="vertical" spacing="xxl" width="full">
                  <Text textStyle="body1Semi">
                    {formatMessage('activities.create-approval-workflows.conditions.title')}
                  </Text>
                  <AmountCondition index={0} />
                  <PaymentSchedulerCondition index={1} />
                  <Divider variant="horizontal" />
                  <Text textStyle="body1Semi">
                    {formatMessage('activities.create-approval-workflows.actions.title')}
                  </Text>
                  <ApprovalAction />
                </Group>
              </Form>
            </Box>
          </Suspense>
        </Container>
      </Layout>
    </FormContext.Provider>
  );
});
