import React from 'react';
import { intersection } from 'lodash';
import { useAnalytics } from '@melio/platform-analytics';
import {
  OAuthAccountInfo,
  OAuthAccountNotCreatedReason,
  OAuthOrganizationInfo,
  OAuthReasonsMetadata,
  OAuthSelectedOrgId,
  OAuthUserConsent,
} from '@melio/platform-api';

import { MissingAccountData } from '@/widgets/onboarding/MissingAccountData.widget';
import { SelectOrganization } from '@/widgets/onboarding/SelectOrganization.widget';
import { UserConsent } from '@/widgets/onboarding/UserConsent.widget';

const USER_CONSENT_REASONS = [OAuthAccountNotCreatedReason.UserConsent];

const MISSING_ACCOUNT_INFO_REASONS = [
  OAuthAccountNotCreatedReason.MissingFirstName,
  OAuthAccountNotCreatedReason.MissingLastName,
  OAuthAccountNotCreatedReason.MissingDateOfBirth,
  OAuthAccountNotCreatedReason.MissingCompanyName,
  OAuthAccountNotCreatedReason.MissingAddress,
];
const SELECT_ORGANIZATION_REASONS = [OAuthAccountNotCreatedReason.HasMultipleOrganizations];

type OAuthRegisterScreenParams = {
  reasons: OAuthAccountNotCreatedReason[];
  reasonsMetadata?: OAuthReasonsMetadata;
  matchingOrgs?: OAuthOrganizationInfo[];
  onReasonsDone: ({
    accountInfo,
    selectedOrgId,
    userConsent,
  }: {
    accountInfo: OAuthAccountInfo;
    selectedOrgId: OAuthSelectedOrgId;
    userConsent: OAuthUserConsent;
  }) => void;
  onClose: () => void;
};

export const OAuthReasonsScreen = ({
  reasons: apiReasons,
  reasonsMetadata,
  matchingOrgs,
  onReasonsDone,
  onClose: onConsentClose,
}: OAuthRegisterScreenParams) => {
  const [currentReasons, setCurrentReasons] = React.useState<OAuthAccountNotCreatedReason[]>(apiReasons);
  const [accountInfo, setAccountInfo] = React.useState<OAuthAccountInfo>({});
  const [selectedOrgId, setSelectedOrgId] = React.useState<OAuthSelectedOrgId>(undefined);
  const [userConsent, setUserConsent] = React.useState<OAuthUserConsent>(undefined);
  const { track } = useAnalytics();

  const filterReasons = React.useCallback(
    (reasonsToFilter: OAuthAccountNotCreatedReason[]) => currentReasons.filter((it) => !reasonsToFilter.includes(it)),
    [currentReasons],
  );

  React.useEffect(() => {
    if (!currentReasons.length) {
      onReasonsDone({ accountInfo, selectedOrgId, userConsent });
    }
  }, [accountInfo, currentReasons, onReasonsDone, selectedOrgId, userConsent]);

  if (intersection(currentReasons, USER_CONSENT_REASONS).length) {
    return (
      <UserConsent
        privacyPolicyUrl={reasonsMetadata?.agreements?.privacyPolicy || ''}
        termsOfServiceUrl={reasonsMetadata?.agreements?.termsOfService || ''}
        onDone={() => {
          setUserConsent(true);
          setCurrentReasons(filterReasons(USER_CONSENT_REASONS));
        }}
        onClose={() => {
          setUserConsent(false);
          onConsentClose();
        }}
      />
    );
  }

  if (intersection(currentReasons, MISSING_ACCOUNT_INFO_REASONS).length) {
    return (
      <MissingAccountData
        reasons={currentReasons}
        onDone={(accountInfo) => {
          setAccountInfo(accountInfo);
          setCurrentReasons(filterReasons(MISSING_ACCOUNT_INFO_REASONS));
        }}
      />
    );
  }

  if (intersection(currentReasons, SELECT_ORGANIZATION_REASONS).length) {
    return (
      <SelectOrganization
        organizations={matchingOrgs}
        onDone={(orgId) => {
          track('OnboardingMultiOrgDetails', 'Submitted', {
            SelectedOrgId: orgId,
          });
          setSelectedOrgId(orgId);
          setCurrentReasons(filterReasons(SELECT_ORGANIZATION_REASONS));
        }}
      />
    );
  }

  return <></>;
};
