import { VisuallyHidden } from '@chakra-ui/react';
import { ApiPagination, ARInvoice, SortableHeaderCell, useMelioIntl } from '@melio/ar-domain';
import { Group, Pagination, Pill, Table, TableColumnDef, useTable } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useCallback, useMemo } from 'react';

import { DisplayStatusLabel, DisplayStatusLabelMap, InvoiceActionsHandlers } from '../../../utils';
import { useHighlightInvoiceTableRows } from '../hooks';
import { InvoiceSortFieldsEnum, OnSort, SortParams } from '../types';
import { InvoiceTableRowsMobile } from './InvoiceTableRows.mobile';
import { AmountCell, CustomerCell, DateCell, InvoiceActionCell } from './table-cells';

export type InvoiceTableProps = {
  invoices: ARInvoice[];
  pagination: ApiPagination;
  isLoading?: boolean;
  onRowClick?: (params: { rowId: string; rowData: ARInvoice }) => void;
  onSort: OnSort;
  sortParams?: SortParams;
  invoiceActions: InvoiceActionsHandlers;
  selectedInvoiceId?: string;
};

type Invoice = ARInvoice;
type InvoiceColumn = TableColumnDef<Invoice, InvoiceActionsHandlers>;

export const InvoiceTable = forwardRef<InvoiceTableProps>(
  (
    { invoices, pagination, isLoading, onRowClick, onSort, invoiceActions, sortParams, selectedInvoiceId, ...props },
    ref
  ) => {
    const highlightedRowIds = useHighlightInvoiceTableRows({
      newTabItemsIds: selectedInvoiceId ? [selectedInvoiceId] : [],
      isLoading,
    });
    const { formatMessage } = useMelioIntl();

    const sortableHeaderProps = useMemo(
      () => ({ order: sortParams?.order, selectedField: sortParams?.field, onClick: onSort }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [JSON.stringify([sortParams?.order, sortParams?.field])]
    );

    const columns: InvoiceColumn[] = useMemo(
      () => [
        {
          id: 'customerName',
          size: 'l',
          header: formatMessage('ar.dashboard.activities.invoiceTable.columns.customer.label'),
          cell: ({ row }) => <CustomerCell row={row} />,
          isPinnedToLeft: true,
        },
        {
          id: 'invoiceNumber',
          header: formatMessage('ar.dashboard.activities.invoiceTable.columns.invoiceNumber.label'),
          cell: ({ row }) => <Table.Cell data-testid="invoice-number">{row.invoiceNumber}</Table.Cell>,
          size: 's',
          isPinnedToLeft: true,
        },
        {
          id: 'updatedAt',
          header: (
            <SortableHeaderCell<InvoiceSortFieldsEnum>
              label={formatMessage('ar.dashboard.activities.invoiceTable.columns.dateUpdated.label')}
              field={InvoiceSortFieldsEnum.updatedAt}
              {...sortableHeaderProps}
            />
          ),
          cell: ({ row }) => <DateCell row={row} field="updatedAt" />,
          size: 's',
        },
        {
          id: 'dueDate',
          header: (
            <SortableHeaderCell<InvoiceSortFieldsEnum>
              label={formatMessage('ar.dashboard.activities.invoiceTable.columns.dueDate.label')}
              field={InvoiceSortFieldsEnum.dueDate}
              {...sortableHeaderProps}
            />
          ),
          cell: ({ row }) => <DateCell row={row} field="dueDate" />,
          size: 's',
        },
        {
          id: 'lastSeen',
          header: formatMessage('ar.dashboard.activities.invoiceTable.columns.lastSeen.label'),
          cell: ({ row }) => <DateCell row={row} field="lastSeen" icon="checked-double" />,
          size: 's',
        },
        {
          id: 'status',
          header: formatMessage('ar.dashboard.activities.invoiceTable.columns.status.label'),
          cell: ({ row }) => (
            <Table.Cell>
              <Pill
                type="secondary"
                status={DisplayStatusLabelMap[row.displayStatus]}
                label={DisplayStatusLabel[row.displayStatus]}
                data-testid="invoice-status-label"
                data-cy-status={row.displayStatus}
              />
            </Table.Cell>
          ),
          size: 's',
        },
        {
          id: 'totalAmount',
          header: (
            <SortableHeaderCell<InvoiceSortFieldsEnum>
              label={formatMessage('ar.dashboard.activities.invoiceTable.columns.amount.label')}
              field={InvoiceSortFieldsEnum.totalAmount}
              textAlign="end"
              {...sortableHeaderProps}
            />
          ),
          cell: ({ row }) => <AmountCell value={row.totalAmount} data-testid="invoice-amount" />,
          size: 's',
          textAlign: 'end',
        },
        {
          id: 'action',
          header: (
            <VisuallyHidden>
              {formatMessage('ar.dashboard.activities.invoiceTable.columns.actions.label')}
            </VisuallyHidden>
          ),
          cell: ({ row, meta }) => (meta ? <InvoiceActionCell row={row} meta={meta} /> : null),
          size: 'xs',
        },
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [sortableHeaderProps]
    );

    const mobileRowRenderer = useCallback(
      (row: ARInvoice, meta?: InvoiceActionsHandlers) => <InvoiceTableRowsMobile invoice={row} invoiceActions={meta} />,
      []
    );

    const tableProps = useTable<ARInvoice, InvoiceActionsHandlers>({
      isLoading,
      data: invoices,
      columns,
      headerVariant: 'dark',
      onRowClick,
      meta: invoiceActions,
      highlightedRowIds,
      mobileRowRenderer,
    });

    return (
      <Group variant="vertical" alignItems="flex-end" spacing="l">
        <Table
          data-testid="invoice-table"
          data-component="InvoiceTable"
          isLoading={isLoading}
          {...props}
          {...tableProps}
          ref={ref}
        />
        {!isLoading && pagination.totalCount > pagination.limit && (
          <Pagination
            currentPage={pagination.pageNumber}
            pageSize={pagination.limit}
            totalItems={pagination.totalCount}
            onPageChange={pagination.goToPage}
          />
        )}
      </Group>
    );
  }
);
InvoiceTable.displayName = 'InvoiceTable';
