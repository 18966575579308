import { createContext } from 'react';

import { SystemMessageType } from './types';

type SystemMessageContextType = {
  message: SystemMessageType | null;
  setMessage: (value: SystemMessageType) => void;
  clear: () => void;
};

export const SystemMessageContext = createContext<SystemMessageContextType>({
  message: null,
  setMessage: () => null,
  clear: () => null,
});
