import { Group, NakedButton, Text } from '@melio/penny';
import { VendorGroup } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { VendorGroupsFilter } from './VendorGroupsFilter';

export const VendorGroupFilterContainer = ({
  onSelectFilter,
  onCreateClick,
  selectedVendorGroupIds,
  onEditVendorsGroupClick,
}: {
  onSelectFilter: (params: { vendorGroupIds: VendorGroup['id'][] }) => void;
  onCreateClick: VoidFunction;
  selectedVendorGroupIds: VendorGroup['id'][];
  onEditVendorsGroupClick: (vendorsGroupId: VendorGroup['id']) => void;
}) => {
  const { formatMessage } = useMelioIntl();
  return (
    <Group spacing="s" alignItems="center" width="full">
      <Text textStyle="body3Semi">{formatMessage('activities.payDashboard.vendorsTab.vendorGroups.filter.title')}</Text>
      <VendorGroupsFilter
        onSelectFilter={onSelectFilter}
        onCreateClick={onCreateClick}
        selectedVendorGroupIds={selectedVendorGroupIds}
      />
      {/* This is temp edit behavior until we'll support mullti select in this ticket - https://meliorisk.atlassian.net/browse/ME-77378 */}
      {selectedVendorGroupIds[0] && (
        <NakedButton
          label={formatMessage('activities.payDashboard.vendorsTab.vendorGroups.filter.editGroup')}
          variant="secondary"
          onClick={() => onEditVendorsGroupClick(selectedVendorGroupIds?.[0] ?? '')}
          data-testid="vendor-groups-edit-button"
        />
      )}
    </Group>
  );
};
