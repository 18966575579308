import { ApiError, useRecommendedVendors as useBaseHook } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { addRanksToVendors, RankedVendor } from '../utils';

export type ExperimentArms = 'control' | 'modal' | 'cards';

type RecommendationState = {
  featureFlag: ExperimentArms;
  isLoading: boolean;
  recommendedVendors?: RankedVendor[];
  error: ApiError | null;
};

type Props = {
  shouldFetch: boolean;
};

export const useRecommendedVendors = (props: Props): RecommendationState => {
  const [featureFlag] = useDevFeature<ExperimentArms>(FeatureFlags.GrowthRecommendedVendorsExperiment, 'control', {
    shouldTrack: true,
  });
  const { data, isFetching, error } = useBaseHook({ disabled: featureFlag === 'control' || !props.shouldFetch });

  return {
    recommendedVendors: data ? addRanksToVendors(data) : undefined,
    isLoading: isFetching,
    featureFlag,
    error,
  };
};
