import { ExternalLayout } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { CardParams } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import {
  ExternalLayoutContentWidget,
  PoweredByMelioFooterWidget,
  PushToDebitHeaderWidget,
  PushToDebitHeaderWidgetProps,
  TabaPayIframe,
} from '@melio/vex-widgets';

export type TabapayCreateCardDetailsScreenProps = PushToDebitHeaderWidgetProps & {
  onDone: (params: CardParams) => void;
  onError?: ErrorFunction;
};

export const TabapayCreateCardDetailsScreen = forwardRef<TabapayCreateCardDetailsScreenProps, 'div'>(
  (
    {
      accountName,
      paymentAmount,
      paymentInvoiceNumber,
      paymentNote,
      feePercentValue,
      calculatedFee,
      onDone,
      onError,
      children,
      ...props
    },
    ref
  ) => {
    const [isLoading, loading] = useBoolean(true);
    useAnalyticsView('PushToDebitAddCardDetails', !isLoading);

    const { createTrackHandler } = useAnalytics();
    const trackSubmit = createTrackHandler<{ Status?: 'succeeded' | 'failed' }>(
      'PushToDebitAddCardDetails',
      'Submitted'
    );

    const { formatMessage } = useMelioIntl();

    const content = (
      <ExternalLayoutContentWidget
        header={{
          title: (
            <PushToDebitHeaderWidget
              accountName={accountName}
              paymentAmount={paymentAmount}
              paymentInvoiceNumber={paymentInvoiceNumber}
              paymentNote={paymentNote}
              feePercentValue={feePercentValue}
              calculatedFee={calculatedFee}
            />
          ),
        }}
        form={{
          title: formatMessage('activities.pushToDebit.screens.createCardDetails.title'),
          description: formatMessage('activities.pushToDebit.screens.createCardDetails.subTitle'),
          fields: (
            <TabaPayIframe
              data-testid="tabapay-iframe"
              onLoad={loading.off}
              onSuccess={(...args) => {
                trackSubmit({ Status: 'succeeded' });
                onDone(...args);
              }}
              onError={(...args) => {
                trackSubmit({ Status: 'failed' });
                onError?.(...args);
              }}
              cardType="debit"
            />
          ),
        }}
      />
    );

    const footer = <PoweredByMelioFooterWidget />;

    return (
      <ExternalLayout
        data-component="PushToDebitActivity.TabapayCreateCardDetailsScreen"
        data-testid="tabapay-push-to-debit-activity-create-card-details-screen"
        {...props}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);
TabapayCreateCardDetailsScreen.displayName = 'PushToDebitActivity.TabapayCreateCardDetailsScreen';
