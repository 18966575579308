import { useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Group, Modal, useBreakpointValue, useTheme } from '@melio/penny';

import { useIsMobile } from '@/hooks/useIsMobile';
import { USBankChevronDownIcon } from '@/screens/embedded-experience-demo/assets/us-bank/USBankChevronDownIcon';
import { USBankFooterIllustration } from '@/screens/embedded-experience-demo/assets/us-bank/USBankFooterIllustration';
import { USBankLogo } from '@/screens/embedded-experience-demo/assets/us-bank/USBankLogo';
import { EmbeddedDemoLayoutComponent } from '@/screens/embedded-experience-demo/layouts/types';
import { MockDropdown, USBankButton } from '@/screens/embedded-experience-demo/layouts/us-bank/us-bank.components';
import { USBankLegalNotes } from '@/screens/embedded-experience-demo/layouts/us-bank/USBankLegalNotes';

import './style.css';
const APP_HEADER_HEIGHT = '4.375rem';
const SECONDARY_HEADER_HEIGHT = '3rem';

export const USBankLayout: EmbeddedDemoLayoutComponent = ({ children }) => {
  const isSmallScreen = useIsMobile();
  const layoutWidth = useBreakpointValue({ xs: '90%', s: '70%', l: '70%', xl: '1280px' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const mockAction = () => setIsModalOpen(true);
  const { borderRadii } = useTheme();

  return (
    <Box backgroundColor="#F7F7FA" display="flex" flexDirection="column" alignItems="center">
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        Mock action
      </Modal>
      <Box
        as="header"
        display="flex"
        width={layoutWidth}
        alignItems="center"
        justifyContent="space-between"
        height={APP_HEADER_HEIGHT}
        flexDirection={isSmallScreen ? 'column' : 'row'}
      >
        <Box as="div">
          <USBankLogo />
        </Box>
        <Group spacing="xl">
          <Group alignItems="center" spacing="xs">
            <USBankButton onClick={mockAction}>Notifications</USBankButton>
            <Box>
              <Box
                borderRadius={borderRadii.global.full}
                width="1.5rem"
                height="1.5rem"
                backgroundColor="#DE162B"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text color="white" fontSize={12}>
                  14
                </Text>
              </Box>
            </Box>
          </Group>
          <Group alignItems="center" spacing="xs">
            <MockDropdown label="Profile & settings" />
          </Group>
          <Group alignItems="center" spacing="xs">
            <MockDropdown label="Need help?" />
          </Group>
          <USBankButton onClick={mockAction}>Logout</USBankButton>
        </Group>
      </Box>
      <Box
        height={SECONDARY_HEADER_HEIGHT}
        width="100%"
        backgroundColor="white"
        borderBottom="2px solid #CCCCD2"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box display="flex" height="full" width={layoutWidth} backgroundColor="white">
          <Group as="div" alignItems="center" spacing="m">
            <USBankButton onClick={mockAction}>Dashboard</USBankButton>
            <Group alignItems="center" spacing="xs">
              <MockDropdown label="Accounts" />
            </Group>
            <Group alignItems="center" spacing="xs">
              <MockDropdown label="Pay & Get paid" />
            </Group>
            <Box borderBottom="2px solid #0A41C5" height="100%">
              <Group alignItems="center" spacing="xs" height="full">
                <USBankChevronDownIcon />
                <MockDropdown label="Plan & track" />
              </Group>
            </Box>
            <Group alignItems="center" spacing="xs">
              <MockDropdown label="Products & offers" />
            </Group>
          </Group>
        </Box>
      </Box>
      {children}
      <Box as="footer" width="full" display="flex" flexDirection="column" alignItems="center">
        <Box width={layoutWidth} display="flex" flexDirection="column" gap="1rem" paddingY="2rem">
          <USBankLegalNotes />
        </Box>
        <Box
          backgroundColor="#0C2074"
          color="white"
          width="full"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box width={layoutWidth} display="flex" justifyContent="space-between" paddingY="2rem">
            <Box display="flex" flexDirection="column" alignItems="flex-start" gap={'1.325rem'}>
              {[
                'Language preference',
                'Privacy',
                'Legal agreements',
                'Accessibility',
                'Cobrowse',
                'Your California privacy choices',
                'Connection secured',
              ].map((label) => (
                <USBankButton key={label} fontSize={'0.75rem'} color="white" fontWeight={400}>
                  {label}
                </USBankButton>
              ))}
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
              <Box>
                <Text fontSize="0.75rem" fontWeight={400} lineHeight="1.09375rem" color="white">
                  U.S. Bank
                </Text>
                <Text fontSize="0.75rem" fontWeight={400} lineHeight="1.09375rem">
                  200 South Sixth St.
                </Text>
                <Text fontSize="0.75rem" fontWeight={400} lineHeight="1.09375rem">
                  Minneapolis, MN 55401
                </Text>
              </Box>
              <Box>
                <Text fontSize="0.75rem" fontWeight={400} lineHeight="1.09375rem">
                  © {new Date().getFullYear()} U.S. Bank
                </Text>
              </Box>
            </Box>
          </Box>
          <USBankFooterIllustration />
        </Box>
      </Box>
    </Box>
  );
};
