import { useMelioIntl } from '@melio/ar-domain';
import { Button, Container, Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { Footer, OnboardingLayout } from '../../../layouts';
import { GetStartedDescription, GetStartedHeader, GetStartedPrivacyPolicy } from '../components';

export type GetStartedScreenProps = {
  onDone: VoidFunction;
};

export const GetStartedScreen = forwardRef<GetStartedScreenProps>(({ onDone }, ref) => {
  const { formatMessage } = useMelioIntl();

  return (
    <OnboardingLayout
      headerContent={formatMessage('ar.onboarding.layout.header.label')}
      footerContent={
        <Footer
          tertiaryButtonProps={{
            label: formatMessage('ar.onboarding.layout.getStarted.buttons.close.label'),
          }}
        />
      }
      isStickyFooter
    >
      <Container ref={ref}>
        <Group variant="vertical" spacing="m">
          <Group variant="vertical">
            <GetStartedHeader />
            <GetStartedDescription />
          </Group>
          <Group spacing="xs" variant="vertical">
            <Group.Item>
              <Button
                variant="primary"
                label={formatMessage('ar.onboarding.activities.getStarted.button.label')}
                data-testid="get-started-button"
                onClick={onDone}
              />
            </Group.Item>
            <GetStartedPrivacyPolicy />
          </Group>
        </Group>
      </Container>
    </OnboardingLayout>
  );
});

GetStartedScreen.displayName = 'GetStartedScreen';
