import { useUserPreference, useUserPreferences } from '@melio/platform-api';
import { useDevFeature } from '@melio/platform-feature-flags';

import { PromotionFlags, PromotionType } from '../types';

export type PromotionProps = {
  type: PromotionType;
};

export const usePromotionEnabled = (type: PromotionType) => {
  const { featureFlag, userPreferenceKey } = PromotionFlags[type];
  const [promotionFlagEnabled] = useDevFeature(featureFlag, false);

  const { data: userPreference, isFetching: isLoadingUserPreference } = useUserPreference({
    id: userPreferenceKey,
    enabled: promotionFlagEnabled,
  });
  const { create: createUserPreferences } = useUserPreferences({
    enabled: false,
  });

  const isPromotionDismissed = userPreference?.value === 'true';

  const onDismiss = async () => {
    await createUserPreferences({
      userPreference: { key: userPreferenceKey, value: 'true' },
    });
  };

  return {
    isLoading: isLoadingUserPreference,
    enabled: promotionFlagEnabled && !isPromotionDismissed,
    onDismiss,
  };
};
