import {
  BulkPostPaymentSettingsCalculatorRequest,
  PatchPaymentIntentsBulkRequest,
  PatchPaymentIntentsPaymentIntentIdRequest,
  PaymentIntent,
  PostPaymentIntentsFromBillBulkRequest,
  PostPaymentIntentsFromBillRequest,
  PostPaymentIntentsRequest,
  PostPaymentSettingsCalculatorBulkResponse,
} from '@melio/javascript-sdk';
import {
  PaymentIntent as PlatformPaymentIntent,
  PostPaymentIntentsFromBillRequest as PlatformPostPaymentIntentsFromBillRequest,
} from '@melio/platform-api-axios-client';
import { convertCentsToDollars, convertDollarsToCents } from '@melio/platform-utils';

import { BatchUpdateResult } from '../../core/types';

export const convertUpdateParamsDollarsToCents = (data: PatchPaymentIntentsPaymentIntentIdRequest) =>
  convertDollarsToCents(data, ['amountToPay', 'billPayments[].amount']);

export const convertBatchUpdateParamsDollarsToCents = (updatedItems: PatchPaymentIntentsBulkRequest) =>
  updatedItems.map(({ id, data }) => ({ id, data: convertUpdateParamsDollarsToCents(data) }));

export const convertBatchUpdateResultsCentsToDollars = (items: BatchUpdateResult<PaymentIntent>) =>
  items.map((item) =>
    item.status === 'error' ? item : { ...item, data: convertPaymentIntentCentsToDollars(item.data) }
  );

export const convertCreateParamsDollarsToCents = (data: PostPaymentIntentsRequest) =>
  convertDollarsToCents(data, ['amountToPay', 'billInfo.amount', 'billPayments[].amount']);

export const convertCreatePaymentIntentFromBillDollarsToCents = <
  T extends PostPaymentIntentsFromBillRequest | PlatformPostPaymentIntentsFromBillRequest
>(
  data: T
) => convertDollarsToCents(data, ['amountToPay', 'billPayments[].amount']);

export const convertBatchCreatePaymentIntentFromBillDollarsToCents = (
  paymentIntents: PostPaymentIntentsFromBillBulkRequest['paymentIntents']
) => paymentIntents.map((paymentIntent) => convertCreatePaymentIntentFromBillDollarsToCents(paymentIntent));

export const convertPaymentIntentsCentsToDollars = (items: PaymentIntent[]) =>
  items.map(convertPaymentIntentCentsToDollars);

export const convertPaymentIntentCentsToDollars = <T extends PaymentIntent | PlatformPaymentIntent>(data: T) =>
  convertCentsToDollars(data, [
    'amountToPay',
    'billInfo.amount',
    'estimatedFees[].amount',
    'deliveryPreferenceOptions[].fee.amount',
    'billPayments[].amount',
  ]);

export const convertGetPaymentSettingsParamDollarsToCents = (param: BulkPostPaymentSettingsCalculatorRequest) =>
  param.map((data) => convertDollarsToCents(data, ['amountToPay', 'invoicesData[].amount']));

export const convertGetPaymentSettingsResponseCentsToDollars = (
  response: PostPaymentSettingsCalculatorBulkResponse['data']
) =>
  response.map((data) =>
    convertCentsToDollars(data, ['amountToPay', 'estimatedFees[].amount', 'deliveryPreferenceOptions[].fee.amount'])
  );
