import { useRecoilValue } from 'recoil';
import { PushToDebitActivity } from '@melio/vex-activities';

import { WithLoading } from '@/hoc/withLoading.hoc';
import {
  vendorActionAccountNameSelector,
  vendorActionPaymentIdSelector,
} from '@/store/VendorActions/VendorAction.model';

export const UpgradePaymentScreen = () => {
  const paymentId = useRecoilValue(vendorActionPaymentIdSelector);
  const accountName = useRecoilValue(vendorActionAccountNameSelector);
  return (
    <WithLoading isLoading={!paymentId || !accountName} isAbsoluteCenter={true}>
      <PushToDebitActivity paymentId={paymentId} accountName={accountName} onDone={() => {}} />
    </WithLoading>
  );
};
