import { FormInputs, useMelioIntl } from '@melio/ar-domain';
import { Form, useMelioForm, UseMelioFormResults } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { number, object, SchemaOf } from 'yup';

import { cardPaymentSharePercentageRanges } from '../const';
import { FinancialInformationFormFields } from '../types';

type FinancialInformationFormProps = {
  isSaving: boolean;
  isLoading: boolean;
  onSubmit: (formValues: FinancialInformationFormFields) => void;
  onSubmissionStateChange: (submissionState: UseMelioFormResults<FinancialInformationFormFields>) => void;
  defaultValues?: Partial<FinancialInformationFormFields>;
};

const useSchema = () => {
  const { formatMessage } = useMelioIntl();
  return object().shape({
    monthlySalesVolume: number()
      .typeError(
        formatMessage(
          'ar.onboarding.activities.financialInformation.form.fields.monthlySalesVolume.valid.required.text'
        )
      )
      .moreThan(
        0,
        formatMessage(
          'ar.onboarding.activities.financialInformation.form.fields.monthlySalesVolume.valid.required.text'
        )
      )
      .required(
        formatMessage(
          'ar.onboarding.activities.financialInformation.form.fields.monthlySalesVolume.valid.required.text'
        )
      )
      .lessThan(
        10000000000,
        formatMessage(
          'ar.onboarding.activities.financialInformation.form.fields.monthlySalesVolume.valid.maxDigit.text'
        )
      ),
    cardPaymentShare: number()
      .typeError(
        formatMessage('ar.onboarding.activities.financialInformation.form.fields.cardPaymentShare.valid.required.text')
      )
      .required(
        formatMessage('ar.onboarding.activities.financialInformation.form.fields.cardPaymentShare.valid.required.text')
      )
      .moreThan(
        0,
        formatMessage('ar.onboarding.activities.financialInformation.form.fields.cardPaymentShare.valid.required.text')
      ),
    averageTransactionSize: number()
      .typeError(
        formatMessage(
          'ar.onboarding.activities.financialInformation.form.fields.averageTransactionSize.valid.required.text'
        )
      )
      .required(
        formatMessage(
          'ar.onboarding.activities.financialInformation.form.fields.averageTransactionSize.valid.required.text'
        )
      )
      .lessThan(
        10000000000,
        formatMessage(
          'ar.onboarding.activities.financialInformation.form.fields.averageTransactionSize.valid.maxDigit.text'
        )
      )
      .moreThan(
        0,
        formatMessage(
          'ar.onboarding.activities.financialInformation.form.fields.averageTransactionSize.valid.required.text'
        )
      ),
  }) as SchemaOf<FinancialInformationFormFields>;
};

export const FinancialInformationForm = forwardRef<FinancialInformationFormProps, 'form'>(
  ({ onSubmit, defaultValues, onSubmissionStateChange, isSaving, isLoading }, ref) => {
    const { registerField, formProps } = useMelioForm({
      schema: useSchema(),
      onSubmit,
      defaultValues,
      onSubmissionStateChange,
      isSaving,
      isLoading,
    });
    const { formatMessage } = useMelioIntl();

    return (
      <Form data-testid="financial-information-form" ref={ref} {...formProps}>
        <FormInputs.AmountField
          {...registerField('monthlySalesVolume')}
          labelProps={{
            label: formatMessage('ar.onboarding.activities.financialInformation.form.fields.monthlySalesVolume.label'),
          }}
        />

        <FormInputs.Select
          labelProps={{
            label: formatMessage('ar.onboarding.activities.financialInformation.form.fields.cardPaymentShare.label'),
          }}
          {...registerField('cardPaymentShare')}
          placeholder={formatMessage(
            'ar.onboarding.activities.financialInformation.form.fields.cardPaymentShare.placeHolder'
          )}
          options={cardPaymentSharePercentageRanges}
        />

        <FormInputs.AmountField
          {...registerField('averageTransactionSize')}
          labelProps={{
            label: formatMessage(
              'ar.onboarding.activities.financialInformation.form.fields.averageTransactionSize.label'
            ),
          }}
        />
      </Form>
    );
  }
);
