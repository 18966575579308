import { useDownloadPaymentConfirmation } from '@melio/ap-domain';
import { Group, IconButton, ListItem, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { ApprovalDecision, PaymentFullyExpanded } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { RefObject, useCallback } from 'react';

import { MicroDepositModalRefHandlers } from '../../../funding-sources';
import { useShowBillDetailsInPaymentDrawer } from '../../paymentDrawer.utils';
import { PaymentApprovalDeclined } from './approval-declined/PaymentApprovalDeclined';
import { PaymentCanceled } from './canceled/PaymentCanceled';
import { PaymentCompleted } from './completed/PaymentCompleted';
import { RefundCompleted } from './completed/RefundCompleted';
import { PaymentFailed } from './failed/PaymentFailed';
import { PaymentInProgress } from './in-progress/PaymentInProgress';
import { RefundInProgress } from './in-progress/RefundInProgress';
import { PaymentScheduled } from './scheduled/PaymentScheduled';
import { PaymentBannerSection } from './sections/PaymentBannerSection';
import { PaymentStatusBadgeSection } from './sections/PaymentStatusBadgeSection';
import { PaymentTimelineTrackerSection } from './sections/PaymentTimelineTrackerSection';
import { usePaymentDetailsSectionType } from './utils';

export type PaymentDetailsProps = {
  payment: PaymentFullyExpanded;
  billDetailsRef: RefObject<HTMLDivElement>;
  approvalDecisions?: ApprovalDecision[];
  microDepositModalRef?: RefObject<MicroDepositModalRefHandlers>;
};

export const PaymentDetails = ({
  payment,
  billDetailsRef,
  approvalDecisions,
  microDepositModalRef,
}: PaymentDetailsProps) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();
  const { track } = useAnalytics();
  const { download, print } = useDownloadPaymentConfirmation();
  const [isPrintButtonsEnabled] = useDevFeature(FeatureFlags.PaymentDrawerPrintButton, false);
  const { showBillDetailsInPaymentDrawer } = useShowBillDetailsInPaymentDrawer(payment.createMethod);
  const [isNoteToSelfEnabled] = usePartnerFeature('PaymentNoteToSelf', false);
  const paymentStatus = usePaymentDetailsSectionType(payment, approvalDecisions);
  const onDownloadClick = () => {
    track('Payment', 'Click', { Cta: 'download', Intent: 'download' });
    download(payment.id);
  };
  const onPrintClick = () => {
    track('Payment', 'Click', { Cta: 'print', Intent: 'print' });
    print(payment.id);
  };

  const renderPaymentDetailsComponent = useCallback(() => {
    switch (paymentStatus) {
      case 'payment-scheduled': {
        if (!microDepositModalRef) {
          throw new Error('microDepositModalRef is required');
        }
        return (
          <PaymentScheduled
            payment={payment}
            approvalDecisions={approvalDecisions}
            billDetailsRef={billDetailsRef}
            microDepositModalRef={microDepositModalRef}
          />
        );
      }
      case 'payment-in-progress':
        return (
          <PaymentInProgress payment={payment} approvalDecisions={approvalDecisions} billDetailsRef={billDetailsRef} />
        );
      case 'payment-completed':
        return (
          <PaymentCompleted payment={payment} approvalDecisions={approvalDecisions} billDetailsRef={billDetailsRef} />
        );
      case 'payment-approval-declined': {
        if (!approvalDecisions) {
          throw new Error('Approval decisions are required for payment-approval-declined section');
        }
        return (
          <PaymentApprovalDeclined
            payment={payment}
            approvalDecisions={approvalDecisions}
            billDetailsRef={billDetailsRef}
          />
        );
      }
      case 'refund-in-progress':
        return <RefundInProgress payment={payment} />;
      case 'refund-completed':
        return <RefundCompleted payment={payment} />;
      case 'payment-failed':
        return <PaymentFailed payment={payment} />;
      case 'payment-canceled':
        return <PaymentCanceled payment={payment} />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  return (
    <Group variant="vertical" spacing="m">
      <Group variant="vertical" spacing="s">
        <Group variant="horizontal" spacing="s" justifyContent="space-between" alignItems="center">
          <Text textStyle="heading3Semi" as="h3">
            {formatMessage('widgets.paymentDetails.paymentActivity')}
          </Text>
          {isPrintButtonsEnabled && (
            <>
              <Group variant="horizontal" spacing="s">
                <IconButton
                  data-testid="payment-details-download"
                  icon="download"
                  onClick={onDownloadClick}
                  aria-label={formatMessage('widgets.paymentDetails.downloadButton.ariaLabel')}
                  size="large"
                />
                {!isExtraSmallScreen ? (
                  <IconButton
                    data-testid="payment-details-print"
                    icon="print"
                    onClick={onPrintClick}
                    aria-label={formatMessage('widgets.paymentDetails.printButton.ariaLabel')}
                    size="large"
                  />
                ) : null}
              </Group>
            </>
          )}
        </Group>

        <PaymentStatusBadgeSection payment={payment} approvalDecisions={approvalDecisions} />

        <PaymentTimelineTrackerSection payment={payment} approvalDecisions={approvalDecisions} />

        <PaymentBannerSection
          payment={payment}
          approvalDecisions={approvalDecisions}
          microDepositModalRef={microDepositModalRef}
        />
      </Group>

      {renderPaymentDetailsComponent()}

      {/** Temporary solution for fiserv until we implement note on the payment https://meliorisk.atlassian.net/browse/ME-73407 */}
      {!isNoteToSelfEnabled && !showBillDetailsInPaymentDrawer && payment.bills?.[0]?.note && (
        <ListItem
          data-testid="payment-details-noteToSelf"
          labelProps={{ label: formatMessage('widgets.paymentDetails.noteToSelf.label') }}
          mainLabelProps={{ label: payment.bills[0].note }}
        />
      )}
    </Group>
  );
};
