import { Icon, SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

export const AccountingClientPlanSelectionStepDiscountBanner = ({ discountPercent }: { discountPercent: number }) => {
  const formattedDiscount = String(discountPercent);
  const { formatMessage } = useMelioIntl();

  return (
    <SectionBanner
      title={formatMessage('activities.accountants.newClient.form.plan.discount.banner.title', {
        discount: formattedDiscount,
      })}
      description={formatMessage('activities.accountants.newClient.form.plan.discount.banner.description', {
        discount: formattedDiscount,
      })}
      variant="brand"
      leftElement={<Icon type="discount" color="brand" aria-hidden />}
    />
  );
};
