export const Countries = [
  { key: 'Afghanistan', value: '004' },
  { key: 'Albania', value: '008' },
  { key: 'Algeria', value: '012' },
  { key: 'American Samoa', value: '016' },
  { key: 'Andorra', value: '020' },
  { key: 'Angola', value: '024' },
  { key: 'Antigua and Barbuda', value: '028' },
  { key: 'Argentina', value: '032' },
  { key: 'Armenia', value: '051' },
  { key: 'Australia', value: '036' },
  { key: 'Austria', value: '040' },
  { key: 'Azerbaijan', value: '031' },
  { key: 'Bahamas', value: '044' },
  { key: 'Bahrain', value: '048' },
  { key: 'Bangladesh', value: '050' },
  { key: 'Barbados', value: '052' },
  { key: 'Belarus', value: '112' },
  { key: 'Belgium', value: '056' },
  { key: 'Belize', value: '084' },
  { key: 'Benin', value: '204' },
  { key: 'Bhutan', value: '064' },
  { key: 'Bolivia', value: '068' },
  { key: 'Bosnia and Herzegovina', value: '070' },
  { key: 'Botswana', value: '072' },
  { key: 'Brazil', value: '076' },
  { key: 'Brunei Darussalam', value: '096' },
  { key: 'Bulgaria', value: '100' },
  { key: 'Burkina Faso', value: '854' },
  { key: 'Burundi', value: '108' },
  { key: 'Cabo Verde', value: '132' },
  { key: 'Cambodia', value: '116' },
  { key: 'Cameroon', value: '120' },
  { key: 'Canada', value: '124' },
  { key: 'Central African Republic', value: '140' },
  { key: 'Chad', value: '148' },
  { key: 'Chile', value: '152' },
  { key: 'China', value: '156' },
  { key: 'Colombia', value: '170' },
  { key: 'Comoros', value: '174' },
  { key: 'Congo', value: '178' },
  { key: 'Congo (Democratic Republic of the)', value: '180' },
  { key: 'Costa Rica', value: '188' },
  { key: 'Croatia', value: '191' },
  { key: 'Cuba', value: '192' },
  { key: 'Cyprus', value: '196' },
  { key: 'Czech Republic', value: '203' },
  { key: 'Denmark', value: '208' },
  { key: 'Djibouti', value: '262' },
  { key: 'Dominica', value: '212' },
  { key: 'Dominican Republic', value: '214' },
  { key: 'Ecuador', value: '218' },
  { key: 'Egypt', value: '818' },
  { key: 'El Salvador', value: '222' },
  { key: 'Equatorial Guinea', value: '226' },
  { key: 'Eritrea', value: '232' },
  { key: 'Estonia', value: '233' },
  { key: 'Eswatini', value: '748' },
  { key: 'Ethiopia', value: '231' },
  { key: 'Fiji', value: '242' },
  { key: 'Finland', value: '246' },
  { key: 'France', value: '250' },
  { key: 'Gabon', value: '266' },
  { key: 'Gambia', value: '270' },
  { key: 'Georgia', value: '268' },
  { key: 'Germany', value: '276' },
  { key: 'Ghana', value: '288' },
  { key: 'Greece', value: '300' },
  { key: 'Grenada', value: '308' },
  { key: 'Guatemala', value: '320' },
  { key: 'Guinea', value: '324' },
  { key: 'Guinea-Bissau', value: '624' },
  { key: 'Guyana', value: '328' },
  { key: 'Haiti', value: '332' },
  { key: 'Honduras', value: '340' },
  { key: 'Hungary', value: '348' },
  { key: 'Iceland', value: '352' },
  { key: 'India', value: '356' },
  { key: 'Indonesia', value: '360' },
  { key: 'Iran', value: '364' },
  { key: 'Iraq', value: '368' },
  { key: 'Ireland', value: '372' },
  { key: 'Israel', value: '376' },
  { key: 'Italy', value: '380' },
  { key: 'Jamaica', value: '388' },
  { key: 'Japan', value: '392' },
  { key: 'Jordan', value: '400' },
  { key: 'Kazakhstan', value: '398' },
  { key: 'Kenya', value: '404' },
  { key: 'Kiribati', value: '296' },
  { key: 'Kuwait', value: '414' },
  { key: 'Kyrgyzstan', value: '417' },
  { key: 'Laos', value: '418' },
  { key: 'Latvia', value: '428' },
  { key: 'Lebanon', value: '422' },
  { key: 'Lesotho', value: '426' },
  { key: 'Liberia', value: '430' },
  { key: 'Libya', value: '434' },
  { key: 'Liechtenstein', value: '438' },
  { key: 'Lithuania', value: '440' },
  { key: 'Luxembourg', value: '442' },
  { key: 'Madagascar', value: '450' },
  { key: 'Malawi', value: '454' },
  { key: 'Malaysia', value: '458' },
  { key: 'Maldives', value: '462' },
  { key: 'Mali', value: '466' },
  { key: 'Malta', value: '470' },
  { key: 'Marshall Islands', value: '584' },
  { key: 'Mauritania', value: '478' },
  { key: 'Mauritius', value: '480' },
  { key: 'Mexico', value: '484' },
  { key: 'Micronesia', value: '583' },
  { key: 'Moldova', value: '498' },
  { key: 'Monaco', value: '492' },
  { key: 'Mongolia', value: '496' },
  { key: 'Montenegro', value: '499' },
  { key: 'Morocco', value: '504' },
  { key: 'Mozambique', value: '508' },
  { key: 'Myanmar', value: '104' },
  { key: 'Namibia', value: '516' },
  { key: 'Nauru', value: '520' },
  { key: 'Nepal', value: '524' },
  { key: 'Netherlands', value: '528' },
  { key: 'New Zealand', value: '554' },
  { key: 'Nicaragua', value: '558' },
  { key: 'Niger', value: '562' },
  { key: 'Nigeria', value: '566' },
  { key: 'North Macedonia', value: '807' },
  { key: 'Norway', value: '578' },
  { key: 'Oman', value: '512' },
  { key: 'Pakistan', value: '586' },
  { key: 'Palau', value: '585' },
  { key: 'Panama', value: '591' },
  { key: 'Papua New Guinea', value: '598' },
  { key: 'Paraguay', value: '600' },
  { key: 'Peru', value: '604' },
  { key: 'Philippines', value: '608' },
  { key: 'Poland', value: '616' },
  { key: 'Portugal', value: '620' },
  { key: 'Qatar', value: '634' },
  { key: 'Romania', value: '642' },
  { key: 'Russia', value: '643' },
  { key: 'Rwanda', value: '646' },
  { key: 'Saint Kitts and Nevis', value: '659' },
  { key: 'Saint Lucia', value: '662' },
  { key: 'Saint Vincent and the Grenadines', value: '670' },
  { key: 'Samoa', value: '882' },
  { key: 'San Marino', value: '674' },
  { key: 'Sao Tome and Principe', value: '678' },
  { key: 'Saudi Arabia', value: '682' },
  { key: 'Senegal', value: '686' },
  { key: 'Serbia', value: '688' },
  { key: 'Seychelles', value: '690' },
  { key: 'Sierra Leone', value: '694' },
  { key: 'Singapore', value: '702' },
  { key: 'Slovakia', value: '703' },
  { key: 'Slovenia', value: '705' },
  { key: 'Solomon Islands', value: '090' },
  { key: 'Somalia', value: '706' },
  { key: 'South Africa', value: '710' },
  { key: 'South Sudan', value: '728' },
  { key: 'Spain', value: '724' },
  { key: 'Sri Lanka', value: '144' },
  { key: 'Sudan', value: '729' },
  { key: 'Suriname', value: '740' },
  { key: 'Sweden', value: '752' },
  { key: 'Switzerland', value: '756' },
  { key: 'Syria', value: '760' },
  { key: 'Tajikistan', value: '762' },
  { key: 'Tanzania', value: '834' },
  { key: 'Thailand', value: '764' },
  { key: 'Togo', value: '768' },
  { key: 'Tonga', value: '776' },
  { key: 'Trinidad and Tobago', value: '780' },
  { key: 'Tunisia', value: '788' },
  { key: 'Turkey', value: '792' },
  { key: 'Turkmenistan', value: '795' },
  { key: 'Tuvalu', value: '798' },
  { key: 'Uganda', value: '800' },
  { key: 'Ukraine', value: '804' },
  { key: 'United Arab Emirates', value: '784' },
  { key: 'United Kingdom', value: '826' },
  { key: 'United States of America', value: '840' },
  { key: 'Uruguay', value: '858' },
  { key: 'Uzbekistan', value: '860' },
  { key: 'Vanuatu', value: '548' },
  { key: 'Venezuela', value: '862' },
  { key: 'Vietnam', value: '704' },
  { key: 'Yemen', value: '887' },
  { key: 'Zambia', value: '894' },
  { key: 'Zimbabwe', value: '716' },
];

export type Countries = (typeof Countries)[number];
