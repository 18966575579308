import { Modal, Text } from '@melio/penny';
import { EventProperties, useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

type ModalData = {
  email: string;
};

type ExistingClientAccountModalProps = {
  data: ModalData;
  isOpen: boolean;
  onClose: VoidFunction;
  onCancel: VoidFunction;
  onDone: VoidFunction;
  isSaving: boolean;
  onError?: ErrorFunction;
};

const useExistingClientAccountModalAnalytics = (isOpen: boolean) => {
  const globalProperties: EventProperties = { PageName: 'this-email-already-in-use', Intent: 'invite-client' };
  const { track } = useAnalytics();

  useEffect(() => {
    if (isOpen) {
      track('Organization', 'View', { ...globalProperties });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, track]);

  const trackClick = (action: 'request-to-join' | 'create-new-account' | 'exit') =>
    track('Organization', 'Click', { ...globalProperties, Cta: action });

  return { trackClick };
};

export const ExistingClientAccountModal = ({
  data,
  isOpen,
  onClose,
  onCancel,
  onDone,
  isSaving,
}: ExistingClientAccountModalProps) => {
  const { trackClick } = useExistingClientAccountModalAnalytics(isOpen);
  const { formatMessage } = useMelioIntl();

  const handleDone = () => {
    trackClick('request-to-join');
    onDone();
  };

  const handleCancel = () => {
    trackClick('create-new-account');
    onCancel();
  };

  const handleClose = () => {
    trackClick('exit');
    onClose();
  };

  const description = formatMessage('activities.accountants.activities.existingClientAccount.modal.description', {
    email: data.email,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      header={formatMessage('activities.accountants.activities.existingClientAccount.modal.title')}
      primaryButton={{
        label: formatMessage(
          'activities.accountants.activities.existingClientAccount.modal.actions.requestToJoin.label'
        ),
        onClick: handleDone,
        isLoading: isSaving,
        variant: 'primary',
      }}
      secondaryButton={{
        label: formatMessage(
          'activities.accountants.activities.existingClientAccount.modal.actions.createNewAccount.label'
        ),
        onClick: handleCancel,
        variant: 'secondary',
      }}
    >
      <Text textStyle="body2">{description}</Text>
    </Modal>
  );
};
