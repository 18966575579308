import { useAnalytics } from '@melio/platform-analytics';
import { Organization, useOrganizations } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';

import { CompanyDetailsScreenOnDone } from '../CompanyDetails.screen';
import { buildCreateOwnedOrganizationParams } from '../CompanyDetails.screen.utils';
import { CompanyDetailsFormWidgetFields } from '../widgets';

type Props = {
  onOrganizationCreate: (data: CompanyDetailsFormWidgetFields) => void;
  onOrganizationCreateSuccess: (createdOrganization: Organization) => void;
  onOrganizationCreateError?: (error: PlatformError) => void;
  onOrganizationCreateContinueOnBehalf: VoidFunction;
};

export const useCompanyDetailsStep = ({
  onOrganizationCreate,
  onOrganizationCreateSuccess,
  onOrganizationCreateError,
  onOrganizationCreateContinueOnBehalf,
}: Props) => {
  const { create: createOrganization, isMutating: isOrganizationSaving } = useOrganizations();
  const { showMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  const handleCreateOrganizationFailure = (isOwner: boolean) => (error: PlatformError) => {
    track('User', 'Status', {
      PageName: 'add-company-details',
      Status: 'failure',
      CompanyOwner: isOwner,
      Cta: 'add-company',
    });
    showMessage({
      type: 'error',
      title: formatMessage('activities.addCompany.screens.companyDetails.createToastError'),
    });
    onOrganizationCreateError?.(error);
  };

  const handleCreateOrganizationSuccess = (isOwner: boolean) => (createdOrganization: Organization) => {
    track('User', 'Status', {
      PageName: 'add-company-details',
      Status: 'success',
      CompanyOwner: isOwner,
      Cta: 'add-company',
    });
    onOrganizationCreateSuccess(createdOrganization);
  };

  const handleStepDone: CompanyDetailsScreenOnDone = ({ name, address, aptNumber, isOwner }) => {
    const createOrganizationParams = buildCreateOwnedOrganizationParams({
      name,
      address,
      aptNumber,
    });

    onOrganizationCreate({ name, address, aptNumber, isOwner });

    if (isOwner) {
      createOrganization(createOrganizationParams)
        .then(handleCreateOrganizationSuccess(isOwner))
        .catch(handleCreateOrganizationFailure(isOwner));
    } else {
      onOrganizationCreateContinueOnBehalf();
    }
  };

  return {
    handleStepDone,
    isSaving: isOrganizationSaving,
  };
};
