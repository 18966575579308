import { isEmpty } from 'lodash';

import { AddVendorFormFields, Managed } from '../../../../types';

export const getCreateVendorPayload = (data: AddVendorFormFields, managed: Managed) => {
  const { companyName, accountNumber, nickname, postalCode, uniqueName } = data;
  const { managedBy, self } = managed;

  return {
    name: companyName ?? '',
    uniqueName: !isEmpty(uniqueName) ? uniqueName : undefined,
    managed: { self, managedBy, ...(postalCode ? { zipCode: postalCode } : null) },
    accountNumber,
    ...(nickname ? { nickname } : {}),
  };
};
