import {
  InboxItemBill,
  InboxItemPaymentRequest,
  InboxItemScannedInvoice,
  Todo,
  TodoActivateCollaboratorsTypeEnum,
  TodoFailedPaymentTypeEnum,
  TodoOverdueIn7DaysInboxItemsTypeEnum,
  TodoOverdueInboxItemsTypeEnum,
  TodoOverdueInMoreThan7DaysInboxItemsTypeEnum,
  TodoPaymentsToApproveTypeEnum,
  useTodos,
  UseTodosProps,
} from '@melio/platform-api';
import { max } from 'lodash';
import { useCallback, useMemo } from 'react';

import { usePayDashboardPagination } from '../../PayDashboard/components/Pagination';
import { usePaymentsTabNewFailedPayments } from '../../PayDashboard/hooks/useGetNewFailedPayments';
import { useNewApprovalsTabItems } from '../../PayDashboard/hooks/useNewApprovalsTabItems';
import {
  useNewOverdueBills,
  useNewOverdueIn7DaysBills,
  useNewOverdueInMoreThan7DaysBills,
} from '../../PayDashboard/hooks/useNewOverdueBills';
import {
  enrichActivateCollaboratorsTodo,
  EnrichedTodo,
  enrichOverdueInboxItemsTodo,
  enrichOverdueInFutureInboxItemsTodo,
  enrichPaymentsTodo,
} from '../utils';
import { useLastSeenTodos } from './useLastSeenTodos';

export type UseEnrichedTodosProps = UseTodosProps;

export const useEnrichedTodos = (props: UseEnrichedTodosProps = {}) => {
  const query = useTodos(props);
  const todos = query.data;
  const { setLastSeen, lastSeenDate, isLoading: isLoadingLastSeenDate } = useLastSeenTodos();

  const overdueBills = useMemo(
    () =>
      (todos?.find(({ type }) => type === TodoOverdueInboxItemsTypeEnum.OverdueInboxItems)?.items?.data ?? []) as (
        | InboxItemBill
        | InboxItemScannedInvoice
        | InboxItemPaymentRequest
      )[],
    [todos]
  );

  const { pageSize } = usePayDashboardPagination();
  const { newPayments: newFailedPayments } = usePaymentsTabNewFailedPayments({
    limit: pageSize,
  });
  const { newPayments: newApprovalsTabItems } = useNewApprovalsTabItems();
  const { bills: newOverdueBills } = useNewOverdueBills({ overdueBills });
  const { bills: newOverdueInMoreThan7DaysBills } = useNewOverdueInMoreThan7DaysBills();
  const { bills: newOverdueIn7DaysBills } = useNewOverdueIn7DaysBills();

  const enrichedTodos = useMemo(
    () =>
      todos
        ?.map((todo) => {
          switch (todo.type) {
            case TodoActivateCollaboratorsTypeEnum.ActivateCollaborators:
              return enrichActivateCollaboratorsTodo({ todo, isLoadingLastSeenDate, lastSeenDate });
            case TodoFailedPaymentTypeEnum.FailedPayments:
              return enrichPaymentsTodo({ todo, newItems: newFailedPayments, isLoadingLastSeenDate, lastSeenDate });
            case TodoPaymentsToApproveTypeEnum.PaymentsToApprove:
              return enrichPaymentsTodo({ todo, newItems: newApprovalsTabItems, isLoadingLastSeenDate, lastSeenDate });
            case TodoOverdueInboxItemsTypeEnum.OverdueInboxItems:
              return enrichOverdueInboxItemsTodo({
                todo,
                isLoadingLastSeenDate,
                lastSeenDate,
                newItems: newOverdueBills,
              });
            case TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems:
              return enrichOverdueInFutureInboxItemsTodo({
                todo,
                isLoadingLastSeenDate,
                lastSeenDate,
                newItems: newOverdueIn7DaysBills,
              });
            case TodoOverdueInMoreThan7DaysInboxItemsTypeEnum.OverdueInMoreThan7DaysInboxItems:
              return enrichOverdueInFutureInboxItemsTodo({
                todo,
                isLoadingLastSeenDate,
                lastSeenDate,
                newItems: newOverdueInMoreThan7DaysBills,
              });
            default:
              return null;
          }
        })
        .filter((todo): todo is EnrichedTodo<Todo> => !!todo),
    [
      isLoadingLastSeenDate,
      lastSeenDate,
      newApprovalsTabItems,
      newFailedPayments,
      newOverdueBills,
      newOverdueIn7DaysBills,
      newOverdueInMoreThan7DaysBills,
      todos,
    ]
  );

  const newItemsAfterLastSeenCount = useMemo(
    () =>
      enrichedTodos
        ? enrichedTodos.reduce((acc, todo) => acc + (todo?.enrichment.newItemsAfterLastSeenCount ?? 0), 0)
        : 0,
    [enrichedTodos]
  );
  const newestTodoDate = useMemo(
    () => (enrichedTodos ? max(enrichedTodos.map((todo) => todo?.enrichment.newestItemDate)) : undefined),
    [enrichedTodos]
  );
  const markAsSeen = useCallback(() => setLastSeen(newestTodoDate), [setLastSeen, newestTodoDate]);

  return {
    ...query,
    data: enrichedTodos,
    newItemsAfterLastSeenCount,
    markAsSeen,
  };
};
