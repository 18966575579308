import { Avatar } from '@melio/penny';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';

type VendorAvatarProps = {
  name?: string;
  isManaged?: boolean;
  logoUrl?: string;
};

export const VendorAvatar = ({ name, isManaged, logoUrl }: VendorAvatarProps) => {
  const { settings } = useConfig();
  const [isExternalVendorLogoEnabled] = useDevFeature<boolean>(FeatureFlags.ExternalVendorLogoEnabled, false);
  if (!name) {
    return null;
  }
  const badgeProps =
    isManaged && settings.showCheckMarkIconForManagedVendor
      ? { badge: 'verified' as const, badgeColor: 'brand' as const }
      : undefined;

  return (
    <Avatar
      name={name}
      src={isExternalVendorLogoEnabled ? logoUrl : undefined}
      size="medium"
      {...badgeProps}
      aria-hidden="true"
    />
  );
};
