import { PublicPayorApiClient } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

export type UseGuestPayorUserDetailsProps = UseModelProps<
  typeof PublicPayorApiClient.getPublicPayorPaymentRequestUserDetails
>;

export const useGuestPayorUserDetails = (props?: UseGuestPayorUserDetailsProps) =>
  useModel({
    queryFn: PublicPayorApiClient.getPublicPayorPaymentRequestUserDetails,
    queryKey: ['PublicPayorApi', 'getPublicPayorPaymentRequestUserDetails'],
    ...props,
  });

export type GuestPayorUserDetailsModel = ReturnType<typeof useGuestPayorUserDetails>;
