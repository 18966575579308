export enum SubscriptionTabsEnum {
  Plans = 'plans',
  Checkout = 'checkout',
  Success = 'success',
  PaymentMethodSelect = 'payment-method/select',
  ClaimAccountantPlan = 'accounting-client-plan-selected',
  OwnerShouldSetUpSubscription = 'finish-setup',
}

export enum SubscriptionCancelOrDowngradeOriginEnum {
  Settings = 'settings',
}

export const DEFAULT_DASHBOARD_ROUTE = '/pay-dashboard';
export const SUBSCRIPTION_SETTINGS_PLANS_ROUTE = '/settings/subscription-plans';
export const SETTINGS_COLLABORATORS = '/settings/collaborators';
export const SETTINGS_SUPPORT = '/settings/support';
export const PAYMENT_METHOD_BANK_SELECT_URL = '/payment-methods/bank/select';

export const TAB_PREFIX = 'subscription';
export const SUBSCRIPTION_SUCCESS_PAGE_ROUTE = `/${TAB_PREFIX}/${SubscriptionTabsEnum.Success}`;
export const SUBSCRIPTION_PAYMENT_METHOD_SELECT_ROUTE = `/${TAB_PREFIX}/${SubscriptionTabsEnum.PaymentMethodSelect}`;
export const SUBSCRIPTION_PAYMENT_METHOD_SELECT_SUCCESS_ROUTE = `/${TAB_PREFIX}/${SubscriptionTabsEnum.PaymentMethodSelect}/success`;
export const SUBSCRIPTION_PAYMENT_METHOD_SELECT_ERROR_ROUTE = `/${TAB_PREFIX}/${SubscriptionTabsEnum.PaymentMethodSelect}/error`;
export const ACCOUNTING_CLIENT_SUBSCRIPTION_PLAN_SELECTED_ROUTE = `/${TAB_PREFIX}/${SubscriptionTabsEnum.ClaimAccountantPlan}`;
export const OWNER_SHOULD_SETUP_SUBSCRIPTION_ROUTE = `/${TAB_PREFIX}/${SubscriptionTabsEnum.OwnerShouldSetUpSubscription}`;

export const PLANS_URL = `/${TAB_PREFIX}/${SubscriptionTabsEnum.Plans}`;
export const CHECKOUT_URL = `/${TAB_PREFIX}/${SubscriptionTabsEnum.Checkout}`;

export const SUBSCRIPTION_GRACE_SELECT_YOUR_PLAN_ROUTE = `/${TAB_PREFIX}/grace/select-your-plan`;
export const SUBSCRIPTION_GRACE_PLAN_IS_REQUIRED_ROUTE = `/${TAB_PREFIX}/grace/plan-is-required`;
export const SUBSCRIPTION_GRACE_SELECT_CLIENT_PLAN_ROUTE = `/${TAB_PREFIX}/grace/select-client-plan`;

export const SUBSCRIPTION_DOWNGRADE_CONFIRM_DOWNGRADE = `/${TAB_PREFIX}/downgrade/confirm-downgrade`;
export const SUBSCRIPTION_DOWNGRADE_ACTION_REQUIRED = `/${TAB_PREFIX}/downgrade/action-required`;

export const ADD_NEW_COMPANY_ROUTE = '/companies/new-company';
export const COMPLETE_DETAILS_ROUTE = '/complete-details';
export const SUBSCRIPTIN_DOWNGRADE_CONFIRMATION_REQUIRED_ALLOWED_ROUTES = [
  PLANS_URL,
  CHECKOUT_URL,
  ACCOUNTING_CLIENT_SUBSCRIPTION_PLAN_SELECTED_ROUTE,
  SUBSCRIPTION_DOWNGRADE_CONFIRM_DOWNGRADE,
  SUBSCRIPTION_DOWNGRADE_ACTION_REQUIRED,
  OWNER_SHOULD_SETUP_SUBSCRIPTION_ROUTE,
];
