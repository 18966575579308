import { useAnalytics } from '@melio/platform-analytics';
import {
  ApprovalWorkflow,
  ApprovalWorkflowRequest,
  useCollaborators,
  WorkflowActionsRoles,
  WorkflowPaymentAmountCondition,
  WorkflowPaymentCreatedByCondition,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useCallback } from 'react';

export function useAnalyticsTracker({ intent }: { intent: 'add-approval-workflow' | 'edit-approval-workflow' }) {
  const [isApprovalWorkflowsV2] = useDevFeature(FeatureFlags.PlatformApprovalWorkflowsV2, false, {
    shouldTrack: true,
  });
  const { data: collaborators, isLoading } = useCollaborators({ expand: ['user'] });
  const { track: _track } = useAnalytics();

  const toAnalyticsPayloadV2 = useCallback((workflow: ApprovalWorkflowRequest): Record<string, unknown> => {
    const workflowConditions = workflow.criteria.conditions.map(({ attribute, condition }) => ({
      ['amount']: attribute === 'payment.amount',
      ['scheduler']: attribute === 'payment.createdBy',
      ['vendor']: attribute === 'payment.vendor',
      ['more-or-equal']: attribute === 'payment.amount' && condition.configuration.operator === 'greaterEqual',
      ['less-or-equal']: attribute === 'payment.amount' && condition.configuration.operator === 'belowEqual',
      ['specific-user']: attribute === 'payment.createdBy' && condition.configuration.filterBy === 'user',
      ['specific-roles']: attribute === 'payment.createdBy' && condition.configuration.filterBy === 'role',
      ['threshold']: attribute === 'payment.amount' ? condition.configuration.value : null,
      ['number-of-users']:
        attribute === 'payment.createdBy' && condition.configuration.filterBy === 'user'
          ? condition.configuration.ids.length
          : null,
      ['number-of-vendors']: attribute === 'payment.vendor' ? condition.configuration.ids.length : null,
      ['any']:
        attribute === 'payment.createdBy' && condition.configuration.filterBy === 'role'
          ? condition.configuration.roles
          : null,
    }));

    const workflowActions = workflow.actions.map(({ type, configuration }) => ({
      ['any-approver']: type === 'role',
      ['specific-approver']: type === 'user',
      ['anyone']: type === 'role' && configuration.numberOfApprovers === 1,
      ['any-2']: type === 'role' && configuration.numberOfApprovers === 2,
      ['any-3']: type === 'role' && configuration.numberOfApprovers === 3,
      ['number-of-approvers']: type === 'user' ? configuration.ids.length : null,
    }));

    return { WorkflowCondition: workflowConditions, WorkflowAction: workflowActions };
  }, []);

  const toAnalyticsPayloadV1 = useCallback(
    (workflow: ApprovalWorkflowRequest): Record<string, unknown> => {
      const amountCondition = workflow.criteria.conditions.find<WorkflowPaymentAmountCondition>(
        (condition): condition is WorkflowPaymentAmountCondition =>
          condition.condition.type === 'workflowNumericCondition'
      );
      const schedulerCondition = workflow.criteria.conditions.find<WorkflowPaymentCreatedByCondition>(
        (condition): condition is WorkflowPaymentCreatedByCondition =>
          condition.condition.type === 'workflowCollaboratorsCondition'
      );
      const roleApproversAction = workflow.actions.find(
        (action): action is WorkflowActionsRoles => action.type === 'role'
      );

      const payload: Record<string, unknown> = {
        Threshold: amountCondition?.condition.configuration.value,
        PaymentScheduler: schedulerCondition?.condition.configuration.filterBy === 'user' ? 'specific' : 'any',
        SchedulerRole:
          schedulerCondition?.condition.configuration.filterBy === 'role'
            ? schedulerCondition?.condition.configuration.roles
            : schedulerCondition?.condition.configuration.ids,
        NumberOfApprovers: collaborators?.filter(({ approvalActions: { canApprovePayments } }) => canApprovePayments),
        ApprovalDecisionsCount: roleApproversAction?.configuration.numberOfApprovers,
      };
      return payload;
    },
    [collaborators]
  );

  const toAnalyticsPayload = useCallback(
    (workflow: ApprovalWorkflowRequest): Record<string, unknown> =>
      isApprovalWorkflowsV2 ? toAnalyticsPayloadV2(workflow) : toAnalyticsPayloadV1(workflow),
    [isApprovalWorkflowsV2, toAnalyticsPayloadV1, toAnalyticsPayloadV2]
  );

  const trackClick = (params: {
    cta: string;
    pageName: string;
    workflow?: ApprovalWorkflowRequest;
    workflowId?: ApprovalWorkflow['id'];
  }) => {
    _track('User', 'Click', {
      PageName: params.pageName,
      Flow: 'approval-workflow',
      Intent: intent,
      Cta: params.cta,
      WorkflowId: params.workflowId,
      ...(params.workflow ? toAnalyticsPayload(params.workflow) : {}),
    });
  };

  const trackView = useCallback(
    (params: { pageName: string; workflow?: ApprovalWorkflowRequest; workflowId?: ApprovalWorkflow['id'] }) => {
      _track('User', 'View', {
        PageName: params.pageName,
        Flow: 'approval-workflow',
        Intent: intent,
        WorkflowId: params.workflowId,
        ...(params.workflow ? toAnalyticsPayload(params.workflow) : {}),
      });
    },
    [_track, intent, toAnalyticsPayload]
  );

  return { isLoading, trackClick, trackView, track: _track };
}
