import { forwardRef } from '@melio/platform-utils';

import { OwnershipDetailsScreen } from './screens';

export type OwnershipDetailsActivityProps = {
  onClose: VoidFunction;
  onDone: VoidFunction;
  onBack: VoidFunction;
};

export const OwnershipDetailsActivity = forwardRef<OwnershipDetailsActivityProps>(({ ...props }, ref) => (
  <OwnershipDetailsScreen {...props} ref={ref} />
));

OwnershipDetailsActivity.displayName = 'OwnershipDetailsActivity';
