import { Container, Pill, PillProps, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export type PlanCardHeaderProps = {
  planName: string;
  planDescription: string;
  isFreePlan?: boolean;
  planBadge?: Pick<PillProps, 'type' | 'label'>;
};

export const PlanCardHeader = ({ planDescription, planName, planBadge, isFreePlan }: PlanCardHeaderProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Container data-testid="subscription-plan-card-header">
      <Container justifyContent="space-between" alignItems="center" paddingBottom="s">
        <Text as="h2" textStyle="heading3Semi">
          {isFreePlan ? formatMessage('activities.subscription.plans.card.title.freePlanName', { planName }) : planName}
        </Text>

        {planBadge && <Pill data-testid="subscription-plan-badge" status="brand" {...planBadge} />}
      </Container>

      <Text textStyle="body2">{planDescription}</Text>
    </Container>
  );
};
