import { Box } from '@chakra-ui/react';
import { NewSinglePaymentStepLayout } from '@melio/ap-activities';
import { Button, Group } from '@melio/penny';
import { DeliveryMethod, useDeliveryMethods } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { PCIComplianceInfo } from '@melio/vex-widgets';

import { useReceivingMethodActivityEvents } from '@/hooks/useReceivingMethodActivityEvents';
import { ReceivingMethodWithActions } from '@/widgets/settings-page/ReceivingMethodsPage/ReceivingMethodWithActions.widget';

export const ReceivingMethodAchExistsPage = () => {
  const { onClose, onDone } = useReceivingMethodActivityEvents();
  const { formatMessage } = useMelioIntl();

  const { data, isLoading: isLoadingDeliveryMethod } = useDeliveryMethods({ isGetOwn: true });

  return (
    <NewSinglePaymentStepLayout
      data-component="ExistReceivingMethod"
      data-testid="exist-receiving-method"
      isLoading={isLoadingDeliveryMethod}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
        </NewSinglePaymentStepLayout.Header>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('widgets.receivingMethodExist.title')}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        {formatMessage('widgets.receivingMethodExist.subTitle')}
      </NewSinglePaymentStepLayout.Description>
      <NewSinglePaymentStepLayout.Content>
        <Group variant="vertical" width="full">
          {data?.map((deliveryMethod: DeliveryMethod) => {
            return (
              <ReceivingMethodWithActions deliveryMethod={deliveryMethod} key={deliveryMethod?.id} isHideActions />
            );
          })}
          <Box mt="20px" mb="20px">
            <PCIComplianceInfo />
          </Box>
          <Button
            size="large"
            label={formatMessage('widgets.receivingMethodExist.cta')}
            variant="primary"
            data-testid="exist-receiving-method-cta"
            onClick={onDone}
          />
        </Group>
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
};
