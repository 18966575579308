import React from 'react';
import { Center } from '@chakra-ui/react';
import { Loader as MelioLoader } from '@melio/penny';

import { AbsoluteCenter } from '../AbsoluteCenter/AbsoluteCenter.component';

type LoaderProps = { isAbsoluteCenter?: boolean };

export const Loader = React.forwardRef<HTMLDivElement, LoaderProps>((props, ref) => {
  const { isAbsoluteCenter, ...rest } = props;

  const loader = <MelioLoader ref={ref} {...rest} />;

  if (isAbsoluteCenter) {
    return <AbsoluteCenter>{loader}</AbsoluteCenter>;
  }

  return <Center height="full">{loader}</Center>;
});

Loader.displayName = 'Loader';
