import { Form, Group, Tooltip } from '@melio/penny';
import { useCollaborators, WorkflowActionsUsers } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useIsSubscriptionsEnabled } from '@melio/subscriptions';
import { compact } from 'lodash';
import React from 'react';

import { useFormContext } from '../../FormContext';
import { ApprovalFormDivider } from '../ApprovalFormDivider';
import { PremiumBadge } from '../PremiumBadge';
import { AddAction } from './components/AddAction';
import { ApprovalAction } from './components/ApprovalAction';

export const ApprovalActions = () => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { watch, setValue, getValues, resetField, unregister } = useFormContext();
  const { formatMessage } = useMelioIntl();
  const { data = [], isLoading } = useCollaborators({
    expand: ['user'],
    suspense: true,
    select: (res) => res.filter(({ approvalActions: { canApprovePayments } }) => canApprovePayments),
  });

  const actions = watch('actions');
  const approvalType = watch('actions.0.type');

  const haveAllUsersBeenSelected =
    actions
      .filter((action): action is WorkflowActionsUsers => action.type === 'user')
      .map(({ configuration }) => configuration.ids)
      .flat().length === data.length;

  const isAddActionDisabled = approvalType === 'role' || (approvalType === 'user' && haveAllUsersBeenSelected);
  const isAddActionVisible = actions.length < 3;

  const handleAddAction = () => {
    if (getValues('actions').length === 3) {
      throw new Error('Maximum of 3 actions are allowed');
    }
    setValue(`actions.${actions.length}`, { type: 'user', configuration: { ids: [] } }, { shouldValidate: false });
  };

  const handleUserApprovalTypeChange = () => {
    const actions = getValues('actions');
    const approvalType = getValues('actions.0.type');

    const selectedUsersIds = actions
      .filter((action): action is WorkflowActionsUsers => action.type === 'user')
      .map(({ configuration }) => configuration.ids)
      .flat();
    if (approvalType === 'user' && selectedUsersIds.length === data.length) {
      actions.forEach(({ type, configuration }, index) => {
        if (type === 'user' && !configuration.ids.length) {
          unregister(`actions.${index}`);
        }
      });
      resetField('actions', { defaultValue: compact(actions), keepError: true });
    }
  };

  const renderAddAction = () =>
    isAddActionDisabled ? (
      <Tooltip
        content={
          approvalType === 'role'
            ? formatMessage('activities.create-approval-workflows.actions.addNew.tooltip.roleApprovalType')
            : formatMessage('activities.create-approval-workflows.actions.addNew.tooltip.allUsersSelected')
        }
        shouldAddTriggerFocus
      >
        <AddAction onClick={handleAddAction} isDisabled />
      </Tooltip>
    ) : (
      <Group justifyContent="space-between" data-testid="approval-workflow-v2.add-step">
        <AddAction onClick={handleAddAction} />
        {isSubscriptionsEnabled && <PremiumBadge featureName="advancedApprovalWorkflows" />}
      </Group>
    );

  return (
    <Group variant="vertical" alignItems="flex-start">
      <Form columns={12} gap="m" as="div">
        {watch('actions').map(({ type }, index) => (
          <React.Fragment key={index}>
            {index !== 0 && (
              <Form.ContentBox colSpan={12}>
                <ApprovalFormDivider type="then" />
              </Form.ContentBox>
            )}
            <ApprovalAction
              approvers={data}
              approvalType={type}
              onUserApprovalTypeChange={handleUserApprovalTypeChange}
              isDisabled={isLoading}
              index={index}
            />
          </React.Fragment>
        ))}
      </Form>
      {isAddActionVisible ? renderAddAction() : null}
    </Group>
  );
};
