import {
  addWildcardToRoutes,
  RouteElement,
  SubscriptionBillingCycleEnum,
  useFlowRouting,
  withLocalization,
  withOutlet,
} from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { SubscriptionUpgradeModalActivity } from '@melio/subscriptions';
import { Route, Routes } from 'react-router-dom';

import { SubscriptionActivity } from '../activities';
import { PlansData } from '../types';

type SubscriptionOnboardingFlowProps = {
  onBack: VoidFunction;
  onClose: VoidFunction;
};

export const SubscriptionOnboardingFlow = withLocalization<SubscriptionOnboardingFlowProps>(({ onBack, onClose }) => {
  const { Paths, goToSubscriptionUpgrade, goToSubscriptionHome } = useSubscriptionOnboardingFlow();

  return (
    <Routes>
      <Route
        path={Paths.Home}
        element={withOutlet(
          <SubscriptionActivity onBack={onBack} onClose={onClose} onDone={goToSubscriptionUpgrade} />
        )}
      >
        <Route
          path={Paths.SubscriptionUpgrade}
          element={
            <RouteElement
              component={SubscriptionUpgradeDialog}
              pathToProps={{ currentPlanId: 'currentPlanId', planId: 'planId' }}
              isOpen
              onClose={() => null}
              onDismiss={() => goToSubscriptionHome({ replace: true })}
              billingCycle={SubscriptionBillingCycleEnum.Monthly}
              returnUrl={Paths.Home}
            />
          }
        />
      </Route>
    </Routes>
  );
});

export const useSubscriptionOnboardingFlow = () => {
  enum Paths {
    Home = '',
    SubscriptionUpgrade = 'upgrade/:currentPlanId/:planId',
  }

  const { navigate, createCallback } = useFlowRouting<Paths>({ withSearchparams: true });

  return {
    Paths: addWildcardToRoutes(Paths),
    goToSubscriptionHome: createCallback(Paths.Home),
    goToSubscriptionUpgrade: ({ currentPlanId, planId }: PlansData) => {
      navigate(Paths.SubscriptionUpgrade, { pathParams: { currentPlanId, planId } });
    },
  };
};

type SubscriptionUpgradeModalActivityProps = Parameters<typeof SubscriptionUpgradeModalActivity>[0];

type SubscriptionUpgradeModalProps = Omit<SubscriptionUpgradeModalActivityProps, 'planUpgradeProps'> &
  SubscriptionUpgradeModalActivityProps['planUpgradeProps'];

const SubscriptionUpgradeDialog = forwardRef<SubscriptionUpgradeModalProps>(
  ({ currentPlanId, planId, billingCycle, returnUrl, ...props }, ref) => (
    <SubscriptionUpgradeModalActivity
      {...props}
      ref={ref}
      planUpgradeProps={{ billingCycle, returnUrl, currentPlanId, planId }}
    />
  )
);
