import { useTbt } from '@melio/form-controls';
// eslint-disable-next-line import/no-deprecated
import { Group, LoadingContainer, NakedButton, useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { CardParams, FundingSource, FundingSourceType, useFundingSources } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { groupBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useLocation } from 'react-router-dom';

import { Plan, SubscriptionBillingCycleEnum } from '../../../../../../api';
import { FundingSourceGroupList } from '../../../../../components';
import { useSubscriptionRouter } from '../../../../../utils/useSubscriptionRouter';
import { CardFundingSourceFormFields } from '../../CardFundingSourceForm/types';
import { AddNewFundingSourceView } from './AddNewFundingSourceView';

export type SubscriptionCheckoutFundingSourceSelectionProps = {
  fundingSourceId: string | null;
  selectedPlan?: Plan;
  selectedBillingCycle?: SubscriptionBillingCycleEnum;
  promoCode?: string;
  setFundingSourceId: (id: string) => void;
  onClose?: () => void;
  onPaymentMethodViewChanged?: (view: PaymentMethodView) => void;
};

export enum PaymentMethodView {
  ALL = 'all',
  ADD_NEW = 'add-new',
}

export const SubscriptionPaymentMethod = withAnalyticsContext<SubscriptionCheckoutFundingSourceSelectionProps>(
  ({
    fundingSourceId,
    selectedPlan,
    selectedBillingCycle,
    promoCode,
    setFundingSourceId,
    setAnalyticsProperties,
    onClose,
    onPaymentMethodViewChanged,
  }) => {
    const { track, trackMarketing } = useAnalytics();
    const { goToAddBankAccount } = useSubscriptionRouter();
    const { formatMessage } = useMelioIntl();
    // eslint-disable-next-line import/no-deprecated
    const { toast } = useToast();
    const { bt, tokenize } = useTbt();
    const location = useLocation();

    const [fundingSourceType, setFundingSourceType] = useState<FundingSourceType>(FundingSourceType.Card);
    const [view, setView] = useState<PaymentMethodView>(PaymentMethodView.ADD_NEW);

    setAnalyticsProperties({
      PageName: 'checkout',
      Flow: 'subscription',
    });

    const {
      data: fundingSources,
      isFetching: isFetchingFundingSources,
      verifyCard,
      create,
      refetch,
    } = useFundingSources();

    useEffect(() => {
      onPaymentMethodViewChanged?.(view);
    }, [view, onPaymentMethodViewChanged]);

    const defaultView = PaymentMethodView.ALL;

    const handleSelectFundingSource = useCallback(
      (fundingSource: FundingSource) => {
        setFundingSourceId(fundingSource.id);
        setView(defaultView);
      },
      [defaultView, setFundingSourceId, setView]
    );

    const fundingSource = useMemo(
      () => fundingSources?.find((x) => x.id === fundingSourceId) || fundingSources?.[0],
      [fundingSourceId, fundingSources]
    );

    useEffect(() => {
      if (fundingSource) {
        handleSelectFundingSource(fundingSource);
      }
    }, [fundingSource, handleSelectFundingSource]);

    const [isVerifyingCard, setIsVerifyingCard] = useState(false);

    const trackCreateCardResult = ({
      status: statusType,
      error: errorType,
      fundingSourceId,
    }: {
      status: 'success' | 'failure';
      fundingSourceId?: string;
      error?: string;
    }) => {
      track('PaymentMethod', 'Status', {
        Intent: 'add-fs',
        StatusType: statusType,
        FundingSourceId: fundingSourceId,
        ...(errorType ? { ErrorType: errorType } : {}),
      });

      if (statusType === 'success') {
        trackMarketing('add-card_done-adding-new-card');
      }
    };

    const createNewFundingSourceAndRefetch = async (fields: CardFundingSourceFormFields, cardParams?: CardParams) => {
      if (cardParams) {
        const { firstName, lastName, postalCode, state, line1, city } = fields;
        const address = { postalCode, state, line1, city };
        const details = { ...cardParams, cardOwner: { firstName, lastName }, address };
        const result = await create({ type: 'card', details });

        setFundingSourceId(result.id);

        trackCreateCardResult({ status: 'success', fundingSourceId: result.id });

        await refetch();
      }
    };

    const onCreateCardDetailsDone = async (fields: CardFundingSourceFormFields) => {
      const errorMessage = formatMessage('activities.subscription.checkout.addCardFailed');

      if (bt) {
        try {
          const { cardNumber, cardExpiration, cardVerificationCode } = fields;

          setIsVerifyingCard(true);

          const cardParams = await tokenize({
            cardNumber,
            cardExpiration,
            cardVerificationCode,
          });

          try {
            await verifyCard({
              cardBin: cardParams.cardBin,
              tabapayToken: cardParams.tabapayToken,
              tokenProvider: 'basistheory',
            });

            await createNewFundingSourceAndRefetch(fields, cardParams);
          } catch (error) {
            trackCreateCardResult({ status: 'failure', error: errorMessage });

            toast({
              type: 'error',
              title: errorMessage,
            });
          } finally {
            setIsVerifyingCard(false);
          }
        } catch (error) {
          trackCreateCardResult({ status: 'failure', error: errorMessage });

          toast({
            type: 'error',
            title: errorMessage,
          });
          setIsVerifyingCard(false);
        }
      }
    };

    const handleNewCardSelection = () => {
      track('Organization', 'Click', {
        Intent: 'choose-pm',
        Cta: 'credit-debit-card',
      });
      setFundingSourceType(FundingSourceType.Card);
      setView(PaymentMethodView.ADD_NEW);
    };

    const navigateToAddBankAccount = () => {
      const searchParams = createSearchParams({
        ...(selectedPlan && { plan: selectedPlan?.id }),
        ...(selectedBillingCycle && { billingCycle: selectedBillingCycle }),
        ...(fundingSourceId && { fundingSourceId }),
        ...(promoCode && { utm_promo: promoCode }),
      });
      const searchParamsString = searchParams.toString();
      const returnUrl = searchParamsString ? `${location.pathname}?${searchParamsString}` : location.pathname;

      return goToAddBankAccount({ returnUrl });
    };

    const handleNewBankAccountSelection = () => {
      track('Organization', 'Click', {
        Intent: 'choose-pm',
        Cta: 'bank-account',
      });
      setFundingSourceType(FundingSourceType.BankAccount);
      navigateToAddBankAccount();
    };

    const onSubmit = (fields: CardFundingSourceFormFields) => {
      onCreateCardDetailsDone(fields).catch((error) => {
        Logger.log(`Error: ${error as string}`, 'error');
      });
    };

    const fundingSourcesGroupedByType = useMemo(() => groupBy(fundingSources, 'type'), [fundingSources]);

    const getView = () => {
      switch (view) {
        case PaymentMethodView.ALL:
          return (
            <Group variant="vertical" width="full">
              {onClose && (
                <Group justifyContent="flex-end">
                  <NakedButton
                    label={formatMessage('activities.subscription.checkout.fundingSourceSelection.button.close')}
                    variant="secondary"
                    onClick={onClose}
                  />
                </Group>
              )}
              <FundingSourceGroupList
                fundingSourcesGroupedByType={fundingSourcesGroupedByType}
                onSelected={handleSelectFundingSource}
                selectedId={fundingSourceId}
                onAddBank={navigateToAddBankAccount}
                onAddCard={() => setView(PaymentMethodView.ADD_NEW)}
              />
            </Group>
          );
        case PaymentMethodView.ADD_NEW:
          return (
            <AddNewFundingSourceView
              fundingSourceType={fundingSourceType}
              onSubmit={onSubmit}
              onAddBank={handleNewBankAccountSelection}
              onAddCard={handleNewCardSelection}
              onClose={() => setView(defaultView)}
              isVerifyingCard={isVerifyingCard}
              hasFundingSources={!!fundingSources?.length}
            />
          );
      }
    };

    return <LoadingContainer isLoading={isFetchingFundingSources}>{getView()}</LoadingContainer>;
  }
);
