import { addWildcardToRoutes, useCurrentRoute, useFlowRouting } from '@melio/ar-domain';
import { useAnalytics } from '@melio/platform-analytics';
import { PartnerName } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import { useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';

import { PaymentOptionsTriageActivity } from '../activities';
import { GuestPayorFundingSourceTypes } from '../types';
import { BankPaymentFulfillmentFlow } from './BankPaymentFulfillment.flow';
import { CardPaymentFulfillmentFlow } from './CardPaymentFulfillment.flow';

export type PaymentFulfillmentFlowProps = {
  paymentRequestLink: string;
  onViewInvoice: VoidFunction;
  partnerName: PartnerName;
  onLoggedIn?: (accessToken: string, refreshToken?: string | null) => Promise<unknown>;
  isNavigateApUsersToDashboardEnabled?: boolean;
  onDone: (paymentId: string) => unknown;
  onClose: VoidFunction;
};

export const PaymentFulfillmentFlow = forwardRef<PaymentFulfillmentFlowProps>(
  ({
    onLoggedIn,
    partnerName,
    paymentRequestLink,
    onViewInvoice,
    isNavigateApUsersToDashboardEnabled,
    onDone,
    onClose,
  }) => {
    const { track } = useAnalytics();
    const Router = useGuestPaymentRouterFlow();

    const onSelectFundingSource = useCallback(
      (type: GuestPayorFundingSourceTypes) => {
        track('PaymentRequest', 'Click', {
          Intent: 'choose-payment-method',
          PaymentMethodType: type === 'card' ? 'card' : 'ach',
          Cta: type === 'card' ? 'card' : 'ach',
        });
        if (type === 'card' && Router.currentRoute != 'Card') return Router.goToCard();
        if (type === 'bank' && Router.currentRoute != 'Bank') return Router.goToBank();
      },
      [Router.currentRoute] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
      <Routes>
        <Route
          path={Router.Paths.InitialRouting}
          element={
            <PaymentOptionsTriageActivity
              currentRoute={Router.currentRoute == 'Bank' ? 'Bank' : Router.currentRoute == 'Card' ? 'Card' : undefined}
              paymentRequestLink={paymentRequestLink}
              isNavigateApUsersToDashboardEnabled={isNavigateApUsersToDashboardEnabled}
              onError={onClose}
              onDone={(next) => {
                switch (next) {
                  case 'card':
                    return Router.goToCard({ replace: true });
                  case 'bank':
                    return Router.goToBank({ replace: true });
                }
              }}
            />
          }
        >
          <Route
            path={Router.Paths.Bank}
            element={
              <BankPaymentFulfillmentFlow
                partnerName={partnerName}
                paymentRequestLink={paymentRequestLink}
                isNavigateApUsersToDashboardEnabled={isNavigateApUsersToDashboardEnabled}
                onDone={onDone}
                onLoggedIn={onLoggedIn}
                onViewInvoice={onViewInvoice}
                onSelectFundingSource={onSelectFundingSource}
              />
            }
          />
          <Route
            path={Router.Paths.Card}
            element={
              <CardPaymentFulfillmentFlow
                partnerName={partnerName}
                paymentRequestLink={paymentRequestLink}
                isNavigateApUsersToDashboardEnabled={isNavigateApUsersToDashboardEnabled}
                onDone={onDone}
                onLoggedIn={onLoggedIn}
                onViewInvoice={onViewInvoice}
                onSelectFundingSource={onSelectFundingSource}
              />
            }
          />
        </Route>
      </Routes>
    );
  }
);
const useGuestPaymentRouterFlow = () => {
  enum Paths {
    InitialRouting = '',
    Bank = 'bank',
    Card = 'card',
  }
  const { createCallback } = useFlowRouting<Paths>({ keepSystemMessage: true });

  return {
    currentRoute: useCurrentRoute(Paths),
    Paths: addWildcardToRoutes(Paths),
    goToBank: createCallback(Paths.Bank),
    goToCard: createCallback(Paths.Card),
  };
};
