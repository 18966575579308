import { FieldValues, OnSubmissionStateChange } from '@melio/penny';

export const enhanceSubmissionStateChange =
  <T extends FieldValues>({
    onSubmissionStateChange,
    enableSubmitButton,
  }: {
    onSubmissionStateChange: OnSubmissionStateChange<T>;
    enableSubmitButton: boolean;
  }): OnSubmissionStateChange<T> =>
  ({ submitButtonProps, ...restFormResults }) => {
    onSubmissionStateChange({
      ...restFormResults,
      submitButtonProps: {
        ...submitButtonProps,
        isDisabled: enableSubmitButton ? false : submitButtonProps.isDisabled,
      },
    });
  };
