import { Container, Group, Icon, Link, Text } from '@melio/penny';

export const PlanDetailsRow = ({
  label,
  description,
  actionLink,
  testIdSuffix,
}: {
  label: string;
  description: string;
  actionLink?: {
    onClick?: (e: React.MouseEvent<HTMLLinkElement>) => void;
    label: string;
  };
  testIdSuffix?: string;
}) => (
  <Group width="full" variant="horizontal" alignItems="flex-start" spacing="s-m" as="li">
    <Icon type="checked-circle" aria-hidden="true" />
    <Group variant="vertical" data-testid={`subscription-plan-details-row-${testIdSuffix ?? ''}`} spacing="xxs">
      <Text>{label}</Text>

      <Text textStyle="body4" color="global.neutral.700">
        {description}
      </Text>

      {actionLink?.onClick && (
        <Container>
          <Text textStyle="body4Semi">
            <Link href="#" onClick={actionLink.onClick} label={actionLink.label} color="secondary" />
          </Text>
        </Container>
      )}
    </Group>
  </Group>
);
