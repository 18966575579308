export const onDownloadCSVTemplateFile = () => {
  const csvHeaders = 'name,price\n';
  const csvRows = [
    ['Logo Design', '1.17'],
    ['4x4 Stickers', '2000.00'],
  ];

  const csvContent = csvHeaders + csvRows.map((row) => row.join(',')).join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', 'catalog_items_template.csv');
  link.style.visibility = 'hidden';

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};
