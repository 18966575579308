import { useMelioIntl } from '@melio/ar-domain';
import { useState } from 'react';

import { ScreenId, TrackerStepData } from '../utils';

type OnboardingTracker = {
  steps: TrackerStepData[];
  currentStepIndex: number;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  goToStepById: (screenId: ScreenId) => void;
};

type OnboardingTrackerProps = {
  screenId?: ScreenId;
};

export const useOnboardingStepsTracker = ({ screenId }: OnboardingTrackerProps = {}): OnboardingTracker => {
  const { formatMessage } = useMelioIntl();

  const onboardingSteps: TrackerStepData[] = [
    {
      screenId: 'business-details',
      title: formatMessage('ar.onboarding.tracker.step.title.BusinessDetails.text'),
      description: formatMessage('ar.onboarding.tracker.step.description.BusinessDetails'),
    },
    {
      screenId: 'financial-information',
      title: formatMessage('ar.onboarding.tracker.step.title.FinancialDetails.text'),
      description: formatMessage('ar.onboarding.tracker.step.description.FinancialDetails'),
    },
    {
      screenId: 'ownership-details',
      title: formatMessage('ar.onboarding.tracker.step.title.BusinessOwnership.text'),
      description: formatMessage('ar.onboarding.tracker.step.description.BusinessOwnership'),
    },
    {
      screenId: 'review-and-approve',
      title: formatMessage('ar.onboarding.tracker.step.title.LegalAgreements.text'),
      description: formatMessage('ar.onboarding.tracker.step.description.LegalAgreements'),
    },
    {
      screenId: 'receiving-account',
      title: formatMessage('ar.onboarding.tracker.step.title.ReceivingAccount.text'),
      description: formatMessage('ar.onboarding.tracker.step.description.ReceivingAccount'),
    },
    {
      screenId: 'subscription',
      title: formatMessage('ar.onboarding.tracker.step.title.UpgradePlan.text'),
      description: formatMessage('ar.onboarding.tracker.step.description.UpgradePlan'),
    },
  ];

  const stepIndexMap: Record<(typeof onboardingSteps)[number]['screenId'], number> = {} as Record<
    (typeof onboardingSteps)[number]['screenId'],
    number
  >;

  onboardingSteps.forEach((step, index) => {
    stepIndexMap[step.screenId] = index;
  });

  const initialStepIndex = screenId ? stepIndexMap[screenId] : -1;

  const [currentStep, setCurrentStep] = useState<number>(initialStepIndex);

  const goToNextStep = () => {
    if (currentStep < onboardingSteps.length) {
      setCurrentStep((currentStep) => currentStep + 1);
    } else {
      throw new Error('step not found');
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep((currentStep) => currentStep - 1);
    } else {
      throw new Error('step not found');
    }
  };

  const goToStepById = (screenId: ScreenId) => {
    const stepIndex = stepIndexMap[screenId];
    if (stepIndex) {
      setCurrentStep(stepIndex);
    } else {
      throw new Error('Step not found');
    }
  };

  return {
    steps: onboardingSteps,
    currentStepIndex: currentStep,
    goToNextStep,
    goToPreviousStep,
    goToStepById,
  };
};
