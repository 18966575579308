import { FormField, IconButton, Tooltip } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type DeleteLineItemProps = {
  IndexNumber: number;
  testId: string;
  onClick: VoidFunction;
  ariaLabel?: string;
};

export const DeleteLineItem = ({ IndexNumber, testId, onClick, ariaLabel }: DeleteLineItemProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <FormField
      labelProps={{ label: '', isHidden: true }}
      render={() => (
        <Tooltip content={formatMessage('activities.addBillV2.lineItems.delete.label')}>
          <IconButton
            data-testid={`${testId}-${IndexNumber}`}
            icon="delete"
            onClick={onClick}
            size="medium"
            variant="naked"
            aria-label={ariaLabel ?? formatMessage('activities.addBillV2.lineItems.delete.defaultAreaLabel')}
          />
        </Tooltip>
      )}
    />
  );
};
