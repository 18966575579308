import { useMelioIntl } from '@melio/ar-domain';
import { Group, useFormSubmissionController } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { Footer, OnboardingLayout } from '../../../layouts';
import { FinancialDetailsHeader, FinancialInformationForm } from '../components';
import { FinancialInformationFormFields } from '../types';

export type FinancialInformationScreenProps = {
  onSubmit: (data: FinancialInformationFormFields) => unknown;
  isLoading: boolean;
  isSaving: boolean;
  onBack: VoidFunction;
  defaultValues?: Partial<FinancialInformationFormFields>;
  error?: ARPlatformError;
  onClose?: VoidFunction;
};

export const FinancialInformationScreen = forwardRef<FinancialInformationScreenProps>(
  ({ isLoading, isSaving, onSubmit, onBack, onClose, defaultValues }) => {
    const { formatMessage } = useMelioIntl();

    const { onSubmissionStateChange, submitButtonProps } =
      useFormSubmissionController<FinancialInformationFormFields>();

    return (
      <OnboardingLayout
        isLoading={isLoading}
        screenId="financial-information"
        headerContent={formatMessage('ar.onboarding.layout.header.text')}
        footerContent={
          <Footer
            primaryButtonProps={{
              label: formatMessage('ar.onboarding.layout.buttons.continue.label'),
              ...submitButtonProps,
            }}
            secondaryButtonProps={{
              label: formatMessage('ar.onboarding.layout.buttons.back.label'),
              onClick: onBack,
              isDisabled: isSaving,
            }}
            tertiaryButtonProps={{
              label: formatMessage('ar.onboarding.layout.buttons.cancel.label'),
              onClick: onClose,
              isDisabled: isSaving,
            }}
          />
        }
        isStickyFooter
        data-component={FinancialInformationScreen.displayName}
        data-testid="financial-information-screen"
      >
        <Group variant="vertical" width="full" spacing="xl">
          <FinancialDetailsHeader />
          <FinancialInformationForm
            defaultValues={defaultValues}
            isSaving={isSaving}
            isLoading={isLoading}
            onSubmissionStateChange={onSubmissionStateChange}
            onSubmit={onSubmit}
          />
        </Group>
      </OnboardingLayout>
    );
  }
);
FinancialInformationScreen.displayName = 'FinancialInformationScreen';
