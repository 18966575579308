import { Outlet } from 'react-router-dom';
import { useConfig } from '@melio/platform-provider';

import { AppLayout } from '@/cl/layouts/app-layout/appLayout.component';
import { usePartnerConfig } from '@/hooks/partners';

type FullScreenLayoutProps = {
  showOrgSwitcher: boolean;
  hideTopNav?: boolean;
};

export const FullScreenLayout = ({ showOrgSwitcher, hideTopNav }: FullScreenLayoutProps) => {
  const { partnerConfig } = usePartnerConfig();
  const {
    settings: { isEmbeddedExperience },
  } = useConfig();

  return (
    <AppLayout
      topNav={
        isEmbeddedExperience || hideTopNav
          ? undefined
          : {
              TopNavIcon: partnerConfig.icons.LogoIcon.small,
              TopNavSmallIcon: partnerConfig.icons.LogoIcon.extraSmall,
              showOrgSwitcher,
            }
      }
    >
      <Outlet />
    </AppLayout>
  );
};
