import {
  BillSubscription,
  DeliveryMethod,
  FundingSource,
  InvoiceDataSourceEntityTypeEnum,
  useOrgBillingFeeSettings,
  usePaymentSettings,
  Vendor,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { converDateToStringRepresentation } from '@melio/platform-utils';
import { pick } from 'lodash';

import { convertPaymentFeeCentsToDollars } from '../../functions';
import { useTotalFees } from './fees';
import { usePaymentFeesDetails } from './usePaymentFeesDetails';

export type UseRecurringPaymentFeesDetailsProps = {
  vendorId: Vendor['id'];
  fundingSourceId: FundingSource['id'];
  deliveryMethodId: DeliveryMethod['id'];
  invoiceNumber: BillSubscription['invoiceNumber'];
  billSubscriptionId: BillSubscription['id'];
  amount: number;
  lastAmount?: number | null;
  startDate: Date;
};

export const useRecurringPaymentFeesDetails = (recurringPayment: UseRecurringPaymentFeesDetailsProps) => {
  const [isBillingFeeSettingsEnabled] = useDevFeature(FeatureFlags.BillingFees, false);

  const { data: orgBillingFeeSettings = [], isLoading: isBillingFeeSettingsLoading } = useOrgBillingFeeSettings({
    enabled: isBillingFeeSettingsEnabled,
  });

  const recurringPaymentsData = {
    ...pick(recurringPayment, ['vendorId', 'amount', 'fundingSourceId', 'deliveryMethodId', 'invoiceNumber']),
    invoiceNumber: recurringPayment.invoiceNumber || null,
    sourceEntity: {
      id: recurringPayment.billSubscriptionId,
      type: InvoiceDataSourceEntityTypeEnum.BillSubscription,
    },
    dueDate: recurringPayment.startDate,
  };

  const {
    data: paymentsSettings,
    isLoading: isLoadingPaymentSettings,
    error: paymentsSettingsError,
  } = usePaymentSettings({
    payload: [
      {
        dueDate: converDateToStringRepresentation(recurringPaymentsData.dueDate), // TODO: this conversion should be on the API level
        amountToPay: recurringPaymentsData.amount,
        vendorId: recurringPaymentsData.vendorId,
        fundingSourceId: recurringPaymentsData.fundingSourceId,
        deliveryMethodId: recurringPaymentsData.deliveryMethodId,
        invoicesData: [
          {
            sourceEntity: recurringPaymentsData.sourceEntity,
            invoiceNumber: recurringPaymentsData.invoiceNumber,
            amount: recurringPaymentsData.amount,
          },
        ],
      },
    ],
  });

  const {
    data: lastPaymentSettings,
    isLoading: isLoadingLastPaymentsSettings,
    error: lastPaymentSettingsError,
  } = usePaymentSettings({
    payload: recurringPayment.lastAmount
      ? [
          {
            ...pick(recurringPaymentsData, 'vendorId'),
            dueDate: converDateToStringRepresentation(recurringPaymentsData.dueDate), // TODO: this conversion should be on the API level
            amountToPay: recurringPayment.lastAmount,
            fundingSourceId: recurringPayment.fundingSourceId,
            deliveryMethodId: recurringPayment.deliveryMethodId,
            invoicesData: [
              {
                invoiceNumber: recurringPayment.invoiceNumber || null,
                amount: recurringPayment.lastAmount,
                sourceEntity: {
                  id: recurringPayment.billSubscriptionId,
                  type: InvoiceDataSourceEntityTypeEnum.BillSubscription,
                },
              },
            ],
          },
        ]
      : [],
    enabled: !!recurringPayment.lastAmount,
  });

  const {
    feeItems,
    error: paymentFeesError,
    isLoading: isLoadingPaymentFeesDetails,
  } = usePaymentFeesDetails({
    ...recurringPaymentsData,
    deliveryPreferenceType: paymentsSettings?.[0]?.deliveryPreferenceOptions?.[0]?.type,
  });

  const lastPaymentFees = convertPaymentFeeCentsToDollars(lastPaymentSettings?.[0]?.estimatedFees || []); // TODO: this conversion should be on the API level

  return {
    isLoading:
      isLoadingPaymentFeesDetails ||
      isLoadingLastPaymentsSettings ||
      isBillingFeeSettingsLoading ||
      isLoadingPaymentSettings,
    error: paymentFeesError || lastPaymentSettingsError || paymentsSettingsError,
    feeItems,
    total: useTotalFees({
      transactionFees: feeItems || [],
      orgBillingFeeSettings,
      recurringLastAmountFees: lastPaymentFees,
    }),
  };
};
