import { usePaymentProcessingInfo } from '@melio/ap-domain';
import { Container, Text } from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

export const PaymentScheduledRiskDisclaimer = ({ vendors }: { vendors: Vendor[] }) => {
  const { formatMessage } = useMelioIntl();
  const [showDisclaimerEnabled] = useDevFeature(FeatureFlags.ShowPaymentScheduledDisclaimer, false);
  const { isPaymentProcessedByFiserv } = usePaymentProcessingInfo();

  const getDisclaimMessage = () => {
    if (vendors.some(isPaymentProcessedByFiserv)) {
      return formatMessage('activities.paymentScheduled.screens.paymentScheduled.disclaimer.fiserv');
    }
    return formatMessage('activities.paymentScheduled.screens.paymentScheduled.disclaimer');
  };

  return showDisclaimerEnabled ? (
    <Container textAlign="center">
      <Text textStyle="caption1" data-testid="payment-scheduled-disclaimer">
        {getDisclaimMessage()}
      </Text>
    </Container>
  ) : (
    <></>
  );
};
