import { useMelioIntl } from '@melio/ar-domain';
import { Link, SectionBanner } from '@melio/penny';
import { MouseEventHandler } from 'react';

type SignInBannerProps = {
  onSignInClick: VoidFunction;
};

export const SignInBanner: React.FC<SignInBannerProps> = ({ onSignInClick }) => {
  const { formatMessage } = useMelioIntl();

  const handleClick: MouseEventHandler<HTMLLinkElement> = (e) => {
    e.preventDefault();
    onSignInClick();
  };

  return (
    <SectionBanner
      data-testid="sign-in-banner"
      variant="informative"
      description={formatMessage('ar.guestPayment.activities.cardHolder.signInBanner.label')}
      action={
        <Link
          href="#"
          size="medium"
          variant="standalone"
          data-testid="sign-in-banner-link"
          onClick={handleClick}
          label={formatMessage('ar.guestPayment.activities.cardHolder.signInBanner.link')}
        />
      }
    />
  );
};

SignInBanner.displayName = 'SignInBanner';
