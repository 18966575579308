import { useMelioQueryClient } from '@melio/api-client';
import {
  ApiKeys,
  PatchVendorGroupsRequest,
  VendorGroup,
  VendorGroupsApiClient,
} from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';

export const useUpdateVendorGroup = () => {
  const queryClient = useMelioQueryClient();

  return useMutation({
    mutationKey: ApiKeys.VendorGroupsApi,
    mutationFn: async ({
      vendorGroupId,
      fields,
    }: {
      vendorGroupId: VendorGroup['id'];
      fields: PatchVendorGroupsRequest;
    }) => {
      const response = await VendorGroupsApiClient.patchVendorGroupsVendorGroupId(fields, vendorGroupId);

      return response.data.data;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(ApiKeys.VendorGroupsApi);
      void queryClient.invalidateQueries(ApiKeys.VendorsApi);
    },
  });
};
