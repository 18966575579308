/* eslint-disable no-restricted-syntax */
import { Payment } from '@melio/platform-api';
import { differenceInCalendarDays } from 'date-fns';
import { useCallback } from 'react';

export const usePaymentsLateApprovals = () => {
  const getDebitDateData = useCallback((scheduledDate: Date) => {
    const daysAfterToday = differenceInCalendarDays(scheduledDate, new Date());
    return { isDebitDateAfterToday: daysAfterToday < 0, isDebitDateToday: daysAfterToday === 0 };
  }, []);

  return useCallback(
    ({ payments }: { payments: Payment[] }) =>
      payments.reduce((acc, payment) => {
        acc[payment.id] = {
          ...getDebitDateData(payment.scheduledDate),
        };
        return acc;
      }, {} as { [paymentId: string]: { isDebitDateAfterToday: boolean; isDebitDateToday: boolean } }),
    [getDebitDateData]
  );
};
