import { useInvoice, useMelioIntl, useSystemMessage } from '@melio/ar-domain';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef, useDateUtils } from '@melio/platform-utils';
import { useMemo } from 'react';

import { MarkAsPaidFormField } from '../../components';
import { MarkInvoiceAsPaidScreen } from './screens';

type MarkInvoiceAsPaidActivityProps = {
  onClose: VoidFunction;
  onDone: VoidFunction;
  isOpen: boolean;
  onError?: ARErrorFunction;
  invoiceId: string;
};

export const MarkInvoiceAsPaidActivity = withAnalyticsContext<MarkInvoiceAsPaidActivityProps>(
  forwardRef(({ setAnalyticsProperties, onClose, onDone, onError, isOpen, invoiceId, ...props }, ref) => {
    const { data: invoice, isFetching, isMutating, markAsPaid } = useInvoice({ id: invoiceId });

    const { formatMessage } = useMelioIntl();
    // eslint-disable-next-line import/no-deprecated
    const { triggerMessage } = useSystemMessage();
    const { createDate } = useDateUtils();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const currentDate = useMemo(() => createDate(), []);

    const { track } = useAnalytics();

    setAnalyticsProperties({
      InvoiceId: invoice?.id,
      InvoiceStatus: invoice?.displayStatus,
      DueDate: invoice?.dueDate,
    });

    const handleSubmit = ({ paymentDate, paymentMethod, note, referenceNumber }: MarkAsPaidFormField) => {
      markAsPaid({
        paidAt: paymentDate,
        method: paymentMethod,
        note,
        referenceNumber,
      })
        .then(() => {
          track('Invoice', 'Status', {
            StatusType: 'success',
            ProductName: 'ar',
            PageName: 'mark-invoice-as-paid',
            Flow: 'manage-invoice',
            Intent: 'mark-as-paid',
            Cta: 'mark-as-paid',
            PaymentDate: paymentDate,
            PaymentMethodType: paymentMethod,
            PaymentNote: note,
            ReferenceNumber: referenceNumber,
          });
          triggerMessage({
            type: 'success',
            title: formatMessage(
              'ar.dashboard.activities.invoiceTable.actions.markAsPaid.systemMessage.success.label',
              {
                InvoiceNumber: invoice?.invoiceNumber,
              }
            ),
          });
          onDone();
        })
        .catch((err: ARPlatformError) => {
          track('Invoice', 'Status', {
            StatusType: 'failure',
            ProductName: 'ar',
            PageName: 'mark-invoice-as-paid',
            Flow: 'manage-invoice',
            Intent: 'mark-as-paid',
            Cta: 'mark-as-paid',
            PaymentDate: paymentDate,
            PaymentMethodType: paymentMethod,
            PaymentNote: note,
            ReferenceNumber: referenceNumber,
          });
          onError?.(err);
        });
    };

    return (
      <MarkInvoiceAsPaidScreen
        onClose={() => {
          track('Invoice', 'Click', {
            ProductName: 'ar',
            PageName: 'mark-invoice-as-paid',
            Flow: 'manage-invoice',
            Intent: 'cancel-mark-as-paid',
            Cta: 'cancel/close',
          });
          onClose();
        }}
        onSubmit={(markAsPaidFormField: MarkAsPaidFormField) => {
          track('Invoice', 'Click', {
            ProductName: 'ar',
            PageName: 'mark-invoice-as-paid',
            Flow: 'manage-invoice',
            Intent: 'mark-as-paid',
            Cta: 'mark-as-paid',
            PaymentDate: markAsPaidFormField.paymentDate,
            PaymentMethodType: markAsPaidFormField.paymentMethod,
            PaymentNote: !!markAsPaidFormField.note,
            ReferenceNumber: markAsPaidFormField.referenceNumber,
          });
          handleSubmit(markAsPaidFormField);
        }}
        isOpen={isOpen}
        isLoading={isFetching}
        isSaving={isMutating}
        amount={invoice?.totalAmount || 0}
        paymentDate={currentDate}
        ref={ref}
        {...props}
      />
    );
  })
);

MarkInvoiceAsPaidActivity.displayName = 'MarkInvoiceAsPaidActivity';
