import { SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const SubscriptionPaymentPendingBanner = () => {
  const { formatMessage } = useMelioIntl();

  return (
    <SectionBanner
      title={formatMessage('widgets.subscription.paymentPendingBanner.title')}
      description={formatMessage('widgets.subscription.paymentPendingBanner.description')}
      variant="informative"
      data-testid="subscription-payment-pending-banner"
    />
  );
};
