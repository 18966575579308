import { ActionsDropdownMenu, Badge, Button, Icon, StatusIndicator } from '@melio/penny';
import { useState } from 'react';

import { useDateRangeFilter } from '../hooks';
import { DateRange, DateRangeFilterTypes } from '../types';

type DateRangeFilterProps = {
  type: DateRangeFilterTypes;
  selectedFilter?: DateRange;
  onApply: (dateRange: DateRange) => void;
};

export const DateRangeFilter = ({ type, onApply, selectedFilter }: DateRangeFilterProps) => {
  const { presets, getPresetRange, getPresetLabel, filterLabel } = useDateRangeFilter(type);
  const [isOpen, setIsOpen] = useState(false);

  const triggerElement = (
    <Badge
      mark={selectedFilter ? <StatusIndicator status="success" /> : undefined}
      placement="top-right"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-next-line - badge should get tab index -1 https://meliorisk.atlassian.net/browse/ME-78926
      tabIndex={-1}
    >
      <Button
        data-testid={`ar-dashboard-filter-menu-${type}`}
        onClick={() => setIsOpen(!isOpen)}
        variant="tertiary"
        label={filterLabel}
        rightElement={<Icon size="small" type={isOpen ? 'caret-up' : 'caret-down'} color="inherit" aria-hidden />}
      />
    </Badge>
  );

  const actions = presets.map((preset) => ({
    label: getPresetLabel(preset),
    onClick: () => {
      if (preset === 'Custom') {
        return;
      }
      const dateRange = getPresetRange(preset);
      onApply(dateRange);
    },
  }));

  return <ActionsDropdownMenu isOpen={isOpen} onOpenChange={setIsOpen} trigger={triggerElement} items={actions} />;
};
