import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { EbillsImportRedirectPage } from '@melio/ap-activities';
import { PartnerName } from '@melio/platform-api';
import { SubscriptionAppFlows } from '@melio/subscriptions';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { ApConfigurationProvider } from '@/cl/components/PartnerApp/app-providers/ApConfigurationProvider.component';
import { VexConfigurationProvider } from '@/cl/components/PartnerApp/app-providers/VexConfigurationProvider.component';
import { RouterComponentWrapper } from '@/hoc/routerComponentWrapper';
import { DashboardLayout } from '@/router/layouts/Dashboard.layout';
import { FullScreenLayout } from '@/router/layouts/FullScreen.layout';
import { OnlyFooterLayout } from '@/router/layouts/OnlyFooter.layout';
import { RestrictedToAccountingFirmOnly, WithAccessToFirm } from '@/router/routes/Access.route';
import { DemoRoutesAccess } from '@/router/routes/Access.route';
import { AccountantsRouter } from '@/router/routes/accountants/Accountants.router';
import { AccountingSoftwareRoute } from '@/router/routes/Accountingsoftware.route';
import { AppRedirectRoute } from '@/router/routes/AppRedirect.route';
import { ApprovalWorkflowsRoute } from '@/router/routes/ApprovalWorkflows.route';
import { AuthRoute } from '@/router/routes/Auth.route';
import { AuthActionRoute } from '@/router/routes/AuthAction.route';
import { AuthDemoRoute } from '@/router/routes/AuthDemo.route';
import { AuthDemoOpenIdCallback, AuthDemoOpenIdRedirect } from '@/router/routes/AuthDemoOpenId.route';
import { AuthIntuitCallbackRouteError } from '@/router/routes/AuthIntuitCallbackRouteError';
import { AuthIntuitCallbackRouteSuccess } from '@/router/routes/AuthIntuitCallbackRouteSuccess';
import { AutoPaymentActivationRoute } from '@/router/routes/AutoPaymentActivation.route';
import { BatchPaymentsRoute } from '@/router/routes/batchPayments.route';
import { BillingMethodsRoute } from '@/router/routes/BillingMethods.route';
import { BillsRoute } from '@/router/routes/Bills.route';
import { CompleteDetailsRoute } from '@/router/routes/CompleteDetails.route';
import { ContextualOnboardingRoute } from '@/router/routes/ContextualOnboarding.route';
import { EBillsSubscriptionRoute } from '@/router/routes/EBillsSubscription.route';
import { AuthEmbeddedExperienceDemoRoute as EmbeddedExperienceDemoRoute } from '@/router/routes/EmbeddedExperienceDemoRoute';
import { ErrorRoute } from '@/router/routes/Error.route';
import { ExternalEntriesRoute } from '@/router/routes/ExternalEntries.route';
import { FinancingApplicationRoute } from '@/router/routes/FinancingApplication.route';
import { NewPayDashboardRoute } from '@/router/routes/NewPayDashboard.route';
import { NewVendorRoute } from '@/router/routes/NewVendorRoute.route';
import { PayDashboardRoute } from '@/router/routes/PayDashboard.route';
import { PaymentRoute } from '@/router/routes/Payment.route';
import { PaymentMethodsRoute } from '@/router/routes/PaymentMethods.route';
import { PaymentsClassificationRoute } from '@/router/routes/PaymentsClassification.route';
import { ReceivingMethodsRoute } from '@/router/routes/ReceivingMethods.route';
import { SchedulePaymentRoute } from '@/router/routes/SchedulePayment.route';
import { StartRoute } from '@/router/routes/Start.route';
import { SubscriptionsRouter } from '@/router/routes/subscriptions/Subscriptions.router';
import { VendorDeliveryMethodRoute } from '@/router/routes/VendorDeliveryMethod.route';
import { VendorsRoute } from '@/router/routes/Vendors.route';
import { EmailVerificationRoute } from '@/router/routes/vex';
import { WithInitialData, WithTimeoutHandling } from '@/router/utils';
import { WithAnonymousInitialData, WithVendorActionsInitialData } from '@/router/utils';
import { AddCompanyScreen } from '@/screens/add-company/AddCompany.screen';
import { EditBillSubscriptionScreen } from '@/screens/edit-bill-subscription/EditBillSubscription.screen';
import { PaymentRequestEntryPointScreen } from '@/screens/payment-request-entry-point/PaymentRequestEntryPoint.screen';
import { UnilateralScreen } from '@/screens/vendors-actions/Unilateral.screen';
import { UpgradePaymentScreen } from '@/screens/vendors-actions/UpgradePayment.screen';
import { NewReviewScannedInvoiceScreen } from '@/widgets/pay-dashboard/review-scanned-invoice/NewReviewScannedInvoiceScreen.widget';

const SettingsRoute = React.lazy(() =>
  import('@/router/routes/Settings.route').then((module) => ({ default: module.SettingsRoute })),
);

const AccountsRoute = React.lazy(() =>
  import('@/router/routes/Accounts.route').then((module) => ({ default: module.AccountsRoute })),
);

export const UserRoutes = ({ partnerName }: { partnerName: PartnerName }) => (
  <Routes>
    <Route element={<VexConfigurationProvider partnerName={partnerName} />}>
      <Route path="email-verification/*" element={<EmailVerificationRoute />} />
      <Route element={<WithVendorActionsInitialData />}>
        <Route path="accept" element={<UnilateralScreen />} />
        <Route path="upgrade-payment" element={<UpgradePaymentScreen />} />
      </Route>
    </Route>
    <Route element={<ApConfigurationProvider partnerName={partnerName} />}>
      <Route element={<WithAnonymousInitialData />}>
        <Route path="start" element={<StartRoute />} />
        <Route element={<OnlyFooterLayout />}>
          <Route path="auth/action" element={<AuthActionRoute />} />
        </Route>
        <Route element={<FullScreenLayout hideTopNav={true} showOrgSwitcher={false} />}>
          <Route path="auth" element={<AuthRoute />} />
          <Route path="redirect" element={<AppRedirectRoute />} />
        </Route>
        <Route element={<OnlyFooterLayout />}>
          <Route path="*" element={<ErrorRoute />} />
        </Route>
      </Route>
      <Route element={<WithTimeoutHandling />}>
        <Route element={<WithInitialData kycComplianceChecksEnabled />}>
          <Route element={<SubscriptionAppFlows />}>
            <Route element={<DashboardLayout />}>
              <Route
                path="settings/*"
                element={
                  <React.Suspense fallback={<Loader isAbsoluteCenter />}>
                    <SettingsRoute />
                  </React.Suspense>
                }
              />
              <Route
                path="accounts/*"
                element={
                  <React.Suspense fallback={<Loader isAbsoluteCenter />}>
                    <AccountsRoute />
                  </React.Suspense>
                }
              />
              <Route element={<WithAccessToFirm />}>
                <Route path="pay/*" element={<PayDashboardRoute />} />
                <Route path="vendors/*" element={<VendorsRoute />} />
                <Route path="payment-classification" element={<PaymentsClassificationRoute />} />
                <Route path="pay-dashboard/*" element={<NewPayDashboardRoute />} />
              </Route>
            </Route>

            <Route path="complete-details/*" element={<CompleteDetailsRoute />} />
            <Route path="bills-sync-redirect" element={<EbillsImportRedirectPage />} />
            <Route
              path="payment-request/:paymentRequestId/pay/*"
              element={
                <RouterComponentWrapper
                  Component={PaymentRequestEntryPointScreen}
                  componentProps={{ paymentRequestId: { _pathParam: 'paymentRequestId' } }}
                />
              }
            />
            <Route element={<OnlyFooterLayout />}>
              <Route element={<WithAccessToFirm />}>
                <Route path="bills/*" element={<BillsRoute />} />
                <Route path="vendors/new-vendor/*" element={<NewVendorRoute />} />
                <Route path="vendors/:vendorId/delivery-methods/*" element={<VendorDeliveryMethodRoute />} />
                <Route path="vendors/:vendorId/ebills-subscription/*" element={<EBillsSubscriptionRoute />} />
                <Route path="vendors/:vendorId/auto-payment-activation/*" element={<AutoPaymentActivationRoute />} />
                <Route path="financing/*" element={<FinancingApplicationRoute />} />
                <Route path="schedule-payment/*" element={<SchedulePaymentRoute />} />
                <Route path="payment/*" element={<PaymentRoute />} />
                <Route path="batch-payments/:ids" element={<BatchPaymentsRoute />} />
                <Route path="onboarding/*" element={<ContextualOnboardingRoute />} />
                <Route path="approval-workflows/*" element={<ApprovalWorkflowsRoute />} />
                <Route path="payment-methods/*" element={<PaymentMethodsRoute />} />
                <Route path="billing-fee/*" element={<BillingMethodsRoute />} />
                <Route path="receiving-methods/*" element={<ReceivingMethodsRoute />} />
                <Route path="accounting-software/*" element={<AccountingSoftwareRoute />} />
              </Route>
              <Route path="companies/new-company/*" element={<AddCompanyScreen />} />
              <Route
                path="review-draft/new/:scannedInvoiceId"
                element={
                  <RouterComponentWrapper
                    Component={NewReviewScannedInvoiceScreen}
                    componentProps={{ scannedInvoiceId: { _pathParam: 'scannedInvoiceId' } }}
                  />
                }
              />
              <Route
                path="bill-subscription/:billSubscriptionId/edit/*"
                element={
                  <RouterComponentWrapper
                    Component={EditBillSubscriptionScreen}
                    componentProps={{ billSubscriptionId: { _pathParam: 'billSubscriptionId' } }}
                  />
                }
              />
              <Route element={<RestrictedToAccountingFirmOnly />}>
                <Route path="accountants/*" element={<AccountantsRouter />} />
              </Route>
              <Route path="auth/providers/intuit/callback/success" element={<AuthIntuitCallbackRouteSuccess />} />
              <Route path="auth/providers/intuit/callback/error" element={<AuthIntuitCallbackRouteError />} />
            </Route>
            <Route path="external-entries/*" element={<ExternalEntriesRoute />} />
            <Route path="subscription/*" element={<SubscriptionsRouter />} />
          </Route>
        </Route>
      </Route>
      <Route element={<DemoRoutesAccess />}>
        <Route
          path="embedded-experience"
          element={<RouterComponentWrapper Component={EmbeddedExperienceDemoRoute} componentProps={{}} />}
        />
        <Route
          path="auth/demo/:accountId"
          element={
            <RouterComponentWrapper
              Component={AuthDemoRoute}
              componentProps={{
                accountId: { _pathParam: 'accountId' },
              }}
            />
          }
        />
        <Route path="auth/demo-openid/callback" element={<AuthDemoOpenIdCallback />} />
        <Route
          path="auth/demo-openid/:accountId"
          element={
            <RouterComponentWrapper
              Component={AuthDemoOpenIdRedirect}
              componentProps={{
                accountId: { _pathParam: 'accountId' },
              }}
            />
          }
        />
      </Route>
    </Route>
  </Routes>
);
