import { Form, Group, Link, StatusModal, Text, useFormSubmissionController, useMelioForm } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useEBillsSubscription, useEBillsSubscriptionPaperSuppressionTerms, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useSystemMessage } from '@melio/platform-utils';
import { useEffect } from 'react';

import { MonitoredAction } from '../../../monitoring';
import { useSchema } from './schema';
import { CancelPaperEBillSubscriptionFormFields } from './types';

export const CancelPaperEBillSubscriptionModalActivity = withAnalyticsContext<{
  vendor: Vendor;
  isOpen: boolean;
  onClose: () => void;
}>(({ vendor, isOpen, onClose, setAnalyticsProperties }) => {
  const { formatMessage } = useMelioIntl();
  const { suppressPaper, isMutating } = useEBillsSubscription({ id: vendor.id, enabled: false });
  const {
    data: terms,
    isLoading: isLoadingTerms,
    isError: isLoadingTermsError,
  } = useEBillsSubscriptionPaperSuppressionTerms({ vendorId: vendor.id });
  const { track } = useAnalytics();
  const { startAction, endAction } = useMonitoring<MonitoredAction>();

  const { showMessage } = useSystemMessage();

  setAnalyticsProperties({
    PageName: 'cancel-paper-ebill-subscription',
    EntryPoint: 'vendor-summary',
    Flow: 'dashboard',
    Intent: 'cancel-paper-ebill-subscription',
    VendorId: vendor.id,
  });

  useEffect(() => {
    if (isOpen) {
      track('Vendor', 'View');
    }
  }, [track, isOpen]);

  const handleClose = () => {
    track('Vendor', 'Click', { Cta: 'exit' });
    onClose();
  };

  const onCancelPaperSubscriptionSuccess = () => {
    track('Vendor', 'Status', { Status: 'success' });
    endAction('ebills_subscription_cancel_paper');
    showMessage({
      type: 'informative',
      title: formatMessage('activities.ebillCancelPaperSubscription.toast.success'),
      dataTestId: 'toast-ebills-cancel-paper-modal-success-toast',
    });
    onClose();
  };

  const onCancelPaperSubscriptionFailure = () => {
    track('Vendor', 'Status', { Status: 'failure', ErrorType: 'ebills-subscription-paper-cancellation-failed' });
    showMessage({
      type: 'error',
      title: formatMessage('activities.ebillCancelPaperSubscription.toast.error'),
      dataTestId: 'toast-ebills-cancel-paper-modal-failure-toast',
    });
    onClose();
  };

  const onCancelPaperSubscription = async () => {
    track('Vendor', 'Click', { Cta: 'cancel-paper-subscription' });
    startAction('ebills_subscription_cancel_paper');

    try {
      await suppressPaper({ vendorId: vendor.id, acceptedTerms: true });
      onCancelPaperSubscriptionSuccess();
    } catch (e) {
      onCancelPaperSubscriptionFailure();
    }
  };

  const schema = useSchema();

  const { submitButtonProps, onSubmissionStateChange } =
    useFormSubmissionController<CancelPaperEBillSubscriptionFormFields>();

  const { registerField, formProps } = useMelioForm<CancelPaperEBillSubscriptionFormFields>({
    onSubmit: onCancelPaperSubscription,
    onSubmissionStateChange,
    schema,
    isSaving: isMutating,
    defaultValues: { acceptedTerms: false },
  });

  if (isLoadingTermsError) {
    showMessage({
      type: 'error',
      title: formatMessage('activities.ebillCancelPaperSubscription.toast.loadTermsError'),
      dataTestId: 'toast-ebills-cancel-paper-modal-failure-toast',
    });
    onClose();
  }

  return (
    <StatusModal
      variant="alert"
      isOpen={isOpen}
      onClose={handleClose}
      isLoading={isLoadingTerms}
      header={formatMessage('activities.ebillCancelPaperSubscription.modal.title')}
      primaryButton={{
        onClick: () => onCancelPaperSubscription(),
        variant: 'primary',
        label: formatMessage('activities.ebillCancelPaperSubscription.modal.action.confirm'),
        ...submitButtonProps,
      }}
      secondaryButton={{
        onClick: () => handleClose(),
        variant: 'tertiary',
        label: formatMessage('activities.ebillCancelPaperSubscription.modal.action.close'),
      }}
      data-testid="cancel-paper-ebill-subscription-modal"
      role="alertdialog"
    >
      <Group variant="vertical" spacing="s" data-testid="cancel-paper-ebill-subscription-modal-content">
        <Text>{formatMessage('activities.ebillCancelPaperSubscription.modal.description', { name: vendor.name })}</Text>
        <Form {...formProps}>
          <Form.Checkbox
            {...registerField('acceptedTerms')}
            data-testid="cancel-paper-ebill-subscription-modal-terms-checkbox"
            aria-label={formatMessage('activities.ebillCancelSubscription.modal.terms.checkbox.ariaLabel')}
          >
            <Group spacing="xxs" alignItems="center">
              <Text color="global.neutral.1000" textStyle="body3">
                {formatMessage('activities.ebillCancelSubscription.modal.terms.description.label')}
              </Text>
              <Link
                href={terms?.termsUrl ?? ''}
                newTab
                aria-label={formatMessage(
                  'activities.ebillCancelSubscription.modal.terms.description.link.newTabAriaLabel',
                  {
                    label: formatMessage('activities.ebillCancelSubscription.modal.terms.description.link.label'),
                  }
                )}
                label={formatMessage('activities.ebillCancelSubscription.modal.terms.description.link.label')}
                variant="standalone"
                color="secondary"
                size="medium"
              />
            </Group>
          </Form.Checkbox>
        </Form>
      </Group>
    </StatusModal>
  );
});
