import { useUserPreference } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';
import { useDateUtils } from '@melio/platform-utils';
import { createContext, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ExpandedSortingState, PayDashboardSortingType } from './types';

const Context = createContext<PayDashboardSortingType | null>(null);
export const usePayDashboardSorting = () => useContext(Context);
const userPreferencePrefix = 'PayDashboardTableSort-';

export const PayDashboardSortingProvider = ({
  children,
  defaultSort,
  tableId,
}: {
  children: React.ReactNode;
  defaultSort?: ExpandedSortingState;
  tableId?: string;
}) => {
  const [sortedColumn, sortColumn] = useState<PayDashboardSortingType['sortingState']>();
  const [isLoaded, setIsLoaded] = useState(false);
  const userPreferenceKey = useMemo(() => (tableId ? `${userPreferencePrefix}${tableId}` : undefined), [tableId]);
  const { data, isFetched, create } = useUserPreference({ id: userPreferenceKey, enabled: !!userPreferenceKey });
  const { createDate } = useDateUtils();
  const [isPayDashboardSaveSortEnabled] = usePartnerFeature('PayDashboardSaveSort', false);

  const isValidSort = useCallback(
    (sort: PayDashboardSortingType['sortingState'] & { lastSorted: string }) => {
      const now = createDate();
      const time_3_month_ago = now.setMonth(now.getMonth() - 3);

      return (
        sort.id &&
        sort.sortDirection &&
        sort.lastSorted &&
        createDate(sort.lastSorted).getTime() > time_3_month_ago &&
        (sort.sortDirection === 'asc' || sort.sortDirection === 'desc')
      );
    },
    [createDate]
  );

  useEffect(() => {
    if (!isPayDashboardSaveSortEnabled || !userPreferenceKey) {
      sortColumn(defaultSort);
      setIsLoaded(true);
    } else if (isFetched) {
      if (!data?.value) {
        sortColumn(defaultSort);
      } else {
        try {
          const sort = JSON.parse(data.value) as PayDashboardSortingType['sortingState'] & { lastSorted: string };
          sortColumn(isValidSort(sort) ? sort : defaultSort);
        } catch (e) {
          sortColumn(defaultSort);
        }
      }

      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, tableId, isPayDashboardSaveSortEnabled, userPreferenceKey]);

  const setSortState = (sort: SetStateAction<PayDashboardSortingType['sortingState'] | undefined>) => {
    sortColumn(sort);
    if (
      isPayDashboardSaveSortEnabled &&
      sort &&
      userPreferenceKey &&
      !(sort as PayDashboardSortingType['sortingState'])?.disableSaveforNextTime
    ) {
      create({
        userPreference: {
          key: userPreferenceKey,
          value: JSON.stringify({ ...sort, lastSorted: createDate().toISOString() }),
        },
      });
    }
  };

  return <Context.Provider value={{ setSortState, sortingState: sortedColumn, isLoaded }}>{children}</Context.Provider>;
};
