import { NakedButton, SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { FormBannerAnnouncement, useFormBanner } from '@melio/platform-utils';

type Props = {
  onEditClick: VoidFunction;
  vendorName: string;
};

export const MissingVendorInfoBannerWidget = ({ onEditClick, vendorName }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { isAlreadyAnnounced, setBannerRef } = useFormBanner();

  return (
    <FormBannerAnnouncement>
      <SectionBanner
        ref={setBannerRef}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tabIndex={-1}
        variant={isAlreadyAnnounced ? 'critical' : 'warning'}
        title={formatMessage('widgets.vendorDrawer.missingInfoBanner.title')}
        description={formatMessage('widgets.vendorDrawer.missingInfoBanner.description', { vendorName })}
        data-testid="vendor-missing-info-banner"
        action={
          <NakedButton
            variant="secondary"
            aria-haspopup="dialog"
            size="medium"
            label={formatMessage('widgets.vendorDrawer.missingInfoBanner.cta')}
            onClick={onEditClick}
            data-testid="vendor-missing-info-banner-cta"
          />
        }
      />
    </FormBannerAnnouncement>
  );
};
