import { hasRequiredAddressFields, isPOBox, useMtlSchemaValidations } from '@melio/ap-domain';
import {
  Address,
  Company,
  LegalInfoField,
  LegalInfoFields as CompleteLegalInfoFormWidgetFields,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';
import { isPast, isValid, parse } from 'date-fns';
import { pickBy } from 'lodash';
import { number, object, SchemaOf, string } from 'yup';

const DATE_REGEX = /\d{2}\/\d{2}\/\d{4}/; // i.e 12/30/1995
export const useCompleteLegalInfoFormSchema = (missingLegalInfoFields: LegalInfoField[]) => {
  const { formatMessage } = useMelioIntl();
  const { createDate } = useDateUtils();
  const { businessType } = useMtlSchemaValidations();

  return object().shape(
    pickBy(
      {
        legalCompanyName: string()
          .nullable()
          .required(formatMessage('_deprecated.widgets.completeLegalInfo.legalCompanyName.validation.required')),
        phoneNumber: string()
          .nullable()
          .required(formatMessage('_deprecated.widgets.completeLegalInfo.phoneNumber.validation.required')),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        legalAddress: object({
          line1: string(),
          line2: string(),
          city: string(),
          postalCode: string(),
          state: string(),
        })
          .nullable()
          .default(null)
          .required(formatMessage('_deprecated.widgets.completeLegalInfo.legalAddress.validation.required'))
          .test(
            'hasRequiredAddressFields',
            formatMessage(`app.mtl.validations.address.required`),
            (address: Partial<Company['address']> | null) => hasRequiredAddressFields(address)
          )
          .test(
            'isNotPoBox',
            formatMessage(`_deprecated.widgets.completeLegalInfo.legalAddress.validation.poBox`),
            (address: Address) => !isPOBox(address?.line1)
          ),
        businessType: businessType(),
        taxInfoType: string()
          .nullable()
          .oneOf(Object.values(['SSN', 'EIN', 'ITIN']))
          .required(formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.type.validation.required')),
        taxInfoIdentifier: string()
          .nullable()
          .required(formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.identifier.validation.required'))
          .matches(
            /(\d{9})|(\d{3}-\d{2}-\d{4})|(\d{2}-\d{7})/,
            formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.identifier.validation.format')
          ),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        industry: object({
          name: string(),
          naicsCode: number().nullable(),
        })
          .nullable()
          .default(null)
          .required(formatMessage('_deprecated.widgets.completeLegalInfo.industry.validation.required')),
        dateOfBirth: string().when('taxInfoType', {
          is: (taxInfoType: string) => taxInfoType === 'ITIN' || taxInfoType === 'SSN',
          then: string()
            .nullable()
            .required(formatMessage('_deprecated.widgets.completeLegalInfo.dateOfBirth.validation.required'))
            .matches(
              DATE_REGEX,
              formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.dateOfBirth.validation.format`)
            )
            .test(
              'validDate',
              formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.dateOfBirth.validation.format`),
              (v) => {
                if (!v) {
                  return false;
                }
                const date = parse(v, 'MM/dd/yyyy', createDate());
                return isValid(date) && isPast(date);
              }
            ),
        }),
      },
      (_, key) => missingLegalInfoFields.includes(key as LegalInfoField)
    )
  ) as SchemaOf<CompleteLegalInfoFormWidgetFields>;
};
