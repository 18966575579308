import { useRef } from 'react';
import {
  emitFocusEvent,
  FocusEvents,
  useFocusOnEvent,
} from '@melio/ap-activities/src/components/PayDashboard/FocusSkipToComponent';

export const useAutoFocusMoreActionsButton = () => {
  const moreActionsButtonRef = useRef<HTMLDivElement>(null);

  useFocusOnEvent({
    event: FocusEvents.VENDOR_DRAWER_MORE_ACTIONS_BUTTON,
    ref: moreActionsButtonRef,
  });

  const focusMoreActions = () => {
    if (moreActionsButtonRef.current) {
      emitFocusEvent(FocusEvents.VENDOR_DRAWER_MORE_ACTIONS_BUTTON);
    }
  };

  return { moreActionsButtonRef, focusMoreActions };
};
