import { useMelioIntl } from '@melio/ar-domain';
import { GoogleAddressInput, InternationalAddress, ZIP_CODE_SHORT_MASK } from '@melio/form-controls';
import { Form, SimpleGrid } from '@melio/penny';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { isEmpty } from 'lodash';
import { ComponentProps } from 'react';

import { BusinessOwnerForm } from '../types';

type GoogleAddressFormSectionProps = {
  businessOwnerForm: BusinessOwnerForm;
  columns: number;
};

export const GoogleAddressFormSection = forwardRef<GoogleAddressFormSectionProps>(
  ({ businessOwnerForm, columns, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { registerField, setValue } = businessOwnerForm;

    const [shouldShowAddressFields, showAddressFields] = useBoolean(false);

    const handleAddressChange: ComponentProps<typeof GoogleAddressInput>['onChange'] = (event) => {
      const address = event.target.value as unknown as InternationalAddress | null;

      if (address === null || isEmpty(address.line1)) {
        setValue('ownerAddress.line1', '');
        setValue('ownerAddress.city', '');
        setValue('ownerAddress.state', '');
        setValue('ownerAddress.postalCode', '');
        setValue('ownerAddress.countryCode', '');
        showAddressFields.off();
      } else {
        showAddressFields.on();
        businessOwnerForm.setValue('ownerAddress.line1', address.line1);
        businessOwnerForm.setValue('ownerAddress.city', address.city.name);
        businessOwnerForm.setValue('ownerAddress.state', address.state.code);
        businessOwnerForm.setValue('ownerAddress.countryCode', address.country.code);
        businessOwnerForm.setValue('ownerAddress.postalCode', address.postalCode);
      }
    };

    return (
      <SimpleGrid columns={columns} spacing="m" {...props} ref={ref}>
        <GoogleAddressInput
          {...registerField('ownerAddress.line1')}
          isRequired
          onChange={handleAddressChange}
          colSpan={2}
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.ownerAddress.label'),
          }}
        />
        <Form.TextField
          colSpan={1}
          isHidden={!shouldShowAddressFields}
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.city.label'),
          }}
          {...registerField('ownerAddress.city')}
        />
        {shouldShowAddressFields ? (
          <Form.TextField
            colSpan={1}
            labelProps={{
              label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.countryCode.label'),
            }}
            {...registerField('ownerAddress.state')}
          />
        ) : null}

        {shouldShowAddressFields ? (
          <Form.TextField
            colSpan={1}
            labelProps={{
              label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.countryCode.label'),
            }}
            {...registerField('ownerAddress.countryCode')}
          />
        ) : null}

        {shouldShowAddressFields ? (
          <Form.TextField
            colSpan={1}
            labelProps={{
              label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.postalCode.label'),
            }}
            maskProps={{ mask: ZIP_CODE_SHORT_MASK }}
            {...registerField('ownerAddress.postalCode')}
          />
        ) : null}
      </SimpleGrid>
    );
  }
);
GoogleAddressFormSection.displayName = 'GoogleAddressFormSection';
