import { InvoiceDiscount, useInvoiceDiscount, useInvoiceDiscounts } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { CreateDiscountModalScreen } from './screens';
import { DiscountFormFields } from './types';
import { NEW_DISCOUNT_SELECT_ID } from './utils';

export type CreateDiscountActivityProps = {
  isOpen: boolean;
  onDone: (discount: InvoiceDiscount) => void;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
  discountId?: string;
};

export const CreateDiscountActivity = forwardRef<CreateDiscountActivityProps>(
  ({ isOpen, onClose, onDone: _onDone, onError, discountId }, ref) => {
    const onDone = (res: InvoiceDiscount) => {
      setNewDiscount(undefined);
      _onDone(res);
    };

    const [selectedDiscountId, setSelectedDiscount] = useState<string | undefined>(discountId);
    const [newDiscount, setNewDiscount] = useState<Pick<InvoiceDiscount, 'name' | 'id'>>();

    const { data: discounts, isLoading } = useInvoiceDiscounts({ onError });
    const { update, create, isMutating } = useInvoiceDiscount({
      id: selectedDiscountId,
      enabled: selectedDiscountId !== NEW_DISCOUNT_SELECT_ID,
      onError,
      onCreate: onDone,
      onUpdate: onDone,
      onCreateError: onError,
      onUpdateError: onError,
    });

    const discountsToSelect = useMemo(
      () => [newDiscount, ...(discounts ?? [])].filter(Boolean) as InvoiceDiscount[],
      [discounts, newDiscount]
    );

    const selectedDiscount = useMemo(
      () => discountsToSelect.find((discount) => discount.id === selectedDiscountId),
      [discountsToSelect, selectedDiscountId]
    );

    const selectOptions = useMemo(
      () => discountsToSelect.map(({ name, id }) => ({ label: name, value: id, avatarProps: { name } })),
      [discountsToSelect]
    );

    useEffect(() => setSelectedDiscount(discountId), [discountId]);

    const onSubmit = async (formValues: DiscountFormFields) => {
      if (formValues.discountId === newDiscount?.id) {
        await create({ name: newDiscount.name, type: formValues.type, value: formValues.value });
      } else if (formValues.type !== selectedDiscount?.type || formValues.value !== selectedDiscount.value) {
        await update({ type: formValues.type, value: formValues.value });
      } else {
        onDone(selectedDiscount);
      }
    };

    const handleChangeDiscount = (event: ChangeEvent<HTMLInputElement>) => {
      setSelectedDiscount(event.target.value);
    };

    const handleCreateDiscount = (name: string) => {
      setNewDiscount({ id: NEW_DISCOUNT_SELECT_ID, name });
      setSelectedDiscount(NEW_DISCOUNT_SELECT_ID);
    };

    return (
      <CreateDiscountModalScreen
        isOpen={isOpen}
        onClose={onClose}
        onCreateDiscount={handleCreateDiscount}
        onChangeDiscount={handleChangeDiscount}
        isLoading={isLoading}
        selectOptions={selectOptions}
        selectedDiscount={selectedDiscount}
        onSubmit={onSubmit}
        isSaving={isMutating}
        ref={ref}
      />
    );
  }
);

CreateDiscountActivity.displayName = 'CreateDiscountActivity';
