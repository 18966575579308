import { DeepPartial } from '@chakra-ui/react';
import { FieldValues, UseMelioFormResults } from '@melio/penny';
import { useDebounceCallback } from '@melio/platform-utils';
import { useEffect } from 'react';

export const getAdditionalWatchPropsStorageKey = (key: string) => `${key}-additionalWatchProp`;

export const useSetFormSessionStorage = <T extends FieldValues, Y>(
  sessionStorageKey: string,
  watch: UseMelioFormResults<T>['watch'],
  additionalWatchProp?: Y
) => {
  const additionalWatchPropsStorageKey = getAdditionalWatchPropsStorageKey(sessionStorageKey);
  // Watch for changes in form values and update sessionStorage

  const onFormChangeDebounced = useDebounceCallback((value: DeepPartial<FieldValues>) => {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(value));
  }, 1000);

  useEffect(() => {
    const subscription = watch(onFormChangeDebounced);

    return () => subscription.unsubscribe();
  }, [watch, sessionStorageKey, onFormChangeDebounced]);

  // Watch the additional prop if provided
  useEffect(() => {
    if (additionalWatchProp) {
      sessionStorage.setItem(additionalWatchPropsStorageKey, JSON.stringify(additionalWatchProp));
    }
  }, [additionalWatchProp, additionalWatchPropsStorageKey]);
};

export const useGetFormSessionStorage = <T extends FieldValues, Y>(sessionStorageKey: string) => {
  const additionalWatchPropsStorageKey = getAdditionalWatchPropsStorageKey(sessionStorageKey);

  const loadInitialValuesFromStorage = (): Partial<T> => {
    const storedValues = sessionStorage.getItem(sessionStorageKey);
    try {
      return (storedValues ? JSON.parse(storedValues) : {}) as Partial<T>;
    } catch {
      return {} as Partial<T>;
    }
  };

  const loadAdditionalWatchPropFromStorage = (): Partial<Y> => {
    const storedValues = sessionStorage.getItem(additionalWatchPropsStorageKey);
    try {
      return (storedValues ? JSON.parse(storedValues) : {}) as Partial<Y>;
    } catch {
      return {} as Partial<Y>;
    }
  };

  const resumedFormValues = loadInitialValuesFromStorage();
  const additionalPropValues = loadAdditionalWatchPropFromStorage();

  return { resumedFormValues, additionalPropValues };
};
