import { FormInputs, FundingSource, useMelioIntl } from '@melio/ar-domain';
import { Form, Group, useMelioForm, UseMelioFormResults } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import * as yup from 'yup';

import { ReceivingAndDebitAccountFormValues } from '../types';
import { DebitAccountDescription } from './DebitAccountDescription';
import { DebitAccountHeader } from './DebitAccountHeader';

export type ReceivingAndDebitAccountDropdownFormProps = {
  fundingSources: FundingSource[];
  onSubmit: (formValues: Omit<ReceivingAndDebitAccountFormValues, 'isReceivingAccountEqualDebitAccount'>) => void;
  onSubmissionStateChange: (submissionState: UseMelioFormResults<ReceivingAndDebitAccountFormValues>) => void;
};

export const ReceivingAndDebitAccountForm = forwardRef<ReceivingAndDebitAccountDropdownFormProps, 'form'>(
  ({ fundingSources, onSubmissionStateChange, onSubmit: _onSubmit }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { registerField, formProps, watch } = useMelioForm<ReceivingAndDebitAccountFormValues>({
      onSubmit: ({ isReceivingAccountEqualDebitAccount, receivingBankAccountId, debitBankAccountId }) => {
        _onSubmit({
          receivingBankAccountId,
          debitBankAccountId: isReceivingAccountEqualDebitAccount ? receivingBankAccountId : debitBankAccountId,
        });
      },
      onSubmissionStateChange,
      defaultValues: {
        receivingBankAccountId: fundingSources[0]?.id,
        isReceivingAccountEqualDebitAccount: fundingSources.length === 1,
      },
      schema: yup.object().shape({
        receivingBankAccountId: yup
          .string()
          .required(
            formatMessage(
              'ar.onboarding.activities.forms.receivingAccountDropdown.fields.fundingSource.valid.required.text'
            )
          ),
        isReceivingAccountEqualDebitAccount: yup.boolean(),
        debitBankAccountId: yup.string().when('isReceivingAccountEqualDebitAccount', {
          is: false,
          then: yup
            .string()
            .required(
              formatMessage(
                'ar.onboarding.activities.forms.DebitAccountDropdown.fields.fundingSource.valid.required.text'
              )
            ),
          otherwise: yup.string().nullable(),
        }),
      }) as yup.SchemaOf<ReceivingAndDebitAccountFormValues>,
    });

    const isSameReceivingAndDebitAccount = watch('isReceivingAccountEqualDebitAccount');

    return (
      <Form
        {...formProps}
        ref={ref}
        data-component="ConfirmReceivingAccount"
        data-testid="confirm-receiving-account-form"
      >
        <FormInputs.BankAccountSelect
          labelProps={{
            label: formatMessage('ar.onboarding.activities.forms.receivingAccountDropdown.receivingAccountSelect.text'),
          }}
          options={fundingSources}
          {...registerField('receivingBankAccountId')}
        />
        <Form.Checkbox
          size="small"
          label={formatMessage(
            'ar.onboarding.activities.forms.receivingAccountDropdown.isReceivingAccountEqualDebitAccount.text'
          )}
          isDisabled={fundingSources.length === 1}
          {...registerField('isReceivingAccountEqualDebitAccount')}
        />
        {fundingSources.length > 1 && !isSameReceivingAndDebitAccount ? (
          <Group variant="vertical" spacing="l">
            <Group variant="vertical" spacing="xs">
              <DebitAccountHeader />
              <DebitAccountDescription />
            </Group>
            <FormInputs.BankAccountSelect
              labelProps={{
                label: formatMessage(
                  'ar.onboarding.activities.forms.DebitAccountDropdown.debitAccountAccountSelect.text'
                ),
              }}
              options={fundingSources}
              {...registerField('debitBankAccountId')}
            />
          </Group>
        ) : null}
      </Form>
    );
  }
);

ReceivingAndDebitAccountForm.displayName = 'ReceivingAndDebitAccountForm';
