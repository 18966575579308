/* eslint-disable max-lines */
import { validateNotContainsEmail } from '@melio/ap-domain';
import { validateUniqueNameForSync } from '@melio/ap-domain/src/hooks/vendors/vendor-name-constraints/utils';
import { useVendors } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { uniq } from 'lodash';
import { object, SchemaOf, string, TestContext } from 'yup';

import {
  FISERV_ACCOUNT_NUMBER_MAX_LENGTH,
  FISERV_ACCOUNT_NUMBER_MIN_LENGTH,
  ROUTING_NUMBER_LENGTH,
  validateRoutingNumber,
  ValidationError,
} from '../bank-details/util/bank-account-util';
import { VendorFormFields } from './types';

export const validateInvalidChars =
  (regexp: RegExp, createErrorMessage: (invalidChars: string) => string) =>
  (value: string | undefined | null, testContext: TestContext) => {
    if (!value) {
      return true;
    }

    const reg = new RegExp(regexp, 'g');

    const invalidCharsList = Array.from(value.replaceAll(reg, '').replaceAll(' ', ''));
    if (invalidCharsList.length === 0) {
      return true;
    }

    const uniqueInvalidCharsList = uniq(invalidCharsList).join(' ');
    return testContext.createError({
      path: testContext.path,
      message: createErrorMessage(uniqueInvalidCharsList),
    });
  };

export const useCompanyNameSchema = ({
  showCompanyField,
  isManaged,
}: {
  showCompanyField?: boolean;
  isManaged: boolean;
}) => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      vendor: {
        createVendor: {
          canAddVendorWithTheSameName,
          companyNameFormatRegexp,
          companyNameMaxLength,
          companyNameMinLength,
        },
      },
    },
  } = useConfig();
  const [vendorNameConstraintsEnabled] = useDevFeature(FeatureFlags.PlatformVendorNameConstraints, false);
  const { data: vendors } = useVendors({ enabled: showCompanyField });

  if (!showCompanyField) {
    // TODO: do the validation and display error in a banner if the field is not displayed https://meliorisk.atlassian.net/browse/ME-33938
    return object().shape({ companyName: string() }) as SchemaOf<Pick<VendorFormFields, 'companyName'>>;
  }

  return object().shape({
    companyName: string()
      .required(formatMessage('widgets.vendors.companyName.validation.required'))
      .when({
        is: () => companyNameMaxLength,
        then: (schema) =>
          schema.max(companyNameMaxLength as number, formatMessage('widgets.vendors.companyName.validation.maxLength')),
      })
      .when({
        is: () => companyNameMinLength,
        then: (schema) =>
          schema.min(companyNameMinLength as number, formatMessage('widgets.vendors.companyName.validation.minLength')),
      })
      .when({
        is: () =>
          companyNameFormatRegexp?.pattern
            ? new RegExp(companyNameFormatRegexp?.pattern, companyNameFormatRegexp?.flags)
            : false,
        then: (schema) =>
          schema.matches(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            new RegExp(companyNameFormatRegexp!.pattern, companyNameFormatRegexp!.flags),
            formatMessage('widgets.vendors.companyName.validation.format')
          ),
      })
      .when({
        is: () => !canAddVendorWithTheSameName,
        then: (schema) =>
          schema
            .required(formatMessage('widgets.vendors.companyName.validation.required'))
            .test('current-vendors-loaded', '', () => !!vendors)
            .test(
              'is-company-name-not-exists',
              formatMessage('widgets.vendors.companyName.validation.alreadyExists'),
              (companyName) => !vendors?.some((vendor) => vendor.name === companyName)
            ),
      })
      .test(
        'not-contains-email',
        formatMessage('form.vendorSelect.constraints.email'),
        validateNotContainsEmail({ isEnabled: vendorNameConstraintsEnabled, isManaged })
      ),
  }) as SchemaOf<Pick<VendorFormFields, 'companyName'>>;
};

export const useAccountNumberSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    accountNumber: string()
      .required(formatMessage('widgets.vendors.accountNumber.validation.required'))
      .max(32, formatMessage('widgets.vendors.accountNumber.validation.maxLength'))
      .test(
        'validate-invalid-chars',
        '',
        validateInvalidChars(/[!"#$%&\-0-9A-Za-z]*/, (invalidChars) =>
          formatMessage('widgets.vendors.accountNumber.validation.format', {
            invalidChars,
          })
        )
      ),
    confirmAccountNumber: string()
      .required(formatMessage('widgets.vendors.confirmAccountNumber.validation.required'))
      .test(
        'match-account-number',
        formatMessage('widgets.vendors.confirmAccountNumber.validation.match'),
        (confirmAccountNumber, context) => confirmAccountNumber === (context.parent as VendorFormFields).accountNumber
      ),
  }) as SchemaOf<Pick<VendorFormFields, 'accountNumber' | 'confirmAccountNumber'>>;
};

export const useBankAccountSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape(
    {
      bankRoutingNumber: string()
        .nullable()
        .when(['bankAccountNumber'], {
          is: (bankAccountNumber: string) => !!bankAccountNumber,
          then: (schema) =>
            schema.required(
              formatMessage('widgets.vendors.bankRoutingNumber.validation.required', {
                length: ROUTING_NUMBER_LENGTH,
              })
            ),
        })
        .test('routingNumber', '', (value, testContext) => {
          if (!value) {
            return true;
          }

          const routingNumberValidation = validateRoutingNumber(value);

          if (routingNumberValidation.valid) {
            return true;
          }

          if (routingNumberValidation.error === ValidationError.INVALID_CHARACTERS) {
            return testContext.createError({
              message: formatMessage('widgets.vendors.bankRoutingNumber.validation.digitsOnly'),
            });
          }

          if (routingNumberValidation.error === ValidationError.INVALID_LENGTH) {
            return testContext.createError({
              message: formatMessage('widgets.vendors.bankRoutingNumber.validation.invalidLength', {
                length: ROUTING_NUMBER_LENGTH,
              }),
            });
          }

          return testContext.createError({
            message: formatMessage('widgets.vendors.bankRoutingNumber.validation.invalidNumber', {
              length: ROUTING_NUMBER_LENGTH,
            }),
          });
        }),
      bankAccountNumber: string()
        .nullable()
        .when(['bankRoutingNumber'], {
          is: (bankRoutingNumber: string) => !!bankRoutingNumber,
          then: (schema) =>
            schema.required(
              formatMessage('widgets.vendors.bankAccountNumber.validation.required', {
                minLength: FISERV_ACCOUNT_NUMBER_MIN_LENGTH,
                maxLength: FISERV_ACCOUNT_NUMBER_MAX_LENGTH,
              })
            ),
        })
        .when({
          is: (bankAccountNumber: string) => !!bankAccountNumber,
          then: (schema) =>
            schema
              .matches(/^[0-9A-Za-z]*$/, formatMessage('widgets.vendors.bankAccountNumber.validation.format'))
              .test(
                'should-contain-uppercase-chars',
                formatMessage('widgets.vendors.bankAccountNumber.validation.shouldContainUpperCase'),
                (bankAccountNumber) => {
                  if (!bankAccountNumber) {
                    return true;
                  }

                  return /[a-z]/.test(bankAccountNumber) ? /[A-Z]/.test(bankAccountNumber) : true;
                }
              )
              .max(
                FISERV_ACCOUNT_NUMBER_MAX_LENGTH,
                formatMessage('widgets.vendors.bankAccountNumber.validation.invalidMaxLength', {
                  minLength: FISERV_ACCOUNT_NUMBER_MIN_LENGTH,
                  maxLength: FISERV_ACCOUNT_NUMBER_MAX_LENGTH,
                })
              ),
        }),
    },
    [['bankRoutingNumber', 'bankAccountNumber']]
  ) as SchemaOf<Pick<VendorFormFields, 'bankRoutingNumber' | 'bankAccountNumber'>>;
};

export const useNicknameSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    nickname: string()
      .nullable()
      .max(30, formatMessage('widgets.vendors.nickname.validation.maxLength'))
      .test(
        'validate-invalid-chars',
        '',
        validateInvalidChars(/[ ,.\-0-9A-Za-z\r\n]*/, (invalidChars) =>
          formatMessage('widgets.vendors.nickname.validation.format', {
            invalidChars,
          })
        )
      ),
  }) as SchemaOf<Pick<VendorFormFields, 'nickname'>>;
};

const ADDRESS_FIELDS_FORMAT_REGEXP = /[ 0-9A-Za-z_@!+#.$,/%^*-]*/;
const ADDRESS_FIELDS_MAX_LENGTH = 32;

export const useAddressLine1Schema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    line1: string()
      .nullable()
      .required(formatMessage('widgets.vendors.addressLine1.validation.required'))
      .max(ADDRESS_FIELDS_MAX_LENGTH, formatMessage('widgets.vendors.addressLine1.validation.maxLength'))
      .test(
        'validate-invalid-chars',
        '',
        validateInvalidChars(ADDRESS_FIELDS_FORMAT_REGEXP, (invalidChars) =>
          formatMessage('widgets.vendors.addressLine1.validation.format', {
            invalidChars,
          })
        )
      ),
  }) as SchemaOf<Pick<VendorFormFields, 'line1'>>;
};

export const useAddressLine2Schema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    line2: string()
      .nullable()
      .max(ADDRESS_FIELDS_MAX_LENGTH, formatMessage('widgets.vendors.addressLine2.validation.maxLength'))
      .test(
        'validate-invalid-chars',
        '',
        validateInvalidChars(ADDRESS_FIELDS_FORMAT_REGEXP, (invalidChars) =>
          formatMessage('widgets.vendors.addressLine2.validation.format', {
            invalidChars,
          })
        )
      ),
  }) as SchemaOf<Pick<VendorFormFields, 'line2'>>;
};

export const useCitySchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    city: string()
      .required(formatMessage('widgets.vendors.city.validation.required'))
      .max(ADDRESS_FIELDS_MAX_LENGTH, formatMessage('widgets.vendors.city.validation.maxLength'))
      .test(
        'validate-invalid-chars',
        '',
        validateInvalidChars(ADDRESS_FIELDS_FORMAT_REGEXP, (invalidChars) =>
          formatMessage('widgets.vendors.city.validation.format', {
            invalidChars,
          })
        )
      ),
  }) as SchemaOf<Pick<VendorFormFields, 'city'>>;
};

export const useZipCodeSchema = (isZipCodeNeeded = true) => {
  const { formatMessage } = useMelioIntl();
  const config = useConfig();
  const isZipMasked = config.settings.vendor.forms.shouldUseZipCodeMask;

  const matcher = isZipMasked ? /^(\d{5}|\d{5}-\d{4}|\d{5}-\d{4}-\d{2})$/ : /^(\d{5}|\d{9}|\d{11})$/;

  return object().shape({
    ...(isZipCodeNeeded
      ? {
          postalCode: string()
            .required(formatMessage('widgets.vendors.postalCode.validation.required'))
            .matches(matcher, formatMessage('widgets.vendors.postalCode.validation.format')),
        }
      : {}),
  }) as SchemaOf<Pick<VendorFormFields, 'postalCode'>>;
};

export const useUniqueNameSchema = ({
  showUniqueNameField,
  isManaged,
  vendorId,
}: {
  showUniqueNameField?: boolean;
  defaultValues?: Partial<VendorFormFields>;
  isManaged: boolean;
  vendorId?: string;
}) => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      vendor: {
        createVendor: { uniqueNameFormatRegexp, companyNameMaxLength, companyNameMinLength },
      },
    },
  } = useConfig();
  const [vendorNameConstraintsEnabled] = useDevFeature(FeatureFlags.PlatformVendorNameConstraints, false);
  const { data: vendors } = useVendors({ enabled: showUniqueNameField });

  if (!showUniqueNameField) {
    return object().shape({ uniqueName: string() }) as SchemaOf<Pick<VendorFormFields, 'uniqueName'>>;
  }

  return object().shape({
    uniqueName: string()
      .trim()
      .required(formatMessage('widgets.vendorDetails.form.uniqueName.validation.required'))
      .when({
        is: () => companyNameMaxLength,
        then: (schema) =>
          schema.max(
            companyNameMaxLength as number,
            formatMessage('widgets.vendorDetails.form.uniqueName.validation.maxLength')
          ),
      })
      .when({
        is: () => companyNameMinLength,
        then: (schema) =>
          schema.min(
            companyNameMinLength as number,
            formatMessage('widgets.vendorDetails.form.uniqueName.validation.minLength')
          ),
      })
      .when({
        is: () =>
          uniqueNameFormatRegexp?.pattern
            ? new RegExp(uniqueNameFormatRegexp.pattern, uniqueNameFormatRegexp.flags)
            : undefined,
        then: (schema) =>
          schema.matches(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            new RegExp(uniqueNameFormatRegexp!.pattern, uniqueNameFormatRegexp!.flags),
            formatMessage('widgets.vendorDetails.form.uniqueName.validation.format')
          ),
      })
      .test(
        'is-name-for-sync-unique',
        formatMessage('widgets.vendorNameInQBOModal.form.companyNameInQBO.validation.alreadyExists'),
        validateUniqueNameForSync(vendors, vendorId)
      )
      .test(
        'not-contains-email',
        formatMessage('form.vendorSelect.constraints.email'),
        validateNotContainsEmail({ isEnabled: vendorNameConstraintsEnabled, isManaged })
      ),
  }) as SchemaOf<Pick<VendorFormFields, 'uniqueName'>>;
};
