import { Flex } from '@chakra-ui/react';
import { FundingSourceCardList } from '@melio/ap-widgets';
import { FundingSource, useIsAccountingFirm } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { useState } from 'react';

import { MicroDepositsVerificationModalActivity } from '../../../../funding-sources/MicroDepositsVerification';
import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type SelectBillingMethodScreenProps = {
  userId?: string;
  isLoading?: boolean;
  fundingSources: FundingSource[];
  isNextDisabled: boolean;
  isLoadingButton?: boolean;
  firmBillingFeeMethodId?: string;
  selectedFundingSourceId?: string;
  onClose: VoidFunction;
  onSubmit: VoidFunction;
  setSelectedFundingSourceId: React.Dispatch<React.SetStateAction<string>>;
};

export const SelectBillingMethodScreen = ({
  userId,
  isLoading,
  fundingSources,
  isNextDisabled,
  isLoadingButton,
  firmBillingFeeMethodId,
  selectedFundingSourceId,
  onClose,
  onSubmit,
  setSelectedFundingSourceId,
}: SelectBillingMethodScreenProps) => {
  const { formatMessage } = useMelioIntl();

  const [fundingSourceIdToVerify, setFundingSourceIdToVerify] = useState<FundingSource['id'] | void>();
  const isAccountingFirm = useIsAccountingFirm();

  return (
    <NewSinglePaymentStepLayout
      data-component="BillingFeeActivity.SelectBillingMethodScreen"
      data-testid="billing-fee-activity-select-method-screen"
      isLoading={isLoading}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <NewSinglePaymentStepLayout.NextButton
            data-testid="save-button"
            onClick={onSubmit}
            variant="primary"
            label={formatMessage('activities.billingFee.screens.select.save')}
            isLoading={isLoadingButton}
            isDisabled={isNextDisabled}
          />
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.billingFee.screens.select.title')}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        {formatMessage('activities.billingFee.screens.select.description')}
      </NewSinglePaymentStepLayout.Description>
      <NewSinglePaymentStepLayout.Content>
        <Flex direction="column" gridGap="16px">
          <FundingSourceCardList
            selectedId={selectedFundingSourceId}
            fundingSources={fundingSources || []}
            userId={userId}
            isBillingMethod
            firmBillingFeeMethodId={!isAccountingFirm ? firmBillingFeeMethodId : null}
            onVerify={setFundingSourceIdToVerify}
            onSelect={setSelectedFundingSourceId}
          />
        </Flex>
        <MicroDepositsVerificationModalActivity
          onError={onClose}
          isOpen={!!fundingSourceIdToVerify}
          onClose={() => setFundingSourceIdToVerify()}
          onDone={() => setFundingSourceIdToVerify()}
          fundingSourceId={fundingSourceIdToVerify as never}
        />
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
};

SelectBillingMethodScreen.displayName = 'BillingFeeActivity.SelectBillingMethodScreen';
