import { isBillSubscriptionCancelled } from '@melio/ap-domain/src/functions/bill-subscriptions';
import { Group, Pill, PillProps } from '@melio/penny';
import { ApprovalDecision, DeliveryMethodType, PaymentFullyExpanded } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

import { CanceledRecurringPaymentStatusDescription } from '../../../../CanceledRecurringPaymentStatusDescription/CanceledRecurringPaymentStatusDescription';
import { usePaymentDetailsSectionType } from '../utils';

type Props = { payment: PaymentFullyExpanded; approvalDecisions?: ApprovalDecision[] };
export const PaymentStatusBadgeSection = ({ payment, approvalDecisions }: Props) => {
  const { formatMessage } = useMelioIntl();
  const [isSecureFileUploadEnabled] = useDevFeature<boolean>(FeatureFlags.SecureFileUpload, false);
  const paymentStatus = usePaymentDetailsSectionType(payment, approvalDecisions);
  switch (paymentStatus) {
    case 'payment-scheduled': {
      if (isSecureFileUploadEnabled && payment.paymentPending?.uploadDocuments) {
        return (
          <StatusBadge
            status="warning"
            label={formatMessage('widgets.paymentDetails.pendingVerification.title')}
            data-testid="pay-dashboard-payment-pending-verification"
          />
        );
      }
      if (payment.approvalDecisionStatus === 'pending') {
        return (
          <StatusBadge
            status="warning"
            label={formatMessage('widgets.paymentDetails.approvalPending.title')}
            data-testid="pay-dashboard-payment-pending-status"
          />
        );
      }
      if (payment.fundingSource && !payment.fundingSource.isVerified) {
        return (
          <StatusBadge
            status="warning"
            label={formatMessage('widgets.paymentDetails.pendingBankAccountVerification.title')}
            data-testid="pay-dashboard-payment-pending-verification-status"
          />
        );
      }
      if (payment.deliveryMethod?.type === DeliveryMethodType.VirtualAccount) {
        return (
          <StatusBadge
            status="warning"
            label={formatMessage('widgets.paymentDetails.pendingVendor.title')}
            data-testid="pay-dashboard-payment-pending-vendor-status"
          />
        );
      }
      return (
        <StatusBadge
          status="informative"
          label={formatMessage('widgets.paymentDetails.scheduled.title')}
          data-testid="pay-dashboard-payment-scheduled-status"
        />
      );
    }
    case 'payment-in-progress':
      return (
        <StatusBadge
          status="success"
          label={formatMessage('widgets.paymentDetails.inProgress.title')}
          data-testid="pay-dashboard-payment-in-progress-status"
        />
      );
    case 'payment-completed':
      return (
        <StatusBadge
          status="success"
          label={
            payment.markedAsPaid
              ? formatMessage('widgets.paymentDetails.markedAsPaid.title')
              : formatMessage('widgets.paymentDetails.completed.title')
          }
          data-testid="pay-dashboard-payment-completed-status"
        />
      );
    case 'payment-approval-declined':
      return (
        <StatusBadge
          status="critical"
          label={formatMessage('widgets.paymentDetails.approvalDeclined.title')}
          data-testid="pay-dashboard-payment-approval-declined-status"
        />
      );
    case 'refund-in-progress':
      return (
        <StatusBadge
          status="neutral"
          label={formatMessage('widgets.paymentDetails.inProgressRefund.title')}
          data-testid="pay-dashboard-payment-refund-in-progress-status"
        />
      );
    case 'refund-completed':
      return (
        <StatusBadge
          status="success"
          label={formatMessage('widgets.paymentDetails.completedRefund.title')}
          data-testid="pay-dashboard-payment-refund-completed-status"
        />
      );
    case 'payment-canceled':
      return (
        <Group variant="vertical" spacing="xxs">
          <StatusBadge
            status="critical"
            label={formatMessage('widgets.paymentDetails.canceled.title')}
            data-testid="pay-dashboard-payment-canceled-status"
          />
          {isBillSubscriptionCancelled(payment.subscriptionOccurrence?.billSubscription) && (
            <CanceledRecurringPaymentStatusDescription testId={`payments-drawer-${payment.id}-status-cell`} />
          )}
        </Group>
      );
    case 'payment-failed': {
      if (payment.isFinanced && payment.loan?.status === 'canceled') {
        return (
          <StatusBadge
            status="critical"
            label={formatMessage('widgets.paymentDetails.canceled.title')}
            data-testid="payment-failed-status-bar-canceled"
          />
        );
      }
      return (
        <StatusBadge
          status="critical"
          label={formatMessage('widgets.paymentDetails.failed.title')}
          data-testid="payment-failed-status-bar-failed"
        />
      );
    }
  }
};

function StatusBadge(props: Omit<PillProps, 'type'>) {
  return <Pill type="secondary" {...props} />;
}
