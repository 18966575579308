import { useIsAccountingClient, useIsExternalAccountant } from '@melio/platform-api';

import {
  useAccountingClientSelectedSubscriptionPlan,
  useIsSubscriptionsEnabled,
  useSubscriptionPermissions,
  useSubscriptionPlan,
} from '../../../hooks';

export const useShowSubscriptionChangePlanButton = () => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const accountingClientSelectedSubscriptionPlan = useAccountingClientSelectedSubscriptionPlan();
  const { isVip, getIsCurrentPlanIsMostExpensivePlan } = useSubscriptionPlan();
  const { hasAccessToUpdatePlan } = useSubscriptionPermissions();
  const isExternalAccountant = useIsExternalAccountant();
  const isAccountingClient = useIsAccountingClient();

  const hideForExternalAccountant = isExternalAccountant && isAccountingClient;

  return (
    isSubscriptionsEnabled &&
    !accountingClientSelectedSubscriptionPlan &&
    !isVip &&
    hasAccessToUpdatePlan() &&
    !hideForExternalAccountant &&
    !getIsCurrentPlanIsMostExpensivePlan()
  );
};
