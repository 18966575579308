import { Button, Container, Form, Group, useWatch } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';

import {
  useKeyDownHandlerButtonClickSimulation,
  useSubscriptionFundingSource,
  useTriggerFormValidationFields,
} from '../../../../hooks';
import { useFocusStepOnError } from '../../../../hooks/useFocusStepOnError';
import { useGetSearchParamsValues } from '../../../../hooks/useGetSearchParamsValues';
import { SubscriptionBillingPayor } from '../../../../types';
import { PaymentMethodBanner } from '../../../PaymentMethodBanner/PaymentMethodBanner';
import {
  AssignFirmClientPlanFormField,
  AssignFirmClientPlanFormFields,
  AssignFirmClientPlanFormStepComponent,
} from '../../types';
import { useAssignFirmClientPlanFormContext } from '../AssignFirmClientPlanForm/AssignFirmClientPlanFormFormContext';

const stepFields: Array<AssignFirmClientPlanFormField> = ['whoPays'];

export const AssignFirmClientPlanBillingDetailsStep: AssignFirmClientPlanFormStepComponent = ({
  submitLabel,
  focusErrorStep,
  onContinue,
}) => {
  const { formatMessage } = useMelioIntl();
  const { isSubscriptionFundingSourceAlreadySelected } = useSubscriptionFundingSource();
  const formContext = useAssignFirmClientPlanFormContext();
  const { formState, control, setValue, registerField, submitButtonProps } = formContext;
  const { buttonRef, handleKeyDown } = useKeyDownHandlerButtonClickSimulation('Enter');
  const triggerFormValidationFields = useTriggerFormValidationFields(formContext);
  const { track } = useAnalytics();

  const onSetFundingSourceId = useCallback(
    (fundingSourceId: string | null) => {
      setValue('fundingSourceId', fundingSourceId || '');
      setValue('whoPays', SubscriptionBillingPayor.AccountingFirm);
    },
    [setValue]
  );

  const { getParamsValues, removeParamsValues: removeFundingSourceFromUrl } = useGetSearchParamsValues([
    'fundingSourceId',
  ]);
  const { fundingSourceId: fundingSourceIdFromUrl } = getParamsValues();

  const [watchFundingSourceId, watchWhoPays] = useWatch({
    control,
    name: ['fundingSourceId', 'whoPays'],
  });

  const shouldShowFirmPaysBanners =
    watchWhoPays === SubscriptionBillingPayor.AccountingFirm ||
    (watchWhoPays === SubscriptionBillingPayor.Client && !watchFundingSourceId);

  useFocusStepOnError<AssignFirmClientPlanFormFields>({
    formState,
    stepFields,
    focusErrorStep,
  });

  const verifyBillingDetails = async () => {
    const whoPays = watchWhoPays === SubscriptionBillingPayor.Client ? 'client-pays-directly' : 'firm-pays-for-client';
    track('Organization', 'Click', {
      Cta: 'continue',
      PageName: 'billing-details',
      Intent: 'add-billing-details',
      OptionChosen: whoPays,
    });

    const isStepFormFieldsValid = await triggerFormValidationFields(stepFields);

    if (isStepFormFieldsValid) {
      onContinue();
    }
  };

  const commonFieldsProps = {
    onKeyDown: handleKeyDown,
  };

  return (
    <Group spacing="m" variant="vertical" width="full">
      <Form.RadioGroup
        options={[
          {
            mainLabelProps: {
              label: formatMessage('activities.accountants.assignPlan.form.billingDetails.fields.clientPays.label'),
            },
            descriptionProps: {
              label: formatMessage(
                'activities.accountants.assignPlan.form.billingDetails.fields.clientPays.description.text'
              ),
            },
            value: SubscriptionBillingPayor.Client,
          },
          {
            mainLabelProps: {
              label: formatMessage('activities.accountants.assignPlan.form.billingDetails.fields.firmPays.label'),
            },
            descriptionProps: {
              label: formatMessage(
                'activities.accountants.assignPlan.form.billingDetails.fields.firmPays.description.text'
              ),
            },
            disabled: { isDisabled: !watchFundingSourceId },
            value: SubscriptionBillingPayor.AccountingFirm,
          },
        ]}
        variant="vertical"
        {...registerField('whoPays')}
        {...commonFieldsProps}
      />
      {shouldShowFirmPaysBanners && (
        <PaymentMethodBanner
          isChangingDisabled={isSubscriptionFundingSourceAlreadySelected}
          fundingSourceId={watchFundingSourceId}
          setFundingSourceId={onSetFundingSourceId}
          onDrawerClose={() => removeFundingSourceFromUrl()}
          isDrawerDefaultOpen={!!fundingSourceIdFromUrl}
        />
      )}
      <Container justifyContent="flex-end">
        <Button
          ref={buttonRef}
          variant="primary"
          size="medium"
          label={submitLabel}
          data-testid="billing-details-continue-button"
          {...submitButtonProps}
          onClick={() => void verifyBillingDetails()}
        />
      </Container>
    </Group>
  );
};
