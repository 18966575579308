import { Button, Container, Group, Illustration, Layout, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { useSubscriptionOrganizationFailureState } from '../../../hooks';
import { useSubscriptionRouter } from '../../utils';

const trackingData = {
  PageName: 'payment-method-changed',
  Flow: 'subscription-payment-method-updated',
  Intent: 'update-pm-for-subscription-payment',
};

export const SubscriptionsSelectFundingSourceSuccess = () => {
  const { formatMessage } = useMelioIntl();
  const { goToSubscriptionPlansSettings } = useSubscriptionRouter();
  const { failureState } = useSubscriptionOrganizationFailureState();
  const { track } = useAnalytics();

  useAnalyticsView('PaymentMethod', true, true, trackingData);

  const onDone = () => {
    track('PaymentMethod', 'Click', {
      ...trackingData,
      Cta: 'done',
    });
    goToSubscriptionPlansSettings(failureState ? { intent: 'resolve-failed-fee-payment' } : {});
  };

  return (
    <Layout maxWidth="600px" data-testid="subscription-select-funding-source-success">
      <Group variant="vertical" alignItems="center">
        <Illustration type="success" size="medium" />
        <Text textStyle="heading1Semi">
          {formatMessage('activities.subscription.selectPaymentMethod.success.title')}
        </Text>
        <Container textAlign="center" paddingY="s">
          <Text textStyle="body2" color="global.neutral.900">
            {formatMessage('activities.subscription.selectPaymentMethod.success.description')}
          </Text>
        </Container>
        <Button
          variant="primary"
          label={formatMessage('activities.subscription.selectPaymentMethod.success.button')}
          onClick={onDone}
          size="large"
          data-testid="subscription-select-funding-source-success-button"
        />
      </Group>
    </Layout>
  );
};
