import { useBoolean } from '@chakra-ui/react';
import { ARIndustry } from '@melio/ar-domain';
import { FormSelectNewOption } from '@melio/penny';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useBusinessDetailsFormContext } from '../../../utils';
import { SelectOptionType } from '../types';

type UseFieldVisibilityProps = {
  industries: ARIndustry[];
};

export const useBusinessDetailsState = ({ industries }: UseFieldVisibilityProps) => {
  const { watch } = useBusinessDetailsFormContext();

  const industriesOptions = useMemo(
    () =>
      industries.map((industry) => ({
        label: industry.name,
        value: industry.id.toString(),
        testId: industry.id.toString(),
      })),
    [industries]
  );

  const [shouldShowIrsName, { on: showIrsName, off: hideIrsName }] = useBoolean(true);
  const [shouldShowDeliveryAddressFields, { on: showDeliveryFields, off: hideDeliveryFields }] = useBoolean(false);
  const [industrySubIndustryOptions, setIndustrySubIndustryOptions] = useState<SelectOptionType[]>([]);
  const [filteredSubIndustryOptions, setFilteredSubIndustryOptions] = useState<FormSelectNewOption[]>([]);
  const [filteredIndustryOptions, setFilteredIndustryOptions] = useState<FormSelectNewOption[]>(industriesOptions);

  const isBusinessNameConsistent = watch('isBusinessNameConsistent');
  const isDeliveryAddressConsistent = watch('isDeliveryAddressConsistent');
  const industrySelectedValue = watch('industry');

  useEffect(() => {
    isBusinessNameConsistent ? hideIrsName() : showIrsName();
  }, [isBusinessNameConsistent, hideIrsName, showIrsName]);

  useEffect(() => {
    isDeliveryAddressConsistent ? hideDeliveryFields() : showDeliveryFields();
  }, [isDeliveryAddressConsistent, hideDeliveryFields, showDeliveryFields]);

  useEffect(() => {
    const selectedIndustry = industries.find((ind) => ind.id.toString() === industrySelectedValue);
    const subIndustries =
      selectedIndustry?.subIndustries?.map((sub) => ({
        label: sub.name,
        value: sub.code,
        testId: sub.code,
      })) || [];

    setIndustrySubIndustryOptions(subIndustries);
    setFilteredSubIndustryOptions(subIndustries);
  }, [industrySelectedValue, industries]);

  const handleSearchChange = useCallback(
    (options: FormSelectNewOption[], searchValue: string) =>
      options.filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase())),
    []
  );

  const handleIndustrySearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredIndustryOptions(handleSearchChange(industriesOptions, e.target.value));
  };

  const handleSubIndustrySearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredSubIndustryOptions(handleSearchChange(industrySubIndustryOptions, e.target.value));
  };

  return {
    industrySelectedValue,
    handleIndustrySearchChange,
    filteredIndustryOptions,
    industriesOptions,
    industrySubIndustryOptions,
    filteredSubIndustryOptions,
    handleSubIndustrySearchChange,
    shouldShowIrsName,
    shouldShowDeliveryAddressFields,
  };
};
