import { FormDialog, FormDialogWidgetProps, useMelioIntl } from '@melio/ar-domain';
import { Form, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { object, string } from 'yup';
import * as yup from 'yup';

export type CustomMessageFormFields = {
  customMessage?: string;
};

const CustomMessageFormSchema = () => {
  const { formatMessage } = useMelioIntl();
  return object().shape({
    customMessage: string()
      .max(
        500,
        formatMessage('ar.settings.activities.notifications.customizeReminder.form.fields.customMessage.valid.max.text')
      )
      .required(
        formatMessage(
          'ar.settings.activities.notifications.customizeReminder.form.fields.customMessage.valid.required.text'
        )
      ),
  }) as yup.SchemaOf<CustomMessageFormFields>;
};

export type CustomMessageDialogProps = FormDialogWidgetProps<CustomMessageFormFields>;

export const CustomMessageDialog = forwardRef<CustomMessageDialogProps>(
  ({ onSubmit, isSaving, defaultValues, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { registerField, ...useFormResults } = useMelioForm<CustomMessageFormFields>({
      schema: CustomMessageFormSchema(),
      onSubmit,
      isSaving,
      defaultValues,
      subscribeToDefaultValuesChanges: true,
    });

    return (
      <FormDialog
        data-component={CustomMessageDialog.displayName}
        data-testid="custom-message-dialog"
        useFormResults={useFormResults}
        header={formatMessage('ar.settings.activities.invoiceEmailPreferences.customMessageDialog.header')}
        description={formatMessage('ar.settings.activities.invoiceEmailPreferences.customMessageDialog.description')}
        primaryButton={{
          label: formatMessage(
            'ar.settings.activities.invoiceEmailPreferences.customMessageDialog.primaryButton.label'
          ),
        }}
        secondaryButton={{
          label: formatMessage(
            'ar.settings.activities.invoiceEmailPreferences.customMessageDialog.secondaryButton.label'
          ),
        }}
        {...props}
        ref={ref}
      >
        <Form.TextArea
          {...registerField('customMessage')}
          labelProps={{
            label: formatMessage(
              'ar.settings.activities.invoiceEmailPreferences.customMessageDialog.fields.customMessage.label'
            ),
          }}
          data-private
          maxChars={500}
        />
      </FormDialog>
    );
  }
);

CustomMessageDialog.displayName = 'CustomMessageDialog';
