import { NakedButton, SectionBanner } from '@melio/penny';

import { usePlatformIntl } from '@/translations/Intl';

type MissingVendorInfoBannerProps = {
  onEditClick: VoidFunction;
  vendorName: string;
};

export const MissingVendorInfoBannerWidget = ({ onEditClick, vendorName }: MissingVendorInfoBannerProps) => {
  const { formatMessage } = usePlatformIntl();

  return (
    <SectionBanner
      variant="warning"
      title={formatMessage('widgets.vendorDrawer.missingInfoBanner.title')}
      description={formatMessage('widgets.vendorDrawer.missingInfoBanner.description', { vendorName })}
      data-testid="vendor-missing-info-banner"
      action={
        <NakedButton
          variant="secondary"
          aria-haspopup="dialog"
          size="medium"
          label={formatMessage('widgets.vendorDrawer.missingInfoBanner.cta')}
          onClick={onEditClick}
          data-testid="vendor-missing-info-banner-cta"
        />
      }
    />
  );
};
