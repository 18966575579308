import { Group, NakedButton, Popover, PopoverProps, StatusIconSolid } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

type AttachInvoiceWarningProps = {
  popoverProps?: PopoverProps;
  onClick?: VoidFunction;
};

export const AttachInvoiceWarning = ({ popoverProps, onClick }: AttachInvoiceWarningProps) => {
  const { formatMessage } = useMelioIntl();
  const addInvoiceButtonText = formatMessage(
    'activities.batchPayments.screens.paymentIntentsTable.deliveryMethodSubCell.missingInvoicePopover.popoverActionLabel'
  );

  const icon = <StatusIconSolid size="small" variant="warning" data-testid="sub-delivery-method-cell-icon" />;
  const iconWithWrapper = popoverProps ? <Popover {...popoverProps}>{icon}</Popover> : icon;

  return (
    <Group spacing="xs" alignItems="center" justifyContent="space-between" width="full">
      <NakedButton
        label={addInvoiceButtonText}
        variant="secondary"
        onClick={onClick}
        data-testid="sub-delivery-method-attach-invoice"
      />
      {iconWithWrapper}
    </Group>
  );
};
