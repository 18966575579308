import { useFundingSources } from '@melio/platform-api';

import { useAccountingFirmBillingFees } from '../../../api/entities';
import { useSelectClients } from '../steps/SelectClients';

export const useBillingFeeSettingsData = () => {
  const {
    data: fundingSources,
    isFetching: isFundingSourcesFetching,
    error: isFundingSourceError,
    isFetched: isFundingSourceFetched,
  } = useFundingSources();

  const {
    data: accountingFirmBillingFeeData,
    isError: isAccountingFirmBillingFeeError,
    isFetching: isAccountingFirmBillingFeesFetching,
    isMutating: isAccountingFirmBillingFeeMutating,
    create: createAccountingFirmBillingFee,
    update: updateAccountingFirmBillingFee,
    isFetched: isAccountingFirmBillingFeeFetched,
  } = useAccountingFirmBillingFees();

  const {
    clientsList,
    isError: isClientsError,
    isFetching: isClientsFetching,
    handleSelect: handleSelectClients,
    isFetched: isClientsFetched,
    isClientListReady,
  } = useSelectClients();

  return {
    fundingSources,
    accountingFirmBillingFeeData,
    clientsList,
    isFetching: isFundingSourcesFetching || isAccountingFirmBillingFeesFetching || isClientsFetching,
    isMutating: isAccountingFirmBillingFeeMutating,
    isError: isAccountingFirmBillingFeeError || isClientsError || isFundingSourceError,
    createAccountingFirmBillingFee,
    updateAccountingFirmBillingFee,
    isFetched: isFundingSourceFetched && isAccountingFirmBillingFeeFetched && isClientsFetched,
    handleSelectClients,
    isClientListReady,
  };
};
