import { useZendesk } from '@/utils/zendesk';
import { useRouter } from './router.hooks';
import { useClearSession } from './session.hooks';

export const useUserSignOut = () => {
  const clearSession = useClearSession();
  const { logoutChat } = useZendesk();
  const { goToLogoutSuccessful } = useRouter();

  const signOut = async () => {
    await clearSession();
    logoutChat();
    goToLogoutSuccessful();
  };

  return { signOut };
};
