import { ErrorPage } from '@/cl/components/ErrorPage/ErrorPage.component';
import { FullScreenContainer } from '@/cl/components/FullScreenContainer/FullScreenContainer.component';
import { CapOneIcon } from '@/cl/icons/capone/success.icon';
import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';

export const LogoutSuccessfulScreen = () => {
  const { formatMessage } = usePlatformIntl();
  const { goToStart } = useRouter();

  return (
    <FullScreenContainer centered>
      <ErrorPage
        icon={CapOneIcon}
        title={formatMessage(`screens.logoutSuccessful.title`)}
        message={formatMessage(`screens.logoutSuccessful.message`)}
        primaryButton={{ text: formatMessage(`screens.logoutSuccessful.button`), onClick: goToStart }}
      />
    </FullScreenContainer>
  );
};
