import { Group } from '@melio/penny';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { usePlanRemainingPaymentsSync } from '../hooks/usePlanRemainingPaymentsSync';
import { PlanRemainingPaymentsSyncSectionBanner } from './PlanRemainingPaymentsSyncSectionBanner';
import { VendorAutoPaySetupSection } from './VendorAutoPaySetupSection';
import { VendorEBillSubscriptionSection } from './VendorEBillSubscriptionSection';

type PaymentScheduledUpsellsProps = {
  vendorId: string;
  isEBillCapable?: boolean;
  isAutoPaySetupCapable?: boolean;
};

export const PaymentScheduledUpsells = ({
  vendorId,
  isEBillCapable,
  isAutoPaySetupCapable,
}: PaymentScheduledUpsellsProps) => {
  const planRemainingPaymentsSync = usePlanRemainingPaymentsSync();

  return isEBillCapable || isAutoPaySetupCapable || planRemainingPaymentsSync ? (
    <NewSinglePaymentStepLayout.Content>
      <Group spacing="l" variant="vertical">
        {isAutoPaySetupCapable ? <VendorAutoPaySetupSection vendorId={vendorId} /> : null}
        {isEBillCapable ? <VendorEBillSubscriptionSection vendorId={vendorId} /> : null}
        {planRemainingPaymentsSync && <PlanRemainingPaymentsSyncSectionBanner {...planRemainingPaymentsSync} />}
      </Group>
    </NewSinglePaymentStepLayout.Content>
  ) : null;
};
