import { useCatalogItems, useMelioIntl, useSystemMessage } from '@melio/ar-domain';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { ImportCatalogItemsFormFields, ImportCatalogItemsScreen } from './screens';
import { onDownloadCSVTemplateFile } from './utils';

type ImportCatalogItemsActivityProps = {
  onClose: VoidFunction;
  onDone: VoidFunction;
  isOpen: boolean;
  onError?: ARErrorFunction;
};

export const ImportCatalogItemsActivity = withAnalyticsContext<ImportCatalogItemsActivityProps>(
  forwardRef(({ setAnalyticsProperties, onDone, onError, onClose, ...props }, ref) => {
    const { fileSizeLimit } = useConfig().settings;
    const { importCatalogItems, isMutating: isImportingCatalogItems } = useCatalogItems({ enabled: false, onError });
    const { triggerMessage } = useSystemMessage();
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();

    setAnalyticsProperties({
      ProductName: 'ar',
      PageName: 'upload-items',
      Flow: 'manage-invoice-items',
      Intent: 'upload-catalog-items',
    });

    const handleSubmit = ({ file }: ImportCatalogItemsFormFields) => {
      importCatalogItems(file)
        .then(({ data }) => {
          const { totalCount, successCount } = data;
          const fullSuccess = successCount === totalCount;
          const type = fullSuccess ? 'success' : 'informative';
          const title =
            totalCount > 1
              ? formatMessage('ar.modals.activities.importCatalogItems.toaster.title.success.plural.text', {
                  successCount,
                  totalCount,
                })
              : formatMessage('ar.modals.activities.importCatalogItems.toaster.title.success.singular.text', {
                  successCount,
                  totalCount,
                });
          track('Settings', 'Status', {
            StatusType: 'success',
            Intent: 'upload-customer-file',
            Cta: 'save',
            CatalogItemsUploadedCount: successCount,
            CatalogItemsInFile: totalCount,
          });
          onDone();
          triggerMessage({ type, title });
        })
        .catch((err: ARPlatformError) => {
          onClose();
          onError?.(err);
          track('Settings', 'Status', {
            StatusType: 'failure',
            ErrorType: err.message,
            Intent: 'upload-customer-file',
            Cta: 'save',
          });
          triggerMessage({
            type: 'critical',
            title: formatMessage('ar.modals.activities.importCatalogItems.toaster.title.error.text'),
          });
        });
    };

    return (
      <ImportCatalogItemsScreen
        data-testid="import-catalog-items-dialog"
        fileSizeLimit={fileSizeLimit}
        onSubmit={handleSubmit}
        isSaving={isImportingCatalogItems}
        ref={ref}
        onDownloadCSVTemplateFile={() => {
          track('Settings', 'Click', { Intent: 'download-template', Cta: 'download-csv-template' });
          onDownloadCSVTemplateFile();
        }}
        onClose={() => {
          track('Settings', 'Click', { Intent: 'exit-flow', Cta: 'cancel' });
          onClose();
        }}
        {...props}
      />
    );
  })
);
ImportCatalogItemsActivity.displayName = 'UpdateCustomerActivity';
