import { DashboardFlow } from '@melio/ar-dashboard';
import {
  addWildcardToRoutes,
  Navigate,
  RouteElement,
  useLocation,
  useNavigate,
  UserActivationProvider,
  withLocalization,
  withRouteId,
} from '@melio/ar-domain';
import {
  CreateInvoiceFlow,
  EditInvoiceFlow as _EditInvoiceFlow,
  PreviewInvoiceFlow as _PreviewInvoiceFlow,
  ShareInvoiceFlow,
  ShareMode,
} from '@melio/ar-invoice-lifecycle';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

const EditInvoiceFlow = withRouteId(_EditInvoiceFlow, 'invoiceId');
const PreviewInvoiceFlow = withRouteId(_PreviewInvoiceFlow, 'invoiceId');

const SANITIZE_QUERY_PARAMS = ['invoiceCustomerId', 'invoiceCustomerName'];

type InvoiceDashboardRouterProps = {
  WithInitialData: React.ComponentType<{ kycComplianceChecksEnabled?: boolean }>;
  DashboardLayout: React.ComponentType;
  onVisitSupportSettingsPage: VoidFunction;
  isMtlKycUpliftEnabled?: boolean;
  onCompleteKycDetails?: ({ returnUrl }?: { returnUrl?: string }) => void;
  onActivateAccount: VoidFunction;
};

export const InvoiceDashboardRouter = withAnalyticsContext<InvoiceDashboardRouterProps>(
  ({
    onVisitSupportSettingsPage,
    DashboardLayout,
    WithInitialData,
    isMtlKycUpliftEnabled,
    onCompleteKycDetails,
    onActivateAccount,
    setAnalyticsProperties,
  }) => {
    const {
      RedirectPaths,
      Paths,
      createdInvoiceId,
      goToDashboard,
      goToEditInvoice,
      goToCreateInvoice,
      goToPreviewInvoicePDF,
      goToSendInvoice,
      onCloseWrapper,
      setCreatedInvoiceId,
      goToAddCustomer,
    } = useARRouterNavigation();

    setAnalyticsProperties({
      ProductName: 'ar',
    });

    return (
      <UserActivationProvider onActivateAccount={onActivateAccount}>
        <Routes>
          <Route element={<WithInitialData kycComplianceChecksEnabled={isMtlKycUpliftEnabled} />}>
            <Route
              index
              element={<Navigate to={RedirectPaths.Dashboard} withSearchparams replace closeToast={false} />}
            />
            <Route
              path={Paths.CreateInvoice}
              element={withLocalization(
                <CreateInvoiceFlow
                  onDone={goToDashboard}
                  onClose={onCloseWrapper(goToDashboard, setCreatedInvoiceId)}
                  onIssueInvoice={(id) => goToSendInvoice(id, 'createInvoice')}
                  isMtlKycUpliftEnabled={isMtlKycUpliftEnabled}
                />
              )}
            />
            <Route
              path={Paths.EditInvoice}
              element={withLocalization(
                <EditInvoiceFlow
                  onDone={goToDashboard}
                  onClose={onCloseWrapper(goToDashboard, setCreatedInvoiceId)}
                  onIssueInvoice={(id) => goToSendInvoice(id, 'updateInvoice')}
                  isMtlKycUpliftEnabled={isMtlKycUpliftEnabled}
                />
              )}
            />
            <Route
              path={Paths.IssueInvoice}
              element={withLocalization(
                <RouteElement
                  component={ShareInvoiceFlow}
                  pathToProps={{ id: 'invoiceId', mode: 'shareMode' }}
                  onClose={onCloseWrapper(goToDashboard, setCreatedInvoiceId)}
                  onDone={goToDashboard}
                  onEditInvoice={goToEditInvoice}
                />
              )}
            />
            <Route
              path={Paths.PreviewInvoicePDF}
              element={withLocalization(
                <PreviewInvoiceFlow onClose={onCloseWrapper(goToDashboard, setCreatedInvoiceId)} />
              )}
            />
            <Route element={<DashboardLayout />}>
              <Route
                path={Paths.Dashboard}
                element={withLocalization(
                  <DashboardFlow
                    onEditInvoice={goToEditInvoice}
                    onVisitSupportSettingsPage={onVisitSupportSettingsPage}
                    onCreateInvoice={goToCreateInvoice}
                    onPreviewInvoicePDF={goToPreviewInvoicePDF}
                    onCreateCustomer={goToAddCustomer}
                    onSendReminder={(id) => goToSendInvoice(id, 'reminder')}
                    createdInvoiceId={createdInvoiceId}
                    isMtlKycUpliftEnabled={isMtlKycUpliftEnabled}
                    onCompleteKycDetails={onCompleteKycDetails}
                    onActivateAccount={onActivateAccount}
                  />
                )}
              />
            </Route>
          </Route>
        </Routes>
      </UserActivationProvider>
    );
  }
);
InvoiceDashboardRouter.displayName = 'InvoiceDashboardRouter';

type Tab = 'customers' | 'invoices';

const useARRouterNavigation = () => {
  enum Path {
    Dashboard = 'dashboard',
    CreateInvoice = 'invoice/new/edit',
    IssueInvoice = 'invoice/:id/issue/:mode',
    EditInvoice = 'invoice/:id/edit',
    PreviewInvoicePDF = 'invoice/:id/preview',
    AddCustomer = 'dashboard/customers/create',
  }

  type ARRouterLocationState = { from?: Tab; origin?: Path };

  const location = useLocation<ARRouterLocationState>();

  const _navigate = useNavigate<Path, ARRouterLocationState>();
  const navigate: typeof _navigate = (to, options) => {
    if (location.pathname.includes(Path.Dashboard)) {
      const origin = [location.pathname, location.search].join('') as Path;
      _navigate(to, { state: { ...location.state, origin }, ...options });
    } else {
      _navigate(to, { state: { origin: location.state?.origin as Path }, ...options });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onCloseWrapper<FN extends (...args: any[]) => void>(fn: FN, preFn?: FN) {
    return function (...args: Parameters<FN>) {
      preFn?.(...args);
      if (location.state?.origin) return navigate(location.state.origin, { state: undefined });
      else return fn(...args);
    };
  }

  const [createdInvoiceId, setCreatedInvoiceId] = useState<string>();
  const reset = () => setCreatedInvoiceId(undefined);

  const goToDashboard = (id?: string) => {
    setCreatedInvoiceId(id);
    navigate(Path.Dashboard, {
      closeToast: false,
      queryParams: SANITIZE_QUERY_PARAMS.reduce((acc, key) => ({ ...acc, [key]: '' }), {}),
    });
  };

  const goToEditInvoice = (id: string) => {
    reset();
    navigate(Path.EditInvoice, { pathParams: { id } });
  };

  const goToCreateInvoice = ({ customerId = '' }: { customerId?: string } = {}) => {
    reset();
    navigate(Path.CreateInvoice, { queryParams: { invoiceCustomerId: customerId } });
  };

  const goToPreviewInvoicePDF = (id: string) => {
    reset();
    navigate(Path.PreviewInvoicePDF, { pathParams: { id } });
  };

  const goToSendInvoice = (id: string, mode: ShareMode) => {
    reset();
    navigate(Path.IssueInvoice, { pathParams: { id, mode } });
  };

  const goToAddCustomer = () => {
    reset();
    navigate(Path.AddCustomer);
  };

  return {
    RedirectPaths: Path,
    Paths: addWildcardToRoutes(Path),
    createdInvoiceId,
    goToDashboard,
    goToEditInvoice,
    goToCreateInvoice,
    goToPreviewInvoicePDF,
    goToSendInvoice,
    onCloseWrapper,
    setCreatedInvoiceId,
    goToAddCustomer,
  };
};
