import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CHECKOUT_URL,
  PAYMENT_METHOD_BANK_SELECT_URL,
  PLANS_URL,
  SUBSCRIPTION_PAYMENT_METHOD_SELECT_ERROR_ROUTE,
  SUBSCRIPTION_PAYMENT_METHOD_SELECT_ROUTE,
  SUBSCRIPTION_PAYMENT_METHOD_SELECT_SUCCESS_ROUTE,
  SUBSCRIPTION_SETTINGS_PLANS_ROUTE,
} from '../constants';

export type SubscriptionPlansSettingsIntent = 'resolve-failed-fee-payment';

export const useSubscriptionRouter = () => {
  const navigate = useNavigate();

  return React.useMemo(
    () => ({
      goToAddBankAccount: ({ returnUrl }: { returnUrl: string }) =>
        navigate(`${PAYMENT_METHOD_BANK_SELECT_URL}?integrationType=plaid&returnUrl=${encodeURIComponent(returnUrl)}`),
      goToAddCard: ({ returnUrl }: { returnUrl: string }) =>
        navigate('/payment-methods/card', { state: { returnUrl } }),
      goToSubscriptionPlans: ({
        returnUrl,
        promoCode,
        analyticsEntryPoint,
        analyticsFlow,
      }: {
        returnUrl?: string;
        promoCode?: string;
        analyticsEntryPoint?: string;
        analyticsFlow?: string;
      } = {}) =>
        navigate(PLANS_URL, {
          state: {
            returnUrl,
            utm_promo: promoCode,
            analyticsEntryPoint,
            analyticsFlow,
          },
        }),
      goToPaymentMethodSelect: () => navigate(SUBSCRIPTION_PAYMENT_METHOD_SELECT_ROUTE),
      goToSubscriptionPlansSettings: ({ intent }: { intent?: SubscriptionPlansSettingsIntent } = {}) =>
        navigate(SUBSCRIPTION_SETTINGS_PLANS_ROUTE, { state: { intent } }),
      goToSubscriptionCheckout: ({
        returnUrl,
        planId,
        billingCycle,
        promoCode,
        analyticsEntryPoint,
        analyticsFlow,
      }: {
        returnUrl?: string;
        planId: string;
        promoCode?: string;
        billingCycle?: string;
        analyticsEntryPoint?: string;
        analyticsFlow?: string;
      }) =>
        navigate(
          {
            pathname: CHECKOUT_URL,
            search: `plan=${planId}`,
          },
          {
            state: { returnUrl, utm_promo: promoCode, billingCycle, analyticsEntryPoint, analyticsFlow },
          }
        ),
      goToSubscriptionSelectFundingSourceSuccess: () => navigate(SUBSCRIPTION_PAYMENT_METHOD_SELECT_SUCCESS_ROUTE),
      goToSubscriptionSelectFundingSourceError: () => navigate(SUBSCRIPTION_PAYMENT_METHOD_SELECT_ERROR_ROUTE),
    }),
    [navigate]
  );
};
