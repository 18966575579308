import { useMelioIntl } from '@melio/ar-domain';
// eslint-disable-next-line import/no-deprecated
import { _createFormFieldInput, Search, SearchOption, SearchProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { ChangeEvent } from 'react';

export type ServiceChargeSelectWidgetProps = Omit<SearchProps, 'options' | 'creatableOption' | 'emptyState'> & {
  onCreateOption: (value: string) => string;
  onChangeOption?: (value: string) => void;
  selectOptions: SearchOption[];
};

export const ServiceChargeSelectWidget = _createFormFieldInput(
  forwardRef<ServiceChargeSelectWidgetProps, 'input'>(
    ({ onChange, onCreateOption, onChangeOption, selectOptions, placeholder, ...props }, ref) => {
      const { formatMessage } = useMelioIntl();

      const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange?.(event);
        onChangeOption?.(event.target.value);
      };

      const handleCreateOption = (inputValue: string) => {
        const newOptionId = onCreateOption(inputValue);
        onChange?.({ target: { value: newOptionId } } as never);
      };

      return (
        <Search
          ref={ref}
          {...props}
          data-component={ServiceChargeSelectWidget.displayName}
          aria-label={formatMessage(
            'ar.invoiceLifecycle.activities.serviceChargeCatalog.form.inputs.name.dropdown.ariaLabel.text'
          )}
          shouldShowPresetOptions
          options={selectOptions}
          onChange={handleChange}
          placeholder={formatMessage(
            'ar.invoiceLifecycle.activities.serviceChargeCatalog.form.inputs.name.dropdown.placeholder.text'
          )}
          creatableOption={{
            label: formatMessage(
              'ar.invoiceLifecycle.activities.serviceChargeCatalog.form.inputs.name.dropdown.options.addNew.label'
            ),
            onClick: handleCreateOption,
          }}
          emptyState={{
            label: formatMessage(
              'ar.invoiceLifecycle.activities.serviceChargeCatalog.form.inputs.name.dropdown.emptyState.label'
            ),
          }}
        />
      );
    }
  )
);

ServiceChargeSelectWidget.displayName = 'ServiceChargeSelectWidget';
