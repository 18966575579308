import { Container, Group, Text } from '@melio/penny';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

import { useIsFeatureInSubscription } from '../../../../hooks/useIsFeatureInSubscription';
import { useRedirects } from '../../../../hooks/useRedirects';
import { PlanDetailsRow } from './PlanDetailsRow';
import { useAccountingSoftwareSyncDescription } from './useAccountingSoftwareSyncDescription';

export const CurrentPlanExtendedDetails = () => {
  const [accountManagerAccessCollaboratorsEnabled] = useDevFeature<boolean>(
    FeatureFlags.AccountManagerAccessCollaborators,
    false
  );
  const { formatMessage, formatCurrency } = useMelioIntl();
  const accountingSoftwareSyncDescription = useAccountingSoftwareSyncDescription();
  const { goToSettingsCollaboratorsPage } = useRedirects();

  const hasArFunctionality = useIsFeatureInSubscription({ featureName: 'ar' }).isEligible;

  const collaboratorsEligibility = useIsFeatureInSubscription({
    featureName: 'collaborators',
    requirements: { totalUnits: 1 },
  });
  const collaboratorsQuota = collaboratorsEligibility.quota;

  const onManageUsersClick = (e: React.MouseEvent<HTMLLinkElement>) => {
    e.preventDefault();
    goToSettingsCollaboratorsPage();
  };

  return (
    <Container paddingX="none" paddingY="none" data-testid="subscription-current-plan-extended-details">
      <Group width="full" variant="vertical" spacing="m" alignItems="flex-start">
        <Text textStyle="body2Semi" color="global.neutral.1000" as="h5">
          {formatMessage('activities.settings.editablePlanCard.action.planIncludes')}
        </Text>

        <Group as="ul" variant="vertical" spacing="m">
          <PlanDetailsRow
            testIdSuffix="user-seats"
            label={formatMessage('activities.settings.editablePlanCardFiserv.title.userSeats', {
              seats: collaboratorsQuota.freeUnitsLimit,
            })}
            description={
              collaboratorsQuota.freeUnitsLimit === 1
                ? formatMessage('activities.settings.editablePlanCard.title.upgradeToAddSeats')
                : collaboratorsQuota.excessUnitFee
                ? formatMessage('activities.settings.editablePlanCard.title.additionalUserSeats', {
                    cost: formatCurrency(collaboratorsQuota.excessUnitFee),
                  })
                : ''
            }
            actionLink={{
              label: formatMessage('activities.settings.editablePlanCardFiserv.seats.manage'),
              onClick: accountManagerAccessCollaboratorsEnabled ? onManageUsersClick : undefined,
            }}
          />

          {accountingSoftwareSyncDescription && (
            <PlanDetailsRow
              testIdSuffix="accounting-software-sync"
              label={formatMessage('activities.settings.editablePlanCardFiserv.title.accountingSoftwareSync')}
              description={accountingSoftwareSyncDescription}
            />
          )}

          {!hasArFunctionality && (
            <PlanDetailsRow
              testIdSuffix="current-plan-ap-functionality"
              label={formatMessage('activities.settings.editablePlanCardFiserv.title.apFunctionality')}
              description={formatMessage('activities.settings.editablePlanCardFiserv.description.apFunctionality')}
            />
          )}

          {hasArFunctionality && (
            <PlanDetailsRow
              testIdSuffix="current-plan-ap-ar-functionality"
              label={formatMessage('activities.settings.editablePlanCardFiserv.title.apArFunctionality')}
              description={formatMessage('activities.settings.editablePlanCardFiserv.description.apArFunctionality')}
            />
          )}
        </Group>
      </Group>
    </Container>
  );
};
