import { Modal } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Payment, usePayment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { ComponentProps } from 'react';

import { NoteToSelfBaseModal } from './NoteToSelfBaseModal';

export type PaymentNoteToSelfModalProps = Pick<ComponentProps<typeof Modal>, 'isOpen' | 'onClose'> & {
  paymentId: string;
  onSuccess?: (data: Payment) => void;
};

export const PaymentNoteToSelfModal = ({ paymentId, isOpen, onClose, onSuccess }: PaymentNoteToSelfModalProps) => {
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();
  const { track } = useAnalytics();

  const onSuccessfulUpdate = (data: Payment) => {
    showMessage({
      type: 'success',
      title: formatMessage(
        payment?.userNote
          ? 'activities.paymentNoteToSelfModal.edit.success.toast'
          : 'activities.paymentNoteToSelfModal.add.success.toast'
      ),
    });
    track('Dashboard', 'Click', { Intent: 'payment-updated', Cta: 'confirm' });
    onSuccess?.(data);
    onClose();
  };

  const onUpdateError = () => {
    showMessage({
      type: 'error',
      title: formatMessage('activities.paymentNoteToSelfModal.error.toast'),
    });
    onClose();
  };

  const {
    data: payment,
    isMutating: isUpdatingPayment,
    update: updatePayment,
  } = usePayment({
    id: paymentId,
    onUpdate: onSuccessfulUpdate,
    onUpdateError,
  });

  return (
    <NoteToSelfBaseModal
      isOpen={isOpen}
      onClose={onClose}
      note={payment?.userNote || ''}
      isUpdating={isUpdatingPayment}
      updateNote={(note) => updatePayment({ userNote: note })}
      onSuccess={(data) => onSuccess?.(data as Payment)}
    />
  );
};
