import { Chip, Group, Text, useBreakpoint } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { PaymentChipFilter, PaymentsTabFilter } from '../types';

type FilterChipsProps = {
  options: PaymentChipFilter[];
  activeFilter: PaymentsTabFilter;
  onChange: (id: PaymentsTabFilter) => void;
};
export const FilterChips = ({ options, activeFilter, onChange }: FilterChipsProps) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  return (
    <Group
      variant="horizontal"
      spacing="xs"
      data-testid="filter-chips"
      allowOverflowX={isExtraSmallScreen}
      alignItems="center"
      role="group"
      aria-labelledby="payments-tab-filters-label"
    >
      <Text textStyle="body3Semi" color="global.neutral.900" id="payments-tab-filters-label">
        {formatMessage('activities.payDashboard.paymentsTab.filters.label')}
      </Text>
      {options.map(({ id, label, status }, index) => (
        <Chip
          key={`${id}.${index}`}
          data-testid={`filter-${id}`}
          label={label}
          onClick={() => onChange(id)}
          isSelected={activeFilter === id}
          status={status}
        />
      ))}
    </Group>
  );
};
