import { getFeeTypeByFundingSource } from '@melio/ap-domain';
import { availableBalanceUpdatedAtFormat, getFundingSourceBalance } from '@melio/ap-widgets';
import { PillProps } from '@melio/penny';
import { FeeCatalog, FeeCatalogValueTypeEnum, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useFormatExpirationDate } from '@melio/platform-utils';

const useFeePill = (fee?: FeeCatalog) => {
  const { formatCurrency, formatMessage } = useMelioIntl();

  if (!fee) {
    return undefined;
  }

  const feeText =
    fee?.valueType === FeeCatalogValueTypeEnum.Percent
      ? formatMessage('activities.paymentFlow.form.content.fundingSourceCard.fee.percent', {
          feeValue: fee.value,
        })
      : formatMessage('activities.paymentFlow.form.content.fundingSourceCard.fee.fixed', {
          feeValue: formatCurrency(fee.value),
        });

  return [
    {
      label: feeText,
      status: 'brand' as const,
      type: 'secondary' as const,
      'data-testid': 'fee-pill',
    },
  ];
};

export const useFundingSourceCardProps = ({
  fundingSource,
  feeCatalog,
}: {
  fundingSource: FundingSource;
  feeCatalog?: FeeCatalog[];
}): {
  title: string;
  line1?: string;
  line2?: string;
  pillProps?: Array<PillProps & { 'data-testid': string }>;
} => {
  const { formatMessage, formatDate, formatCurrency } = useMelioIntl();
  const { formatExpirationDate } = useFormatExpirationDate();
  const feeForFundingSource = feeCatalog?.find((fee) => fee.feeType === getFeeTypeByFundingSource(fundingSource));
  const pillProps = useFeePill(feeForFundingSource);

  if (fundingSource.type === 'bank-account') {
    const balance = getFundingSourceBalance(fundingSource);

    return {
      title: fundingSource.displayName,
      line1: fundingSource.nickname,
      pillProps,
      line2: balance
        ? formatMessage('activities.paymentFlow.form.content.fundingSourceCard.bank-account.line2', {
            availableBalance: formatCurrency(balance.availableBalance),
            availableBalanceUpdatedAt: formatDate(balance.availableBalanceUpdatedAt, availableBalanceUpdatedAtFormat),
          })
        : undefined,
    };
  } else if (fundingSource.type === 'card') {
    return {
      title: fundingSource.displayName,
      line1: fundingSource.nickname,
      pillProps,
      line2: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.line2', {
        expirationDate: formatExpirationDate({
          month: fundingSource.details.expirationMonth,
          year: fundingSource.details.expirationYear,
        }),
      }),
    };
  } else {
    return {
      title: 'not implemented',
    };
  }
};
