import { useMelioIntl } from '@melio/ar-domain';
import { useDateUtils } from '@melio/platform-utils';
import {
  addDays,
  addMonths,
  addQuarters,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subYears,
} from 'date-fns';

import { DatePreset, DatePresetsType, DateRange, DateRangeFilterTypes } from '../types';

const getDateRange = (preset: DatePreset, today: Date): DateRange => {
  const formatDate = (date: Date): string => format(date, 'yyyy-MM-dd');

  switch (preset) {
    case 'ThisMonth':
      return { start: formatDate(startOfMonth(today)), end: formatDate(endOfMonth(today)) };
    case 'Next30Days':
      return { start: formatDate(today), end: formatDate(addDays(today, 30)) };
    case 'NextMonth':
      return { start: formatDate(startOfMonth(addMonths(today, 1))), end: formatDate(endOfMonth(addMonths(today, 1))) };
    case 'ThisQuarter':
      return { start: formatDate(startOfQuarter(today)), end: formatDate(endOfQuarter(today)) };
    case 'NextQuarter':
      return {
        start: formatDate(startOfQuarter(addQuarters(today, 1))),
        end: formatDate(endOfQuarter(addQuarters(today, 1))),
      };
    case 'Last30Days':
      return { start: formatDate(subDays(today, 30)), end: formatDate(today) };
    case 'ThisWeek':
      return { start: formatDate(startOfWeek(today)), end: formatDate(today) };
    case 'PreviousMonth':
      return { start: formatDate(startOfMonth(subMonths(today, 1))), end: formatDate(endOfMonth(subMonths(today, 1))) };
    case 'PreviousQuarter':
      return {
        start: formatDate(startOfQuarter(subQuarters(today, 1))),
        end: formatDate(endOfQuarter(subQuarters(today, 1))),
      };
    case 'ThisYear':
      return { start: formatDate(startOfYear(today)), end: formatDate(endOfYear(today)) };
    case 'PreviousYear':
      return { start: formatDate(startOfYear(subYears(today, 1))), end: formatDate(endOfYear(subYears(today, 1))) };
    default:
      throw new Error('Invalid date preset');
  }
};

const getDatePresets = (type: DateRangeFilterTypes): DatePreset[] => {
  switch (type) {
    case 'dueDateRange':
      return ['ThisMonth', 'Next30Days', 'NextMonth', 'ThisQuarter', 'NextQuarter', 'ThisYear', 'Custom'];
    case 'updatedAtRange':
      return [
        'ThisMonth',
        'ThisWeek',
        'Last30Days',
        'PreviousMonth',
        'ThisQuarter',
        'PreviousQuarter',
        'ThisYear',
        'PreviousYear',
        'Custom',
      ];
    default:
      throw new Error('Invalid date range filter type');
  }
};

export const useDateRangeFilter = (type: DateRangeFilterTypes) => {
  const { createDate } = useDateUtils();
  const today = createDate();
  const { formatMessage } = useMelioIntl();

  const presets = getDatePresets(type);

  const getFilterLabel = () => {
    switch (type) {
      case 'dueDateRange':
        return formatMessage('ar.dashboard.activities.invoiceTable.filters.dueDate.label');
      case 'updatedAtRange':
        return formatMessage('ar.dashboard.activities.invoiceTable.filters.updatedAt.label');
      default:
        throw new Error('Invalid date range filter type');
    }
  };

  const getPresetLabel = (preset: DatePreset): string => {
    const DatePresets: DatePresetsType = {
      ThisWeek: formatMessage('ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.thisWeek'),
      ThisMonth: formatMessage('ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.thisMonth'),
      Last30Days: formatMessage('ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.last30Days'),
      Next30Days: formatMessage('ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.next30Days'),
      NextMonth: formatMessage('ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.nextMonth'),
      ThisQuarter: formatMessage('ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.thisQuarter'),
      NextQuarter: formatMessage('ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.nextQuarter'),
      PreviousMonth: formatMessage('ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.previousMonth'),
      PreviousQuarter: formatMessage(
        'ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.previousQuarter'
      ),
      ThisYear: formatMessage('ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.thisYear'),
      PreviousYear: formatMessage('ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.previousYear'),
      Custom: formatMessage('ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.custom'),
    };

    return DatePresets[preset];
  };

  const getPresetRange = (preset: DatePreset): DateRange => getDateRange(preset, today);

  return {
    presets,
    filterLabel: getFilterLabel(),
    getPresetLabel,
    getPresetRange,
  };
};
