import { Group, Table, Text } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { DebitDateTooltip } from '../utils';

export const DebitDateCell = ({ payment, debitDate }: { payment: Payment; debitDate?: Date }) => {
  const { formatDate } = useMelioIntl();

  return (
    <Table.Cell data-testid="pending-approval-payments-row-debit-date-cell">
      <Group variant="horizontal" spacing="xxs">
        <Text textStyle="body3">{formatDate(debitDate ?? payment.scheduledDate, { dateStyle: 'medium' })}</Text>
        <DebitDateTooltip payment={payment} debitDate={debitDate} />
      </Group>
    </Table.Cell>
  );
};
