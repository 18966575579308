import { useSearchParams } from 'react-router-dom';
import { AddBankAccountActivity } from '@melio/ap-activities';
import { withAnalyticsContext } from '@melio/platform-analytics';

import { usePaymentMethodActivityEvents } from '@/hooks/usePaymentMethodActivityEvents';

export const PaymentMethodBankSelectScreen = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get('returnUrl');
  const { onBack, onDone, onClose } = usePaymentMethodActivityEvents(returnUrl);

  setAnalyticsProperties({ Flow: 'settings-payment-method' });

  return <AddBankAccountActivity onBack={onBack} onClose={onClose} onDone={onDone} />;
});

PaymentMethodBankSelectScreen.displayName = 'PaymentMethodBankSelectScreen';
