import { useAccountingPlatformName } from '@melio/ap-widgets';
import { useAccountingPlatforms } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

import { createTourIdSelector } from '../../../../utils';

export const useSyncNowStep = ({ enabled }: { enabled: boolean }) => {
  const { formatMessage } = useMelioIntl();
  const [isSyncedTourStepChangesEnabled] = useDevFeature(
    FeatureFlags.FTEChangesForOrgsConnectedToAccountingPlatform,
    false
  );
  const { activeAccountingPlatform, isLoading: isActiveAccountingPlatformLoading } = useAccountingPlatforms({
    enabled: isSyncedTourStepChangesEnabled && enabled,
  });
  const activeAccountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);

  const shouldShowSyncAlreadyConnectedTourStep = isSyncedTourStepChangesEnabled && !!activeAccountingPlatformName;

  const syncConnectionFormatKey = shouldShowSyncAlreadyConnectedTourStep ? 'syncAlreadyConnected' : 'sync';
  return {
    isLoading: isActiveAccountingPlatformLoading,
    step: {
      target: createTourIdSelector('sync-now-button'),
      title: formatMessage(`activities.payDashboard.tour.step.${syncConnectionFormatKey}.title`, {
        activeAccountingPlatformName,
      }),
      description: formatMessage(`activities.payDashboard.tour.step.${syncConnectionFormatKey}.content`, {
        activeAccountingPlatformName,
      }),
    },
  };
};
