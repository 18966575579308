import { FormSelectNewOption, Group, Text, Typography } from '@melio/penny';

import { EbillAmountDropdownOption } from './types';

export const EbillOptionRenderer = (option: FormSelectNewOption<string>) => (
  <Group variant="vertical" alignItems="flex-start" spacing="xxxs">
    <Text textStyle="body3">{option.label}</Text>
    <Typography.Description label={(option as EbillAmountDropdownOption).description} />
  </Group>
);
