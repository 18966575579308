import { usePaymentsLateApprovalsEnabled } from '@melio/ap-domain';
import { Container, Group, Table, Text } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

import { PaymentLateApprovalIndication } from './PaymentLateApprovalIndication';

export const PaymentsTabDebitDateCell = ({ payment }: { payment: Payment }) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const [isFinancingEnabled] = useDevFeature<boolean>(FeatureFlags.PlatformFinancing, false);
  const getPaymentsLateApprovalsEnabled = usePaymentsLateApprovalsEnabled();

  const paymentsLateApprovalsEnabled = getPaymentsLateApprovalsEnabled({ payments: [payment] });
  const paymentLateApprovalEnabled = !!paymentsLateApprovalsEnabled[payment.id]?.isEnabled;
  return (
    <Table.Cell
      data-testid={`payments-tab-row-${payment.id}-debit-date-cell`}
      placeholder={formatMessage('activities.payDashboard.paymentsTab.table.cells.notAvailable')}
    >
      {payment.scheduledDate && (
        <Group variant="vertical" spacing="xxs" alignItems="flex-start">
          <Text textStyle="body3">{formatDate(payment.scheduledDate, { dateStyle: 'medium' })}</Text>
          {isFinancingEnabled && payment?.loan && (
            <Container width="full">
              <Text textStyle="body4" color="global.neutral.900">
                {formatMessage('activities.payDashboard.paymentsTab.table.cells.debitDate.installments', {
                  installmentNumber: payment.loan.nextInstallmentNumber ?? 1,
                  totalInstallments: payment.loan.installments.length,
                })}
              </Text>
            </Container>
          )}
          {paymentLateApprovalEnabled ? <PaymentLateApprovalIndication payment={payment} /> : null}
        </Group>
      )}
    </Table.Cell>
  );
};
