import { InternationalAccountDeliveryMethod, Vendor } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';
import { useEffect, useMemo, useState } from 'react';

import { DeliveryMethodSelectionActivityProps, DeliveryMethodSelectionStep } from './types';

export const useDeliveryMethodSelectionActivityStep = ({
  onFirstStepBack,
  vendor,
  billCurrency,
}: {
  onFirstStepBack: DeliveryMethodSelectionActivityProps['onBack'];
  vendor?: Vendor;
  billCurrency?: string;
}) => {
  const [currentStep, goToStep] = useState<DeliveryMethodSelectionStep>();
  const [enabledInternationalUSDWise] = usePartnerFeature('EnableInternationalUSDWise', false);

  const isPaymentCurrencyNotUsd = useMemo(
    () => (vendor?.currency && vendor?.currency !== 'USD') || (billCurrency && billCurrency !== 'USD'),
    [billCurrency, vendor]
  );

  useEffect(() => {
    const internationalDeliveryMethod = vendor?.deliveryMethods.find(
      (dm): dm is InternationalAccountDeliveryMethod => dm.type === 'international-account'
    );

    if (isPaymentCurrencyNotUsd) {
      goToStep('ADD_VENDOR_INTERNATIONAL_FX_DETAILS');
    } else if (
      enabledInternationalUSDWise &&
      internationalDeliveryMethod &&
      internationalDeliveryMethod.details.identifierType !== 'v2'
    ) {
      goToStep('ADD_VENDOR_INTERNATIONAL_FX_DETAILS');
    } else {
      goToStep('DELIVERY_METHOD_SELECTION');
    }
  }, [enabledInternationalUSDWise, isPaymentCurrencyNotUsd, vendor?.deliveryMethods]);

  const goToPreviousStep = () => {
    if (currentStep === 'DELIVERY_METHOD_SELECTION' || isPaymentCurrencyNotUsd) {
      onFirstStepBack();
    } else {
      goToStep('DELIVERY_METHOD_SELECTION');
    }
  };

  return { currentStep, goToStep, goToPreviousStep };
};
