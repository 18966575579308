import { useBill, useBills } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { useCompleteComplianceInformation } from './useCompleteComplianceInformation';
import { useMissingCompanyLegalInfoFields } from './useMissingCompanyLegalInfoFields';

const useShouldCollectOld = ({ billIds = [] }: { billIds?: string[] }) => {
  const { isLoading: isMissingCompanyLegalInfoFieldsLoading, missingCompanyLegalInfoFields = [] } =
    useMissingCompanyLegalInfoFields() || {};
  const { isLoading: isLoadingMissingKycComplianceInformation, missingFields } = useCompleteComplianceInformation();

  // This is split in two to match the current flow and avoid the extra roundtrip
  const { isLoading: isLoadingBillsResult, data: billsResult } = useBills({
    enabled: billIds.length > 1,
    params: { search: { 'bill.id': billIds }, expand: ['paymentRequest'] },
  });
  const { isLoading: isLoadingBillResult, data: billResult } = useBill({
    enabled: billIds.length === 1,
    id: billIds[0],
  });

  const isLoadingBills = isLoadingBillsResult || isLoadingBillResult;
  const bills =
    billIds.length === 1 && billResult ? [billResult] : billIds.length > 1 && billsResult ? billsResult : undefined;

  // A little bit confusing but:
  // 1. If we're not loading bills, and we have no bills, we should look at the missing company legal info fields
  // 2. If we do have bills, we should look at the paymentRequest field
  // This covers the case where we initiate a payment from a flow with no bills (e.g. recurring)

  const shouldCollectLegalInfoOnPaymentFlow = isLoadingBills
    ? false
    : (bills === undefined || bills.length === 0 ? true : bills.some((bill) => !bill.paymentRequest)) &&
      (missingCompanyLegalInfoFields?.length > 0 || !!missingFields.length);

  return {
    isLoadingShouldCollectLegalInfoOnPaymentFlow:
      isMissingCompanyLegalInfoFieldsLoading || isLoadingMissingKycComplianceInformation,
    shouldCollectLegalInfoOnPaymentFlow,
  };
};

const useShouldCollectNew = ({ billIds = [] }: { billIds?: string[] }) => {
  // This is split in two to match the current flow and avoid the extra roundtrip
  const { isLoading: isLoadingBillsResult, data: billsResult } = useBills({
    enabled: billIds.length > 1,
    params: { search: { 'bill.id': billIds }, expand: ['paymentRequest'] },
  });
  const { isLoading: isLoadingBillResult, data: billResult } = useBill({
    enabled: billIds.length === 1,
    id: billIds[0],
  });

  const isLoadingBills = isLoadingBillsResult || isLoadingBillResult;
  const bills =
    billIds.length === 1 && billResult ? [billResult] : billIds.length > 1 && billsResult ? billsResult : undefined;

  // A little bit confusing but:
  // 1. If we're not loading bills, and we have no bills, we should look at the missing company legal info fields
  // 2. If we do have bills, we should look at the paymentRequest field
  // This covers the case where we initiate a payment from a flow with no bills (e.g. recurring)

  const { isLoading: isLoadingMissingKycComplianceInformation, missingFields } = useCompleteComplianceInformation({
    isInitiator: bills === undefined || bills.length === 0 ? true : bills.some((bill) => !bill.paymentRequest),
  });

  const shouldCollectLegalInfoOnPaymentFlow = isLoadingBills ? false : !!missingFields.length;

  return {
    isLoadingShouldCollectLegalInfoOnPaymentFlow: isLoadingMissingKycComplianceInformation,
    shouldCollectLegalInfoOnPaymentFlow,
  };
};

export const useShouldCollectLegalInfoOnPaymentFlow = ({ billIds = [] }: { billIds?: string[] } = {}) => {
  const [enabled] = useDevFeature(FeatureFlags.RiskMtlStageAndInitiatorParams, false);

  const oldShouldCollect = useShouldCollectOld({ billIds });
  const newShouldCollect = useShouldCollectNew({ billIds });

  return enabled ? newShouldCollect : oldShouldCollect;
};
