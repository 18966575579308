export const cardPaymentSharePercentageRanges = [
  { label: '0% - 10%', value: '5', testId: '5' },
  { label: '11% - 20%', value: '15', testId: '15' },
  { label: '21% - 30%', value: '25', testId: '25' },
  { label: '31% - 40%', value: '35', testId: '35' },
  { label: '41% - 50%', value: '45', testId: '45' },
  { label: '51% - 60%', value: '55', testId: '55' },
  { label: '61% - 70%', value: '65', testId: '65' },
  { label: '71% - 80%', value: '75', testId: '75' },
  { label: '81% - 90%', value: '85', testId: '85' },
  { label: '91% - 100%', value: '95', testId: '95' },
];
