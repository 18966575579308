/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@chakra-ui/react';
import { usePaymentLateApprovalTooltip, usePaymentsLateApprovalsEnabled } from '@melio/ap-domain';
import {
  getFundingSourceIconType,
  getFundingSourceImageProps,
  isCreditCardFundingSource,
  PaymentReviewLineItem,
  useGetFundingSourceCardHelperText,
  useGetFundingSourceLabel,
} from '@melio/ap-widgets';
import { Group, VisuallyHidden } from '@melio/penny';
import { sanitizeStringForAnalytics, useAnalytics } from '@melio/platform-analytics';
import { PaymentFullyExpanded, PaymentStatusEnum } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { RefObject } from 'react';

import { MicroDepositModalRefHandlers } from '../../../../funding-sources';
import { LoanSection } from './loan-section/LoanSection';
import { SectionBlock } from './SectionBlock';

export const PayFromSection = ({
  payment,
  microDepositModalRef,
}: {
  payment: PaymentFullyExpanded;
  microDepositModalRef?: RefObject<MicroDepositModalRefHandlers>;
}) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const [isFinancingEnabled] = useDevFeature<boolean>(FeatureFlags.PlatformFinancing, false);
  const { track } = useAnalytics();

  const getPaymentsLateApprovalsEnabled = usePaymentsLateApprovalsEnabled();
  const mainLabelTooltipProps = usePaymentLateApprovalTooltip(payment);

  const paymentsLateApprovalsEnabled = getPaymentsLateApprovalsEnabled({ payments: [payment] });
  const paymentLateApprovalEnabled = !!paymentsLateApprovalsEnabled[payment.id]?.isEnabled;
  const { fundingSource, loan, scheduledDate, status: paymentStatus } = payment;
  const { type: fundingSourceType, displayName, nickname } = fundingSource;

  const listItemLabel =
    paymentStatus === PaymentStatusEnum.Completed
      ? formatMessage('widgets.paymentDetails.payFrom.completedPaymentDate')
      : formatMessage(
          `widgets.paymentDetails.payFrom.scheduledDate.label.${
            isCreditCardFundingSource(fundingSource) ? 'creditCardFundingType' : 'default'
          }`
        );

  const helperText = useGetFundingSourceCardHelperText({
    fundingSource,
    onClick: () => {
      track('PaymentMethod', 'Click', {
        PageName: 'payment-summary',
        Flow: 'dashboard',
        Intent: 'verify-payment-method',
        PaymentMethodId: payment.fundingSourceId,
        PaymentMethodType: fundingSource?.type,
        Cta: sanitizeStringForAnalytics(
          formatMessage('widgets.paymentMethods.paymentMethodLineItem.bankAccount.unverified.link')
        ),
        EntryPoint: 'payment-summary',
      });
      microDepositModalRef?.current?.openModal();
    },
  });

  const fundingSourceImage = getFundingSourceImageProps(fundingSource);
  const fundingSourceIconType = getFundingSourceIconType(fundingSource);
  const fundingSourceLabel = useGetFundingSourceLabel(fundingSource);

  return (
    <Group data-testid="pay-from-section">
      {isFinancingEnabled && loan ? (
        <LoanSection payment={payment} />
      ) : (
        <Group variant="vertical" spacing="s">
          <SectionBlock.Title label={formatMessage('widgets.paymentDetails.payFrom.title')} />
          <PaymentReviewLineItem
            labelProps={{ label: fundingSourceLabel }}
            mainLabelProps={{ label: nickname || displayName }}
            contentTestId="pay-from-section-bank-details"
            {...(fundingSourceType === 'bank-account' && helperText ? { descriptionProps: { label: helperText } } : {})}
            {...(fundingSourceImage ? { imageIcon: fundingSourceImage } : { icon: { type: fundingSourceIconType } })}
          />
          <PaymentReviewLineItem
            labelProps={{ label: listItemLabel }}
            mainLabelProps={{
              label: formatDate(scheduledDate, { dateStyle: 'medium' }),
              tooltipProps: paymentLateApprovalEnabled
                ? {
                    ...mainLabelTooltipProps,
                    content: (
                      <>
                        <VisuallyHidden>{mainLabelTooltipProps.title} tooltip</VisuallyHidden>
                        <Box as="span" display="inline-flex" textStyle="body4Semi">
                          {mainLabelTooltipProps.title}
                        </Box>
                        {mainLabelTooltipProps.label}
                      </>
                    ),
                  }
                : undefined,
            }}
            contentTestId="pay-from-section-deducted-from"
            icon={{ type: 'scheduled' }}
          />
        </Group>
      )}
    </Group>
  );
};
