import { Outlet } from 'react-router-dom';
import { useIsAccountingFirm } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';

import { useHasAccessToFirm } from '@/hooks/useHasAccessToFirm.hooks';

export const DemoRoutesAccess = () => {
  const config = useConfig();
  if (config.production) {
    return <></>;
  }

  return <Outlet />;
};

export const WithAccessToFirm = () => {
  const hasAccessToFirm = useHasAccessToFirm();
  if (!hasAccessToFirm) {
    return null;
  }

  return <Outlet />;
};

export const RestrictedToAccountingFirmOnly = () => {
  const isAccountingFirm = useIsAccountingFirm();
  if (!isAccountingFirm) {
    return null;
  }

  return <Outlet />;
};
