import { Table } from '@melio/penny';
import { Bill, PaymentIntent } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import _compact from 'lodash/compact';

export type ActionsSubCellProps = {
  bill: Bill;
  paymentIntent: PaymentIntent;
  onViewBillDetailsClick: (paymentIntentId: PaymentIntent['id'], bill: Bill) => void;
  onSetInvoiceNumberClick: (paymentIntentId: PaymentIntent['id']) => void;
  onRemoveBillsClick: (paymentIntentId: PaymentIntent['id'], billId?: Bill['id']) => void;
};

export const ActionsSubCell = forwardRef<ActionsSubCellProps, 'div'>(
  ({ onViewBillDetailsClick, onSetInvoiceNumberClick, onRemoveBillsClick, bill, paymentIntent, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const [isVendorsBatchEnabled] = useDevFeature(FeatureFlags.PlatformVendorsBatchNewPaymentsEnabled, false, {
      shouldTrack: true,
    });

    const viewBillDetailsAction = {
      label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.actionsCell.viewBillDetails'),
      onClick: () => {
        onViewBillDetailsClick(paymentIntent.id, bill);
      },
      dataTestId: 'view-bill-details-action',
    };

    const invoiceNumberAction = {
      label: formatMessage(
        `activities.batchPayments.screens.paymentIntentsTable.actionsCell.invoiceNumber.${
          paymentIntent.billInfo.invoice.number ? 'edit' : 'add'
        }`
      ),
      onClick: () => onSetInvoiceNumberClick(paymentIntent.id),
      dataTestId: 'invoice-number-action',
    };

    const removeFromListAction = {
      label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.actionsCell.removeBillFromList'),
      onClick: () => onRemoveBillsClick(paymentIntent.id, bill.id),
      dataTestId: 'remove-from-list-action',
      variant: 'critical',
    };

    const actions = _compact([
      viewBillDetailsAction,
      isVendorsBatchEnabled ? invoiceNumberAction : null,
      removeFromListAction,
    ]);
    return (
      <Table.ActionsCell
        options={actions}
        data-testid={`${paymentIntent.id}-${bill.id}-actions`}
        {...props}
        ref={ref}
      />
    );
  }
);

ActionsSubCell.displayName = 'ActionsSubCell';
