import { AddBillV2FormValuesResult, AddBillV2OriginEnum } from '@melio/ap-activities';
import { AddBillV2SubmitType, EditBillActivity } from '@melio/ap-activities/src/components/edit-bill';
import { useNavigate } from '@melio/platform-utils';

import { useRouter } from '@/hooks/router.hooks';
import { PayDashboardTabs } from '@/types/payDashboard.types';

export const EditBillScreen = ({ id, returnUrl }: { id: string; returnUrl?: string }) => {
  const navigate = useNavigate();
  const { goBack, replaceToPayDashboardTab, navigateToSchedulePayment, generateNPEDashboardLink } = useRouter();

  const onDone = (_: AddBillV2FormValuesResult, saveType?: AddBillV2SubmitType) => {
    if (saveType === AddBillV2SubmitType.CONTINUE_TO_PAY) {
      navigateToSchedulePayment({
        billId: id,
        returnUrl: generateNPEDashboardLink('bills'),
      });
    } else {
      if (returnUrl) {
        navigate(returnUrl, { state: { origin: AddBillV2OriginEnum.Edit } });
      } else replaceToPayDashboardTab(PayDashboardTabs.Inbox, id);
    }
  };

  const onError = () => {
    navigate('../../notfound');
  };

  return <EditBillActivity onClose={goBack} onDone={onDone} onBack={goBack} id={id} onError={onError} />;
};
