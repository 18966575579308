// eslint-disable-next-line import/no-deprecated
import { _createFormFieldInput, Search, SearchOption, SearchProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useMemo, useState } from 'react';

import { useCatalogItems } from '../api-hooks';
import { useMelioIntl } from '../i18n';

type ChangeEvent = React.ChangeEvent<Override<HTMLInputElement, { value: string }>>;

export type CatalogItemSelectInputProps = Override<
  Omit<SearchProps, 'options' | 'creatableOption' | 'emptyState'>,
  {
    onChange?: (event: ChangeEvent) => void;
    value?: string;
    onCreate?: (id: string) => void;
    excludeIds?: string[];
  }
>;

export const CatalogItemSelectInput = _createFormFieldInput(
  forwardRef<CatalogItemSelectInputProps, 'input'>(
    ({ onChange, onCreate, value, placeholder, excludeIds, ...props }, ref) => {
      const [selectOptions, setSelectOptions] = useState<SearchOption[]>([]);
      const [creatingPlaceHolder, setCreatingPlaceHolder] = useState<string | null>(null);

      const { formatCurrency, formatMessage } = useMelioIntl();

      const { data, isFetched, isMutating, create: createCatalogItem } = useCatalogItems();
      const catalogItems = useMemo(
        // exclude ids from the list of options, but include the current value if available (for editing)
        () => data?.filter((item) => value == item.id || !excludeIds?.includes(item.id)),
        [data, excludeIds, value]
      );

      useEffect(() => {
        if (isFetched && catalogItems && catalogItems.length > 0) {
          setSelectOptions(
            catalogItems
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((catalogItem) => ({
                label: catalogItem.name,
                value: catalogItem.id,
                description: catalogItem.description,
                pillProps: { label: formatCurrency(catalogItem.price), status: 'neutral' },
              }))
          );
        }
      }, [catalogItems, formatCurrency, isFetched]);

      const handleCreateOption = (inputValue: string) => {
        // This is so we could display the name of the soon-to-be-created vendor as the placeholder.
        setCreatingPlaceHolder(inputValue);
        createCatalogItem({ name: inputValue, price: 0, currency: 'USD', description: '', taxable: false })
          .then((res) => {
            setSelectOptions((value) => [...value] as SearchOption[]);
            onChange?.({ target: { value: res.id } } as ChangeEvent);
            onCreate?.(res.id);
          })
          .catch((error) => {
            setCreatingPlaceHolder(null);
            throw error;
          });
      };

      return (
        <Search
          data-component="CatalogItemSelectInput"
          ref={ref}
          {...props}
          value={catalogItems?.find((i) => i.id == value)?.id}
          shouldShowPresetOptions
          options={selectOptions}
          onChange={onChange as never}
          placeholder={isMutating && creatingPlaceHolder ? creatingPlaceHolder : placeholder}
          creatableOption={{
            label: formatMessage('ar.invoiceLifecycle.activities.invoiceTable.inputs.selectCatalog.addNewButton.text'),
            onClick: handleCreateOption,
            shouldDisplay: (value) => (value === '' ? false : true),
          }}
          emptyState={{
            label:
              selectOptions.length > 0
                ? formatMessage(
                    'ar.invoiceLifecycle.activities.invoiceTable.inputs.selectCatalog.emptyState.noOptions.text'
                  )
                : formatMessage(
                    'ar.invoiceLifecycle.activities.invoiceTable.inputs.selectCatalog.emptyState.addNew.text'
                  ),
          }}
        />
      );
    }
  )
);

CatalogItemSelectInput.displayName = 'CatalogItemSelectInput';
