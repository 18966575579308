import { Bill, useBills, usePaginatedVendors, VendorGroup } from '@melio/platform-api';
import { Search, VendorExpandParam } from '@melio/platform-api/src/entities-v2/vendor/types';

import { ExpandedSortingState } from '../../types';
import { usePayDashboardPagination } from '../Pagination';
import { convertVendorsTabSortToApiSort } from './utils';

type UseVendorsTabDataResult = ReturnType<typeof usePaginatedVendors> & { unpaidBills: Bill[] };

export const useVendorsTabData = ({
  searchTerm,
  enabled = true,
  selectedVendorGroupIds,
  sorting,
}: {
  searchTerm: string | null;
  sorting: ExpandedSortingState | null;
  enabled?: boolean;
  selectedVendorGroupIds?: VendorGroup['id'][];
}): UseVendorsTabDataResult => {
  const { pageSize } = usePayDashboardPagination();

  const searchTermParams = searchTerm ? { searchTerm } : {};
  const filterParams: Search = {};
  let expandParams: VendorExpandParam = 'lastPaymentDate';

  if (selectedVendorGroupIds && selectedVendorGroupIds?.length > 0) {
    filterParams['vendorGroup.id'] = selectedVendorGroupIds;
    expandParams = [expandParams, 'groups'];
  }

  const paginationResponse = usePaginatedVendors({
    cacheTime: 0,
    params: {
      ...searchTermParams,
      ...(Object.keys(filterParams).length > 0 ? { search: filterParams } : {}),
      expand: expandParams,
      limit: pageSize,
      sort: sorting ? convertVendorsTabSortToApiSort(sorting) : undefined,
    },
    enabled,
  });
  const { data: vendorsResult, isLoading: isLoadingVendors } = paginationResponse;
  const vendors = vendorsResult?.data ?? [];

  const { data: unpaidBills = [], isLoading: isLoadingBills } = useBills({
    cacheTime: 0,
    enabled: enabled ? vendors.length > 0 : false,
    params: {
      search: { 'vendor.id': vendors.map(({ id }) => id).sort() },
      shouldFilterPaidBills: true,
      expand: 'none',
    },
  });

  return {
    ...paginationResponse,
    isLoading: isLoadingVendors || isLoadingBills,
    unpaidBills,
  } as UseVendorsTabDataResult;
};
