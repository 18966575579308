import { addWildcardToRoutes, RouteElement, useNavigate, withOutlet } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import {
  AddCatalogItemDialogActivity,
  CatalogItemsTableActivity,
  DeleteCatalogItemDialogActivity,
  EditCatalogItemDialogActivity,
  ImportCatalogItemsActivity,
} from '../../activities';

export const CatalogItemsTableFlow = forwardRef((_props, ref) => {
  const { Paths, goToEditItem, goToImport, goToDeleteItem, goToAddItem, goToTable } = useNavigation();

  return (
    <Routes>
      <Route
        path={Paths.Table}
        element={withOutlet(
          <CatalogItemsTableActivity
            onImportCatalogItems={goToImport}
            onEditItem={goToEditItem}
            onAddItem={goToAddItem}
            onDeleteItem={goToDeleteItem}
            ref={ref}
          />
        )}
      >
        <Route path={Paths.Add} element={<AddCatalogItemDialogActivity onClose={goToTable} onDone={goToTable} />} />
        <Route
          path={Paths.Edit}
          element={
            <RouteElement
              component={EditCatalogItemDialogActivity}
              pathToProps={{ id: 'itemId' }}
              onClose={goToTable}
              onDone={goToTable}
            />
          }
        />
        <Route
          path={Paths.Delete}
          element={
            <RouteElement
              component={DeleteCatalogItemDialogActivity}
              pathToProps={{ id: 'itemId' }}
              onClose={goToTable}
              onDone={goToTable}
            />
          }
        />
        <Route
          path={Paths.Import}
          element={<ImportCatalogItemsActivity isOpen onClose={goToTable} onDone={goToTable} />}
        />
      </Route>
    </Routes>
  );
});

const useNavigation = () => {
  enum Paths {
    Table = '',
    Add = 'add',
    Edit = ':id/edit',
    Delete = ':id/delete',
    Import = 'import',
  }

  const navigate = useNavigate<Paths>({ withSearchparams: true });

  return {
    Paths: addWildcardToRoutes(Paths),
    goToEditItem: (id: string) => navigate(Paths.Edit, { pathParams: { id } }),
    goToDeleteItem: (id: string) => navigate(Paths.Delete, { pathParams: { id } }),
    goToAddItem: () => navigate(Paths.Add),
    goToTable: () => navigate(Paths.Table, { closeToast: false }),
    goToImport: () => navigate(Paths.Import),
  };
};
