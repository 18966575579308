import { Box } from '@chakra-ui/react';
import { useNavigationWithQueryParams } from '@melio/ap-domain';
import { Container, Group, useBreakpoint, useTable } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  FinancingEligibilityStatus,
  Loan,
  Payment,
  useFinancingOrgEligibility,
  usePayments,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useResolvedPath } from 'react-router-dom';

import { LoanDrawerActivity } from '../../../loan-drawer/LoanDrawer.activity';
import { useCanPayWithFinancing } from '../../hooks/useCanPayWithFinancing';
import { useLoadingState } from '../../hooks/useLoadingState';
import { useNewFinancingTabItems } from '../../hooks/useNewFinancingTabItems';
import { PayDashboardSortingProvider, usePayDashboardSorting } from '../../PayDashboardSortingProvider';
import { APTable } from '../APTable';
import { useItemsTabHighlightedRows } from '../BillsTab/useItemsTabHighlightedRows';
import { FinancingTabCreditDetails } from './components/FinancingTabCreditDetails';
import { FinancingTabEmptyState } from './components/FinancingTabEmptyState';
import { FinancingTabListItem } from './components/FinancingTabListItem';
import { LoansTabItem } from './types';
import { useFinancingTabAnalytics } from './useFinancingTabAnalytics';
import { useLoansTableColumns } from './useLoansTableColumns';
import { convertFinancingTabSortToApiSort } from './utils';

export type FinancingTabProps = {
  onViewPayment: ({ id }: { id: Payment['id'] }) => void;
  onAddNewPayment: () => void;
  onFinancingApplicationApply: VoidFunction;
};

const FinancingTabComponent = withAnalyticsContext(
  ({ onAddNewPayment, onViewPayment, onFinancingApplicationApply }: FinancingTabProps) => {
    const resolvedPathUrl = useResolvedPath('');
    const sorting = usePayDashboardSorting();
    const { formatMessage } = useMelioIntl();
    const { handleNavigationWithQueryParams } = useNavigationWithQueryParams();

    const { data = [], isLoading: isLoadingPayments } = usePayments({
      cacheTime: 0,
      params: {
        search: { 'payment.isFinanced': true },
        expand: ['loan', 'vendor'],
        sort: sorting?.sortingState ? convertFinancingTabSortToApiSort(sorting.sortingState) : undefined,
      },
      enabled: !!sorting?.isLoaded,
    });

    // TODO consider asking payments-api to populate nextInstallmentDate in melio-db so we can do this sort in the backend
    const payments =
      !sorting?.sortingState && !!sorting?.isLoaded
        ? sortBy(
            data as Array<Override<Payment, { loan: Loan; vendor: Vendor }>>,
            ({ loan }) => -(loan.nextInstallmentDate?.getTime() ?? 0)
          )
        : (data as Array<Override<Payment, { loan: Loan; vendor: Vendor }>>);

    const { data: orgEligibility, isLoading: isLoadingOrgEligibility } = useFinancingOrgEligibility({
      refetchOnMount: 'always',
    });

    const { limit, balance, status: eligibilityStatus, appliedToProvider: isAppliedToProvider } = orgEligibility ?? {};
    const columns = useLoansTableColumns();
    const { track } = useAnalytics();
    const { canReadRepaymentTerms } = useCanPayWithFinancing();

    useFinancingTabAnalytics({ loans: payments.map(({ loan }) => loan), limit, balance });
    const { routeReady } = useMonitoring();

    const { isEmptyState, isInitialLoading } = useLoadingState({
      isLoading: isLoadingPayments || !sorting?.isLoaded,
      items: payments,
      searchTerm: null,
    });

    const { newPayments: newLoans } = useNewFinancingTabItems();
    const loansToHighlights = useMemo(() => newLoans?.map((loan) => loan.id), [newLoans]);
    const highlightedRowIds = useItemsTabHighlightedRows(loansToHighlights);
    const { isExtraSmallScreen: isMobile } = useBreakpoint();

    useEffect(() => {
      if (isLoadingPayments || isLoadingOrgEligibility) {
        return;
      }
      track('Dashboard', 'View', {
        TotalCredit: balance ?? 0,
        AvailableCredit: limit ?? 0,
        SortColumn: sorting?.sortingState?.id,
        SortDirection: sorting?.sortingState?.sortDirection,
        ...(isAppliedToProvider ? {} : { Intent: 'start-financing-application' }),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingPayments, isLoadingOrgEligibility]);

    const handleViewLoan = ({ id }: { id: Payment['id'] }) => {
      track('Dashboard', 'Click', { Cta: 'view-loan' });
      handleNavigationWithQueryParams({ newPath: id });
    };

    const handleCloseDrawer = () => {
      handleNavigationWithQueryParams({ newPath: resolvedPathUrl.pathname });
    };

    const handleOnAddNewPayment = () => {
      track('Dashboard', 'Click', {
        Cta: 'pay-over-time',
        Intent: 'pay-with-financing',
        TotalCredit: balance ?? 0,
        AvailableCredit: limit ?? 0,
      });

      onAddNewPayment();
    };

    const handleOnFinancingApplicationApply = () => {
      track('Dashboard', 'Click', {
        Cta: 'apply-now',
        Intent: 'start-financing-application',
        EntryPoint: 'dashboard-financing',
      });

      onFinancingApplicationApply();
    };

    const mobileRowRenderer = useCallback((row: LoansTabItem) => <FinancingTabListItem loansTabItem={row} />, []);

    const tableProps = useTable({
      isLoading: isLoadingPayments || !sorting?.isLoaded,
      data: payments,
      columns,
      ...sorting,
      getRowId: ({ id }) => id,
      onRowClick: ({ rowData: { id } }) => handleViewLoan({ id }),
      headerVariant: 'dark',
      hideHeaderWhileLoading: isInitialLoading,
      captionId: formatMessage('activities.payDashboard.tabs.financing.caption'),
      meta: {
        onViewClick: handleViewLoan,
        onViewPayment,
      },
      highlightedRowIds,
      mobileRowRenderer,
    });

    const renderContent = () => {
      if (isEmptyState) {
        return (
          <FinancingTabEmptyState
            limit={limit ?? 0}
            onAddNewPayment={handleOnAddNewPayment}
            isAppliedToProvider={!!isAppliedToProvider}
            onFinancingApplicationApply={handleOnFinancingApplicationApply}
            eligibilityStatus={eligibilityStatus}
          />
        );
      }

      return (
        <APTable
          {...tableProps}
          captionLabel={
            isInitialLoading ? undefined : formatMessage('activities.payDashboard.tabs.financing.captionLabel')
          }
        />
      );
    };

    const isEligible = eligibilityStatus === FinancingEligibilityStatus.Eligible;
    const shouldShowFinancingTabCreditDetails =
      !!isAppliedToProvider &&
      (eligibilityStatus === FinancingEligibilityStatus.Eligible ||
        eligibilityStatus === FinancingEligibilityStatus.Suspended);

    if (isEmptyState && !!isAppliedToProvider && !canReadRepaymentTerms) {
      return <Navigate to="/pay-dashboard" />;
    }

    return (
      <Container data-testid="pay-dashboard-financing-tab" overflow="initial">
        {!isLoadingPayments && <span ref={routeReady} />}
        <Box paddingBottom="xxxl">
          <Group variant="vertical" width="full" hasDivider={isMobile}>
            {shouldShowFinancingTabCreditDetails && (
              <Group variant="horizontal" width="full" justifyContent="flex-end">
                <FinancingTabCreditDetails
                  isLoading={isLoadingOrgEligibility}
                  limit={limit}
                  balance={balance}
                  isDisabled={!isLoadingOrgEligibility && !isEligible}
                />
              </Group>
            )}
            <Container overflow="initial">{renderContent()}</Container>
          </Group>
        </Box>
        <Routes>
          <Route
            path=":paymentId"
            element={<LoanDrawerActivity onClose={handleCloseDrawer} onViewPayment={onViewPayment} />}
          />
        </Routes>
      </Container>
    );
  }
);

export const FinancingTab = (props: FinancingTabProps) => (
  <PayDashboardSortingProvider>
    <FinancingTabComponent {...props} />
  </PayDashboardSortingProvider>
);
