/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDisclosure } from '@chakra-ui/react';

import { usePartnerConfig } from '@/hooks/partners';
import { ConsentScreenActivity, OldConsentScreenActivity } from './components/consent-screen';

type UserConsentProps = {
  privacyPolicyUrl: string;
  termsOfServiceUrl: string;
  onDone: () => void;
  onClose: () => void;
};

export const UserConsent = ({ privacyPolicyUrl, termsOfServiceUrl, onDone, onClose }: UserConsentProps) => {
  const { isOpen, onOpen: openConsentModal, onClose: closeConsentModal } = useDisclosure();
  const { partnerConfig } = usePartnerConfig();

  const Component = partnerConfig.config.settings.newConsentScreen ? ConsentScreenActivity : OldConsentScreenActivity;

  const onDialogDone = () => {
    closeConsentModal();
    onDone();
  };

  const onDialogClose = () => {
    closeConsentModal();
    onClose();
  };

  React.useEffect(() => {
    openConsentModal();
  }, []);

  return (
    <Component
      termsOfServiceUrl={termsOfServiceUrl}
      privacyPolicyUrl={privacyPolicyUrl}
      isOpen={isOpen}
      onClose={onDialogClose}
      onDone={onDialogDone}
    />
  );
};
