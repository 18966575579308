import { Route, Routes } from 'react-router-dom';
import { SystemMessageProvider } from '@melio/ar-domain';
import { PartnerName } from '@melio/platform-api';

import { ApConfigurationProvider } from '@/cl/components/PartnerApp/app-providers/ApConfigurationProvider.component';
import { ARRouter } from './AR.router';

export const ARRoutes = ({ partnerName }: { partnerName: PartnerName }) => {
  return (
    <SystemMessageProvider>
      <Routes>
        <Route element={<ApConfigurationProvider partnerName={partnerName} />}>
          <Route path="*" element={<ARRouter />} />
        </Route>
      </Routes>
    </SystemMessageProvider>
  );
};
