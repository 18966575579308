import { Card, CardType, FeeType, FundingSource, FundingSourceType, PaymentFee } from '@melio/platform-api';
import { getDollarsFromCents } from '@melio/platform-utils';

export const convertPaymentFeeCentsToDollars = (paymentFees: PaymentFee[]) =>
  paymentFees.map((paymentFee) => ({
    type: paymentFee.type,
    amount: getDollarsFromCents(paymentFee.amount),
  }));

export const getFeeTypeByFundingSource = (fundingSource: FundingSource) =>
  fundingSource.type == FundingSourceType.BankAccount
    ? FeeType.Ach
    : (fundingSource?.details as Card).type === CardType.Credit
    ? FeeType.Credit
    : FeeType.Debit;
