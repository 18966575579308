import { usePlaidLinkToken } from '@melio/ap-widgets';
import { Traits, useAnalytics } from '@melio/platform-analytics';
import {
  DeliveryMethod,
  PlaidAccountData,
  useDeliveryMethod,
  useFundingSource,
  useFundingSources,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import uniq from 'lodash/uniq';
import { useEffect, useState } from 'react';

type Props = {
  onError?: ErrorFunction;
  updateDeliveryMethodId?: string;
  deliveryMethodIdToVerify?: string;
  fundingSourceId?: string;
  onClose: VoidFunction;
  options?: { isReceivingMethodFlow?: boolean; shouldAllowNonInstantVerification?: boolean };
};

export const useBankAccountPlaid = ({
  onError,
  onClose,
  updateDeliveryMethodId,
  deliveryMethodIdToVerify,
  fundingSourceId: _fundingSourceId,
  options,
}: Props) => {
  const [fundingSourceId, setFundingSourceId] = useState<string | undefined>();
  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>();

  const { isReceivingMethodFlow, shouldAllowNonInstantVerification } = options || {};
  const collection = useFundingSources({ enabled: false });
  const { data: fundingSource, isLoading: isFundingSourceLoading } = useFundingSource({ id: fundingSourceId });
  const { formatMessage } = useMelioIntl();

  const {
    data: linkData,
    linkError,
    isLoading: isTokenLoading,
  } = usePlaidLinkToken({
    deliveryMethodId: deliveryMethod?.id || deliveryMethodIdToVerify,
    fundingSourceId,
    options: { isReceivingMethodFlow, shouldAllowNonInstantVerification },
  });

  useEffect(() => {
    if (_fundingSourceId) {
      setFundingSourceId(_fundingSourceId);
    }
  }, [_fundingSourceId]);

  const { createPlaidAccount, updatePlaidAccount } = useDeliveryMethod({
    id: deliveryMethodIdToVerify || updateDeliveryMethodId,
    enabled: true,
  });

  const { createTrackHandler, setTraits, trackMarketing } = useAnalytics();

  const handleFail = (error: PlatformError) => {
    Logger.log(
      `Failed to create/update plaid ${isReceivingMethodFlow ? 'delivery-method' : 'funding-source'}: ` + error.message,
      'error'
    );
    onError?.({ code: error.code, message: formatMessage('activities.createFundingSource.screens.error') });
  };

  const onSuccess = async (data: PlaidAccountData) => {
    const trackSubmitted = createTrackHandler<{ Status: 'succeeded' | 'failed' }>('VerifyPlaidDetails', 'Submitted');
    const setFundingSourceTraits = async () => {
      const traits: Traits = { added_funding: true };
      const result = await collection.refetch();

      if (result.data?.length && result.data.length > 0) {
        const fundingSourcesTypes = uniq(result.data?.map((fundingSource) => fundingSource.type)).join(',');
        traits['funding_sources_types'] = fundingSourcesTypes;
      }

      setTraits(traits);
    };
    const setDeliveryMethodTraits = () => {
      const traits: Traits = { added_delivery_method: true };

      setTraits(traits);
    };
    if (isReceivingMethodFlow && !!deliveryMethodIdToVerify) {
      // This is for verifying existing non-instant micro deposit plaid DM -> continuing the bank account verification process
      await updatePlaidAccount({ type: 'plaid' })
        .then((deliveryMethod) => {
          trackSubmitted({ Status: 'succeeded' });
          trackMarketing('vendor-company-delivery-method_add-delivery-method-success', { Status: 'succeeded' });
          setDeliveryMethod(deliveryMethod as DeliveryMethod);
        })
        .catch((err) => {
          trackSubmitted({ Status: 'failed' });
          onClose();
          handleFail(err);
        });
    } else if (isReceivingMethodFlow && !!updateDeliveryMethodId) {
      // This updates a DM with existing plaid details
      await updatePlaidAccount({
        type: 'plaid',
        details: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          plaidAccountId: data.accounts[0]!.id,
          plaidToken: data.public_token,
        },
      })
        .then((deliveryMethod) => {
          trackSubmitted({ Status: 'succeeded' });
          trackMarketing('vendor-company-delivery-method_add-delivery-method-success', { Status: 'succeeded' });
          setDeliveryMethod(deliveryMethod as DeliveryMethod);
        })
        .catch((err) => {
          trackSubmitted({ Status: 'failed' });
          onClose();
          handleFail(err);
        });
    } else if (isReceivingMethodFlow && !updateDeliveryMethodId) {
      // This creates a new DM with new plaid details
      await createPlaidAccount({
        type: 'plaid',
        details: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          plaidAccountId: data.accounts[0]!.id,
          plaidToken: data.public_token,
        },
      })
        .then((deliveryMethod) => {
          trackSubmitted({ Status: 'succeeded' });
          trackMarketing('vendor-company-delivery-method_add-delivery-method-success', { Status: 'succeeded' });
          setDeliveryMethodTraits();
          setDeliveryMethod(deliveryMethod as DeliveryMethod);
        })
        .catch((err) => {
          trackSubmitted({ Status: 'failed' });
          handleFail(err);
        });
    } else {
      await collection
        .create({
          type: 'plaid',
          details: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            plaidAccountId: data.accounts[0]!.id,
            plaidToken: data.public_token,
          },
        })
        .then(({ id: fundingSourceId }) => {
          trackSubmitted({ Status: 'succeeded' });
          trackMarketing('bank-add-plaid_plaid-success');
          setFundingSourceTraits();
          setFundingSourceId(fundingSourceId);
        })
        .catch((err) => {
          trackSubmitted({ Status: 'failed' });
          handleFail(err);
        });
    }
  };

  return {
    fundingSource,
    deliveryMethod,
    onSuccess,
    isLoading: collection.isMutating || isFundingSourceLoading || isTokenLoading,
    linkData,
    linkError,
  };
};
