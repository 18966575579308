import { useMelioIntl } from '@melio/platform-i18n';
import { array, object, string } from 'yup';

export const useVendorsGroupFormSchema = (existingVendorGroupsNames: string[]) => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    name: string()
      .required(formatMessage('activities.vendorsGroupForm.errors.name.required'))
      .max(32, formatMessage('activities.vendorsGroupForm.errors.name.length'))
      .trim()
      .notOneOf(existingVendorGroupsNames, formatMessage('activities.vendorsGroupForm.errors.name.exist')),
    vendorIds: array().of(string().required()),
  });
};
