import { Form, Group, Modal, useFormSubmissionController, useMelioForm } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { Payment, PaymentIntentWithDerivatives } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { ComponentProps, useEffect } from 'react';
import { object, SchemaOf, string } from 'yup';

export type NoteToSelfBaseModalProps = Pick<ComponentProps<typeof Modal>, 'isOpen' | 'onClose'> & {
  note: string;
  isUpdating: boolean;
  updateNote: (note: string | null) => void;
  onSuccess?: (data: Payment | PaymentIntentWithDerivatives) => void;
};

type NoteToSelfFormFields = {
  noteToSelf: string;
};

export const useSchema = () => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      payment: {
        noteToSelf: { noteRegex },
      },
    },
  } = useConfig();

  return object().shape({
    noteToSelf: string().matches(
      new RegExp(noteRegex.pattern, noteRegex.flags),
      formatMessage('activities.paymentNoteToSelfModal.form.noteSoSelf.validation.invalid.character')
    ),
  }) as SchemaOf<NoteToSelfFormFields>;
};

export const NoteToSelfBaseModal = withAnalyticsContext(
  ({ isOpen, onClose, note, isUpdating, updateNote }: NoteToSelfBaseModalProps) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();

    const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<NoteToSelfFormFields>();

    const form = useMelioForm<NoteToSelfFormFields>({
      onSubmit: ({ noteToSelf }) => updateNote(noteToSelf || null),
      isSaving: isUpdating,
      schema: useSchema(),
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
      defaultValues: {
        noteToSelf: note || '',
      },
    });

    useEffect(() => {
      if (isOpen) {
        track('Dashboard', 'View', { Intent: 'payment-updated' });
      }
    }, [isOpen, track]);

    const handleCloseClick = () => {
      track('Dashboard', 'Click', { Intent: 'payment-updated', Cta: 'exit' });
      form.reset();
      onClose();
    };

    const title = note
      ? formatMessage('activities.paymentNoteToSelfModal.title.edit')
      : formatMessage('activities.paymentNoteToSelfModal.title');

    return (
      <Modal
        isOpen={isOpen}
        header={title}
        primaryButton={{
          ...submitButtonProps,
          label: formatMessage('activities.paymentNoteToSelfModal.confirm'),
          variant: 'primary',
        }}
        secondaryButton={{
          onClick: handleCloseClick,
          label: formatMessage('activities.paymentNoteToSelfModal.cancel'),
          variant: 'secondary',
        }}
        onClose={handleCloseClick}
        data-testid="edit-payment-note-to-self-modal"
      >
        <Group variant="vertical">
          <Form {...form.formProps}>
            <Form.TextArea
              {...form.registerField('noteToSelf')}
              labelProps={{
                label: formatMessage('activities.paymentNoteToSelfModal.form.noteSoSelf.label'),
              }}
              placeholder={formatMessage('activities.paymentNoteToSelfModal.form.noteSoSelf.placeholder')}
              helperTextProps={{
                label: formatMessage('activities.paymentNoteToSelfModal.form.noteSoSelf.helperText'),
              }}
              maxChars={100}
            />
          </Form>
        </Group>
      </Modal>
    );
  }
);
