import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';

export const useTodosDescriptions = () => {
  const { formatMessage, formatCurrency, formatDate } = useMelioIntl();

  const createAmountDescription = useCallback(
    (
      totalItems: number,
      data: { amount: number; currency: string; usdToForeignRate?: number | null }[]
    ): { label: string; value?: string; popover?: string }[] => {
      //in case of pagination (totalItems > data.length) that we don't have all items, we won't show amout
      if (data.length === 0 || totalItems > data.length) {
        return [];
      }
      if (totalItems === 1) {
        return [
          {
            label: formatMessage('widgets.todosDrawer.section.amount.description'),
            value: data[0] ? formatCurrency(data[0].amount, data[0].currency) : '',
          },
        ];
      }

      const firstItemCurrency = data[0]?.currency ?? 'USD';
      const isSingleCurrency = data.every((cur) => (cur.currency ?? 'USD') === firstItemCurrency);

      const normalizedAmount = data.reduce((acc, cur) => {
        const currency = cur.currency || 'USD';
        const amount = isSingleCurrency
          ? cur.amount
          : currency === 'USD'
          ? cur.amount
          : cur.amount / (cur.usdToForeignRate ?? 1);
        return acc + amount;
      }, 0);

      return [
        {
          label: formatMessage('widgets.todosDrawer.section.amount.description'),
          value: formatCurrency(normalizedAmount, isSingleCurrency ? firstItemCurrency : 'USD'),
          ...(isSingleCurrency
            ? {}
            : { popover: formatMessage('widgets.todosDrawer.section.amount.popover.description') }),
        },
      ];
    },
    [formatMessage, formatCurrency]
  );

  const createDueDateDescription = useCallback(
    (
      totalItems: number,
      curPageCount: number,
      dueDate: Date | number,
      dueDateKey: 'dueDate' | 'debitDate' | 'deliveryBy'
    ) => {
      //in case of pagination (totalItems > curPageCount) that we don't have all items, we won't show amout
      if (curPageCount === 0 || totalItems > curPageCount) {
        return [];
      }
      return [
        {
          label: formatMessage(`widgets.todosDrawer.section.${dueDateKey}.description`),
          value: formatDate(dueDate, { day: 'numeric', month: 'short', year: 'numeric' }),
        },
      ];
    },
    [formatMessage, formatDate]
  );

  const createViewBillsAction = useCallback(
    (totalItems: number, onClick: () => void) => ({
      label: formatMessage('widgets.todosDrawer.section.action.viewBills', { count: totalItems }),
      onClick,
    }),
    [formatMessage]
  );

  const createViewPaymentAction = useCallback(
    (totalItems: number, onClick: () => void) => ({
      label: formatMessage('widgets.todosDrawer.section.action.viewPayments', { count: totalItems }),
      onClick,
    }),
    [formatMessage]
  );

  return { createAmountDescription, createDueDateDescription, createViewBillsAction, createViewPaymentAction };
};
