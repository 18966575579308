import { TokenProvider } from '@melio/ar-api-axios-client';
import {
  useGuestPayorFundingSources,
  useGuestPayorPayment,
  useGuestPayorPaymentRequestDetails,
  useMonitoring,
} from '@melio/ar-domain';
import { convertDateToStringRepresentation, forwardRef } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { PaymentProcessingIndicator } from '../../../components';
import { BaseLayout } from '../../../layout';
import { CardDetailsFormValues } from '../../types';

const tokenProvider: TokenProvider = 'basistheory';

export type CreateCardPaymentActivityProps = {
  paymentRequestLink: string;
  formValues?: CardDetailsFormValues;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
  onDone: (paymentId: string) => void;
};
export const CreateCardPaymentActivity = forwardRef<CreateCardPaymentActivityProps>(
  ({ paymentRequestLink, formValues, onClose, onDone, onError, ...props }, ref) => {
    const { startAction } = useMonitoring();

    const [paymentId, setPaymentId] = useState<string>();

    const paymentRequestDetailsModel = useGuestPayorPaymentRequestDetails({ paymentRequestLink, onError });
    const paymentModel = useGuestPayorPayment({ id: paymentId, enabled: false });
    const fundingSourceCollection = useGuestPayorFundingSources({ enabled: false });

    async function createFundingSourceAndPayment() {
      if (!formValues) return onError?.({ code: '500', message: 'Form values are required' });
      startAction('pay');

      const { address, cardOwner, cardParams, scheduledDate } = formValues;
      const { cardBin, tabapayToken } = cardParams;

      await fundingSourceCollection.verifyCard({ cardBin, tabapayToken, tokenProvider });

      const { id: fundingSourceId } = await fundingSourceCollection.create({
        type: 'card',
        details: { ...cardParams, address, cardOwner, tokenProvider },
      });

      const { paymentId } = await paymentModel.createPayment({
        fundingSourceId,
        paymentRequestLink,
        scheduledDate: convertDateToStringRepresentation(scheduledDate),
      });

      setPaymentId(paymentId);
      await Promise.all([paymentRequestDetailsModel.refetch(), paymentModel.refetch()]);
      onDone(paymentId);
    }

    useEffect(() => void createFundingSourceAndPayment().catch(onError), [formValues]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <BaseLayout data-testid="payment-processing-screen" {...props} ref={ref}>
        <PaymentProcessingIndicator />
      </BaseLayout>
    );
  }
);
