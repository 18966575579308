import { useEffect, useState } from 'react';

import { useAccountingFirmBillingFees, useAccountingFirmClientsNonExpanded } from '../../../../api/entities/client';
import { SelectableList } from './SelectableList';

export const useSelectClients = () => {
  const [clientsList, setClientsList] = useState<SelectableList>({ items: [], selectedCount: 0 });

  const {
    error: accountingFirmClientsError,
    data: clients = [],
    isFetching: isClientsFetching,
    isFetched: isClientsFetched,
  } = useAccountingFirmClientsNonExpanded();

  const {
    error: billingFeeSettingsError,
    data: billingFeeSettings,
    isFetching: isBillingFeeSettingsFetching,
    isFetched: isBillingFeeSettingsFetched,
  } = useAccountingFirmBillingFees();

  useEffect(() => {
    if (isClientsFetching || isBillingFeeSettingsFetching) {
      return;
    }

    if (billingFeeSettings?.clientsBillingFees && clients) {
      const clientsBillingFees = billingFeeSettings.clientsBillingFees;
      const clientsMap = clients.reduce(
        (acc, client) => {
          const clientBillingFees = clientsBillingFees.find((cbf) => cbf.organizationId === client.organization.id);
          if (client.organization.name) {
            acc.items.push({
              id: client.organization.id,
              label: client.organization.name,
              checked: clientBillingFees?.isActive || false,
            });
            acc.selectedCount += clientBillingFees?.isActive ? 1 : 0;
          }

          return acc;
        },
        { items: [], selectedCount: 0 } as SelectableList
      );
      setClientsList(clientsMap);
    }
  }, [isClientsFetching, isBillingFeeSettingsFetching, billingFeeSettings?.clientsBillingFees, clients]);

  const handleSelect = (newList: SelectableList) => setClientsList(newList);

  return {
    clientsList,
    handleSelect,
    isFetching: isClientsFetching && isBillingFeeSettingsFetching,
    isClientListReady: isClientsFetched && clientsList.items.length === clients.length,
    isError: !!accountingFirmClientsError || !!billingFeeSettingsError,
    isFetched: isClientsFetched && isBillingFeeSettingsFetched,
  };
};
