import { Button, Container, Group } from '@melio/penny';
import { SubscriptionBillingCycleEnum } from '@melio/subscriptions/src/api';
import { useState } from 'react';

import { FIRM_DISCOUNT_PERCENTAGE } from '../../../../consts';
import { SubscriptionBillingPayor } from '../../../../types';
import {
  FirmClientSubscriptionReviewAndConfirmActivity,
  PreviewState,
} from '../../../FirmClientSubscriptionReviewAndConfirm';
import { AssignFirmClientPlanFormStepComponent } from '../../types';
import { useAssignFirmClientPlanFormContext } from '../AssignFirmClientPlanForm/AssignFirmClientPlanFormFormContext';

export const AssignFirmClientPlanReviewAndConfirmStep: AssignFirmClientPlanFormStepComponent = ({
  submitLabel,
  isSaving,
  onContinue,
}) => {
  const { watch, submitButtonProps, setValue, getFieldState } = useAssignFirmClientPlanFormContext();
  const [previewState, setPreviewState] = useState<PreviewState | null>(null);
  const planId = watch('subscriptionPlanId');
  const promoCode = watch('subscriptionPromoCode');
  const isFirmBilled = watch('whoPays') === SubscriptionBillingPayor.AccountingFirm;

  if (!planId) {
    return <></>;
  }

  const handlePromoCodeChange = (value?: string) => {
    setValue('subscriptionPromoCode', value);
  };
  const shouldPrefetchPromoCode = !!promoCode && !getFieldState('subscriptionPromoCode').isDirty;

  return (
    <Group spacing="m" variant="vertical" width="full">
      <FirmClientSubscriptionReviewAndConfirmActivity
        planId={planId}
        fundingSourceId={isFirmBilled ? watch('fundingSourceId') : null}
        planCycle={SubscriptionBillingCycleEnum.Monthly}
        discountPercentage={FIRM_DISCOUNT_PERCENTAGE}
        organizationId={watch('organizationId')}
        onPreviewStateChange={setPreviewState}
        promoCode={promoCode}
        onPromoCodeChange={handlePromoCodeChange}
        shouldPrefetchPromoCode={shouldPrefetchPromoCode}
      />
      <Container justifyContent="flex-end">
        <Button
          variant="primary"
          size="medium"
          label={submitLabel}
          {...submitButtonProps}
          onClick={onContinue}
          isLoading={previewState === 'loading' || isSaving}
          isDisabled={previewState === 'error'}
        />
      </Container>
    </Group>
  );
};
