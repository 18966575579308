import { MobileFilter, useMelioIntl } from '@melio/ar-domain';
import { Container, Group, SearchBar as DSSearchBar } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useFiltersLabel } from '../hooks';
import { Filters, OnSort, QueryFilter, SortParams } from '../types';
import { SortMenuMobile } from './SortMenu.mobile';

export type FilterBarMobileProps = {
  selectedFilter?: QueryFilter;
  onSelectFilter: (filters: Filters) => void;
  search?: string;
  onSearch: (value: string) => void;
  onSort: OnSort;
  sortParams?: SortParams;
};

export const FiltersToolBarMobile = forwardRef<FilterBarMobileProps>(
  ({ onSelectFilter, selectedFilter = 'all', onSearch, search, onSort, sortParams, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const filtersLabel = useFiltersLabel();
    const orderedFilters: QueryFilter[] = ['all', 'draft', 'open', 'overdue', 'paid', 'canceled'];

    return (
      <Group
        ref={ref}
        data-component={FiltersToolBarMobile.displayName}
        variant="horizontal"
        alignItems="center"
        data-testid="pay-dashboard-payments-sub-and-search-tab"
        {...props}
      >
        <Group.Item>
          <DSSearchBar
            value={search}
            onChange={(e) => onSearch(e.target.value)}
            placeholder={formatMessage('ar.dashboard.activities.invoiceTable.mobile.searchInput.placeholder')}
            onClear={() => onSearch('')}
            data-testid="invoice-table-search-input"
            aria-label={formatMessage('ar.dashboard.activities.invoiceTable.mobile.searchInput.aria-label')}
            instructionsText={formatMessage('ar.dashboard.activities.invoiceTable.mobile.searchInput.instructions')}
          />
        </Group.Item>
        <Group.Item>
          <Group allowOverflowX={false} spacing="xs">
            <Container width="fit-content">
              <SortMenuMobile onSort={onSort} sortParams={sortParams} />
            </Container>
            <Container width="fit-content">
              <MobileFilter
                activeFilter={selectedFilter}
                defaultFilter="all"
                options={orderedFilters.map((filter) => ({
                  id: filter,
                  label: filtersLabel[filter],
                }))}
                onChange={(filter) =>
                  filter === 'all'
                    ? onSelectFilter({ invoiceStatus: undefined })
                    : onSelectFilter({ invoiceStatus: [filter] })
                }
                title={formatMessage('ar.dashboard.activities.invoiceTable.filters.title')}
              />
            </Container>
          </Group>
        </Group.Item>
      </Group>
    );
  }
);
FiltersToolBarMobile.displayName = 'MobileFiltersToolBar';
