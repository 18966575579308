import { Group, Pill, PillProps, Text } from '@melio/penny';

type BlockPart = { label: string; testId?: string };
type Props = {
  title: BlockPart;
  body: BlockPart & { badgeProps?: PillProps };
  description: BlockPart;
  secondaryDescription?: BlockPart;
  testId?: string;
};

const BlockTitle = ({ label, testId }: Props['title']) => (
  <Text textStyle="body3Semi" color="global.neutral.900" data-testid={testId}>
    {label}
  </Text>
);

const BlockBody = ({ label, testId, badgeProps }: Props['body']) => (
  <Group spacing="xxs" alignItems="center">
    <Text textStyle="body2" data-testid={testId}>
      {label}
    </Text>

    {badgeProps ? <Pill {...badgeProps} data-testid={testId && `${testId}-badge`} /> : null}
  </Group>
);

const BlockDescription = ({ label, testId }: Props['description']) => (
  <Text textStyle="body4" color="global.neutral.900" data-testid={testId}>
    {label}
  </Text>
);

const Block = ({ title, body, description, testId, secondaryDescription }: Props) => (
  <Group variant="vertical" spacing="xxs" data-testid={testId}>
    <Group.Item>
      <BlockTitle {...title} />
    </Group.Item>

    <Group.Item>
      <BlockBody {...body} testId={testId && `${testId}-body`} />
    </Group.Item>

    {secondaryDescription && (
      <Group.Item>
        <BlockDescription {...secondaryDescription} testId={testId && `${testId}-secondary-description`} />
      </Group.Item>
    )}

    <Group.Item>
      <BlockDescription {...description} testId={testId && `${testId}-description`} />
    </Group.Item>
  </Group>
);

export const SectionBlock = Object.assign(Block, {
  Title: BlockTitle,
  Body: BlockBody,
  Description: BlockDescription,
});
