import { Tier } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';

export const useDowngradeLostFeaturesList = () => {
  const { formatMessage } = useMelioIntl();

  const lostPlusFeatures = [
    formatMessage('activities.subscription.planDowngraded.lostFeature.roleAndUserManagament'),
    formatMessage('activities.subscription.planDowngraded.lostFeature.automation'),
    formatMessage('activities.subscription.planDowngraded.lostFeature.syncSoftware'),
    formatMessage('activities.subscription.planDowngraded.lostFeature.batchPayments'),
    formatMessage('activities.subscription.planDowngraded.lostFeature.multiplePayments'),
    formatMessage('activities.subscription.planDowngraded.lostFeature.w9'),
  ];

  const lostProFeatures = [
    formatMessage('activities.subscription.planDowngraded.lostFeature.accountantAndUsers'),
    formatMessage('activities.subscription.planDowngraded.lostFeature.paymentsApproval'),
    formatMessage('activities.subscription.planDowngraded.lostFeature.advanceBill'),
    formatMessage('activities.subscription.planDowngraded.lostFeature.syncSoftware'),
    formatMessage('activities.subscription.planDowngraded.lostFeature.quickBooksDesktop'),
    formatMessage('activities.subscription.planDowngraded.lostFeature.multiplePaymentsWithBatchActions'),
    formatMessage('activities.subscription.planDowngraded.lostFeature.w9And1099Reporting'),
    formatMessage('activities.subscription.planDowngraded.lostFeature.combinePaymentsIntoOne'),
    formatMessage('activities.subscription.planDowngraded.lostFeature.premiumSupport'),
  ];

  const getLostFeaturesByPreviousPlanTier = (lastPaidSubscriptionPlanTier: Tier) => {
    switch (lastPaidSubscriptionPlanTier) {
      case Tier.MelioPlus:
        return lostPlusFeatures;
      case Tier.MelioPro:
        return lostProFeatures;
      default:
        Logger.log(`previousSubscription plan tier not found: ${lastPaidSubscriptionPlanTier}`, 'error');
        throw new Error(`No lost features found for this plan: ${lastPaidSubscriptionPlanTier}`);
    }
  };

  return { getLostFeaturesByPreviousPlanTier };
};
