import { FormInputs, FormWidgetProps, RoleType, useMelioIntl } from '@melio/ar-domain';
import { Form, GridItem, useMelioForm } from '@melio/penny';
import { Countries } from '@melio/platform-api';
import { forwardRef as reactForwardRef, MutableRefObject, useEffect } from 'react';
import * as yup from 'yup';

import { BusinessOwnerForm, OwnerDetailsFormValues } from '../types';
import { GoogleAddressFormSection } from './GoogleAddressFormSection';

export type OwnerDetailsFormProps = Omit<
  FormWidgetProps<OwnerDetailsFormValues>,
  'onSubmit' | 'onSubmissionStateChange'
> & {
  ownerId: string;
  selectedMainOwnerId: string | null;
  setMainOwner: (isSelected: boolean | undefined) => void;
};

export const OwnerDetailsForm = reactForwardRef<BusinessOwnerForm, OwnerDetailsFormProps>(
  ({ isSaving, selectedMainOwnerId, ownerId, setMainOwner, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const useAddressSchema = () => {
      const { formatMessage } = useMelioIntl();
      return yup.object().shape({
        line1: yup
          .string()
          .required(formatMessage('ar.onboarding.activities.ownershipDetails.form.fields.line1.valid.required.text')),
        line2: yup.string().optional(),
        countryCode: yup.string().required(),
        city: yup
          .string()
          .min(2, formatMessage('ar.onboarding.activities.ownershipDetails.form.fields.city.valid.minLength.text'))
          .max(32, formatMessage('ar.onboarding.activities.ownershipDetails.form.fields.city.valid.maxLength.text'))
          .matches(
            /^[\w\s]+$/,
            formatMessage('ar.onboarding.activities.ownershipDetails.form.fields.city.valid.matches.text')
          )
          .required(),
        state: yup
          .string()
          .length(2, formatMessage('ar.onboarding.activities.ownershipDetails.form.fields.state.valid.length.text'))
          .required(),
        postalCode: yup
          .string()
          .min(
            5,
            formatMessage('ar.onboarding.activities.ownershipDetails.form.fields.postalCode.valid.minLength.text')
          )
          .max(
            11,
            formatMessage('ar.onboarding.activities.ownershipDetails.form.fields.postalCode.valid.maxLength.text')
          )
          .required(
            formatMessage('ar.onboarding.activities.ownershipDetails.form.fields.postalCode.valid.required.text')
          ),
      });
    };

    const formControl = useMelioForm<OwnerDetailsFormValues>({
      onSubmit: () => null,
      isSaving,
      schema: yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        dateOfBirth: yup.date().nullable().required(),
        nationality: yup.string().required(),
        taxNumber: yup.string().required(),
        phoneNumber: yup.string().required(),
        email: yup.string().required().email(),
        ownerAddress: useAddressSchema(),
        role: yup.string().oneOf(Object.values(RoleType)).required(),
        percentageOwnership: yup.number().required(),
        mainOwnership: yup.boolean().notRequired(),
      }) as yup.SchemaOf<OwnerDetailsFormValues>,
    });

    useEffect(() => {
      (ref as MutableRefObject<BusinessOwnerForm>).current = formControl;
    }, [formControl, ref]);

    const isSelectedOwner = formControl.watch('mainOwnership');

    useEffect(() => {
      if (!selectedMainOwnerId || (!isSelectedOwner && ownerId === selectedMainOwnerId)) {
        setMainOwner(isSelectedOwner);
      }
    }, [isSelectedOwner, ownerId, selectedMainOwnerId, setMainOwner]);

    const { registerField, formProps } = formControl;

    return (
      <Form {...props} {...formProps} columns={2}>
        <Form.TextField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.firstName.label'),
          }}
          {...registerField('firstName')}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.lastName.label'),
          }}
          {...registerField('lastName')}
        />
        <Form.DateField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.dateOfBirth.label'),
          }}
          {...registerField('dateOfBirth')}
        />
        <FormInputs.Select
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.nationality.label'),
          }}
          {...registerField('nationality')}
          options={Countries.map(({ key, value }) => ({
            label: key,
            value,
          }))}
          shouldHideClearButton
        />
        <Form.TextField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.taxNumber.label'),
          }}
          {...registerField('taxNumber')}
          colSpan={2}
        />
        <Form.PhoneField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.phoneNumber.label'),
          }}
          {...registerField('phoneNumber')}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.email.label'),
          }}
          {...registerField('email')}
        />
        <GridItem colSpan={2}>
          <GoogleAddressFormSection businessOwnerForm={formControl} columns={2} />
        </GridItem>

        <FormInputs.Select
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.label'),
          }}
          {...registerField('role')}
          options={[
            {
              value: RoleType.VicePresident,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.VicePresident.label'
              ),
            },
            {
              value: RoleType.President,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.President.label'
              ),
            },
            {
              value: RoleType.Treasurer,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Treasurer.label'
              ),
            },
            {
              value: RoleType.Owner,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Owner.label'
              ),
            },
            {
              value: RoleType.Partner,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Partner.label'
              ),
            },
            {
              value: RoleType.Ceo,
              label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Ceo.label'),
            },
            {
              value: RoleType.Secretary,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Secretary.label'
              ),
            },
            {
              value: RoleType.Director,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Director.label'
              ),
            },
            {
              value: RoleType.SoleProprietor,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.SoleProprietor.label'
              ),
            },
            {
              value: RoleType.Coo,
              label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Coo.label'),
            },
            {
              value: RoleType.Cfo,
              label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Cfo.label'),
            },
            {
              value: RoleType.MemberLlc,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.MemberLlc.label'
              ),
            },
            {
              value: RoleType.Other,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Other.label'
              ),
            },
          ]}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage(
              'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.percentageOwnership.label'
            ),
          }}
          {...registerField('percentageOwnership')}
        />
        <Form.Checkbox
          size="small"
          label={formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.mainOwnership.label')}
          isDisabled={!!selectedMainOwnerId && ownerId !== selectedMainOwnerId}
          {...registerField('mainOwnership')}
        />
      </Form>
    );
  }
);
