import { useLocation } from 'react-router-dom';
import { AddCardFundingSourceActivity } from '@melio/ap-activities';

import { AddCardRouteStateType } from '@/hooks/router.hooks';
import { usePaymentMethodActivityEvents } from '@/hooks/usePaymentMethodActivityEvents';

export const AddCardAccountScreen = () => {
  const { state } = useLocation();
  const { cardType, returnUrl } = (state ?? {}) as AddCardRouteStateType;
  const { onBack, onDone, onClose } = usePaymentMethodActivityEvents(returnUrl);

  return (
    <div data-testid={`add-card-funding-source-screen-${cardType ?? 'card'}`} style={{ height: '100%' }}>
      <AddCardFundingSourceActivity cardType={cardType} onBack={onBack} onClose={onClose} onDone={onDone} />
    </div>
  );
};

AddCardAccountScreen.displayName = 'AddCardAccountScreen';
