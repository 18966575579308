import { useMelioIntl } from '@melio/ar-domain';
import { CollapsibleCard, Group, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { Footer, OnboardingLayout } from '../../../layouts';

type ReviewAndApproveScreenProps = {
  onBack: VoidFunction;
  onClose: VoidFunction;
};

export const ReviewAndApproveScreen = forwardRef<ReviewAndApproveScreenProps>(({ onBack, onClose, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();
  return (
    <OnboardingLayout
      {...props}
      data-component={ReviewAndApproveScreen.displayName}
      screenId="review-and-approve"
      ref={ref}
      headerContent={formatMessage('ar.onboarding.layout.header.label')}
      footerContent={
        <Footer
          primaryButtonProps={{
            label: formatMessage('ar.onboarding.layout.buttons.continue.label'),
          }}
          secondaryButtonProps={{
            label: formatMessage('ar.onboarding.layout.buttons.back.label'),
            onClick: onBack,
          }}
          tertiaryButtonProps={{
            label: formatMessage('ar.onboarding.layout.buttons.cancel.label'),
            onClick: onClose,
          }}
        />
      }
      isStickyFooter
    >
      <Group variant="vertical" width="full" spacing="l">
        <Group variant="vertical" spacing="xxs">
          <Text textStyle="heading1Semi">Review and accept agreements</Text>
          <Text textStyle="body2" color="global.neutral.700">
            To activate online payment processing, review and confirm the following agreements.
          </Text>
        </Group>

        <Group variant="vertical" spacing="m">
          <CollapsibleCard title="Sub-merchant agreement" defaultIsExpanded>
            <iframe
              src="https://secure.eu1.adobesign.com/public/apiesign?pid=CBFCIBAA3AAABLblqZhCPma7k8yUXoJt28SVHyr6YjQF9WES3ZqTGDNOX5nV7oUeW2llwUmn8xunv7k7NV8mEYsjyTs3C_8DLibN4oyAa&client_id=CBJCHBCAABAAX7OLsZJZ-4Nw_xO5zUB7sNIdlp67ax4Z"
              height="500px"
            />
          </CollapsibleCard>

          <CollapsibleCard title="Remittance agreement">
            <iframe
              src="https://secure.eu1.adobesign.com/public/apiesign?pid=CBFCIBAA3AAABLblqZhCPma7k8yUXoJt28SVHyr6YjQF9WES3ZqTGDNOX5nV7oUeW2llwUmn8xunv7k7NV8mEYsjyTs3C_8DLibN4oyAa&client_id=CBJCHBCAABAAX7OLsZJZ-4Nw_xO5zUB7sNIdlp67ax4Z"
              height="500px"
            />
          </CollapsibleCard>
        </Group>
      </Group>
    </OnboardingLayout>
  );
});
ReviewAndApproveScreen.displayName = 'ReviewAndApproveScreen';
