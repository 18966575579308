/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { LocalStorageKeys } from '@melio/local-storage';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { verifyGoogleJWT } from '@/api/google/google.api';
import { usePartnerLocalStorage } from '@/hooks/partners/usePartnerLocalStorage';

export const useGoogleSSO = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [googleToken, setGoogleToken] = useState<string | undefined>();
  const localStorage = usePartnerLocalStorage();
  const [isGoogleSSOOn] = useDevFeature(FeatureFlags.PlatformGoogleSSO, true);

  const getGoogleAccessToken = useCallback(
    async (token?: string) => {
      try {
        setLoading(true);
        const accessToken = token || localStorage.getItem(LocalStorageKeys.googleAccessToken);
        if (accessToken) {
          const { exp, email } = await verifyGoogleJWT(accessToken);
          const [, domain] = email.split('@');
          if (Number(exp) * 1000 > Date.now() && domain === 'melio.com') {
            setGoogleToken(accessToken);
          } else {
            localStorage.removeItem(LocalStorageKeys.googleAccessToken);
          }
        }
      } catch {
        localStorage.removeItem(LocalStorageKeys.googleAccessToken);
      } finally {
        setLoading(false);
      }
    },
    [localStorage],
  );

  const signIn = useGoogleLogin({
    onSuccess: (response) => {
      setGoogleToken(response.access_token);
    },
  });

  useEffect(() => {
    getGoogleAccessToken();
  }, []);

  useEffect(() => {
    if (googleToken) {
      localStorage.setItem(LocalStorageKeys.googleAccessToken, googleToken);
    }
  }, [googleToken]);

  return {
    isLoggedIn: !!googleToken || !isGoogleSSOOn,
    signIn,
    isLoading,
  };
};
