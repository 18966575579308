import { SystemMessage, TestModeMessage, useSystemMessage, useUserActivation } from '@melio/ar-domain';
import { Container, Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export const FormPanelHeaderMobile = forwardRef((_, ref) => {
  const { message } = useSystemMessage();
  const { isTestMode } = useUserActivation();

  const isMessage = message || isTestMode;

  return (
    <Container
      ref={ref}
      paddingX={isMessage ? 's' : undefined}
      paddingTop={isMessage ? 'l' : undefined}
      paddingBottom={isMessage ? 'xs' : undefined}
    >
      <Group spacing="s" variant="vertical">
        <TestModeMessage screen="edit-create-share" />
        <SystemMessage />
      </Group>
    </Container>
  );
});

FormPanelHeaderMobile.displayName = 'FormPanelHeaderMobile';
