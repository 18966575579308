import { TableCell } from './types';

export const cellToResetMap: Record<TableCell, TableCell[]> = {
  vendor: ['vendor'],
  amount: ['amount'],
  debitDate: ['debitDate'],
  deliveryDate: ['deliveryDate'],
  deliveryMethod: ['deliveryMethod'],
  fundingSource: ['fundingSource'],
};
