import { NotificationsApiClient } from '@melio/ar-api-axios-client';

import { NotificationsNames, PutNotificationPreferencesByNameRequest } from './types';

type PatchNotificationPreferencesByNotificationNameProps = {
  id: NotificationsNames;
  data: PutNotificationPreferencesByNameRequest;
};

export const patchNotificationPreferencesByNotificationName = ({
  id,
  data,
}: PatchNotificationPreferencesByNotificationNameProps) =>
  NotificationsApiClient.putNotificationPreferencesByNotificationName(id, data);

export const patchNotification = (id: string, data: PutNotificationPreferencesByNameRequest) =>
  NotificationsApiClient.putNotificationPreferencesByNotificationName(id as never, data);
