import { withAnalyticsContext } from '@melio/platform-analytics';
import { FeatureFlags } from '@melio/platform-feature-flags';
import { useCallback, useState } from 'react';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { usePromotionsData } from '../../../../promotions';
import { CardToCardPromotionBanner } from '../../../../promotions/banners/card-to-card/CardToCardPromotionBanner';
import { QBOSyncPromotionBanner } from '../../../../promotions/banners/qbo/QBOSyncPromotionBanner';
import { PaymentFlowDoneAction } from '../../../../types';

type PaymentScheduledPromotionsProps = {
  flow: 'batch-payments' | 'single-payment' | 'recurring-payment';
  paymentIds: string[];
  onDone: (action: PaymentFlowDoneAction) => void;
};

export const PaymentScheduledPromotions = withAnalyticsContext<PaymentScheduledPromotionsProps>(
  ({ flow, paymentIds, onDone }) => {
    const { activePromotion: promotionName } = usePromotionsData();

    const [promotionDismissed, setPromotionDismissed] = useState<boolean>(false);

    const onDismissHandler = useCallback(() => {
      setPromotionDismissed(true);
    }, [setPromotionDismissed]);

    const createDoneHandler = useCallback(
      (action: PaymentFlowDoneAction) => () => {
        onDone(action);
      },
      [onDone]
    );

    const analyticsProperties = {
      Flow: flow,
      PageName: 'payment-scheduled',
      PaymentId: paymentIds,
    };

    return !promotionName || promotionDismissed ? null : (
      <NewSinglePaymentStepLayout.Content>
        {promotionName === FeatureFlags.PlatformScheduledPaymentAccountingPlatformSyncPromotion && (
          <QBOSyncPromotionBanner
            analyticProperties={analyticsProperties}
            onDone={createDoneHandler('syncQBOAccountingPlatform')}
            onClose={onDismissHandler}
          />
        )}
        {promotionName === FeatureFlags.PlatformScheduledPaymentCardToCardPromotionEnabled && (
          <CardToCardPromotionBanner
            analyticProperties={{ ...analyticsProperties, DeliveryMethodType: 'virtual-card' }}
            onDone={createDoneHandler('goToDashboard')}
            onClose={onDismissHandler}
          />
        )}
      </NewSinglePaymentStepLayout.Content>
    );
  }
);
