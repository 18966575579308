import { ApiError, useMelioQueryClient } from '@melio/api-client';
import {
  PaymentsApiClient,
  PayorApiClient,
  PostPayorPayPaymentRequest,
  PostPayorPayPaymentResponseData,
} from '@melio/ar-api-axios-client';
import { useMutation } from 'react-query';

import { useModel, UseModelProps } from '../../api-client';

export type UseGuestPayorPaymentProps = UseModelProps<typeof PaymentsApiClient.getPaymentsPaymentId>;

export const useGuestPayorPayment = (props: UseGuestPayorPaymentProps = {}) => {
  const query = useModel({
    ...props,
    queryKey: ['PaymentApi', props.id],
    queryFn: PaymentsApiClient.getPaymentsPaymentId,
  });

  const queryClient = useMelioQueryClient();

  const { mutateAsync: createPayment, isLoading: isCreatingPayment } = useMutation<
    PostPayorPayPaymentResponseData,
    ApiError,
    PostPayorPayPaymentRequest
  >(
    async ({ fundingSourceId, paymentRequestLink, scheduledDate }) => {
      const response = await PayorApiClient.postPayorPayPayment({ fundingSourceId, paymentRequestLink, scheduledDate });
      return response.data.data;
    },
    {
      mutationKey: 'PayorApiClient:postPayorPayPayment',
      onSuccess: () => {
        void queryClient.invalidateQueries('PublicPayorApi');
      },
    }
  );

  return {
    ...query,
    createPayment,
    isCreatingPayment,
  };
};
