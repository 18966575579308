import { useInvalidFundingSources } from '@melio/ap-domain';
import {
  BillSubscriptionOptions,
  FsTypeOption,
  FundingSourceType,
  Payment,
  useFundingSources,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { keyBy } from 'lodash';
import { useMemo } from 'react';

export const useFilteredFundingSources = ({
  fundingSourceTypesOptions,
  billSubscriptionOptions,
  payment,
  isRecurring,
}: {
  fundingSourceTypesOptions: FsTypeOption[] | undefined;
  billSubscriptionOptions: BillSubscriptionOptions | undefined;
  payment: Payment | undefined;
  isRecurring: boolean;
}) => {
  const { getIsValidFundingSource } = useInvalidFundingSources(fundingSourceTypesOptions);
  const { data: fundingSources = [], isLoading: isFundingSourcesLoading } = useFundingSources();
  const { formatMessage } = useMelioIntl();

  const filteredFundingSourcesData = useMemo(() => {
    const validFundingSourcesBeforeRecurringCheck = fundingSources.filter(getIsValidFundingSource);
    let validFundingSources = validFundingSourcesBeforeRecurringCheck;
    if ((isRecurring || payment?.subscriptionOccurrenceId) && billSubscriptionOptions?.eligibleFundingSources) {
      const eligibleFundingSourceMap = keyBy(billSubscriptionOptions.eligibleFundingSources, 'id');
      validFundingSources = validFundingSourcesBeforeRecurringCheck.filter(
        (fundingSource) => eligibleFundingSourceMap[fundingSource.id]
      );
    }

    const getCardDisabledMessage = (): string | undefined => {
      const orgHasCardFundingSource = !!fundingSources.find(({ type }) => type === FundingSourceType.Card);
      if (!orgHasCardFundingSource) {
        return formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.type.disabled');
      }

      const hasValidCard = !!validFundingSourcesBeforeRecurringCheck.find(
        ({ type }) => type === FundingSourceType.Card
      );
      if (!hasValidCard) {
        return formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.type.disabled.notCapable');
      }

      const hasValidCardForRecurring = !!validFundingSources.find(({ type }) => type === FundingSourceType.Card);
      if (!hasValidCardForRecurring) {
        return formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.type.disabled.recurring');
      }

      return;
    };

    return {
      fundingSources: validFundingSources,
      cardDisabledMessage: getCardDisabledMessage(),
    };
  }, [
    fundingSources,
    isRecurring,
    billSubscriptionOptions,
    getIsValidFundingSource,
    formatMessage,
    payment?.subscriptionOccurrenceId,
  ]);

  return {
    fundingSources: filteredFundingSourcesData.fundingSources,
    isLoading: isFundingSourcesLoading,
    cardDisabledMessage: filteredFundingSourcesData.cardDisabledMessage,
  };
};
