import { BaseModal, Button, Container, Group, Icon, SelectionCardProps, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { useBoolean } from '@melio/platform-utils';

export const useCardPaymentLearnMoreModal = (
  isModalAvailable: boolean
): {
  onDismiss: () => void;
  isOpen: boolean;
  mainLabelProps: Partial<SelectionCardProps['mainLabelProps']> | null;
  descriptionProps: Partial<SelectionCardProps['descriptionProps']> | null;
} => {
  const [isFeatureEnabled] = usePartnerFeature('PromoteCardFundingSource', false);
  const [isOpen, setIsOpen] = useBoolean(false);
  const { formatMessage } = useMelioIntl();

  const isActive = isFeatureEnabled && isModalAvailable;

  return {
    onDismiss: () => setIsOpen.off(),
    isOpen,
    mainLabelProps: isActive
      ? {
          pillProps: {
            label: formatMessage('widgets.fundingSources.addFundingSourceCard.credit.learnMore.label'),
            status: 'informative',
          },
        }
      : null,
    descriptionProps: isActive
      ? {
          action: {
            label: formatMessage('widgets.fundingSources.addFundingSourceCard.credit.learnMore.descriptionCta'),
            onClick: () => setIsOpen.on(),
          },
        }
      : null,
  };
};

export const CardPaymentLearnMoreModal = ({
  isOpen,
  onDismiss,
  onCardAction,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  onCardAction: () => void;
}) => {
  const { formatMessage } = useMelioIntl();

  const { track } = useAnalytics();

  useAnalyticsView('PaymentMethod', isOpen, true, {
    PageName: 'why-pay-with-a-credit-card',
    Intent: 'credit-card-info',
  });

  const handleAction = () => {
    track('PaymentMethod', 'Click', {
      PageName: 'why-pay-with-a-credit-card',
      Intent: 'pay-by-card',
      Cta: 'pay-by-card',
    });
    onCardAction();
  };

  const handleDismiss = () => {
    track('PaymentMethod', 'back', {
      PageName: 'why-pay-with-a-credit-card',
      Intent: 'pay-by-card',
      Cta: 'back',
    });
    onDismiss();
  };

  return (
    <BaseModal isOpen={isOpen} onClose={handleDismiss}>
      <Container textAlign="center" backgroundColor="light" paddingY="m">
        <Container justifyContent="center">
          <img src="https://platform-static.meliopayments.com/assets/capital-one/credit-card.svg" />
        </Container>
      </Container>
      <Container paddingX="l" paddingY="l" paddingTop="xl">
        <BaseModal.Body>
          <Group variant="vertical">
            <Text textStyle="heading2Semi">
              {formatMessage('widgets.fundingSources.addFundingSourceCard.credit.learnMore.title')}
            </Text>
            <Text textStyle="body2">
              {formatMessage('widgets.fundingSources.addFundingSourceCard.credit.learnMore.body')}
            </Text>
            <Group>
              <Text textStyle="body2Semi">
                <Icon type="checked" size="small" />
              </Text>
              <Group variant="vertical" spacing="xxs">
                <Text textStyle="body2Semi">
                  {formatMessage('widgets.fundingSources.addFundingSourceCard.credit.learnMore.bullet.1.title')}
                </Text>
                <Text textStyle="body2">
                  {formatMessage('widgets.fundingSources.addFundingSourceCard.credit.learnMore.bullet.1.description')}
                </Text>
              </Group>
            </Group>
            <Group>
              <Text textStyle="body2Semi">
                <Icon type="checked" size="small" />
              </Text>
              <Group variant="vertical" spacing="xxs">
                <Text textStyle="body2Semi">
                  {formatMessage('widgets.fundingSources.addFundingSourceCard.credit.learnMore.bullet.2.title')}
                </Text>
                <Text textStyle="body2">
                  {formatMessage('widgets.fundingSources.addFundingSourceCard.credit.learnMore.bullet.2.description')}
                </Text>
              </Group>
            </Group>
          </Group>
        </BaseModal.Body>
        <BaseModal.Footer>
          <Group variant="vertical">
            <Button
              label={formatMessage('widgets.fundingSources.addFundingSourceCard.credit.learnMore.cta')}
              onClick={handleAction}
            />
          </Group>
        </BaseModal.Footer>
      </Container>
    </BaseModal>
  );
};
