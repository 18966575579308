import { useMelioIntl } from '@melio/platform-i18n';
import { noop } from 'lodash';
import { createContext, PropsWithChildren, ReactNode, useContext, useRef, useState } from 'react';

type FormBannerAnnouncementContextType = {
  setBannerRef: (ref: HTMLDivElement) => void;
  announceBanner: VoidFunction;
  isAlreadyAnnounced: boolean;
};

type Props = {
  children: ReactNode;
};

const FormBannerAnnouncementContext = createContext<FormBannerAnnouncementContextType | undefined>(undefined);

export const FormBannerAnnouncementProvider = ({ children }: Props) => {
  const bannerRef = useRef<HTMLDivElement>();
  const [isAlreadyAnnounced, setAlreadyAnnounced] = useState(false);

  const setBannerRef = (ref: HTMLDivElement) => {
    bannerRef.current = ref;
  };

  const announceBanner = () => {
    setAlreadyAnnounced(true);
    bannerRef.current?.focus();
    bannerRef.current?.scrollIntoView();
  };

  return (
    <FormBannerAnnouncementContext.Provider value={{ setBannerRef, announceBanner, isAlreadyAnnounced }}>
      {children}
    </FormBannerAnnouncementContext.Provider>
  );
};

export const useFormBanner = (): FormBannerAnnouncementContextType => {
  const context = useContext(FormBannerAnnouncementContext);
  if (context === undefined) {
    return {
      announceBanner: noop,
      setBannerRef: noop,
      isAlreadyAnnounced: false,
    };
  }
  return context;
};

type FormBannerAnnouncementProps = {
  'data-testid'?: string;
};

export const FormBannerAnnouncement = (props: PropsWithChildren<FormBannerAnnouncementProps>) => {
  const { formatMessage } = useMelioIntl();
  const { children, ...rest } = props;

  return (
    <section aria-label={formatMessage('app.notifications.ariaLabel')} {...rest}>
      {children}
    </section>
  );
};
