import { SystemMessage, TestModeMessage, useIsMobile, useSystemMessage, useUserActivation } from '@melio/ar-domain';
import { Group, Layout, LayoutProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type InvoiceFormLayoutProps = {
  onClose?: VoidFunction;
  isDisabled?: boolean;
  isLoading?: boolean;
  footer?: NonNullable<LayoutProps['footer']>['content'];
  header?: NonNullable<LayoutProps['header']>['content'];
  backgroundColor?: LayoutProps['backgroundColor'];
  maxWidth: LayoutProps['maxWidth'];
};

export const InvoiceFormLayout = forwardRef<InvoiceFormLayoutProps>(
  ({ isLoading, isDisabled, onClose, footer, header, children, ...props }, ref) => {
    const isMobile = useIsMobile();
    const { message } = useSystemMessage();
    const { isTestMode } = useUserActivation();

    const isMessage = message || isTestMode;
    return (
      <Layout
        isLoading={isLoading}
        footer={footer && { content: footer, isSticky: true }}
        header={isMobile && header ? { content: header, isSticky: true } : undefined}
        data-component={InvoiceFormLayout.displayName}
        {...props}
        ref={ref}
      >
        <Group variant="vertical" spacing={isMessage ? 'xl' : 'none'}>
          <Group variant="vertical" spacing="s">
            <TestModeMessage screen="edit-create-share" />
            <SystemMessage />
          </Group>
          <Group variant="vertical" spacing="xl" data-testid="invoice-screen-content">
            {!isMobile && header}
            {children}
          </Group>
        </Group>
      </Layout>
    );
  }
);
InvoiceFormLayout.displayName = 'InvoiceFormLayout';
