import { Plan, PlanInfo, useMelioIntl } from '@melio/ar-domain';
import { Grid, Link } from '@melio/penny';
import { useConfig } from '@melio/platform-provider';
import { PlanCardView } from '@melio/subscriptions';

export type SubscriptionPlanCardProps = {
  planInfo: PlanInfo;
  plan: Plan;
};

export const SubscriptionPlanCard = ({ plan, planInfo }: SubscriptionPlanCardProps) => {
  const { formatMessage } = useMelioIntl();
  const { privacyPolicyUrl } = useConfig().settings;

  return (
    <Grid
      padding="4px"
      justifyContent="center"
      gap="m"
      gridTemplateColumns="1fr"
      data-testid="firm-client-subscription-plan-selection-screen"
    >
      <PlanCardView
        id={plan.id}
        headerProps={{
          planName: formatMessage('activities.subscription.plans.FiservApArBasic.title'),
          planDescription: formatMessage('activities.subscription.plans.FISERV_AP_AR_BASIC.description'),
        }}
        priceProps={{
          finalPrice: plan.cycles.monthly.basePrice,
          isFreePlan: false,
          selectedBillingCycle: 'monthly',
        }}
        featureListProps={{
          capabilities: planInfo.capabilities,
        }}
        planFeeProps={{
          transactionFee: formatMessage('activities.subscription.plans.planCard.fees.transactionsFeesMayApply', {
            viewFeesSchedule: (
              <Link
                color="secondary"
                href={privacyPolicyUrl}
                label={formatMessage('activities.subscription.plans.planCard.fees.feesSchedule')}
                newTab
              />
            ),
          }),
        }}
      />
    </Grid>
  );
};
