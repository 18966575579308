import { ApiPagination, ARInvoice } from '@melio/ar-domain';
import { Group } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { InvoiceActionsHandlers } from '../../../utils';
import { EmptyTableState, InvoiceTable, InvoiceTableToolbar } from '../components';
import { Filters, OnSort, SetSortableInvoicesParams, SortParams } from '../types';

export type InvoicesTabScreenProps = {
  onRowClick?: (params: { rowId: string; rowData: ARInvoice }) => void;
  invoices: ARInvoice[];
  pagination: ApiPagination;
  isFetching?: boolean;
  filters: Filters;
  onClearFilters: () => void;
  search?: string;
  sortParams: SortParams;
  onParamsChange: SetSortableInvoicesParams;
  invoiceActions: InvoiceActionsHandlers;
  selectedInvoiceId?: string;
  id?: string;
};

export const InvoicesTabScreen = forwardRef<InvoicesTabScreenProps>(
  (
    {
      invoices,
      pagination,
      isFetching,
      onRowClick,
      onParamsChange,
      onClearFilters,
      filters,
      search,
      sortParams,
      invoiceActions,
      selectedInvoiceId,
      ...props
    },
    ref
  ) => {
    const noResults = !isFetching && invoices.length === 0;

    useAnalyticsView('Dashboard', !isFetching, true, {
      ProductName: 'ar',
      PageName: 'dashboard-invoices',
      TabName: 'invoices',
      Flow: 'dashboard',
      CountInTab: pagination.totalCount,
      Intent: 'view-ar-invoices',
    });

    const { track } = useAnalytics();

    const handleSort: OnSort = (sort, order) => {
      track('Dashboard', 'Click', {
        ProductName: 'ar',
        PageName: 'dashboard',
        TabName: 'invoices',
        Flow: 'dashboard',
        Intent: 'sort-invoices',
        Cta: sort,
        SortOrder: order,
        SortDirection: sort,
      });
      onParamsChange({ sort, order });
    };

    return (
      <Group
        width="full"
        variant="vertical"
        data-component={InvoicesTabScreen.displayName}
        data-testid="invoices-tab-screen"
        {...props}
        ref={ref}
      >
        <InvoiceTableToolbar
          search={search}
          onSearch={(searchTerm) => onParamsChange({ searchTerm })}
          filters={filters}
          onSort={handleSort}
          sortParams={sortParams}
          onClearFilters={onClearFilters}
          onFilter={(filters) => {
            track('Dashboard', 'Click', {
              ProjectName: 'ar',
              PageName: 'dashboard',
              TabName: 'invoices',
              Flow: 'dashboard-filters',
              Intent: 'filter-invoices',
              Cta: filters,
            });
            onParamsChange(filters);
          }}
          isLoading={isFetching}
        />

        <InvoiceTable
          invoices={invoices}
          pagination={pagination}
          isLoading={isFetching}
          onRowClick={onRowClick}
          onSort={handleSort}
          sortParams={sortParams}
          invoiceActions={invoiceActions}
          selectedInvoiceId={selectedInvoiceId}
        />
        {noResults && <EmptyTableState />}
      </Group>
    );
  }
);

InvoicesTabScreen.displayName = 'InvoicesTabScreen';
