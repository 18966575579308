import { useTaxRates } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useMemo } from 'react';

import { TaxRatesTableScreen, TaxRatesTableScreenProps } from './screens';

type TaxRatesTableActivityProps = {
  onError?: ARErrorFunction;
  onEdit: TaxRatesTableScreenProps['onEdit'];
  onDelete: TaxRatesTableScreenProps['onDelete'];
  onAdd: VoidFunction;
};

export const TaxRatesTableActivity = forwardRef<TaxRatesTableActivityProps>(
  ({ onEdit, onAdd, onError, onDelete, ...props }, ref) => {
    const { data, isLoading, isFetching } = useTaxRates({ onError });

    const sortedData = useMemo(
      () =>
        (data ?? []).sort((a, b) => {
          if (a.isDefault && !b.isDefault) return -1;
          if (!a.isDefault && b.isDefault) return 1;
          return a.name.localeCompare(b.name);
        }),
      [data]
    );

    return (
      <TaxRatesTableScreen
        isLoading={isLoading || isFetching}
        taxRates={sortedData}
        onDelete={onDelete}
        onEdit={onEdit}
        onAdd={onAdd}
        {...props}
        ref={ref}
      />
    );
  }
);
