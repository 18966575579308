import { Button, Icon, IconButton, SortDirection, SortDropdownMenu } from '@melio/penny';
import { MessageKey } from '@melio/platform-i18n';
import { useMelioIntl } from '@melio/platform-provider';

import { usePayDashboardMobileSort } from './usePayDashboardMobileSort';

export const MobileSortMenu = ({
  items,
  onSortChange,
  title = 'activities.payDashboard.sort.title',
  showTriggerText = true,
}: {
  items: { label: string; cellId: string; defaultSortOrder: SortDirection }[];
  onSortChange?: VoidFunction;
  title?: MessageKey;
  showTriggerText?: boolean;
}) => {
  const { sortMenuItems, isMenuOpen, setIsMenuOpen, selectedItemIndex } = usePayDashboardMobileSort({
    sortableItems: items,
    onSortChange,
  });
  const { formatMessage } = useMelioIntl();

  const label = formatMessage('sort.button.text');

  const renderTrigger = () => {
    if (showTriggerText) {
      return (
        <Button
          leftElement={<Icon size="small" type="sort" color="inherit" aria-hidden />}
          label={label}
          variant="tertiary"
          size="medium"
          aria-label={label}
          isFullWidth
        />
      );
    }
    return <IconButton icon="sort" variant="primary" size="large" aria-label={label} />;
  };

  return (
    <SortDropdownMenu
      items={sortMenuItems}
      selectedItemIndex={selectedItemIndex}
      sortDirection={sortMenuItems[selectedItemIndex]?.sortDirection}
      trigger={renderTrigger()}
      onOpenChange={setIsMenuOpen}
      isOpen={isMenuOpen}
      title={formatMessage(title)}
    />
  );
};
