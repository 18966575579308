import { Route, Routes } from 'react-router-dom';

import { FiservPaymentActivity, PaymentRoutingActivity } from '../activities';
import { GuestPayorFundingSourceTypes } from '../types';
import { usePaymentFulfillmentRouter } from './hooks';

type PaymentFlowProps = {
  paymentRequestLink: string;
  onViewInvoice: VoidFunction;
  onError?: ARErrorFunction;
  onDone: (paymentId: string) => unknown;
};

export const FiservPaymentFulfillmentFlow = ({ paymentRequestLink, onViewInvoice, onError }: PaymentFlowProps) => {
  const { Paths, goToBank, goToCard } = usePaymentFulfillmentRouter();

  const handleFundingSourceSelection = (fundingSourceType: GuestPayorFundingSourceTypes) => {
    switch (fundingSourceType) {
      case 'card':
        return goToCard({ replace: false });
      case 'bank':
        return goToBank({ replace: false });
    }
  };

  return (
    <Routes>
      <Route
        path={Paths.Routing}
        element={
          <PaymentRoutingActivity
            paymentRequestLink={paymentRequestLink}
            onDone={(next) => {
              switch (next) {
                case 'card':
                  return goToCard();
                case 'bank':
                  return goToBank();
              }
            }}
            onError={onError}
          />
        }
      />
      <Route
        path={Paths.Bank}
        element={
          <FiservPaymentActivity
            fundingSourceType="bank"
            onSelectFundingSourceType={handleFundingSourceSelection}
            paymentRequestLink={paymentRequestLink}
            onViewInvoice={onViewInvoice}
            onError={onError}
          />
        }
      />
      <Route
        path={Paths.Card}
        element={
          <FiservPaymentActivity
            fundingSourceType="card"
            onSelectFundingSourceType={handleFundingSourceSelection}
            paymentRequestLink={paymentRequestLink}
            onViewInvoice={onViewInvoice}
            onError={onError}
          />
        }
      />
    </Routes>
  );
};
