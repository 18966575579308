import { useMtlDateUtils } from '@melio/ap-domain';
import { FieldValues, UseMelioFormResults } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import type { Path } from 'react-hook-form';
import * as yup from 'yup';

import { phoneIsValid } from '../../../../../../utils/validation/phone.valdiation.utils';
import { useInternationalAddressSchema } from '../../../../../international-address/internationalAddressSchema';

const getDirtyValues = <T extends FieldValues, DirtyFields extends Record<string, unknown>>({
  dirtyFields,
  formValues,
}: {
  dirtyFields: DirtyFields;
  formValues: T;
}): Partial<T> =>
  Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RFH sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) {
      return prev;
    }

    return {
      ...prev,
      [key as keyof T]:
        typeof dirtyFields[key] === 'object'
          ? getDirtyValues({ dirtyFields: dirtyFields[key] as DirtyFields, formValues: formValues[key] as T })
          : (formValues[key] as T),
    };
  }, {});
export const getDirtyValuesFromForm = <T extends FieldValues>({
  form,
}: {
  form: UseMelioFormResults<T>;
}): Partial<T> => {
  const formValues = form.getValues();
  const dirtyFields = form.formState.dirtyFields;

  return getDirtyValues({ dirtyFields, formValues });
};

export const setValueAndMakeDirty = <T extends FieldValues, K extends Path<T>>({
  fieldKey,
  form,
  value,
}: {
  form: UseMelioFormResults<T>;
  fieldKey: K;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: T[K];
}) => form.setValue(fieldKey, value, { shouldDirty: true });

export const useFxCommonValidations = () => {
  const MIN_AGE = 18;

  const { formatMessage } = useMelioIntl();
  const { addressSchema, countryCodeSchema } = useInternationalAddressSchema();
  const { isValidAge } = useMtlDateUtils();

  const firstNameSchema = yup
    .string()
    .required(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.firstName.required'
      )
    )
    .min(
      2,
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.firstName.invalid'
      )
    )
    .trim();

  const lastNameSchema = yup
    .string()
    .required(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.lastName.required'
      )
    )
    .min(
      2,
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.lastName.invalid'
      )
    )
    .trim();

  const ssnSchema = yup
    .string()
    .required(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.ssn.required')
    )
    .trim();

  const dateOfBirthSchema = yup
    .date()
    .required(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.dateOfBirth.required'
      )
    )
    .typeError(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.dateOfBirth.required'
      )
    )
    .test(
      'validMinAge',
      formatMessage(
        `activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.dateOfBirth.minAge`,
        {
          age: MIN_AGE,
        }
      ),
      (v) => isValidAge(v, MIN_AGE, 'min')
    );

  const emailSchema = yup
    .string()
    .email(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.email.invalid')
    )
    .required(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.email.required')
    )
    .trim();

  const phoneSchema = yup
    .string()
    .trim()
    .required(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.phone.required')
    )
    .test(
      'phoneIsValid',
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.phone.invalid'),
      phoneIsValid
    );

  return {
    phoneSchema,
    emailSchema,
    firstNameSchema,
    lastNameSchema,
    addressSchema,
    countryCodeSchema,
    ssnSchema,
    dateOfBirthSchema,
  };
};
