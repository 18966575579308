import { Box } from '@chakra-ui/react';
import { Group, NakedButton, SelectionCard } from '@melio/penny';
import { FundingSourceBankAccount } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

export type FundingSourceCardListProps = {
  fundingSources?: FundingSourceBankAccount[];
  onAddPlaidAccount: VoidFunction;
  onSelectedFundingSource?: (fundingSourceId?: string) => void;
  selectedFundingSourceId?: string;
  isDisabled?: boolean;
};

export const FundingSourceCardList = forwardRef<FundingSourceCardListProps>(
  (
    { fundingSources = [], onAddPlaidAccount, onSelectedFundingSource, selectedFundingSourceId, isDisabled, ...props },
    ref
  ) => (
    <Box
      paddingY="xs"
      data-component={FundingSourceCardList.displayName}
      data-testid="funding-source-card-list"
      {...props}
      ref={ref}
    >
      <Group spacing="s" variant="vertical" width="full" alignItems="flex-start">
        {fundingSources.map((fundingSource) => {
          const isSelected = fundingSource.id === selectedFundingSourceId;
          const idx = fundingSource.displayName.indexOf(',');
          return (
            <SelectionCard
              key={fundingSource.id}
              data-testid={`funding-source-card-${fundingSource.id}`}
              isSelected={!isDisabled && isSelected}
              mainLabelProps={{ label: fundingSource.displayName.substring(0, idx) }}
              descriptionProps={{ label: fundingSource.displayName.substring(idx + 1) }}
              image={{
                src: fundingSource.logo ?? '',
                alt: fundingSource.displayName,
                fallbackIcon: 'bank',
              }}
              onClick={() => onSelectedFundingSource?.(isSelected ? undefined : fundingSource.id)}
              isDisabled={isDisabled}
            />
          );
        })}
        <NakedButton
          data-testid="add-another-bank-account-button"
          variant="secondary"
          label="+ Add another bank account"
          onClick={onAddPlaidAccount}
          isDisabled={isDisabled}
        />
      </Group>
    </Box>
  )
);
FundingSourceCardList.displayName = 'FundingSourceCardList';
