import { CatalogItem, EmptyState, FormattedMessage } from '@melio/ar-domain';
import { Container, Group, NakedButton, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { CatalogItemsTable, CatalogItemsTableToolbar } from '../components';

export type CatalogItemsTableScreenProps = {
  searchTerm?: string;
  setSearchTerm: (value: string) => unknown;
  isLoading?: boolean;
  catalogItems: CatalogItem[];
  onEditItem: (itemId: string) => unknown;
  onDeleteItem: (itemId: string) => unknown;
  onAddItem: VoidFunction;
  onImportCatalogItems: VoidFunction;
};

export const CatalogItemsTableScreen = forwardRef<CatalogItemsTableScreenProps>(
  (
    {
      setSearchTerm,
      onImportCatalogItems,
      searchTerm,
      catalogItems,
      isLoading,
      onEditItem,
      onDeleteItem,
      onAddItem,
      ...props
    },
    ref
  ) => {
    const { track } = useAnalytics();

    return (
      <Group variant="vertical" {...props} ref={ref}>
        <CatalogItemsTableToolbar
          onImportCatalogItems={() => {
            track('Settings', 'Click', {
              ProductName: 'ar',
              PageName: 'invoice-items',
              TabName: 'items',
              Flow: 'manage-invoice-items',
              Intent: 'upload-catalog-items',
              Cta: 'upload',
            });
            onImportCatalogItems();
          }}
          onAddItem={onAddItem}
          isLoading={isLoading}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <CatalogItemsTable
          catalogItems={catalogItems}
          onDeleteItem={onDeleteItem}
          onEditItem={onEditItem}
          isLoading={isLoading}
        />
        {!isLoading && catalogItems.length === 0 && (
          <EmptyState
            illustrationType="no-items"
            content={
              <Container textAlign="center">
                <Text textStyle="body4">
                  <FormattedMessage id="ar.settings.activities.catalogItemsTable.components.catalogItemsTable.emptyState.search.text" />
                </Text>
              </Container>
            }
            footer={
              <NakedButton data-testid="clean-search-button" label="Clear all" onClick={() => setSearchTerm('')} />
            }
          />
        )}
      </Group>
    );
  }
);
