import { Navigate } from 'react-router-dom';
import { PartnerGroupEnum } from '@melio/partner-tools';

import { FullScreenContainer } from '@/cl/components/FullScreenContainer/FullScreenContainer.component';
import { usePartnerConfig } from '@/hooks/partners';
import { useRouter } from '@/hooks/router.hooks';
import { useSyncOnDemand } from '@/hooks/useSyncOnDemand.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { ErrorPage } from '@/widgets/error-page/ErrorPage.widget';

export const TimeoutScreen = () => {
  const { formatMessage } = usePlatformIntl();
  const { partnerConfig, partnerGroup } = usePartnerConfig();
  const { goToPayDashboard, generateErrorLink } = useRouter();

  const { triggerSync, isLoading, isSyncDone, isError: isSyncError } = useSyncOnDemand();
  const fallbackPageName =
    partnerGroup === PartnerGroupEnum.FISERV ? partnerConfig.config.settings.partnerProductName : 'dashboard';

  if (isSyncDone) {
    goToPayDashboard();
  }

  return !isSyncError ? (
    <FullScreenContainer centered>
      <ErrorPage
        illustration="page-not-found"
        title={formatMessage('screens.timeout.title')}
        message={formatMessage('screens.timeout.message', { fallbackPageName })}
        primaryButton={{
          text: formatMessage('screens.timeout.button', { fallbackPageName }),
          onClick: triggerSync,
          isLoading,
        }}
      />
    </FullScreenContainer>
  ) : (
    <Navigate to={generateErrorLink()} />
  );
};
