import { Company } from '@melio/platform-api';

import countryStateData from './states-data.json';

export const hasRequiredAddressFields = (address: Partial<Company['address']> | null): boolean => {
  if (!address) {
    return false;
  }
  const { line1, city, state, postalCode } = address;
  return !!(line1 && city && state && postalCode);
};

export const isPOBox = (addressLine1?: string | null): boolean => {
  if (!addressLine1) {
    return false;
  }

  const poBoxesString = [
    'po box',
    'p.o box',
    'p.o.box',
    'p.o. box',
    'p o box',
    ' po ',
    'p.o',
    'p o b',
    'p o b.',
    ' pob ',
    'pobox',
    'post office box',
    'postal service box',
    'post office bin',
    'p.o. bin',
  ];

  return poBoxesString.some((poBoxString) => addressLine1.toLocaleLowerCase().includes(poBoxString));
};

type State = {
  stateCode: string;
  name: string;
  id: number;
};

export const countriesWithStates = countryStateData.filter((country) => !!country.states.length);

export const getStatesByCountry = (countryCode: string): Array<State> | undefined =>
  countriesWithStates.find((country) => country.iso2.toUpperCase() === countryCode.toUpperCase())?.states;

export const isValidState = (stateCode: string, countryCode: string) => {
  const states = getStatesByCountry(countryCode);
  if (!states?.length) {
    return true;
  }
  return !!states?.find((s) => s.stateCode === stateCode.toUpperCase());
};
