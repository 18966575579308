import { ApiKeys, PaymentsApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQuery } from 'react-query';

export const usePaymentPendingApprovers = ({ id }: { id: string }) => {
  const queryKey = [ApiKeys.PaymentsApi, 'model', id, 'pendingApprovers'];

  return useQuery({
    queryKey,
    queryFn: async () => {
      const result = await PaymentsApiClient.getPaymentPendingApprovers(id);
      return result.data.data;
    },
  });
};
