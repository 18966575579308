import { forwardRef, useImperativeHandle, useState } from 'react';

import { MicroDepositsVerificationModalActivity } from './MicroDepositsVerification.modal-activity';
import { MicroDepositModalProps, MicroDepositModalRefHandlers } from './types';

export const MicroDepositModalWrapper = forwardRef<MicroDepositModalRefHandlers, MicroDepositModalProps>(
  ({ initialValue, fundingSourceId, deliveryMethodId, onSuccess, onError }, ref) => {
    const [isOpen, setIsOpen] = useState(!!initialValue);

    useImperativeHandle(ref, () => ({
      openModal: () => setIsOpen(true),
    }));

    return (
      <MicroDepositsVerificationModalActivity
        fundingSourceId={fundingSourceId}
        deliveryMethodId={deliveryMethodId}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onDone={() => {
          setIsOpen(false);
        }}
        onSuccessMicroDepositVerified={onSuccess}
        onError={onError}
      />
    );
  }
);

MicroDepositModalWrapper.displayName = 'MicroDepositModalWrapper';
