import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

import {
  getArePaymentsToCombineContainEBills,
  getCanCombinePayments,
  getThereIsSameVendorForMultipleBills,
} from '../../../../BatchPayments.activity.utils';
import { SchedulePaymentIntent } from '../../../../types';

export const useCombinePaymentsToggle = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]) => {
  const { formatMessage } = useMelioIntl();
  const [isEbillsFFEnabled] = useDevFeature(FeatureFlags.EBills, false);

  const bills = paymentIntentsWithDerivatives?.flatMap(({ bills }) => bills);
  const hasEBills = isEbillsFFEnabled && getArePaymentsToCombineContainEBills(bills);

  const isDisabled = !getCanCombinePayments(bills) || hasEBills;

  const getTooltip = () => {
    if (!getThereIsSameVendorForMultipleBills(bills)) {
      return formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.combinePayments.tooltip.hasNoCommonVendor'
      );
    }
    if (hasEBills) {
      return formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.combinePayments.tooltip.hasEBills'
      );
    }
    return formatMessage(
      'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.combinePayments.tooltip.active'
    );
  };

  return { isDisabled, getTooltip };
};
