import { InvoiceDiscountsApiClient } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

export type UseInvoiceDiscountProps = UseModelProps<
  typeof InvoiceDiscountsApiClient.getInvoiceDiscountById,
  typeof InvoiceDiscountsApiClient.updateInvoiceDiscountById,
  typeof InvoiceDiscountsApiClient.postInvoiceDiscount
>;

export const useInvoiceDiscount = (props: UseInvoiceDiscountProps = {}) =>
  useModel({
    ...props,
    queryKey: 'InvoiceDiscountsApi',
    queryFn: InvoiceDiscountsApiClient.getInvoiceDiscountById,
    createFn: InvoiceDiscountsApiClient.postInvoiceDiscount,
    deleteFn: InvoiceDiscountsApiClient.deleteInvoiceDiscountById,
    updateFn: InvoiceDiscountsApiClient.updateInvoiceDiscountById,
  });

export type InvoiceDiscountModel = ReturnType<typeof useInvoiceDiscount>;
