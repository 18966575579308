import {
  extractData,
  GetVendorsResponse,
  VendorsApiClient,
  VendorsApiInstance,
  VendorWarning,
  VendorWarningCodeEnum,
} from '@melio/platform-api-axios-client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AxiosResponse } from 'axios';

import { BatchUpdateParam } from '../../core';
import {
  BatchElement,
  BatchElementError,
  BatchElementSuccess,
  ParamsWithIds,
  UseVendorsParams,
  Vendor,
  VendorExpandParam,
  VendorsListParams,
  VendorUpdateParams,
} from './types';

export const patchVendorsVendorsIds = (data: BatchUpdateParam<VendorUpdateParams>): Promise<BatchElement[]> =>
  Promise.all(
    data.map(({ id, data }) =>
      VendorsApiClient.patchVendorsVendorId(id, data)
        .then((response) => ({ id, data: response.data.data, status: 'success' } as BatchElementSuccess))
        .catch((response: PlatformError) => ({ id, error: response, status: 'error' } as BatchElementError))
    )
  );

export async function getVendorsByIds(ids: string[], expand?: VendorExpandParam) {
  const data = await Promise.all(ids.map((id) => VendorsApiClient.getVendorsVendorId(id, expand).then(extractData)));
  return {
    data: { data, pagination: { cursors: {}, totalCount: ids.length } },
  } as AxiosResponse<Override<GetVendorsResponse, { data: Vendor[] }>>;
}

export const getVendors = (params: VendorsListParams) => {
  const { cursor, expand, limit, searchTerm, sort } = params;
  return VendorsApiInstance.getVendors({
    expand,
    searchTerm,
    search: JSON.stringify(params.search),
    limit,
    cursor,
    sort,
  });
};

const isParamsWithIds = (params: UseVendorsParams = {}): params is ParamsWithIds => 'ids' in params;
export function createGetVendorsQueryFn(params: UseVendorsParams = {}) {
  return () => (isParamsWithIds(params) ? getVendorsByIds(params.ids, params.expand) : getVendors(params));
}

export const isVendorBankAccountNotCreated = (warnings?: VendorWarning[]) =>
  !!warnings?.some(({ code }) => code === VendorWarningCodeEnum.AchDeliveryMethodWasNotCreated);
