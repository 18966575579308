import { Box } from '@chakra-ui/react';
import { FormattedMessage } from '@melio/ar-domain';
import { Container, Group, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

export type CustomPaymentInstructionsProps = {
  paymentInstructions: string;
};

export const CustomPaymentInstructions = forwardRef<CustomPaymentInstructionsProps>(
  ({ paymentInstructions, ...props }, ref) => (
    <Container
      data-component={CustomPaymentInstructions.displayName}
      data-testid="custom-payment-instructions"
      backgroundColor="light"
      width="full"
      paddingX="s"
      paddingY="s"
      {...props}
      ref={ref}
    >
      <Group variant="vertical" spacing="xxs">
        <Text textStyle="body4Semi" color="global.neutral.800">
          <FormattedMessage id="ar.guestPayment.customInstructionsPayment.title.text" />
        </Text>
        <Box textStyle="body3" whiteSpace="pre-line">
          {paymentInstructions}
        </Box>
      </Group>
    </Container>
  )
);

CustomPaymentInstructions.displayName = 'CustomPaymentInstructions';
