import { usePrevious } from '@chakra-ui/react';
import { useAnalytics } from '@melio/platform-analytics';
import { useEffect } from 'react';
import { FieldErrors, UseFormGetValues } from 'react-hook-form';

import { getFilledFields } from '../utils';
import { CompleteRequiredDetailsFormFields, MissingField } from './types';

export function useAnalyticsReportFailures<T extends CompleteRequiredDetailsFormFields>(
  submitCount: number,
  errors: FieldErrors<T>,
  getValues: UseFormGetValues<T>,
  missingFields: MissingField[],
  ctaLabel: string
) {
  const { track } = useAnalytics();
  const prevSubmitCount = usePrevious(submitCount);

  useEffect(() => {
    if (prevSubmitCount !== submitCount) {
      if (Object.keys(errors).length) {
        const data = getValues();
        const { filledFields, numFilledOrgFields, numFilledUserFields } = getFilledFields(missingFields, data);

        const failureReasons = Object.fromEntries(Object.entries(errors).map(([key, value]) => [key, value?.message]));

        track('Organization', 'Status', {
          Intent: 'submit-details',
          NumFilledUserFields: numFilledUserFields,
          NumFilledOrgFields: numFilledOrgFields,
          FilledFields: filledFields,
          Cta: ctaLabel,
          Status: 'Failure',
          FailureReasons: failureReasons,
        });
      }
    }
  }, [submitCount, errors, prevSubmitCount, ctaLabel, track, missingFields, getValues]);
}
