import { isFXCurrency } from '@melio/ap-domain';
import {
  convertInboxItemCentsToDollars,
  convertPaymentsCentsToDollars,
  InboxItem,
  InboxItemBill,
  InboxItemPaymentRequest,
  InboxItemScannedInvoice,
  Payment,
  ShortHistory,
  Todo,
  TodoActivateCollaborators,
  TodoActivateCollaboratorsTypeEnum,
  TodoFailedPayment,
  TodoFailedPaymentTypeEnum,
  TodoOverdueIn7DaysInboxItems,
  TodoOverdueIn7DaysInboxItemsTypeEnum,
  TodoOverdueInboxItems,
  TodoOverdueInboxItemsTypeEnum,
  TodoOverdueInMoreThan7DaysInboxItems,
  TodoOverdueInMoreThan7DaysInboxItemsTypeEnum,
  TodoPaymentsToApprove,
  TodoPaymentsToApproveTypeEnum,
} from '@melio/platform-api';
import { maxBy } from 'lodash';

//in payments the currency from the api is the real currency.
//while the amount is always USD and the foreignAmount is the matching to the currency
export const normalizePayments = (todo: TodoFailedPayment | TodoPaymentsToApprove) =>
  todo.items.data.map((d) => ({
    amount: isFXCurrency(d.currency) ? d.foreignAmount || d.amount : d.amount,
    currency: d.currency,
    usdToForeignRate: d.usdToForeignRate,
    scheduledDate: d.scheduledDate,
    estimatedDelivery: d.estimatedDelivery,
  }));

//in paymentRequest and scannedInvoice the currency is always "USD"
//while in bills it can be any other currencies
//in bills the currency is the real currency while the amount is the foreign amount if it is foreign
export const normalizeInboxItems = (
  todo: TodoOverdueInboxItems | TodoOverdueIn7DaysInboxItems | TodoOverdueInMoreThan7DaysInboxItems | undefined
) => {
  if (!todo) {
    return [];
  }
  return todo.items.data
    .map((d) => {
      switch (d.type) {
        case 'bill': {
          const payload = d.payload;
          return {
            amount: payload.amount,
            currency: payload.currency,
            vendorName: payload.vendor?.name ?? '',
            dueDate: payload.dueDate,
            usdToForeignRate: payload.usdToForeignRate == null ? undefined : payload.usdToForeignRate,
            type: 'bill',
          };
        }
        case 'paymentRequest': {
          const payload = d.payload;
          return {
            amount: payload.totalAmount,
            currency: payload.currency,
            vendorName: payload.vendor?.name ?? '',
            dueDate: payload.dueDate,
            type: 'paymentRequest',
          };
        }
        case 'scannedInvoice': {
          const payload = d.payload;
          return {
            amount: payload.amount,
            currency: 'USD',
            vendorName: payload.vendorName ?? '',
            dueDate: payload.dueDate,
            type: 'scannedInvoice',
          };
        }
        case 'payment':
          return null;
      }
    })
    .filter(
      (
        d
      ): d is {
        amount: number;
        currency: string;
        vendorName: string;
        dueDate: Date;
        usdToForeignRate: number | undefined;
        type: InboxItemBill['type'] | InboxItemPaymentRequest['type'] | InboxItemScannedInvoice['type'];
      } => !!d
    );
};

export const isEmptySection = (todo?: Todo) =>
  !todo || todo.items.pagination.totalCount === 0 || todo.items.pagination.totalCount == null;

export const filterByCreatedAt =
  (date: Date) =>
  ({ history }: { history?: ShortHistory }) =>
    history ? history.createdAt.getTime() > date.getTime() : false;

export const sortByCreatedAt = ({ history }: { history?: ShortHistory }) => history?.createdAt.getTime();

export const filterByDueDate =
  (date: Date) =>
  ({ dueDate }: { dueDate?: Date }) =>
    dueDate ? dueDate.getTime() > date.getTime() : false;

export const sortByCreatedAtAndDueDate = ({ dueDate, history }: { dueDate?: Date | null; history?: ShortHistory }) =>
  Math.max(dueDate?.getTime() ?? 0, history?.createdAt?.getTime() ?? 0);

export const filterByUpdatedAt =
  (date: Date) =>
  ({ history }: { history?: ShortHistory }) =>
    history ? history.updatedAt?.getTime() > date.getTime() : false;

export const sortByUpdatedAt = ({ history }: { history?: ShortHistory }) => history?.updatedAt?.getTime();

export type EnrichedTodo<T> = T & {
  enrichment: { newItemsCount: number; newItemsAfterLastSeenCount: number; newestItemDate?: Date };
};

export const enrichActivateCollaboratorsTodo = ({
  todo,
  isLoadingLastSeenDate,
  lastSeenDate,
}: {
  todo: TodoActivateCollaborators;
  isLoadingLastSeenDate: boolean;
  lastSeenDate?: Date;
}): EnrichedTodo<TodoActivateCollaborators> => {
  const newestItemDate = maxBy(todo.items.data, sortByCreatedAt)?.history?.createdAt;
  const newItemsCount = todo.items.pagination.totalCount;
  const newItemsAfterLastSeenCount = isLoadingLastSeenDate
    ? 0
    : lastSeenDate
    ? todo.items.data.filter((t) => t.history?.createdAt && t.history?.createdAt.getTime() > lastSeenDate.getTime())
        .length
    : newItemsCount;
  return {
    ...todo,
    enrichment: { newItemsCount, newItemsAfterLastSeenCount, newestItemDate },
  };
};

export const enrichPaymentsTodo = ({
  todo,
  newItems,
  isLoadingLastSeenDate,
  lastSeenDate,
}: {
  todo: TodoFailedPayment | TodoPaymentsToApprove;
  newItems: Payment[];
  isLoadingLastSeenDate: boolean;
  lastSeenDate?: Date;
}): EnrichedTodo<TodoFailedPayment | TodoPaymentsToApprove> => {
  const newestItemDate = newItems.length > 0 ? maxBy(newItems, sortByUpdatedAt)?.history?.updatedAt : undefined;
  const newItemsAfterLastSeenCount = isLoadingLastSeenDate
    ? 0
    : lastSeenDate
    ? newItems.filter((payment) => payment.history.updatedAt?.getTime() > lastSeenDate.getTime()).length
    : newItems.length;
  const enrichedTodo = {
    ...todo,
    items: { ...todo.items, data: convertPaymentsCentsToDollars(todo.items.data) },
  };
  return {
    ...enrichedTodo,
    enrichment: { newItemsCount: newItems.length, newItemsAfterLastSeenCount, newestItemDate },
  };
};

export const enrichOverdueInboxItemsTodo = ({
  todo,
  isLoadingLastSeenDate,
  lastSeenDate,
  newItems,
}: {
  todo: TodoOverdueInboxItems;
  isLoadingLastSeenDate: boolean;
  lastSeenDate?: Date;
  newItems: (InboxItemPaymentRequest | InboxItemBill | InboxItemScannedInvoice)[];
}): EnrichedTodo<TodoOverdueInboxItems> => {
  const newItemsPayloads = newItems.map((inboxItem) => inboxItem.payload);
  const newestItem = newItems.length > 0 ? maxBy(newItemsPayloads, sortByCreatedAtAndDueDate) : undefined;
  const newestItemDate = newestItem
    ? (newestItem.dueDate?.getTime() ?? 0) > (newestItem.history?.createdAt.getTime() ?? 0)
      ? newestItem.dueDate
      : newestItem.history?.createdAt
    : undefined;
  const newItemsAfterLastSeenCount = isLoadingLastSeenDate
    ? 0
    : lastSeenDate
    ? newItemsPayloads.filter(
        (inboxItem) =>
          (inboxItem.dueDate && inboxItem.dueDate.getTime() > lastSeenDate.getTime()) ||
          (inboxItem.history?.createdAt && inboxItem.history.createdAt.getTime() > lastSeenDate.getTime())
      ).length
    : newItems.length;
  const enrichedTodo = {
    ...todo,
    items: {
      ...todo.items,
      data: todo.items.data.map((d) => convertInboxItemCentsToDollars(d as InboxItem)),
    },
  };
  return {
    ...enrichedTodo,
    enrichment: {
      newItemsCount: newItems.length,
      newestItemDate: newestItemDate ?? undefined,
      newItemsAfterLastSeenCount,
    },
  };
};

type InboxItemsTodoTypes = TodoOverdueIn7DaysInboxItems | TodoOverdueInMoreThan7DaysInboxItems;
export const enrichOverdueInFutureInboxItemsTodo = ({
  todo,
  isLoadingLastSeenDate,
  lastSeenDate,
  newItems,
}: {
  todo: InboxItemsTodoTypes;
  isLoadingLastSeenDate: boolean;
  lastSeenDate?: Date;
  newItems: InboxItem[];
}): EnrichedTodo<InboxItemsTodoTypes> => {
  const newItemsPayloads = newItems.map((inboxItem) => inboxItem.payload);
  const newestItemDate = newItems.length > 0 ? maxBy(newItemsPayloads, sortByCreatedAt)?.history?.createdAt : undefined;
  const newItemsAfterLastSeenCount = isLoadingLastSeenDate
    ? 0
    : lastSeenDate
    ? newItemsPayloads.filter((inboxItem) => inboxItem.history.createdAt.getTime() > lastSeenDate.getTime()).length
    : newItems.length;
  const enrichedTodo = {
    ...todo,
    items: {
      ...todo.items,
      data: todo.items.data.map((d) => convertInboxItemCentsToDollars(d as InboxItem)),
    },
  };
  return {
    ...enrichedTodo,
    enrichment: { newItemsCount: newItems.length, newestItemDate, newItemsAfterLastSeenCount },
  };
};

export const getAnalyticsData = (todos: Todo[] | undefined) => ({
  ActivateAccount:
    todos?.find((t) => t.type === TodoActivateCollaboratorsTypeEnum.ActivateCollaborators)?.items.pagination
      .totalCount ?? 0,
  FailedPayments:
    todos?.find((t) => t.type === TodoFailedPaymentTypeEnum.FailedPayments)?.items.pagination.totalCount ?? 0,
  ApprovePayments:
    todos?.find((t) => t.type === TodoPaymentsToApproveTypeEnum.PaymentsToApprove)?.items.pagination.totalCount ?? 0,
  OverdueBills:
    todos?.find((t) => t.type === TodoOverdueInboxItemsTypeEnum.OverdueInboxItems)?.items.pagination.totalCount ?? 0,
  UnpaidBills:
    todos
      ?.filter(
        (t) =>
          t.type === TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems ||
          t.type === TodoOverdueInMoreThan7DaysInboxItemsTypeEnum.OverdueInMoreThan7DaysInboxItems
      )
      .map((t) => t.items.pagination.totalCount ?? 0)
      .reduce((acc, cur) => acc + cur, 0) ?? 0,
});
