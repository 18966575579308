import { addWildcardToRoutes, useFlowRouting, useIsTestMode, withHidden, withOutlet } from '@melio/ar-domain';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useShouldCollectLegalInfoOnPaymentFlow } from '@melio/platform-kyc';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import { CreateInvoiceActivity as _CreateInvoiceActivity } from '../activities';
import { useInvoiceSubRoutes } from './useInvoiceSubRoutes';

const CreateInvoiceActivity = withOutlet(withHidden(_CreateInvoiceActivity));

export type CreateInvoiceFlowProps = {
  onClose: VoidFunction;
  onDone: (id?: string) => void;
  onIssueInvoice: (id: string) => void;
  onError?: ARErrorFunction;
  isMtlKycUpliftEnabled?: boolean;
};

export const CreateInvoiceFlow = withAnalyticsContext<CreateInvoiceFlowProps>(
  forwardRef(({ setAnalyticsProperties, onClose, onDone, onIssueInvoice, isMtlKycUpliftEnabled, ...props }, ref) => {
    const { Paths, goToCreateInvoice } = useCreateInvoiceFlowNavigation();
    const isTestMode = useIsTestMode();

    const {
      renderSubRoutes,
      openTaxRateModal,
      openDiscountModal,
      openServiceChargeModal,
      removeServiceCharge,
      discount,
      serviceCharges,
      removeDiscount,
      taxRate,
      onCreateCustomer,
      onUpdateCustomer,
      customerId,
      goToCompleteKycDetails,
      currentRoute,
      isKycSkipped,
    } = useInvoiceSubRoutes({ onClose: goToCreateInvoice });

    const { shouldCollectLegalInfoOnPaymentFlow } = useShouldCollectLegalInfoOnPaymentFlow();

    setAnalyticsProperties({
      PageName: 'create-invoice',
      Flow: 'create-invoice',
      Intent: 'create-invoice',
      IsTestInvoice: isTestMode,
    });

    const onCreateDone = (id: string, shouldIssue?: boolean) => {
      if (!shouldIssue) {
        onDone(id);
      } else {
        onIssueInvoice(id);
      }
    };

    return (
      <Routes>
        <Route
          path={Paths.Home}
          element={
            <CreateInvoiceActivity
              discount={discount}
              onSetDiscount={openDiscountModal}
              onRemoveDiscount={removeDiscount}
              onClose={onClose}
              onDone={onCreateDone}
              taxRate={taxRate}
              onSetTaxRate={openTaxRateModal}
              serviceCharges={serviceCharges}
              onAddServiceCharge={openServiceChargeModal}
              onRemoveServiceCharge={removeServiceCharge}
              onCreateCustomer={onCreateCustomer}
              onUpdateCustomer={onUpdateCustomer}
              customerId={customerId}
              isHidden={currentRoute === 'CompleteKycDetails'}
              isMissingKycDetails={isMtlKycUpliftEnabled && shouldCollectLegalInfoOnPaymentFlow && !isKycSkipped}
              handleMissingKycDetails={goToCompleteKycDetails}
              {...props}
              ref={ref}
            />
          }
        >
          {renderSubRoutes()}
        </Route>
      </Routes>
    );
  })
);

CreateInvoiceFlow.displayName = 'CreateInvoiceFlow';

const useCreateInvoiceFlowNavigation = () => {
  enum Paths {
    Home = '',
  }

  const { createCallback } = useFlowRouting<Paths>({ withSearchparams: true });
  const goToCreateInvoice = createCallback(Paths.Home);

  return {
    Paths: addWildcardToRoutes(Paths),
    goToCreateInvoice,
  };
};
