import {
  ApiQueryKey,
  createQueryKey,
  EntityWithId,
  Parser,
  useCreateMutation,
  useDeleteMutation,
  useUpdateMutation,
} from '@melio/api-client';
import { ApiCreateRequest, ApiDeleteRequest, ApiUpdateRequest, ARPaginationResponse } from '@melio/ar-api-axios-client';
import { useDebouncedObject, useIsMounted, useUpdateEffect } from '@melio/platform-utils';
import { AxiosPromise } from 'axios';
import { isNull, isObject } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsMutating, useQuery, useQueryClient } from 'react-query';

import { AnyType, ApiResult, CollectionProps, PromiseFunctionReturnData, UseCollectionResult } from './types';
import { normalizeParams } from './utils';

const DEFAULT_PAGE_SIZE = 10;

type PaginatedApiListRequest<T = AnyType> = (...args: AnyType[]) => AxiosPromise<{
  data: T[];
  pagination?: ARPaginationResponse;
}>;

export const useCollection = <
  TQueryFn extends PaginatedApiListRequest,
  TCreateQueryFn extends ApiCreateRequest = never,
  TUpdateQueryFn extends ApiUpdateRequest = never,
  TDeleteQueryFn extends ApiDeleteRequest = never,
  TData = PromiseFunctionReturnData<TQueryFn>[number],
  TCreateVariables = Required<Parameters<TCreateQueryFn>>[0],
  TUpdateVariables = Required<Parameters<TUpdateQueryFn>>[1]
>({
  queryKey: _queryKey,
  queryFn: _queryFn,
  scope = 'default',
  enabled = !!_queryFn,
  createFn,
  onCreate,
  onCreateError,
  prepareCreateParams,
  updateFn,
  onUpdate,
  onUpdateError,
  prepareUpdateParams,
  deleteFn,
  onDelete,
  onDeleteError,
  isPaginated,
  populateModels = false,
  limit = DEFAULT_PAGE_SIZE,
  keepPreviousData = isPaginated,
  pageNumber = 1,
  params: _params,
  paramsParser,
  onPageChange,
  ...options
}: CollectionProps<
  TQueryFn,
  TCreateQueryFn,
  TUpdateQueryFn,
  TDeleteQueryFn,
  TData,
  TCreateVariables,
  TUpdateVariables
>): UseCollectionResult<TQueryFn, TDeleteQueryFn, TData, TCreateVariables, TUpdateVariables> => {
  type TQueryFnData = PromiseFunctionReturnData<TQueryFn>[number];
  type TDataWithId = TData & EntityWithId;

  const [pagination, setPagination] = useState<ARPaginationResponse>({
    limit,
    pageNumber,
    totalCount: 0,
  });

  // support back and forth pagination via pageNumber on a router or any other state management
  useEffect(() => setPagination((current) => ({ ...current, pageNumber })), [pageNumber]);

  // memoize the params to avoid unnecessary refetching when the params change
  const params = useMemo(
    () => (paramsParser ? _params && paramsParser(_params) : _params ?? {}),
    [JSON.stringify(normalizeParams(_params))] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // debounce target params calculation to avoid unnecessary refetching when resetting the page on params change
  const targetParams = useDebouncedObject(
    isPaginated ? { ...params, pageNumber: pagination.pageNumber, limit: pagination.limit } : params ?? {},
    10
  );

  const queryKey = createQueryKey({
    queryKey: _queryKey,
    role: 'paginated-collection',
    scope,
    params: normalizeParams(targetParams),
  });

  useUpdateEffect(() => goToPage(1), [JSON.stringify(params)]);

  const isMounted = useIsMounted();

  const queryFn = useCallback(async () => {
    const { data } = await _queryFn(targetParams);
    if (isMounted() && isPaginated) {
      setPagination((current) => ({ ...current, ...data.pagination }));
    }
    return data;
  }, [targetParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const queryClient = useQueryClient();

  const hasId = (item: TData): item is TDataWithId => isObject(item) && !isNull(item) && 'id' in item;
  const setDataInModel = (entity: TDataWithId) => {
    if (populateModels) {
      queryClient.setQueryData([queryKey[0], 'model', entity.id, scope], entity);
    }
  };

  const { initialData, onSuccess, placeholderData, select, useErrorBoundary, ...queryOptions } = options;
  const { data, ...query } = useQuery<ApiResult<TQueryFnData[]>, ARPlatformError, ApiResult<TData[]>, ApiQueryKey>({
    ...queryOptions,
    useErrorBoundary: useErrorBoundary as never,
    initialData: initialData && {
      data: initialData as TQueryFnData[],
      pagination,
    },
    placeholderData: placeholderData && {
      data: placeholderData as TQueryFnData[],
      pagination,
    },
    onSuccess: ({ data }) => {
      data.filter(hasId).forEach(setDataInModel);
      onSuccess?.(select ? select(data) : data);
    },
    queryKey,
    queryFn,
    enabled,
    keepPreviousData,
  });

  const useCreateQueryKey = useMemo(() => [...queryKey, 'create'], [queryKey]) as typeof queryKey;
  const useUpdateQueryKey = useMemo(() => [...queryKey, 'update'], [queryKey]) as typeof queryKey;
  const useDeleteQueryKey = useMemo(() => [...queryKey, 'delete'], [queryKey]) as typeof queryKey;

  // apply the select logic that is designed to handle an array to a single item
  const mutationSelect: Parser<PromiseFunctionReturnData<TQueryFn>[number], TData> = (data) => {
    if (select) {
      return select([data])[0] as TData;
    } else {
      return data as TData;
    }
  };

  const createMutation = useCreateMutation<TCreateQueryFn, TCreateVariables, TData, ARPlatformError>(
    createFn,
    useCreateQueryKey,
    {
      onSuccess: onCreate,
      onError: onCreateError,
      prepareData: prepareCreateParams,
      select: mutationSelect,
    }
  );

  const updateMutation = useUpdateMutation<TUpdateQueryFn, TQueryFnData, TUpdateVariables, ARPlatformError>(
    updateFn,
    useUpdateQueryKey,
    {
      onSuccess: onUpdate,
      onError: onUpdateError,
      prepareParams: prepareUpdateParams,
      select: mutationSelect,
    }
  );

  const deleteMutation = useDeleteMutation(deleteFn, useDeleteQueryKey, {
    onSuccess: onDelete,
    onError: onDeleteError,
  });

  const goToPage = (page: number) => {
    setPagination({ ...pagination, ...data?.pagination, pageNumber: page });
    if (page.toString() !== pageNumber.toString()) {
      onPageChange?.(page);
    }
  };

  const isCreating = useIsMutating(useCreateQueryKey) > 0;
  const isUpdating = useIsMutating(useUpdateQueryKey) > 0;
  const isDeleting = useIsMutating(useDeleteQueryKey) > 0;
  const _isMutating = useIsMutating(queryKey) > 0;
  const isMutating = useMemo(
    () => _isMutating || isCreating || isUpdating || isDeleting,
    [_isMutating, isCreating, isUpdating, isDeleting]
  );

  const refetch = async (...args: Parameters<typeof query.refetch>) =>
    query.refetch(...args).then((res) => ({ ...res, data: res.data?.data ?? [] }));

  return {
    ...query,
    data: useMemo(() => (select && data?.data ? select(data.data) : data?.data), [data]), // eslint-disable-line react-hooks/exhaustive-deps
    pagination: useMemo(() => ({ ...(data?.pagination ?? pagination), goToPage }), [data, pagination]), // eslint-disable-line react-hooks/exhaustive-deps
    queryKey,
    refetch: refetch as never,
    update: updateMutation.update,
    create: createMutation.mutateAsync,
    delete: deleteMutation.mutateAsync,
    isCreating,
    isUpdating,
    isDeleting,
    isMutating,
    _mutations: {
      create: createMutation,
      update: updateMutation,
      delete: deleteMutation,
    },
  };
};
