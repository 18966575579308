import { Group, Icon, Link, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useBoolean } from '@melio/platform-utils';

import { PaymentNoteToSelfModal } from '../../../../../payment-note-to-self-modal';

export const PaymentDetailsNoteToSelf = ({ paymentId, userNote }: { paymentId: string; userNote?: string }) => {
  const [isNoteToSelfModalOpen, setIsNoteToSelfModalOpen] = useBoolean(false);
  const { formatMessage } = useMelioIntl();

  const addNote = (
    <Group spacing="xxs" alignItems="center">
      <Icon type="note-add" size="small" color="brand" />
      <Link
        data-testid="add-note-to-self"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setIsNoteToSelfModalOpen.on();
        }}
        label={formatMessage(
          'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.addPaymentNoteLabel'
        )}
        size="medium"
        variant="standalone"
        color="secondary"
        isBold
      />
    </Group>
  );

  const editNote = (
    <Group variant="vertical" spacing="xxs">
      <Text textStyle="body3Semi">
        {formatMessage('activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.noteToSelf')}
      </Text>
      <Text textStyle="body3">{userNote}</Text>

      <Group width="fit-content">
        <Link
          data-testid="edit-note-to-self"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setIsNoteToSelfModalOpen.on();
          }}
          label={formatMessage(
            'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.editPaymentNoteLabel'
          )}
          size="medium"
          variant="standalone"
          color="secondary"
          isBold
        />
      </Group>
    </Group>
  );

  return (
    <>
      {userNote ? editNote : addNote}

      <PaymentNoteToSelfModal
        paymentId={paymentId}
        isOpen={isNoteToSelfModalOpen}
        onClose={setIsNoteToSelfModalOpen.off}
      />
    </>
  );
};
