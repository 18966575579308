import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { LocalStorageKeys } from '@melio/local-storage';
import { Button, Group, Modal, SectionBanner, Text } from '@melio/penny';
import { Account } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';

import { usePartnerLocalStorage } from '@/hooks/partners/usePartnerLocalStorage';
import { useRouter } from '@/hooks/router.hooks';
import { useSwitchOrganization } from '@/hooks/useSwitchOrganization.hooks';
import { appAccessTokenSelector } from '@/store/app/app.model';
import { usePlatformIntl } from '@/translations/Intl';
import { decodeAccessToken } from '@/utils/getAccessTokenData.utils';

const usePartnerLocalStorageAccessToken = () => {
  const localStorage = usePartnerLocalStorage();
  const [accessToken, setAccessToken] = useState<string>(localStorage.getItem(LocalStorageKeys.accessToken) || '');

  useEffect(() => {
    const handler = () => {
      setAccessToken(localStorage.getItem(LocalStorageKeys.accessToken) || '');
    };
    window.addEventListener('storage', handler);
    return () => {
      window.removeEventListener('storage', handler);
    };
  }, [localStorage]);

  return accessToken;
};

export const OutdatedSessionModal: React.FC<{ account: Account | undefined }> = ({ account }) => {
  const appAccessToken = useRecoilValue(appAccessTokenSelector);
  const storageAccessToken = usePartnerLocalStorageAccessToken();
  const hasAccessTokenConflict = !!appAccessToken && !!storageAccessToken && appAccessToken !== storageAccessToken;
  const { switchOrganization } = useSwitchOrganization();
  const [appMemoryAccessTokenPayload, storagePayload] = hasAccessTokenConflict
    ? [decodeAccessToken(appAccessToken), decodeAccessToken(storageAccessToken)]
    : [];
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  const { formatMessage } = usePlatformIntl();
  const [error, setError] = useState<string>();
  const [outdatedSessionModalEnabled] = usePartnerFeature('OutdatedSessionModal', false);

  if (!outdatedSessionModalEnabled) {
    return null;
  }

  const isDifferentUser =
    !!appMemoryAccessTokenPayload?.user.id &&
    !!storagePayload?.user.id &&
    appMemoryAccessTokenPayload.user.id !== storagePayload.user.id;

  const isOpen =
    hasAccessTokenConflict &&
    !!appMemoryAccessTokenPayload?.accountId &&
    !!storagePayload?.accountId &&
    appMemoryAccessTokenPayload.accountId !== storagePayload.accountId;

  const onRetrieveOutdatedSession = async () => {
    if (!account) {
      return;
    }
    setIsLoading(true);
    try {
      await switchOrganization({
        organizationId: account.organizationId,
        switchAccessTokenOnly: true,
        isAccountingFirm: !!account.isAccountingFirmMember && !account.isAccountingClient,
        loadingState: 'none',
      });
      router.refresh();
    } catch (error) {
      setIsLoading(false);
      setError((error as Error).message);
      throw error;
    }
  };

  return (
    <Modal
      data-testid="outdated-session-guard"
      isOpen={isOpen}
      onClose={onRetrieveOutdatedSession}
      header={formatMessage('outdated.modal.title')}
    >
      <Group variant="vertical">
        <Text>
          {formatMessage('outdated.modal.description', {
            companyName: isDifferentUser ? storagePayload?.organization?.companyName : account?.company.name,
          })}
        </Text>
        {error && <SectionBanner variant={'critical'} description={error} />}
        <Button
          label={formatMessage(`outdated.modal.cta.label`)}
          onClick={isDifferentUser ? () => router.refresh() : onRetrieveOutdatedSession}
          isLoading={isLoading}
          data-testid="outdated-session-cta"
        />
      </Group>
    </Modal>
  );
};
