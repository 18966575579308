import { ServiceChargeCatalog } from '@melio/ar-domain';
import { Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { ServiceChargesEmptyState, ServiceChargesTable, ServiceChargesTableToolbar } from '../components';

export type ServiceChargesTableScreenProps = {
  isLoading: boolean;
  onAdd: VoidFunction;
  serviceCharges: ServiceChargeCatalog[];
  onEdit: (serviceChargeId: string) => unknown;
  onDelete: (serviceChargeId: string) => unknown;
};

export const ServiceChargesTableScreen = forwardRef<ServiceChargesTableScreenProps>(
  ({ serviceCharges, onEdit, onDelete, isLoading, onAdd, ...props }, ref) => {
    if (!isLoading && serviceCharges.length === 0) {
      return <ServiceChargesEmptyState onAdd={onAdd} />;
    }
    return (
      <Group variant="vertical" {...props} ref={ref}>
        <ServiceChargesTableToolbar onAdd={onAdd} />
        <ServiceChargesTable
          onEdit={onEdit}
          onDelete={onDelete}
          serviceCharges={serviceCharges}
          isLoading={isLoading}
        />
      </Group>
    );
  }
);
