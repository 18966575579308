import { useBill, usePayments, useVendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { PaymentFlowActivityProps } from '../PaymentFlowActivity/PaymentFlowActivity';

export const useCreatePaymentDefaults = ({
  billId,
  vendorId,
  deliveryMethodId,
  amountToPay,
}: {
  billId?: string;
  vendorId?: string;
  deliveryMethodId?: string;
  amountToPay?: string;
}) => {
  const { formatMessage } = useMelioIntl();

  const { data: [payment] = [], isLoading: isPaymentsLoading } = usePayments({
    params: {
      limit: 1,
      expand: 'none',
      sort: [{ field: 'createdAt', order: 'desc' }],
    },
  });

  const { data: bill, isLoading: isBillLoading } = useBill({
    enabled: Boolean(billId),
    id: billId || 'CreatePaymentActivityBillId',
    params: { expand: 'none' },
    cacheTime: 0,
  });

  const defaultVendorId = bill?.vendorId || vendorId;

  const { data: vendor, isLoading: isVendorLoading } = useVendor({
    enabled: Boolean(defaultVendorId),
    id: defaultVendorId || 'CreatePaymentActivityVendorId',
  });

  const fundingSourceId = payment?.markedAsPaid ? undefined : payment?.fundingSourceId;

  const defaultValues: PaymentFlowActivityProps['defaultValues'] = {
    vendorId: vendor?.id,
    vendorEmail: vendor?.contact.email,
    deliveryMethodId,
    amountToPay: bill?.balance.toString() || amountToPay,
    noteToVendor: bill?.invoice.number
      ? formatMessage('activities.paymentFlow.form.content.defaultMemo', {
          invoiceNumber: bill.invoice.number,
        })
      : undefined,
    fundingSourceId,
  };

  return {
    defaultValues,
    bill,
    vendor,
    isLoading: isPaymentsLoading || isBillLoading || isVendorLoading,
  };
};
