import { FundingSource, PaymentCreationError, PaymentErrorCode, PaymentIntent } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { isEmpty, mapValues, omit, pickBy } from 'lodash';
import { useCallback, useState } from 'react';

import { cellToResetMap } from './consts';
import { CellError, TableCell, TableErrorMap } from './types';
import { useGetErrorDetails } from './useGetErrorDetails';

export const useSubmitBatchPaymentsErrors = ({ fundingSources }: { fundingSources: FundingSource[] | undefined }) => {
  const { formatMessage } = useMelioIntl();
  const [tableErrors, setTableErrors] = useState<TableErrorMap>({});
  const [bannerError, setBannerError] = useState<CellError | undefined>();
  const [isBatchPaymentsSubmitErrorsEnabled] = usePartnerFeature('BatchPaymentsSubmitErrors', false);

  const getErrorDetails = useGetErrorDetails({ fundingSources });

  const {
    settings: { supportPhoneNumber },
  } = useConfig();

  const setErrors = useCallback(
    (
      failedPaymentIntents: Array<{
        paymentIntent: PaymentIntent;
        paymentCreationError: PaymentCreationError & { errorCode?: PaymentErrorCode };
      }>
    ) => {
      if (!isBatchPaymentsSubmitErrorsEnabled) {
        return;
      }

      const criticalError = failedPaymentIntents.find(
        (failedPaymentIntent) => failedPaymentIntent.paymentCreationError.errorCode === PaymentErrorCode.AccountFrozen
      );

      if (criticalError) {
        setTableErrors({});
        setBannerError({
          variant: 'alert',
          label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.error.banner.title', {
            numberOfPaymentIntents: failedPaymentIntents.length,
          }),
          description: formatMessage('activities.batchPayments.screens.paymentIntentsTable.error.accountFrozen', {
            supportPhoneNumber,
          }),
        });

        return;
      }

      const newTableErrors: TableErrorMap = {};

      for (const failedPaymentIntent of failedPaymentIntents) {
        const { paymentIntent, paymentCreationError } = failedPaymentIntent;
        const { errorCode } = paymentCreationError;

        const errorDetails = getErrorDetails({ errorCode, paymentIntent });
        if (errorDetails) {
          newTableErrors[paymentIntent.id] = errorDetails;
        }
      }

      setTableErrors(newTableErrors);
      setBannerError(
        !isEmpty(newTableErrors)
          ? {
              variant: 'alert',
              label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.error.banner.title', {
                numberOfPaymentIntents: failedPaymentIntents.length,
              }),
              description: formatMessage(
                'activities.batchPayments.screens.paymentIntentsTable.error.banner.description'
              ),
            }
          : undefined
      );
    },
    [isBatchPaymentsSubmitErrorsEnabled, formatMessage, supportPhoneNumber, getErrorDetails]
  );

  const resetErrors = useCallback(
    ({ cell, paymentIntentIds }: { cell: TableCell; paymentIntentIds?: Array<PaymentIntent['id']> }) => {
      setTableErrors((prevErrors) => {
        const mappedErrors = mapValues(prevErrors, (rowErrors, rowId) => {
          if (!paymentIntentIds || paymentIntentIds.includes(rowId)) {
            return omit(rowErrors, cellToResetMap[cell]);
          }

          return rowErrors;
        });

        const remainingErrors = pickBy(mappedErrors, (rowErrors) => !isEmpty(rowErrors));
        if (isEmpty(remainingErrors)) {
          setBannerError(undefined);
        }

        return remainingErrors;
      });
    },
    []
  );

  return { tableErrors, bannerError, setErrors, resetErrors };
};
