import { FileUpload, Group, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { TaxpayerInfo } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type FileInfo = TaxpayerInfo['file'];

export type TaxPayerFileUploadProps = {
  isLoading?: boolean;
  onSelectFile: (file: File | null) => void;
  value?: FileInfo | null | File;
  onDelete: () => void;
  isInvalidFile?: boolean;
  createdByType?: TaxpayerInfo['createdByType'];
  vendorName?: string;
};

export const TaxPayerFileUpload = ({
  onSelectFile,
  value,
  isLoading,
  onDelete,
  isInvalidFile,
  createdByType,
  vendorName,
}: TaxPayerFileUploadProps) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const showDescription = !!(value as FileInfo)?.history && !isInvalidFile;
  const { createTrackHandler } = useAnalytics();
  const trackActionClick = createTrackHandler('Vendor', 'Click');
  const descriptionMessage =
    createdByType === 'payor'
      ? formatMessage('widgets.taxPayerInfo.attachmentByPayor.description', {
          date: formatDate((value as FileInfo)?.history?.createdAt ?? undefined, { dateStyle: 'medium' }),
        })
      : formatMessage('widgets.taxPayerInfo.attachmentByVendor.description', {
          vendorName,
          date: formatDate((value as FileInfo)?.history?.createdAt ?? undefined, { dateStyle: 'medium' }),
        });

  useAnalyticsView('Vendor', true, true, {
    TaxId: 'file',
    PageName: 'vendor-details',
    Intent: 'view-vendor',
  });

  return (
    <Group data-testid="taxpayer-form-file-upload" width="full" spacing="xs" variant="vertical">
      <FileUpload
        value={value as File}
        onDelete={onDelete}
        onChange={onSelectFile}
        acceptTypes={['pdf', 'png', 'jpg']}
        onReplace={() =>
          trackActionClick({
            Intent: 'view-vendor',
            TaxId: 'file',
            Cta: 'replace',
            PageName: 'vendor-details',
          })
        }
        isLoading={isLoading}
        isDisabled={isLoading}
        isInvalid={isInvalidFile}
        placeholder={formatMessage('widgets.taxPayerInfo.form.upload.placeholder')}
        onPreview={(previewFileMetadata) => {
          if (previewFileMetadata) {
            window.open(previewFileMetadata.url);
          }
        }}
      />
      {showDescription && (
        <Text data-testid="tax-payer-info-attachment-fileOwner-payor" textStyle="body4" color="global.neutral.800">
          {descriptionMessage}
        </Text>
      )}
      {isInvalidFile && (
        <Text data-testid="tax-payer-info-attachment-invalid-error" textStyle="body4" color="global.critical.700">
          {formatMessage('widgets.taxPayerInfo.uploadW9.error.fileSize')}
        </Text>
      )}
    </Group>
  );
};

TaxPayerFileUpload.displayName = 'TaxPayerFileUpload';
