import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';

import { SectionBlock } from './SectionBlock';
import { SectionWrapper } from './SectionWrapper';

export const NoteToSelfSection = ({ userNote }: { userNote?: string | null }) => {
  const { formatMessage } = useMelioIntl();
  const [isNoteToSelfEnabled] = usePartnerFeature('PaymentNoteToSelf', false);

  if (!isNoteToSelfEnabled || !userNote) {
    return null;
  }

  return (
    <SectionWrapper>
      <SectionBlock
        testId="payment-details-paymentNoteToSelf"
        title={{ label: formatMessage('widgets.paymentDetails.paymentNoteToSelf.label') }}
        body={{
          label: userNote,
        }}
        description={{
          label: '',
        }}
      />
    </SectionWrapper>
  );
};
