import { PaymentPendingApprovers } from '@melio/ap-activities';
import { useFundingSourceUnverifiedText } from '@melio/ap-widgets';
import { Group, SectionBanner, Text } from '@melio/penny';
import {
  BillSubscriptionEndPolicyEnum,
  DeliveryMethod,
  FundingSource,
  PaymentApprovalDecisionStatusEnum,
  SubscriptionOccurrence,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { useDateUtils } from '@melio/platform-utils';
import { isFuture } from 'date-fns';
import { useCallback, useMemo } from 'react';

export const PaymentDetailsBanners = ({
  deliveryMethod,
  fundingSource,
  scheduledDate,
  approvalDecisionStatus,
  subscriptionOccurrence,
  isEditPaymentFlow = false,
  isRecurringFlow = false,
  paymentId,
}: {
  deliveryMethod: DeliveryMethod;
  fundingSource: FundingSource;
  scheduledDate: Date;
  approvalDecisionStatus: PaymentApprovalDecisionStatusEnum;
  subscriptionOccurrence?: SubscriptionOccurrence | null;
  isEditPaymentFlow?: boolean;
  isRecurringFlow?: boolean;
  paymentId?: string | null;
}) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const {
    settings: {
      payment: {
        scheduling: { showFuturePaymentBanner },
        scheduled: { showEstimatedDeliveryDateBanner },
      },
    },
  } = useConfig();
  const { createDate } = useDateUtils();
  const [isPendingApproversEnabled] = usePartnerFeature('ApprovalWorkflowPendingApprovers', false);

  const isPendingApprovalWorkflow = approvalDecisionStatus === PaymentApprovalDecisionStatusEnum.Pending;
  const isFuturePayment = isFuture(scheduledDate);

  const unverifiedFundingSourceBannerText: string = useFundingSourceUnverifiedText({
    fundingSource,
    effectiveScheduleDate: scheduledDate,
  });

  const getPendingApprovalBannerMessage = useCallback(() => {
    const pendingApprovalMessage = formatMessage(
      'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.approval.banner',
      { date: formatDate(createDate(scheduledDate.toISOString()), { dateStyle: 'medium' }) }
    );

    return isPendingApproversEnabled ? (
      <Group variant="vertical" spacing="none">
        <Text textStyle="body3">{pendingApprovalMessage}</Text>
        {paymentId ? <PaymentPendingApprovers id={paymentId} /> : null}
      </Group>
    ) : (
      pendingApprovalMessage
    );
  }, [formatDate, scheduledDate, createDate, formatMessage, isPendingApproversEnabled, paymentId]);

  const banners = useMemo(() => {
    const labels = [];

    if (deliveryMethod.type === 'virtual-account') {
      labels.push({
        message: formatMessage(
          'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.unilateral.banner',
          {
            date: formatDate(scheduledDate, { month: 'short', day: 'numeric' }),
          }
        ),
        testId: 'unilateral-banner',
      });
    }

    if (isPendingApprovalWorkflow) {
      labels.push({
        message: getPendingApprovalBannerMessage(),
        testId: 'approval-banner',
      });
    }

    if (isRecurringFlow) {
      labels.push({
        message: formatMessage(
          'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.recurringPayment.banner'
        ),
        testId: 'recurring-payment-banner',
      });
    }

    if (isFuturePayment && showFuturePaymentBanner) {
      labels.push({
        message: formatMessage(
          'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.futurePayment.banner'
        ),
        testId: 'future-payment-banner',
      });
    }

    if (isEditPaymentFlow && subscriptionOccurrence) {
      let message = formatMessage(
        'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.editSingleRecurringPayment.banner',
        {
          occurrenceNumber: subscriptionOccurrence.occurrenceNumber,
          occurrences: subscriptionOccurrence.billSubscription?.numOfOccurrences,
        }
      );

      if (subscriptionOccurrence.billSubscription?.endPolicy == BillSubscriptionEndPolicyEnum.NoEndDate) {
        message = formatMessage(
          'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.editSingleRecurringPayment.untilUserCancel.banner'
        );
      }

      labels.push({
        message,
        testId: 'edit-single-recurring-payment-banner',
      });
    }

    if (showEstimatedDeliveryDateBanner && deliveryMethod.type === 'paper-check') {
      labels.push({
        message: formatMessage(
          'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.paperCheckEstimatedDeliveryDate.banner'
        ),
        testId: 'estimated-delivery-date-banner',
      });
    }

    if (unverifiedFundingSourceBannerText) {
      labels.push({
        message: unverifiedFundingSourceBannerText,
        testId: 'funding-source-unverified-banner',
      });
    }

    return labels;
  }, [
    deliveryMethod.type,
    isPendingApprovalWorkflow,
    isRecurringFlow,
    isFuturePayment,
    showFuturePaymentBanner,
    isEditPaymentFlow,
    subscriptionOccurrence,
    showEstimatedDeliveryDateBanner,
    unverifiedFundingSourceBannerText,
    formatMessage,
    formatDate,
    scheduledDate,
    getPendingApprovalBannerMessage,
  ]);

  return (
    <>
      {banners.length ? (
        <Group variant="vertical" spacing="s" data-testid="payment-details-card-banner">
          {banners.map((banner, i) => (
            <SectionBanner key={i} description={banner.message} variant="informative" data-testid={banner.testId} />
          ))}
        </Group>
      ) : null}
    </>
  );
};
