import { forwardRef } from '@melio/platform-utils';

import { PaymentProcessingIndicator } from '../../../../components';
import { BaseLayout } from '../../../../layout';

export const CreatingPaymentScreen = forwardRef((props, ref) => (
  <BaseLayout data-testid="payment-processing-screen" {...props} ref={ref}>
    <PaymentProcessingIndicator />
  </BaseLayout>
));
CreatingPaymentScreen.displayName = 'CreatingPaymentScreen';
