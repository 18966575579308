import { FormDialog, FormDialogProps, FormInputs, useMelioIntl } from '@melio/ar-domain';
import { useMelioForm } from '@melio/penny';
import { MccItem } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';
import { object, SchemaOf, string } from 'yup';

import { useMccOptions } from '../hooks';

type IndustrySelectionForm = { group: string; mcc: string };

const useSchema = () => {
  const { formatMessage } = useMelioIntl();
  return object().shape({
    group: string().required(formatMessage('ar.guestPayment.industrySelectionModal.category.validation')),
    mcc: string().required(formatMessage(`ar.guestPayment.industrySelectionModal.subcategory.validation`)),
  }) as SchemaOf<IndustrySelectionForm>;
};

export type IndustrySelectionAmexModalScreenProps = {
  isOpen: boolean;
  isSavingIndustry: boolean;
  isLoadingMccList: boolean;
  onSave: (mcc: string) => void;
  onClose: VoidFunction;
  mccIndustryList: MccItem[];
  vendorCompanyName: string;
  banner?: FormDialogProps['banner'];
};

export const IndustrySelectionAmexModalScreen = forwardRef<IndustrySelectionAmexModalScreenProps>(
  ({ isSavingIndustry, isLoadingMccList, onSave, mccIndustryList, vendorCompanyName, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { clearErrors, setValue, registerField, watch, ...rest } = useMelioForm<IndustrySelectionForm>({
      isSaving: isSavingIndustry,
      onSubmit: ({ mcc }) => onSave(mcc),
      schema: useSchema(),
    });
    const selectedGroup = watch('group');
    const { groupOptions, mccOptions } = useMccOptions({ mccIndustryList, selectedGroup, isMCCGroupsSupported: true });

    useEffect(() => {
      setValue('mcc', '');
      clearErrors('mcc');
    }, [selectedGroup]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <FormDialog
        isLoading={isLoadingMccList}
        data-testid="industry-selection-modal"
        data-component="IndustrySelectionAmexForm"
        header={formatMessage('ar.guestPayment.industrySelectionModal.header')}
        description={formatMessage(`ar.guestPayment.industrySelectionModal.amex.description`, {
          companyName: vendorCompanyName,
        })}
        primaryButton={{
          variant: 'primary',
          label: formatMessage('ar.guestPayment.industrySelectionModal.submit.label'),
        }}
        useFormResults={rest}
        {...props}
        ref={ref}
      >
        <FormInputs.Select
          shouldHideClearButton
          data-testid="industry-selection-modal-group-select"
          options={groupOptions}
          labelProps={{
            label: formatMessage('ar.guestPayment.industrySelectionModal.category.label'),
          }}
          placeholder={formatMessage('ar.guestPayment.industrySelectionModal.category.placeholder')}
          {...registerField('group')}
        />
        <FormInputs.Select
          shouldHideClearButton
          data-testid="industry-selection-modal-mcc-select"
          options={mccOptions}
          labelProps={{
            label: formatMessage(`ar.guestPayment.industrySelectionModal.subcategory.label`),
          }}
          placeholder={formatMessage(`ar.guestPayment.industrySelectionModal.subcategory.placeholder`)}
          isHidden={!selectedGroup}
          {...registerField('mcc')}
        />
      </FormDialog>
    );
  }
);
IndustrySelectionAmexModalScreen.displayName = 'IndustrySelectionAmexModalScreen';
