import { Modal, Text } from '@melio/penny';
import { EventProperties, useAnalytics } from '@melio/platform-analytics';
import { useEffect } from 'react';

type Props = {
  isOpen: boolean;
  title: string;
  description: string;
  okButtonText: string;
  cancelButtonText: string;
  onCancel: VoidFunction;
  onDone: VoidFunction;
  isLeaving: boolean;
};

type LeaveConfirmationModalAnalyticsIntent = 'exit-and-discard' | 'continue-adding-client';
type LeaveConfirmationModalAnalyticsCta = LeaveConfirmationModalAnalyticsIntent | 'exit';

const useLeaveConfirmationModalAnalytics = () => {
  const globalProperties: EventProperties = { PageName: 'exit-without-adding-client' };
  const { track } = useAnalytics();

  const trackClick = (
    intent: LeaveConfirmationModalAnalyticsIntent,
    cta: LeaveConfirmationModalAnalyticsCta = intent
  ) => track('Organization', 'Click', { ...globalProperties, Cta: cta, Intent: intent });

  return { track, trackClick };
};

export const LeaveConfirmationModal = ({
  isOpen,
  title,
  description,
  okButtonText,
  cancelButtonText,
  onDone,
  onCancel,
  isLeaving,
}: Props) => {
  const { track, trackClick } = useLeaveConfirmationModalAnalytics();

  useEffect(() => {
    if (isOpen) {
      track('Organization', 'View', { PageName: 'exit-without-adding-client', geIntent: 'exit' });
    }
  }, [isOpen, track]);

  const handleConfirmClick = () => {
    trackClick('exit-and-discard');
    onDone();
  };

  const getOnCancelClickHandler = (cta: LeaveConfirmationModalAnalyticsCta) => () => {
    trackClick('continue-adding-client', cta);
    onCancel();
  };

  return (
    <Modal
      data-testid="leave-confirmation-modal"
      isOpen={isOpen}
      onClose={getOnCancelClickHandler('exit')}
      header={title}
      isLoading={isLeaving}
      primaryButton={{
        label: okButtonText,
        onClick: handleConfirmClick,
        variant: 'primary',
      }}
      secondaryButton={{
        label: cancelButtonText,
        onClick: getOnCancelClickHandler('continue-adding-client'),
        variant: 'secondary',
      }}
    >
      <Text textStyle="body2">{description}</Text>
    </Modal>
  );
};
