import { Route, Routes } from 'react-router-dom';

import { SubscriptionTabsEnum } from '../constants';
import { AccountingClientSubscriptionPlanSelectedActivity } from './AccountingClientSubscriptionPlanSelected';
import { ConfirmDowngradeOwnerScreen } from './ConfirmDowngradeOwnerScreen/ConfirmDowngradeOwnerScreen';
import { DowngradeFlowNonOwnerScreen } from './DowngradeFlowNonOwnerScreen';
import { GraceEndPeriodForExternalAccountant } from './GraceEndPeriodForExternalAccountant';
import { GraceEndPeriodNonOwner } from './GraceEndPeriodNonOwner';
import { GraceEndPeriodOwner } from './GraceEndPeriodOwner';
import { OwnerShouldSetUpSubscriptionActivity } from './OwnerShouldSetUpSubscription';
import { SubscriptionSelectFundingSource } from './SubscriptionPaymentMethodSelect/SubscriptionSelectFundingSource.activity';
import { SubscriptionsSelectFundingSourceError } from './SubscriptionPaymentMethodSelect/SubscriptionSelectFundingSourceError';
import { SubscriptionsSelectFundingSourceSuccess } from './SubscriptionPaymentMethodSelect/SubscriptionSelectFundingSourceSuccess';
import { SubscriptionSuccessActivity } from './SubscriptionSuccess';
import { SubscriptionWizardActivity } from './SubscriptionWizard';

export const SubscriptionPlansRoute = () => (
  <Routes>
    <Route path="/*" element={<SubscriptionWizardActivity />} />
    <Route path={`/${SubscriptionTabsEnum.Success}/*`} element={<SubscriptionSuccessActivity />} />
    <Route path={`/${SubscriptionTabsEnum.PaymentMethodSelect}`} element={<SubscriptionSelectFundingSource />} />
    <Route
      path={`/${SubscriptionTabsEnum.PaymentMethodSelect}/success`}
      element={<SubscriptionsSelectFundingSourceSuccess />}
    />
    <Route
      path={`${SubscriptionTabsEnum.PaymentMethodSelect}/error`}
      element={<SubscriptionsSelectFundingSourceError />}
    />
  </Routes>
);

export const SubscriptionGraceRoutes = ({
  goToAssignClientPlan,
  switchOrganization,
}: {
  goToAssignClientPlan: ({ accountingClientId }: { accountingClientId: string }) => void;
  switchOrganization: ({
    organizationId,
    skipPayDashboardRedirect,
  }: {
    organizationId: string;
    skipPayDashboardRedirect?: boolean | undefined;
  }) => Promise<void>;
}) => (
  <Routes>
    <Route path="/select-your-plan" element={<GraceEndPeriodOwner />} />
    <Route path="/plan-is-required" element={<GraceEndPeriodNonOwner />} />
    <Route
      path="/select-client-plan"
      element={
        <GraceEndPeriodForExternalAccountant
          goToAssignClientPlan={goToAssignClientPlan}
          onSwitchOrganization={(organizationId: string) =>
            switchOrganization({ organizationId, skipPayDashboardRedirect: true })
          }
        />
      }
    />
  </Routes>
);

export const DowngradeSubscriptionRoutes = () => (
  <Routes>
    <Route path="/confirm-downgrade" element={<ConfirmDowngradeOwnerScreen />} />
    <Route path="/action-required" element={<DowngradeFlowNonOwnerScreen />} />
  </Routes>
);

export const AccountingClientSubscriptionRoutes = () => (
  <Routes>
    <Route path="/*" element={<AccountingClientSubscriptionPlanSelectedActivity />} />
  </Routes>
);

export const SubscriptionFlowsFullScreen = () => (
  <Routes>
    <Route path="/*" element={<OwnerShouldSetUpSubscriptionActivity />} />
  </Routes>
);
