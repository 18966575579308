import { useConfig } from '@melio/platform-provider';

import { usePlansMe, useSubscriptionMe } from '../api';
import { useAccountingClientSubscriptionSelectedPlan } from '../hooks/useAccountingClientSubscriptionSelectedPlan';
import { useIsSubscriptionsEnabled } from '../hooks/useIsSubscriptionsEnabled';

export type UseSubscriptionDataProps = {
  retryOnMount?: boolean;
};

export const useSubscriptionData = ({ retryOnMount }: UseSubscriptionDataProps = {}) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const {
    settings: { subscriptions: subscriptionsConfig },
  } = useConfig();
  const {
    data: subscription,
    isLoading: isLoadingMySubscription,
    error: subscriptionError,
  } = useSubscriptionMe({
    enabled: isSubscriptionsEnabled,
    retryOnMount,
  });
  const {
    data: plans,
    isLoading: isLoadingPlans,
    error: plansError,
  } = usePlansMe({
    enabled: isSubscriptionsEnabled,
  });
  const accountingClientSelectedSubscriptionPlan = useAccountingClientSubscriptionSelectedPlan();

  const isLoading = isLoadingMySubscription || isLoadingPlans;

  const error =
    plansError ||
    (subscriptionError && (subscriptionError.code !== '404' || subscriptionsConfig?.expectOrgsToBeSubscribed)
      ? subscriptionError
      : null);

  return { isLoading, error, subscription, plans, accountingClientSelectedSubscriptionPlan };
};
