import { useCatalogItem, useMelioIntl, useSystemMessage } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { DeleteIEntityDialogScreen } from '../shared';

type DeleteCatalogItemDialogActivityProps = {
  itemId?: string;
  onDone: VoidFunction;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
};

export const DeleteCatalogItemDialogActivity = forwardRef<DeleteCatalogItemDialogActivityProps>(
  ({ itemId, onDone: onSuccess, onClose, onError, ...props }, ref) => {
    const { data, delete: deleteItem, isLoading, isMutating } = useCatalogItem({ id: itemId, onError });

    const { formatMessage } = useMelioIntl();
    const { triggerMessage } = useSystemMessage();

    const onSubmit = () =>
      deleteItem()
        .then(() => {
          onSuccess();
          triggerMessage({
            type: 'informative',
            title: formatMessage('ar.settings.activities.catalogItemsTable.toast.onDelete.label', {
              name: data?.name,
            }),
          });
        })
        .catch(onError);

    return (
      <DeleteIEntityDialogScreen
        data-testid="delete-catalog-item-dialog"
        id={itemId}
        onSubmit={onSubmit}
        onClose={onClose}
        isDeleting={isMutating}
        isLoading={isLoading}
        header={formatMessage('ar.settings.activities.catalogItemsTable.screens.deleteCatalogItemDialog.header')}
        description={formatMessage(
          'ar.settings.activities.catalogItemsTable.screens.deleteCatalogItemDialog.description',
          { name: data?.name }
        )}
        primaryButtonLabel={formatMessage(
          'ar.settings.activities.catalogItemsTable.screens.deleteCatalogItemDialog.primaryButton.label'
        )}
        secondaryButtonLabel={formatMessage(
          'ar.settings.activities.catalogItemsTable.screens.deleteCatalogItemDialog.secondaryButton.label'
        )}
        {...props}
        ref={ref}
      />
    );
  }
);
