import { getDefaultValuesFromAccount, NewSinglePaymentStepLayout } from '@melio/ap-activities';
import { CompleteLegalInfoFormWidget, CompleteLegalInfoFormWidgetFields } from '@melio/ap-widgets';
import { Group, SectionBanner, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { CompanyLegalInfoField, LegalInfoFields, useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { kebabCase } from 'lodash';
import React, { useState } from 'react';

import { buildLegalCompanyData } from '../business-legal-info/CompleteLegalInfo/CompleteLegalInfo.activity.utils';

export const CompleteRequiredCompanyLegalInfo = withAnalyticsContext<{
  missingCompanyLegalInfoFields: Array<CompanyLegalInfoField>;
  companyName?: string;
}>(({ setAnalyticsProperties, missingCompanyLegalInfoFields }) => {
  const { formatMessage } = useMelioIntl();
  const [error, setError] = useState<string>();
  const { data: account, isLoading, update: updateAccount, isMutating: isMutatingAccount } = useAccount({ id: 'me' });
  const { onSubmissionStateChange, submitButtonProps } =
    useFormSubmissionController<CompleteLegalInfoFormWidgetFields>();

  const pageTitle = formatMessage('widgets.completeRequiredCompanyLegalInfo.title');
  const submitButtonLabel = formatMessage('widgets.completeRequiredCompanyLegalInfo.submit.label');

  setAnalyticsProperties({
    Flow: 'sign-in',
    Intent: 'sign-in',
    PageName: kebabCase(pageTitle),
  });

  const { track } = useAnalytics();

  useAnalyticsView('Organization', !isLoading, true);

  const handleSubmit = async ({
    legalCompanyName,
    legalAddress,
    phoneNumber,
    industry,
    businessType,
    taxInfoType,
    taxInfoIdentifier,
  }: LegalInfoFields) => {
    try {
      setError(undefined);
      track('Organization', 'Click', {
        Intent: 'submit-details',
        Cta: kebabCase(submitButtonLabel),
      });
      await updateAccount({
        company: buildLegalCompanyData(account, {
          legalCompanyName,
          legalAddress,
          phoneNumber,
          industry,
          businessType,
          taxInfoType,
          taxInfoIdentifier,
        }),
      });
      track('Organization', 'Status', {
        Intent: 'submit-details',
        NumRequiredOrgFields: missingCompanyLegalInfoFields.length,
        // all fields are required in this schema
        RequiredFields: missingCompanyLegalInfoFields,
        NumFilledOrgFields: missingCompanyLegalInfoFields.length,
        FilledFields: missingCompanyLegalInfoFields,
        Status: 'success',
      });
      track('VerifyTaxDetails', 'Submitted');
    } catch (error) {
      const message = (error as Error).message;
      Logger.log(`Error while trying to update account ${message ?? JSON.stringify(error)}`);
      setError(message ?? formatMessage('widgets.completeRequiredDetailsModal.error.generic'));
    }
  };

  const defaultValues = getDefaultValuesFromAccount(account);

  return (
    <NewSinglePaymentStepLayout isLoading={isLoading} dataTestId="complete-required-company-info-layout">
      <Group variant="vertical" spacing="s">
        <Group.Item>
          <NewSinglePaymentStepLayout.Title>
            {formatMessage('widgets.completeRequiredCompanyLegalInfo.title')}
          </NewSinglePaymentStepLayout.Title>
        </Group.Item>
        <Group.Item>
          <NewSinglePaymentStepLayout.Description>
            {formatMessage('widgets.completeRequiredCompanyLegalInfo.description', {
              companyName: account?.company.name ?? '',
            })}
          </NewSinglePaymentStepLayout.Description>
        </Group.Item>
      </Group>
      <NewSinglePaymentStepLayout.Content>
        <CompleteLegalInfoFormWidget
          missingLegalInfoFields={missingCompanyLegalInfoFields}
          onSubmit={handleSubmit}
          isSaving={isMutatingAccount}
          defaultValues={defaultValues}
          onSubmissionStateChange={onSubmissionStateChange}
        />
      </NewSinglePaymentStepLayout.Content>
      <Group variant="vertical" alignItems="center" spacing="l">
        <Group variant="vertical" alignItems="center" spacing="s">
          {error && <SectionBanner variant="critical" description={error} />}
          <NewSinglePaymentStepLayout.NextButton
            {...submitButtonProps}
            isLoading={isMutatingAccount}
            isFullWidth
            label={submitButtonLabel}
          />
        </Group>
      </Group>
    </NewSinglePaymentStepLayout>
  );
});
