import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';

export const useTodosEnabled = () => {
  const { can } = usePermissions();

  const [isTodosEnabled] = useDevFeature<boolean>(FeatureFlags.Todos, false, {
    shouldTrack: true,
  });

  return { isEnabled: isTodosEnabled && can({ subject: 'todos', action: 'read' }) };
};
