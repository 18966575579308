import { useMelioIntl } from '@melio/ar-domain';
// eslint-disable-next-line import/no-deprecated
import { _createFormFieldInput, Search, SearchOption, SearchProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { ChangeEvent } from 'react';

export type DiscountSelectWidgetProps = Omit<SearchProps, 'options' | 'creatableOption' | 'emptyState'> & {
  selectOptions: SearchOption[];
  onCreateDiscount: (name: string) => void;
  onChangeDiscount: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const DiscountSelectWidget = _createFormFieldInput(
  forwardRef<DiscountSelectWidgetProps, 'input'>(
    ({ onChange, onCreateDiscount, placeholder, onChangeDiscount, selectOptions, ...props }, ref) => {
      const { formatMessage } = useMelioIntl();

      return (
        <Search
          data-component="DiscountSelect"
          ref={ref}
          {...props}
          options={selectOptions}
          shouldShowPresetOptions
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onChangeDiscount(event);
            onChange?.(event);
          }}
          placeholder={formatMessage(
            'ar.modals.activities.discounts.formDialog.fields.name.dropdown.placeholder.label'
          )}
          creatableOption={{
            label: formatMessage('ar.modals.activities.discounts.formDialog.fields.name.dropdown.options.addNew.label'),
            onClick: (inputValue) => {
              onCreateDiscount(inputValue);
            },
          }}
          emptyState={{
            label: formatMessage('ar.modals.activities.discounts.formDialog.fields.name.dropdown.emptyState.label'),
          }}
        />
      );
    }
  )
);

DiscountSelectWidget.displayName = 'DiscountSelectWidget';
