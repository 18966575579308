import { EmptyState, FormattedMessage, TaxRate, useMelioIntl } from '@melio/ar-domain';
import { Button, Container, Group, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { TaxRatesTable, TaxRatesTableToolbar } from '../components';

export type TaxRatesTableScreenProps = {
  isLoading?: boolean;
  taxRates: TaxRate[];
  onEdit: (itemId: string) => unknown;
  onDelete: (itemId: string) => unknown;
  onAdd: VoidFunction;
};

export const TaxRatesTableScreen = forwardRef<TaxRatesTableScreenProps>(
  ({ taxRates, isLoading, onEdit, onDelete, onAdd, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const hasTaxRates = taxRates.length > 0;

    if (!isLoading && !hasTaxRates) {
      return (
        <Group variant="vertical" {...props} ref={ref}>
          <EmptyState
            illustrationType="no-items"
            content={
              <Container>
                <Text textStyle="body3" color="semantic.text.secondary">
                  <FormattedMessage id="ar.settings.activities.taxRatesTable.components.taxRatesTable.emptyState.text" />
                </Text>
              </Container>
            }
            footer={
              <Button
                variant="secondary"
                label={formatMessage(
                  'ar.settings.activities.taxRatesTable.components.taxRatesTableToolbar.addButton.label'
                )}
                onClick={onAdd}
                data-testid="empty-state-add-tax-button"
              />
            }
          />
        </Group>
      );
    }

    return (
      <Group variant="vertical" {...props} ref={ref}>
        <TaxRatesTableToolbar onAdd={onAdd} />
        <TaxRatesTable taxRates={taxRates} onEdit={onEdit} onDelete={onDelete} isLoading={isLoading} />
      </Group>
    );
  }
);
