/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@chakra-ui/react';
import { getAccountingPlatformNameForAnalytics, useAccountingPlatformPollingSync } from '@melio/ap-domain';
import { Group, Link, StatusIndicator, useBreakpoint } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { AccountingPlatformConnectionStatus, AccountingPlatformSlug, useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useNavigate } from '@melio/platform-utils';
import { useSystemMessage } from '@melio/platform-utils/system-message';
import { useCallback } from 'react';

import { SyncIcon } from './SyncIcon';
import { SyncNowWidgetProps } from './types';
import { formatSyncDate } from './utils';

export const SyncNowWidget = withAnalyticsContext<SyncNowWidgetProps>(
  ({ accountingPlatform, analyticsProperties, setAnalyticsProperties, ...props }) => {
    const navigate = useNavigate();
    const { formatMessage } = useMelioIntl();
    const { showMessage } = useSystemMessage();
    const { isExtraSmallScreen } = useBreakpoint();
    const { track, trackMarketing } = useAnalytics();
    const { accountingSlug, connectionStatus } = accountingPlatform;
    const { data: account } = useAccount({ id: 'me' });
    const isQuickBooksDesktopAccountingPlatform = accountingSlug === AccountingPlatformSlug.QuickBooksDesktop;
    const accountingPlatformNameForAnalytics = getAccountingPlatformNameForAnalytics(accountingSlug);

    setAnalyticsProperties(analyticsProperties);

    const getAccountingPlatformName = useCallback(() => {
      switch (accountingSlug) {
        case AccountingPlatformSlug.QuickBooksDesktop:
          return formatMessage('widgets.syncNow.accountingPlatformNames.quickBooksDesktop');
        case AccountingPlatformSlug.QuickBooksOnline:
          return formatMessage('widgets.syncNow.accountingPlatformNames.quickBooks');
        case AccountingPlatformSlug.Xero:
          return formatMessage('widgets.syncNow.accountingPlatformNames.xero');
        default:
          throw new Error('unkown accounting platform');
      }
    }, [accountingSlug]);

    const handleSyncDone = useCallback(
      ({
        isLastSyncTriggeredByUser,
        lastSyncDate,
        analyticsProps,
      }: {
        isLastSyncTriggeredByUser: boolean;
        lastSyncDate: Date;
        analyticsProps: Record<string, string | boolean>;
      }) => {
        track('SyncAccountingSoftware', 'Status', {
          Status: 'success',
          AccountingSoftwareType: accountingPlatformNameForAnalytics,
        });
        trackMarketing('active-accounting-software-sync', {
          Status: 'success',
        });
        track('Dev', 'SyncAccountingSoftware', {
          accountingSoftwareType: accountingPlatformNameForAnalytics,
          component: 'SyncNow',
          ...analyticsProps,
        });

        const isConnected = connectionStatus === AccountingPlatformConnectionStatus.Connected;

        isConnected &&
          isLastSyncTriggeredByUser &&
          showMessage({
            type: 'success',
            title: formatMessage('widgets.syncNow.success.toast', {
              accountingPlatformName: getAccountingPlatformName(),
              lastSyncDate: formatSyncDate(lastSyncDate),
            }),
          });
      },
      [track, trackMarketing, showMessage, formatMessage, getAccountingPlatformName, connectionStatus]
    );

    const handleSyncError = useCallback(() => {
      track('SyncAccountingSoftware', 'Status', {
        Status: 'failure',
      });
      showMessage({
        type: 'error',
        action: {
          text: formatMessage('widgets.syncNow.dashboard.error.toast.link'),
          type: 'link',
          onAction: () => {
            navigate('/settings/accounting-software');
          },
        },
        title: formatMessage('widgets.syncNow.dashboard.error.toast', {
          accountingPlatformName: getAccountingPlatformName(),
        }),
      });
    }, [track, showMessage, formatMessage, getAccountingPlatformName]);

    const handleCommunicateError = useCallback(() => {
      track('CommunicateAccountingSoftware', 'Status', {
        Status: 'failure',
        AccountingPlatform: getAccountingPlatformName(),
      });
      showMessage({
        type: 'error',
        title: formatMessage('widgets.syncNow.dashboard.error.quickBooksDesktop.toast', {
          link: (
            <Link
              color="inherit"
              href="#"
              size="medium"
              label={formatMessage('widgets.syncNow.dashboard.error.quickBooksDesktop.toast.link')}
              variant="standalone"
              data-testid="toast-link-to-settings"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate('/settings/accounting-software');
              }}
            />
          ),
        }),
      });
    }, [track, showMessage, formatMessage, getAccountingPlatformName]);

    const {
      triggerSync,
      isRunning: isSyncRunning,
      isError: isSyncError,
    } = useAccountingPlatformPollingSync({
      organizationId: account?.organizationId,
      accountingPlatformId: accountingPlatform.id,
      isQuickBooksDesktopAccountingPlatform,
      onSyncDone: handleSyncDone,
      onSyncError: handleSyncError,
      onCommunicateError: handleCommunicateError,
    });

    const handleSyncButtonClicked = useCallback(() => {
      track('SyncAccountingSoftware', 'Click', {
        Cta: 'sync',
      });
      void triggerSync();
    }, [track, triggerSync]);

    const syncIconProps = {
      isSyncRunning,
      accountingPlatform,
      isExtraSmallScreen,
      onClick: handleSyncButtonClicked,
    };

    const getStartSyncButton = () => {
      const { lastCompletionTime } = accountingPlatform;
      const tooltipLabel = lastCompletionTime
        ? formatMessage('widgets.syncNow.initial.tooltip.label', {
            lastSyncDate: formatSyncDate(lastCompletionTime),
          })
        : '';

      const tooltipTitle = formatMessage('widgets.syncNow.initial.tooltip.title', {
        accountingPlatformName: getAccountingPlatformName(),
      });

      return (
        <Box>
          <SyncIcon
            {...syncIconProps}
            ariaLabel={tooltipTitle}
            isError={false}
            tooltipTitle={tooltipTitle}
            tooltipLabel={tooltipLabel}
            tooltipTestId="sync-now-button-tooltip"
          />
        </Box>
      );
    };

    const getSyncErrorButton = () => {
      const tooltipTitle = formatMessage('widgets.syncNow.error.tooltip.title', {
        accountingPlatformName: getAccountingPlatformName(),
      });

      return (
        <StatusIndicator data-testid="sync-now-button-error-indicator" status="critical">
          <SyncIcon
            {...syncIconProps}
            ariaLabel={tooltipTitle}
            isError
            tooltipTitle={tooltipTitle}
            tooltipLabel={formatMessage('widgets.syncNow.error.tooltip.label')}
            tooltipTestId="sync-now-button-tooltip"
          />
        </StatusIndicator>
      );
    };

    const getSyncRunningButton = () => {
      const accountingPlatformName = getAccountingPlatformName();
      const tooltipLabel = formatMessage('widgets.syncNow.running.tooltip.label', {
        accountingPlatformName,
      });
      const runningIconAriaLabel = formatMessage('widgets.syncNow.running.icon.ariaLabel', {
        accountingPlatformName,
      });

      return (
        <Box data-testid="sync-now-button-wrapper">
          <SyncIcon
            {...syncIconProps}
            ariaLabel={runningIconAriaLabel}
            isError={false}
            tooltipLabel={tooltipLabel}
            tooltipTestId="sync-now-button-tooltip"
          />
        </Box>
      );
    };

    const getSyncButton = () => {
      if (isSyncError) {
        return getSyncErrorButton();
      } else if (isSyncRunning) {
        return getSyncRunningButton();
      } else {
        return getStartSyncButton();
      }
    };

    return (
      <Group data-component="SyncNowWidget" data-testid="sync-now-wrapper" alignItems="center" {...props}>
        {getSyncButton()}
      </Group>
    );
  }
);

SyncNowWidget.displayName = 'SyncNowWidget';
