import { Container, Divider, Group, IconButton } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';

import { useSubscriptionRouter } from '../../utils';

export const SubscriptionSelectFundingSourceHeader = () => {
  const { goToSubscriptionPlansSettings } = useSubscriptionRouter();
  const { track } = useAnalytics();

  const handleGoBack = () => {
    track('PaymentMethod', 'Click', {
      Cta: 'exit',
    });

    goToSubscriptionPlansSettings();
  };

  return (
    <>
      <Container paddingX="s" paddingY="s">
        <Group justifyContent="flex-end" width="full">
          <IconButton icon="close" variant="naked" size="medium" aria-label="Go back" onClick={handleGoBack} />
        </Group>
      </Container>
      <Divider />
    </>
  );
};
