import lookup from 'country-code-lookup';

export const getCountryCode = (countryCodeOrName: string) => {
  if (!countryCodeOrName) {
    return undefined;
  }

  if (countryCodeOrName.length === 2) {
    return lookup.byIso(countryCodeOrName)?.iso2;
  }

  return lookup.byCountry(countryCodeOrName)?.iso2;
};
