import { ButtonProps } from '@melio/penny';
import { EventProperties, useAnalytics } from '@melio/platform-analytics';
import { useAccountingPlatforms } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';

import { useActivitiesNavigate } from '../../utils';

type UsePromoteAccountingSoftwareButtonPropsProps = {
  analyticsProps?: EventProperties;
};

export const useImportFromQuickbooksButtonProps = ({
  analyticsProps = {},
}: UsePromoteAccountingSoftwareButtonPropsProps): null | ButtonProps => {
  const { formatMessage } = useMelioIntl();

  const [featureEnabled] = usePartnerFeature('PromoteAccountingSoftwareSync', false);
  const { hasAccountingPlatform } = useAccountingPlatforms();
  const { track } = useAnalytics();
  const { navigateToSettingsAccountingPlatform } = useActivitiesNavigate();

  if (!featureEnabled || hasAccountingPlatform) {
    return null;
  }

  const handleImportFromAccountingSoftware = () => {
    track('SyncAccountingSoftware', 'Click', {
      Cta: 'import-from-quickbooks',
      Intent: 'accounting-software-sync',
      ...analyticsProps,
    });
    navigateToSettingsAccountingPlatform();
  };

  return {
    variant: 'secondary',
    label: formatMessage('activities.promoteAccountingSoftwareSync.importFromQuickBooks.cta'),
    onClick: handleImportFromAccountingSoftware,
  };
};
