import { useMelioIntl } from '@melio/ar-domain';
import { Link } from '@melio/penny';
import { useConfig } from '@melio/platform-provider';

export const useGetPaymentErrorNotification = () => {
  const { settings } = useConfig();
  const { formatMessage } = useMelioIntl();

  const contactSupportLink = (
    <Link
      href={settings.helpCenterUrl}
      label={formatMessage('ar.guestPayment.notifications.createPaymentError.description.contactSupport')}
      newTab
    />
  );

  const getPaymentErrorNotification = (fundingSource: 'bank' | 'card') => {
    if (fundingSource === 'bank') {
      return {
        title: formatMessage('ar.guestPayment.notifications.createPaymentError.bank.title.text'),
        description: formatMessage('ar.guestPayment.notifications.createPaymentError.bank.description.text', {
          contactSupportLink,
        }),
      };
    }

    return {
      title: formatMessage('ar.guestPayment.notifications.createPaymentError.card.title.text'),
      description: formatMessage('ar.guestPayment.notifications.createPaymentError.card.description.text', {
        contactSupportLink,
      }),
    };
  };

  return getPaymentErrorNotification;
};
