import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import {
  PostScannedInvoicesBulkResponse,
  ScannedInvoice,
  ScannedInvoicesApiClient,
  ScannedInvoicesApiGetScannedInvoicesRequest,
} from '@melio/platform-api-axios-client';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
// eslint-disable-next-line no-restricted-imports
import { useIsMutating, useMutation, useQuery, useQueryClient } from 'react-query';

import { createScannedInvoices } from '../file';
import { DeleteScannedInvoicesFn, ListGetScannedInvoicesFn } from './types';
import { convertScannedInvoicesCentsToDollars, postScannedInvoicesBulkCreate } from './utils';

export type UseScannedInvoicesProps = UseCollectionApiProps<
  typeof ScannedInvoicesApiClient.getScannedInvoices,
  ScannedInvoice
> & {
  params?: ScannedInvoicesApiGetScannedInvoicesRequest;
};

export const useScannedInvoices = ({ params, ...rest }: UseScannedInvoicesProps = {}) => {
  const { melioAccount, search } = (params = { melioAccount: undefined, search: undefined });
  const [isNewFileUploadEnabled] = useDevFeature(FeatureFlags.NewFileUploadEnabled, false);

  const query = useCollectionApi<ListGetScannedInvoicesFn, never, never, DeleteScannedInvoicesFn, ScannedInvoice>({
    ...rest,
    queryKey: ['ScannedInvoicesApi', params],
    queryFn: () => ScannedInvoicesApiClient.getScannedInvoices(melioAccount, search),
    select: (data) => (data ? convertScannedInvoicesCentsToDollars(data) : data),
    deleteFn: ScannedInvoicesApiClient.deleteScannedInvoicesScannedInvoiceId,
  });

  const bulkCreate = async (files: File[]): Promise<PostScannedInvoicesBulkResponse> => {
    if (isNewFileUploadEnabled) {
      return createScannedInvoices(files);
    }
    return postScannedInvoicesBulkCreate(files);
  };

  // batch create handling
  const queryClient = useQueryClient();
  const batchQuery = 'ScannedInvoicesApiBatch';
  const batchQueryFiles = 'FilesScannedInvoicesApiBatch';

  const queryFiles = useQuery<{ results?: PostScannedInvoicesBulkResponse; files?: File[] } | undefined>(
    batchQueryFiles,
    () => undefined
  );

  const batchCreateMutation = useMutation(batchQuery, bulkCreate, {
    onSuccess: (results: PostScannedInvoicesBulkResponse, files: File[]) => {
      void queryClient.setQueryData(batchQueryFiles, { results, files });
    },
    onMutate: (files: File[]) => {
      const previousData: unknown = queryClient.getQueryData(batchQuery);

      void queryClient.setQueryData(batchQueryFiles, { files });

      return { previousData };
    },
  });

  const reset = () => queryClient.setQueryData(batchQueryFiles, undefined);

  return {
    ...query,
    reset,
    createMultiple: batchCreateMutation.mutateAsync,
    isBatchMutating: useIsMutating(batchQuery) > 0,
    multipleInvoices: queryFiles.data,
  };
};
