import { VendorSelectWidget } from '@melio/ap-widgets';
import { UseMelioFormResults } from '@melio/penny';
import { EventProperties } from '@melio/platform-analytics';
import { CreateVendorAnalyticsMetadataFlow } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { PaymentFlowFormFields, PaymentFlowOnChangeHandlers } from '../../../types';

type VendorInputProps = {
  form: UseMelioFormResults<PaymentFlowFormFields>;
  onChange: PaymentFlowOnChangeHandlers['onVendorChange'];
  isReadOnly: boolean;
  analyticsProperties?: EventProperties;
  createVendorFlow?: CreateVendorAnalyticsMetadataFlow;
  isSubmitted: boolean;
};

export const VendorInput = ({
  form,
  onChange,
  isReadOnly,
  analyticsProperties,
  createVendorFlow,
  isSubmitted,
}: VendorInputProps) => {
  const { formatMessage } = useMelioIntl();
  const { registerField } = form;

  return (
    <VendorSelectWidget
      {...registerField('vendorId')}
      placeholder={formatMessage('widgets.vendorSelect.placeholder')}
      labelProps={{ label: formatMessage('activities.paymentFlow.form.content.vendor.label') }}
      isReadOnly={isReadOnly}
      onChange={(event) => onChange(event.target.value)}
      eventContextName="Payment"
      analyticsProperties={analyticsProperties}
      createVendorFlow={createVendorFlow}
      isSubmitted={isSubmitted}
    />
  );
};
