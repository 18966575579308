import { FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Avatar, FileAttachment, Group, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type InvoiceDetailsHeaderProps = {
  onViewInvoice?: VoidFunction;
  companyName?: string;
  companyLogoUrl?: string;
  invoiceFileUrl?: string;
  invoiceTotalAmount?: number;
  invoiceDueDate?: Date;
};

export const InvoiceDetailsHeader = forwardRef<InvoiceDetailsHeaderProps>(
  ({ companyName = '', companyLogoUrl, invoiceFileUrl, invoiceDueDate, invoiceTotalAmount, onViewInvoice }, ref) => {
    const { formatDate, formatCurrency } = useMelioIntl();

    return (
      <Group justifyContent="space-between" ref={ref}>
        <Group variant="vertical">
          <Avatar data-testid="company-logo" name={companyName} size="large" src={companyLogoUrl} variant="square" />
          <Group variant="vertical" spacing="xs">
            <Group variant="vertical" spacing="none">
              <Text textStyle="heading3Semi" data-testid="company-name">
                {companyName}
              </Text>
              <Text textStyle="body3" color="global.neutral.800">
                <FormattedMessage id="ar.guestPayment.header.invoiceInvoiceOf.text" />
              </Text>
            </Group>
            <Group variant="vertical" spacing="none">
              <Text textStyle="heading1Semi" data-testid="invoice-amount">
                {invoiceTotalAmount && formatCurrency(invoiceTotalAmount)}
              </Text>
              <Text textStyle="body3" color="global.neutral.800" data-testid="invoice-due-date">
                <FormattedMessage
                  id="ar.guestPayment.header.invoiceDueDate.text"
                  values={{ dueDate: formatDate(invoiceDueDate) }}
                />
              </Text>
            </Group>
          </Group>
        </Group>
        {invoiceFileUrl && (
          <FileAttachment
            data-testid="invoice-file"
            isViewMode
            width={90}
            value={invoiceFileUrl}
            onViewModeClick={onViewInvoice}
          />
        )}
      </Group>
    );
  }
);

InvoiceDetailsHeader.displayName = 'InvoiceDetailsHeader';
