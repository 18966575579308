import { useMelioIntl } from '@melio/ar-domain';
import { Group } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { MAX_FILE_ATTACHMENTS } from '../../../types';
import { createDefaultFormAttachment, useInvoiceFormContext } from '../../../utils';
import { InvoiceFileAttachmentsForm } from './InvoiceFileAttachmentsForm';

export const InvoiceFileAttachmentsFormSection = forwardRef<{ isSaving: boolean }>(({ isSaving }, ref) => {
  const { filesFieldArray, getValues, setError, clearErrors } = useInvoiceFormContext();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  return (
    <Group variant="vertical" spacing="m" ref={ref}>
      {filesFieldArray.fields.map((field, index) => (
        <InvoiceFileAttachmentsForm
          isSaving={isSaving}
          key={field.id}
          index={index}
          onError={(type) => {
            const isSizeExceeded = type === 'sizeExceeded';
            const errorMessageKey = isSizeExceeded
              ? 'ar.invoiceLifecycle.activities.createInvoice.inputs.attachments.error.size.text'
              : 'ar.invoiceLifecycle.activities.createInvoice.inputs.attachments.error.general.text';

            const errorMessage = formatMessage(errorMessageKey);

            track('Invoice', 'Status', {
              StatusType: 'failure',
              ErrorType: errorMessage,
              Intent: 'add-attachment',
              Cta: 'file-attachments',
              AttachmentNumber: index + 1,
            });

            setError(`attachments.${index}`, {
              message: errorMessage,
            });
          }}
          onCreate={() => {
            clearErrors(`attachments.${index}`);

            const attachmentCount = filesFieldArray.fields.length;
            const canAddMoreAttachments = attachmentCount < MAX_FILE_ATTACHMENTS;

            if (canAddMoreAttachments) {
              filesFieldArray.append(createDefaultFormAttachment());
            }

            track('Invoice', 'Status', {
              StatusType: 'success',
              Intent: 'add-attachment',
              Cta: 'file-attachments',
              AttachmentNumber: index + 1,
            });
          }}
          onDelete={(isDeletedByUser) => {
            const { attachments } = getValues();
            const isLastFileEmpty = attachments?.[attachments.length - 1]?.fileName == '';
            const isEmptyFileDeleted = filesFieldArray.fields[index]?.fileName == '';
            const attachment = attachments?.[index];

            if (isDeletedByUser) {
              track('Invoice', 'Click', {
                Intent: 'delete-attachment',
                Cta: 'delete',
                AttachmentFileId: attachment?.id,
              });
            }

            filesFieldArray.remove(index);
            if (
              (filesFieldArray.fields.length === MAX_FILE_ATTACHMENTS && !isLastFileEmpty) ||
              filesFieldArray.fields.length === 1 ||
              isEmptyFileDeleted
            ) {
              filesFieldArray.append(createDefaultFormAttachment());
            }
          }}
        />
      ))}
    </Group>
  );
});
InvoiceFileAttachmentsFormSection.displayName = 'InvoiceAttachmentsFormSection';
