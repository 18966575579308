import { MelioConfig } from '@melio/platform-provider';
import { usePlacesAutocompleteServiceConfig } from '@melio/react-google-autocomplete/lib/usePlacesAutocompleteService';
import get from 'lodash/get';
import { object, string } from 'yup';

import { GooglePlaceAddressComponentType, GooglePlaceType, InternationalAddress } from './GoogleAddress.types';

const STREET_NUMBER_COMPONENT = 'street_number';
const STREET_COMPONENT = 'route';
const CITY_COMPONENT = 'locality';
const CITY_FALLBACK_COMPONENT = 'sublocality';
const STATE_COMPONENT = 'administrative_area_level_1';
const ZIP_CODE_COMPONENT = 'postal_code';
const COUNTRY_COMPONENT = 'country';

export const parseGoogleAddress = (googleAddress: GooglePlaceType): InternationalAddress => {
  const streetNumberComponent = googleAddress.address_components?.find((c) =>
    c.types.includes(STREET_NUMBER_COMPONENT)
  );
  const streetComponent = googleAddress.address_components?.find((c) => c.types.includes(STREET_COMPONENT));
  const cityComponent =
    googleAddress.address_components?.find((c) => c.types.includes(CITY_COMPONENT)) ||
    googleAddress.address_components?.find((c) => c.types.includes(CITY_FALLBACK_COMPONENT));
  const stateComponent = googleAddress.address_components?.find((c) => c.types.includes(STATE_COMPONENT));
  const zipComponent = googleAddress.address_components?.find((c) => c.types.includes(ZIP_CODE_COMPONENT));
  const countryComponent = googleAddress.address_components?.find((c) => c.types.includes(COUNTRY_COMPONENT));
  const streetNumber = streetNumberComponent ? get(streetNumberComponent, 'long_name', '') : '';
  const streetName = streetComponent ? get(streetComponent, 'long_name', '') : '';
  const formattedAddress = googleAddress.formatted_address || '';
  let addressLine1 = formattedAddress ? formattedAddress.split(',')[0] : '';

  if (streetNumberComponent && streetComponent) {
    addressLine1 = `${streetNumber} ${streetName}`;
  }
  const convertComponentToNameAndCode = (component?: GooglePlaceAddressComponentType) => ({
    name: component?.long_name || '',
    code: component?.short_name || '',
  });

  if (countryComponent?.short_name === 'PR') {
    countryComponent.short_name = 'US';
    countryComponent.long_name = 'United States';
    if (stateComponent) {
      stateComponent.short_name = 'PR';
      stateComponent.long_name = 'Puerto Rico';
    }
  }

  return {
    country: convertComponentToNameAndCode(countryComponent),
    state: convertComponentToNameAndCode(stateComponent),
    city: convertComponentToNameAndCode(cityComponent),
    postalCode: zipComponent ? get(zipComponent, 'long_name', '') : '',
    line1: addressLine1 || '',
    formattedAddress,
  };
};

export const internationalAddressSchema = object().shape({
  country: object().required(),
  state: object().required(),
  city: object().required(),
  postalCode: string(),
  line1: string().required(),
  formattedAddress: string().required(),
});

export function getPlacesAutocompleteServiceProps(
  config: MelioConfig,
  country?: string[]
): usePlacesAutocompleteServiceConfig {
  let props: usePlacesAutocompleteServiceConfig = {
    apiKey: config.services.googleMaps.apiKey,
  };
  if (country?.length) {
    props = {
      ...props,
      options: { componentRestrictions: { country } } as google.maps.places.AutocompletionRequest,
    };
  }
  return props;
}
