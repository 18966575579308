import { createProvider, FormattedCurrency, FormattedDate, Renderers } from '@melio/i18n-tools';
import { useCallback } from 'react';

import { ConversionUtils } from '../conversion-utils';
import messages from './messages.json';

const renderers: Renderers = {
  bold: (...chunks: unknown[]) => <strong>{chunks}</strong>,
  emphasize: (...chunks: unknown[]) => <em className="emphasize">{chunks}</em>,
  br: <br />,
};

export type MessageKey = keyof typeof messages;

const { LocalizationProvider, useMelioIntl: _useMelioIntl, FormattedMessage } = createProvider(messages, renderers);

const useMelioIntl = () => {
  const {
    formatCurrency: _formatCurrency,
    formatPercents: _formatPercents,
    formatDate: _formatDate,
    ...intl
  } = _useMelioIntl();

  const formatDate = useCallback<typeof _formatDate>(
    (value: string, options = { dateStyle: 'medium' }, ...args) => _formatDate(value, options, ...args),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const formatCurrency = useCallback(
    (value: number, currency?: string, options?: Parameters<typeof _formatCurrency>[2]) => {
      const targetValue = ConversionUtils.fromCentsToDollars(value);
      return _formatCurrency(targetValue, currency, options);
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  type FormatPercentsOptions = NonNullable<Parameters<typeof _formatPercents>[1]>;
  type FormatPercents = (value: number, options?: Omit<FormatPercentsOptions, 'divide'>) => string;

  const formatPercents = useCallback<FormatPercents>(
    (value, options = {}) => _formatPercents(value, { maximumFractionDigits: 3, ...options }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return { ...intl, formatCurrency, formatPercents, formatDate };
};

export { FormattedCurrency, FormattedDate, FormattedMessage, LocalizationProvider, useMelioIntl };
