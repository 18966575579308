import { _createFormFieldInput, createNumberMask, TextField, TextFieldProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useMemo, useState } from 'react';

import { ConversionUtils } from '../conversion-utils';
import { isValidValue } from './utils';

export type PercentageFieldInputProps = Omit<TextFieldProps, 'maskProps' | 'placeholder'> & {
  allowDecimal?: boolean;
  decimalLimit?: number;
};

export const convertToNumericString = (amount: string): string => amount.replace(/[^0-9.]/g, '');

export const PercentageFieldInput = _createFormFieldInput(
  forwardRef<PercentageFieldInputProps, 'input'>(
    ({ allowDecimal = true, decimalLimit = 2, onChange, value: _value, ...inputProps }, ref) => {
      const mask = createNumberMask({
        suffix: '%',
        prefix: '',
        allowDecimal,
        decimalLimit,
        integerLimit: 8,
      });

      const value = useMemo(() => {
        if (!isValidValue(_value)) {
          return '';
        } else if (_value.toString().endsWith('.')) {
          return ConversionUtils.fromDecimalToPercent(_value.toString().slice(0, -1)).toString() + '.';
        } else {
          return ConversionUtils.fromDecimalToPercent(_value);
        }
      }, [_value]);

      const [inputValue, setInputValue] = useState<string | number>(value);
      useEffect(() => setInputValue(value), [value]);

      return (
        <TextField
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(inputProps as any)}
          value={inputValue}
          maskProps={{ mask }}
          onChange={(event) => {
            setInputValue(event.target.value);
            let value = convertToNumericString(event.target.value);
            if (value) {
              value = ConversionUtils.fromPercentToDecimal(value).toString();
            }
            onChange?.({ ...event, target: { ...event.target, value: value === '' ? '' : (Number(value) as never) } });
          }}
          ref={ref}
        />
      );
    }
  )
);

PercentageFieldInput.displayName = 'PercentageFieldInput';
