import { useCatalogItem, useMelioIntl, useSystemMessage } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { pick } from 'lodash';

import { CatalogItemFormDialogScreen, CatalogItemFormFields } from './screens';

type EditCatalogItemDialogActivityProps = {
  itemId: string;
  onDone: (id: string) => unknown;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
};

export const EditCatalogItemDialogActivity = forwardRef<EditCatalogItemDialogActivityProps>(
  ({ onDone: onSuccess, onClose, onError, itemId, ...props }, ref) => {
    const { data, update, isMutating, isLoading } = useCatalogItem({ id: itemId, onError });

    const { formatMessage } = useMelioIntl();
    const { triggerMessage } = useSystemMessage();

    const onSubmit = (values: CatalogItemFormFields) =>
      update(values)
        .then((item) => {
          onSuccess(item.id);
          triggerMessage({
            type: 'success',
            title: formatMessage('ar.settings.activities.addEditCatalogItem.toast.onEdit.label', {
              name: item.name,
            }),
          });
        })
        .catch(onError);

    return (
      <CatalogItemFormDialogScreen
        data-testid="edit-catalog-item-dialog"
        isOpen
        variant="edit"
        defaultValues={pick(data, ['name', 'price', 'taxable'])}
        isLoading={isLoading}
        isSaving={isMutating}
        onClose={onClose}
        onSubmit={onSubmit}
        {...props}
        ref={ref}
      />
    );
  }
);
EditCatalogItemDialogActivity.displayName = 'EditCatalogItemActivity';
