import { Bill } from '@melio/platform-api';

export const isEbill = (bill: Bill | Partial<Bill>) => bill.origin === 'fiserv';

export const getEbillAmount = (bill: Bill) => {
  if (bill.eBillAmountDue != null) {
    return bill.eBillAmountDue;
  }
  if (bill.minimumAmount != null) {
    return bill.minimumAmount;
  }
  return bill.amount;
};

export const isContainsSensitiveInfo = (invoiceNumber?: string): boolean => {
  const sensitiveInfoRegex = /^((4\d{3})|(5[1-5]\d{2})|(6011)|(7\d{3}))-?\d{4}-?\d{4}-?\d{4}|3[4,7]\d{13}$/;
  return invoiceNumber ? sensitiveInfoRegex.test(invoiceNumber.toLowerCase()) : false;
};
