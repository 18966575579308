import { Form, Group, OnSubmissionStateChange, useMelioForm, useWatch } from '@melio/penny';
import { Bill } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import {
  defaultEBillPayOption,
  EbillOptionRenderer,
  EbillPayOption,
  EbillValueOptionRender,
  getBillPaymentOptions,
  useEditAmountFormOptions,
} from '../../../../edit-ebill-payment-option';
import { EditPaymentAmountFormFields, useEditAmountFormSchema } from '../useEditAmountFormSchema';
export type EditPaymentAmountFormProps = {
  defaultValues?: Partial<EditPaymentAmountFormFields>;
  onSubmit: (data: EditPaymentAmountFormFields) => void;
  onSubmissionStateChange: OnSubmissionStateChange<EditPaymentAmountFormFields>;
  bill: Bill;
  isSaving?: boolean;
};

export const EditPaymentAmountForm = ({
  onSubmit,
  onSubmissionStateChange,
  bill,
  isSaving,
}: EditPaymentAmountFormProps) => {
  const form = useMelioForm<EditPaymentAmountFormFields>({
    onSubmit,
    onSubmissionStateChange,
    isSaving,
    defaultValues: { amountToPay: bill.amount.toString(), paymentAmountOption: defaultEBillPayOption },
    schema: useEditAmountFormSchema(),
  });
  const { formatMessage } = useMelioIntl();
  const options = useEditAmountFormOptions({ bill });
  const amounts = getBillPaymentOptions(bill);

  const [paymentAmountOption] = useWatch({
    control: form.control,
    name: ['paymentAmountOption'],
  });

  return (
    <Group variant="vertical">
      <Form {...form.formProps}>
        <Group width="full" variant="vertical" spacing="s" data-testid="payment-flow-ebill-amount-dropdown-container">
          <Form.SelectNew
            {...form.registerField('paymentAmountOption')}
            labelProps={{
              label: formatMessage('activities.batchPayment.content.ebill.form.paymentAmountOption.label'),
            }}
            options={options}
            valueRenderer={EbillValueOptionRender}
            optionRenderer={EbillOptionRenderer}
            onChange={(event) => {
              const value = event.target.value as EbillPayOption;
              form.setValue('amountToPay', amounts[value].toString());
              form.setValue('paymentAmountOption', value);
            }}
            size="large"
            shouldHideClearButton
          />
          {paymentAmountOption === 'customAmount' ? (
            <Form.AmountField
              {...form.registerField('amountToPay')}
              labelProps={{ label: formatMessage('activities.batchPayment.content.ebill.form.amountToPay.label') }}
              integerLimit={10}
              decimalLimit={2}
              decimalScale={2}
              allowNegativeValue={false}
            />
          ) : null}
        </Group>
      </Form>
    </Group>
  );
};
