import { Link, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

type Props = {
  actionLabel: string;
};

export const TermsAndConditionsLabel = ({ actionLabel }: Props) => {
  const { formatMessage } = useMelioIntl();

  const {
    settings: { termAndConditionsLink: termAndConditionsLinkUrl },
  } = useConfig();
  const termsAndConditionsLink = formatMessage('app.mtl.labels.term.and.conditions.label', {
    actionLabel,
    termsLink: (
      <Link
        color="inherit"
        href={termAndConditionsLinkUrl}
        label={formatMessage('app.mtl.labels.term.and.conditions.text')}
        newTab
      />
    ),
  });
  return termAndConditionsLinkUrl ? (
    <Text textStyle="body3" color="global.neutral.700">
      {termsAndConditionsLink}
    </Text>
  ) : null;
};
