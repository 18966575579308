import { useGuestPayorPaymentRequestDetails } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { InvoiceDrawerScreen } from './screens';

export type InvoiceDrawerActivityProps = {
  onClose: VoidFunction;
  onError?: ARErrorFunction;
  paymentRequestLink: string;
  isOpened: boolean;
  onDownloadInvoice: (fileUrl?: string) => void;
};

export const InvoiceDrawerActivity = forwardRef<InvoiceDrawerActivityProps>(
  ({ isOpened, onDownloadInvoice, paymentRequestLink, onClose, onError, ...props }, ref) => {
    const { data: paymentRequestDetails, isLoading } = useGuestPayorPaymentRequestDetails({
      paymentRequestLink,
      onError,
    });

    return (
      <InvoiceDrawerScreen
        isOpen={isOpened}
        isLoading={isLoading}
        onCloseButtonClick={onClose}
        onClose={onClose}
        previewUrl={paymentRequestDetails?.invoice.fileInfo?.previewUrls[0]}
        invoiceNumber={paymentRequestDetails?.invoice.invoiceNumber}
        onDownloadInvoice={() => onDownloadInvoice(paymentRequestDetails?.invoice.fileInfo?.url)}
        ref={ref}
        {...props}
      />
    );
  }
);
InvoiceDrawerActivity.displayName = 'InvoiceDrawerScreen';
