import { OrganizationExpanded } from '@melio/platform-api/src/entities-v2/organizations/types';
import { usePermissions } from '@melio/platform-permissions';

export const useCanSwitch = () => {
  const { can } = usePermissions();
  const canSwitch = ({ permission }: { permission?: OrganizationExpanded['permissionLevel'] }) =>
    can({
      subject: 'auth:switchOrganization',
      action: 'create',
      subjectData: { permission },
    });
  return { canSwitch };
};
