import { Group, Popover, StatusIconSolid, Table, Text, Tooltip } from '@melio/penny';
import { DeliveryMethod, PaymentIntent } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { useGetDeliveryMethodWarning } from '../../PaymentIntentsTable.screen.utils';
import { useDeliveryMethodOptions } from '../DeliveryMethodSelectCell/DeliveryMethodSelectCell.utils';

type DeliveryMethodCellProps = {
  deliveryMethod: DeliveryMethod;
  paymentIntent: PaymentIntent;
  onOpenPaymentPurposeModal?: VoidFunction;
};

export const DeliveryMethodCell = forwardRef<DeliveryMethodCellProps, 'div'>(
  ({ deliveryMethod, paymentIntent, onOpenPaymentPurposeModal, ...props }, ref) => {
    const { getOptionToDisplayOnSelectLabel } = useDeliveryMethodOptions();

    const { warningInfo } = useGetDeliveryMethodWarning({
      selectedDeliveryMethodId: deliveryMethod.id,
      paymentIntent,
      onOpenPaymentPurposeModal,
    });
    const { shouldShowWarning, tooltipProps, popoverProps } = warningInfo;
    const iconVariant = tooltipProps ? 'informative' : 'warning';
    const icon = <StatusIconSolid size="small" variant={iconVariant} data-testid="delivery-method-cell-icon" />;
    const iconWithWrapper = tooltipProps ? (
      <Tooltip content={tooltipProps.content} shouldAddTriggerFocus>
        {icon}
      </Tooltip>
    ) : popoverProps ? (
      <Popover {...popoverProps}>{icon}</Popover>
    ) : (
      icon
    );

    return (
      <Table.Cell {...props} ref={ref}>
        <Group spacing="xs" alignItems="center" justifyContent="space-between" width="full">
          <Text color="inherit" textStyle="inline" shouldSupportEllipsis>
            {getOptionToDisplayOnSelectLabel(deliveryMethod)}
          </Text>
          {shouldShowWarning && iconWithWrapper}
        </Group>
      </Table.Cell>
    );
  }
);

DeliveryMethodCell.displayName = 'DeliveryMethodCell';
