import { useMelioQueryClient } from '@melio/api-client';
import { TaxRatesApiClient } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

export type UseTaxRateProps = UseModelProps<
  typeof TaxRatesApiClient.getTaxRateById,
  typeof TaxRatesApiClient.updateTaxRateById,
  typeof TaxRatesApiClient.postTaxRate
>;

export const useTaxRate = ({ onUpdate, ...props }: UseTaxRateProps = {}) => {
  const queryClient = useMelioQueryClient();
  return useModel({
    ...props,
    queryKey: 'TaxRatesApi',
    queryFn: TaxRatesApiClient.getTaxRateById,
    createFn: TaxRatesApiClient.postTaxRate,
    deleteFn: TaxRatesApiClient.deleteTaxRateById,
    updateFn: TaxRatesApiClient.updateTaxRateById,
    onUpdate: (...args) => {
      // isDefault might have changed on other tax rates. Invalidate all tax rates.
      void queryClient.invalidateQueries('TaxRatesApi');
      void onUpdate?.(...args);
    },
  });
};

export type TaxRateModel = ReturnType<typeof useTaxRate>;
