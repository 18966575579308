import { Box } from '@chakra-ui/react';
import { Group, Pill, Text } from '@melio/penny';
import {
  isJoinOrganizationRequestExpired,
  JoinOrganizationRequest,
  JoinOrganizationRequestStatus,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { CompanyAvatar } from '../../CompanyAvatar';
import { PaymentsOverview } from '../../PaymentsOverview';
import { CardLayout } from '../common/Card.layout';

type Props = {
  joinOrganizationRequest: JoinOrganizationRequest;
  onRequestCancel: VoidFunction;
  onRequestResend: VoidFunction;
};

export const JoinOrganizationRequestCard = ({ joinOrganizationRequest, onRequestCancel, onRequestResend }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { id, companyName, status } = joinOrganizationRequest;
  const isExpired = isJoinOrganizationRequestExpired(joinOrganizationRequest);

  const statusBadgeData = (() => {
    if (isExpired) {
      return {
        label: formatMessage('widgets.joinOrganizationRequestCard.badge.expired'),
        isExpired: true,
        testId: 'badge-expired',
      };
    }

    if (status === JoinOrganizationRequestStatus.Pending) {
      return {
        label: formatMessage('widgets.joinOrganizationRequestCard.badge.pending'),
        testId: 'badge-pending',
      };
    }

    return undefined;
  })();

  return (
    <CardLayout
      data-testid={`join-organization-request-card-${id}`}
      actions={[
        {
          label: formatMessage('widgets.joinOrganizationRequestCard.menu.resend'),
          onClick: onRequestResend,
          dataTestId: 'join-organization-request-resend',
        },
        {
          label: formatMessage('widgets.joinOrganizationRequestCard.menu.cancel'),
          onClick: onRequestCancel,
          variant: 'critical',
          dataTestId: 'join-organization-request-cancel',
        },
      ]}
      top={
        <>
          <CompanyAvatar size="medium" isDisabled={isExpired} bgColor="default" name={companyName} />
          <Group variant="vertical" spacing="xxs">
            <Group variant="horizontal" alignItems="center" spacing="xs">
              <Text
                textStyle="body2Semi"
                color={isExpired ? 'semantic.text.disabled' : 'semantic.text.primary'}
                shouldSupportEllipsis
              >
                {companyName}
              </Text>
              {statusBadgeData ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 'xs',
                    marginRight: 's',
                    flex: '1',
                    whiteSpace: 'nowrap',
                  }}
                  data-testid={statusBadgeData.testId}
                >
                  <Pill type="secondary" status="neutral" isDisabled={isExpired} label={statusBadgeData.label} />
                </Box>
              ) : null}
            </Group>
          </Group>
        </>
      }
      bottom={
        <PaymentsOverview
          paymentsOverview={{
            unpaid: {
              count: 0,
            },
            scheduled: {
              count: 0,
            },
            failed: {
              count: 0,
            },
            pending: {
              count: 0,
            },
            paid: {
              count: 0,
            },
          }}
          isDisabled={isExpired}
        />
      }
    />
  );
};
