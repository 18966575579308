import { Group, Icon, Text, Tooltip } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const CanceledRecurringPaymentStatusDescription = ({ testId }: { testId: string }) => {
  const { formatMessage } = useMelioIntl();
  return (
    <Group spacing="xxs">
      <Text textStyle="body3" color="global.neutral.800" data-testid={testId}>
        {formatMessage('activities.payDashboard.paymentsTab.table.cells.statusDescription.recurringCanceled')}
      </Text>
      <Tooltip
        shouldAddTriggerFocus
        content={formatMessage(
          'activities.payDashboard.paymentsTab.table.cells.statusDescription.recurringCanceledTooltip'
        )}
      >
        <Group spacing="xxs" alignItems="center">
          <Icon type="info" color="inherit" size="small" />
        </Group>
      </Tooltip>
    </Group>
  );
};
