import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { BankAccountFormModel } from '@melio/ap-widgets';
import { Button, Container, Form, Text, useFormSubmissionController, useMelioForm } from '@melio/penny';

import { usePlatformIntl } from '@/translations/Intl';

type ViewManualBankAccountProps = {
  defaultValues: BankAccountFormModel;
  onDone: () => void;
};

export const ViewManualBankAccount = ({ defaultValues, onDone }: ViewManualBankAccountProps) => {
  const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<BankAccountFormModel>();
  const { formProps, registerField } = useMelioForm<BankAccountFormModel>({
    onSubmit: onDone,
    onSubmissionStateChange,
    defaultValues,
  });
  const { formatMessage } = usePlatformIntl();

  return (
    <Flex direction={'column'} gap={'40px'} marginBottom="40px">
      <Text as="h2" textStyle="heading1Semi">
        {formatMessage('widgets.bankDetailsForm.title')}
      </Text>
      <Form data-component="BankDetailsForm" {...formProps} {...formProps}>
        <Form.TextField
          type="number"
          labelProps={{ label: formatMessage('widgets.bankDetailsForm.fields.routingNumber.label') }}
          {...registerField('routingNumber')}
          value={defaultValues.routingNumber}
          isDisabled={true}
          isRequired={true}
          role="textbox"
          data-private
        />
        <Form.SecuredTextField
          type="number"
          labelProps={{ label: formatMessage('widgets.bankDetailsForm.fields.accountNumber.label') }}
          {...registerField('accountNumber')}
          value={defaultValues.accountNumber}
          isRequired={true}
          role="textbox"
          aria-disabled
          data-private
        />
      </Form>
      <Box>
        <Button
          size={'large'}
          isFullWidth={false}
          {...submitButtonProps}
          label={formatMessage('widgets.bankDetailsForm.back')}
        />
      </Box>
      <Container textAlign="center">
        <Text textStyle="body4" color="global.neutral.800">
          {formatMessage('widgets.bankDetailsForm.details')}
        </Text>
      </Container>
    </Flex>
  );
};
