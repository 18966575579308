import { Box, useBreakpointValue } from '@chakra-ui/react';
import { StatusIconSolid, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { NotificationBanner } from './NotificationBanner';

export const ComplianceVerificationFailedBanner = () => {
  const isSmallScreen = useBreakpointValue({ xs: true, s: false, l: false });
  const { formatMessage } = useMelioIntl();

  return (
    <NotificationBanner
      variant="warning"
      data-testid="kyc-warning-banner-verification-failed"
      centered={false}
      rounded
      narrow
      content={
        <Box
          display="flex"
          gap="2"
          flexDirection="row"
          alignItems={isSmallScreen ? 'start' : 'center'}
          textAlign="start"
        >
          <StatusIconSolid variant="pending" size="medium" />
          <Text textStyle="body3" color="global.neutral.900">
            {formatMessage('widget.kyc.warning.banner.text.verificationFailed')}
          </Text>
        </Box>
      }
    />
  );
};
