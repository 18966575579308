import { FieldValues, UseMelioFormResults } from '@melio/penny';
import { isObject } from 'lodash';
import _isEmpty from 'lodash/isEmpty';
import { Path, PathValue } from 'react-hook-form';

const isDate = (value: unknown): value is Date => value instanceof Date;

export const isDirty = <T extends FieldValues>({
  fieldKey,
  form,
}: {
  form: UseMelioFormResults<T>;
  fieldKey: Path<T>;
}) => form.getFieldState(fieldKey, form.formState).isDirty;

export const isFieldReadonly = <T extends FieldValues>({
  fieldKey,
  form,
}: {
  form: UseMelioFormResults<T>;
  fieldKey: Path<T>;
}) => {
  const _isDirty = isDirty({ fieldKey, form });
  const formValue = form.getValues(fieldKey);
  return !isEmpty(formValue) && !_isDirty;
};

export const isEmpty = <T extends FieldValues>(value: PathValue<T, Path<T>>) => {
  if (isDate(value)) {
    return !value;
  }
  if (isObject(value)) {
    return Object.values(value).every((val) => _isEmpty(val));
  }
  return _isEmpty(value);
};
