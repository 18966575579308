import { useOnboarding } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { FinancialInformationScreen } from './screens';
import { FinancialInformationFormFields } from './types';

type FinancialInformationActivityProps = {
  onDone: VoidFunction;
  onError?: ARErrorFunction;
  onBack: VoidFunction;
  onClose?: VoidFunction;
};

export const FinancialInformationActivity = forwardRef(
  ({ onDone, onBack, onError, ...props }: FinancialInformationActivityProps, ref) => {
    const { isLoading, isMutating, update } = useOnboarding();
    const handleSubmit = async (data: FinancialInformationFormFields) => {
      const financialInformationRequest = {
        monthlySalesVolume: data.monthlySalesVolume,
        cardPaymentShare: data.cardPaymentShare,
        averageTransactionSize: data.averageTransactionSize,
      };
      await update({ businessDetails: financialInformationRequest })
        .then(() => {
          onDone();
        })
        .catch(onError);
    };

    return (
      <FinancialInformationScreen
        {...props}
        defaultValues={{
          monthlySalesVolume: undefined,
          averageTransactionSize: undefined,
          cardPaymentShare: undefined,
        }}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        isSaving={isMutating}
        onBack={onBack}
        ref={ref}
      />
    );
  }
);

FinancialInformationActivity.displayName = 'FinancialInformationActivity';
