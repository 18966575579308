import { TourtipSteps } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { createTestIdSelector, createTourIdSelector } from '../../../../../utils';
import { useVendorsTabData } from '../../VendorsTab/useVendorsTabData';
import { useSyncNowStep } from '../useSyncNowStep';

export const useVendorsTabTourSteps = ({ enabled }: { enabled: boolean }) => {
  const { formatMessage } = useMelioIntl();

  const {
    unpaidBills,
    isLoading,
    data: vendorsResult,
  } = useVendorsTabData({ searchTerm: null, enabled, sorting: null });
  const { data: vendors = [] } = vendorsResult ?? {};

  const vendorWithUnpaidBills = vendors.find((vendor) => unpaidBills.some((bill) => bill.vendorId === vendor.id));
  const vendorWithoutUnpaidBills = vendors.find((vendor) => vendor.id !== vendorWithUnpaidBills?.id);

  const { step: syncNowStep, isLoading: isLoadingSyncNowStep } = useSyncNowStep({ enabled });

  const steps: TourtipSteps = [
    {
      target: createTestIdSelector('pay-dashboard-activity-pay-dashboard-tabs-list-tab-vendors'),
      title: formatMessage('activities.payDashboard.tour.step.vendorsTab.title'),
      description: formatMessage('activities.payDashboard.tour.step.vendorsTab.content'),
    },
    ...(vendorWithoutUnpaidBills
      ? [
          {
            target: createTourIdSelector(`vendors-tab-action-cell-${vendorWithoutUnpaidBills.id}`),
            title: formatMessage('activities.payDashboard.tour.step.newPayment.title'),
            description: formatMessage('activities.payDashboard.tour.step.newPayment.content'),
          },
        ]
      : []),
    ...(vendorWithUnpaidBills
      ? [
          {
            target: createTourIdSelector(`vendors-tab-action-cell-${vendorWithUnpaidBills.id}`),
            title: formatMessage('activities.payDashboard.tour.step.openBalance.title'),
            description: formatMessage('activities.payDashboard.tour.step.openBalance.content'),
          },
        ]
      : []),
    {
      target: createTestIdSelector('pay-dashboard-activity-pay-dashboard-tabs-list-tab-bills'),
      title: formatMessage('activities.payDashboard.tour.step.billsTab.title'),
      description: formatMessage('activities.payDashboard.tour.step.billsTab.content'),
    },
    {
      target: createTestIdSelector('pay-dashboard-activity-pay-dashboard-tabs-list-tab-payments'),
      title: formatMessage('activities.payDashboard.tour.step.paymentsTab.title'),
      description: formatMessage('activities.payDashboard.tour.step.paymentsTab.content'),
    },
    { ...syncNowStep, nextButtonLabel: formatMessage('activities.payDashboard.bills-tour.step.done.label') },
  ];

  return {
    isLoading: isLoading || isLoadingSyncNowStep,
    steps,
  };
};
