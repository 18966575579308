import { usePaymentProcessingInfo } from '@melio/ap-domain';
import { getDeductionDate } from '@melio/ap-widgets';
import { Group, Icon, Text, Tooltip } from '@melio/penny';
import { PaymentIntent } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type DeductionDateCellProps = Pick<
  PaymentIntent,
  'scheduledDate' | 'effectiveScheduledDate' | 'selectedDeliveryPreferenceType'
> & {
  tooltipText?: string | null;
  helperTextLabel?: string | null;
};

export const DeductionDateCell: React.FC<DeductionDateCellProps> = ({
  scheduledDate,
  effectiveScheduledDate,
  selectedDeliveryPreferenceType,
  tooltipText,
  helperTextLabel,
}) => {
  const { formatDateTimeRange, formatDate } = useMelioIntl();
  const { calculateExpiryDate, isPaymentProcessedByCapitalOne } = usePaymentProcessingInfo();
  const deductionDate = getDeductionDate(scheduledDate, effectiveScheduledDate);

  const shouldDisplayDateRange =
    selectedDeliveryPreferenceType && isPaymentProcessedByCapitalOne(selectedDeliveryPreferenceType);

  if (!deductionDate) {
    return null;
  }

  const displayDate = shouldDisplayDateRange
    ? formatDateTimeRange(deductionDate, calculateExpiryDate(deductionDate), {
        dateStyle: 'medium',
      })
    : formatDate(deductionDate, { dateStyle: 'medium' });

  return (
    <Group spacing="xxs" variant="vertical">
      <Group alignItems="center">
        <Text textStyle="inline" color="inherit" shouldSupportEllipsis data-testid="deduction-date-cell-date">
          {displayDate}
        </Text>
        {tooltipText && (
          <Tooltip shouldAddTriggerFocus content={tooltipText}>
            <Icon type="info" size="small" data-testid="deduction-date-cell-tooltip-icon" />
          </Tooltip>
        )}
      </Group>
      {helperTextLabel && (
        <Text textStyle="body4" color="global.neutral.700" shouldSupportEllipsis>
          {helperTextLabel}
        </Text>
      )}
    </Group>
  );
};
