import { isFXCurrency } from '@melio/ap-domain';
import { Group, Table, Text } from '@melio/penny';
import { FxQuote, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { FxAmountTooltip } from '../utils';

export const PaymentAmountCell = ({ payment, fxQuote }: { payment: Payment; fxQuote?: FxQuote }) => {
  const { formatCurrency } = useMelioIntl();

  const isFxPayment = isFXCurrency(payment.currency);
  const formattedAmount = formatCurrency(
    isFxPayment && payment.foreignAmount ? payment.foreignAmount : payment.amount,
    payment.currency
  );

  return (
    <Table.Cell data-testid="pending-approval-payments-row-payment-amount-cell" textAlign="end">
      <Group variant="vertical" spacing="xxs" alignItems="flex-end">
        <Text textStyle="body3Semi">{formattedAmount}</Text>
        {fxQuote ? (
          <Group variant="horizontal" spacing="xxs" data-testid="pending-approval-row-payment-fx-amount">
            <Text textStyle="body4" color="global.neutral.700">
              {formatCurrency(fxQuote.amount)}
            </Text>
            <FxAmountTooltip fxQuote={fxQuote} />
          </Group>
        ) : null}
      </Group>
    </Table.Cell>
  );
};
