import { BankAccount, DeliveryMethod, DeliveryMethodType, Vendor } from '@melio/platform-api';

import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { BasicDeliveryMethodCardProps } from '@/types/deliveryMethod.types';
import { DeliveryMethodIconsEnum } from '@/types/vendors.types';
import { DeliveryMethodCard } from '@/widgets/vendorsDashboard/DeliveryMethod/components/DeliveryMethodCard.component';

export interface AchDeliveryMethodProps extends BasicDeliveryMethodCardProps {
  vendor: Vendor;
  ach: DeliveryMethod | null;
}

export const AchDeliveryMethodCard = ({ vendor, ach, navigationStateParams }: AchDeliveryMethodProps) => {
  const { formatMessage, formatDate } = usePlatformIntl();
  const { generateVendorBankAccountLink } = useRouter();

  const info1 = ach
    ? formatMessage('widgets.addOrUpdateDeliveryMethod.ach.info.info1', {
        companyName: vendor.name,
        bankAccount: (ach.details as BankAccount).accountNumber.slice(-4),
      })
    : undefined;
  const info2 = ach
    ? formatMessage('widgets.addOrUpdateDeliveryMethod.ach.info.info2', {
        updatedDate: formatDate(ach.history.updatedAt),
      })
    : undefined;

  return (
    <DeliveryMethodCard
      deliveryMethodExists={ach !== null}
      navigationParams={generateVendorBankAccountLink(navigationStateParams)}
      iconType={DeliveryMethodIconsEnum.BANK}
      displayName={formatMessage('widgets.addOrUpdateDeliveryMethod.ach.title')}
      helperText={formatMessage('widgets.addOrUpdateDeliveryMethod.ach.feeText')}
      info={info1 && info2 ? { info1, info2 } : undefined}
      caption={formatMessage('widgets.addOrUpdateDeliveryMethod.ach.comment')}
      type={DeliveryMethodType.BankAccount}
      addMethodAriaLabel={formatMessage('widgets.addOrUpdateDeliveryMethod.ach.add.ariaLabel')}
    />
  );
};
