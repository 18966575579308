import { Button, Popover } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

export const FinancingTabDisabledCreditDetailsPopover: React.FC<{
  showPopover?: boolean;
}> = ({ showPopover, children }) => {
  const { formatMessage } = useMelioIntl();
  const { contactMelioSupport } = useConfig().links;

  return showPopover ? (
    <Popover
      data-testid="disabled-financing-credit-details-tooltip"
      title={formatMessage('activities.payDashboard.financingTab.emptyState.creditDetails.errorPopover.title')}
      actionRenderer={({ onClose, ...props }) => (
        <Button
          {...props}
          size="small"
          variant="secondary-inverse"
          label={formatMessage(
            'activities.payDashboard.financingTab.emptyState.creditDetails.errorPopover.action.label'
          )}
          onClick={() => {
            onClose();
            window.open(contactMelioSupport, '_blank');
          }}
        />
      )}
      description={formatMessage(
        'activities.payDashboard.financingTab.emptyState.creditDetails.errorPopover.description'
      )}
      triggerEvent="hover"
    >
      {children}
    </Popover>
  ) : (
    <>{children}</>
  );
};
