import { Group, Modal, Text } from '@melio/penny';
import { useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { CopyOwnerEmailToClipboradButton } from '../CopyOwnerEmailToClipboradButton';

type ContactOwnerForUpgradeModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ContactOwnerForUpgradeModal = ({ isOpen, onClose }: ContactOwnerForUpgradeModalProps) => {
  const { formatMessage } = useMelioIntl();
  const { data: account, isLoading: isAccountLoading } = useAccount({ id: 'me' });

  const companyName = account?.company.name;
  const organizationOwner = account?.organizationOwner;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={formatMessage('activities.subscription.gracePeriodBanner.nonOwnerModal.title')}
      data-testid="subscription-contact-owner-for-upgrade-modal"
      primaryButton={{
        label: formatMessage('activities.subscription.gracePeriodBanner.nonOwnerModal.closeButton'),
        onClick: onClose,
        variant: 'primary',
      }}
      isLoading={isAccountLoading}
    >
      <Group variant="vertical" spacing="m">
        <Text>
          {formatMessage('activities.subscription.gracePeriodBanner.nonOwnerModal.description', {
            companyName,
          })}
        </Text>

        <Group variant="horizontal" spacing="xxs" alignItems="center">
          <CopyOwnerEmailToClipboradButton ownerEmail={organizationOwner?.email} />
          <Text color="global.neutral.800">{organizationOwner?.email}</Text>
        </Group>
      </Group>
    </Modal>
  );
};
