import {
  BillSubscriptionApprovalDecisionStatusEnum,
  Payment,
  PaymentStatusEnum,
  useFundingSources,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';

import { isEbill } from '../../functions';
import { getIsVoidAndRefundEnabled } from '../../functions/isVoidAndRefundEnabled';
import { getIsVoidAndResendEnabled } from '../../functions/isVoidAndResendEnabled';
import { useBillSubscriptionActions } from '../bill-subscription/useBillSubscriptionActions';
import { usePaymentProcessingInfo } from './paymentProcessingInfo.hook';
import { PaymentActions } from './types';
import { useFailedPaymentActions } from './useFailedPaymentActions';

const defaultPaymentActions: PaymentActions = {
  edit: false,
  delete: false,
  cancel: false,
  cancelBillSubscription: false,
  isEditBillSubscriptionAllowed: false,
  markAsPaid: false,
  markAsUnpaid: false,
  resolveFailedToCollect: false,
  resolveFailedToDeliver: false,
  resolveRefund: false,
  resolveVoidAndRefund: false,
  resolveVoidAndResend: false,
  approvalDecision: false,
};

const useScheduledPaymentActions = (payment?: Payment) => {
  const { can } = usePermissions();
  const {
    settings: {
      payment: {
        editing: { shouldCheckFundingSourceEntitlements },
      },
    },
  } = useConfig();
  const { data: fundingSources } = useFundingSources({ enabled: shouldCheckFundingSourceEntitlements });
  const [isCancelPaymentEnabled] = useDevFeature(FeatureFlags.CancelPayment, false);

  const { isCancelBillSubscriptionAllowed, isEditBillSubscriptionAllowed } = useBillSubscriptionActions(
    payment?.subscriptionOccurrence?.billSubscription,
    payment?.vendor
  );

  if (!payment) {
    return defaultPaymentActions;
  }

  const isEntitlementToFundingSource = shouldCheckFundingSourceEntitlements
    ? !!fundingSources?.find((fundingSource) => fundingSource.id === payment.fundingSourceId)
    : true;

  const isPendingApprovalRecurringPayment =
    payment.subscriptionOccurrence?.billSubscription?.approvalDecisionStatus ===
    BillSubscriptionApprovalDecisionStatusEnum.Pending;
  const canUpdatePayment =
    can({
      subject: 'payment',
      action: 'update',
      subjectData: {
        createdById: payment.createdById,
        fundingSourceId: payment.fundingSourceId,
        vendor: {
          createdById: payment.vendor?.createdById,
          managedBy: payment.vendor?.managedBy,
        },
        payment: {
          origin: payment.origin,
        },
      },
    }) &&
    isEntitlementToFundingSource &&
    !payment.isFinanced &&
    !!payment.paymentActions?.edit.eligible &&
    !isPendingApprovalRecurringPayment;

  const canDeleteBills = payment.bills?.length
    ? payment.bills.every((bill) =>
        can({
          subject: 'bill',
          action: 'delete',
          subjectData: {
            createdById: bill.createdById,
            vendor: {
              createdById: payment.vendor?.createdById,
              managedBy: payment.vendor?.managedBy,
            },
          },
        })
      )
    : true;

  const canDeletePayment =
    can({
      subject: 'payment',
      action: 'delete',
      subjectData: {
        createdById: payment.createdById,
        fundingSourceId: payment.fundingSourceId,
        vendor: {
          createdById: payment.vendor?.createdById,
          managedBy: payment.vendor?.managedBy,
        },
        payment: {
          origin: payment.origin,
        },
      },
    }) &&
    canDeleteBills &&
    !!payment.paymentActions?.delete.eligible;

  const canCancelPayment =
    isCancelPaymentEnabled &&
    !!payment.paymentActions?.cancel.eligible &&
    can({
      subject: 'payment:cancel',
      action: 'update',
      subjectData: {
        createdById: payment.createdById,
        fundingSourceId: payment.fundingSourceId,
        vendor: {
          createdById: payment.vendor?.createdById,
          managedBy: payment.vendor?.managedBy,
        },
        payment: {
          origin: payment.origin,
        },
      },
    });

  const canCancelBillSubscription = isCancelBillSubscriptionAllowed && (canDeletePayment || canCancelPayment);

  return {
    edit: canUpdatePayment,
    delete: canDeletePayment && !isPendingApprovalRecurringPayment,
    cancelBillSubscription: !!payment.subscriptionOccurrenceId && canCancelBillSubscription,
    isEditBillSubscriptionAllowed,
    approvalDecision: !!payment.paymentActions?.approvalDecision.eligible,
    cancel: canCancelPayment,
  };
};

const useInProgressPaymentActions = (payment?: Payment) => {
  const { isPaymentProcessedByCapitalOne } = usePaymentProcessingInfo();
  const { can } = usePermissions();
  if (!payment) {
    return {};
  }

  const canDeletePayment = can({
    subject: 'payment',
    action: 'delete',
    subjectData: { createdById: payment.createdById },
  });

  const paymentIsCancellable = payment.paymentActions?.cancel?.eligible;
  const canDeletePaymentFinal = !!(
    canDeletePayment &&
    isPaymentProcessedByCapitalOne(payment.deliveryMethod?.type) &&
    paymentIsCancellable
  );

  const isVoidAndRefundEnabled = getIsVoidAndRefundEnabled(payment);
  const isVoidAndResendEnabled = getIsVoidAndResendEnabled(payment);

  return {
    resolveVoidAndRefund: isVoidAndRefundEnabled,
    resolveVoidAndResend: isVoidAndResendEnabled,
    delete: canDeletePaymentFinal,
  };
};

const useCompletedPaymentActions = (payment?: Payment) => {
  const [isMarkAsPaidEnabled] = usePartnerFeature('MarkAsPaid', false);
  const { can } = usePermissions();
  const isVoidAndRefundEnabled = getIsVoidAndRefundEnabled(payment);
  const { isCompletedPaymentStillProcessing } = usePaymentProcessingInfo();
  const isVoidAndResendEnabled = getIsVoidAndResendEnabled(payment);

  if (!payment) {
    return {
      resolveVoidAndRefund: false,
      resolveVoidAndResend: false,
      markAsUnpaid: false,
    };
  }

  const canUpdateBills = payment.bills?.length
    ? payment.bills.every((bill) =>
        can({
          subject: 'bill',
          action: 'update',
          subjectData: {
            createdById: bill.createdById,
            vendor: {
              createdById: payment.vendor?.createdById,
              managedBy: payment.vendor?.managedBy,
            },
          },
        })
      )
    : true;

  const canMarkAsUnpaid =
    !!payment.bills?.every((bill) => !isEbill(bill)) &&
    isMarkAsPaidEnabled &&
    payment.markedAsPaid &&
    canUpdateBills &&
    can({
      subject: 'payment:markAsPaid',
      action: 'update',
      subjectData: { createdById: payment.createdById },
    });

  const canCancelPayment = can({
    subject: 'payment',
    action: 'delete',
    subjectData: { createdById: payment.createdById },
  });

  const paymentIsCancellable = payment.paymentActions?.cancel?.eligible;
  const isCompletedPaymentStillProcessingFlag =
    payment.status === PaymentStatusEnum.Completed && isCompletedPaymentStillProcessing(payment);

  const canCancelPaymentFinal = !!(canCancelPayment && isCompletedPaymentStillProcessingFlag && paymentIsCancellable);

  return {
    resolveVoidAndRefund: isVoidAndRefundEnabled,
    resolveVoidAndResend: isVoidAndResendEnabled,
    markAsUnpaid: canMarkAsUnpaid,
    delete: canCancelPaymentFinal,
  };
};

export const usePaymentActions = (
  payment?: Payment
): {
  type: PaymentStatusEnum | null;
  actions: PaymentActions;
} => {
  const scheduledPaymentAllowedActions = useScheduledPaymentActions(payment);
  const completedPaymentAllowedActions = useCompletedPaymentActions(payment);
  const inProgressPaymentAllowedActions = useInProgressPaymentActions(payment);
  const failedPaymentAllowedActions = useFailedPaymentActions(payment);

  if (!payment) {
    return { type: null, actions: defaultPaymentActions };
  }

  switch (payment.status) {
    case PaymentStatusEnum.Blocked:
    case PaymentStatusEnum.Scheduled:
      return { actions: { ...defaultPaymentActions, ...scheduledPaymentAllowedActions }, type: 'scheduled' as const };
    case PaymentStatusEnum.Completed:
      return { actions: { ...defaultPaymentActions, ...completedPaymentAllowedActions }, type: 'completed' as const };
    case PaymentStatusEnum.Failed:
      return { actions: { ...defaultPaymentActions, ...failedPaymentAllowedActions }, type: 'failed' as const };
    case PaymentStatusEnum.InProgress:
      return {
        actions: { ...defaultPaymentActions, ...inProgressPaymentAllowedActions },
        type: 'in-progress' as const,
      };
    default:
      return { type: payment.status, actions: defaultPaymentActions };
  }
};
