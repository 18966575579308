import { useJoinOrganizationRequests } from '@melio/platform-api';
import { useSystemMessage } from '@melio/platform-utils';

import { CompanyDetailsFormWidgetFields } from '../../CompanyDetails/widgets';
import { buildCreateJoinOrganizationRequestParams } from '../OwnerDetails.screen.utils';
import { OwnerDetailsFormWidgetFields } from '../widgets';

type Props = {
  onJoinOrganizationRequestCreateSuccess: VoidFunction;
  onJoinOrganizationRequestCreateError?: (error: PlatformError) => void;
};

export const useCreateJoinOrganizationRequest = ({
  onJoinOrganizationRequestCreateSuccess,
  onJoinOrganizationRequestCreateError,
}: Props) => {
  const { create: createJoinOrganizaitonRequest, isMutating } = useJoinOrganizationRequests();
  const { showMessage } = useSystemMessage();

  const handleCreateJoinOrganizationRequestFailure = (error: PlatformError) => {
    showMessage({ type: 'error', title: error.message });
    onJoinOrganizationRequestCreateError?.(error);
  };

  const handleCreateJoinOrganizationRequest = (
    enteredData: CompanyDetailsFormWidgetFields & OwnerDetailsFormWidgetFields
  ) => {
    const createJoinOrganizationRequestParams = buildCreateJoinOrganizationRequestParams({
      ...enteredData,
      address: {
        ...enteredData?.address,
        ...(enteredData?.aptNumber ? { aptNumber: enteredData?.aptNumber } : {}),
      },
    });

    createJoinOrganizaitonRequest(createJoinOrganizationRequestParams)
      .then(onJoinOrganizationRequestCreateSuccess)
      .catch(handleCreateJoinOrganizationRequestFailure);
  };

  return {
    handleCreateJoinOrganizationRequest,
    isLoading: isMutating,
  };
};
