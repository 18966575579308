import { Container, ContainerProps, Group, Pill, Text, useBreakpointValue } from '@melio/penny';
import { useMelioIntl, usePartnerFeature } from '@melio/platform-provider';
import { useIsSubscriptionsEnabled } from '@melio/subscriptions';

type Props = {
  title: string;
  testId?: string;
};

export const LineItemsHeader = ({ title, testId }: Props) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const headerPaddingTop = useBreakpointValue<ContainerProps['paddingTop']>({ xs: 's', m: 'm' });
  const [showPremiumPill] = usePartnerFeature('ShowLineItemsPremiumPill', false);
  const { formatMessage } = useMelioIntl();

  return (
    <Container paddingTop={headerPaddingTop} paddingBottom="xs" data-testid="line-items-header">
      <Group spacing="s" variant="horizontal" alignItems="center">
        <Text as="h2" textStyle="heading3Semi" data-testid={testId}>
          {title}
        </Text>
        {isSubscriptionsEnabled && showPremiumPill ? (
          <Pill
            status="brand"
            label={formatMessage('activities.addBillV2.lineItems.lineItemsHeader.pill.label')}
            type="secondary"
          />
        ) : null}
      </Group>
    </Container>
  );
};
