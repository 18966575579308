import { useMelioIntl } from '@melio/platform-i18n';

import { DeliveryMethodDetailsDiff } from '../types';

export const useHeaderTexts = (diffs: DeliveryMethodDetailsDiff[]): string[] => {
  const { formatMessage } = useMelioIntl();

  return diffs.map((diff) => {
    const { deliveryMethod } = diff;
    const vendorName = diff.vendor?.name;

    if (deliveryMethod.old.details.deliveryType === 'check' && deliveryMethod.new.details.deliveryType === 'ach') {
      return formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.checkToAch', {
        vendorName,
      });
    }

    if (deliveryMethod.old.details.deliveryType === 'ach' && deliveryMethod.new.details.deliveryType === 'check') {
      return formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.achToCheck', {
        vendorName,
      });
    }

    return '';
  });
};
