import { isEbill } from '@melio/ap-domain';
import { Group, Icon, Table, Text, Tooltip } from '@melio/penny';
import { Bill, BillingFeeSetting, PaymentIntent } from '@melio/platform-api';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import { forwardRef, sumBy } from '@melio/platform-utils';

import { EbillPayOption } from '../../../../../edit-ebill-payment-option';
import { getTotalFee, useGetEbillAmountOptionText, usePaymentFees } from './AmountCell.utils';

export type AmountCellProps = {
  paymentIntent: PaymentIntent;
  bills: Bill[];
  orgBillingFeeSettings: BillingFeeSetting[];
  paymentIntentToEbillOption: Record<string, EbillPayOption>;
};

export const AmountCell = forwardRef<AmountCellProps, 'div'>(
  ({ paymentIntent, orgBillingFeeSettings, bills, paymentIntentToEbillOption, ...props }, ref) => {
    const {
      settings: { batchPayments },
    } = useConfig();
    const amountToPay = sumBy(bills, (bill) => bill.balance);
    const { formatCurrency } = useMelioIntl();
    const fee = getTotalFee(paymentIntent.estimatedFees);
    const shouldShowFees = fee > 0 || batchPayments.showZeroFees;

    const { getFeesTooltip } = usePaymentFees(paymentIntent.estimatedFees, orgBillingFeeSettings);
    const tooltip = getFeesTooltip();

    const shouldRenderEbill = bills[0] && isEbill(bills[0]);
    const { label: ebillLabel, tooltip: ebillTooltip } = useGetEbillAmountOptionText(
      paymentIntentToEbillOption[paymentIntent.id]
    );

    return (
      <Table.Cell textAlign="end" {...props} ref={ref} cellPadding="1">
        <Group justifyContent="center" alignItems="flex-end" variant="vertical" spacing="xxs">
          <Text data-testid="payment-intent-table-amount-cell-amount" textStyle="body3Semi">
            {formatCurrency(amountToPay)}
          </Text>
          {shouldRenderEbill && (
            <Group justifyContent="center" spacing="xxs" alignItems="center" variant="horizontal">
              <Text
                data-testid="payment-intent-table-amount-cell-ebill-option-label"
                color="semantic.text.secondary"
                shouldSupportEllipsis
                textStyle="body4"
              >
                {ebillLabel}
              </Text>
              <Tooltip
                data-testid="payment-intent-table-amount-cell-ebill-option-tooltip"
                content={ebillTooltip}
                shouldAddTriggerFocus
              >
                <Icon
                  data-testid="payment-intent-table-amount-cell-ebill-option-tooltip-indicator"
                  size="small"
                  type="info"
                />
              </Tooltip>
            </Group>
          )}
          {shouldShowFees && (
            <Group justifyContent="center" spacing="xxs" alignItems="center" variant="horizontal">
              <Text
                data-testid="payment-intent-table-amount-cell-fee"
                color="semantic.text.secondary"
                textStyle="body4"
              >
                +{formatCurrency(fee)}
              </Text>
              {tooltip && (
                <Tooltip
                  data-testid="payment-intent-table-amount-cell-fee-tooltip"
                  content={tooltip?.content}
                  shouldAddTriggerFocus
                >
                  <Icon data-testid="payment-intent-table-amount-cell-fee-tooltip-indicator" size="small" type="info" />
                </Tooltip>
              )}
            </Group>
          )}
        </Group>
      </Table.Cell>
    );
  }
);

AmountCell.displayName = 'AmountCell';
