import { useMelioIntl } from '@melio/ar-domain';
import { Button, Container } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type TaxRatesTableToolbarProps = {
  onAdd: VoidFunction;
};

export const TaxRatesTableToolbar = forwardRef<TaxRatesTableToolbarProps>(({ onAdd }, ref) => {
  const { formatMessage } = useMelioIntl();

  return (
    // we need a container with padding for the inputs to not break on focus state that grows the inputs
    <Container
      ref={ref}
      paddingX="xxs"
      justifyContent="flex-end"
      paddingY="xxs"
      data-component={TaxRatesTableToolbar.displayName}
    >
      <Button
        variant="tertiary"
        label={formatMessage('ar.settings.activities.taxRatesTable.components.taxRatesTableToolbar.addButton.label')}
        onClick={() => onAdd()}
        data-testid="add-tax-button"
      />
    </Container>
  );
});
TaxRatesTableToolbar.displayName = 'TaxRatesTableToolbar';
