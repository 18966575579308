import { useAccount } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useState } from 'react';

import { useSubscriptionContext } from '../../../context';
import { BannerDetails } from './BannerDetails';

const SESSION_STORAGE_GRACE_BANNER_CLOSED = 'grace_period_banner_closed';
export const getSessionStorageKey = (organizationId: string) =>
  `${SESSION_STORAGE_GRACE_BANNER_CLOSED}-${organizationId}`;

export const SubscriptionGracePeriodBanner = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { data: dataAccountMe } = useAccount({ id: 'me' });
  const [hideGraceBannerInClient] = useDevFeature(FeatureFlags.HideGraceBannerInAccountingFirmClient, false);

  const storageKey = getSessionStorageKey(dataAccountMe?.organizationId || '');
  const isBannerManuallyClosed = sessionStorage.getItem(storageKey);

  const {
    gracePeriod: { isEligibleForGracePeriod, isTodayInGracePeriod },
  } = useSubscriptionContext();

  const canBeOpened =
    !isBannerManuallyClosed &&
    isEligibleForGracePeriod &&
    isTodayInGracePeriod &&
    !(dataAccountMe?.isAccountingClient && (hideGraceBannerInClient || dataAccountMe.isAccountingFirmMember));

  const handleOnClose = () => {
    sessionStorage.setItem(storageKey, 'closed');
    setIsOpen(false);
  };

  if (!isOpen || !canBeOpened) {
    return null;
  }

  return <BannerDetails onClose={handleOnClose} />;
};
