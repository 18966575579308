import { useAnalytics } from '@melio/platform-analytics';
import {
  AccountingPlatformParseAuthParamsResponseDataCompanies,
  useAccountingPlatformVerifyQuickBooksDesktop,
  VerifyQuickBooksDesktopConnectionStatus,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

import { SyncFlowEnum } from '../types';

type Props = {
  flow?: SyncFlowEnum;
  organizationId?: string;
  accountingPlatformId?: string;
  onSuccess: VoidFunction;
};

export const useVerifyQuickBooksDesktop = ({ flow, organizationId, accountingPlatformId, onSuccess }: Props) => {
  const { showMessage } = useSystemMessage();
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const {
    isMutating,
    verifyQuickBooksDesktop: verifyQuickBooksDesktopAction,
    verifyQuickBooksDesktopData,
  } = useAccountingPlatformVerifyQuickBooksDesktop({
    id: accountingPlatformId,
    enabled: false,
  });
  const isUnavailable =
    !isEmpty(verifyQuickBooksDesktopData) &&
    verifyQuickBooksDesktopData?.connectionStatus !== VerifyQuickBooksDesktopConnectionStatus.Available;
  const isAvailable =
    !isEmpty(verifyQuickBooksDesktopData) &&
    verifyQuickBooksDesktopData?.connectionStatus === VerifyQuickBooksDesktopConnectionStatus.Available;
  const registrableQuickBooksDesktopOrganization: AccountingPlatformParseAuthParamsResponseDataCompanies = {
    externalId: verifyQuickBooksDesktopData?.externalOrganizationId,
    companyName: verifyQuickBooksDesktopData?.qbdtCompanyName || undefined,
    isRegistrable: true,
  };
  const defaultAnalyticsFields = {
    PageName: `connecting-to-qbdt`,
    Flow: `connecting-qbdt`,
    Intent: `sync`,
    EntryPoint: flow === SyncFlowEnum.Setting ? 'settings-page' : 'dashboard',
    AccountingSoftwareType: 'qbdt',
  };

  useEffect(() => {
    if (isAvailable) {
      onSuccess();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvailable]);

  const verifyQuickBooksDesktop = async () => {
    try {
      const result = await verifyQuickBooksDesktopAction(organizationId || '');

      if (result?.connectionStatus === VerifyQuickBooksDesktopConnectionStatus.Available) {
        track('SyncAccountingSoftware', 'Status', {
          ...defaultAnalyticsFields,
          Status: 'success',
          ErrorType: null,
        });
        showMessage({
          type: 'success',
          dataTestId: 'verify-success',
          title: formatMessage(
            'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.verifySuccess'
          ),
        });
      }
    } catch (e) {
      track('SyncAccountingSoftware', 'Status', {
        ...defaultAnalyticsFields,
        Status: 'failure',
        ErrorType: 'verify-error',
      });
      showMessage({
        type: 'error',
        dataTestId: 'verify-error',
        title: formatMessage(
          'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.verifyError'
        ),
      });
      throw e;
    }
  };

  return {
    isMutating,
    isUnavailable,
    verifyQuickBooksDesktop,
    verifyQuickBooksDesktopData,
    registrableQuickBooksDesktopOrganization,
  };
};
