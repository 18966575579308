import { Box, IconProps } from '@chakra-ui/react';
import { Text, useBreakpointValue } from '@melio/penny';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { IconLabelTheme } from './IconLabel.component.theme';

type Props = {
  Icon: (props: IconProps) => JSX.Element;
  label: string;
  variant?: 'active' | 'default';
};

export const IconLabel = ({ Icon, label, variant = 'default' }: Props) => {
  const [splitContactsTab] = useDevFeature(FeatureFlags.SplitContactsTab, true);
  const styles = useAppTheme(IconLabelTheme, { variant, splitContactsTab });
  const textStyle = useBreakpointValue({ xs: 'body3Semi', s: splitContactsTab ? 'caption2Semi' : 'caption1Semi' });

  return (
    <Box sx={styles['container']}>
      <Icon sx={styles['icon']} />
      <Text color={variant === 'active' ? 'global.brand.700' : undefined} textStyle={textStyle}>
        {label}
      </Text>
    </Box>
  );
};
