import { UploadFilesWidget } from '@melio/ap-widgets';
import { ActionsDropdownMenu, Icon, IconButton } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { compact, noop } from 'lodash';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useActivitiesNavigate } from '../../../../utils';
import { EbillsImportModal, useShouldShowEbillsSyncOption } from '../../../ebills-import';
import { useDynamicEbillsImportCopy } from '../../../ebills-import/EbillsImport.activity';
import { usePayDashboardTabs } from '../../hooks/usePayDashboardTabs';
import { useShouldShowConnectAccountingSoftwareOption } from '../../hooks/useShouldShowConnectAccountingSoftwareOption';
import { BillsTabProps } from '../BillsTab/BillsTab';

export type MobileActionsMenuNBEProps = {
  onAddNewBillManual: BillsTabProps['onAddNewBillManual'];
  onAddNewBillUpload: BillsTabProps['onAddNewBillUpload'];
  onAccountingPlatformConnect: BillsTabProps['onAccountingPlatformConnect'];
  onAddNewPayment: () => void;
};

export const MobileActionsMenuNBE = withAnalyticsContext<MobileActionsMenuNBEProps>(
  ({
    onAddNewPayment,
    onAddNewBillUpload = noop,
    onAddNewBillManual = noop,
    onAccountingPlatformConnect = noop,
    setAnalyticsProperties,
  }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { pathname } = useLocation();
    const { track } = useAnalytics();
    const { selectedTab } = usePayDashboardTabs();
    const { formatMessage } = useMelioIntl();
    const { navigateToNewVendor } = useActivitiesNavigate();

    const [isAddBillOpen, setIsAddBillOpen] = useState(false);
    const [isEbillsImportModalOpen, toggleEbillsImportModal] = useState(false);
    const fileInput = useRef<HTMLInputElement>(null);

    const shouldShowSyncAccountingSoftware = useShouldShowConnectAccountingSoftwareOption();
    const shouldShowEbillsSyncOption = useShouldShowEbillsSyncOption();
    const { importCta } = useDynamicEbillsImportCopy();

    setAnalyticsProperties({
      PageName: 'add-item',
      Flow: 'dashboard',
      Intent: 'add-item',
      EntryPoint: selectedTab ? `dashboard-${selectedTab}` : 'dashboard-unkown',
    });

    const handleNewPaymentClick = () => {
      track('Dashboard', 'Click', {
        Cta: 'plus-sign-new-payment',
      });
      onAddNewPayment();
    };

    const handleOpenAddBill = () => {
      setIsAddBillOpen(true);
      setIsMenuOpen(true);

      track('Dashboard', 'Click', {
        Cta: 'plus-sign-add-a-bill',
      });
    };

    const handleAddVendorClick = () => {
      track('Dashboard', 'Click', {
        Cta: 'plus-sign-add-vendor',
      });
      navigateToNewVendor({ returnUrl: pathname });
    };

    const handleUploadBillClick = () => {
      track('Dashboard', 'Click', {
        Cta: 'plus-sign-scan-or-upload',
      });

      if (fileInput.current) {
        fileInput.current.click();
      }
      setIsAddBillOpen(true);
      setIsMenuOpen(true);
    };

    const handleAddNewBillManual = () => {
      track('Dashboard', 'Click', {
        Cta: 'plus-sign-create-a-bill',
      });

      onAddNewBillManual({ returnUrl: pathname });
    };

    const handleUploadABill = (files: File[]) => {
      onAddNewBillUpload({ files, returnUrl: pathname });
    };

    const handleAccountingPlatformConnect = () => {
      track('Dashboard', 'Click', {
        Cta: 'plus-sign-sync',
      });

      onAccountingPlatformConnect();
    };

    const addBillItems = compact([
      {
        label: formatMessage('activities.payDashboard.mobileActionsMenu.enterBillManually'),
        onClick: handleAddNewBillManual,
        icon: 'add' as const,
        dataTestId: 'pay-dashboard-mobile-actions-menu-add-bill-manually',
      },
      {
        label: formatMessage('activities.payDashboard.mobileActionsMenu.uploadBills'),
        onClick: handleUploadBillClick,
        icon: 'upload' as const,
        dataTestId: 'pay-dashboard-mobile-actions-menu-add-bill-upload',
      },
      shouldShowSyncAccountingSoftware && {
        label: formatMessage('activities.payDashboard.mobileActionsMenu.syncAccountingSoftware'),
        onClick: handleAccountingPlatformConnect,
        icon: 'refresh' as const,
        dataTestId: 'pay-dashboard-mobile-actions-menu-add-bill-sync-accounting-software',
      },
      shouldShowEbillsSyncOption && {
        label: importCta,
        icon: 'mail-inbox' as const,
        onClick: () => toggleEbillsImportModal(true),
      },
    ]);

    const items = isAddBillOpen
      ? addBillItems
      : [
          {
            label: formatMessage('activities.payDashboard.mobileActionsMenu.newPayment'),
            icon: 'pay' as const,
            onClick: handleNewPaymentClick,
            dataTestId: 'pay-dashboard-mobile-actions-menu-new-payment',
          },
          {
            label: formatMessage('activities.payDashboard.mobileActionsMenu.addBill'),
            icon: 'add-bill' as const,
            onClick: handleOpenAddBill,
            dataTestId: 'pay-dashboard-mobile-actions-menu-add-bill',
            rightElement: <Icon type="chevron-right" />,
          },
          {
            label: formatMessage('activities.payDashboard.mobileActionsMenu.addVendor'),
            icon: 'user-add' as const,
            onClick: handleAddVendorClick,
            dataTestId: 'pay-dashboard-mobile-actions-menu-add-vendor',
          },
        ];

    const handleOpenChange = (isOpen: boolean) => {
      setIsMenuOpen(isOpen);

      if (!isOpen) {
        setIsAddBillOpen(false);
      }
    };

    return (
      <>
        <UploadFilesWidget onUploadFiles={handleUploadABill} ref={fileInput} />
        <ActionsDropdownMenu
          items={items}
          size="medium"
          title={formatMessage('activities.payDashboard.mobile.actionsMenu.title')}
          trigger={
            <IconButton
              aria-label={formatMessage('activities.payDashboard.mobileActionsMenu.addButton.ariaLabel')}
              variant="brand"
              size="large"
              icon="add"
              data-testid="pay-dashboard-mobile-fab"
            />
          }
          isOpen={isMenuOpen}
          onOpenChange={handleOpenChange}
        />
        <EbillsImportModal isOpen={isEbillsImportModalOpen} onClose={() => toggleEbillsImportModal(false)} />
      </>
    );
  }
);
