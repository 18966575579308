import { Group, NakedButton } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';

import { usePartnerGroups } from '../../../hooks';
import { FundingSourceCard } from './FundingSourceCard';
import { FundingSourceEmptyCard } from './FundingSourceEmptyCard';

export const FundingSourceCardList = ({
  fundingSources,
  onCardClick,
  selectedId,
  onAddClick,
  addLabel,
  fundingSourceType,
  currentFundingSourceId,
}: {
  fundingSources: FundingSource[];
  onCardClick: (fundingSource: FundingSource) => void;
  selectedId: string | null;
  onAddClick: VoidFunction;
  addLabel: string;
  fundingSourceType: FundingSource['type'];
  currentFundingSourceId?: string;
}) => {
  const { isFiservPartner } = usePartnerGroups();

  return (
    <>
      <Group role="radiogroup" aria-labelledby="subscritpions-choose-billing-account" variant="vertical">
        {fundingSources.map((fundingSource) => (
          <FundingSourceCard
            key={fundingSource.id}
            fundingSource={fundingSource}
            onClick={() => onCardClick(fundingSource)}
            isSelected={selectedId === fundingSource.id}
            isCurrent={currentFundingSourceId === fundingSource.id}
          />
        ))}
      </Group>
      {!isFiservPartner &&
        (fundingSources.length > 0 ? (
          <Group>
            <NakedButton label={addLabel} variant="secondary" onClick={onAddClick} />
          </Group>
        ) : (
          <FundingSourceEmptyCard fundingSourceType={fundingSourceType} onClick={onAddClick} />
        ))}
    </>
  );
};
