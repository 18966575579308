import { Button, CollapsibleCard, Form, useWatch } from '@melio/penny';
import { useAnalytics, useAnalyticsContext, useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { isValidInternationalPhoneNumber } from '@melio/platform-utils';

import { isFieldReadonly as _isFieldReadonly } from '../../../../../../utils';
import { InternationalAddressInput } from '../../../../../international-address';
import { useTrackFormError } from '../hooks/useTrackFormError';
import { AccountHolderForm, FXFormSectionCardBaseProps } from '../types';
import { BirthdateField } from './BirthdateField';
import { VerifiedPersonaTaxIdField } from './VerifiedPersonaTaxIdField';

type FXPersonalDetailsCardProps = FXFormSectionCardBaseProps<AccountHolderForm> & {
  isAllowedToBeUbo: boolean;
};
export const FXPersonalDetailsCard = ({
  isExpanded,
  form,
  onExpandChange,
  isCompleted,
  isAllowedToBeUbo,
  isLoading,
}: FXPersonalDetailsCardProps) => {
  const { formatMessage } = useMelioIntl();
  const { control } = form;
  const { track } = useAnalytics();
  const phoneNumber = useWatch({ control, name: 'phoneNumber' });

  useTrackFormError<AccountHolderForm>({
    form,
    intent: 'set-account-holder-details',
    pageName: 'account-holder',
    viewContext: 'Organization',
  });

  useAnalyticsView('Organization', !isLoading && isExpanded, true, {
    PageName: 'account-holder',
    Intent: 'set-account-holder-details',
    Flow: 'UBO',
  });

  useAnalyticsContext({
    globalProperties: {
      IsOwn25: form.getValues('isUbo'),
    },
  });

  const onClickSaveAndContinue = () => {
    track('Organization', 'Click', {
      Cta: 'save-and-continue',
      Flow: 'UBO',
      Intent: 'set-account-holder-details',
      PageName: 'account-holder',
      FirstName: form.getValues('firstName'),
      LastName: form.getValues('lastName'),
      Email: form.getValues('email'),
      PhoneNumber: form.getValues('phoneNumber'),
      DayOfBirth: form.getValues('dayOfBirth'),
      TaxId: form.getValues('taxId'),
    });
    form.submitButtonProps.onClick();
  };

  const isFieldReadonly = (fieldKey: keyof AccountHolderForm) => _isFieldReadonly({ fieldKey, form });
  const isPhoneNumberValid = isValidInternationalPhoneNumber(phoneNumber);
  return (
    <CollapsibleCard
      data-testid="fx-personal-details-form-card"
      isDisabled={!isExpanded && !isCompleted}
      isExpanded={isExpanded}
      title={formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.personalDetailsCard.title')}
      description={
        isExpanded
          ? formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.personalDetailsCard.subtitle')
          : undefined
      }
      onExpandChange={onExpandChange}
      status={isCompleted ? 'success' : undefined}
    >
      <Form {...form.formProps} columns={6}>
        <Form.TextField
          colSpan={2}
          {...form.registerField('firstName')}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.personalDetailsCard.firstName.label'
            ),
          }}
          isViewMode={isFieldReadonly('firstName')}
        />
        <Form.TextField
          colSpan={2}
          {...form.registerField('lastName')}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.personalDetailsCard.lastName.label'
            ),
          }}
          isViewMode={isFieldReadonly('lastName')}
        />
        <Form.TextField
          colSpan={6}
          {...form.registerField('email')}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.personalDetailsCard.email.label'
            ),
          }}
          isViewMode={isFieldReadonly('email')}
        />
        <Form.TextField
          colSpan={6}
          {...form.registerField('phoneNumber')}
          placeholder={formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.personalDetailsCard.phoneNumber.placeholder'
          )}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.personalDetailsCard.phoneNumber.label'
            ),
          }}
          isViewMode={isPhoneNumberValid ? isFieldReadonly('phoneNumber') : false}
        />
        <BirthdateField
          colSpan={3}
          {...form.registerField('dayOfBirth')}
          isViewMode={isFieldReadonly('dayOfBirth')}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.personalDetailsCard.dateOfBirth.label'
            ),
          }}
        />

        <VerifiedPersonaTaxIdField colSpan={3} {...form.registerField('taxId')} />
        <InternationalAddressInput
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error*
          form={form}
          addressLine1FieldName="address.line1"
          cityFieldName="address.city"
          countryCodeFieldName="address.countryCode"
          stateFieldName="address.state"
          postalCodeFieldName="address.postalCode"
        />
        <Form.Checkbox
          {...form.registerField('isUbo')}
          colSpan={6}
          isHidden={!isAllowedToBeUbo}
          label={formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.personalDetailsCard.isUbo.label'
          )}
        />
      </Form>
      <Button
        isFullWidth
        data-testid="account-holder-submit-button"
        label={formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.personalDetailsCard.continueButton.label'
        )}
        {...form.submitButtonProps}
        isLoading={isLoading}
        onClick={onClickSaveAndContinue}
      />
    </CollapsibleCard>
  );
};
