import { Group, Modal, Text } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { SystemMessageDisplay } from '@melio/platform-utils';
import { useEffect } from 'react';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  contactEmail?: string;
  onConfirm: VoidFunction;
  onClose: VoidFunction;
};

export const EmailAlreadyInUseModal = withAnalyticsContext<Props>(
  ({ isOpen, isLoading, contactEmail, onConfirm, onClose, setAnalyticsProperties }) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();

    const trackClick = (cta: string) =>
      track(`User`, 'Click', {
        Cta: cta,
      });

    useEffect(() => {
      if (isOpen) {
        setAnalyticsProperties({
          PageName: 'email-address-in-use',
          CompanyOwner: false,
          OwnerEmail: contactEmail,
        });
        track('User', 'View');
      }
    }, [contactEmail, isOpen, setAnalyticsProperties, track]);

    const handleClose = () => {
      trackClick('exit');
      onClose();
    };

    const handleConfirm = () => {
      trackClick('request-to-join');
      onConfirm();
    };

    return (
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        data-component="EmailAlreadyInUseModal"
        data-testid="email-already-in-use-modal"
        header={formatMessage('activities.addCompany.screens.ownerDetails.modal.header')}
        primaryButton={{
          onClick: handleConfirm,
          variant: 'primary',
          label: formatMessage('activities.addCompany.screens.ownerDetails.modal.cta'),
          isLoading,
        }}
      >
        <Group variant="vertical" spacing="s" width="full">
          <SystemMessageDisplay />
          <Text>{formatMessage('activities.addCompany.screens.ownerDetails.modal.description', { contactEmail })}</Text>
        </Group>
      </Modal>
    );
  }
);

EmailAlreadyInUseModal.displayName = 'EmailAlreadyInUseModal';
