import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const FooterLayoutTheme: ComponentThemeType = (currentTheme) => ({
  baseStyle: {
    footer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: { xs: '48px', s: '40px' } as never,
      backgroundColor: currentTheme.colors.global.neutral['100'],
      paddingY: '4px',
      paddingX: '16px',
      gap: '12px',
      borderTop: 'basic.light',
    },
    link: {
      ...currentTheme.textStyles.body3,
      color: currentTheme.colors.global.brand['700'],
      fontSize: '10px',
    } as never,
    melioLogo: {
      color: currentTheme.colors.global.neutral['900'],
    },
  },
});
