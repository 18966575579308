import { useCallback } from 'react';
import { Box, Center, Flex, Image } from '@chakra-ui/react';
import { BaseModal, Button, Container, Group, Icon, Link, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePartnerConfig } from '@/hooks/partners';
import { AgreementTypeEnum } from './types';

export type ConsentScreenActivityProps = {
  termsOfServiceUrl: string;
  privacyPolicyUrl: string;
  isOpen: boolean;
  onClose: VoidFunction;
  onDone: VoidFunction;
};
export const ConsentScreenActivity = ({
  termsOfServiceUrl,
  privacyPolicyUrl,
  isOpen,
  onClose,
  onDone,
}: ConsentScreenActivityProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const {
    partnerConfig: { icons, partnerProductName, displayName },
  } = usePartnerConfig();

  useAnalyticsView('Consent');

  const onDoneWithAnalytics = useCallback(() => {
    track('Consent', 'Click', {
      Intent: 'Accept consent terms',
    });
    onDone();
  }, [track, onDone]);

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <BaseModal.Header>
        <Container textAlign="start">
          <Text textStyle="heading2Semi">{formatMessage('widgets.consentScreen.header', { partnerProductName })}</Text>
        </Container>
      </BaseModal.Header>
      <BaseModal.Body>
        <Group variant="vertical">
          <Text>{formatMessage('widgets.consentScreen.subtitle')}</Text>
          <Center>
            <Flex direction="column" alignItems="center" w="244px">
              {icons?.ConsentImage1?.small && <Image w="56px" h="56px" src={icons.ConsentImage1.small} alt="card" />}
              <Container textAlign="center" paddingTop="s">
                <Text>{formatMessage('widgets.consentScreen.fs')}</Text>
              </Container>
            </Flex>
            <Box w="24px" textAlign="center">
              <Icon type="arrow-right" color="default" />
            </Box>
            <Flex direction="column" alignItems="center" w="244px">
              {icons?.ConsentImage2?.small && <Image w="56px" h="56px" src={icons.ConsentImage2.small} alt="check" />}
              <Container textAlign="center" paddingTop="s">
                <Text>{formatMessage('widgets.consentScreen.dm')}</Text>
              </Container>
            </Flex>
          </Center>
        </Group>
      </BaseModal.Body>
      <BaseModal.Footer>
        <Button
          onClick={onDoneWithAnalytics}
          data-testid="modal-btn-primary"
          label={formatMessage('widgets.consentScreen.button')}
          isFullWidth
          variant="primary"
        />
        <Container textAlign="center">
          <Text textStyle="body4">
            {formatMessage('widgets.consentScreen.note', {
              partnerDisplayName: displayName,
              termsOfService: <Link href={termsOfServiceUrl} label={AgreementTypeEnum.termsOfService} newTab />,
              privacyPolicy: <Link href={privacyPolicyUrl} label={AgreementTypeEnum.privacyPolicy} newTab />,
            })}
          </Text>
        </Container>
      </BaseModal.Footer>
    </BaseModal>
  );
};
