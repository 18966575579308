import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAccountingFirmBillingFees } from '../../../../api/entities';
import { FundingSourceSelection } from '../../../FundingSourceSelection/FundingSourceSelection.activity';

type Props = {
  onSelectFundingSource: (fundingSourceId: string) => void;
  selectedFundingSourceId: string | null;
};

export const SelectPaymentMethod = ({ onSelectFundingSource, selectedFundingSourceId }: Props) => {
  const [searchParams] = useSearchParams();
  const urlFundingSourceId = searchParams.get('fundingSourceId');

  const { data, isLoading: isAccountingFirmBillingFeeLoading } = useAccountingFirmBillingFees();

  useEffect(() => {
    if (urlFundingSourceId) {
      onSelectFundingSource(urlFundingSourceId);
    } else if (data?.accountingFirmBillingFee?.fundingSourceId) {
      onSelectFundingSource(data?.accountingFirmBillingFee?.fundingSourceId);
    }
  }, [
    urlFundingSourceId,
    isAccountingFirmBillingFeeLoading,
    onSelectFundingSource,
    data?.accountingFirmBillingFee?.fundingSourceId,
  ]);

  return (
    <FundingSourceSelection fundingSourceId={selectedFundingSourceId} setFundingSourceId={onSelectFundingSource} />
  );
};
