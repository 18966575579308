import { ModelError } from '@melio/platform-api';
import { MessageKey } from '@melio/platform-i18n';

const errorCodes = {
  cancel_ebill_autopay_failed: 'cancel_ebill_autopay_failed',
  cancel_failed_ebill_pending: 'cancel_failed_ebill_pending',
  cancel_failed_ebill_enabled: 'cancel_failed_ebill_enabled',
  ebill_and_recurring_model_cancelled_payee_not_deleted: 'ebill_and_recurring_model_cancelled_payee_not_deleted',
  ebill_service_cancellation_failed: 'ebill_service_cancellation_failed',
  ebill_service_cancelled_payee_not_deleted: 'ebill_service_cancelled_payee_not_deleted',
  inactive_vendor: 'inactive_vendor',
  recurring_transaction_model_delete_failed: 'recurring_transaction_model_delete_failed',
  recurring_transaction_model_deleted_payee_not_deleted: 'recurring_transaction_model_deleted_payee_not_deleted',
};

const errorCodesMap = new Map(Object.entries(errorCodes));

const defaultMessageKey = 'activities.payDashboard.deleteVendorModal.toast.error';
const messageKeyPrefix = 'activities.payDashboard.deleteVendorModal.toast.error';

export const getMessageKey = (error: ModelError): MessageKey => {
  if (!error.data) {
    return defaultMessageKey;
  } else {
    const errorCode = error.data[0]?.errorCode;
    if (!errorCode) {
      return defaultMessageKey;
    }
    if (errorCodesMap.has(errorCode)) {
      const errorCodeValue = errorCodesMap.get(errorCode); // Using get to retrieve the value from the map
      if (!errorCodeValue) {
        return defaultMessageKey;
      }
      return `${messageKeyPrefix}.${errorCodeValue}` as MessageKey;
    }
    return defaultMessageKey;
  }
};
