import { Group, Table, Text } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { DeliveryDateTooltip } from '../utils';

export const DeliveryDateCell = ({ payment, deliveryDate }: { payment: Payment; deliveryDate?: Date }) => {
  const { formatDate } = useMelioIntl();

  return (
    <Table.Cell data-testid="pending-approval-payments-row-delivery-date-cell">
      <Group variant="horizontal" spacing="xxs">
        <Text textStyle="body3">{formatDate(deliveryDate ?? payment.estimatedDelivery, { dateStyle: 'medium' })}</Text>
        <DeliveryDateTooltip payment={payment} deliveryDate={deliveryDate} />
      </Group>
    </Table.Cell>
  );
};
