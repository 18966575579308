import { VendorBankDetailsPayorForm, VendorBankDetailsPayorFormFields } from '@melio/ap-widgets';
import { Group, SectionBanner, useFormSubmissionController } from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef, SystemMessageDisplay } from '@melio/platform-utils';
import { ReactElement } from 'react';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { DeliveryMethodFlowOrigin } from '../../../types';

export type AddVendorBankDetailsScreenProps = {
  isLoading?: boolean;
  isSaving: boolean;
  vendorName: Vendor['name'];
  defaultValues?: Partial<VendorBankDetailsPayorFormFields>;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (
    data: VendorBankDetailsPayorFormFields,
    event?: React.MouseEvent<HTMLButtonElement>,
    target?: 'continue' | 'close'
  ) => void;
  origin: DeliveryMethodFlowOrigin;
  bottomContent?: ReactElement;
};

export const AddVendorBankDetailsScreen: React.FC<AddVendorBankDetailsScreenProps> = forwardRef<
  AddVendorBankDetailsScreenProps,
  'div'
>(
  (
    { isLoading, isSaving, vendorName, defaultValues, onBack, onClose, onDone, origin, bottomContent, ...props },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const { accountNumber, routingNumber } = defaultValues ?? {};
    const showPreFilledBanner = !!(accountNumber || routingNumber);
    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps, handleSubmit } =
      useFormSubmissionController<VendorBankDetailsPayorFormFields>();

    return (
      <NewSinglePaymentStepLayout
        isLoading={isLoading}
        data-component="AddVendorBankDetailsActivity.AddVendorBankDetailsScreen"
        data-testid="add-vendor-bank-details-activity-add-vendor-bank-details-screen"
        {...props}
        ref={ref}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
            <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
          </NewSinglePaymentStepLayout.Header>
        }
        bottomContent={
          bottomContent ?? (
            <NewSinglePaymentStepLayout.BottomTextContent>
              {formatMessage('activities.addVendorBankDetails.screens.addVendorBankDetails.footNotes', {
                vendorName,
              })}
            </NewSinglePaymentStepLayout.BottomTextContent>
          )
        }
        footerContent={
          <Group variant="vertical">
            <NewSinglePaymentStepLayout.Actions>
              {origin === 'vendor' ? (
                <Group variant="vertical" spacing="m" width="full">
                  <NewSinglePaymentStepLayout.NextButton
                    {...submitButtonProps}
                    onClick={handleSubmit?.('continue')}
                    label={formatMessage(
                      'activities.addVendorBankDetails.screens.addVendorBankDetails.actions.vendorOrigin.continue'
                    )}
                  />
                  <NewSinglePaymentStepLayout.SecondaryNextButton
                    {...submitButtonProps}
                    onClick={handleSubmit?.('close')}
                    variant="tertiary"
                    label={formatMessage(
                      'activities.addVendorBankDetails.screens.addVendorBankDetails.actions.vendorOrigin.close'
                    )}
                  />
                </Group>
              ) : (
                <NewSinglePaymentStepLayout.NextButton
                  {...submitButtonProps}
                  onClick={handleSubmit?.('continue')}
                  label={formatMessage(
                    'activities.addVendorBankDetails.screens.addVendorBankDetails.actions.paymentOrigin.continue'
                  )}
                />
              )}
            </NewSinglePaymentStepLayout.Actions>
          </Group>
        }
      >
        {showPreFilledBanner ? (
          <SectionBanner
            variant="informative"
            description={formatMessage('activities.addVendorBankDetails.screens.addVendorBankDetails.sectionBanner')}
          />
        ) : null}
        <NewSinglePaymentStepLayout.Title>
          {formatMessage('activities.addVendorBankDetails.screens.addVendorBankDetails.title', { vendorName })}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Content>
          <Group variant="vertical">
            <SystemMessageDisplay data-testid="add-vendor-bank-details-activity-system-message-display" />
            <VendorBankDetailsPayorForm
              onSubmit={onDone}
              isSaving={isSaving}
              defaultValues={{ accountNumber, routingNumber }}
              onSubmissionStateChange={onSubmissionStateChange}
            />
          </Group>
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

AddVendorBankDetailsScreen.displayName = 'AddVendorBankDetailsActivity.AddVendorBankDetailsScreen';
