import { useVendorNotPermitted } from '@melio/ap-domain';
import { Payment } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { identity } from 'lodash';

import { isPaymentCompletedRefund } from '../../../../../utils/pay-flow/Payment.utils';
import { usePaymentAmountDetails } from '../../../hooks/usePaymentAmountDetails';
import { usePaymentDeliveryMethod } from './PaymentsTabDeliveryMethodCell';
import { usePaymentStatus } from './PaymentsTabStatusCell';

export function usePaymentsTabListItem(payment: Payment) {
  const { formatCurrency, formatDate, formatMessage } = useMelioIntl();
  const { deliveryMethod } = usePaymentDeliveryMethod(payment);
  const getPaymentStatus = usePaymentStatus();
  const { label: statusLabel, status } = getPaymentStatus(payment);
  const [isFinancingEnabled] = useDevFeature<boolean>(FeatureFlags.PlatformFinancing, false);
  const isVendorNotPermitted = useVendorNotPermitted(payment.vendor);

  const formattedAmount = formatCurrency(payment.foreignAmount || payment.amount, payment.currency);
  const amountDescription = usePaymentAmountDetails(payment);
  const isCompletedRefundPayment = isPaymentCompletedRefund(payment);
  const deliveryDate = isCompletedRefundPayment
    ? formatMessage('activities.payDashboard.paymentsTab.table.cells.notAvailable')
    : formatMessage('activities.payDashboard.paymentsTab.listItem.deliveryDate', {
        deliveryDate: formatDate(payment.estimatedDelivery, { dateStyle: 'medium' }),
      });
  const vendorDescription = [payment?.vendor?.nickname, payment?.vendor?.accountNumber].filter(identity).join(' | ');

  return {
    isVendorNotPermitted,
    formattedAmount,
    amountDescription,
    vendorDescription,
    deliveryDate,
    isFinancingEnabled,
    deliveryMethod,
    statusLabel,
    status,
  };
}
