import { Table, TableColumnDef, useBreakpoint } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

import { useTableColumnsSort } from '../../hooks/useTableColumnsSort';
import { usePayDashboardSorting } from '../../PayDashboardSortingProvider';
import { FinancingTabActionButtonCell } from './components/FinancingTabActionButtonCell';
import { FinancingTabDebitDateCell } from './components/FinancingTabDebitDateCell';
import { FinancingTabNextInstallmentCell } from './components/FinancingTabNextInstallmentCell';
import { FinancingTabRemainingAmountCell } from './components/FinancingTabRemainingAmountCell';
import { FinancingTabStatusCell } from './components/FinancingTabStatusCell';
import { FinancingTabTotalAmountCell } from './components/FinancingTabTotalAmountCell';
import { FinancingTabVendorNameCell } from './components/FinancingTabVendorNameCell';
import { CELLS_IDS, FinancingTabSortableCells, LoansTabItem, LoansTableMeta } from './types';

export const useLoansTableColumns = () => {
  const { formatMessage } = useMelioIntl();
  const sorting = usePayDashboardSorting();
  const { getSortableHeader } = useTableColumnsSort<FinancingTabSortableCells>({ sorting });
  const { breakpoint } = useBreakpoint();

  const isLargeScreen = ['l', 'xl'].includes(breakpoint);

  return useMemo(() => {
    const vendorColumn: TableColumnDef<LoansTabItem, LoansTableMeta> = {
      id: CELLS_IDS.VENDOR_NAME,
      isPinnedToLeft: true,
      size: 'm',
      header: getSortableHeader({
        label: formatMessage('activities.payDashboard.financingTab.table.headers.vendor'),
        cellId: CELLS_IDS.VENDOR_NAME,
        defaultSortOrder: 'asc',
      }),
      cell: ({ row, meta }) =>
        meta ? <FinancingTabVendorNameCell onViewPayment={meta.onViewPayment} loansTabItem={row} /> : null,
    };

    const statusColumn: TableColumnDef<LoansTabItem, LoansTableMeta> = {
      id: CELLS_IDS.STATUS,
      size: 's',
      header: formatMessage('activities.payDashboard.financingTab.table.headers.status'),
      cell: ({ row }) => <FinancingTabStatusCell loansTabItem={row} />,
    };

    const nextDebitColumn: TableColumnDef<LoansTabItem, LoansTableMeta> = {
      id: CELLS_IDS.NEXT_DEBIT,
      size: 's',
      header: !sorting?.sortingState ? (
        <Table.HeaderSortableCell sortDirection="desc">
          {formatMessage('activities.payDashboard.financingTab.table.headers.nextDebit')}
        </Table.HeaderSortableCell>
      ) : (
        formatMessage('activities.payDashboard.financingTab.table.headers.nextDebit')
      ),
      cell: ({ row }) => <FinancingTabDebitDateCell loansTabItem={row} />,
    };

    const nextInstallmentColumn: TableColumnDef<LoansTabItem, LoansTableMeta> = {
      id: CELLS_IDS.NEXT_INSTALLMENT,
      size: 140,
      header: formatMessage('activities.payDashboard.financingTab.table.headers.nextInstallment'),
      textAlign: 'end',
      cell: ({ row }) => <FinancingTabNextInstallmentCell loansTabItem={row} />,
    };

    const remainingAmountColumn: TableColumnDef<LoansTabItem, LoansTableMeta> = {
      id: CELLS_IDS.REMAINING_AMOUNT,
      size: 140,
      header: formatMessage('activities.payDashboard.financingTab.table.headers.remainingAmount'),
      textAlign: 'end',
      cell: ({ row }) => <FinancingTabRemainingAmountCell loansTabItem={row} />,
    };

    const totalAmountColumn: TableColumnDef<LoansTabItem, LoansTableMeta> = {
      id: CELLS_IDS.TOTAL_AMOUNT,
      size: 140,
      isPinnedToRight: isLargeScreen,
      header: formatMessage('activities.payDashboard.financingTab.table.headers.totalAmount'),
      textAlign: 'end',
      cell: ({ row }) => <FinancingTabTotalAmountCell loansTabItem={row} />,
    };

    const actionButtonColumn: TableColumnDef<LoansTabItem, LoansTableMeta> = {
      id: CELLS_IDS.ACTION_BUTTON,
      size: 140,
      isPinnedToRight: isLargeScreen,
      header: formatMessage('activities.payDashboard.table.headers.action'),
      textAlign: 'end',
      cell: ({ row, meta }) =>
        meta ? <FinancingTabActionButtonCell loansTabItem={row} onViewClick={meta.onViewClick} /> : null,
    };

    return [
      vendorColumn,
      statusColumn,
      nextDebitColumn,
      nextInstallmentColumn,
      remainingAmountColumn,
      totalAmountColumn,
      actionButtonColumn,
    ];
  }, [formatMessage, getSortableHeader, sorting?.sortingState, isLargeScreen]);
};
