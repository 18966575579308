import { Flex } from '@chakra-ui/react';
import {
  Control,
  Form,
  FormLineItems,
  NakedButton,
  Typography,
  useBreakpoint,
  useFieldArray,
  useWatch,
} from '@melio/penny';
import { useAccountingPlatformItems, useAccountingPlatforms } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useRef, useState } from 'react';

import { useLineItemAccessibility } from '../../../hooks/useLineItemAccessibility';
import { useLineItemAnalytics } from '../../../hooks/useLineItemAnalytics';
import { AddBillV2FormValues, AddBillV2SetValue, RegisterFieldFn } from '../../../types';
import { createEmptyXeroSyncedLineItem } from '../../../utils';
import { TotalAmount } from '../../TotalAmount';
import { LineItemsPaywallWrapper } from '../LineItemsPaywallWrapper';
import { XeroSyncedLineItem } from './XeroSyncedLineItem';

type Props = {
  formControl: Control<AddBillV2FormValues>;
  setValue: AddBillV2SetValue;
  registerField: RegisterFieldFn;
  isHidden: boolean;
  currency?: string;
  isLineItemsExpanded: boolean;
  onExpandLineItems: VoidFunction;
  isLineItemsEligible: boolean;
};

export const XeroSyncedLineItemsForm = ({
  formControl,
  currency,
  setValue,
  isHidden,
  registerField,
  isLineItemsExpanded,
  onExpandLineItems,
  isLineItemsEligible,
}: Props) => {
  const { onDeleteLineItemSuccess } = useLineItemAccessibility();
  const { trackLineItemAdded, trackLineItemDeleted } = useLineItemAnalytics();
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { activeAccountingPlatform, hasAccountingPlatform } = useAccountingPlatforms();
  const { data: accountingPlatformItems } = useAccountingPlatformItems({
    accountingPlatformId: activeAccountingPlatform?.id,
    enabled: hasAccountingPlatform,
  });
  const { fields, append, remove, update } = useFieldArray({
    control: formControl,
    name: 'xeroSyncedLineItems',
    shouldUnregister: false,
    rules: {
      minLength: 1,
    },
  });
  const xeroSyncedLineItems = useWatch({
    control: formControl,
    name: 'xeroSyncedLineItems',
  });
  const amount = useWatch({
    control: formControl,
    name: 'amount',
  });
  const totalAmount = formatCurrency(parseFloat(amount || '0'), currency);
  const footerRef = useRef<HTMLDivElement>(null);

  const [shouldAutoFocus, setShouldAutoFocus] = useState(false);
  const onAddLineItem = () => {
    if (!shouldAutoFocus) {
      setShouldAutoFocus(true);
    }

    if (!fields.length && !xeroSyncedLineItems?.length) {
      append(
        createEmptyXeroSyncedLineItem({
          quantity: '1',
          amount: amount ?? '',
          unitPrice: amount ?? '',
        })
      );

      return;
    }

    append(createEmptyXeroSyncedLineItem());
    trackLineItemAdded();
    window.setTimeout(() => footerRef.current?.scrollIntoView({ behavior: 'smooth' }));
    return;
  };

  if (isHidden) {
    return null;
  }

  const onRemoveLineItem = (index: number) => {
    remove(index);
    trackLineItemDeleted();
    onDeleteLineItemSuccess();
  };

  const hasLineItems = fields.length > 1;

  const lineItems = isMobile ? (
    <FormLineItems.MobileList>
      {fields.map((field, index) => (
        <XeroSyncedLineItem
          formControl={formControl}
          key={field.id}
          items={accountingPlatformItems ?? []}
          itemsPickerProps={registerField(`xeroSyncedLineItems.${index}.externalItemId`)}
          descriptionFieldProps={registerField(`xeroSyncedLineItems.${index}.description`)}
          amountFieldProps={registerField(`xeroSyncedLineItems.${index}.amount`)}
          unitPriceFieldProps={registerField(`xeroSyncedLineItems.${index}.unitPrice`)}
          currency={currency}
          categoriesPickerProps={registerField(`xeroSyncedLineItems.${index}.externalCategoryId`)}
          quantityFieldProps={registerField(`xeroSyncedLineItems.${index}.quantity`)}
          activeAccountingPlatform={activeAccountingPlatform}
          accountingPlatformCategoryLabel={formatMessage(
            'activities.addBillV2.lineItems.categoryBased.sectionTitle.xero'
          )}
          index={index}
          setValue={setValue}
          onClickRemoveLineItem={() => onRemoveLineItem(index)}
          hasLineItems={hasLineItems}
          shouldAutoFocus={shouldAutoFocus}
          updateField={update}
        />
      ))}
    </FormLineItems.MobileList>
  ) : (
    <Form.ContentBox colSpan={16}>
      <FormLineItems>
        <FormLineItems.HeaderRow>
          <FormLineItems.HeaderCell size="xs" />
          <FormLineItems.HeaderCell size="m">
            <Typography.Label
              labelShouldSupportEllipsis
              label={formatMessage('activities.addBillV2.lineItems.xeroSynced.itemPicker.label')}
            />
          </FormLineItems.HeaderCell>
          <FormLineItems.HeaderCell size="m">
            <Typography.Label
              labelShouldSupportEllipsis
              label={formatMessage('activities.addBillV2.lineItems.xeroSynced.description.label')}
            />
          </FormLineItems.HeaderCell>
          <FormLineItems.HeaderCell size="s">
            <Typography.Label
              labelShouldSupportEllipsis
              label={formatMessage('activities.addBillV2.lineItems.xeroSynced.quantity.placeholder')}
              description="*"
            />
          </FormLineItems.HeaderCell>
          <FormLineItems.HeaderCell size="s">
            <Typography.Label
              labelShouldSupportEllipsis
              label={formatMessage('activities.addBillV2.lineItems.xeroSynced.unitPrice.placeholder')}
              description="*"
            />
          </FormLineItems.HeaderCell>
          <FormLineItems.HeaderCell size="m">
            <Typography.Label
              labelShouldSupportEllipsis
              label={formatMessage('activities.addBillV2.lineItems.categoryBased.sectionTitle.xero')}
            />
          </FormLineItems.HeaderCell>
          <FormLineItems.HeaderCell size="l" isSticky>
            <Typography.Label
              labelShouldSupportEllipsis
              label={formatMessage('activities.addBillV2.lineItems.xeroSynced.amount.placeholder')}
              description="*"
            />
          </FormLineItems.HeaderCell>
        </FormLineItems.HeaderRow>

        <FormLineItems.Body>
          {fields.map((field, index) => (
            <XeroSyncedLineItem
              formControl={formControl}
              key={field.id}
              items={accountingPlatformItems ?? []}
              itemsPickerProps={registerField(`xeroSyncedLineItems.${index}.externalItemId`)}
              descriptionFieldProps={registerField(`xeroSyncedLineItems.${index}.description`)}
              amountFieldProps={registerField(`xeroSyncedLineItems.${index}.amount`)}
              unitPriceFieldProps={registerField(`xeroSyncedLineItems.${index}.unitPrice`)}
              currency={currency}
              categoriesPickerProps={registerField(`xeroSyncedLineItems.${index}.externalCategoryId`)}
              quantityFieldProps={registerField(`xeroSyncedLineItems.${index}.quantity`)}
              activeAccountingPlatform={activeAccountingPlatform}
              accountingPlatformCategoryLabel={formatMessage(
                'activities.addBillV2.lineItems.categoryBased.sectionTitle.xero'
              )}
              index={index}
              setValue={setValue}
              onClickRemoveLineItem={() => onRemoveLineItem(index)}
              hasLineItems={hasLineItems}
              shouldAutoFocus={shouldAutoFocus}
              updateField={update}
            />
          ))}
        </FormLineItems.Body>
      </FormLineItems>
    </Form.ContentBox>
  );

  return (
    <LineItemsPaywallWrapper
      isHidden={isHidden}
      isLineItemsExpanded={isLineItemsEligible && isLineItemsExpanded}
      title={formatMessage('activities.addBillV2.lineItems.xeroSynced.sectionTitle')}
      onExpandLineItems={onExpandLineItems}
      data-testid="add-bill-v2-xero-line-items-title"
    >
      {lineItems}
      <Form.ContentBox colSpan={16}>
        <Flex justifyContent="space-between" alignItems="flex-start" pb="m" ref={footerRef}>
          <NakedButton
            data-testid="add-bill-v2-mixed-line-items-add-new-line"
            variant="secondary"
            label={formatMessage('activities.addBillV2.lineItems.xeroSynced.addNewLine')}
            aria-label={formatMessage('activities.addBillV2.lineItems.xeroSynced.addNewLine.ariaLabel')}
            onClick={onAddLineItem}
          />
          <TotalAmount amount={totalAmount} />
        </Flex>
      </Form.ContentBox>
    </LineItemsPaywallWrapper>
  );
};
