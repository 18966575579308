import { useBillSubscription, Vendor } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';
import { useState } from 'react';

import { PaymentScheduledNotifyVendorModalActivity } from '../PaymentScheduledNotifyVendor';
import { PaymentFlowDoneAction } from '../types';
import { RecurringPaymentScheduledSuccessfullyActivity } from './NewRecurringPaymentScheduled';
import { RecurringPaymentScheduledLoadingScreen, RecurringPaymentScheduledScreen } from './screens';
import { BillSubscriptionDescriptionPropsOld } from './screens/RecurringPaymentScheduled/RecurringPaymentScheduled.types';
import { RecurringPaymentScheduledActivityProps } from './types';

export const RecurringPaymentScheduledActivity = (props: RecurringPaymentScheduledActivityProps) => {
  const [isNewPaymentScheduledSuccessfully] = useDevFeature(FeatureFlags.NewPaymentScheduledSuccessfully, false);

  if (isNewPaymentScheduledSuccessfully) {
    return <RecurringPaymentScheduledSuccessfullyActivity {...props} />;
  }

  return <RecurringPaymentScheduledActivityComponent {...props} />;
};

export const RecurringPaymentScheduledActivityComponent: React.VFC<RecurringPaymentScheduledActivityProps> = ({
  onClose,
  onError,
  onDone,
  billSubscriptionId,
}) => {
  const [isNotifyVendorModalOpen, setIsNotifyVendorModalOpen] = useState<boolean>(false);

  const { data: billSubscription, isFetching } = useBillSubscription({ id: billSubscriptionId });

  const {
    settings: {
      payment: { allowNotifyingVendor },
    },
  } = useConfig();

  const isLoading = isFetching || !billSubscription || !billSubscription?.vendor;
  if (isLoading) {
    return <RecurringPaymentScheduledLoadingScreen onClose={onClose} />;
  }

  const descriptionProps: BillSubscriptionDescriptionPropsOld = {
    billSubscriptionId,
    amount: billSubscription.amount,
    currency: billSubscription.currency,
    vendor: billSubscription.vendor as Vendor,
    scheduledDate: billSubscription.startDate,
    intervalType: billSubscription.intervalType,
    approvalDecisionStatus: billSubscription.approvalDecisionStatus,
    paymentIds: billSubscription.occurrences?.map(({ paymentId }) => paymentId || ''),
  };

  const vendorEmailExists = !!billSubscription.vendor?.contact.email;
  const isManagedAccountDeliveryMethod = billSubscription.deliveryMethod?.type === 'managed-account';

  return (
    <>
      <RecurringPaymentScheduledScreen
        onClose={() => onDone('goToDashboard')}
        subscriptionDetails={descriptionProps}
        notifyVendor={!vendorEmailExists && !isManagedAccountDeliveryMethod && allowNotifyingVendor}
        onNotifyVendors={() => setIsNotifyVendorModalOpen(true)}
        onDone={(action: PaymentFlowDoneAction) => onDone(action)}
      />
      <PaymentScheduledNotifyVendorModalActivity
        onError={onError}
        isOpen={isNotifyVendorModalOpen}
        onClose={() => setIsNotifyVendorModalOpen(false)}
        onDone={() => {
          setIsNotifyVendorModalOpen(false);
          onDone('goToDashboard');
        }}
        vendorsIds={vendorEmailExists ? [] : [billSubscription.vendorId]}
      />
    </>
  );
};
