import { Link } from '@melio/penny';
import { PaymentFullyExpanded, useBill } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { identity } from 'lodash';
import { MouseEventHandler, RefObject } from 'react';

import { useShowBillDetailsInPaymentDrawer } from '../../../paymentDrawer.utils';
import { SectionBlock } from './SectionBlock';

type Props = { payment: PaymentFullyExpanded; billDetailsRef: RefObject<HTMLDivElement> };

export const PaidToSection = ({ payment, billDetailsRef }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { data: bill } = useBill({
    id: payment.paymentBillsInfo?.[0]?.id,
    enabled: !!payment.paymentBillsInfo?.[0]?.id,
  });

  const vendorName = payment.vendor.name;
  const vendorDeletedDate = payment.vendor?.history?.deletedAt;
  const vendorDescription = [payment.vendor.nickname, payment.vendor.accountNumber].filter(identity).join(' | ');

  const invoiceNumber = bill?.invoice.number ?? null;
  return (
    <SectionBlock
      testId="paid-to-section"
      title={{ label: formatMessage('widgets.paymentDetails.paidTo.title') }}
      body={{
        label: vendorName,
        ...(vendorDeletedDate && {
          badgeProps: {
            type: 'secondary',
            label: formatMessage('widgets.paymentDetails.paidTo.deleted'),
            status: 'neutral',
          },
        }),
      }}
      secondaryDescription={{ label: vendorDescription }}
      description={{
        label: invoiceNumber
          ? formatMessage('widgets.paymentDetails.paidTo.description.full', {
              invoiceNumber,
              link: <BillDetailsLink billDetailsRef={billDetailsRef} payment={payment} />,
            })
          : formatMessage('widgets.paymentDetails.paidTo.description.empty', {
              link: <BillDetailsLink billDetailsRef={billDetailsRef} payment={payment} />,
            }),
      }}
    />
  );
};

const BillDetailsLink = ({ billDetailsRef, payment }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { showBillDetailsInPaymentDrawer } = useShowBillDetailsInPaymentDrawer(payment.createMethod);

  const handleClick: MouseEventHandler<HTMLLinkElement> = (e) => {
    e.preventDefault();
    billDetailsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  if (!showBillDetailsInPaymentDrawer) {
    return null;
  }

  return (
    <Link
      href="#"
      size="medium"
      color="secondary"
      onClick={handleClick}
      data-testid="bill-details-link"
      label={formatMessage('widgets.paymentDetails.paidTo.description.link')}
    />
  );
};
