import {
  InvoiceExpandedSummary,
  InvoiceSummary,
  InvoiceTimeline,
  useFiles,
  useIsMobile,
  useMelioIntl,
} from '@melio/ar-domain';
import { SectionBanner, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { Footer, InvoiceFormLayout } from '../../../layouts';
import { InvoiceForm, InvoiceFormHeader, InvoiceFormMobileHeader, InvoiceFormProps } from '../components';
import {
  DiscountProps,
  EditInvoiceSubmitTarget,
  InvoiceTotalAmountLimits,
  ServiceChargesProps,
  TaxRateProps,
} from '../types';
import { InvoiceFormState, InvoiceFormValues } from '../utils';

export type EditInvoiceScreenProps = {
  onClose: VoidFunction;
  onSubmit: (
    data: InvoiceFormValues,
    event?: React.MouseEvent<HTMLButtonElement>,
    target?: EditInvoiceSubmitTarget
  ) => unknown;
  taxRate?: TaxRateProps;
  discount?: DiscountProps;
  serviceCharges?: ServiceChargesProps;
  isSaving?: EditInvoiceSubmitTarget;
  isDisabled?: boolean;
  isLoading?: boolean;
  isLoadingSummary?: boolean;
  defaultValues?: InvoiceFormProps['defaultValues'];
  error?: ARPlatformError;
  invoiceSummary?: InvoiceSummary;
  onLineItemsChange?: (lineItems: InvoiceFormValues['lineItems']) => unknown;
  onFormStateChange?: (state: InvoiceFormState) => void;
  invoice?: InvoiceExpandedSummary;
  totalAmountLimits?: InvoiceTotalAmountLimits;
  onCreateCustomer: (name: string) => void;
  onUpdateCustomer: (customerId: string) => void;
  customerId?: string;
  invoiceEmail?: InvoiceTimeline['emails'][number];
};

export const EditInvoiceScreen = forwardRef<EditInvoiceScreenProps>(
  (
    {
      defaultValues,
      isSaving,
      isLoading,
      isLoadingSummary,
      discount = { onEdit: undefined, id: undefined, onRemove: undefined },
      taxRate = { onEdit: undefined, id: undefined },
      serviceCharges = { onAdd: undefined, onRemove: undefined },
      onSubmit,
      error,
      invoiceSummary,
      onLineItemsChange,
      onClose,
      onFormStateChange,
      invoice,
      totalAmountLimits,
      onCreateCustomer,
      onUpdateCustomer,
      customerId,
      invoiceEmail,
      ...props
    },
    ref
  ) => {
    const { onSubmissionStateChange, handleSubmit, submitButtonProps } =
      useFormSubmissionController<InvoiceFormValues>();
    const { isMutating: isLoadingAttachment } = useFiles({ enabled: false });
    const { formatMessage, formatDate } = useMelioIntl();
    const isMobile = useIsMobile();
    const isLoadInProgress = isLoadingAttachment || submitButtonProps?.isDisabled || isLoadingSummary || isLoading;

    useAnalyticsView('Invoice', !isLoadInProgress, true, {
      ProductName: 'ar',
      PageName: 'edit-invoice',
      Flow: 'edit-invoice',
      Intent: 'edit-invoice',
      AttachmentFileIds: defaultValues?.attachments?.map((attachment) => attachment.id),
    });

    return (
      <InvoiceFormLayout
        backgroundColor="white"
        maxWidth="1200px"
        isLoading={isLoading}
        onClose={onClose}
        footer={
          <Footer
            primaryButtonProps={{
              label: formatMessage(
                invoice?.issuedAt
                  ? `ar.invoiceLifecycle.activities.editInvoice.buttons.saveAndContinue.issued.label`
                  : `ar.invoiceLifecycle.activities.editInvoice.buttons.saveAndContinue.draft.label`
              ),
              isLoading: isSaving === 'save-and-continue',
              onClick: handleSubmit?.('save-and-continue'),
              isDisabled: isLoadInProgress || (!!isSaving && isSaving !== 'save-and-continue'),
            }}
            secondaryButtonProps={{
              label: formatMessage(
                invoice?.issuedAt
                  ? `ar.invoiceLifecycle.activities.editInvoice.buttons.saveAndClose.issued.label`
                  : `ar.invoiceLifecycle.activities.editInvoice.buttons.saveAndClose.draft.label`
              ),
              isLoading: isSaving === 'save-and-close',
              isDisabled: isLoadInProgress || (!!isSaving && isSaving !== 'save-and-close'),
              onClick: handleSubmit?.('save-and-close'),
            }}
            tertiaryButtonProps={
              !isMobile
                ? {
                    label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.buttons.cancel.label'),
                    onClick: onClose,
                    isDisabled: isLoading || !!isSaving,
                  }
                : undefined
            }
          />
        }
        data-component={EditInvoiceScreen.displayName}
        data-testid="create-invoice-screen"
        {...props}
        ref={ref}
        header={
          isMobile ? (
            <InvoiceFormMobileHeader
              headerText={formatMessage('ar.invoiceLifecycle.activities.editInvoice.screens.form.header')}
              onBack={onClose}
            />
          ) : (
            <InvoiceFormHeader
              headerText={formatMessage('ar.invoiceLifecycle.activities.editInvoice.screens.form.header')}
            />
          )
        }
      >
        {invoiceEmail && (
          <SectionBanner
            title={formatMessage('ar.invoiceLifecycle.activities.editInvoice.draft.sectionBanner.title', {
              email: invoiceEmail.email,
              sharedAt: formatDate(invoiceEmail.sentAt),
            })}
            description={formatMessage('ar.invoiceLifecycle.activities.editInvoice.draft.sectionBanner.description')}
          />
        )}
        <InvoiceForm
          mode="edit"
          invoiceSummary={invoiceSummary}
          discount={discount}
          taxRate={taxRate}
          serviceCharges={serviceCharges}
          onSubmissionStateChange={onSubmissionStateChange}
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          isSaving={!!isSaving}
          isLoadingSummary={isLoadingSummary}
          onLineItemsChange={onLineItemsChange}
          onFormStateChange={onFormStateChange}
          totalAmountLimits={totalAmountLimits}
          error={error}
          onCreateCustomer={onCreateCustomer}
          onUpdateCustomer={onUpdateCustomer}
          customerId={customerId}
        />
      </InvoiceFormLayout>
    );
  }
);
EditInvoiceScreen.displayName = 'EditInvoiceScreen';
