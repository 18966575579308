import { DashboardCustomer, useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { ActionsDropdownMenuItemProps } from '@melio/penny';

import { CustomerActions } from '../types';

export type UseCustomerActionsDropdownMenuItemsProps = {
  customer: DashboardCustomer;
  customerActions?: CustomerActions;
};

export const useCustomerActionsDropdownMenuItems = ({
  customer,
  customerActions,
}: UseCustomerActionsDropdownMenuItemsProps): ActionsDropdownMenuItemProps[] => {
  const { formatMessage } = useMelioIntl();
  const isMobile = useIsMobile();

  const createInvoiceAction = {
    label: formatMessage('ar.dashboard.activities.customersTable.cells.actions.createInvoice.text'),
    onClick: () => customerActions?.onCreateInvoice(customer.id),
  };
  const editCustomerAction = {
    label: formatMessage('ar.dashboard.activities.customersTable.cells.actions.editCustomer.text'),
    onClick: () => customerActions?.onEditCustomer(customer.id),
  };
  const deleteCustomerAction = {
    label: formatMessage('ar.dashboard.activities.customersTable.cells.actions.deleteCustomer.text'),
    onClick: () => customerActions?.onDeleteCustomer(customer.id),
    variant: 'critical',
  };
  const displayInvoicesAction = {
    label: formatMessage('ar.dashboard.activities.customersTable.listItem.actionsMenu.displayInvoices.text'),
    onClick: () =>
      customerActions?.onDisplayInvoices?.({
        customerId: customer.id,
        searchTerm: customer.companyName,
      }),
  };

  return isMobile
    ? [createInvoiceAction, editCustomerAction, displayInvoicesAction, deleteCustomerAction]
    : [createInvoiceAction, editCustomerAction, deleteCustomerAction];
};
