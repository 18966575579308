import { usePaymentsLateApprovalsEnabled } from '@melio/ap-domain';
import { useAnalyticsContext } from '@melio/platform-analytics';
import { Payment } from '@melio/platform-api';
import { useSearchParams } from 'react-router-dom';

import { createPaymentStatusCountMapObject } from '../../utils/payment-analytics.utils';

export const useApprovalsTabAnalytics = ({
  payments,
  areAllSelected,
}: {
  payments: Payment[];
  areAllSelected: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('search');
  const getPaymentsLateApprovalsEnabled = usePaymentsLateApprovalsEnabled();

  const paymentsLateApprovalsEnabled = getPaymentsLateApprovalsEnabled({ payments });
  const latePaymentCount = Object.entries(paymentsLateApprovalsEnabled).filter(
    ([, { isEnabled }]) => !!isEnabled
  ).length;

  useAnalyticsContext({
    globalProperties: {
      PageName: 'dashboard-approvals',
      EntryPoint: 'dashboard-approvals',
      Flow: 'dashboard',
      CountInTab: payments?.length,
      SearchValue: searchTerm,
      TPV: payments?.reduce((total, item) => total + item.amount, 0),
      Status: createPaymentStatusCountMapObject(payments),
      MultiSelection: areAllSelected,
      LatePayments: latePaymentCount,
    },
  });
};
