/* eslint-disable no-restricted-syntax */
import { getDefaultDeliveryPreferenceByDeliveryMethodType } from '@melio/ap-domain';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import {
  ApiError,
  BillSubscriptionErrorCode,
  DeliveryPreferenceType,
  ModelError,
  PaymentCreationError,
  PaymentErrorCode,
  Vendor,
} from '@melio/platform-api';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
  DeliverMethodChangedError,
  DeliveryMethodDetailsDiff,
  PaymentErrorDetails,
  UpdatedDeliveryMethodInfo,
} from '../types';
import { useChangeModalTableData } from './useChangeModalTableData';
import { useHeaderTexts } from './useHeaderText';

type PaymentSubmitError = {
  paymentData: PaymentErrorDetails;
  vendor?: Vendor;
  error: PaymentCreationError | ModelError | ApiError | undefined | null;
};

export function useDeliveryMethodChangedModal({
  paymentSubmitErrors,
  onSubmit,
}: {
  paymentSubmitErrors: Array<PaymentSubmitError>;
  onSubmit: (fieldValues: UpdatedDeliveryMethodInfo[]) => Promise<unknown>;
}) {
  const { track } = useAnalytics();

  const today = useMemo(() => new Date(), []);

  const [deliveryMethodChangedErrors, setDeliveryMethodChangedErrors] = useState<
    Array<PaymentSubmitError & { error: DeliverMethodChangedError }>
  >([]);

  useEffect(() => {
    const errors = paymentSubmitErrors
      .filter((x) => isDeliveryMethodChangedError(x.error))
      .map((x) => ({
        error: x.error as DeliverMethodChangedError,
        paymentData: x.paymentData,
        vendor: x.vendor,
      }));
    if (errors.length === 0) {
      return;
    }

    setDeliveryMethodChangedErrors(errors);
  }, [paymentSubmitErrors]);

  const getDeliveryMethodChangeDiffs = (
    deliveryMethodChangedErrors: Array<
      PaymentSubmitError & {
        error: DeliverMethodChangedError;
      }
    >
  ): DeliveryMethodDetailsDiff[] =>
    deliveryMethodChangedErrors
      .map((data) => {
        const { error, paymentData, vendor } = data;

        const [newDeliveryMethodDetails] = error.data;
        const { deliveryDate, deliveryMethodId, scheduleDate } = paymentData;
        const newDeliveryMethod = vendor?.deliveryMethods?.find(
          (method) => method.id === newDeliveryMethodDetails.dmId
        );
        const oldDeliveryMethod = vendor?.deliveryMethods?.find((method) => method.id === deliveryMethodId);

        if (!newDeliveryMethod || !oldDeliveryMethod) {
          return null;
        }
        const existingDeliveryDate = deliveryDate || today;
        const existingDebitByDate = scheduleDate || today;

        return {
          deliveryMethod: {
            old: oldDeliveryMethod,
            new: newDeliveryMethod,
          },
          deliveryByDate: {
            old: existingDeliveryDate,
            new: new Date(newDeliveryMethodDetails.deliveryDate || existingDeliveryDate),
          },
          debitByDate: {
            old: existingDebitByDate,
            new: new Date(newDeliveryMethodDetails.debitDate || existingDebitByDate),
          },
          paymentData,
          vendor,
          id: paymentData.id,
        };
      })
      .filter(Boolean) as DeliveryMethodDetailsDiff[];

  const deliveryMethodChangeDiffs = getDeliveryMethodChangeDiffs(deliveryMethodChangedErrors);
  const deliveryMethodChangeDiffsAnalytics = deliveryMethodChangeDiffs.map((diff) =>
    deliveryMethodChangeDiffs
      ? [
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          `${diff.deliveryMethod.old.details.deliveryType!}-to-${diff.deliveryMethod.new.details.deliveryType!}`,
          `${diff.deliveryByDate.old.toISOString()}-to-${diff.deliveryByDate.new.toISOString()}`,
          `${diff.debitByDate.old.toISOString()}-to-${diff.debitByDate.new.toISOString()}`,
        ]
      : undefined
  );

  useAnalyticsView('Payment', !isEmpty(deliveryMethodChangeDiffsAnalytics), true, {
    PageName: 'delivery-details-were-updated',
    UserMessage: 'vendor-delivery-method-unavailable',
    DeliveryMethodChanged: deliveryMethodChangeDiffsAnalytics,
  });

  const close = () => {
    track('Payment', 'Click', {
      Cta: 'confirm-and-pay',
      Intent: 'edit-payment-details',
      DeliveryMethodChanged: deliveryMethodChangeDiffsAnalytics,
    });

    setDeliveryMethodChangedErrors([]);
  };

  const confirm = () => {
    if (!deliveryMethodChangeDiffs.length) {
      return;
    }

    track('Payment', 'Click', {
      Cta: 'edit-payment-details',
      Intent: 'approve-delivery-details-update',
      DeliveryMethodChanged: deliveryMethodChangeDiffsAnalytics,
    });

    const newValues = deliveryMethodChangeDiffs.map((deliveryMethodChangeDiff) => ({
      deliveryMethodId: deliveryMethodChangeDiff.deliveryMethod.new.id,
      deliveryPreference:
        deliveryMethodChangeDiff.deliveryMethod.new.details.deliveryType === 'check'
          ? DeliveryPreferenceType.Check
          : getDefaultDeliveryPreferenceByDeliveryMethodType(deliveryMethodChangeDiff.deliveryMethod.new.type),
      scheduleDate: deliveryMethodChangeDiff.debitByDate.new,
      deliveryDate: deliveryMethodChangeDiff.deliveryByDate.new,
      startDate: deliveryMethodChangeDiff.deliveryByDate.new,
      id: deliveryMethodChangeDiff.id,
    }));

    const submitValues = newValues.map((values) => ({
      ...values,
      etaDetails: {
        deliveryDate: values.deliveryDate,
        scheduledDate: values.scheduleDate,
      },
    }));

    return onSubmit(submitValues).finally(close);
  };

  return {
    open: !!deliveryMethodChangedErrors.length,
    headers: useHeaderTexts(deliveryMethodChangeDiffs),
    deliveryMethodChangeTableData: useChangeModalTableData(deliveryMethodChangeDiffs),
    close,
    confirm,
  };
}

const isDeliveryMethodChangedError = (
  error?: PaymentCreationError | ModelError | ApiError | null
): error is DeliverMethodChangedError => {
  const deliveryMethodChangedErrors: string[] = [
    PaymentErrorCode.DeliveryMethodChanged,
    BillSubscriptionErrorCode.DeliveryMethodChangedForBillSubscription,
  ];

  return Boolean(
    error && 'errorCode' in error && error.errorCode && deliveryMethodChangedErrors.includes(error.errorCode)
  );
};
