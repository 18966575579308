import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const UploadIndicatorTheme: ComponentThemeType<{ show: boolean; minimize: boolean }> = (
  currentTheme,
  { show },
) => ({
  baseStyle: {
    uploadIndicator: {
      position: 'fixed',
      width: 440,
      bottom: 0,
      right: 47,
      display: show ? 'flex' : 'none',
      flexDirection: 'column',
      alignItems: 'start',
      backgroundColor: currentTheme.colors.global.neutral['100'],
      border: 'basic.light',
      borderRadius: '10px 10px 0 0',
      padding: 0,
      overflow: 'hidden',
      zIndex: 9999,
    },
  },
});

export const UploadIndicatorTitleTheme: ComponentThemeType = (currentTheme) => ({
  baseStyle: {
    uploadIndicatorTitle: {
      height: 56,
      width: '100%',
      padding: '0 17px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: currentTheme.colors.global.neutral['200'],

      p: {
        width: '100%',
      },
      button: {
        color: currentTheme.colors.global.neutral['1000'],
      },
    },
  },
});

export const UploadIndicatorItemTheme: ComponentThemeType<{ minimize: boolean }> = (currentTheme, { minimize }) => ({
  baseStyle: {
    uploadIndicatorItem: {
      position: 'relative',
      backgroundColor: currentTheme.colors.global.neutral['100'],
      width: '100%',
      height: 75,
      padding: '0 17px',
      display: minimize ? 'none' : 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      ':not(:last-of-type)': {
        borderBottom: 'basic.light',
      },
      span: {
        paddingRight: '80px',
      },
      text: {
        width: 'calc(100% - 44px)',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: '4px',
      },
      progressBar: {
        position: 'absolute',
        left: 0,
        bottom: -1,
        height: 2,
        width: '100%',
        background: `linear-gradient(270deg, ${currentTheme.colors.global.neutral['100']}, #7849ff, ${currentTheme.colors.global.neutral['100']})`,
        backgroundSize: '600%',
        animation: 'progressAnimation 2s ease infinite',
      },
      '@keyframes progressAnimation': {
        '0%': { backgroundPosition: '0% 50%' },
        '50%': { backgroundPosition: '100% 50%' },
        '100%': { backgroundPosition: '0% 50%' },
      },
    },
    uploadIndicatorIcon: {
      success: {
        color: 'global.success.900',
      },
      fail: {
        color: 'global.critical.700',
      },
      default: {
        color: 'global.neutral.1000',
      },
    },
  },
});
