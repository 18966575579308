import { FormWidgetProps, useMelioIntl } from '@melio/ar-domain';
import { Form, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';
import { object, SchemaOf, string } from 'yup';

import { CardHolderEmailDetails } from '../../../types';

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    email: string()
      .email(formatMessage('ar.guestPayment.activities.cardHolder.form.fields.email.valid.text'))
      .required(formatMessage('ar.guestPayment.activities.cardHolder.form.fields.email.required.text')),
  }) as SchemaOf<CardHolderEmailDetails>;
};

export type CardHolderEmailDetailsFormProps = FormWidgetProps<CardHolderEmailDetails> & {
  onEmailChange: (email: string) => void;
};

export const CardHolderEmailDetailsForm = forwardRef<CardHolderEmailDetailsFormProps, 'form'>(
  ({ onSubmit, onSubmissionStateChange, isSaving, onEmailChange, isLoading, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { formProps, registerField, watch } = useMelioForm<CardHolderEmailDetails>({
      onSubmit,
      schema: useSchema(),
      isSaving,
      onSubmissionStateChange,
    });

    const email = watch('email');
    useEffect(() => {
      onEmailChange(email);
    }, [email]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Form
        data-component="CardHolderEmailDetailsForm"
        data-testid="card-holder-email-details-form"
        {...props}
        {...formProps}
        columns={2}
        ref={ref}
      >
        <Form.TextField
          colSpan={2}
          labelProps={{ label: formatMessage('ar.guestPayment.activities.cardHolder.form.fields.email.label') }}
          isLoading={isLoading}
          {...registerField('email')}
        />
      </Form>
    );
  }
);
CardHolderEmailDetailsForm.displayName = 'CardHolderEmailDetailsForm';
