import { useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useAllowedMcc, useVendor, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { getVendorMissingDetailsList } from '../../BatchPayments/screens/PaymentIntentsTable/PaymentIntentsTable.screen.analytics-utils';
import { VendorDetailsModalState } from './components/types';
import { VendorDetailsModalScreen, VendorDetailsModalScreenProps } from './screens';

export type VendorDetailsModalActivityProps = {
  vendorDetailsModalState: VendorDetailsModalState;
  onDone: VoidFunction;
  onClose: VoidFunction;
  onError?: ErrorFunction;
  vendorId: Vendor['id'];
  onLoadingVendorDetailsFailure: VoidFunction;
};

export const VendorDetailsModalActivity = ({
  vendorDetailsModalState,
  onClose,
  onError,
  onDone,
  vendorId,
  onLoadingVendorDetailsFailure,
}: VendorDetailsModalActivityProps) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();
  const vendor = useVendor({ id: vendorId });
  const { data: mccIndustryList = [], isLoading: isLoadingMccList } = useAllowedMcc({
    params: { cardNetwork: vendorDetailsModalState.cardNetwork },
    enabled: vendorDetailsModalState.isOpen,
    onError: () => {
      onLoadingVendorDetailsFailure();
      onClose();
    },
  });

  const vendorName = vendor.data?.name || '';

  const handleUpdateFailure = (error: PlatformError) => {
    const formattedMessage = formatMessage('activities.fundingSources.vendorDetailsForm.toast.error');
    toast({ type: 'error', title: formattedMessage });
    onError?.(error);

    track('PaymentMethodOptions', 'Status', {
      PageName: 'add-vendor-details',
      CardNetwork: vendorDetailsModalState.cardNetwork,
      DetailsToFill: getVendorMissingDetailsList(vendorDetailsModalState.requiredFormFields),
      Intent: 'add-vendor-business-details',
      ErrorType: "vendor-details-didn't-save",
      Status: 'failure',
    });
  };

  const handleUpdateSuccess = () => {
    const formattedMessage = formatMessage('activities.fundingSources.vendorDetailsForm.toast.success');
    toast({ type: 'success', title: formattedMessage });
    onDone();

    track('PaymentMethodOptions', 'Status', {
      PageName: 'add-vendor-details',
      CardNetwork: vendorDetailsModalState.cardNetwork,
      DetailsToFill: getVendorMissingDetailsList(vendorDetailsModalState.requiredFormFields),
      Intent: 'add-vendor-business-details',
      Status: 'success',
    });
  };

  const updateVendorDetails: VendorDetailsModalScreenProps['onDone'] = (data) => {
    const { mcc, email, phone: phoneNumber } = data;
    const { line1, city, countryCode, postalCode, state } = data.address;
    const isValidAddress = !!line1 && !!city && !!postalCode && !!countryCode;

    const traits = {
      ...(mcc ? { mcc } : {}),
    };

    const contact = {
      ...(email ? { email } : {}),
      ...(phoneNumber ? { phoneNumber } : {}),
      ...(isValidAddress
        ? {
            address: {
              line1,
              city,
              state,
              countryCode,
              postalCode,
            },
          }
        : {}),
    };

    vendor
      .update({
        ...(mcc ? { traits } : {}),
        ...(email || phoneNumber || isValidAddress ? { contact } : {}),
      })
      .then(handleUpdateSuccess)
      .catch(handleUpdateFailure);
  };

  const isLoading = isLoadingMccList || vendor.isMutating;

  return (
    <VendorDetailsModalScreen
      vendorDetailsModalState={vendorDetailsModalState}
      isLoading={isLoading}
      isSaving={vendor.isMutating}
      mccIndustryList={mccIndustryList}
      vendorName={vendorName}
      onDone={updateVendorDetails}
      onClose={onClose}
    />
  );
};
