import { FundingSourcesApiClient, PayorApiClient } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

export type UseGuestPayorFundingSourceProps = UseModelProps<
  typeof FundingSourcesApiClient.getFundingSourcesFundingSourceId,
  typeof FundingSourcesApiClient.patchFundingSourcesFundingSourceId,
  typeof FundingSourcesApiClient.postFundingSources
>;

export const useGuestPayorFundingSource = (props: UseGuestPayorFundingSourceProps = {}) => {
  const query = useModel({
    ...props,
    queryKey: 'FundingSourcesApi',
    queryFn: FundingSourcesApiClient.getFundingSourcesFundingSourceId,
    createFn: PayorApiClient.postPayorFundingSource,
    deleteFn: FundingSourcesApiClient.deleteFundingSourcesFundingSourceId,
    updateFn: FundingSourcesApiClient.patchFundingSourcesFundingSourceId,
  });

  return {
    ...query,
  };
};
