import { InvoicesApiClient } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';
import { PostInvoiceSummaryRequest } from './types';

const postInvoiceSummary = (_id: string, args?: PostInvoiceSummaryRequest) =>
  InvoicesApiClient.postRawSummaryInvoice(args);

export type UseInvoiceSummaryProps = Omit<UseModelProps<typeof postInvoiceSummary>, 'id'>;

export const useInvoiceSummary = ({
  params,
  enabled = !!(params?.discount || params?.taxRate || params?.serviceCharges?.length || params?.lineItems?.length),
  ...props
}: UseInvoiceSummaryProps = {}) =>
  useModel({
    ...props,
    enabled,
    id: 'postRawSummaryInvoice',
    queryKey: 'InvoicesApi',
    params,
    queryFn: postInvoiceSummary,
    keepPreviousData: true,
  });

export type InvoiceSummaryModel = ReturnType<typeof useInvoiceSummary>;
