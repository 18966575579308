import { StatusModal, Text } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useDeleteVendorGroup, useVendorGroups } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { SystemMessageDisplay, useSystemMessage } from '@melio/platform-utils';
import { useEffect } from 'react';

import { DeleteVendorsGroupModalProps } from './types';

export const DeleteVendorsGroupModal = withAnalyticsContext<DeleteVendorsGroupModalProps>(
  ({ isOpen, onClose, vendorsGroupId, onSuccess, setAnalyticsProperties }) => {
    const { formatMessage } = useMelioIntl();
    const { showMessage } = useSystemMessage();
    const { track } = useAnalytics();
    const { mutateAsync: deleteVendorsGroup, isLoading: isDeletingVendorsGroup } = useDeleteVendorGroup();
    const { data: vendorGroups, isLoading: isLoadingVendorsGroup } = useVendorGroups();

    const vendorsGroup = vendorGroups?.find(({ id }) => id === vendorsGroupId);

    setAnalyticsProperties({ PageName: 'delete-group', Intent: 'delete-vendor-groups', Flow: 'vendor-groups' });

    useEffect(() => {
      if (isLoadingVendorsGroup) {
        return;
      }
      track('DeleteVendorGroup', 'View');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingVendorsGroup]);

    const handleSubmit = async () => {
      try {
        track('DeleteVendorGroup', 'Click', { Cta: 'delete' });

        await deleteVendorsGroup({ vendorGroupId: vendorsGroupId });
        showMessage({
          title: formatMessage('activities.deleteVendorsGroupModal.toasts.success', { name: vendorsGroup?.name }),
          type: 'success',
          dataTestId: 'delete-vendors-group-success',
        });
        onSuccess();
      } catch (e) {
        showMessage({
          title: formatMessage('activities.deleteVendorsGroupModal.toasts.error', { name: vendorsGroup?.name }),
          type: 'error',
          dataTestId: 'delete-vendors-group-error',
        });
      }
    };

    const handleClose = () => {
      track('DeleteVendorGroup', 'Click', { Cta: 'exit' });
      onClose();
    };

    const handleCancel = () => {
      track('DeleteVendorGroup', 'Click', { Cta: 'cancel' });
      onClose();
    };

    return (
      <StatusModal
        isOpen={isOpen}
        isLoading={isLoadingVendorsGroup}
        variant="cancel"
        header={formatMessage('activities.deleteVendorsGroupModal.header')}
        primaryButton={{
          label: formatMessage('activities.deleteVendorsGroupModal.buttons.primary'),
          onClick: handleSubmit,
          variant: 'primary',
          isLoading: isDeletingVendorsGroup,
        }}
        secondaryButton={{
          label: formatMessage('activities.createVendorsGroupModal.buttons.secondary'),
          variant: 'secondary',
          onClick: handleCancel,
        }}
        onClose={handleClose}
        data-testid="delete-vendors-group-modal"
      >
        <SystemMessageDisplay data-testid="vendors-group-delete-modal-message" />
        <Text textStyle="body2">
          {formatMessage('activities.deleteVendorsGroupModal.description', { name: vendorsGroup?.name })}
        </Text>
      </StatusModal>
    );
  }
);
