import { SubscriptionBillingPayor } from '../../types';

export type AssignFirmClientPlanFormFields = {
  whoPays: SubscriptionBillingPayor;
  fundingSourceId: string;
  subscriptionPlanId?: string;
  organizationId: string;
  subscriptionPromoCode?: string;
};

export enum AssignFirmClientPlanFormStep {
  BillingDetails = 'billing-details',
  PlanSelection = 'plan-selection',
  ReviewAndConfirm = 'review-and-confirm',
}

export type AssignFirmClientPlanFormProps = {
  submitLabel: string;
  focusErrorStep: (error: boolean) => void;
  onContinue: () => void;
  isSaving: boolean;
};

export type AssignFirmClientPlanFormStepComponent = React.FC<AssignFirmClientPlanFormProps>;

export type AssignFirmClientPlanFormStepDetails = {
  title: string;
  description: string;
  stepId: AssignFirmClientPlanFormStep;
  component: AssignFirmClientPlanFormStepComponent;
};

export type AssignFirmClientPlanFormField = keyof AssignFirmClientPlanFormFields;
