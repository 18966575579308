import { useSupportedFXData } from '@melio/ap-domain';
import { AmountField, Container, FlagIcon, FloatingMenu, Group, Icon, Loader, Text, Tooltip } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { CurrencyDetails, currencyDictionary, defaultCurrencyDetails, getSupportedCurrenciesDefs } from './utils';

type CurrencySwitcherProps = {
  isDisabled?: boolean;
  selectedCurrency?: CurrencyDetails;
  setSelectedCurrency?: (currency?: string) => void;
  tooltipLabel?: string;
  onHover?: () => void;
  onOpen?: VoidFunction;
};

export const CurrencySwitcher = ({
  isDisabled = false,
  selectedCurrency = defaultCurrencyDetails,
  setSelectedCurrency,
  tooltipLabel,
  onHover,
  onOpen,
}: CurrencySwitcherProps) => {
  const { currencies, isLoading } = useSupportedFXData({});
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useMelioIntl();

  const supportedCurrenciesDefs = getSupportedCurrenciesDefs(currencyDictionary, ['USD', ...currencies]);

  const handleOpen = (isDropdownOpen: boolean) => {
    setIsOpen(isDropdownOpen);
    if (isDropdownOpen && onOpen) {
      onOpen();
    }
  };

  const triggerElement = isLoading ? (
    <Container alignItems="center" paddingX="s" justifyContent="center">
      <Loader />
    </Container>
  ) : (
    <AmountField.EndElement isDisabled={isDisabled}>
      <Container
        data-disabled={isDisabled || undefined}
        data-testid="currency-switcher"
        alignItems="center"
        paddingX="s"
        height="full"
        onTouchStart={onHover}
        onMouseEnter={onHover}
        backgroundColor={isDisabled ? 'default' : 'white'}
        style={{ borderTopRightRadius: '7px', borderBottomRightRadius: '7px' }}
        color={isDisabled ? 'global.neutral.600' : undefined}
      >
        <Group width="full" alignItems="center" spacing="xxs">
          <FlagIcon isDisabled={isDisabled} countryCode={selectedCurrency.flag} size="small" />
          <Text color={isDisabled ? 'global.neutral.600' : 'global.neutral.800'}>{selectedCurrency.currencyCode}</Text>
          <Group.Item grow={1} />
          <Icon type={isOpen ? 'caret-up' : 'caret-down'} size="small" color="inherit" />
        </Group>
      </Container>
    </AmountField.EndElement>
  );

  return (
    <FloatingMenu
      maxHeight={256}
      width={306}
      isOpen={isOpen}
      isDisabled={isDisabled}
      onOpenChange={handleOpen}
      trigger={tooltipLabel ? <Tooltip content={tooltipLabel}>{triggerElement}</Tooltip> : triggerElement}
      content={
        <FloatingMenu.DropdownList paddingY="xs">
          {supportedCurrenciesDefs.map((currency) => (
            <FloatingMenu.SelectionItem
              key={currency?.currencyCode}
              data-testId={`currency-switcher-${currency.currencyCode}`}
              isSelected={selectedCurrency?.currencyCode === currency?.currencyCode}
              onClick={() => {
                setSelectedCurrency?.(currency?.currencyCode);
              }}
            >
              <Group alignItems="center" spacing="xs-s">
                <FlagIcon countryCode={currency.flag} size="small" />
                <Group alignItems="center" spacing="none">
                  <Text textStyle="body3Semi">{currency.currencyCode}&nbsp;</Text>
                  <Text textStyle="body3">- {formatMessage(currency.label)}</Text>
                </Group>
              </Group>
            </FloatingMenu.SelectionItem>
          ))}
        </FloatingMenu.DropdownList>
      }
    />
  );
};
