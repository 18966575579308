import { useGuestPayorOnboarding } from '@melio/ar-domain';
import { useEffect, VFC } from 'react';

type OnboardingActivityProps = {
  paymentRequestLink: string;
  email: string;
  onDone: (vendorId: string, isMissingMcc: boolean) => void;
  onError?: ARErrorFunction;
  onClose?: VoidFunction;
};

export const OnboardingActivity: VFC<OnboardingActivityProps> = ({
  paymentRequestLink,
  email,
  onDone,
  onError,
  onClose,
}) => {
  const { data, error } = useGuestPayorOnboarding({ id: paymentRequestLink, email });

  useEffect(() => {
    if (data) onDone(data.vendorId, data.isMissingMcc);
    else if (error) {
      onClose?.();
      onError?.(error);
    }
  }, [data, error]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

OnboardingActivity.displayName = 'OnboardingActivity';
