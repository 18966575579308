import { Button as ChakraButton } from '@chakra-ui/react';
import { FloatingMenu, Group, Icon, Text, useTheme } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo, useState } from 'react';

import { CompanyAvatarWidget } from '../CompanyAvatar';
import { Account, sortAccounts } from './AccountSwitcher.widgets.utils';

export type AccountSwitcherWidgetsProps = {
  data: Account[];
  selectedId: Account['id'];
  onSelect: (account: Account) => void;
  defaultIsOpen?: boolean;
};

export const AccountSwitcherWidget = ({
  data,
  selectedId,
  onSelect,
  defaultIsOpen,
  ...props
}: AccountSwitcherWidgetsProps) => {
  const { formatMessage } = useMelioIntl();

  const [isOpen, setIsOpen] = useState<boolean>(!!defaultIsOpen);

  const selectedItem = data.find((item) => item.id === selectedId);
  const theme = useTheme();

  const sortedAccounts = useMemo(() => sortAccounts(data), [data]);

  const trigger = (
    <ChakraButton
      data-testid="select-company-btn"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      sx={{
        ...theme.textStyles.body4Semi,
        maxWidth: 'inherit',
        borderRadius: theme.borderRadii.global['200'],
        padding: '12px 16px',
        _hover: { backgroundColor: theme.colors.global.neutral['200'] },
        _expanded: { backgroundColor: theme.colors.global.neutral['300'] },
        _focus: { outlineColor: theme.colors.global.informative['700'], outlineOffset: 0 },
      }}
    >
      <Group alignItems="center" spacing="s">
        {selectedItem?.company.name && (
          <CompanyAvatarWidget
            name={selectedItem?.company.name}
            src={selectedItem?.logoUrl}
            isSelected={selectedItem.id === selectedId}
          />
        )}
        {selectedItem?.company.name && (
          <Text color="inherit" textStyle="inline" shouldSupportEllipsis>
            {selectedItem?.company.name}
          </Text>
        )}
        {data.length > 1 && <Icon type={isOpen ? 'caret-up' : 'caret-down'} size="small" color="default" />}
      </Group>
    </ChakraButton>
  );

  const header = (
    <FloatingMenu.Header>
      <Text textStyle="body4SemiUpper" color="global.neutral.900">
        {formatMessage('widgets.accountSwitcher.menuList.title')}
      </Text>
    </FloatingMenu.Header>
  );

  return (
    <FloatingMenu
      data-component="AccountSwitcherWidget"
      data-testid="account-switcher-dropdown"
      {...props}
      trigger={trigger}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      header={header}
      content={
        <FloatingMenu.DropdownList paddingY="xs">
          {sortedAccounts.map((item, index) => (
            <FloatingMenu.Item
              key={index}
              onClick={() => {
                onSelect(item);
                setIsOpen(false);
              }}
              data-testid={`account-switcher-item-${item.id}`}
            >
              <Group alignItems="center" spacing="s">
                {item?.company.name && (
                  <CompanyAvatarWidget
                    name={item.company.name}
                    src={item?.logoUrl}
                    isSelected={item.id === selectedId}
                  />
                )}
                {item.company.name && (
                  <Text textStyle="inline" shouldSupportEllipsis data-testid="account-switcher-item-name">
                    {item.company.name}
                  </Text>
                )}
              </Group>
            </FloatingMenu.Item>
          ))}
        </FloatingMenu.DropdownList>
      }
      width="310px"
    />
  );
};

AccountSwitcherWidget.displayName = 'AccountSwitcherWidget';
