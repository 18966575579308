import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useRef } from 'react';

import { OnBeforeDone, OnBeforeDoneOptions } from '../types';

type UseShareTestInvoiceDialogActionsProps = {
  onOpen?: VoidFunction;
  onClose?: VoidFunction;
  onDone?: VoidFunction;
};

// this hook shares the "onDone" and "onClose" functions between IssueInvoiceActivity and ShareTestInvoiceModalActivity
// we need to get those from IssueInvoiceActivity using `onShareTestInvoice` and pass them to ShareTestInvoiceModalActivity
// we use a ref and not a state in order to avoid re-rendering the component
export const useShareTestInvoiceDialogActions = ({
  onOpen,
  onClose,
  onDone,
}: UseShareTestInvoiceDialogActionsProps = {}) => {
  const disclosure = useDisclosure({ onOpen, onClose });

  const onShareTestInvoiceRef = useRef<OnBeforeDoneOptions>();

  const handleOpenShareTestInvoiceModal: OnBeforeDone = useCallback(async (onDone, onClose) => {
    onShareTestInvoiceRef.current = { onDone, onClose };
    disclosure.onOpen();
    return Promise.resolve();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const shareTestInvoice = useCallback(async () => {
    onCloseShareTestInvoiceModal();
    await onShareTestInvoiceRef.current?.onDone();
    onDone?.();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onCloseShareTestInvoiceModal = useCallback(() => {
    onShareTestInvoiceRef.current?.onClose?.();
    disclosure.onClose();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    onDone: shareTestInvoice,
    onClose: onCloseShareTestInvoiceModal,
    onOpen: handleOpenShareTestInvoiceModal,
    isOpen: disclosure.isOpen,
  };
};
