import { Button, LoadingContainer } from '@melio/penny';
import { useOrganizations } from '@melio/platform-api';
import { OrganizationExpanded } from '@melio/platform-api/src/entities-v2/organizations/types';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSearchParams } from 'react-router-dom';

import { FullScreenLayout } from '../../components/FullScreenLayout/FullScreenLayout';
import { useCanSwitch } from '../../hooks/useCanSwitch';
import { useAccountantsRoutes } from '../../utils/useAccountantsRoutes';

const MessageType = {
  failedToAddClient: 'failedToAddClient',
  inviteSent: 'inviteSent',
  newClientCreated: 'newClientCreated',
} as const;

const useGetMessageType = (organization?: OrganizationExpanded | null) => {
  const { canSwitch } = useCanSwitch();
  if (!organization) {
    return MessageType.failedToAddClient;
  }
  if (canSwitch({ permission: organization.permissionLevel })) {
    return MessageType.newClientCreated;
  }
  return MessageType.inviteSent;
};

const illustrationMap = {
  [MessageType.failedToAddClient]: 'network-error',
  [MessageType.inviteSent]: 'sent',
  [MessageType.newClientCreated]: 'vendor-add',
};

type Props = {
  onClose: VoidFunction;
};

export const AccountingSoftwareRedirectActivity = ({ onClose }: Props) => {
  const { formatMessage } = useMelioIntl();
  const [searchParams] = useSearchParams();
  const organizationId = searchParams.get('orgId') || '';
  const { goToAssignClientPlan } = useAccountantsRoutes();
  const { data: organizations = [], isLoading } = useOrganizations();
  const connectedOrg = organizationId ? organizations.find((org) => org.id === organizationId) : null;
  const messageType = useGetMessageType(connectedOrg);
  const onClick =
    messageType === MessageType.newClientCreated
      ? () => goToAssignClientPlan({ accountingClientId: organizationId })
      : onClose;
  return (
    <LoadingContainer isLoading={isLoading}>
      <FullScreenLayout
        title={formatMessage(`activities.accountants.AccountingSoftwareRedirect.${messageType}.title`)}
        content={formatMessage(`activities.accountants.AccountingSoftwareRedirect.${messageType}.content`, {
          clientCompanyName: connectedOrg?.name,
        })}
        illustration={illustrationMap[messageType]}
        actions={
          <>
            <Button
              isFullWidth={false}
              data-testid="client-created-actions-primary"
              variant="primary"
              label={formatMessage(`activities.accountants.AccountingSoftwareRedirect.${messageType}.redirect.label`)}
              onClick={onClick}
            />
          </>
        }
      />
    </LoadingContainer>
  );
};
