import { Button, Group, NakedButton, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { PageTitle } from '@melio/platform-utils';
import { useEffect } from 'react';

type Props = {
  title: string;
  description?: string;
  onNext: () => void;
  children: React.ReactNode;
  nextButtonLabel: string;
  isNextDisabled?: boolean;
  onSkip?: () => void;
  onLoad?: () => void;
};
export const WizardStep = ({
  title,
  description,
  onNext,
  nextButtonLabel,
  isNextDisabled = false,
  onSkip,
  onLoad,
  children,
}: Props) => {
  useEffect(() => {
    onLoad?.();
  }, [onLoad]);

  const { formatMessage } = useMelioIntl();

  return (
    <Group alignItems="stretch" variant="vertical" spacing="xl">
      <Group textAlign="center" alignItems="stretch" variant="vertical" spacing="l">
        <Group.Item>
          <PageTitle textStyle="heading1Semi">{title}</PageTitle>
        </Group.Item>
        {description && (
          <Group.Item>
            <Text textStyle="body2">{description}</Text>
          </Group.Item>
        )}
      </Group>
      <Group.Item>{children}</Group.Item>
      <Group alignItems="stretch" variant="vertical">
        <Group.Item alignSelf="center">
          <Button
            data-testid="billing-fee-funding-source-submit-button"
            isFullWidth={false}
            isDisabled={isNextDisabled}
            label={nextButtonLabel}
            onClick={onNext}
          />
        </Group.Item>
        {onSkip && (
          <Group.Item alignSelf="center">
            <NakedButton
              data-testid="billing-fee-settings-skip-button"
              label={formatMessage('activities.accountants.billingSettings.clientSelection.button.skip')}
              onClick={onSkip}
            />
          </Group.Item>
        )}
      </Group>
    </Group>
  );
};
