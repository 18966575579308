import { Group, SectionBanner, StatusModal, Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { DeliveryMethodChangeTable } from './DeliveryMethodChangeTable';
import { DeliveryMethodChangeTableData } from './types';

type Props = {
  isOpen: boolean;
  isSubmitting: boolean;
  onClose: VoidFunction;
  onSubmit: VoidFunction;
  headers: string[];
  deliveryMethodChangeTableData: DeliveryMethodChangeTableData[];
};

const SectionBannerDescription = ({ headers }: { headers: string[] }) => {
  if (headers.length === 1) {
    return <>{headers[0]}</>;
  }

  return <Typography.ParagraphList list={headers.map((h) => ({ content: h }))} type="unordered" />;
};
export const DeliveryMethodChangedModal = ({
  isOpen,
  onClose,
  onSubmit,
  headers,
  isSubmitting,
  deliveryMethodChangeTableData,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  return (
    <StatusModal
      aria-modal={false}
      size="medium"
      data-testid="delivery-method-changed-modal"
      header={formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.header')}
      variant="warning"
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={{
        isLoading: isSubmitting,
        label: formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.confirmChange'),
        onClick: onSubmit,
        variant: 'primary',
      }}
      secondaryButton={{
        isDisabled: isSubmitting,
        label: formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.editPayment'),
        onClick: onClose,
        variant: 'tertiary',
      }}
    >
      <Group variant="vertical" spacing="m">
        <Group variant="vertical">
          <SectionBanner description={<SectionBannerDescription headers={headers} />} variant="warning" hideIcon />
        </Group>
        {deliveryMethodChangeTableData.length > 0 && (
          <DeliveryMethodChangeTable deliveryMethodChangeTableData={deliveryMethodChangeTableData} />
        )}
      </Group>
    </StatusModal>
  );
};
