import { FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Link, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useConfig } from '@melio/platform-provider';

export const LegalDisclaimer = () => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const { privacyPolicyUrl, termsOfServiceUrl } = useConfig().settings;

  return (
    <Text textStyle="body4" color="global.neutral.800">
      <FormattedMessage
        id="ar.guestPayment.payment.termsAndPolicyDisclaimer.text"
        values={{
          termsOfService: (
            <Link
              color="secondary"
              href={termsOfServiceUrl}
              label={formatMessage('ar.guestPayment.payment.termsAndPolicyDisclaimer.terms.label')}
              newTab
              onClick={() => track('PaymentRequest', 'Click', { Cta: 'terms-of-service' })}
            />
          ),
          privacyPolicy: (
            <Link
              color="secondary"
              href={privacyPolicyUrl}
              label={formatMessage('ar.guestPayment.payment.termsAndPolicyDisclaimer.privacyPolicy.label')}
              newTab
              onClick={() => track('PaymentRequest', 'Click', { Cta: 'privacy-policy' })}
            />
          ),
        }}
      />
    </Text>
  );
};
