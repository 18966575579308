import { useLocation } from '@melio/platform-utils';
import { useSearchParams } from 'react-router-dom';

import { SubscriptionBillingCycleEnum } from '../api';
import { usePlansTiers } from './usePlansTiers';

export const useWizardParams = () => {
  const { getFirstPaidPlan } = usePlansTiers();

  const firstPaidPlan = getFirstPaidPlan();
  const { state } = useLocation<{
    returnUrl?: string;
    utm_promo?: string;
    billingCycle?: SubscriptionBillingCycleEnum;
  }>();
  const [searchParams] = useSearchParams();

  const { returnUrl, utm_promo: promoCodeState, billingCycle: billingCycleFromState } = state || {};
  const planId = searchParams.get('plan') || firstPaidPlan?.id;
  const promoCodeQueryString = searchParams.get('utm_promo') || promoCodeState;

  const getBillingCycle = () => {
    const billingCycleFromQueryString = searchParams.get('billingCycle');

    if (billingCycleFromQueryString === SubscriptionBillingCycleEnum.Monthly) {
      return SubscriptionBillingCycleEnum.Monthly;
    }
    if (billingCycleFromQueryString === SubscriptionBillingCycleEnum.Annual) {
      return SubscriptionBillingCycleEnum.Annual;
    }

    return billingCycleFromState;
  };

  return {
    returnUrl,
    planId,
    promoCodeQueryString,
    billingCycle: getBillingCycle(),
  };
};
