import { UploadFilesWidget } from '@melio/ap-widgets';
import { Container, Icon, Link, Loader, SectionBanner, StatusIconSolid } from '@melio/penny';
import { uploadFiles, useAttachRequestedDocuments, usePaymentRequiredDocuments } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useRef, useState } from 'react';

import { DocumentsUploadIndicator } from '../upload-indication/DocumentsUploadIndicator.component';
import { UploadFile } from './types';

type PaymentPendingVerificationAlertType = {
  paymentId: string;
};

export const PaymentPendingVerificationAlert = ({ paymentId }: PaymentPendingVerificationAlertType) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();
  const [showUploadIndicator, setShowUploadIndicator] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState<UploadFile[]>([]);
  const { isLoading, data } = usePaymentRequiredDocuments(paymentId);
  const { mutateAsync: attachRequestedDocuments } = useAttachRequestedDocuments(paymentId);

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  const numOfUploadedFiles = data.files?.length ?? 0;
  const isFileAlreadyUploaded = numOfUploadedFiles > 0;
  const documentsNames = data?.requiredDocs ?? [];

  const alertDescription = isFileAlreadyUploaded
    ? formatMessage('widgets.paymentDetails.pendingVerification.receivedFiles.description')
    : formatMessage('widgets.paymentDetails.pendingVerification.actionRequired.description');

  const alertTitle = isFileAlreadyUploaded
    ? formatMessage('widgets.paymentDetails.pendingVerification.receivedFiles.title', {
        filesNumber: numOfUploadedFiles,
      })
    : formatMessage('widgets.paymentDetails.approvalPending.actionRequired.title');

  const onUploadFiles = async (files: File[]) => {
    if (!data.requestId) {
      return;
    }
    setShowUploadIndicator(true);
    setUploadingFiles(files.map((file) => ({ ...file, name: file.name, size: file.size, status: 'processing' })));
    try {
      const fileInfos = await uploadFiles(files);
      const uploadedFiles = await attachRequestedDocuments({ requestId: data.requestId, fileInfos });

      setUploadingFiles(fileInfos.map((f) => ({ ...f, name: f.fileName || '', status: 'success' })));
      showMessage({
        title: formatMessage('widgets.paymentDetails.approvalPending.fileUploaded.title', {
          numOfFiles: uploadedFiles.fileIds?.length ?? 0,
        }),
        type: 'success',
      });
    } catch (err) {
      setUploadingFiles(files.map((file) => ({ ...file, name: file.name, status: 'error' })));
      showMessage({
        title: formatMessage('widgets.paymentDetails.approvalPending.fileUploadFailed'),
        type: 'error',
      });
    }
  };

  return (
    <>
      <UploadFilesWidget onUploadFiles={onUploadFiles} ref={fileInput} />
      <DocumentsUploadIndicator showUploadIndicator={showUploadIndicator} files={uploadingFiles} />
      <SectionBanner
        data-testid="payment-pending-verification-alert"
        variant={isFileAlreadyUploaded ? 'neutral' : 'warning'}
        description={
          <>
            {alertDescription}
            <Container paddingX="m" paddingY="xxs">
              <ol data-testid="payment-pending-verification-documents-list">
                {documentsNames.map((name, idx) => (
                  <li key={idx}>{name}</li>
                ))}
              </ol>
            </Container>
            <Link
              data-testid="payment-pending-verification-upload-documents-button"
              href="#"
              label={formatMessage('widgets.paymentDetails.pendingVerification.actionRequired.upload.button')}
              onClick={() => fileInput.current?.click()}
            />
          </>
        }
        title={alertTitle}
        leftElement={
          isFileAlreadyUploaded ? (
            <Icon type="info-fill" aria-hidden />
          ) : (
            <StatusIconSolid variant="warning" size="medium" aria-hidden />
          )
        }
      />
    </>
  );
};
