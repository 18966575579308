import { forwardRef } from '@melio/platform-utils';

import { ReviewAndApproveScreen } from './screens';

type ReviewAndApproveActivityProps = {
  onBack: VoidFunction;
  onClose: VoidFunction;
};

export const ReviewAndApproveActivity = forwardRef<ReviewAndApproveActivityProps>((props, ref) => (
  <ReviewAndApproveScreen {...props} ref={ref} />
));
