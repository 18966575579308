import { isEmpty } from 'lodash';

import { VendorDetailsFormFields } from '@/types/vendors.types';

export const getUpdateVendorPayload = (data: VendorDetailsFormFields) => {
  const { companyName, nickname, accountNumber, uniqueName } = data;

  return {
    name: companyName || '',
    nickname: nickname || '',
    accountNumber: accountNumber || '',
    uniqueName: !isEmpty(uniqueName) ? uniqueName : undefined,
  };
};
