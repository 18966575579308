import { usePaymentProcessingInfo, useSectionBadge } from '@melio/ap-domain';
import { Container, Group, ListItem, NakedButton } from '@melio/penny';
import {
  BankAccountDeliveryMethod,
  BillerAccountDeliveryMethod,
  DebitDeliveryMethod,
  DeliveryMethodType,
  DeliveryPreference,
  DomesticWireDeliveryMethod,
  InternationalAccountDeliveryMethod,
  ManagedAccountDeliveryMethod,
  PaperCheckDeliveryMethod,
  PaypalBalanceAccountDeliveryMethod,
  Vendor,
  VirtualCardAccountDeliveryMethod,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import {
  getCanEditDeliveryMethod,
  getDeliveryMethodHelperText,
  getDeliveryMethodIcon,
  getDeliveryMethodLabelMessageKey,
  getMainLabelProperties,
  getTooltipMessageKey,
} from './utils';

export type DeliveryMethodListItemByPayorWidgetProps = {
  deliveryMethod:
    | BankAccountDeliveryMethod
    | DomesticWireDeliveryMethod
    | PaperCheckDeliveryMethod
    | DebitDeliveryMethod
    | InternationalAccountDeliveryMethod
    | VirtualCardAccountDeliveryMethod
    | BillerAccountDeliveryMethod
    | ManagedAccountDeliveryMethod
    | PaypalBalanceAccountDeliveryMethod;
  deliveryPreference: DeliveryPreference;
  vendorName?: Vendor['name'];
  countryDisplayName?: string;
  onEdit?: VoidFunction;
  showUpdatedBadge?: boolean;
};
export const DeliveryMethodListItemByPayorWidget = forwardRef<DeliveryMethodListItemByPayorWidgetProps, 'div'>(
  ({ deliveryMethod, vendorName, countryDisplayName, onEdit, showUpdatedBadge, deliveryPreference, ...props }, ref) => {
    const { formatMessage, formatDate } = useMelioIntl();
    const { settings } = useConfig();
    const { calculateExpiryDate } = usePaymentProcessingInfo();
    const getFormattedExpiryDate = () => {
      if (deliveryMethod.type !== DeliveryMethodType.VirtualCard) {
        return undefined;
      }
      const expiryDate = calculateExpiryDate(deliveryPreference.effectiveScheduleDate);
      return formatDate(expiryDate, { day: 'numeric', month: 'short' });
    };

    const mainLabelProperties = getMainLabelProperties({ deliveryMethod, vendorName });
    const labelMessageKey = getDeliveryMethodLabelMessageKey({
      deliveryMethod,
      deliveryPreferenceType: deliveryPreference.type,
    });
    const helperText = getDeliveryMethodHelperText({
      deliveryMethod,
      virtualCardExpiryDateFormatted: getFormattedExpiryDate(),
      countryDisplayName,
    });
    const tooltipMessageKey = getTooltipMessageKey(deliveryMethod);
    const canEditDeliveryMethod = onEdit && getCanEditDeliveryMethod(deliveryMethod);

    const updateBadge = useSectionBadge({
      isShowUpdatedBadge: !!showUpdatedBadge,
    });

    return (
      <Group>
        <Group.Item shrink={0}>
          <Container paddingY="xs">
            {getDeliveryMethodIcon(deliveryMethod.type, settings.icons.deliveryMethod)}
          </Container>
        </Group.Item>
        <Group.Item grow={1}>
          <Container>
            <ListItem
              data-testid="delivery-method-list-item-by-payor"
              data-component="DeliveryMethodListItemByPayorWidget"
              mainLabelProps={{
                pillProps: updateBadge ? [updateBadge] : [],
                label: mainLabelProperties
                  ? formatMessage(mainLabelProperties.messageKey, mainLabelProperties?.values)
                  : '',
                ...(tooltipMessageKey &&
                  formatMessage(tooltipMessageKey) && { tooltipProps: { content: formatMessage(tooltipMessageKey) } }),
              }}
              {...(labelMessageKey && { labelProps: { label: formatMessage(labelMessageKey) } })}
              {...(helperText && {
                descriptionProps: {
                  label: formatMessage(helperText.messageKey, helperText.values),
                },
              })}
              {...props}
              ref={ref}
            />
          </Container>
        </Group.Item>
        {canEditDeliveryMethod && (
          <Container overflow="visible" width="fit-content" alignItems="flex-start">
            <NakedButton
              variant="secondary"
              onClick={onEdit}
              data-testid="delivery-method-list-item-by-payor-edit-button"
              label={formatMessage('widgets.deliveryMethodListItemByPayor.edit')}
            />
          </Container>
        )}
      </Group>
    );
  }
);

DeliveryMethodListItemByPayorWidget.displayName = 'DeliveryMethodListItemByPayorWidget';
