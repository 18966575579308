import { useAnalytics } from '@melio/platform-analytics';
import { ScannedInvoice } from '@melio/platform-api';
import { useCallback, useMemo } from 'react';

export const useScannedInvoiceReviewAnalytics = ({ scannedInvoice }: { scannedInvoice?: ScannedInvoice }) => {
  const { track } = useAnalytics();

  const eventProperties = useMemo(() => {
    if (scannedInvoice) {
      return {
        ScannedInvoiceId: scannedInvoice.id,
        MissingData: (['invoiceNumber', 'dueDate', 'amount', 'vendorName'] as (keyof ScannedInvoice)[]).filter(
          (key) => !scannedInvoice[key]
        ),
      };
    }
    return undefined;
  }, [scannedInvoice]);

  const trackOnFilePagination = useCallback(() => {
    track('DraftBillReviewFilePagination', 'Chose', eventProperties);
  }, [track, eventProperties]);

  const trackOnFileDownload = useCallback(() => {
    track('DraftBillReviewFileDownload', 'Chose', eventProperties);
  }, [track, eventProperties]);

  return {
    eventProperties,
    trackOnFilePagination,
    trackOnFileDownload,
  };
};
