import { Flex } from '@chakra-ui/react';
import { Container, Group, IconButton, LinearProgress } from '@melio/penny';

import { Steps } from '../types';

type HeaderProps = {
  currentStep: Steps;
  steps: Steps[];
  onBack: () => void;
  onCancel: () => void;
};

export const Header = ({ currentStep, steps, onBack, onCancel }: HeaderProps) => {
  const totalSteps = steps.length;
  const currentStepIndex = steps.indexOf(currentStep) + 1;

  return (
    <Container maxWidth="full" alignItems="center" justifyContent="space-around" paddingX="s" paddingY="s">
      <Group alignItems="center" width="full" spacing="l">
        <Group.Item>
          <IconButton
            data-testid="back-button"
            icon="chevron-left"
            aria-label="icon-button-naked-prev"
            variant="naked"
            onClick={onBack}
          />
        </Group.Item>
        <Group.Item basis="100%">
          <Flex gridArea="header-progressBar" w="full" justifyContent="center">
            {currentStepIndex > 0 && <LinearProgress currentStep={currentStepIndex} totalSteps={totalSteps} />}
          </Flex>
        </Group.Item>
        <Group.Item>
          <IconButton
            data-testid="close-button"
            icon="close"
            aria-label="icon-button-naked-close"
            variant="naked"
            onClick={onCancel}
          />
        </Group.Item>
      </Group>
    </Container>
  );
};
