import { useRelativeDateText } from '@melio/ap-domain';
import { Group, Icon, StatusIconSolid, Text, Tooltip } from '@melio/penny';
import { PaymentIntent } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import { CellError } from '../../../../../useSubmitBatchPaymentsErrors/types';
import { LateBanner } from './LateBanner';

type DeliveryDateProps = {
  paymentIntentId: PaymentIntent['id'];
  deliveryDate?: Date | null;
  selectedDeliveryPreferenceType: PaymentIntent['selectedDeliveryPreferenceType'];
  isLate: boolean;
  isDeliveryMethodSelected: boolean;
  error?: CellError;
};

const useHelperTextLabel = (selectedDeliveryPreferenceType: PaymentIntent['selectedDeliveryPreferenceType']) => {
  const { formatMessage } = useMelioIntl();
  switch (selectedDeliveryPreferenceType) {
    case 'check':
      return formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.paperCheck.USPS.helperText'
      );
    case 'express-check':
      return formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.paperCheck.FedEx.helperText'
      );
    default:
      return null;
  }
};

export const DeliveryDateValue = ({
  paymentIntentId,
  deliveryDate,
  selectedDeliveryPreferenceType,
  isLate,
  isDeliveryMethodSelected,
  error,
}: DeliveryDateProps) => {
  const { formatRelativeDate } = useRelativeDateText();
  const { formatMessage } = useMelioIntl();
  const helperTextLabel = useHelperTextLabel(selectedDeliveryPreferenceType);
  const {
    settings: {
      batchPayments: { showLateIndicator },
    },
  } = useConfig();

  const tooltipText = formatMessage(
    'activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.deliveryMethodNotSelectedTooltip'
  );
  const deliveryDatePlaceholder = formatMessage(
    'activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.date.placeholder'
  );

  return (
    <Group spacing="xxs" variant="vertical">
      <Group alignItems="center">
        <Text
          textStyle="body3"
          color="inherit"
          data-testid={`${paymentIntentId}-delivery-date-cell-date`}
          shouldSupportEllipsis
        >
          {!deliveryDate ? deliveryDatePlaceholder : formatRelativeDate({ date: deliveryDate })}
        </Text>

        {isLate && showLateIndicator && <LateBanner />}
        {error && (
          <Tooltip content={error.label} shouldAddTriggerFocus>
            <StatusIconSolid variant={error.variant} size="small" data-testid="delivery-date-cell-tooltip-icon" />
          </Tooltip>
        )}
        {!isDeliveryMethodSelected && (
          <Tooltip shouldAddTriggerFocus content={tooltipText}>
            <Icon type="info" size="small" data-testid="deduction-date-cell-tooltip-icon" />
          </Tooltip>
        )}
      </Group>
      {helperTextLabel && (
        <Text
          textStyle="body4"
          color="global.neutral.900"
          shouldSupportEllipsis
          data-testid={`${paymentIntentId}-delivery-date-cell-helper-text`}
        >
          {helperTextLabel}
        </Text>
      )}
    </Group>
  );
};
