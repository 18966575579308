import { BusinessStructure, FormWidgetProps, useMelioIntl } from '@melio/ar-domain';
import { useMelioForm } from '@melio/penny';
import { createContext, useContext } from 'react';
import { FormState } from 'react-hook-form';
import { boolean, date, object, string } from 'yup';
import * as yup from 'yup';

import { BusinessDetailsAddressFormFields, BusinessDetailsFormFields } from '../activities';

export const useAddressSchema = () => {
  const { formatMessage } = useMelioIntl();
  return yup.object().shape({
    line1: string().required(
      formatMessage('ar.onboarding.activities.businessDetails.form.fields.line1.valid.required.text')
    ),
    line2: string().optional(),
    city: string()
      .min(2, formatMessage('ar.onboarding.activities.businessDetails.form.fields.city.valid.minLength.text'))
      .max(32, formatMessage('ar.onboarding.activities.businessDetails.form.fields.city.valid.maxLength.text'))
      .matches(
        /^[\w\s]+$/,
        formatMessage('ar.onboarding.activities.businessDetails.form.fields.city.valid.matches.text')
      )
      .required(),
    state: string()
      .length(2, formatMessage('ar.onboarding.activities.businessDetails.form.fields.state.valid.length.text'))
      .matches(
        /^[A-Z]{2}$/,
        formatMessage('ar.onboarding.activities.businessDetails.form.fields.state.valid.matches.text')
      )
      .required(),
    postalCode: string()
      .min(5, formatMessage('ar.onboarding.activities.businessDetails.form.fields.postalCode.valid.minLength.text'))
      .max(11, formatMessage('ar.onboarding.activities.businessDetails.form.fields.postalCode.valid.maxLength.text'))
      .matches(
        /^[\w\s]+$/,
        formatMessage('ar.onboarding.activities.businessDetails.form.fields.postalCode.valid.matches.text')
      )
      .required(formatMessage('ar.onboarding.activities.businessDetails.form.fields.postalCode.valid.required.text')),
  }) as yup.SchemaOf<BusinessDetailsAddressFormFields>;
};

export const useFormSchema = () => {
  const { formatMessage } = useMelioIntl();
  return object().shape({
    legalName: string()
      .min(2, formatMessage('ar.onboarding.activities.businessDetails.form.fields.legalName.valid.minLength.text'))
      .matches(
        /^[a-zA-Z0-9 _(){}&@!+#.'$,%^*-]*$/,
        formatMessage('ar.onboarding.activities.businessDetails.form.fields.legalName.valid.matches.text')
      )
      .required(formatMessage('ar.onboarding.activities.businessDetails.form.fields.legalName.valid.required.text')),
    isBusinessNameConsistent: boolean(),
    irsLegalName: string()
      .required()
      .when('isBusinessNameConsistent', {
        is: false,
        then: (schema) =>
          schema
            .required(
              formatMessage('ar.onboarding.activities.businessDetails.form.fields.irsLegalName.valid.required.text')
            )
            .min(
              2,
              formatMessage('ar.onboarding.activities.businessDetails.form.fields.irsLegalName.valid.minLength.text')
            )
            .max(
              40,
              formatMessage('ar.onboarding.activities.businessDetails.form.fields.irsLegalName.valid.maxLength.text')
            )
            .matches(
              /^[a-zA-Z0-9 _(){}&@!+#.'$,%^*-]*$/,
              formatMessage('ar.onboarding.activities.businessDetails.form.fields.irsLegalName.valid.matches.text')
            ),

        otherwise: (schema) => schema.optional(),
      }),
    legalAddress: useAddressSchema(),
    deliveryAddress: useAddressSchema().when('isDeliveryAddressConsistent', {
      is: true,
      then: (schema) =>
        schema.shape({
          line1: schema.fields.line1.optional() as yup.StringSchema,
          state: schema.fields.state.optional() as yup.StringSchema,
          city: schema.fields.city.optional() as yup.StringSchema,
          postalCode: schema.fields.postalCode.optional() as yup.StringSchema,
        }),
      otherwise: useAddressSchema(),
    }),
    isDeliveryAddressConsistent: boolean(),
    businessStructure: string()
      .oneOf(Object.values(BusinessStructure))
      .required(
        formatMessage('ar.onboarding.activities.businessDetails.form.fields.businessStructure.valid.required.text')
      ),
    dateIncorporated: date().required(
      formatMessage('ar.onboarding.activities.businessDetails.form.fields.dateIncorporated.valid.required.text')
    ),
    industry: string().required(),
    industrySubCategory: string().required(),
    email: string()
      .required()
      .email()
      .min(1, formatMessage('ar.onboarding.activities.businessDetails.form.fields.email.valid.minLength.text'))
      .max(100, formatMessage('ar.onboarding.activities.businessDetails.form.fields.email.valid.maxLength.text')),
    contactNumber: string()
      .length(10, formatMessage('ar.onboarding.activities.businessDetails.form.fields.contactNumber.valid.length.text'))
      .matches(
        /^[1-9][0-9]+$/,
        formatMessage('ar.onboarding.activities.businessDetails.form.fields.contactNumber.valid.matches.text')
      )
      .required(
        formatMessage('ar.onboarding.activities.businessDetails.form.fields.contactNumber.valid.required.text')
      ),
    websiteUrl: string().optional(),
  }) as yup.SchemaOf<BusinessDetailsFormFields>;
};

type FormProps = FormWidgetProps<BusinessDetailsFormFields>;

export type UseBusinessDetailsFormProps = Override<FormProps, { defaultValues?: IncomingDefaultValues }>;

export type IncomingDefaultValues = NonNullable<FormProps['defaultValues']>;

export const useBusinessDetailsForm = ({ onSubmit, defaultValues, ...props }: UseBusinessDetailsFormProps) => {
  const form = useMelioForm<BusinessDetailsFormFields>({
    onSubmit,
    schema: useFormSchema(),
    defaultValues,
    subscribeToDefaultValuesChanges: true,
    ...props,
  });
  return { ...form, defaultValues };
};

export type BusinessDetailsForm = ReturnType<typeof useBusinessDetailsForm>;

export const BusinessDetailsFormContext = createContext<BusinessDetailsForm>({} as BusinessDetailsForm);
export const useBusinessDetailsFormContext = () => useContext<BusinessDetailsForm>(BusinessDetailsFormContext);

export type BusinessDetailsFormState = FormState<BusinessDetailsFormFields>;
