import { useAnalytics } from '@melio/platform-analytics';
import { useTaxPayerInfoDetailsRequest } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useSubscriptionFeature } from '@melio/subscriptions';

export const useTaxPayerInfoRequestWithSubscription = (vendorId: string) => {
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();
  const { track } = useAnalytics();
  const { tryUseFeature: tryUseW9Feature } = useSubscriptionFeature({
    featureName: 'w9',
  });

  const { create: createTaxPayerInfoRequest, isMutating } = useTaxPayerInfoDetailsRequest({
    vendorId,
  });

  const handleTaxpayerInfoRequest = async (email: string) => {
    try {
      await createTaxPayerInfoRequest({ email });
      showMessage({
        type: 'success',
        dataTestId: 'taxpayer-request-success',
        title: formatMessage('widgets.taxPayerInfo.modal.toast.success'),
      });
      track('Vendor', 'Status', { Intent: 'send-w9-request', TaxId: 'email', Status: 'success' });
    } catch (e) {
      showMessage({
        type: 'error',
        dataTestId: 'taxpayer-request-error',
        title: formatMessage('widgets.taxPayerInfo.modal.toast.error'),
      });
      track('Vendor', 'Status', { Intent: 'send-w9-request', TaxId: 'email', Status: 'fail' });
    }
  };

  const onRequestTaxPayerInfoDetailsWithSubscriptionCheck = (email: string, onFeatureIsBlockedCb?: () => void) => {
    tryUseW9Feature({
      onFeatureIsEligible: () => handleTaxpayerInfoRequest(email),
      onFeatureIsBlocked: onFeatureIsBlockedCb,
    });
  };

  return {
    isMutating,
    onRequestTaxPayerInfoDetailsWithSubscriptionCheck,
  };
};
