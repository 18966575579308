import { useMelioQueryClient, useModelApi, UseModelApiProps } from '@melio/api-client';
import { AccountLimitations, AccountsLimitationsApiClient, ApiKeys } from '@melio/platform-api-axios-client';

type QueryFunc = (id: string) => ReturnType<typeof AccountsLimitationsApiClient.getAccountsMeLimitations>;

export type UseAccountMeLimitationsProps = UseModelApiProps<QueryFunc, AccountLimitations> & {
  params?:
    | {
        stage: 'dashboard';
      }
    | { stage: 'payment'; isInitiator: boolean };
};

export const useAccountMeLimitations = ({ params, ...props }: UseAccountMeLimitationsProps) => {
  const queryClient = useMelioQueryClient();
  const query = useModelApi<QueryFunc, never, never, never, AccountLimitations>({
    id: 'me',
    queryKey: [ApiKeys.AccountsLimitationsApi, params],
    queryFn: () =>
      AccountsLimitationsApiClient.getAccountsMeLimitations(
        params?.stage,
        params?.stage === 'payment' ? params.isInitiator : undefined
      ),
    ...props,
  });

  const invalidateQuery = () => queryClient.invalidateQueries(ApiKeys.AccountsLimitationsApi);

  return {
    ...query,
    invalidateQuery,
  };
};
