import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { isFxPayment } from '../../functions/fx-utils';
import { usePaymentsLateApprovals } from './usePaymentsLateApprovals';

export const usePaymentLateApprovalTooltip = (payment: Payment) => {
  const { formatMessage } = useMelioIntl();
  const getPaymentsLateApprovals = usePaymentsLateApprovals();

  const paymentsLateApprovals = getPaymentsLateApprovals({ payments: [payment] });
  const paymentLateApproval = paymentsLateApprovals[payment.id];

  const getMainLabelTooltipProps = (): {
    label: string;
    title?: string;
    triggerAriaLabel?: string;
    triggerStatus?: 'warning' | 'informative';
  } => {
    const isDebitDateAfterToday = !!paymentLateApproval?.isDebitDateAfterToday;

    const isFx = isFxPayment(payment);
    let label: string;
    let title: string;
    let triggerStatus: 'warning' | 'informative';

    if (isDebitDateAfterToday) {
      triggerStatus = 'warning';
      title = formatMessage('activities.payDashboard.paymentsTab.debitDate.indication.tooltip.title.lateDebitDate');
      if (payment.fundingSource?.type === 'bank-account') {
        label = formatMessage(
          'activities.payDashboard.paymentsTab.debitDate.indication.tooltip.label.lateDebitDate.bank-account',
          { isFx }
        );
      } else if (payment.fundingSource?.type === 'card') {
        label = formatMessage(
          'activities.payDashboard.paymentsTab.debitDate.indication.tooltip.label.lateDebitDate.card',
          { isFx }
        );
      } else {
        label = formatMessage(
          'activities.payDashboard.paymentsTab.debitDate.indication.tooltip.label.lateDebitDate.default',
          { isFx }
        );
      }
    } else {
      triggerStatus = 'informative';
      title = formatMessage('activities.payDashboard.paymentsTab.debitDate.indication.tooltip.title.debitDateToday');
      if (payment.fundingSource?.type === 'bank-account') {
        label = formatMessage(
          'activities.payDashboard.paymentsTab.debitDate.indication.tooltip.label.debitDateToday.bank-account'
        );
      } else if (payment.fundingSource?.type === 'card') {
        label = formatMessage(
          'activities.payDashboard.paymentsTab.debitDate.indication.tooltip.label.debitDateToday.card'
        );
      } else {
        label = formatMessage(
          'activities.payDashboard.paymentsTab.debitDate.indication.tooltip.label.debitDateToday.default'
        );
      }
    }

    return { label, triggerAriaLabel: label, title, triggerStatus };
  };

  return getMainLabelTooltipProps();
};
