import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { ApiKeys, GetContractorsInfo1099Response, VendorsApiClient } from '@melio/platform-api-axios-client';

export const useVendorsContractorsInfo1099 = (
  props: UseModelApiProps<typeof VendorsApiClient.getVendorsContractorsInfo1099, GetContractorsInfo1099Response>
) =>
  useModelApi({
    ...props,
    id: 'vendorsContractorsInfo1099',
    queryKey: [ApiKeys.VendorsApi, 'model', 'contractors-info-1099'],
    queryFn: VendorsApiClient.getVendorsContractorsInfo1099,
  });
