import { useAccount } from '@melio/ar-domain';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { OnBeforeDone, ShareMode } from '../../types';
import { SendReminderScreen, ShareInvoiceScreen } from './screens';
import { useSendInvoiceActivity } from './utils';

export type IssueInvoiceActivityProps = {
  invoiceId: string;
  onDone: (id: string) => void;
  onBeforeDone?: OnBeforeDone;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
  onUploadCompanyLogo?: VoidFunction;
  shareMode: ShareMode;
  onEditInvoice: (id: string) => unknown;
};

export const ShareInvoiceActivity = withAnalyticsContext<IssueInvoiceActivityProps>(
  forwardRef(
    (
      {
        setAnalyticsProperties,
        onDone,
        onClose,
        onError,
        invoiceId,
        onBeforeDone,
        onUploadCompanyLogo: _onUploadCompanyLogo,
        shareMode,
        onEditInvoice,
        ...props
      },
      ref
    ) => {
      const [isSendEmail, setIsSendEmail] = useState(true);
      const {
        isLoading,
        isSaving,
        submitHandler,
        submitTarget,
        customer,
        invoice,
        paymentRequestLink,
        handleCopyPaymentLink,
        handleBackClick,
      } = useSendInvoiceActivity({
        invoiceId,
        onDone,
        onError,
        onBeforeDone,
        shareMode,
        onClose,
        onEditInvoice,
        shouldSend: isSendEmail,
      });
      const { data: account, isLoading: isAccountLoading } = useAccount({ id: 'me', onError });
      const { track } = useAnalytics();

      setAnalyticsProperties({
        PageName: shareMode === 'reminder' ? 'send-reminder' : 'send-invoice',
        InvoiceStatus: invoice?.displayStatus,
        DueDate: invoice?.dueDate,
      });

      const onUploadCompanyLogo = () => {
        track('Invoice', 'Click', {
          Cta: 'upload-logo',
          Intent: 'add-company-logo',
        });
        _onUploadCompanyLogo?.();
      };
      const handleSelectSendEmail = (isSendEmailSelected: boolean) => {
        track('Invoice', 'Click', {
          Cta: isSendEmailSelected ? 'email-it-to-my-customer' : 'share-payment-link',
          Intent: isSendEmailSelected ? 'email-customer' : 'share-payment-link',
        });
        setIsSendEmail(isSendEmailSelected);
      };

      return shareMode === 'reminder' ? (
        <SendReminderScreen
          {...props}
          isLoading={isLoading || isAccountLoading}
          shouldSend={isSendEmail}
          onSelectSendEmail={handleSelectSendEmail}
          invoiceId={invoiceId}
          onClose={onClose}
          onSubmit={(formData, _, submitTarget) => submitHandler(formData, submitTarget)}
          defaultValues={{ email: customer?.email }}
          isSubmitting={isSaving}
          submitTarget={submitTarget}
          onBack={handleBackClick}
          onUploadCompanyLogo={onUploadCompanyLogo}
          shouldDisplayUploadCompanyLogoBanner={!account?.company.logoId}
          paymentRequestLink={paymentRequestLink}
          onCopyPaymentLink={handleCopyPaymentLink}
          customerName={invoice?.customer?.companyName}
          ref={ref}
        />
      ) : (
        <ShareInvoiceScreen
          {...props}
          isLoading={isLoading || isAccountLoading}
          shouldSend={isSendEmail}
          invoiceId={invoiceId}
          onClose={onClose}
          onSubmit={(formData, _, submitTarget) => submitHandler(formData, submitTarget)}
          onSelectSendEmail={handleSelectSendEmail}
          defaultValues={{ email: customer?.email }}
          isSubmitting={isSaving}
          submitTarget={submitTarget}
          onBack={handleBackClick}
          onUploadCompanyLogo={onUploadCompanyLogo}
          shouldDisplayUploadCompanyLogoBanner={!account?.company.logoId}
          paymentRequestLink={paymentRequestLink}
          onCopyPaymentLink={handleCopyPaymentLink}
          customerName={invoice?.customer?.companyName}
          ref={ref}
        />
      );
    }
  )
);
