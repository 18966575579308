import {
  GuestPayorUserTypes,
  PayorPaymentRequestDetails,
  PayorUserDetailsByEmail,
  useAccount,
  useGuestPayorPaymentRequestDetails,
  useGuestPayorUserDetails,
  useGuestPayorUserDetailsByEmail,
} from '@melio/ar-domain';
import { useConfig } from '@melio/platform-provider';
import { useDebounce } from '@melio/platform-utils';
import { useMemo } from 'react';

import { useAddBankAccountDetailsSchema } from '../activities';
import { generateDeepLinkToPaymentRequestPayDashboard } from '../utils';

export const EMAIL_CHANGED_DEBOUNCE_TIME = 400;

type InitialState = {
  isLoading: false;
  isCheckingEmail: false;
  isError: false;
  isAuthenticated: false;
  isApUser: false;
  signInUrl?: never;
  userType?: never;
  errors?: never;
  paymentRequestEmail?: never;
};

type LoadingUserState = Override<InitialState, { isLoading: true; isCheckingEmail: boolean }>;
type ErrorUserState = Override<InitialState, { isError: true; errors: ARPlatformError[] }>;
type LoadedUserState = Override<
  InitialState,
  { paymentRequestEmail?: string; isAuthenticated: boolean; userType?: GuestPayorUserTypes }
>;
type APUserState = Override<LoadedUserState, { isApUser: true; signInUrl: string }>;

type UserState = LoadingUserState | ErrorUserState | LoadedUserState | APUserState;

export type UserStateProps = {
  paymentRequestLink?: string;
  email?: string;
  onError?: ARErrorFunction;
};

export const useUserState = ({ paymentRequestLink, email: _email, onError }: UserStateProps): UserState => {
  const email = useCurrentUserEmail(_email);
  const accountModel = useAccount({
    id: 'me',
    onError: (error) => {
      if (error.code != '401') onError?.(error);
    },
  });
  const paymentRequestDetailsModel = useGuestPayorPaymentRequestDetails({ paymentRequestLink, onError });
  const guestPayorUserDetailsModel = useGuestPayorUserDetails({ id: paymentRequestLink, onError });
  const guestPayorUserDetailsByEmailModel = useGuestPayorUserDetailsByEmail({ email, paymentRequestLink, onError });

  const requiredModels = [paymentRequestDetailsModel, guestPayorUserDetailsModel, guestPayorUserDetailsByEmailModel];
  const models = [...requiredModels, accountModel];

  const errors = requiredModels.map((model) => model.error).filter(Boolean) as ARPlatformError[];
  const isLoading = models.some((model) => model.isLoading);

  const isAuthenticated = useMemo(
    () => emailsAreEqual(email, accountModel.data?.user.email),
    [accountModel.data, email]
  );

  const dashboardUrl = useRedirectUrl({
    paymentRequestLink,
    paymentRequestDetails: paymentRequestDetailsModel.data,
    userDetails: guestPayorUserDetailsByEmailModel.data,
  });

  const defaultState = {
    isAuthenticated: false,
    isApUser: false,
    isLoading: false,
    isCheckingEmail: false,
    isError: false,
  } as const;

  if (isLoading) {
    return { ...defaultState, isLoading: true, isCheckingEmail: !!guestPayorUserDetailsByEmailModel.isFetching };
  }

  if (errors.length) {
    return { ...defaultState, isError: true, errors };
  }

  const target: LoadedUserState = {
    ...defaultState,
    isAuthenticated,
    userType: guestPayorUserDetailsByEmailModel.data?.userType ?? guestPayorUserDetailsModel.data?.userType,
    paymentRequestEmail: guestPayorUserDetailsModel.data?.email,
  };

  if (dashboardUrl) {
    return {
      ...target,
      isApUser: true,
      signInUrl: dashboardUrl,
    };
  }

  return target;
};

type UseRedirectUrlProps = {
  paymentRequestLink?: string;
  userDetails?: PayorUserDetailsByEmail;
  paymentRequestDetails?: PayorPaymentRequestDetails;
};
const useRedirectUrl = ({ paymentRequestLink, paymentRequestDetails, userDetails }: UseRedirectUrlProps) => {
  const shouldGenerateDeepLinkToPaymentRequestPayDashboard =
    useConfig().settings.guestPaymentFlow?.shouldGenerateDeepLinkToPaymentRequestPayDashboard ?? null;

  if (
    !paymentRequestDetails ||
    !shouldGenerateDeepLinkToPaymentRequestPayDashboard ||
    userDetails?.userType !== 'registered-and-matched'
  )
    return undefined;

  return generateDeepLinkToPaymentRequestPayDashboard({
    paymentRequestId: paymentRequestDetails.paymentRequestId,
    paymentRequestLink,
    vendorName: paymentRequestDetails.payeeDetails.companyName,
  });
};

const useCurrentUserEmail = (email?: string) => {
  const schema = useAddBankAccountDetailsSchema();
  return useDebounce(schema.isValidSync({ email }) ? email : undefined, EMAIL_CHANGED_DEBOUNCE_TIME);
};

export const emailsAreEqual = (...emails: Array<string | null | undefined>) =>
  !!emails[0] && emails.every((email) => email?.toLocaleLowerCase() == emails[0]?.toLocaleLowerCase());
