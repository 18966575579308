import { useBlockPaymentsForOrganizations } from '@melio/ap-domain';
import { TableRowSelectionState, useBreakpoint, useTable } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

type UsePaymentsTabSelectionResult = { rowSelections: string[]; areAllSelected: boolean } & Pick<
  Parameters<typeof useTable<Payment>>[0],
  'selectedRows' | 'onRowSelectionChange' | 'onAllRowsSelectionChange' | 'disableRowSelection' | 'rowSelectionTooltips'
>;

export const useApprovalsTabSelection = (
  paymentsApprovalsData: Payment[],
  clearSelected = false,
  setClearSelectedFalse: Dispatch<SetStateAction<boolean>>
): UsePaymentsTabSelectionResult => {
  const [rowSelections, setRowSelections] = useState<string[]>([]);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();
  const { shouldBlockInternationalPayments, getTooltipContent } = useBlockPaymentsForOrganizations();

  const disableRowSelection = useCallback(
    (payment: Payment): boolean => {
      const deliveryMethods = payment.deliveryMethod ? [payment.deliveryMethod] : undefined;
      return shouldBlockInternationalPayments(deliveryMethods);
    },
    [shouldBlockInternationalPayments]
  );
  const selectableRows = useMemo(
    () => paymentsApprovalsData.filter((payment) => !disableRowSelection(payment)),
    [disableRowSelection, paymentsApprovalsData]
  );

  useEffect(() => {
    setAreAllSelected(selectableRows.length > 0 && selectableRows.length === rowSelections.length);
  }, [rowSelections, paymentsApprovalsData, selectableRows]);

  useEffect(() => {
    if (clearSelected) {
      setRowSelections([]);
    }
  }, [clearSelected]);

  const onRowSelectionChange = useCallback(
    (selectionState: TableRowSelectionState<Payment>) => {
      setClearSelectedFalse(false);
      const { rowId, isSelected } = selectionState;
      setRowSelections((selectedRows) =>
        isSelected ? [...selectedRows, rowId] : selectedRows.filter((selectedRowId) => selectedRowId !== rowId)
      );
    },
    [setClearSelectedFalse]
  );

  const onAllRowsSelectionChange = useCallback(
    (areAllSelected: boolean) => {
      const rowSelections = areAllSelected ? selectableRows?.map((val) => val.id) ?? [] : [];
      setRowSelections(rowSelections);
    },
    [selectableRows]
  );
  const rowSelectionTooltips = useMemo(
    () => ({
      header: {
        content: !areAllSelected
          ? formatMessage('activities.payDashboard.billsTab.table.tooltips.selectAll')
          : formatMessage('activities.payDashboard.billsTab.table.tooltips.deselectAll'),
      },
      row({ rowData, isSelectionDisabled }: { rowData: Payment; isSelectionDisabled: boolean }) {
        if (isSelectionDisabled) {
          const deliveryMethods = rowData.deliveryMethod ? [rowData.deliveryMethod] : undefined;
          if (shouldBlockInternationalPayments(deliveryMethods)) {
            return {
              content: getTooltipContent(deliveryMethods),
            };
          }
        }
        return undefined;
      },
    }),
    [areAllSelected, formatMessage, getTooltipContent, shouldBlockInternationalPayments]
  );

  if (isExtraSmallScreen) {
    return { rowSelections, areAllSelected };
  }

  return {
    disableRowSelection,
    rowSelections,
    selectedRows: rowSelections.reduce<Record<string, boolean>>((obj, rowId) => ({ ...obj, [rowId]: true }), {}),
    onRowSelectionChange,
    onAllRowsSelectionChange,
    rowSelectionTooltips,
    areAllSelected,
  };
};
