import { useDisclosure } from '@chakra-ui/react';
import { Icon } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import React, { useCallback } from 'react';

import { PromotionProps, PromotionType } from '../../types';
import { PromotionBanner } from '../PromotionBanner';
import { CardToCardInfoModal } from './CardToCardInfoModal';

export const IS_C2C_PROMOTION_DISMISSED_KEY = 'isCardToCardPromotionDismissed';

export const CardToCardPromotionBanner = ({ analyticProperties, onClose, onDone }: PromotionProps) => {
  const { track } = useAnalytics();
  const { isOpen: isVirtualCardModalOpen, onOpen: onVcModalOpen, onClose: onVcModalClose } = useDisclosure();

  const onClickDismiss = useCallback(() => {
    track('DeliveryMethod', 'Click', {
      ...analyticProperties,
      Intent: 'remove-banner',
      Cta: 'exit',
    });
    onClose();
  }, [analyticProperties, onClose, track]);

  const onLearnMoreClick = useCallback(() => {
    track('DeliveryMethod', 'Click', {
      ...analyticProperties,
      Intent: 'information-page',
      Cta: 'learn-more',
    });
    onVcModalOpen();
  }, [analyticProperties, onVcModalOpen, track]);

  return (
    <>
      {isVirtualCardModalOpen && <CardToCardInfoModal onClose={onVcModalClose} onDone={onDone} />}
      <PromotionBanner
        promoType={PromotionType.CardToCard}
        data-testid="card-to-card-promotion"
        leftElement={<Icon type="single-use-card" aria-hidden />}
        onDismiss={onClickDismiss}
        onConfirm={onLearnMoreClick}
      />
    </>
  );
};
