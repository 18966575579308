import { Money } from '@melio/money';
import { Bill, Payment, PaymentCreateMethodEnum } from '@melio/platform-api';

export const getAvailableBalance = (bill: Bill, payment: Payment | undefined) =>
  Money.fromNaturalUnit(bill.balance, bill.currency).add(payment?.amount || 0);

export const isJustPayPayment = (createMethod: PaymentCreateMethodEnum | undefined) => {
  switch (createMethod) {
    case PaymentCreateMethodEnum.JustPay:
    case PaymentCreateMethodEnum.JustPayBatch:
    case PaymentCreateMethodEnum.JustPaySync:
      return true;
    default:
      return false;
  }
};
