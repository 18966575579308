import {
  ApiError,
  BillSubscriptionErrorCode,
  PaymentErrorCode,
  paymentSettingsQueryKey,
  useFundingSources,
  vendorsQueryKey,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useSystemMessage } from '@melio/platform-utils';
import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useQueryClient } from 'react-query';

const paymentSettingsRelatedErrors: string[] = [
  PaymentErrorCode.IneligibleCardNetworkForPayment,
  BillSubscriptionErrorCode.IneligibleCardNetworkForBillSubscription,
];

const vendorRelatedErrors: string[] = [
  PaymentErrorCode.DeliveryMethodChanged,
  BillSubscriptionErrorCode.DeliveryMethodChangedForBillSubscription,
];

export const useSubmitErrorHandler = () => {
  const { formatMessage } = useMelioIntl();
  const queryClient = useQueryClient();
  const { data: fundingSources } = useFundingSources();
  const { showMessage } = useSystemMessage();
  const {
    settings: { supportPhoneNumber },
  } = useConfig();

  return useCallback(
    async (submitError: ApiError, { fundingSourceId }: { fundingSourceId?: string | undefined }) => {
      const selectedFundingSource = fundingSources?.find((fundingSource) => fundingSource.id === fundingSourceId);

      if (submitError.errorCode && paymentSettingsRelatedErrors.includes(submitError.errorCode)) {
        await queryClient.invalidateQueries(paymentSettingsQueryKey);
      }
      if (submitError.errorCode && vendorRelatedErrors.includes(submitError.errorCode)) {
        await queryClient.invalidateQueries(vendorsQueryKey);
      }

      const errors: Record<string, string | null> = {
        [PaymentErrorCode.DuplicatePayment]: formatMessage('activities.paymentFlow.errors.duplicatePayment'),
        [BillSubscriptionErrorCode.DuplicateBillSubscription]: formatMessage(
          'activities.paymentFlow.errors.duplicatePayment'
        ),
        [PaymentErrorCode.AccountFrozen]: formatMessage('activities.paymentFlow.errors.accountFrozen', {
          supportPhoneNumber,
        }),
        [PaymentErrorCode.AccountClosed]: formatMessage('activities.paymentFlow.errors.accountClosed', {
          supportPhoneNumber,
        }),
        [PaymentErrorCode.DateTooEarly]: formatMessage('activities.paymentFlow.errors.dateTooEarly'),
        [PaymentErrorCode.UnauthorizedPayment]: formatMessage('activities.paymentFlow.errors.unauthorized'),
        [BillSubscriptionErrorCode.UnauthorizedSubscription]: formatMessage(
          'activities.paymentFlow.errors.unauthorized'
        ),
        [PaymentErrorCode.PaymentEditingLocked]: formatMessage('activities.paymentFlow.errors.editLocked'),
        [PaymentErrorCode.FailedPaymentEditingLocked]: formatMessage('activities.paymentFlow.errors.editLockedFailed'),
        [PaymentErrorCode.MaxPaymentAmountLimit]: formatMessage(
          'activities.paymentFlow.errors.paymentApiMaxAmountLimit'
        ),
        [BillSubscriptionErrorCode.MaxSubscriptionAmountLimit]: formatMessage(
          'activities.paymentFlow.errors.paymentApiMaxAmountLimit'
        ),
        [PaymentErrorCode.InsufficientFunds]: formatMessage('activities.paymentFlow.errors.insufficientFunds', {
          maskedAccountNumber: selectedFundingSource?.displayName,
        }),
        [PaymentErrorCode.IneligibleCardNetworkForPayment]: formatMessage(
          'activities.paymentFlow.errors.ineligibleCardNetworkForPayment'
        ),
        [BillSubscriptionErrorCode.IneligibleCardNetworkForBillSubscription]: formatMessage(
          'activities.paymentFlow.errors.ineligibleCardNetworkForBillSubscription'
        ),
        [PaymentErrorCode.DeliveryMethodChanged]: null,
        [BillSubscriptionErrorCode.DeliveryMethodChangedForBillSubscription]: null,
      };

      const message =
        submitError.errorCode && submitError.errorCode in errors
          ? errors[submitError.errorCode]
          : formatMessage('activities.paymentFlow.errors.default');

      if (message !== null) {
        showMessage({ title: message, type: 'error' });
      }
    },
    [fundingSources, formatMessage, showMessage, queryClient, supportPhoneNumber]
  );
};
