import { useConfig } from '@melio/platform-provider';
import { addYears } from 'date-fns';

export function useRecurringEndDateRestrictionYears() {
  const {
    settings: { payment },
  } = useConfig();
  const recurringEndDateRestrictionYears = payment?.scheduling?.recurringEndDateRestrictionYears;

  return {
    getRecurringEndDateRestrictionYears: (startDate: Date | undefined | null): Date | undefined => {
      if (!startDate || !recurringEndDateRestrictionYears) {
        return undefined;
      }
      return addYears(startDate, recurringEndDateRestrictionYears);
    },
    restrictionYears: recurringEndDateRestrictionYears,
  };
}
