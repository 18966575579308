import { FundingSource, PaymentErrorCode, PaymentIntent } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useCallback } from 'react';

import { CellError, TableCell } from './types';

export const useGetErrorDetails = ({ fundingSources }: { fundingSources: FundingSource[] | undefined }) => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: { supportPhoneNumber },
  } = useConfig();

  const getErrorDetails = useCallback(
    ({
      errorCode,
      paymentIntent,
    }: {
      errorCode: PaymentErrorCode | undefined;
      paymentIntent: PaymentIntent;
    }): Partial<Record<TableCell, CellError>> | undefined => {
      switch (errorCode) {
        case 'deliveryMethodChanged':
          return;
        case 'accountClosed':
          return {
            fundingSource: {
              variant: 'alert',
              label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.error.accountClosed', {
                supportPhoneNumber,
              }),
            },
          };
        case 'duplicatePayment':
          return {
            vendor: {
              variant: 'alert',
              label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.error.duplicatePayment'),
            },
          };
        case 'ineligibleCardNetworkForPayment':
          return {
            fundingSource: {
              variant: 'alert',
              label: formatMessage(
                'activities.batchPayments.screens.paymentIntentsTable.error.ineligibleCardNetworkForPayment'
              ),
            },
          };
        case 'insufficientFunds': {
          const fundingSource = fundingSources?.find(
            (fundingSource) => fundingSource.id === paymentIntent.fundingSourceId
          );

          return {
            fundingSource: {
              variant: 'alert',
              label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.error.insufficientFunds', {
                maskedAccountNumber: fundingSource?.displayName,
              }),
            },
          };
        }
        case 'maxPaymentAmountLimit':
          return {
            vendor: {
              variant: 'alert',
              label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.error.maxPaymentAmountLimit'),
            },
          };
        case 'unauthorizedPayment':
          return {
            vendor: {
              variant: 'alert',
              label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.error.unauthorizedPayment'),
            },
          };
        case 'dateTooEarly':
          return {
            deliveryDate: {
              variant: 'alert',
              label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.error.dateTooEarly'),
            },
          };
        default:
          return {
            vendor: {
              variant: 'alert',
              label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.error.default'),
            },
          };
      }
    },
    [formatMessage, fundingSources, supportPhoneNumber]
  );

  return getErrorDetails;
};
