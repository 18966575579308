import { Link } from '@melio/penny';
import { useUserPreference } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { Logger } from '@melio/platform-logger';

import { usePlatformIntl } from '@/translations/Intl';
import { getAccessTokenData } from '@/utils/getAccessTokenData.utils';
import { parseBoolean } from '@/utils/parsing.utils';
import { BannerModalContent } from '@/widgets/AccountCentricViewMessaging/components/modal-content/BannerTypeContent';
import { DescriptionModalContent } from '@/widgets/AccountCentricViewMessaging/components/modal-content/DescriptionTypeContent';
export type AccountCentricViewMessagingFeatureFlagTypes = 'closed' | 'simple' | 'complexA' | 'complexB';
export type ModalContent = DescriptionModalContent | BannerModalContent;
export const IS_MODAL_ALRADY_SHOWN_PREFERENCE_KEY = 'isAccountCentricViewModalShown';
const EMAIL_TO_LINK = 'multi-account-support@melio.com';

const MailToLink: React.FC = () => <Link href={`mailto:${EMAIL_TO_LINK}}`} label={EMAIL_TO_LINK} />;

export const useAccountCentricViewCase = () => {
  const hasUser = !!getAccessTokenData()?.user;
  const [accountCentricViewMessagingCase] = useDevFeature<AccountCentricViewMessagingFeatureFlagTypes>(
    FeatureFlags.MessagingAccountCentricViewCase,
    'closed',
  );
  const {
    data: { value: isPopupAlreadyShownUserPreference } = {},
    isFetched,
    create,
  } = useUserPreference({
    id: IS_MODAL_ALRADY_SHOWN_PREFERENCE_KEY,
    enabled: hasUser,
  });
  const { formatMessage } = usePlatformIntl();
  const isSimpleCase = accountCentricViewMessagingCase === 'simple';
  const isPopupAlreadyShown = parseBoolean(isPopupAlreadyShownUserPreference);
  const shouldPopupModal = !isSimpleCase && isFetched && !isPopupAlreadyShown;
  const shouldShowBanner = accountCentricViewMessagingCase !== 'closed';
  const complexBCaseContent: ModalContent[] = [
    {
      key: 'status-banner',
      type: 'banner',
      title: formatMessage('widgets.accountCentricViewMessaging.modal.status.title.complexB'),
      explanation: formatMessage('widgets.accountCentricViewMessaging.modal.status.explanation.complexB', {
        email: <MailToLink />,
      }),
    },
    {
      key: 'separate-dashboards',
      type: 'description',
      icon: 'iframe',
      title: formatMessage('widgets.accountCentricViewMessaging.modal.separateDashboards.title'),
      explanation: formatMessage('widgets.accountCentricViewMessaging.modal.separateDashboards.explanation'),
    },
    {
      key: 'manage-payments',
      type: 'description',
      icon: 'installments',
      title: formatMessage('widgets.accountCentricViewMessaging.modal.managePayments.title'),
      explanation: formatMessage('widgets.accountCentricViewMessaging.modal.managePayments.explanation.complex'),
    },
  ];

  const complexACaseContent: ModalContent[] = [
    {
      key: 'separate-dashboards',
      type: 'description',
      icon: 'iframe',
      title: formatMessage('widgets.accountCentricViewMessaging.modal.separateDashboards.title'),
      explanation: formatMessage('widgets.accountCentricViewMessaging.modal.separateDashboards.explanation'),
    },
    {
      key: 'manage-payments',
      type: 'description',
      icon: 'installments',
      title: formatMessage('widgets.accountCentricViewMessaging.modal.managePayments.title'),
      explanation: formatMessage('widgets.accountCentricViewMessaging.modal.managePayments.explanation.complex'),
    },
    {
      key: 'status-banner',
      type: 'banner',
      title: formatMessage('widgets.accountCentricViewMessaging.modal.status.title'),
      explanation: formatMessage('widgets.accountCentricViewMessaging.modal.status.explanation.complexA', {
        email: <MailToLink />,
      }),
    },
  ];

  const simpleCaseContent: ModalContent[] = [
    {
      key: 'separate-dashboards',
      type: 'description',
      icon: 'iframe',
      title: formatMessage('widgets.accountCentricViewMessaging.modal.separateDashboards.title'),
      explanation: formatMessage('widgets.accountCentricViewMessaging.modal.separateDashboards.explanation'),
    },
    {
      key: 'manage-payments',
      type: 'description',
      icon: 'installments',
      title: formatMessage('widgets.accountCentricViewMessaging.modal.managePayments.title'),
      explanation: formatMessage('widgets.accountCentricViewMessaging.modal.managePayments.explanation'),
    },
    {
      key: 'status-banner',
      type: 'banner',
      title: formatMessage('widgets.accountCentricViewMessaging.modal.status.title'),
      explanation: formatMessage('widgets.accountCentricViewMessaging.modal.status.explanation'),
    },
  ];

  const getModalContent = (): ModalContent[] => {
    switch (accountCentricViewMessagingCase) {
      case 'complexB':
        return complexBCaseContent;
      case 'complexA':
        return complexACaseContent;
      case 'simple':
        return simpleCaseContent;
      case 'closed':
      default:
        return [];
    }
  };

  const onCloseModal = async () => {
    try {
      if (!isPopupAlreadyShown) {
        await create({ userPreference: { key: IS_MODAL_ALRADY_SHOWN_PREFERENCE_KEY, value: 'true' } });
      }
    } catch (e) {
      Logger.handleException(
        `Failed while trying to update userPreferences ${IS_MODAL_ALRADY_SHOWN_PREFERENCE_KEY} value to 'true': ${e}`,
      );
    }
  };

  return {
    shouldShowBanner,
    shouldPopupModal,
    modalContent: getModalContent(),
    onCloseModal,
  };
};
