import { isBusinessTypeAllowedForFX, isIndustryShouldPrefilled, isNaicsCodeAllowedForFX } from '@melio/ap-domain';
import { useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Account, AccountAddressParams, PatchAccountsAccountIdRequestCompany, useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useSystemMessage } from '@melio/platform-utils';

import { MonitoredAction } from '../../../../../../monitoring';
import { BusinessDetailsForm, UnsupportedModalValues } from '../types';
import { getDirtyValuesFromForm } from '../utils/formUtils';
import { useBusinessDetailsFormSchema } from './useBusinessDetailsFormSchema';

export const useFxBusinessDetailsForm = ({
  onDone,
  onUnsupportedBusinessType,
}: {
  onDone: (data: Account) => void;
  onUnsupportedBusinessType: ({
    isUnsupportedBusinessType,
    isUnsupportedIndustryType,
    values,
  }: {
    isUnsupportedBusinessType: boolean;
    isUnsupportedIndustryType: boolean;
    values: UnsupportedModalValues;
  }) => void;
}) => {
  const { formatMessage } = useMelioIntl();
  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const { showMessage } = useSystemMessage();
  const { track } = useAnalytics();
  const {
    data: account,
    update: updateAccount,
    isMutating,
    isLoading,
  } = useAccount({
    id: 'me',
    onUpdate: (res) => {
      endAction('fx_business_details_submit');
      const { businessType, industry } = res.company;
      if (isNaicsCodeAllowedForFX(industry?.naicsCode) && isBusinessTypeAllowedForFX(businessType)) {
        onDone(res);
      }
    },
    onUpdateError: () => {
      track('Organization', 'Status', {
        Status: 'failure',
        PageName: 'business-details',
        Intent: 'set-business-details',
        ErrorType: `some-thing-went-wrong`,
      });
      showMessage({
        dataTestId: 'fx-update-business-failed-toast',
        type: 'error',
        title: formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.errors.general-error'
        ),
      });
    },
  });
  const defaultTaxId = account?.company?.taxInfo?.identifier ?? '';
  const businessDetailsFormSchema = useBusinessDetailsFormSchema({ defaultTaxId });
  const form = useMelioForm<BusinessDetailsForm>({
    onSubmit: () => {
      startAction('fx_business_details_submit');
      const dirtyBusinessDetails = getDirtyValuesFromForm({
        form,
      });

      const taxInfo = {
        identifier: dirtyBusinessDetails.businessTaxId,
        type: dirtyBusinessDetails.taxIdType,
      };
      const updateCompanyRequest: PatchAccountsAccountIdRequestCompany = {
        name: dirtyBusinessDetails.companyName,
        legalName: dirtyBusinessDetails.companyLegalName,
        businessType: dirtyBusinessDetails.businessType,
        industry: dirtyBusinessDetails.businessIndustry && {
          naicsCode: dirtyBusinessDetails.businessIndustry?.naicsCode,
          name: '',
        },
        taxInfo,
        legalAddress: dirtyBusinessDetails.legalBusinessAddress
          ? (dirtyBusinessDetails.legalBusinessAddress as AccountAddressParams)
          : undefined,
        phoneNumber: dirtyBusinessDetails.phone,
      };

      updateAccount({
        company: updateCompanyRequest,
      });
      const businessIndustry = form.getValues('businessIndustry');
      const businessType = form.getValues('businessType');
      const isBusinessTypeNotAllowed = !isBusinessTypeAllowedForFX(businessType);
      const isNaicsCodeNotAllowed = !isNaicsCodeAllowedForFX(businessIndustry.naicsCode);
      if (isBusinessTypeNotAllowed || isNaicsCodeNotAllowed) {
        onUnsupportedBusinessType({
          isUnsupportedBusinessType: isBusinessTypeNotAllowed,
          isUnsupportedIndustryType: isNaicsCodeNotAllowed,
          values: {
            businessType,
            businessIndustryName: businessIndustry,
          },
        });
        return;
      }
    },
    defaultValues: {
      businessType: account?.company?.businessType ?? undefined,
      businessIndustry:
        account?.company?.industry && isIndustryShouldPrefilled(account?.company?.industry)
          ? account?.company?.industry
          : undefined,
      companyName: account?.company.name ?? '',
      companyLegalName: account?.company.legalName ?? '',
      legalBusinessAddress: account?.company.legalAddress,
      phone: account?.company.phoneNumber ?? '',
      businessTaxId: account?.company.taxInfo?.identifier ?? '',
      taxIdType: account?.company.taxInfo?.type ?? undefined,
    },
    subscribeToDefaultValuesChanges: true,
    schema: businessDetailsFormSchema,
  });

  return {
    form,
    isLoading,
    isMutating,
  };
};
