import { useIsMobile } from '@melio/ar-domain';
import { SplitScreen, SplitScreenProps, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { kebabCase } from 'lodash';
import { useState } from 'react';

import {
  FormPanelHeaderDesktop,
  FormPanelHeaderMobile,
  IssueInvoiceForm,
  IssueInvoiceFormProps,
  PreviewPanelHeader,
  PreviewTabs,
  ShareInvoiceDesktopFooter,
  ShareInvoiceHeader,
  ShareInvoiceMobileFooter,
} from '../components';
import { IssueInvoiceFormFields, SubmitTarget, TabsNames } from '../types';
import { useScreenLabels } from '../utils';

export type SendReminderScreenProps = {
  error?: ARPlatformError;
  onClose: VoidFunction;
  onBack: VoidFunction;
  onSubmit: (
    data: IssueInvoiceFormFields,
    event?: React.MouseEvent<HTMLButtonElement>,
    target?: SubmitTarget
  ) => unknown;
  onUploadCompanyLogo?: VoidFunction;
  onSelectSendEmail: (isSendInvoice: boolean) => void;
  shouldSend: boolean;
  isLoading: boolean;
  invoiceId: string;
  isSubmitting: boolean;
  submitTarget?: SubmitTarget;
  defaultValues?: IssueInvoiceFormProps['defaultValues'];
  defaultTab?: TabsNames;
  shouldDisplayUploadCompanyLogoBanner: boolean;
  paymentRequestLink?: string;
  onCopyPaymentLink?: () => Promise<void>;
  customerName?: string;
};

export const SendReminderScreen = forwardRef<SendReminderScreenProps>(
  (
    {
      invoiceId,
      defaultValues,
      isLoading,
      isSubmitting,
      onClose,
      onBack,
      onSubmit,
      onUploadCompanyLogo,
      onSelectSendEmail,
      submitTarget,
      error,
      defaultTab = 'PDF',
      shouldDisplayUploadCompanyLogoBanner,
      paymentRequestLink,
      onCopyPaymentLink,
      customerName,
      shouldSend,
      ...props
    },
    ref
  ) => {
    const [activeTab, setActiveTab] = useState<TabsNames>(defaultTab);
    const { onSubmissionStateChange, handleSubmit } = useFormSubmissionController<IssueInvoiceFormFields>();
    const isMobile = useIsMobile();
    const { track } = useAnalytics();

    useAnalyticsView('Invoice', true, true, { Intent: 'send-reminder' });

    const { headerText } = useScreenLabels({ shareMode: 'reminder' });

    const handleChangeActiveTab = (tab: TabsNames) => {
      track('Invoice', 'Click', {
        Cta: kebabCase(tab),
        Intent: tab === TabsNames.PDF ? 'view-invoice-file' : 'view-email',
      });
      setActiveTab(tab);
    };

    const previewPanel: SplitScreenProps['panelB'] = {
      header: <PreviewPanelHeader activeTab={activeTab} onChangeActiveTab={handleChangeActiveTab} />,
      content: (
        <PreviewTabs
          activeTab={isMobile ? TabsNames.PDF : activeTab}
          invoiceId={invoiceId}
          onUploadCompanyLogo={onUploadCompanyLogo}
          shouldDisplayUploadCompanyLogoBanner={shouldDisplayUploadCompanyLogoBanner}
          customerName={customerName}
        />
      ),
    };

    const formPanel: SplitScreenProps['panelB'] = {
      header: isMobile ? <FormPanelHeaderMobile /> : <FormPanelHeaderDesktop />,
      content: (
        <IssueInvoiceForm
          onSubmissionStateChange={onSubmissionStateChange}
          onSelectSendEmail={onSelectSendEmail}
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          paymentRequestLink={paymentRequestLink}
          onCopyPaymentLink={onCopyPaymentLink}
          {...props}
        />
      ),
    };

    return (
      <SplitScreen
        data-component={SendReminderScreen.displayName}
        header={<ShareInvoiceHeader headerText={headerText} onBack={onBack} />}
        panelA={isMobile ? formPanel : previewPanel}
        panelB={isMobile ? previewPanel : formPanel}
        footer={
          isMobile ? (
            <ShareInvoiceMobileFooter
              shouldSend={shouldSend}
              isLoading={isLoading}
              isSubmitting={isSubmitting}
              handleSubmit={handleSubmit}
              submitTarget={submitTarget}
            />
          ) : (
            <ShareInvoiceDesktopFooter
              shouldSend={shouldSend}
              isLoading={isLoading}
              isSubmitting={isSubmitting}
              handleSubmit={handleSubmit}
              submitTarget={submitTarget}
            />
          )
        }
        variant="1:1"
        isLoading={isLoading}
        data-testid="issue-invoice-screen"
        ref={ref}
      />
    );
  }
);

SendReminderScreen.displayName = 'SendReminderScreen';
