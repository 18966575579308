import { isEbill } from '@melio/ap-domain';
import { SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { BasicAmountInput } from './components/BasicAmountInput';
import { EbillAmountDropdown } from './components/EbillAmountDropdown';
import { AmountInputProps } from './types';

export const EBILL_SECTION_BANNER_TEST_ID = 'payment-flow-form-content-banners-eBill';

export const AmountInputContainer = (props: AmountInputProps) => {
  const { formatMessage } = useMelioIntl();
  if (props.bill && isEbill(props.bill)) {
    return (
      <>
        <SectionBanner
          data-testid={EBILL_SECTION_BANNER_TEST_ID}
          description={formatMessage('activities.paymentFlow.form.content.banners.eBill')}
          variant="informative"
        />
        <EbillAmountDropdown {...props} bill={props.bill} />
      </>
    );
  }

  return <BasicAmountInput {...props} />;
};
