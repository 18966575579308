import { Group, Table, Text } from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { identity } from 'lodash';

export const VendorCell = ({ vendor }: { vendor: Vendor }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Table.Cell data-testid="pending-approval-payments-row-vendor-cell">
      <Group variant="vertical" spacing="xxs">
        <Text textStyle="body3Semi" color="global.neutral.1000" shouldSupportEllipsis>
          {vendor.name}
        </Text>

        <Text textStyle="body4" color="global.neutral.800">
          {[vendor.nickname, vendor.accountNumber]
            .filter(identity)
            .join(formatMessage('activities.paymentsLateApprovalModal.table.cells.vendor.descriptionDivider'))}
        </Text>
      </Group>
    </Table.Cell>
  );
};
