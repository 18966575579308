import { getAvailableToConnectAccountingPlatforms, useAccountingPlatforms } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

export const useShouldShowConnectAccountingSoftwareOption = () => {
  const [isAccountingSoftwareEnabled] = useDevFeature(FeatureFlags.IsAccountingSoftwareEnabled, false);
  const { data, hasAccountingPlatform } = useAccountingPlatforms();
  const accountingPlatforms = getAvailableToConnectAccountingPlatforms(data);

  return isAccountingSoftwareEnabled && !hasAccountingPlatform && (accountingPlatforms ?? []).length > 0;
};
