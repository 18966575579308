import { useMemo } from 'react';

type Props = {
  defaultValues?: Record<string, unknown>;
  isVendorUniqueNameEnabled: boolean;
};

export const useShowVendorUniqueNameField = ({ defaultValues, isVendorUniqueNameEnabled }: Props) =>
  useMemo(
    () =>
      !!defaultValues?.['uniqueName'] &&
      isVendorUniqueNameEnabled &&
      defaultValues['companyName'] !== defaultValues['uniqueName'],
    [defaultValues, isVendorUniqueNameEnabled]
  );
