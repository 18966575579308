import { ReactNode } from 'react';
import { ButtonProps } from '@chakra-ui/button/src/button';
import { Button, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/react';

import { USBankChevronDownIcon } from '@/screens/embedded-experience-demo/assets/us-bank/USBankChevronDownIcon';

export const USBankButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  return (
    <Button
      ref={ref}
      color="#4C4C50"
      fontSize="0.8475rem"
      lineHeight="0.875rem"
      fontWeight="700"
      size="small"
      {...props}
    />
  );
});

export const MockDropdown = ({ label }: { label: string }) => (
  <Menu>
    <MenuButton as={USBankButton}>
      {label} <USBankChevronDownIcon />
    </MenuButton>
    <MenuList>
      <MenuItem>Download</MenuItem>
      <MenuItem>Create a Copy</MenuItem>
      <MenuItem>Mark as Draft</MenuItem>
      <MenuItem>Delete</MenuItem>
      <MenuItem>Attend a Workshop</MenuItem>
    </MenuList>
  </Menu>
);

export const USBankLegalText = ({ children }: { children: ReactNode }) => (
  <Text color="#6E6E6E" fontSize="0.75rem" fontWeight={400} lineHeight={'15px'}>
    {children}
  </Text>
);
