import { Table, Text } from '@melio/penny';
import { Vendor, VendorGroup } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type VendorsTabGroupsCellProps = {
  vendor: Vendor;
  filteredGroups?: VendorGroup['id'][];
};

export const VendorsTabGroupsCell = ({ vendor, filteredGroups }: VendorsTabGroupsCellProps) => {
  const { formatMessage } = useMelioIntl();

  const renderMessage = () => {
    const { groups } = vendor;
    if (!groups || groups.length === 0) {
      return (
        <Text textStyle="body3" color="global.neutral.800">
          {formatMessage('activities.payDashboard.vendorsTab.table.cells.groups.none')}
        </Text>
      );
    }
    const groupIdFromFilter = filteredGroups?.find((filteredGroupId) => groups.some((g) => g.id === filteredGroupId));

    const firstGroupName = (groups.find((group) => group.id === groupIdFromFilter)?.name || groups[0]?.name) ?? '';
    const rest = groups.length - 1;

    return <Text textStyle="body3">{`${firstGroupName}${rest > 0 ? ` + ${rest}` : ''}`}</Text>;
  };

  return <Table.Cell data-testid={`vendors-tab-row-${vendor.id}-groups-cell`}>{renderMessage()}</Table.Cell>;
};
