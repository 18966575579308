import { addWildcardToRoutes, useFlowRouting } from '@melio/ar-domain';

enum Paths {
  Routing = '',
  Bank = 'bank',
  PayByBank = 'bank/:fundingSourceId',
  Card = 'card',
}

export const usePaymentFulfillmentRouter = () => {
  const { createCallback, createCallbackWithParam } = useFlowRouting<Paths>({ withSearchparams: true });

  const goToBank = createCallback(Paths.Bank);
  const goToPayByBank = createCallbackWithParam(Paths.PayByBank, 'fundingSourceId');
  const goToCard = createCallback(Paths.Card);

  return {
    RedirectPaths: Paths,
    Paths: addWildcardToRoutes(Paths),
    goToBank,
    goToCard,
    goToPayByBank,
  };
};
