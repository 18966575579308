import { DeliveryMethodTypeOption, SupportedDeliveryMethodTypeOption } from '@melio/platform-api';
import { DeliveryMethodTypeOptionDisabledReasonsMap } from '@melio/platform-provider';

const deliveryMethodTypeOptionToListType: Record<
  SupportedDeliveryMethodTypeOption['type'],
  'international' | 'domestic'
> = {
  'bank-account': 'domestic',
  'paper-check': 'domestic',
  'domestic-wire-account': 'domestic',
  'international-account': 'international',
  'virtual-account': 'domestic',
  'virtual-card': 'domestic',
  'paypal-balance': 'domestic',
};

const filterUnsupportedTypOptions = (
  option: DeliveryMethodTypeOption,
  disabledReasons: DeliveryMethodTypeOptionDisabledReasonsMap
) =>
  option.type !== 'managed-account' &&
  option.type !== 'card' &&
  option.type !== 'biller-account' &&
  (option.supported || (disabledReasons[option.type] || []).includes(option.reason));

export const filterAndGroupDeliveryMethodTypeOptionsByPartner = (
  deliveryMethodTypeOptions: DeliveryMethodTypeOption[],
  disabledReasons: DeliveryMethodTypeOptionDisabledReasonsMap
) =>
  deliveryMethodTypeOptions
    .filter((opt): opt is SupportedDeliveryMethodTypeOption => filterUnsupportedTypOptions(opt, disabledReasons))
    .reduce<{
      domesticDeliveryMethodTypeOptions: SupportedDeliveryMethodTypeOption[];
      internationalDeliveryMethodTypeOptions: SupportedDeliveryMethodTypeOption[];
    }>(
      (acc, typeOption) => {
        const listType = deliveryMethodTypeOptionToListType[typeOption.type];

        if (listType === 'international') {
          acc.internationalDeliveryMethodTypeOptions.push(typeOption);
        } else if (listType === 'domestic') {
          acc.domesticDeliveryMethodTypeOptions.push(typeOption);
        }
        return acc;
      },
      {
        domesticDeliveryMethodTypeOptions: [],
        internationalDeliveryMethodTypeOptions: [],
      }
    );
