import { TableColumnDef } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

import { DebitDateCell } from './components/cells/DebitDateCell';
import { DeliveryDateCell } from './components/cells/DeliveryDateCell';
import { PaymentAmountCell } from './components/cells/PaymentAmountCell';
import { VendorCell } from './components/cells/VendorCell';
import { PaymentLateApprovalTableMeta } from './types';

export const usePaymentsLateApprovalTableColumns = () => {
  const { formatMessage } = useMelioIntl();

  return useMemo<TableColumnDef<Payment, PaymentLateApprovalTableMeta>[]>(() => {
    const vendorCell: TableColumnDef<Payment, PaymentLateApprovalTableMeta> = {
      id: 'vendor',
      size: 's',
      header: formatMessage('activities.paymentsLateApprovalModal.table.headers.vendor'),
      cell: ({ row: payment }) => (payment.vendor ? <VendorCell vendor={payment.vendor} /> : null),
    };
    const debitDateCell: TableColumnDef<Payment, PaymentLateApprovalTableMeta> = {
      id: 'debitDate',
      size: 's',
      header: formatMessage('activities.paymentsLateApprovalModal.table.headers.debitDate'),
      cell: ({ row: payment, meta }) => {
        const debitDate = meta?.changedFields[payment.id]?.date?.minScheduledDate;

        return <DebitDateCell payment={payment} debitDate={debitDate} />;
      },
    };
    const deliveryDateCell: TableColumnDef<Payment, PaymentLateApprovalTableMeta> = {
      id: 'deliveryDate',
      size: 's',
      header: formatMessage('activities.paymentsLateApprovalModal.table.headers.deliveryDate'),
      cell: ({ row: payment, meta }) => {
        const deliveryDate = meta?.changedFields[payment.id]?.date?.deliveryDate;

        return <DeliveryDateCell payment={payment} deliveryDate={deliveryDate} />;
      },
    };
    const paymentAmountCell: TableColumnDef<Payment, PaymentLateApprovalTableMeta> = {
      id: 'paymentAmount',
      size: 's',
      textAlign: 'end',
      header: formatMessage('activities.paymentsLateApprovalModal.table.headers.paymentAmount'),
      cell: ({ row: payment, meta }) => (
        <PaymentAmountCell payment={payment} fxQuote={meta?.changedFields[payment.id]?.quote} />
      ),
    };

    return [vendorCell, debitDateCell, deliveryDateCell, paymentAmountCell];
  }, [formatMessage]);
};
