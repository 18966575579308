import { Box } from '@chakra-ui/react';
import { Group, Icon, Table, Text, Tooltip } from '@melio/penny';
import { DeliveryPreferenceType, PaymentIntentWithDerivatives } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { LateBanner } from '../DeliveryDateSelectCell/components/LateBanner';

export type DeliveryDateCellProps = {
  deliveryPreference?: PaymentIntentWithDerivatives['computedDeliveryPreference'];
  isLate: boolean;
};

export const DeliveryDateCell = forwardRef<DeliveryDateCellProps, 'div'>(
  ({ deliveryPreference, isLate, ...props }, ref) => {
    const { formatDateTimeRange, formatMessage } = useMelioIntl();
    const [isUsHolidayChecksEnabled] = usePartnerFeature('UsHolidayCheck', false);
    const {
      settings: {
        batchPayments: { showLateIndicator },
      },
    } = useConfig();
    const date =
      deliveryPreference &&
      formatDateTimeRange(deliveryPreference.minDeliveryDate, deliveryPreference.maxDeliveryDate, {
        dateStyle: 'medium',
      });

    const isCheckDeliveryPreference = deliveryPreference?.type === DeliveryPreferenceType.Check;
    const shouldDisplayUsHolidayChecksHelper = isUsHolidayChecksEnabled && isCheckDeliveryPreference;

    return (
      <Table.Cell
        {...props}
        placeholder={formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.date.placeholder'
        )}
        data-component="DeliveryDateCell"
        ref={ref}
      >
        {date && (
          <Group alignItems="center" spacing="xs" width="full">
            <Text textStyle="inline" color="inherit" shouldSupportEllipsis>
              {date}
            </Text>
            {shouldDisplayUsHolidayChecksHelper && <USHolidayHelper />}
            {isLate && showLateIndicator && <LateBanner />}
          </Group>
        )}
      </Table.Cell>
    );
  }
);

const USHolidayHelper: React.FC = () => {
  const { formatMessage } = useMelioIntl();

  return (
    <Tooltip
      data-testid="batch-payments-delivery-date-cell-holidays-tooltip"
      content={formatMessage('activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.eoyHolidayHelper')}
    >
      <Box
        as={Icon}
        data-testid="batch-payments-delivery-date-cell-holidays-icon"
        type="info"
        size="small"
        color="default"
        tabIndex={0}
      />
    </Tooltip>
  );
};

DeliveryDateCell.displayName = 'DeliveryDateCell';
