import { useMelioQueryClient } from '@melio/api-client';
import { ServiceChargeCatalogsApiClient, ServiceChargeCatalogsApiInstance } from '@melio/ar-api-axios-client';

import { useCollection, UseCollectionProps } from '../../api-client';

export type UseServiceChargeCatalogsProps = UseCollectionProps<
  typeof ServiceChargeCatalogsApiInstance.getServiceChargeCatalogs,
  typeof ServiceChargeCatalogsApiClient.postServiceChargeCatalog,
  typeof ServiceChargeCatalogsApiClient.updateServiceChargeCatalogById
>;

export const useServiceChargeCatalogs = ({ onUpdate, ...props }: UseServiceChargeCatalogsProps = {}) => {
  const client = useMelioQueryClient();

  return useCollection({
    ...props,
    queryKey: 'ServiceChargeCatalogsApi',
    queryFn: ServiceChargeCatalogsApiInstance.getServiceChargeCatalogs,
    createFn: ServiceChargeCatalogsApiClient.postServiceChargeCatalog,
    deleteFn: ServiceChargeCatalogsApiClient.deleteServiceChargeCatalogById,
    updateFn: ServiceChargeCatalogsApiClient.updateServiceChargeCatalogById,
    onUpdate: (data, ...args) => {
      void client.invalidateQueries('ServiceChargeCatalogsApi', 'model', data.id);
      void onUpdate?.(data, ...args);
    },
    keepPreviousData: true,
  });
};

export type ServiceChargeCatalogCollection = ReturnType<typeof useServiceChargeCatalogs>;
