import {
  getFundingSourceIconType,
  getFundingSourceImageProps,
  PaymentReviewLineItem,
  useGetFundingSourceLabel,
} from '@melio/ap-widgets';
import { Group } from '@melio/penny';
import { PaymentFullyExpanded } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

import { LoanSection } from './loan-section/LoanSection';
import { SectionBlock } from './SectionBlock';

export const PaymentFailedPayFromSection = ({ payment }: { payment: PaymentFullyExpanded }) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const [isFinancingEnabled] = useDevFeature<boolean>(FeatureFlags.PlatformFinancing, false);

  const { fundingSource, loan } = payment;
  const { displayName, nickname } = fundingSource;

  const fundingSourceImage = getFundingSourceImageProps(fundingSource);
  const fundingSourceIconType = getFundingSourceIconType(fundingSource);
  const fundingSourceLabel = useGetFundingSourceLabel(fundingSource);

  const payFromContent =
    isFinancingEnabled && loan ? (
      <LoanSection payment={payment} />
    ) : (
      <>
        <SectionBlock.Title label={formatMessage('widgets.paymentDetails.payFrom.title')} />
        <PaymentReviewLineItem
          isInvalid={!payment.isFinanced}
          labelProps={{ label: fundingSourceLabel }}
          mainLabelProps={{ label: nickname || displayName }}
          {...(payment.isFinanced
            ? {}
            : {
                descriptionProps: {
                  label: formatMessage('widgets.paymentDetails.failed.failedDate', {
                    date: formatDate(payment.history.updatedAt, { dateStyle: 'medium' }),
                  }),
                },
              })}
          {...(fundingSourceImage ? { imageIcon: fundingSourceImage } : { icon: { type: fundingSourceIconType } })}
        />
      </>
    );
  return (
    <Group variant="vertical" spacing="s">
      {payFromContent}
    </Group>
  );
};
