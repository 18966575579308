import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const AvatarDropdownMenuComponentTheme: ComponentThemeType = (currentTheme) => ({
  baseStyle: {
    menuList: {
      paddingY: '8px',
      backgroundColor: currentTheme.colors.global.neutral['100'],
      borderColor: currentTheme.colors.global.neutral['400'],
      borderRadius: currentTheme.borderRadii.global['200'],
      boxShadow: '500',
    },
    container: {},
    avatar: {
      cursor: 'pointer',
      transition: 'background-color 0.1s ease-in-out',
    },
  },
});
