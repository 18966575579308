import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const MethodCardTheme: ComponentThemeType = (currentTheme) => ({
  baseStyle: {
    container: {
      padding: '24px',
      border: 'basic.light',
      borderRadius: currentTheme.borderRadii.global['200'],
      display: 'flex',
      flexDirection: 'column',
      _disabled: {
        color: currentTheme.colors.global.neutral['600'],
        borderStyle: 'solid',
        borderColor: currentTheme.colors.global.neutral['600'],
        borderWidth: '2px',
      },
    },
    icon: {
      alignSelf: 'center',
      marginRight: '20px',
      color: currentTheme.colors.global.neutral['1000'],
      _disabled: {
        filter: 'grayscale(100%)',
        color: currentTheme.colors.global.neutral['600'],
      },
    },
    topSection: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    actionContainer: {
      flexShrink: 0,
      fontSize: '14px',
    },
    bottomSection: {
      display: 'flex',
      flexDirection: 'column',
    },
    dividerContainer: {
      paddingY: '12px',
    },
    innerContainer: {
      display: 'flex',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    displayName: {
      ...currentTheme.textStyles.body2Semi,
    } as never,
  },
});
