import { Flex } from '@chakra-ui/react';
import {
  Container,
  Control,
  Form,
  FormLineItems,
  Group,
  NakedButton,
  Typography,
  useBreakpoint,
  useFieldArray,
  useWatch,
} from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useRef, useState } from 'react';

import { useLineItemAccessibility } from '../../../hooks/useLineItemAccessibility';
import { useLineItemAnalytics } from '../../../hooks/useLineItemAnalytics';
import { AddBillV2FormValues, RegisterFieldFn } from '../../../types';
import { createEmptyUnsyncedLineItem } from '../../../utils';
import { TotalAmount } from '../../TotalAmount';
import { LineItemsPaywallWrapper } from '../LineItemsPaywallWrapper';
import { UnSyncedLineItem } from './UnSyncedLineItem';

type Props = {
  formControl: Control<AddBillV2FormValues>;
  isHidden?: boolean;
  currency?: string;
  registerField: RegisterFieldFn;
  isLineItemsExpanded: boolean;
  onExpandLineItems: VoidFunction;
  isLineItemsEligible: boolean;
};

export const UnSyncedLineItemsForm = ({
  formControl,
  registerField,
  isHidden,
  currency,
  isLineItemsExpanded,
  onExpandLineItems,
  isLineItemsEligible,
}: Props) => {
  const { onDeleteLineItemSuccess } = useLineItemAccessibility();
  const { trackLineItemAdded, trackLineItemDeleted } = useLineItemAnalytics();
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { fields, append, remove } = useFieldArray<AddBillV2FormValues>({
    control: formControl,
    name: 'nonSyncedLineItems',
    rules: {
      minLength: 1,
    },
    shouldUnregister: false,
  });
  const footerRef = useRef<HTMLDivElement>(null);
  const [amount] = useWatch({
    control: formControl,
    name: ['amount'],
  });
  const totalAmount = formatCurrency(parseFloat(amount || '0'), currency);

  const hasLineItems = fields.length > 1;
  const [shouldAutoFocus, setShouldAutoFocus] = useState(false);
  const onAddLineItem = () => {
    if (!shouldAutoFocus) {
      setShouldAutoFocus(true);
    }

    if (!fields.length) {
      append(
        createEmptyUnsyncedLineItem({
          amount: amount ?? '0',
        })
      );
      return;
    }

    append(createEmptyUnsyncedLineItem());
    trackLineItemAdded();
    window.setTimeout(() => footerRef.current?.scrollIntoView({ behavior: 'smooth' }));
    return;
  };

  if (isHidden) {
    return null;
  }

  const canRemoveLineItem = fields.length > 1;

  const onRemoveLineItem = (index: number) => {
    remove(index);
    trackLineItemDeleted();
    onDeleteLineItemSuccess();
  };

  const lineItems = isMobile ? (
    <FormLineItems.MobileList>
      {fields.map((field, index) => (
        <UnSyncedLineItem
          index={index}
          key={field.id}
          amountFieldProps={registerField(`nonSyncedLineItems.${index}.amount`)}
          descriptionFieldProps={registerField(`nonSyncedLineItems.${index}.description`)}
          onClickRemoveLineItem={canRemoveLineItem ? () => onRemoveLineItem(index) : null}
          hasLineItems={hasLineItems}
          currency={currency}
          formControl={formControl}
          shouldAutoFocus={shouldAutoFocus}
        />
      ))}
    </FormLineItems.MobileList>
  ) : (
    <Form.ContentBox colSpan={16}>
      <FormLineItems>
        <FormLineItems.HeaderRow>
          <FormLineItems.HeaderCell size="xs" />
          <FormLineItems.HeaderCell size="xxl">
            <Typography.Label
              labelShouldSupportEllipsis
              label={formatMessage('activities.addBillV2.lineItems.unsynced.description.label')}
            />
          </FormLineItems.HeaderCell>
          <FormLineItems.HeaderCell size="m">
            <Typography.Label
              labelShouldSupportEllipsis
              label={formatMessage('activities.addBillV2.lineItems.unsynced.amount.placeholder')}
              description="*"
            />
          </FormLineItems.HeaderCell>
          {canRemoveLineItem && <FormLineItems.HeaderCell size={48} />}
        </FormLineItems.HeaderRow>

        <FormLineItems.Body>
          {fields.map((field, index) => (
            <UnSyncedLineItem
              index={index}
              currency={currency}
              key={field.id}
              amountFieldProps={registerField(`nonSyncedLineItems.${index}.amount`)}
              descriptionFieldProps={registerField(`nonSyncedLineItems.${index}.description`)}
              onClickRemoveLineItem={canRemoveLineItem ? () => onRemoveLineItem(index) : null}
              hasLineItems={hasLineItems}
              formControl={formControl}
              shouldAutoFocus={shouldAutoFocus}
            />
          ))}
        </FormLineItems.Body>
      </FormLineItems>
    </Form.ContentBox>
  );

  const summary = isMobile ? (
    <Form.ContentBox colSpan={16}>
      <Group variant="vertical" hasDivider>
        <Container paddingBottom="xs" ref={footerRef}>
          <NakedButton
            data-testid="add-bill-v2-unsynced-line-items-add-new-line"
            variant="secondary"
            size="medium"
            label={formatMessage('activities.addBillV2.lineItems.unsynced.addNewLine')}
            aria-label={formatMessage('activities.addBillV2.lineItems.unsynced.addNewLine.ariaLabel')}
            onClick={onAddLineItem}
          />
        </Container>
        <Container width="full" justifyContent="flex-end" paddingTop="xs">
          <TotalAmount amount={totalAmount} paddingBottom="xs-s" />
        </Container>
      </Group>
    </Form.ContentBox>
  ) : (
    <Form.ContentBox colSpan={16}>
      <Flex justifyContent="space-between" alignItems="flex-start" pb="m" ref={footerRef}>
        <NakedButton
          data-testid="add-bill-v2-unsynced-line-items-add-new-line"
          variant="secondary"
          label={formatMessage('activities.addBillV2.lineItems.unsynced.addNewLine')}
          aria-label={formatMessage('activities.addBillV2.lineItems.unsynced.addNewLine.ariaLabel')}
          onClick={onAddLineItem}
        />
        <TotalAmount amount={totalAmount} />
      </Flex>
    </Form.ContentBox>
  );

  return (
    <>
      <LineItemsPaywallWrapper
        isHidden={isHidden}
        isLineItemsExpanded={isLineItemsEligible && isLineItemsExpanded}
        onExpandLineItems={onExpandLineItems}
        title={formatMessage('activities.addBillV2.lineItems.unsynced.sectionTitle')}
        data-testid="add-bill-v2-unsynced-line-items-title"
      >
        {lineItems}
        {summary}
      </LineItemsPaywallWrapper>
    </>
  );
};
