import { useBreakpointValue } from '@chakra-ui/react';
import { datadogRum } from '@datadog/browser-rum';
import { usePaymentSchedulingPreference } from '@melio/ap-domain';
import { useFundingSourceUnverifiedText } from '@melio/ap-widgets';
import { Container, Group, GroupProps, Illustration, IllustrationProps, SectionBanner } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import {
  FundingSourceType,
  PaymentApprovalDecisionStatusEnum,
  Vendor,
  VendorAutoPayStatusEnum,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMonitoring } from '@melio/platform-monitoring';
import { useMelioIntl } from '@melio/platform-provider';
import { useCallback, useEffect, useMemo } from 'react';

import { MonitoredAction } from '../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import {
  PromoteAccountingSoftwareSyncBanner,
  useShowPromoteAccountingSoftwareSyncBanner,
} from '../../../promote-accounting-software-sync';
import { usePromotionsData } from '../../../promotions';
import { PaymentFlowDoneAction } from '../../../types';
import { PaymentScheduledPromotions } from './components/PaymentScheduledPromotions';
import { PaymentScheduledRiskDisclaimer } from './components/RiskDisclaimer';
import { PaymentScheduledUpsells } from './components/Upsells';
import { PaymentScheduledActionButtons } from './PaymentScheduledActionButtons/PaymentScheduledActionButtons';
import { PaymentDetails } from './PaymentsScheduled.types';
import {
  getPaymentsDescription,
  getPaymentsTitle,
  getSecondaryPaymentsDescriptionLabel,
  getSinglePaymentDetails,
  isRtpDeliveryPreferenceTypePayment,
  isVendorCapableEBillSubscription,
} from './PaymentsScheduled.utils';

export type PaymentsScheduledScreenProps = {
  paymentsDetails: PaymentDetails[];
  currency?: string;
  isLoading?: boolean;
  onClose: VoidFunction;
  onDone: (action: PaymentFlowDoneAction) => void;
  vendorsIdsWithoutEmail: Vendor['id'][];
  onNotifyVendors: VoidFunction;
  externalOrigin?: string | null;
};

export const PaymentsScheduledScreen = ({
  vendorsIdsWithoutEmail,
  paymentsDetails,
  currency,
  onClose,
  onDone,
  onNotifyVendors,
  externalOrigin,
  isLoading,
  ...props
}: PaymentsScheduledScreenProps) => {
  const { formatMessage } = useMelioIntl();
  const { scheduleBy } = usePaymentSchedulingPreference();
  const [isEbillsFFEnabled] = useDevFeature(FeatureFlags.EBills, false);
  const [isAutoPayEnabled] = useDevFeature(FeatureFlags.AutoPay, false);

  const paymentsIds = paymentsDetails.map(({ id }) => id);
  const flow = paymentsDetails.length > 1 ? 'batch-payments' : 'single-payment';

  const { endAction } = useMonitoring<MonitoredAction>();
  const endActionMonitoring = useCallback(() => {
    endAction('review_and_confirm');
    endAction('complete_legal_info');
    endAction('batch_payment_confirm');
  }, [endAction]);

  const singlePayment = getSinglePaymentDetails(paymentsDetails);
  const isRtpPayment = isRtpDeliveryPreferenceTypePayment(singlePayment);

  // This is a temporary solution, we should solve the responsive sizing in one of two ways: 1. Component level responsiveness OR 2. Layout level responsiveness for components
  const illustrationSize = useBreakpointValue<IllustrationProps['size']>({ xs: 'small', s: 'small', m: 'large' });
  const illustrationType = isRtpPayment ? 'fast' : 'success';
  const footerGap = useBreakpointValue<GroupProps['spacing']>({ xs: 'm', s: 'm', m: 'm' });
  const actionSectionWidth = useBreakpointValue<GroupProps['width']>({ xs: 'full', s: 'full', m: 'full' });

  const secondaryPaymentsDescriptionLabel = getSecondaryPaymentsDescriptionLabel(paymentsDetails);
  const isAutoPaySetupCapable =
    isAutoPayEnabled &&
    singlePayment?.vendor?.autoPayStatus === VendorAutoPayStatusEnum.Capable &&
    singlePayment.bills?.[0]?.origin === 'fiserv';
  const isEBillCapable = isEbillsFFEnabled && !!singlePayment && isVendorCapableEBillSubscription(singlePayment.vendor);

  const paymentDetails = useMemo(
    () =>
      singlePayment ||
      paymentsDetails
        .filter((payment) => payment.fundingSource.type === FundingSourceType.BankAccount)
        ?.sort((p1, p2) => p1.scheduledDate.getTime() - p2.scheduledDate.getTime())?.[0] ||
      (paymentsDetails[0] as PaymentDetails),
    [paymentsDetails, singlePayment]
  );

  const unVerifiedFundingSourceBannerText = useFundingSourceUnverifiedText({
    fundingSource: paymentDetails.fundingSource,
    effectiveScheduleDate: paymentDetails.scheduledDate,
  });

  const {
    isLoading: isPromotionsLoading,
    analyticsData: promotionsAnalyticsData,
    activePromotion,
  } = usePromotionsData();
  const { viewAnalyticsProps } = useShowPromoteAccountingSoftwareSyncBanner({ variant: 'payment-scheduled' });

  useAnalyticsView('Payment', !isPromotionsLoading, false, {
    PaymentId: paymentsIds,
    Feature: promotionsAnalyticsData,
    PaymentNeedApproval: paymentsDetails.some(
      ({ approvalDecisionStatus }) => approvalDecisionStatus === PaymentApprovalDecisionStatusEnum.Pending
    ),
    ...viewAnalyticsProps,
  });

  const { trackMarketing } = useAnalytics();
  useEffect(() => {
    if (isPromotionsLoading) {
      return;
    }

    const eventName = 'pay-bill_confirm-success';
    activePromotion
      ? trackMarketing(eventName, { Feature: { name: activePromotion, displayed: true } })
      : trackMarketing(eventName);
  }, [isPromotionsLoading, activePromotion, trackMarketing]);

  useEffect(() => {
    datadogRum.addTiming('payment_scheduled_ready');
  }, []);

  return (
    <NewSinglePaymentStepLayout
      data-component="PaymentScheduledActivity.PaymentsScheduledScreen"
      data-testid="payment-scheduled-activity-payments-scheduled-screen"
      isLoading={isLoading}
      ref={endActionMonitoring}
      {...props}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <Group variant="vertical" spacing={footerGap} width={actionSectionWidth}>
            <PaymentScheduledActionButtons
              onDone={onDone}
              onNotifyVendors={onNotifyVendors}
              vendorsIdsWithoutEmail={vendorsIdsWithoutEmail}
              externalOrigin={externalOrigin}
              autoPayCapable={isAutoPaySetupCapable}
              eBillCapable={isEBillCapable}
              paymentsDetails={paymentsDetails}
              showResolvePaymentsButton={false}
            />
          </Group>
        </NewSinglePaymentStepLayout.Actions>
      }
      bottomContent={
        <NewSinglePaymentStepLayout.Actions>
          <PaymentScheduledRiskDisclaimer vendors={paymentsDetails.map(({ vendor }) => vendor)} />
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <Group justifyContent="center">
        <Illustration type={illustrationType} size={illustrationSize} />
      </Group>
      <NewSinglePaymentStepLayout.Title data-testid="payment-scheduled-activity-payments-scheduled-screen-payments-title">
        {getPaymentsTitle(paymentsDetails)}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        {getPaymentsDescription(paymentsDetails, currency, scheduleBy)}
      </NewSinglePaymentStepLayout.Description>
      {secondaryPaymentsDescriptionLabel ? (
        <NewSinglePaymentStepLayout.Content>
          <Container textAlign="center">{formatMessage(secondaryPaymentsDescriptionLabel)}</Container>
        </NewSinglePaymentStepLayout.Content>
      ) : null}
      {unVerifiedFundingSourceBannerText && (
        <SectionBanner
          variant="informative"
          description={unVerifiedFundingSourceBannerText}
          data-testid="funding-source-unverified-banner"
        />
      )}
      <PaymentScheduledUpsells
        vendorId={singlePayment?.vendor.id || ''}
        isEBillCapable={isEBillCapable}
        isAutoPaySetupCapable={isAutoPaySetupCapable}
      />
      <PaymentScheduledPromotions flow={flow} paymentIds={paymentsIds} onDone={onDone} />
      <PromoteAccountingSoftwareSyncBanner
        variant="payment-scheduled"
        analyticsProps={{ PageName: 'payment-scheduled' }}
      />
    </NewSinglePaymentStepLayout>
  );
};

PaymentsScheduledScreen.displayName = 'PaymentScheduledActivity.PaymentsScheduledScreen';
