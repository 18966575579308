import {
  ACCOUNTING_CLIENT_SUBSCRIPTION_PLAN_SELECTED_ROUTE,
  CHECKOUT_URL,
  COMPLETE_DETAILS_ROUTE,
  OWNER_SHOULD_SETUP_SUBSCRIPTION_ROUTE,
  PAYMENT_METHOD_BANK_SELECT_URL,
  PLANS_URL,
  SUBSCRIPTION_GRACE_PLAN_IS_REQUIRED_ROUTE,
  SUBSCRIPTION_GRACE_SELECT_CLIENT_PLAN_ROUTE,
  SUBSCRIPTION_GRACE_SELECT_YOUR_PLAN_ROUTE,
} from '../constants';

const NOT_SUBSCRIBED_ORGS_ALLOWED_ROUTES = [
  PAYMENT_METHOD_BANK_SELECT_URL,
  PLANS_URL,
  CHECKOUT_URL,
  ACCOUNTING_CLIENT_SUBSCRIPTION_PLAN_SELECTED_ROUTE,
  SUBSCRIPTION_GRACE_SELECT_YOUR_PLAN_ROUTE,
  SUBSCRIPTION_GRACE_PLAN_IS_REQUIRED_ROUTE,
  SUBSCRIPTION_GRACE_SELECT_CLIENT_PLAN_ROUTE,
  OWNER_SHOULD_SETUP_SUBSCRIPTION_ROUTE,
  COMPLETE_DETAILS_ROUTE,
  '/accountants/client/.*/assign-plan',
];

export const isRouteAllowedForNotSubscribedOrg = (pathname: string) =>
  NOT_SUBSCRIBED_ORGS_ALLOWED_ROUTES.some((route) => new RegExp(`^${route}(/.*)?$`).test(pathname));
