import { Box } from '@chakra-ui/react';
import { Checkbox, Group, Modal, Text, useTheme } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useBoolean } from '@melio/platform-utils';
import { useEffect } from 'react';

import { TermsAndConditions } from './TermsAndConditions';

export const AgreementModal: React.VFC<{
  isOpen: boolean;
  onClose: VoidFunction;
  onSubmit: VoidFunction;
  agreementContent?: string;
}> = ({ isOpen, onClose, onSubmit, agreementContent }) => {
  const { formatMessage } = useMelioIntl();
  const theme = useTheme();
  const [isAgreementApproved, { toggle: setIsAgreementApproved }] = useBoolean();
  const { track, createTrackHandler } = useAnalytics();

  const trackAndHandleClick = createTrackHandler<{
    PageName: 'repayment-terms-and-conditions';
    Cta: 'agree' | 'cancel' | 'exit';
  }>('Payment', 'Click', { PageName: 'repayment-terms-and-conditions' });

  useEffect(() => {
    if (isOpen) {
      track('Payment', 'View', {
        PageName: 'repayment-terms-and-conditions',
      });
    }
  }, [isOpen, track]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => trackAndHandleClick({ Cta: 'exit' }, onClose)}
      data-testid="repayment-terms-screen-financing-agreement-modal"
      header={formatMessage('activities.repaymentTerms.screens.repaymentTerms.agreementModal.title')}
      primaryButton={{
        onClick: () => trackAndHandleClick({ Cta: 'agree' }, onSubmit),
        isDisabled: !isAgreementApproved,
        variant: 'primary',
        label: formatMessage('activities.repaymentTerms.screens.repaymentTerms.agreementModal.primaryButtonLabel'),
      }}
      secondaryButton={{
        onClick: () => trackAndHandleClick({ Cta: 'cancel' }, onClose),
        variant: 'secondary',
        label: formatMessage('activities.repaymentTerms.screens.repaymentTerms.agreementModal.secondaryButtonLabel'),
      }}
    >
      <Group variant="vertical" spacing="s">
        <Box data-testid="agreement-modal" border="basic.light">
          <Box
            margin="16px"
            marginRight="8px"
            paddingRight="8px"
            height="240px"
            textStyle="body4"
            color={theme.colors.global.neutral['900']}
            overflowY="scroll"
            dangerouslySetInnerHTML={{ __html: agreementContent || '' }}
          />
          <Box borderTop="basic.light" padding="16px">
            <Group spacing="xs" alignItems="center">
              <Checkbox
                isChecked={isAgreementApproved}
                onChange={setIsAgreementApproved}
                data-testid="agreement-modal-checkbox"
              />
              <Text textStyle="body4">
                {formatMessage('activities.repaymentTerms.screens.repaymentTerms.agreementModal.checkboxLabel')}
              </Text>
            </Group>
          </Box>
        </Box>
        <TermsAndConditions />
      </Group>
    </Modal>
  );
};
