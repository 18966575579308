import {
  isPayorPaymentFee,
  sumPaymentFees,
  useGuestPayorPayment,
  useGuestPayorPaymentRequestDetails,
} from '@melio/ar-domain';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { BaseLayout } from '../../layout';
import { GuestPaymentConfirmationScreen } from './screens';

export type GuestPaymentConfirmationActivityProps = {
  paymentRequestLink: string;
  onViewInvoice: VoidFunction;
  paymentId: string;
  onError?: ARErrorFunction;
};

export const GuestPaymentConfirmationActivity = withAnalyticsContext<GuestPaymentConfirmationActivityProps>(
  forwardRef(({ setAnalyticsProperties, paymentRequestLink, onViewInvoice, paymentId, onError }, ref) => {
    const {
      data: paymentRequestDetails,
      isLoading: isLoadingPaymentRequestDetails,
      error: paymentRequestDetailsError,
    } = useGuestPayorPaymentRequestDetails({
      paymentRequestLink,
    });
    const {
      data: paymentData,
      isLoading: isLoadingPayment,
      error: paymentError,
    } = useGuestPayorPayment({ id: paymentId });

    setAnalyticsProperties({ PaymentId: paymentId });

    useEffect(() => {
      if (isLoadingPayment || isLoadingPaymentRequestDetails) return;
      if (
        paymentRequestDetailsError ||
        !paymentData ||
        paymentError ||
        !paymentRequestDetails?.receivablePaymentDetails
      ) {
        onError?.(paymentRequestDetailsError ?? paymentError ?? { code: 'UNKNOWN_ERROR', message: 'Unknown error' });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingPaymentRequestDetails, isLoadingPayment]);

    if (
      isLoadingPaymentRequestDetails ||
      isLoadingPayment ||
      !paymentRequestDetails ||
      paymentRequestDetailsError ||
      !paymentData ||
      paymentError ||
      !paymentRequestDetails.receivablePaymentDetails
    ) {
      return <BaseLayout isLoading data-component={GuestPaymentConfirmationActivity.displayName} ref={ref} />;
    }

    const payorFees = paymentData.fees?.filter(isPayorPaymentFee) ?? [];
    const totalFeesAmount = sumPaymentFees(payorFees);

    return (
      <GuestPaymentConfirmationScreen
        ref={ref}
        amount={paymentRequestDetails.invoice.totalAmount}
        invoicePreviewUrl={paymentRequestDetails.invoice.fileInfo?.previewUrls[0]}
        onViewInvoice={onViewInvoice}
        debitDate={paymentData.scheduledDate}
        deliveryDate={paymentData.estimatedDelivery}
        totalFeeAmount={totalFeesAmount}
        invoiceNumber={paymentRequestDetails.invoice.invoiceNumber}
        vendorName={paymentRequestDetails.payeeDetails.companyName}
        fundingSourceDisplayData={paymentData.fundingSource?.displayName as string}
      />
    );
  })
);

GuestPaymentConfirmationActivity.displayName = 'GuestPaymentRequestConfirmationActivity';
