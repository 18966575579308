import { failedPaymentsSearchParams } from '../components/PaymentsTab/usePaymentsTabQueryParams';
import { sortPaymentsByUpdatedAt } from '../utils/sort.utils';
import { useGetNewPayments } from './useGetNewPayments';

type UseGetNewPaymentsParams = {
  preferenceKey: 'npePaymentsTabLastFailedBadge' | 'npeFailedFilterLastFailedBadge';
  limit: number;
};

const useGetNewFailedPayments = ({ preferenceKey, limit }: UseGetNewPaymentsParams) =>
  useGetNewPayments({
    preferenceKey,
    sortPaymentsBy: sortPaymentsByUpdatedAt,
    paymentsParams: {
      limit,
      expand: 'none',
      search: failedPaymentsSearchParams,
      sort: [
        {
          field: 'updatedAt',
          order: 'desc',
        },
      ],
    },
  });

export const usePaymentsTabNewFailedPayments = ({ limit }: { limit: number }) =>
  useGetNewFailedPayments({ preferenceKey: 'npePaymentsTabLastFailedBadge', limit });

export const useFiltersNewFailedPayments = () =>
  useGetNewFailedPayments({ preferenceKey: 'npeFailedFilterLastFailedBadge', limit: 1 });
