import React from 'react';
import { useIsMissingKycComplianceInformation } from '@melio/platform-kyc';
import { usePartnerFeature } from '@melio/platform-provider';

import { WithCompleteRequiredCompanyLegalInfoGuard } from '@/components/risk/WithCompleteRequiredCompanyLegalInfoGuard';
import { CompleteMtlDetailsScreen } from '@/screens/CompleteMtlDetails.screen';

export const WithKYCComplianceRouteGuard: React.FC<{ enabled?: boolean }> = ({ enabled = true, children }) => {
  const { isVisitedBlockerScreen, onBlockerScreenUserInteraction, isMissingKycComplianceInformation } =
    useIsMissingKycComplianceInformation();
  const [mtlKycUpliftCompanyDetailsEnabled] = usePartnerFeature('MtlKycUpliftCompanyDetails', false);

  if (enabled && !isVisitedBlockerScreen && isMissingKycComplianceInformation) {
    return <CompleteMtlDetailsScreen onDone={onBlockerScreenUserInteraction} />;
  }

  return (
    <WithCompleteRequiredCompanyLegalInfoGuard enabled={enabled && mtlKycUpliftCompanyDetailsEnabled}>
      {children}
    </WithCompleteRequiredCompanyLegalInfoGuard>
  );
};
