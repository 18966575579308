import { Payment } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { usePaymentNotPermittedText } from './usePaymentNotPermittedText';

export const usePaymentDescription = (payment: Payment) => {
  const [isSbbExclusiveUserViewEnabled] = useDevFeature<boolean>(FeatureFlags.SbbExclusiveUserView, false);
  const paymentNotPermittedText = usePaymentNotPermittedText(payment);

  if (isSbbExclusiveUserViewEnabled && paymentNotPermittedText) {
    return paymentNotPermittedText;
  }

  return null;
};
