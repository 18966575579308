import { Container, Group } from '@melio/penny';
import { ApprovalDecision, PaymentFullyExpanded } from '@melio/platform-api';
import { RefObject } from 'react';

import { MicroDepositModalRefHandlers } from '../../../../funding-sources';
import { usePaymentDescription } from '../../../hooks/usePaymentDescription';
import { PaymentDescription } from '../PaymentDescription';
import { PaymentDetailsTop } from '../PaymentDetailsTop';
import { NoteToSelfSection } from '../sections/NoteToSelfSection';
import { PaidToSection } from '../sections/PaidToSection';
import { PayFromSection } from '../sections/PayFromSection';
import { PaymentApprovalDecisionSection } from '../sections/PaymentApprovalDecisionSection';
import { PaymentScheduledBySection } from '../sections/PaymentScheduledBySection';
import { SectionWrapper } from '../sections/SectionWrapper';
import { useSections } from '../sections/useSections';
import { VendorReceivesSection } from '../sections/VendorReceivesSection';

export const PaymentScheduled = ({
  payment,
  billDetailsRef,
  approvalDecisions,
  microDepositModalRef,
}: {
  payment: PaymentFullyExpanded;
  billDetailsRef: RefObject<HTMLDivElement>;
  approvalDecisions?: ApprovalDecision[];
  microDepositModalRef: RefObject<MicroDepositModalRefHandlers>;
}) => {
  const { vendor, createdBy, history } = payment;

  const { showScheduledBySection, showApprovalDecisionSection } = useSections({ payment, approvalDecisions });

  const description = usePaymentDescription(payment);
  return (
    <Group variant="vertical" spacing="m">
      {description ? <PaymentDescription description={description} /> : null}
      <Container data-testid="pay-dashboard-payment-scheduled">
        <Group variant="vertical" spacing="m">
          <SectionWrapper>
            <PaymentDetailsTop payment={payment} />
          </SectionWrapper>

          <SectionWrapper>
            <PaidToSection payment={payment} billDetailsRef={billDetailsRef} />
          </SectionWrapper>

          {showApprovalDecisionSection || showScheduledBySection ? (
            <SectionWrapper>
              <Group variant="vertical" spacing="m">
                {showApprovalDecisionSection && approvalDecisions ? (
                  <PaymentApprovalDecisionSection approvalDecisions={approvalDecisions} />
                ) : null}

                {showScheduledBySection && createdBy ? (
                  <PaymentScheduledBySection scheduledDate={history.createdAt} user={createdBy} />
                ) : null}
              </Group>
            </SectionWrapper>
          ) : null}

          <NoteToSelfSection userNote={payment.userNote} />

          <SectionWrapper>
            <PayFromSection payment={payment} microDepositModalRef={microDepositModalRef} />
          </SectionWrapper>

          <SectionWrapper>
            <VendorReceivesSection vendorName={vendor.name} payment={payment} />
          </SectionWrapper>
        </Group>
      </Container>
    </Group>
  );
};
