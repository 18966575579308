import { Box } from '@chakra-ui/react';
import { Container, Group, Icon, StatusIconSolid, Text, Tooltip, Typography, useBreakpoint } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { Fragment } from 'react';

import { FinancingTabDisabledCreditDetailsPopover } from './FinancingTabDisabledCreditDetailsPopover';

export type FinancingTabCreditDetailsProps = {
  limit: number | undefined;
  balance: number | undefined;
  isLoading: boolean;
  isDisabled?: boolean;
};

const GroupItemWrapper = ({ children }: { children: React.ReactNode }) => {
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const Wrapper = isMobile ? Container : Fragment;

  return <Wrapper>{children}</Wrapper>;
};

export const FinancingTabCreditDetails = ({
  limit,
  balance,
  isLoading,
  isDisabled,
}: FinancingTabCreditDetailsProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { isExtraSmallScreen: isMobile } = useBreakpoint();

  const amountColor = isDisabled ? 'global.neutral.400' : 'global.neutral.1000';
  const alignCreditData = isMobile ? 'center' : 'flex-end';

  return (
    <FinancingTabDisabledCreditDetailsPopover showPopover={isDisabled}>
      <Group
        variant="horizontal"
        data-testid="financing-credit-details-container"
        hasDivider={isMobile}
        width={isMobile ? 'full' : 'auto'}
      >
        <GroupItemWrapper>
          <Group variant="vertical" spacing="none" alignItems={alignCreditData}>
            <Group spacing="xxs">
              {isDisabled ? (
                <StatusIconSolid data-testid="disabled-financing-available-credit-icon" size="small" variant="alert" />
              ) : (
                <Tooltip
                  data-testid="financing-available-credit-tooltip"
                  content={formatMessage(
                    'activities.payDashboard.financingTab.emptyState.creditDetails.availableCredit.tooltip.label'
                  )}
                >
                  <Box as={Icon} data-testid="financing-available-credit-icon" size="small" type="info" tabIndex={0} />
                </Tooltip>
              )}
              <Typography.Label
                label={formatMessage(
                  'activities.payDashboard.financingTab.emptyState.creditDetails.availableCredit.label'
                )}
              />
            </Group>
            {isLoading ? (
              <Text color="global.neutral.600">
                {formatMessage('activities.payDashboard.financingTab.emptyState.creditDetails.availableCredit.loading')}
              </Text>
            ) : (
              <Text textStyle="body2Semi" color={amountColor}>
                {balance
                  ? formatCurrency(balance)
                  : formatMessage(
                      'activities.payDashboard.financingTab.emptyState.creditDetails.availableCredit.notAvailable.placeholder'
                    )}
              </Text>
            )}
          </Group>
        </GroupItemWrapper>
        <GroupItemWrapper>
          <Group variant="vertical" spacing="none" alignItems={alignCreditData}>
            <Group spacing="xxs">
              {isDisabled ? (
                <StatusIconSolid data-testid="disabled-financing-total-credit-icon" size="small" variant="alert" />
              ) : (
                <Tooltip
                  data-testid="financing-total-credit-tooltip"
                  content={formatMessage(
                    'activities.payDashboard.financingTab.emptyState.creditDetails.totalCredit.tooltip.label'
                  )}
                >
                  <Icon data-testid="financing-total-credit-icon" size="small" type="info" />
                </Tooltip>
              )}
              <Typography.Label
                label={formatMessage('activities.payDashboard.financingTab.emptyState.creditDetails.totalCredit.label')}
              />
            </Group>
            {isLoading ? (
              <Text color="global.neutral.600">
                {formatMessage('activities.payDashboard.financingTab.emptyState.creditDetails.totalCredit.loading')}
              </Text>
            ) : (
              <Text textStyle="body2Semi" color={amountColor}>
                {limit
                  ? formatCurrency(limit)
                  : formatMessage(
                      'activities.payDashboard.financingTab.emptyState.creditDetails.totalCredit.notAvailable.placeholder'
                    )}
              </Text>
            )}
          </Group>
        </GroupItemWrapper>
      </Group>
    </FinancingTabDisabledCreditDetailsPopover>
  );
};
