import { Icon, NakedButton, SectionBanner, useBreakpointValue } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePermissions } from '@melio/platform-permissions';
import { differenceInCalendarDays } from 'date-fns';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useSubscriptionGracePeriod } from '../../../hooks';
import { useSubscriptionRouter } from '../../utils';
import { ContactOwnerForUpgradeModal } from '../ContactOwnerForUpgradeModal/ContactOwnerForUpgradeModal';

type GracePeriodBannerDetailsProps = {
  onClose: () => void;
};
export const BannerDetails = withAnalyticsContext<GracePeriodBannerDetailsProps>(
  ({ onClose, setAnalyticsProperties }) => {
    const { gracePeriodEndDate } = useSubscriptionGracePeriod();
    const { formatMessage } = useMelioIntl();
    const { goToSubscriptionPlans } = useSubscriptionRouter();
    const { pathname } = useLocation();
    const { track } = useAnalytics();
    const [isNonOwnerModalOpen, setIsNonOwnerModalOpen] = useState(false);
    const { can } = usePermissions();
    const isMobileOrTablet = useBreakpointValue({ s: true, m: false });

    // eslint-disable-next-line no-restricted-syntax
    const today = new Date();
    const remainingDaysToSubscribe = gracePeriodEndDate ? differenceInCalendarDays(gracePeriodEndDate, today) + 1 : -1;

    const canUpdateSubscription = can({
      subject: 'subscriptions:me',
      action: 'update',
    });

    setAnalyticsProperties({
      PageName: 'limited-time-offer',
      Intent: 'push-user-to-subscribe',
      Discount: 35,
      DaysToOfferExpired: remainingDaysToSubscribe,
    });

    useAnalyticsView('Dashboard', true, true);

    const handleOnClaim = () => {
      track('Dashboard', 'Click', {
        Cta: 'claim-now',
      });

      if (canUpdateSubscription) {
        goToSubscriptionPlans({
          returnUrl: pathname,
          analyticsFlow: 'existing-user-transition',
          analyticsEntryPoint: 'dashboard-limited-time-offer',
        });
      } else {
        setIsNonOwnerModalOpen(true);
      }
    };

    if (remainingDaysToSubscribe <= 0) {
      return null;
    }

    return (
      <>
        <SectionBanner
          description={formatMessage('activities.subscription.gracePeriodBanner.description', {
            gracePeriodEndDate: 'Dec 31',
            remainingNumberOfDays: remainingDaysToSubscribe,
          })}
          variant="brand"
          data-testid="subscription-grace-period-banner"
          data-role="alert"
          leftElement={<Icon type="crown" color="brand" aria-hidden />}
          showCloseIcon
          action={
            <NakedButton
              label={formatMessage('activities.subscription.gracePeriodBanner.actionLabel')}
              onClick={handleOnClaim}
              data-testid="subscription-grace-period-banner-claim-button"
            />
          }
          isCompact={!isMobileOrTablet}
          onClose={onClose}
        />
        <ContactOwnerForUpgradeModal isOpen={isNonOwnerModalOpen} onClose={() => setIsNonOwnerModalOpen(false)} />
      </>
    );
  }
);
