import { Plan, PlanInfo, useMelioIntl } from '@melio/ar-domain';
import { Container, Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { Footer, OnboardingLayout } from '../../../layouts';
import { SubscriptionDescription, SubscriptionHeader, SubscriptionPlanCard } from '../components';

export type SubscriptionScreenProps = {
  onDone: VoidFunction;
  onBack: VoidFunction;
  onClose: VoidFunction;
  planInfo: PlanInfo;
  plan: Plan;
};

export const SubscriptionScreen = forwardRef<SubscriptionScreenProps>(
  ({ onDone, onBack, onClose, planInfo, plan }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <OnboardingLayout
        screenId="subscription"
        headerContent={formatMessage('ar.onboarding.layout.header.label')}
        footerContent={
          <Footer
            tertiaryButtonProps={{
              label: formatMessage('ar.onboarding.components.subscription.buttons.close.label'),
              onClick: onClose,
            }}
            primaryButtonProps={{
              label: formatMessage('ar.onboarding.components.subscription.buttons.next.label'),
              onClick: onDone,
            }}
            secondaryButtonProps={{
              label: formatMessage('ar.onboarding.components.subscription.buttons.back.label'),
              onClick: onBack,
            }}
          />
        }
        isStickyFooter
      >
        <Container ref={ref}>
          <Group variant="vertical">
            <Group variant="vertical">
              <SubscriptionHeader />
              <SubscriptionDescription />
            </Group>
            <SubscriptionPlanCard plan={plan} planInfo={planInfo} />
          </Group>
        </Container>
      </OnboardingLayout>
    );
  }
);

SubscriptionScreen.displayName = 'SubscriptionScreen';
