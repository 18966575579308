import { Group, Modal, Text, useFormSubmissionController } from '@melio/penny';
import { EventProperties, useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  CreateVendorAnalyticsMetadataFlow,
  CreateVendorParams,
  isVendorBankAccountNotCreated,
  ModelError,
  useVendors,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { SystemMessageDisplay, useSystemMessage, withSystemMessageProvider } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { getErrorsByType, getInlineApiErrorsFields } from '../../apiErrorsUtils';
import { AddVendorOnDone, ErrorTypeToErrorCodesMap, VendorFormBannerApiErrorCode } from '../../types';
import { AddVendorFormFields, AddVendorFormWidget, useSwitchToUnmanagedForm } from '../AddVendorForm';
import { AddVendorFormWidgetProps } from '../AddVendorForm/types';

export type AddVendorModalWidgetWidgetProps = {
  managed?: AddVendorFormWidgetProps['managed'];
  defaultFormValues?: Override<
    Partial<AddVendorFormFields>,
    {
      companyName: string;
    }
  >;
  isOpen: boolean;
  analyticsProperties?: EventProperties;
  createVendorFlow?: CreateVendorAnalyticsMetadataFlow;
  onDone: AddVendorOnDone;
  onClose: VoidFunction;
  onSwitchToUnmanagedForm: (
    formData: Pick<AddVendorFormFields, 'companyName' | 'accountNumber' | 'postalCode'>
  ) => void;
};

export const AddVendorModalWidget = withSystemMessageProvider(
  withAnalyticsContext<AddVendorModalWidgetWidgetProps>(
    ({
      setAnalyticsProperties,
      managed,
      defaultFormValues,
      isOpen,
      analyticsProperties,
      createVendorFlow,
      onDone,
      onClose,
      onSwitchToUnmanagedForm,
      ...props
    }) => {
      const { onSubmissionStateChange, submitButtonProps, handleSubmit } =
        useFormSubmissionController<AddVendorFormFields>();
      const { formatMessage } = useMelioIntl();

      const [apiErrorsCodes, setApiErrorsCodes] = useState<Pick<ErrorTypeToErrorCodesMap, 'inline' | 'banner'>>();

      const { create: createVendor, isMutating: isCreatingVendor } = useVendors({ enabled: false });
      const { showMessage } = useSystemMessage();

      const { track, trackMarketing } = useAnalytics();

      setAnalyticsProperties({
        PageName: 'add-a-vendor',
        Intent: 'add-vendor-details',
      });

      useEffect(() => {
        track('Vendor', 'View', { VendorType: managed ? 'directory' : 'local' });
      }, [track, managed]);

      const { onSwitchToUnmanaged, shouldSwitchToUnmanaged } = useSwitchToUnmanagedForm({
        onSwitch: ({ companyName, accountNumber, postalCode }) => {
          onSwitchToUnmanagedForm({
            companyName,
            accountNumber,
            postalCode,
          });
          setApiErrorsCodes(undefined);
        },
      });

      type TrackCreateVendorStatusParams = {
        vendorType: 'directory' | 'local';
        status: 'success' | 'failure';
        vendorId?: string;
        inlineErrorsFields?: (keyof AddVendorFormFields)[];
        bannerErrorsTypes?: VendorFormBannerApiErrorCode[];
      };
      const trackCreateVendorStatus = ({
        vendorType,
        status,
        vendorId,
        inlineErrorsFields,
        bannerErrorsTypes,
      }: TrackCreateVendorStatusParams) => {
        const properties = {
          PageName: 'add-a-vendor',
          Intent: 'add-a-vendor',
          VendorType: vendorType,
          Status: status,
          InlineErrorsFields: inlineErrorsFields,
          BannerErrorsTypes: bannerErrorsTypes,
          ...(analyticsProperties ?? {}),
          ...(vendorId ? { VendorId: vendorId } : {}),
        };

        track('Vendor', 'Status', properties);
        status === 'success' && trackMarketing('bill-create_create-vendor', properties);
      };

      const handleCreateVendor = (data: CreateVendorParams) => {
        setApiErrorsCodes(undefined);

        const analyticsMetadata = createVendorFlow
          ? {
              flow: createVendorFlow,
            }
          : undefined;
        createVendor({ ...data, analyticsMetadata })
          .then((vendor) => {
            trackCreateVendorStatus({
              vendorType: managed ? 'directory' : 'local',
              status: 'success',
              vendorId: vendor.id,
            });

            const isBankAccountNotCreated = isVendorBankAccountNotCreated((vendor as Vendor).warnings);

            onDone({
              vendor: vendor as Vendor,
              isBankAccountNotCreated,
              bankAccountNumber: data.bankAccount?.accountNumber,
            });
          })
          .catch((error: ModelError) => handleCreateVendorFail(data, error));
      };

      const handleCreateVendorFail = (submittedData: CreateVendorParams, error: ModelError) => {
        const { inline, banner } = getErrorsByType(error, !!managed?.isZipCodeNeeded);

        if (inline?.length || banner?.length) {
          trackCreateVendorStatus({
            vendorType: managed ? 'directory' : 'local',
            status: 'failure',
            ...(inline?.length ? { inlineErrorsFields: getInlineApiErrorsFields(inline) } : {}),
            ...(banner?.length ? { bannerErrorsTypes: banner } : {}),
          });
          setApiErrorsCodes({ inline, banner });
        } else {
          // TODO: add monitor for a case where getErrorsByType return unknow key.length https://meliorisk.atlassian.net/browse/ME-41490

          showMessage({
            type: 'error',
            title: formatMessage('addVendor.createVendorToast.error', {
              companyName: submittedData.name,
            }),
            action: {
              text: formatMessage('addVendor.createVendorToast.error.action'),
              onAction: (close) => {
                close();
                handleCreateVendor(submittedData);
              },
              type: 'button',
            },
          });
        }

        if (shouldSwitchToUnmanaged(error)) {
          onSwitchToUnmanaged({
            companyName: submittedData.name,
            accountNumber: submittedData.accountNumber,
            postalCode: submittedData.managed?.zipCode,
          });
        }
      };

      const onSubmit = () => {
        track(`Vendor`, 'Click', {
          VendorType: managed ? 'directory' : 'local',
        });
        handleSubmit?.()();
      };

      return (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          data-testid="add-vendor-modal-widget"
          header={
            <Text as="h3" textStyle="heading2Semi">
              {formatMessage('widgets.addVendor.title', { companyName: defaultFormValues?.companyName || '' })}
            </Text>
          }
          primaryButton={{
            variant: 'primary',
            label: formatMessage('widgets.addVendor.saveDetails'),
            ...submitButtonProps,
            onClick: () => onSubmit(),
          }}
          {...props}
        >
          <Group variant="vertical" spacing="l" width="full">
            <SystemMessageDisplay />
            <AddVendorFormWidget
              onSubmit={handleCreateVendor}
              onSubmissionStateChange={onSubmissionStateChange}
              isSaving={isCreatingVendor}
              managed={managed}
              defaultValues={defaultFormValues}
              shouldHideCompanyNameField
              inlineApiErrorCodes={apiErrorsCodes?.inline}
              bannerApiErrorCodes={apiErrorsCodes?.banner}
            />
          </Group>
        </Modal>
      );
    }
  )
);

AddVendorModalWidget.displayName = 'AddVendorModalWidget';
