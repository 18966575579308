import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { object, SchemaOf, string, StringSchema } from 'yup';

import { EbillPayOption } from '../../../edit-ebill-payment-option';

export type EditPaymentAmountFormFields = { amountToPay?: string; paymentAmountOption?: EbillPayOption };

export const useEditAmountFormSchema = (): SchemaOf<EditPaymentAmountFormFields> => {
  const { formatMessage } = useMelioIntl();
  const { messageKey: positiveAmountMessageKey, validation: positiveAmountValidation } = createPositiveAmountTest();

  return object().shape({
    amountToPay: string()
      .required(formatMessage('activities.paymentFlow.form.content.amountToPay.required'))
      .test('amountToPayPositive', formatMessage(positiveAmountMessageKey), positiveAmountValidation),
    paymentAmountOption: new StringSchema<'amountDue' | 'minimumAmount' | 'accountBalance' | 'customAmount'>()
      .oneOf(['amountDue', 'minimumAmount', 'accountBalance', 'customAmount'])
      .required(),
  });
};

const createPositiveAmountTest = (): { messageKey: MessageKey; validation: () => boolean } => ({
  messageKey: 'activities.paymentFlow.form.content.amountToPay.validGreaterThanZero.label',
  validation: (value?: string) => {
    if (!value) {
      return true;
    }
    const number = Number(value);
    return number > 0;
  },
});
