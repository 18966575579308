import { useAnalytics } from '@melio/platform-analytics';
import { AccountAddressParams, Organization, useOrganizationsOnBehalf } from '@melio/platform-api';
import { useSystemMessage } from '@melio/platform-utils';

import { CompanyDetailsFormWidgetFields } from '../../CompanyDetails/widgets';
import { OwnerDetailsScreenOnDone } from '../OwnerDetails.screen';
import { buildCreateOrganizationOnBehalfParams } from '../OwnerDetails.screen.utils';
import { OwnerDetailsFormWidgetFields } from '../widgets';

type Props = {
  enteredData?: Partial<CompanyDetailsFormWidgetFields & OwnerDetailsFormWidgetFields>;
  onOrganizationCreateOnBehalf: (data: OwnerDetailsFormWidgetFields) => void;
  onOrganizationCreateOnBehalfSuccess: (createdOrganization: Organization) => void;
  onOrganizationCreateOnBehalfError?: (error: PlatformError) => void;
};

export const ERROR_USER_ALREADY_EXISTS = 'Recipient user already exists';

export const useOwnerDetailsStep = ({
  enteredData,
  onOrganizationCreateOnBehalf,
  onOrganizationCreateOnBehalfSuccess,
  onOrganizationCreateOnBehalfError,
}: Props) => {
  const { createOrganizationOnBehalf, isLoading } = useOrganizationsOnBehalf();
  const { showMessage } = useSystemMessage();
  const { track } = useAnalytics();

  const handleCreateOrganizationOnBehalfFailure = (error: PlatformError) => {
    const doesOwnerUserAlreadyExist = error.message === ERROR_USER_ALREADY_EXISTS;

    track('User', 'Status', {
      PageName: 'add-business-owner-details',
      Status: 'failure',
      CompanyOwner: false,
      Cta: 'add-company',
      isOwnerMelioUser: doesOwnerUserAlreadyExist,
      OwnerEmail: enteredData?.contactEmail,
    });

    if (!doesOwnerUserAlreadyExist) {
      showMessage({ type: 'error', title: error.message });
    }
    onOrganizationCreateOnBehalfError?.(error);
  };

  const handleCreateOrganizationOnBehalfSuccess = (createdOrganization: Organization) => {
    track('User', 'Status', {
      PageName: 'add-business-owner-details',
      Status: 'success',
      CompanyOwner: false,
      Cta: 'add-company',
      isOwnerMelioUser: false,
      OwnerEmail: enteredData?.contactEmail,
    });
    onOrganizationCreateOnBehalfSuccess(createdOrganization);
  };

  const handleStepDone: OwnerDetailsScreenOnDone = ({ contactFirstName, contactLastName, contactEmail, phone }) => {
    const createOrganizationOnBehalfParams = buildCreateOrganizationOnBehalfParams({
      name: enteredData?.name as string,
      address: {
        ...(enteredData?.address as AccountAddressParams),
        ...(enteredData?.aptNumber ? { aptNumber: enteredData?.aptNumber } : {}),
      },
      contactFirstName,
      contactLastName,
      contactEmail,
      phone,
    });

    onOrganizationCreateOnBehalf({ contactFirstName, contactLastName, contactEmail, phone });

    createOrganizationOnBehalf(createOrganizationOnBehalfParams)
      .then(handleCreateOrganizationOnBehalfSuccess)
      .catch(handleCreateOrganizationOnBehalfFailure);
  };

  return {
    handleStepDone,
    isSaving: isLoading,
  };
};
