import { createContext } from 'react';

type UserActivationContextType = {
  isTestMode: boolean;
  onActivateAccount: VoidFunction;
};

export const UserActivationContext = createContext<UserActivationContextType>({
  isTestMode: false,
  onActivateAccount: () => null,
});
