import { isFXCurrency } from '@melio/ap-domain';
import { Container, Group, StatusIconSolid, Text } from '@melio/penny';
import { PaymentApprovalDecisionStatusEnum, PaymentFullyExpanded } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { FormattedMessage, useMelioIntl } from '@melio/platform-i18n';

import { usePaymentConfirmationNumber } from '../../../../utils/pay-flow/usePaymentConfirmationNumber';
import { usePaymentAmountDetails } from '../../../PayDashboard/hooks/usePaymentAmountDetails';
import { getPaymentDetailsTitlePostfix } from './PaymentDetailsTop.utils';

const FxPaymentDebitsInUsd = ({ amountInUSD }: { amountInUSD: number }) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  return (
    <Group width="full" justifyContent="space-between" alignItems="center">
      <Text textStyle="body3">{formatMessage('widgets.paymentDetails.debitsInUsd')}</Text>
      <Group variant="vertical" spacing="xxs" alignItems="flex-end">
        <Group spacing="xs" alignItems="center">
          <Text data-testid="payment-details-top-debitInUsd" textStyle="body3">
            {formatCurrency(amountInUSD)}
          </Text>
        </Group>
      </Group>
    </Group>
  );
};

export const PaymentDetailsTop = ({ payment }: { payment: PaymentFullyExpanded }) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const [isAutoPayEnabled] = useDevFeature(FeatureFlags.AutoPay, false);

  const titlePostfix = getPaymentDetailsTitlePostfix(payment);
  const isAutoPayment = isAutoPayEnabled && payment.isAutoPayment;
  const isPaymentPendingApproval = payment.approvalDecisionStatus === PaymentApprovalDecisionStatusEnum.Pending;
  const recurringPaymentLastAmount = payment.subscriptionOccurrence?.billSubscription?.lastAmount;
  const { getConfirmationNumber } = usePaymentConfirmationNumber();
  const confirmationNumber = getConfirmationNumber(payment);
  const paymentAmountDetails = usePaymentAmountDetails(payment);

  const isFxPayment = isFXCurrency(payment.currency);
  const amount = isFxPayment && payment.foreignAmount ? payment.foreignAmount : payment.amount;
  return (
    <Container data-testid="payment-details-top">
      <Group variant="vertical" spacing="xxs">
        <Group width="full" justifyContent="space-between" alignItems="center">
          <Text data-testid="payment-details-top-subtitle" textStyle="body2Semi">
            <FormattedMessage id={`widgets.paymentDetails.${titlePostfix}`} />
          </Text>

          <Group variant="vertical" spacing="xxs" alignItems="flex-end">
            <Group spacing="xs" alignItems="center">
              <Text textStyle="body2Semi">{formatCurrency(amount, payment.currency)}</Text>
            </Group>
          </Group>
        </Group>
        {isFxPayment && payment.foreignAmount && payment.usdToForeignRate && (
          <FxPaymentDebitsInUsd amountInUSD={payment.amount} />
        )}
        <Group width="full" justifyContent="space-between" alignItems="center">
          {payment.markedAsPaid ? null : (
            <Text textStyle="body4" color="global.neutral.800" data-testid="payment-details-payment-id">
              {formatMessage('widgets.paymentDetails.confirmationNumber', { confirmationNumber })}
            </Text>
          )}
          <Group variant="vertical" spacing="xxs" alignItems="flex-end">
            {payment.subscriptionOccurrence || isAutoPayment ? paymentAmountDetails : null}
            {isFxPayment && payment.usdToForeignRate && (
              <Text textStyle="body4" color="global.neutral.800" data-testid="payment-details-our-rate">
                {payment.approvalDecisionStatus === PaymentApprovalDecisionStatusEnum.Pending
                  ? formatMessage('widgets.paymentDetails.rateDisclaimer', {
                      rate: payment.usdToForeignRate.toFixed(4),
                      currency: payment.currency,
                    })
                  : formatMessage('widgets.paymentDetails.ourRate', {
                      rate: payment.usdToForeignRate.toFixed(4),
                      currency: payment.currency,
                    })}
              </Text>
            )}
          </Group>
        </Group>
      </Group>

      {isPaymentPendingApproval && recurringPaymentLastAmount && recurringPaymentLastAmount > payment.amount && (
        <Container paddingTop="xxs">
          <Group
            spacing="xxs"
            justifyContent="flex-end"
            alignItems="center"
            data-testid="payment-details-top-last-amount"
          >
            <StatusIconSolid variant="warning" size="small" />

            <Text textStyle="body4Semi" color="global.neutral.800">
              {formatMessage('widgets.paymentDetails.recurring.lastAmount')}{' '}
            </Text>

            <Text textStyle="body4" color="global.neutral.800">
              {formatCurrency(recurringPaymentLastAmount)}
            </Text>
          </Group>
        </Container>
      )}
    </Container>
  );
};
