import { useBreakpoint } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { useImportFromQuickbooksButtonProps } from '../../../../promote-accounting-software-sync/useImportFromQuickbooksButtonProps';
import { PayDashboardEmptyState } from '../../PayDashboardEmptyState';
import { PayDashboardClearedState } from '../../PayDashboardEmptyState/PayDashboardClearedState';
import { useBillsTabEmptyState } from '../useBillsTabEmptyState';
import { AddBillDropdownMenu } from './AddBillDropdownMenu';

type Props = {
  onAddNewBillUpload: (files: File[]) => void;
  connectGmailCta: string;
  onAccountingPlatformConnect: VoidFunction;
  shouldShowConnectAccountingSoftwareOption: boolean;
  shouldShouldGmailOption: boolean;
  onGmailConnect: VoidFunction;
  manuallyAddNewBill: VoidFunction;
  onEmailCopy: (value: string) => void;
};

export const BillsTabEmptyState = ({ manuallyAddNewBill, onEmailCopy, ...props }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  const importFromQuickbooksButtonProps = useImportFromQuickbooksButtonProps({});

  const { emptyStateText, descriptionCTA } = useBillsTabEmptyState(props.shouldShouldGmailOption, onEmailCopy);

  return isExtraSmallScreen ? (
    <PayDashboardClearedState
      title={formatMessage('activities.payDashboard.billsTab.emptyState.mobile.title')}
      description={emptyStateText}
      descriptionCTA={descriptionCTA}
      illustrationSrc="pay-zero"
    />
  ) : (
    <PayDashboardEmptyState
      description={emptyStateText}
      descriptionCTA={descriptionCTA}
      customCTAComponent={
        !importFromQuickbooksButtonProps && (
          <AddBillDropdownMenu {...props} label={formatMessage('activities.payDashboard.billsTab.importButton')} />
        )
      }
      buttonProps={[
        ...(importFromQuickbooksButtonProps ? [importFromQuickbooksButtonProps] : []),
        {
          label: formatMessage('activities.payDashboard.billsTab.emptyState.primaryButton'),
          onClick: manuallyAddNewBill,
          'data-testid': 'bills-tab-empty-state-primary-button',
        },
      ]}
      animationSrc="https://platform-static.meliopayments.com/assets/melio/add-bill.lottie.json"
    />
  );
};
