export const FX_PROHIBITED_NAICS_CODES = [
  111910, 211120, 211130, 212111, 212112, 212113, 212114, 212115, 212210, 212220, 212221, 212222, 212230, 212290,
  212291, 212299, 212311, 212312, 212313, 212319, 212390, 212391, 212392, 212393, 212399, 213111, 213112, 213113,
  213114, 213115, 312230, 324110, 324191, 324199, 325110, 325120, 325193, 325312, 325314, 325411, 325920, 332992,
  332993, 332994, 336414, 336415, 336419, 336992, 423520, 424940, 445310, 445320, 446110, 453991, 454110, 454390,
  456110, 459991, 485310, 486110, 486210, 486910, 486990, 521110, 522110, 522120, 522130, 522180, 522190, 522210,
  522220, 522291, 522292, 522293, 522294, 522298, 522299, 522310, 522320, 522390, 523110, 523120, 523130, 523140,
  523150, 523160, 523210, 523910, 523920, 523930, 523940, 523991, 523999, 524113, 524114, 524126, 524127, 524128,
  524130, 524210, 524291, 524292, 524298, 525110, 525120, 525190, 525910, 525920, 525990, 551111, 551112, 551114,
  561613, 713210, 713290, 721120, 813110, 813211, 813219, 813311, 813312, 813319, 813410, 813940, 813990, 921110,
  921120, 921130, 921140, 921150, 921190, 922110, 922190, 923120, 923130, 923140, 924110, 924120, 925110, 925120,
  926110, 926120, 926130, 926140, 926150, 927110, 928110, 928120,
];
