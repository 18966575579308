import { useGuestPayorPaymentRequestDetails, useGuestPayorUserDetails } from '@melio/ar-domain';
import { useConfig } from '@melio/platform-provider';
import { useEffect, useMemo } from 'react';

import { usePaymentRequestStatus } from '../../../hooks';
import { generateDeepLinkToPaymentRequestPayDashboard } from '../../../utils';

export const useRedirectToPayDashboard = (paymentRequestLink: string, _isEnabled?: boolean) => {
  const { data: userDetails, isLoading: isUserDetailsLoading } = useGuestPayorUserDetails({ id: paymentRequestLink });
  const { data: paymentRequest, isLoading: isPaymentRequestLoading } = useGuestPayorPaymentRequestDetails({
    paymentRequestLink,
  });
  const { status, isLoading: isPaymentRequestStatusLoading } = usePaymentRequestStatus(paymentRequestLink);
  const { shouldGenerateDeepLinkToPaymentRequestPayDashboard } = useConfig().settings.guestPaymentFlow ?? {};

  const isEnabled = useMemo(
    () =>
      _isEnabled &&
      status === 'unpaid' &&
      shouldGenerateDeepLinkToPaymentRequestPayDashboard &&
      userDetails?.userType === 'registered-and-matched' &&
      paymentRequest?.isPayable,
    [
      _isEnabled,
      status,
      shouldGenerateDeepLinkToPaymentRequestPayDashboard,
      userDetails?.userType,
      paymentRequest?.isPayable,
    ]
  );

  const dashboardUrl = useMemo(
    () =>
      isEnabled && paymentRequest
        ? generateDeepLinkToPaymentRequestPayDashboard({
            paymentRequestId: paymentRequest.paymentRequestId,
            paymentRequestLink,
            vendorName: paymentRequest.payeeDetails.companyName,
          })
        : null,
    [isEnabled, paymentRequest, paymentRequestLink]
  );

  useEffect(() => {
    if (dashboardUrl) window.location.assign(dashboardUrl);
  }, [dashboardUrl]);

  return {
    isRedirecting: !!dashboardUrl,
    isLoading: isPaymentRequestLoading || isUserDetailsLoading || isPaymentRequestStatusLoading,
  };
};
