import { forwardRef } from '@melio/platform-utils';

import { usePaymentRequestStatus } from '../../hooks';
import { ErrorType } from '../../types';
import { GuestPaymentRequestErrorScreen } from './screens';

type GuestPaymentRequestErrorsActivityProps = {
  paymentRequestLink: string;
  onError?: ARErrorFunction;
};

export const GuestPaymentRequestErrorsActivity = forwardRef<GuestPaymentRequestErrorsActivityProps>(
  ({ paymentRequestLink, onError, ...props }, ref) => {
    const { status, isLoading } = usePaymentRequestStatus(paymentRequestLink);
    const errorType = status == 'cancelled' ? ErrorType.INVOICE_CANCELLED : ErrorType.GENERAL_ERROR;

    return <GuestPaymentRequestErrorScreen isLoading={isLoading} errorType={errorType} {...props} ref={ref} />;
  }
);

GuestPaymentRequestErrorsActivity.displayName = 'GuestPaymentRequestErrorsActivity';
