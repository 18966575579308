import { useDisclosure } from '@chakra-ui/react';
import { addWildcardToRoutes, RouteElement, useFlowRouting, withOutlet } from '@melio/ar-domain';
import { PartnerGroupEnum } from '@melio/partner-tools';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { PartnerName } from '@melio/platform-api';
import { useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  GuestPaymentConfirmationActivity,
  GuestPaymentRequestErrorsActivity,
  InvoiceDrawerActivity,
  InvoicePaidActivity,
  NoPaymentOptionsActivity,
  PaymentRequestTriageActivity,
} from '../activities';
import { FiservPaymentFulfillmentFlow } from './FiservPaymentFulfillment.flow';
import { PaymentFulfillmentFlow } from './PaymentFulfillment.flow';

export type PaymentRequestFlowProps = {
  partnerName?: PartnerName;
  partnerGroup?: PartnerGroupEnum | null;
  isNavigateApUsersToDashboardEnabled?: boolean;
  paymentRequestLink: string;
  onLoggedIn?: (accessToken: string, refreshToken?: string | null) => Promise<unknown>;
  onUpdatedLink: (paymentRequestLink: string) => void;
  onDone?: (...args: string[]) => unknown; // for testing purposes
};

export const PaymentRequestFlow = withAnalyticsContext<PaymentRequestFlowProps>(
  ({
    onLoggedIn,
    partnerName = 'melio',
    partnerGroup,
    isNavigateApUsersToDashboardEnabled,
    paymentRequestLink,
    onUpdatedLink,
    setAnalyticsProperties,
  }) => {
    setAnalyticsProperties({
      ProductName: 'ar',
      Flow: 'payment-request',
      PaymentRequestLink: paymentRequestLink,
      Intent: 'pay-invoice',
      PageName: 'payment-request',
    });

    const { track } = useAnalytics();

    const Router = usePaymentRequestFlow();

    const drawer = useDisclosure({
      onOpen: () => {
        track('PaymentRequest', 'Click', { Intent: 'view-invoice-file', Cta: 'view' });
      },
    });

    const onDownloadInvoice = useCallback((fileUrl?: string) => {
      track('PaymentRequest', 'Click', { Intent: 'download-invoice-file', Cta: 'download' });
      fileUrl && window.location.assign(fileUrl);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Routes>
        <Route
          element={withOutlet(
            <InvoiceDrawerActivity
              paymentRequestLink={paymentRequestLink}
              isOpened={drawer.isOpen}
              onDownloadInvoice={onDownloadInvoice}
              onClose={drawer.onClose}
              onError={() => {
                drawer.onClose();
                Router.goHome();
              }}
            />
          )}
        >
          <Route
            path={Router.Paths.Error}
            element={
              <GuestPaymentRequestErrorsActivity
                paymentRequestLink={paymentRequestLink}
                onError={() => Router.goHome({ replace: true })}
              />
            }
          />
          <Route
            path={Router.Paths.InvoicePaid}
            element={
              <InvoicePaidActivity
                paymentRequestLink={paymentRequestLink}
                onViewInvoice={drawer.onOpen}
                onError={() => Router.goHome({ replace: true })}
              />
            }
          />
          <Route
            path={Router.Paths.NoPaymentOptions}
            element={
              <NoPaymentOptionsActivity
                paymentRequestLink={paymentRequestLink}
                onViewInvoice={drawer.onOpen}
                onError={() => Router.goHome({ replace: true })}
              />
            }
          />
          <Route
            path={Router.Paths.PaymentConfirmation}
            element={
              <RouteElement
                component={GuestPaymentConfirmationActivity}
                pathToProps={{ paymentId: 'paymentId' }}
                paymentRequestLink={paymentRequestLink}
                onViewInvoice={drawer.onOpen}
                onError={() => Router.goHome({ replace: true })}
              />
            }
          />
          <Route
            path={Router.Paths.Pay}
            element={
              PartnerGroupEnum.FISERV === partnerGroup ? (
                <FiservPaymentFulfillmentFlow
                  paymentRequestLink={paymentRequestLink}
                  onViewInvoice={drawer.onOpen}
                  onDone={(id) => Router.goToPaymentConfirmation(id, { replace: true })}
                />
              ) : (
                <PaymentFulfillmentFlow
                  paymentRequestLink={paymentRequestLink}
                  onViewInvoice={drawer.onOpen}
                  onDone={(id) => Router.goToPaymentConfirmation(id, { replace: true })}
                  isNavigateApUsersToDashboardEnabled={isNavigateApUsersToDashboardEnabled}
                  partnerName={partnerName}
                  onLoggedIn={onLoggedIn}
                  onClose={Router.goHome}
                />
              )
            }
          />
          <Route
            path={Router.Paths.InitialRouting}
            element={
              <PaymentRequestTriageActivity
                paymentRequestLink={paymentRequestLink}
                onDone={(status, link) => {
                  switch (status) {
                    case 'updated':
                      return onUpdatedLink(link);
                    case 'paid':
                      return Router.goToInvoicePaid({ replace: true });
                    case 'error':
                    case 'cancelled':
                      return Router.goToError({ replace: true });
                    case 'no-payment-option':
                      return Router.goToNoPaymentOptions({ replace: true });
                    default:
                      return Router.goToFulfillmentFlow({ replace: true });
                  }
                }}
              />
            }
          />
        </Route>
      </Routes>
    );
  }
);

PaymentRequestFlow.displayName = 'PaymentRequestFlow';

const usePaymentRequestFlow = () => {
  enum Paths {
    InitialRouting = '',
    Pay = 'pay-by',
    Error = 'error',
    InvoicePaid = 'invoice-paid',
    NoPaymentOptions = 'no-payment-options',
    PaymentConfirmation = 'payment-confirmation/:paymentId',
  }
  const { createCallback, createCallbackWithParam } = useFlowRouting<Paths>();

  return {
    RedirectPaths: Paths,
    Paths: addWildcardToRoutes(Paths),
    goHome: createCallback(Paths.InitialRouting),
    goToError: createCallback(Paths.Error),
    goToInvoicePaid: createCallback(Paths.InvoicePaid),
    goToFulfillmentFlow: createCallback(Paths.Pay),
    goToNoPaymentOptions: createCallback(Paths.NoPaymentOptions),
    goToPaymentConfirmation: createCallbackWithParam(Paths.PaymentConfirmation, 'paymentId'),
  };
};
