import { Box } from '@chakra-ui/react';
import { Container, Layout } from '@melio/penny';
import { ComponentProps } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { SubscriptionTabsEnum } from '../../../constants';
import { SubscriptionCheckoutActivity } from '../../SubscriptionCheckout';
import { SubscriptionPlansActivity } from '../../SubscriptionPlans';

export type SubscriptionWizardScreenProps = {
  returnUrl?: string;
  planId: string;
  onClose: () => void;
  onBackOverride: ComponentProps<typeof SubscriptionCheckoutActivity>['onBackOverride'];
};

export const SubscriptionWizardScreen = ({
  returnUrl,
  planId,
  onClose,
  onBackOverride,
}: SubscriptionWizardScreenProps) => (
  <Layout data-testid="subscription-wizard-screen">
    <Container justifyContent="center" alignItems="center" width="full">
      <Box width="full">
        <Routes>
          <Route index element={<Navigate to={SubscriptionTabsEnum.Plans} replace />} />
          <Route
            path={`/${SubscriptionTabsEnum.Plans}/*`}
            element={<SubscriptionPlansActivity returnUrl={returnUrl} onBack={onClose} />}
          />
          <Route
            path={`/${SubscriptionTabsEnum.Checkout}/*`}
            element={
              <SubscriptionCheckoutActivity
                returnUrl={returnUrl}
                planId={planId}
                onClose={onClose}
                onBackOverride={onBackOverride}
              />
            }
          />
        </Routes>
      </Box>
    </Container>
  </Layout>
);
