import { useNonPositiveAmountsEffect } from '@melio/ap-domain';
import { PaymentApprovalDecisionStatusEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Navigate, Route, Routes } from 'react-router-dom';

import { PaymentScheduledActivity } from '../../PaymentScheduled';
import { RecurringPaymentScheduledActivity } from '../../RecurringPaymentScheduled';
import { PaymentFlowDoneAction } from '../../types';
import { PaymentFlowLoader } from '../components/PaymentFlowLoader';
import { PaymentFlowActivity } from '../PaymentFlowActivity/PaymentFlowActivity';
import { useSubmitErrorHandler } from '../util/useSubmitErrorHandler/useSubmitErrorHandler';
import { useCreatePaymentDefaults } from './useCreatePaymentDefaults';
import { useCreatePaymentHandlers } from './useCreatePaymentHandlers';
import { useCreatePaymentSubmit } from './useCreatePaymentSubmit';
import { isBillSubscription } from './utils';

type CreatePaymentActivityProps = {
  billId?: string;
  vendorId?: string;
  deliveryMethodId?: string;
  amountToPay?: string;
  onLoginToAccountPlatformAuth: VoidFunction;
  returnUrl?: string;
};

export const CreatePaymentActivity = ({
  billId,
  vendorId,
  deliveryMethodId,
  amountToPay,
  returnUrl,
  onLoginToAccountPlatformAuth,
}: CreatePaymentActivityProps) => {
  const { formatMessage } = useMelioIntl();

  const {
    result: paymentOrSubscription,
    error: submitError,
    onSubmit,
    isSubmitting,
  } = useCreatePaymentSubmit({
    billId,
    onError: useSubmitErrorHandler(),
  });

  const { handleClose, handleDone, handleSubmit } = useCreatePaymentHandlers({
    billId,
    returnUrl,
    onLoginToAccountPlatformAuth,
  });

  const { defaultValues, bill, isLoading } = useCreatePaymentDefaults({
    billId,
    vendorId,
    deliveryMethodId,
    amountToPay,
  });

  useNonPositiveAmountsEffect(handleClose, [bill?.balance]);

  if (isLoading) {
    return <PaymentFlowLoader />;
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PaymentFlowActivity
            defaultValues={defaultValues}
            billId={billId}
            title={formatMessage('activities.paymentFlow.form.header.title.newPayment')}
            isSubmitting={isSubmitting}
            submitError={submitError}
            onSubmit={(data) => onSubmit(data).then(() => handleSubmit())}
            onClose={handleClose}
          />
        }
      />
      <Route
        path="/success"
        element={
          paymentOrSubscription ? (
            isBillSubscription(paymentOrSubscription) ? (
              <RecurringPaymentScheduledActivity
                billSubscriptionId={paymentOrSubscription.id}
                onClose={handleClose}
                onDone={(action) =>
                  handleDone({
                    action,
                    paymentId: paymentOrSubscription.occurrences?.[0]?.paymentId || undefined,
                    isPendingApproval:
                      paymentOrSubscription.approvalDecisionStatus === PaymentApprovalDecisionStatusEnum.Pending,
                  })
                }
              />
            ) : (
              <PaymentScheduledActivity
                paymentsIds={[paymentOrSubscription.id]}
                onClose={handleClose}
                onDone={(action: PaymentFlowDoneAction) =>
                  handleDone({
                    action,
                    paymentId: paymentOrSubscription.id,
                    isPendingApproval:
                      paymentOrSubscription.approvalDecisionStatus === PaymentApprovalDecisionStatusEnum.Pending,
                  })
                }
              />
            )
          ) : (
            <Navigate to=".." />
          )
        }
      />
    </Routes>
  );
};
