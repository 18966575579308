import {
  FormDialog,
  FormInputs,
  FormWidgetProps,
  InvoiceDiscount,
  InvoiceDiscountTypeEnum,
  maxTotalAmount,
  useMelioIntl,
} from '@melio/ar-domain';
import { Form, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';
import * as yup from 'yup';

export type DiscountFormFields = {
  name: string;
  type: InvoiceDiscount['type'];
  value: number;
};

const useValidationSchema = () => {
  const { formatMessage } = useMelioIntl();

  return yup.object().shape({
    name: yup
      .string()
      .required(formatMessage('ar.settings.activities.addDiscounts.fields.name.validation.required'))
      .min(2, formatMessage('ar.settings.activities.addDiscounts.fields.name.validation.min', { length: 2 })),
    type: yup
      .string()
      .default(InvoiceDiscountTypeEnum.Percentage)
      .oneOf(Object.values(InvoiceDiscountTypeEnum))
      .required(formatMessage('ar.settings.activities.addDiscounts.fields.name.type.required')),
    value: yup
      .number()
      .nullable()
      .typeError(formatMessage('ar.settings.activities.addDiscounts.fields.value.validation.required'))
      .required(formatMessage('ar.settings.activities.addDiscounts.fields.value.validation.required'))
      .when('type', {
        is: InvoiceDiscountTypeEnum.Percentage,
        then: (schema) =>
          schema
            .lessThan(1, formatMessage('ar.settings.activities.addDiscounts.fields.value.validation.percentage.max'))
            .moreThan(0, formatMessage('ar.settings.activities.addDiscounts.fields.value.validation.percentage.min')),
        otherwise: (schema) => schema,
      })
      .when('type', {
        is: InvoiceDiscountTypeEnum.Amount,
        then: (schema) =>
          schema
            .lessThan(
              maxTotalAmount,
              formatMessage('ar.settings.activities.addDiscounts.fields.value.validation.value.max')
            )
            .moreThan(0.0001, formatMessage('ar.settings.activities.addDiscounts.fields.value.validation.value.min')),
      }),
  }) as yup.SchemaOf<DiscountFormFields>;
};

export type DiscountFormDialogScreenProps = Pick<
  FormWidgetProps<DiscountFormFields>,
  'defaultValues' | 'isSaving' | 'onSubmit'
> & {
  isOpen: boolean;
  onClose: VoidFunction;
  isLoading?: boolean;
  variant: 'add' | 'edit';
};
export const DiscountFormDialogScreen = forwardRef<DiscountFormDialogScreenProps>(
  ({ onSubmit, defaultValues, isSaving, isLoading, onClose, variant: mode, isOpen, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { registerField, watch, setValue, ...rest } = useMelioForm<DiscountFormFields>({
      schema: useValidationSchema(),
      isSaving,
      defaultValues: {
        name: defaultValues?.name || '',
        type: defaultValues?.type ?? InvoiceDiscountTypeEnum.Percentage,
        value: defaultValues?.value || ('' as never),
      },
      onSubmit,
      subscribeToDefaultValuesChanges: true,
    });

    const type = watch('type');
    const isTypePercentage = type === InvoiceDiscountTypeEnum.Percentage;

    useEffect(() => {
      if (mode === 'edit') {
        if (type !== defaultValues?.type) {
          setValue('value', '' as never);
        } else {
          setValue('value', defaultValues.value as number);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    return (
      <FormDialog
        data-component={DiscountFormDialogScreen.displayName}
        isOpen={isOpen}
        isLoading={isLoading}
        onClose={onClose}
        header={formatMessage(
          mode == 'add'
            ? 'ar.settings.activities.addDiscounts.add.header'
            : 'ar.settings.activities.addDiscounts.edit.header'
        )}
        primaryButton={{
          label: formatMessage(
            mode == 'add'
              ? 'ar.settings.activities.addDiscounts.primaryButton.add.label'
              : 'ar.settings.activities.addDiscounts.primaryButton.edit.label'
          ),
        }}
        secondaryButton={{
          label: formatMessage('ar.settings.activities.addDiscounts.secondaryButton.label'),
        }}
        useFormResults={rest}
        {...props}
        ref={ref}
      >
        <Form.TextField
          colSpan={2}
          labelProps={{
            label: formatMessage('ar.settings.activities.addDiscounts.discountForm.fields.name.title.label'),
          }}
          {...registerField('name')}
          placeholder={formatMessage('ar.settings.activities.addDiscounts.discountForm.fields.type.placeholder.text')}
          autoFocus
        />
        <Form.RadioGroup
          {...registerField('type')}
          colSpan={2}
          variant="horizontal"
          labelProps={{
            label: formatMessage('ar.settings.activities.addDiscounts.discountForm.fields.type.title.label'),
          }}
          options={[
            {
              mainLabelProps: {
                label: formatMessage(
                  'ar.settings.activities.addDiscounts.discountForm.fields.type.options.percentage.label'
                ),
              },
              value: InvoiceDiscountTypeEnum.Percentage,
            },
            {
              mainLabelProps: {
                label: formatMessage(
                  'ar.settings.activities.addDiscounts.discountForm.fields.type.options.fixedAmount.label'
                ),
              },
              value: InvoiceDiscountTypeEnum.Amount,
            },
          ]}
        />
        {isTypePercentage ? (
          <FormInputs.PercentageField
            colSpan={2}
            {...registerField('value')}
            labelProps={{
              label: formatMessage(
                'ar.settings.activities.addDiscounts.discountForm.fields.type.options.value.title.label'
              ),
            }}
            placeholder="0%"
          />
        ) : (
          <FormInputs.AmountField
            colSpan={2}
            {...registerField('value')}
            labelProps={{
              label: formatMessage(
                'ar.settings.activities.addDiscounts.discountForm.fields.type.options.value.title.label'
              ),
            }}
            placeholder="$0.00"
          />
        )}
      </FormDialog>
    );
  }
);
DiscountFormDialogScreen.displayName = 'DiscountFormDialogScreen';
