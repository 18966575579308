import { useMtlMessages, useMtlSchemaValidations } from '@melio/ap-domain';
import { Form, SelectOption, useMelioForm } from '@melio/penny';
import { useTrackAnalyticsOnFailedFormSubmit } from '@melio/platform-analytics';
import { Account, LegalInfoField } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { defaults, pickBy } from 'lodash';
import React from 'react';
import { object, SchemaOf, string } from 'yup';

import { AddressSearchWidget, IndustryTypeSelectWidget } from '../../form-controls';
import {
  CompleteLegalInfoFormWidgetFields,
  CompleteLegalInfoFormWidgetProps,
  ExistingTaxIdIsEinOption,
} from '../types';
import { BusinessTypeAndTaxInfo } from './components/BusinessTypeAndTaxInfo';

type Industry = NonNullable<Account['company']['industry']>;
type DynamicSchemaFields = {
  [K in keyof CompleteLegalInfoFormWidgetFields]?: SchemaOf<CompleteLegalInfoFormWidgetFields[K]>;
};

export const mandatorySchemaFields: (keyof CompleteLegalInfoFormWidgetFields)[] = [
  'existingTaxIdIsEin',
  'taxIdEinOverride',
];

export const useSchema = (
  missingLegalInfoFields: LegalInfoField[],
  defaultValues: Partial<CompleteLegalInfoFormWidgetFields>
) => {
  const { address, phoneNumber, industry, businessType, taxIdType, taxId, companyName } = useMtlSchemaValidations();

  return object().shape(
    pickBy(
      {
        legalCompanyName: companyName(),
        phoneNumber: phoneNumber(),
        legalAddress: address('legal-address'),
        industry: industry(),
        businessType: businessType(),
        ...(defaultValues.existingTaxIdIsEin !== 'Yes' && { taxInfoType: taxIdType() }),
        ...(!defaultValues.taxInfoIdentifier && { taxInfoIdentifier: taxId('taxInfoType') }),
        existingTaxIdIsEin: string().required(),
        taxIdEinOverride: string()
          .required()
          .optional()
          .when('existingTaxIdIsEin', {
            is: (existingTaxIdIsEin: ExistingTaxIdIsEinOption) => existingTaxIdIsEin === 'No',
            then: taxId('taxInfoType', true),
            otherwise: string().optional(),
          }),
      } as DynamicSchemaFields,
      (_: unknown, key: string) =>
        missingLegalInfoFields.includes(key as LegalInfoField) ||
        mandatorySchemaFields.includes(key as keyof CompleteLegalInfoFormWidgetFields)
    ) as DynamicSchemaFields
  ) as SchemaOf<CompleteLegalInfoFormWidgetFields>;
};

const resetLegalAddressIfMissingDetails = (missingLegalInfoFields: LegalInfoField[]) => {
  if (missingLegalInfoFields.includes('legalAddress')) {
    return {
      legalAddress: undefined,
    };
  }

  return {};
};

export const CompleteLegalInfoFormWidget = forwardRef<CompleteLegalInfoFormWidgetProps, 'form'>(
  (
    {
      onSubmit,
      missingLegalInfoFields,
      defaultValues: _defaultValues,
      isSaving,
      onSubmissionStateChange,
      taxIdType,
      companyName,
      ...props
    },
    ref
  ) => {
    const defaultValues = defaults(
      {
        ..._defaultValues,
        ...resetLegalAddressIfMissingDetails(missingLegalInfoFields),
      },
      {
        taxInfoType: undefined,
        existingTaxIdIsEin: 'Yes',
        taxInfoIdentifier: '',
        legalCompanyName: '',
        taxIdEinOverride: '',
      } as Partial<CompleteLegalInfoFormWidgetFields>
    );

    const { formatMessage } = useMelioIntl();

    const {
      labels: { company: companyLabels },
      placeholders,
    } = useMtlMessages();

    const useFormResult = useMelioForm<CompleteLegalInfoFormWidgetFields>({
      onSubmit,
      schema: useSchema(missingLegalInfoFields, defaultValues),
      defaultValues,
      isSaving,
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
    });
    const { formProps, formState, registerField, setValue } = useFormResult;

    useTrackAnalyticsOnFailedFormSubmit(formState, 'Organization', 'Status');

    const handleIndustryCreatable = (option: SelectOption) => {
      setValue('industry', option.value as unknown as Industry);
    };

    return (
      <Form data-component="CompleteLegalInfoFormWidget" ref={ref} {...props} {...formProps}>
        <Form.TextField
          isHidden={!missingLegalInfoFields.includes('legalCompanyName')}
          labelProps={{ label: companyLabels.legalName }}
          placeholder={placeholders.companyName}
          helperTextProps={{ label: formatMessage('widgets.completeLegalInfo.legalCompanyName.helperText') }}
          {...registerField('legalCompanyName')}
        />
        <Form.PhoneField
          {...registerField('phoneNumber')}
          isHidden={!missingLegalInfoFields.includes('phoneNumber')}
          labelProps={{ label: companyLabels.phoneNumber }}
          placeholder={placeholders.phoneNumber}
        />
        <AddressSearchWidget
          {...registerField('legalAddress')}
          isHidden={!missingLegalInfoFields.includes('legalAddress')}
          labelProps={{ label: companyLabels.legalAddress }}
          placeholder={placeholders.address}
        />
        <IndustryTypeSelectWidget
          {...registerField('industry')}
          onCreateOption={handleIndustryCreatable}
          isHidden={!missingLegalInfoFields.includes('industry')}
          labelProps={{ label: companyLabels.industry }}
          placeholder={placeholders.industry}
        />
        <BusinessTypeAndTaxInfo
          missingLegalInfoFields={missingLegalInfoFields}
          existingBusinessType={!!defaultValues.businessType}
          existingTaxInfoIdentifier={defaultValues.taxInfoIdentifier}
          companyName={companyName}
          {...useFormResult}
        />
      </Form>
    );
  }
);

CompleteLegalInfoFormWidget.displayName = 'CompleteLegalInfoFormWidget';
