import { useMelioIntl } from '@melio/ar-domain';
import { Button, Container } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type ServiceChargesTableToolbarProps = {
  onAdd: VoidFunction;
};

export const ServiceChargesTableToolbar = forwardRef<ServiceChargesTableToolbarProps>(({ onAdd }, ref) => {
  const { formatMessage } = useMelioIntl();
  return (
    <Container
      justifyContent="flex-end"
      ref={ref}
      paddingX="xxs"
      paddingY="xxs"
      data-component={ServiceChargesTableToolbar.displayName}
    >
      <Button
        variant="tertiary"
        label={formatMessage(
          'ar.settings.activities.serviceChagresTable.components.serviceChagresTableToolbar.addButton.label'
        )}
        onClick={onAdd}
        data-testid="add-service-charge-button"
      />
    </Container>
  );
});
ServiceChargesTableToolbar.displayName = 'ServiceChargesTableToolbar';
