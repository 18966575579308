import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { usePlansTiers } from '.';
import { useSubscription } from './useSubscription';

export const useShouldConfirmSubscriptionDowngrade = () => {
  const [isDowngradeFlowEnabled] = useDevFeature(FeatureFlags.PlatformSubscriptionsDowngradeFlow, false);
  const subscription = useSubscription();
  const { getIsFreePlan, getPlanTier } = usePlansTiers();

  if (!isDowngradeFlowEnabled) {
    return false;
  }

  const lastPaidSubscription = subscription?.lastPaidSubscription;

  if (!lastPaidSubscription) {
    return false;
  }

  const isCurrentPlanFree = getIsFreePlan(subscription.planId);
  const isCurrentPlanDowngradedFromLastPlan =
    getPlanTier(lastPaidSubscription.planId) > getPlanTier(subscription.planId);

  return (
    isCurrentPlanFree && !lastPaidSubscription.doesNextSubscriptionChangesApplied && isCurrentPlanDowngradedFromLastPlan
  );
};
