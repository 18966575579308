import { addWildcardToRoutes, RouteElement, useNavigate, withOutlet } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import {
  AddDiscountDialogActivity,
  DeleteDiscountDialogActivity,
  DiscountsTableActivity,
  EditDiscountDialogActivity,
} from '../../activities';

export const DiscountsTableFlow = forwardRef((props, ref) => {
  const { Paths, goToAdd, goToTable, goToEdit, goToDelete } = useNavigation();
  return (
    <Routes>
      <Route
        path={Paths.Table}
        element={withOutlet(
          <DiscountsTableActivity onDelete={goToDelete} onEdit={goToEdit} onAdd={goToAdd} {...props} ref={ref} />
        )}
      >
        <Route path={Paths.Add} element={<AddDiscountDialogActivity onClose={goToTable} onDone={goToTable} />} />
        <Route
          path={Paths.Edit}
          element={
            <RouteElement
              component={EditDiscountDialogActivity}
              pathToProps={{ id: 'discountId' }}
              onClose={goToTable}
              onDone={goToTable}
            />
          }
        />
        <Route
          path={Paths.Delete}
          element={
            <RouteElement
              component={DeleteDiscountDialogActivity}
              pathToProps={{ id: 'discountId' }}
              onClose={goToTable}
              onDone={goToTable}
            />
          }
        />
      </Route>
    </Routes>
  );
});

const useNavigation = () => {
  enum Paths {
    Table = '',
    Add = 'add',
    Edit = ':id/edit',
    Delete = ':id/delete',
  }

  const navigate = useNavigate<Paths>();

  return {
    Paths: addWildcardToRoutes(Paths),
    goToAdd: () => navigate(Paths.Add, { keepSystemMessage: true }),
    goToTable: () => navigate(Paths.Table, { keepSystemMessage: true }),
    goToEdit: (id: string) => navigate(Paths.Edit, { pathParams: { id }, keepSystemMessage: true }),
    goToDelete: (id: string) => navigate(Paths.Delete, { pathParams: { id }, keepSystemMessage: true }),
  };
};
