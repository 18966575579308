import { useMelioIntl } from '@melio/platform-i18n';

import { PayDashboardClearedState } from '../../PayDashboardEmptyState/PayDashboardClearedState';

export const PaymentsTabClearedState = ({ hidden }: { hidden?: boolean }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <PayDashboardClearedState
      title={formatMessage('activities.payDashboard.paymentsTab.clearedState.title')}
      description={formatMessage('activities.payDashboard.paymentsTab.clearedState.description')}
      illustrationSrc="pay-zero"
      hidden={hidden}
    />
  );
};
