import { Group, Text } from '@melio/penny';
import { PaymentStatusEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { orderBy } from 'lodash';

type Payment = {
  id: string;
  scheduledDate: Date;
  status: PaymentStatusEnum;
  amount: number;
  currency: string;
};

export const BillPaymentsOverview = ({ payments }: { payments: Payment[] }) => {
  const { formatMessage, formatDate, formatCurrency } = useMelioIntl();

  const scheduledPayments = payments.filter(
    (payment) => payment.status === PaymentStatusEnum.Scheduled || payment.status === PaymentStatusEnum.Blocked
  );
  const inProgressPayments = payments.filter((payment) => payment.status === PaymentStatusEnum.InProgress);
  const completedPayments = payments.filter((payment) => payment.status === PaymentStatusEnum.Completed);

  const renderPayments = (payments: Payment[], status: 'scheduled' | 'inProgress' | 'completed') => {
    const sortedPayments = orderBy(payments, 'scheduledDate', 'desc');

    return (
      <>
        {sortedPayments.map((payment) => (
          <Group
            key={payment.id}
            justifyContent="space-between"
            data-testid={`bill-details-payments-overview-${payment.id}`}
          >
            <Text color="global.neutral.800" textStyle="body2">
              {formatMessage(`widgets.billDetails.paymentsOverview.${status}`, {
                date: formatDate(payment.scheduledDate, { dateStyle: 'medium' }),
              })}
            </Text>
            <Text color="global.neutral.800" textStyle="body2">
              {formatCurrency(payment.amount, payment.currency)}
            </Text>
          </Group>
        ))}
      </>
    );
  };

  return (
    <Group variant="vertical" justifyContent="space-between" data-testid="bill-details-payments-overview">
      {renderPayments(scheduledPayments, 'scheduled')}
      {renderPayments(inProgressPayments, 'inProgress')}
      {renderPayments(completedPayments, 'completed')}
    </Group>
  );
};
