import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { InternationalApiClient, InternationalApiGetInternationalRateRequest } from '@melio/platform-api-axios-client';

const getInternationalRate = (foreignCurrency?: string | undefined, melioAccount?: string, foreignAmount?: number) =>
  InternationalApiClient.getInternationalRate(foreignCurrency as string, melioAccount, foreignAmount);

export type UseInternationalRateProps = UseModelApiProps<typeof getInternationalRate> &
  Omit<InternationalApiGetInternationalRateRequest, 'foreignCurrency'> & { foreignCurrency?: string };

export const useInternationalRate = (props: UseInternationalRateProps) => {
  const { isLoading, isFetching, ...rest } = useModelApi({
    ...props,
    enabled: props?.enabled !== false && !!props.foreignCurrency && props.foreignCurrency !== 'USD',
    id: 'InternationalApiRate',
    cacheTime: 0,
    queryKey: ['InternationalApi', props.foreignCurrency, props?.foreignAmount],
    queryFn: async () => {
      try {
        const response = await getInternationalRate(props.foreignCurrency, props.melioAccount, props.foreignAmount);
        return response;
      } catch (error) {
        props.onError?.(error as Error);
        throw error;
      }
    },
  });

  return {
    ...rest,
    isLoading: isLoading || isFetching,
  };
};
