/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCheckFeePromotions } from '@melio/ap-widgets';
import {
  BillingFeeSetting,
  FeesBreakdown,
  FeesBreakdownRequest,
  FeeType,
  hasActiveBillingFeeMethod,
  useFeeCatalog,
  useFeesBreakdown,
  useFundingSources,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { sumBy } from '@melio/platform-utils';
import Big from 'big.js';
import { min } from 'lodash';

import { SchedulePaymentIntent } from '../../../../types';

export const getBalanceSum = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]): number =>
  sumBy(
    paymentIntentsWithDerivatives.flatMap(({ bills }) => bills),
    (bill) => bill.balance
  );

export const getFeesSum = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]): number =>
  sumBy(paymentIntentsWithDerivatives, ({ paymentIntent }) =>
    paymentIntent.estimatedFees ? sumBy(paymentIntent.estimatedFees, (fee) => fee.amount) : 0
  );

const useFreeAchToAchApplied = (feesBreakdown?: FeesBreakdown) => {
  const { data: feeCatalogEntries } = useFeeCatalog();

  const singleAchToAchFee = feeCatalogEntries?.find((entry) => entry.feeType === FeeType.AchToAch);

  if (!singleAchToAchFee || singleAchToAchFee.valueType !== 'fixed' || !singleAchToAchFee.value) {
    return;
  }

  const achToAchTotalDiscount =
    feesBreakdown?.feesBreakdown.find((fee) => fee.type === FeeType.AchToAch)?.discount || 0;

  return {
    totalAchToAchDiscount: achToAchTotalDiscount,
    freeAchToAchApplied: new Big(achToAchTotalDiscount).div(singleAchToAchFee.value).toNumber(),
  };
};

export const useFreeChecks = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]) => {
  const { freeChecks: freeChecksData, isLoading } = useCheckFeePromotions();

  const totalAchToCheckPayments = paymentIntentsWithDerivatives.filter(({ paymentIntent }) =>
    paymentIntent.estimatedFees?.find(({ type }) => type === FeeType.AchToCheck)
  )?.length;

  const freeChecksToApply = min([freeChecksData.available, totalAchToCheckPayments]);

  return {
    freeChecksToApply,
    isLoading,
  };
};

export const useAmountHeaderFees = (
  paymentIntentsWithDerivatives: SchedulePaymentIntent[]
): { feesBreakdown: FeesBreakdown | undefined; isLoading: boolean } => {
  const { data: fundingSources = [], isLoading: isLoadingFundingSources } = useFundingSources();

  const { data: feesBreakdown, isLoading: isLoadingFeesBreakdown } = useFeesBreakdown({
    params: {
      feesBreakdownParams: paymentIntentsWithDerivatives
        .map(({ paymentIntent, vendor }) => {
          const fundingSource = fundingSources.find((fs) => fs.id === paymentIntent.fundingSourceId);
          const deliveryMethod = vendor.deliveryMethods.find((dm) => dm.id === paymentIntent.deliveryMethodId);

          if (!deliveryMethod || !fundingSource || !paymentIntent.selectedDeliveryPreferenceType) {
            return null;
          }

          return {
            deliveryMethodType: deliveryMethod.type,
            deliveryPreference: paymentIntent.selectedDeliveryPreferenceType,
            fundingSourceType: fundingSource.type,
            paymentAmount: paymentIntent.amountToPay!,
            fundingSourceId: paymentIntent.fundingSourceId,
            deliveryMethodId: paymentIntent.deliveryMethodId,
            isFinancing: !!paymentIntent?.financingEligibilityToken,
            billIds: paymentIntent.billPayments?.map((billPayment) => billPayment.billId) || [],
          };
        })
        .filter(Boolean) as FeesBreakdownRequest['feesBreakdownParams'],
    },
    enabled: !!fundingSources.length,
  });

  return { feesBreakdown, isLoading: isLoadingFeesBreakdown || isLoadingFundingSources };
};

type UseGetTooltipTextProps = {
  hasBillingFeeMethod?: boolean;
  availableFreeChecks?: number;
  freeCheckDiscount?: number;
  achToAchDiscountData?: {
    totalAchToAchDiscount: number;
    freeAchToAchApplied: number;
  };
};

const useGetTooltipText = ({
  hasBillingFeeMethod,
  availableFreeChecks,
  freeCheckDiscount,
  achToAchDiscountData,
}: UseGetTooltipTextProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  const textItems = [
    formatMessage(
      'activities.batchPayments.screens.paymentIntentsTable.amountHeader.totalFees.tooltip.items.feesChargedIndividually'
    ),
  ];

  if (hasBillingFeeMethod) {
    textItems.push(
      formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.amountHeader.totalFees.tooltip.items.transactionFees'
      )
    );
  }

  if (availableFreeChecks) {
    textItems.push(
      formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.amountHeader.totalFees.tooltip.items.freeChecksUsed',
        { available: availableFreeChecks, discount: freeCheckDiscount && formatCurrency(freeCheckDiscount) }
      )
    );
  }

  if (achToAchDiscountData) {
    const { totalAchToAchDiscount, freeAchToAchApplied } = achToAchDiscountData;

    textItems.push(
      formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.amountHeader.totalFees.tooltip.items.freeAchToAchApplied',
        { freeAchToAchApplied, discount: formatCurrency(totalAchToAchDiscount) }
      )
    );
  }

  return (textItems.length > 1 ? textItems.map((line) => `• ${line}`) : textItems).join('\n\n');
};

type UseGetFeesTooltipProps = {
  availableFreeChecks?: number;
  feesBreakdown?: FeesBreakdown;
  orgBillingFeeSettings: BillingFeeSetting[];
};

export const useGetFeesTooltip = ({
  availableFreeChecks,
  feesBreakdown,
  orgBillingFeeSettings,
}: UseGetFeesTooltipProps) => {
  const achToAchDiscountData = useFreeAchToAchApplied(feesBreakdown);

  const freeCheckDiscount = feesBreakdown?.feesBreakdown.find((fee) => fee.type === FeeType.AchToCheck)?.discount;

  const hasActiveBillingFee = hasActiveBillingFeeMethod(orgBillingFeeSettings, feesBreakdown?.feesBreakdown);

  const tooltip = useGetTooltipText({
    hasBillingFeeMethod: hasActiveBillingFee,
    availableFreeChecks,
    freeCheckDiscount,
    achToAchDiscountData,
  });

  return tooltip;
};
