import { TourtipSteps } from '@melio/penny';
import { useUserHasPayments } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-provider';
import React from 'react';

import { createTestIdSelector } from '../../../../../utils';
import { PlatformTourtip, usePlatformTourtip } from '../../../../platform-tourtip';

const deliveryDateTourActiveTourKey = 'deliveryDateTourActive';

export const DeliveryDateTour = () => {
  const { formatMessage } = useMelioIntl();
  const [isDeliveryDateTourEnabled] = useDevFeature<boolean>(FeatureFlags.IsDeliveryDateTourEnabled, false);
  const { userHasPayments, isLoading: isLoadingUserHasPayments } = useUserHasPayments({
    enabled: isDeliveryDateTourEnabled,
  });

  const deliveryDateTourSteps: TourtipSteps = [
    {
      target: createTestIdSelector('form-input-deliveryDate'),
      title: formatMessage('activities.deliveryDate.tour.step.title'),
      description: formatMessage('activities.deliveryDate.tour.step.content'),
      placement: 'bottom-end',
    },
  ];

  const { isTourActive, ...tourtipProps } = usePlatformTourtip({
    tourName: 'DeliveryDateTour',
    enabled: isDeliveryDateTourEnabled && userHasPayments && !isLoadingUserHasPayments,
    userPreferenceKey: deliveryDateTourActiveTourKey,
    steps: deliveryDateTourSteps,
    defaultLabels: {
      nextButtonLabel: formatMessage('activities.deliveryDate.tour.nextButton.label'),
      prevButtonLabel: formatMessage('activities.deliveryDate.tour.backButton.label'),
    },
  });

  return (
    <PlatformTourtip
      {...tourtipProps}
      steps={deliveryDateTourSteps}
      analyticsProperties={{
        Flow: 'fte-existing-user',
        Intent: 'fte-guide',
        EntryPoint: 'delivery-date-page',
      }}
    />
  );
};
