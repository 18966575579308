import { useMelioIntl } from '@melio/platform-i18n';

import type { Plan } from '../../../../../api';
import { usePlansTiers, useSubscriptionPlan } from '../../../../../hooks';

export const usePlanBadge = (planId: Plan['id']) => {
  const { formatMessage } = useMelioIntl();

  const { getIsCurrentPlan, isDowngradedPlan } = useSubscriptionPlan();
  const { getIsMostPopularPlan } = usePlansTiers();
  const isCurrentPlan = getIsCurrentPlan(planId);
  const isMostPopularPlan = getIsMostPopularPlan(planId);

  if (isCurrentPlan) {
    return {
      label: formatMessage('activities.subscription.plans.card.activePlan'),
      type: 'secondary' as const,
    };
  }

  if (isMostPopularPlan && !isDowngradedPlan(planId)) {
    return { label: formatMessage('activities.subscription.plans.card.mostPopular'), type: 'primary' as const };
  }

  return;
};
