import { InvoicesApiClient } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

export type UseInvoiceProps = UseModelProps<typeof InvoicesApiClient.getMarkedAsPaidDetails>;

export const useInvoicePaymentDetails = (props: UseInvoiceProps = {}) =>
  useModel({
    ...props,
    queryKey: ['InvoicesApi', 'PaymentDetails'],
    queryFn: InvoicesApiClient.getMarkedAsPaidDetails,
    updateFn: InvoicesApiClient.patchMarkAsPaidDetails,
  });

export type InvoicePaymentDetailsModel = ReturnType<typeof useInvoicePaymentDetails>;
