import { CustomersDashboardApiInstance } from '@melio/ar-api-axios-client';

import { useCollection, UseCollectionProps } from '../../api-client';

export type UseCustomersDashboardProps = UseCollectionProps<typeof CustomersDashboardApiInstance.getCustomersDashboard>;

export const useCustomersDashboard = (props: UseCustomersDashboardProps = {}) =>
  useCollection({
    ...props,
    isPaginated: true,
    queryKey: 'CustomersDashboardApi',
    queryFn: CustomersDashboardApiInstance.getCustomersDashboard,
  });

export type CustomersDashboardCollection = ReturnType<typeof useCustomersDashboard>;
