import {
  getFeeDescription,
  isFXCurrency,
  isPayorPaymentFee,
  sumPaymentFees,
  useFeeValueFormatting,
} from '@melio/ap-domain';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { first } from 'lodash';

import { PaymentDetails } from '../../types';

export function useFeesRow(payment: PaymentDetails) {
  const { formatCurrency, formatMessage } = useMelioIntl();
  const {
    settings: {
      payment: {
        scheduled: { showZeroFees },
      },
    },
  } = useConfig();

  const { formatFeeValue, isLoading } = useFeeValueFormatting();

  const payorFees = payment.fees?.filter(isPayorPaymentFee) ?? [];
  const totalFeesAmount = sumPaymentFees(payorFees);
  const isFxPayment = isFXCurrency(payment.vendor.currency);
  const show = !!totalFeesAmount || (showZeroFees && !isFxPayment);

  const firstFee = first(payorFees);

  return {
    show,
    isLoading,
    value: formatMessage('activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.fees.value', {
      value: formatCurrency(totalFeesAmount),
      feeDescription:
        payorFees.length === 1 && firstFee ? getFeeDescription({ formatFeeValue, fee: firstFee }) : undefined,
    }),
  };
}
