import { CollapsibleCardFormWidget } from '@melio/form-controls';
import { Container } from '@melio/penny';

type CollapsibleCardSectionProps = {
  id: string;
  title: string;
  description: string;
  isExpanded: boolean;
  isDisabled: boolean;
  isFilled: boolean;
  children: React.ReactNode;
  onExpandChange: (expanded: boolean) => void;
};

export const CollapsibleCardSection = ({
  id,
  title,
  description,
  isExpanded,
  isDisabled,
  isFilled,
  children,
  onExpandChange,
}: CollapsibleCardSectionProps) => (
  <Container key={`Container_${id}`} alignItems="center">
    <CollapsibleCardFormWidget
      key={`section_${id}`}
      data-testid={`step-${id}`}
      title={title}
      isFilled={isFilled}
      subtitle={description}
      isOpened={isExpanded}
      isDisabled={isDisabled}
      onOpen={() => onExpandChange(true)}
    >
      {children}
    </CollapsibleCardFormWidget>
  </Container>
);
