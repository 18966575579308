import { AccountingPlatform } from '@melio/platform-api';

import { AccountingPlatformIconWrapper } from './components/AccountingPlatformIconWrapper';
import { SyncNowIconButton } from './components/SyncNowIconButton';

type SyncIconProps = {
  ariaLabel: string;
  isError?: boolean;
  isSyncRunning: boolean;
  accountingPlatform: AccountingPlatform;
  isExtraSmallScreen: boolean;
  onClick: VoidFunction;
  tooltipTestId?: string;
  tooltipTitle?: string;
  tooltipLabel?: string;
};

export const SyncIcon = ({
  ariaLabel,
  isError,
  isSyncRunning,
  accountingPlatform,
  isExtraSmallScreen,
  onClick,
  tooltipTestId,
  tooltipTitle,
  tooltipLabel,
}: SyncIconProps) => {
  const shouldShowPlatformIcon = !isError && !isSyncRunning;

  return (
    <AccountingPlatformIconWrapper
      enabled={shouldShowPlatformIcon}
      accountingPlatformSlug={accountingPlatform.accountingSlug}
    >
      <SyncNowIconButton
        variant={isExtraSmallScreen ? 'naked' : undefined}
        onClick={onClick}
        isDisabled={isSyncRunning}
        ariaLabel={ariaLabel}
        tooltipTestId={tooltipTestId}
        tooltipLabel={tooltipLabel}
        tooltipTitle={tooltipTitle}
        placement="bottom"
      />
    </AccountingPlatformIconWrapper>
  );
};
