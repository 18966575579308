import { Box } from '@chakra-ui/react';
import { Group, GroupProps, useBreakpointValue } from '@melio/penny';
import { ReactNode } from 'react';

export const CardsGroup = ({ children }: { children: ReactNode }) => {
  const variant = useBreakpointValue<GroupProps['variant']>({
    s: 'vertical',
    m: 'horizontal',
  });

  return (
    <Box sx={{ '& > *': { flexWrap: 'wrap' } }}>
      <Group width="full" justifyContent="space-between" variant={variant}>
        {children}
      </Group>
    </Box>
  );
};
export const CardContainer = ({ children }: { children: ReactNode }) => (
  <Group.Item basis={['100%', null, 'calc(50% - 8px)'] as never}>{children}</Group.Item>
);
