import { ARErrorCode, useInvoicePreference, useMelioIntl, useSystemMessage } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { EditInvoiceFormatDialogScreen, InvoiceFormatFormFields } from './screens';

type EditInvoiceFormatDialogActivityProps = {
  onError?: ARErrorFunction;
  onDone: VoidFunction;
  onClose: VoidFunction;
};

export const EditInvoiceFormatDialogActivity = forwardRef<EditInvoiceFormatDialogActivityProps>(
  ({ onDone, onClose, onError, ...props }, ref) => {
    const { data: invoicePreferences, isFetching, isMutating, update } = useInvoicePreference({ onError });
    const { triggerMessage } = useSystemMessage();
    const { formatMessage } = useMelioIntl();

    const onSubmit = (values: InvoiceFormatFormFields) => {
      update({ prefix: values.prefix, invoiceNumber: values.invoiceNumber.toString() })
        .then(() => {
          onDone();
          triggerMessage({
            type: 'success',
            title: formatMessage('ar.settings.flows.invoiceSettings.sections.editInvoiceFormat.toast.success.label'),
          });
        })
        .catch((err: ARPlatformError) => {
          triggerMessage({
            type: 'critical',
            title: formatMessage(
              err.errorCode === ARErrorCode.DuplicateInvoiceNumber
                ? 'ar.settings.flows.invoiceSettings.sections.editInvoiceFormat.toast.failure.duplicatedInvoiceNumber.label'
                : 'ar.settings.flows.invoiceSettings.sections.editInvoiceFormat.toast.failure.generalErrorMessage.label'
            ),
          });
          onError?.(err);
        });
    };

    return (
      <EditInvoiceFormatDialogScreen
        data-testid="edit-invoice-format-dialog"
        onSubmit={onSubmit}
        onClose={onClose}
        isOpen
        defaultValues={{
          prefix: invoicePreferences?.prefix,
          invoiceNumber: Number(invoicePreferences?.invoiceNumber),
        }}
        isLoading={isFetching}
        isSaving={isMutating}
        ref={ref}
        {...props}
      />
    );
  }
);
