import { isNil } from 'lodash';

import { BooleanFeatureName, Plan, QuotaFeatureName } from '../api';

type FeatureEligibilityData = {
  isEligible: boolean;
};

export type QuantityBasedFeatureRequest = { featureName: QuotaFeatureName; requirements: { totalUnits: number } };
type QuantityBasedFeatureEligibilityData = FeatureEligibilityData & {
  quota: {
    freeUnitsLimit: number;
    excessFee?: {
      monthly: number | undefined;
      annual: number | undefined;
    };
  };
};

type BooleanBasedFeatureRequest = { featureName: BooleanFeatureName };
type BooleanBasedFeatureEligibilityData = FeatureEligibilityData;

export type FeatureRequest = BooleanBasedFeatureRequest | QuantityBasedFeatureRequest;

export function getIsFeatureInPlan<T extends FeatureRequest>(
  plan: Plan,
  feature: T
): T extends QuantityBasedFeatureRequest ? QuantityBasedFeatureEligibilityData : BooleanBasedFeatureEligibilityData;

export function getIsFeatureInPlan(
  plan: Plan,
  feature: FeatureRequest
): QuantityBasedFeatureEligibilityData | BooleanBasedFeatureEligibilityData {
  if ('requirements' in feature) {
    return getQuantityBasedIsFeatureInPlan(plan, feature.featureName, feature.requirements.totalUnits);
  }

  return getBooleanBasedIsFeatureInPlan(plan, feature.featureName);
}

export const getQuantityBasedIsFeatureInPlan = (
  plan: Plan,
  featureName: QuotaFeatureName,
  totalRequestedUnits: number
) => {
  if (featureName === 'collaborators') {
    const freeUnitsLimit = plan.numberOfFreeUsers;

    const excessFee = {
      monthly: plan.cycles.monthly.unitPrice,
      annual: plan.cycles.annual?.unitPrice,
    };

    const isBelowFreeUnitsLimit = freeUnitsLimit && totalRequestedUnits <= freeUnitsLimit;
    const canExceedFreeUnitsLimit = !isNil(excessFee?.monthly);

    const isEligible = isBelowFreeUnitsLimit || canExceedFreeUnitsLimit;

    return { isEligible, quota: { freeUnitsLimit, excessFee } };
  }

  const freeUnitsLimit = plan.features[featureName]?.limit ?? Infinity;
  return { isEligible: totalRequestedUnits <= freeUnitsLimit, quota: { freeUnitsLimit } };
};

const getBooleanBasedIsFeatureInPlan = (plan: Plan, featureName: BooleanFeatureName) => ({
  isEligible: !!plan.features[featureName]?.enabled,
});
