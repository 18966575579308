import { useMelioIntl } from '@melio/ar-domain';
import { Modal, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

export type ShareTestInvoiceModalActivityProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onDone?: VoidFunction;
  onActivateAccount?: VoidFunction;
};

export const ShareTestInvoiceModalActivity = forwardRef<ShareTestInvoiceModalActivityProps>(
  ({ isOpen, onClose, onDone = onClose, onActivateAccount, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();

    return (
      <Modal
        data-testid="share-test-invoice-modal-activity"
        data-component={ShareTestInvoiceModalActivity.displayName}
        header={formatMessage('ar.invoiceLifecycle.activities.shareTestInvoice.modal.header.text')}
        isOpen={isOpen}
        onClose={onClose}
        secondaryButton={{
          label: formatMessage('ar.invoiceLifecycle.activities.shareTestInvoice.modal.buttons.secondary.label'),
          onClick: () => {
            onActivateAccount?.();
            track('Invoice', 'Status', {
              PageName: 'share-test-invoice',
              Intent: 'activate-ar',
              Cta: 'activate-receivables',
            });
          },
          variant: 'tertiary',
        }}
        primaryButton={{
          label: formatMessage('ar.invoiceLifecycle.activities.shareTestInvoice.modal.buttons.primary.label'),
          variant: 'primary',
          onClick: () => {
            track('Invoice', 'Click', {
              PageName: 'share-test-invoice',
              Intent: 'send-test-invoice-to-customer',
              Cta: 'share-test-invoice',
            });
            onDone();
          },
        }}
        {...props}
        ref={ref}
      >
        <Text>{formatMessage('ar.invoiceLifecycle.activities.shareTestInvoice.modal.description.text')}</Text>
      </Modal>
    );
  }
);

ShareTestInvoiceModalActivity.displayName = 'ShareTestInvoiceModalActivity';
