import { useMelioIntl } from '@melio/platform-i18n';
import { isSameDay } from 'date-fns';

import { DeliveryMethodChangeTableData, DeliveryMethodDetailsDiff } from '../types';
import { formatDate } from '../utils';

export const useChangeModalTableData = (
  deliveryMethodDetails: DeliveryMethodDetailsDiff[] | undefined
): DeliveryMethodChangeTableData[] => {
  const { formatMessage } = useMelioIntl();

  if (!deliveryMethodDetails || deliveryMethodDetails.length === 0) {
    return [];
  }

  return deliveryMethodDetails.map((deliveryMethod) => {
    const { debitByDate, deliveryByDate, paymentData } = deliveryMethod;

    let debitDateTooltipText = '';
    let deliveryDateTooltipText = '';

    if (!isSameDay(debitByDate.new, debitByDate.old) && !isSameDay(deliveryByDate.new, deliveryByDate.old)) {
      debitDateTooltipText = formatMessage(
        'activities.paymentFlow.form.content.deliveryMethodChangedModal.different-debit-and-delivery.debit',
        {
          fromDebitDate: formatDate(debitByDate.old),
          toDebitDate: formatDate(debitByDate.new),
        }
      );
      deliveryDateTooltipText = formatMessage(
        'activities.paymentFlow.form.content.deliveryMethodChangedModal.different-debit-and-delivery.delivery',
        {
          fromDeliveryDate: formatDate(deliveryByDate.old),
          toDeliveryDate: formatDate(deliveryByDate.new),
        }
      );
    } else if (!isSameDay(deliveryByDate.new, deliveryByDate.old)) {
      deliveryDateTooltipText = formatMessage(
        'activities.paymentFlow.form.content.deliveryMethodChangedModal.different-delivery-same-debit',
        {
          fromDeliveryDate: formatDate(deliveryByDate.old),
          toDeliveryDate: formatDate(deliveryByDate.new),
          debitDate: formatDate(debitByDate.old),
        }
      );
    } else if (!isSameDay(debitByDate.new, debitByDate.old)) {
      debitDateTooltipText = formatMessage(
        'activities.paymentFlow.form.content.deliveryMethodChangedModal.different-debit-same-delivery',
        {
          fromDebitDate: formatDate(debitByDate.old),
          toDebitDate: formatDate(debitByDate.new),
          deliveryDate: formatDate(deliveryByDate.old),
        }
      );
    }

    return {
      vendor: deliveryMethod.vendor,
      scheduleDate: {
        value: debitByDate.new,
        tooltipText: debitDateTooltipText,
      },
      deliveryDate: {
        value: deliveryByDate.new,
        tooltipText: deliveryDateTooltipText,
      },
      amount: paymentData.amountToPay,
      invoiceNumber: paymentData.invoiceNumber,
    };
  });
};
