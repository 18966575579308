import { useIsMobile } from '@melio/ar-domain';
import { Container, Group, Tabs, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { TabsNames } from '../types';
import { useScreenLabels } from '../utils';

type PreviewPanelHeaderProps = {
  activeTab: TabsNames;
  onChangeActiveTab: (tabName: TabsNames) => void;
};

export const PreviewPanelHeader = forwardRef<PreviewPanelHeaderProps>(({ activeTab, onChangeActiveTab }, ref) => {
  const isMobile = useIsMobile();
  const { tabs, previewHeaderText } = useScreenLabels();

  return !isMobile ? (
    <Container paddingLeft="xxl" paddingRight="xxl" paddingTop="xxl" data-component={PreviewPanelHeader.displayName}>
      <Group width="full" spacing="xs" variant="vertical">
        <Text textStyle="heading3Semi" as="h2" data-testid="preview-panel-header">
          {previewHeaderText}
        </Text>
        <Tabs
          variant="default"
          ref={ref}
          activeTab={activeTab}
          onChange={(tab) => onChangeActiveTab(tab as TabsNames)}
          tabs={tabs}
          data-testid="preview-tabs"
        />
      </Group>
    </Container>
  ) : null;
});
PreviewPanelHeader.displayName = 'PreviewPanelHeader';
