import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { abortRefreshAccessToken } from '@/api/apiClients';
import { createAccountAccessToken } from '@/api/auth.api';
import { getMeliocomOrgUrl } from '@/utils/generateMelioRedirectPath';
import { usePartnerConfig } from './partners/usePartnerConfig';
import { useRouter } from './router.hooks';
import { useResetAccessToken } from './session.hooks';

export const useSwitchAccount = () => {
  const setAccessToken = useResetAccessToken();
  const { goHome } = useRouter();
  const { partnerConfig } = usePartnerConfig();
  const [isMelioMigrationWhitelistToBlacklistEnabled] = useDevFeature(
    FeatureFlags.MelioMigrationWhitelistToBlacklist,
    false,
  );
  const switchAccessToken = async (accountId: string) => {
    abortRefreshAccessToken();
    const { accessToken, refreshToken } = await createAccountAccessToken(accountId);
    setAccessToken(accessToken, refreshToken!);
  };

  const switchAccount = async (accountId: string) => {
    let url;

    await switchAccessToken(accountId);

    if (partnerConfig.supportLegacyOrganizations) {
      url = await getMeliocomOrgUrl({ isMelioMigrationWhitelistToBlacklistEnabled });
    }

    url ? window.location.replace(url) : goHome({ shouldRefreshPage: true });
  };

  return { switchAccount };
};
