import { PaymentSchedulingPreference, useConfig } from '@melio/platform-provider';
import { addDays } from 'date-fns';
import { useMemo } from 'react';

export const usePaymentSchedulingPreference = () => {
  // eslint-disable-next-line no-restricted-syntax
  const today = useMemo(() => new Date(), []);

  const {
    settings: {
      payment: {
        scheduling: { scheduleBy, maxDeliveryDateInDays },
      },
    },
  } = useConfig();

  return {
    scheduleBy,
    isByDeliveryDate: scheduleBy === PaymentSchedulingPreference.ByDeliveryDate,
    isByScheduleDate: scheduleBy === PaymentSchedulingPreference.ByScheduleDate,
    minDeliveryDate: today,
    maxDeliveryDate: addDays(today, maxDeliveryDateInDays),
  };
};
