import { Box } from '@chakra-ui/react';
import { CopyToClipboardWidget } from '@melio/ap-widgets';
import { Link, NakedButton } from '@melio/penny';
import { AccountingPlatformSlug, useAccount, useAccountingPlatforms } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const useBillsTabEmptyState = (shouldShowGmailText: boolean, onEmailCopy: (value: string) => void) => {
  const { formatMessage } = useMelioIntl();
  const { data: accountData } = useAccount({ id: 'me' });
  const { data: accountingPlatformData } = useAccountingPlatforms();

  const getGmailTextPartial = () =>
    shouldShowGmailText ? formatMessage('activities.payDashboard.billsTab.emptyState.description.gmail') : '';

  const getAccountingPlatformTextPartial = () => {
    const isOnlyQBOAvailable =
      accountingPlatformData?.length === 1 &&
      accountingPlatformData?.[0]?.accountingSlug === AccountingPlatformSlug.QuickBooksOnline;

    return formatMessage(
      isOnlyQBOAvailable
        ? 'activities.payDashboard.billsTab.emptyState.description.qboOnly'
        : 'activities.payDashboard.billsTab.emptyState.description.allAccountingSoftware'
    );
  };

  const getCopyLinkButton = (inboxEmailAddress: string) => (
    <CopyToClipboardWidget
      initialTooltipText={formatMessage('widgets.inboxEmailAddressCopyLink.tooltip')}
      copyText={inboxEmailAddress}
      triggerAriaLabel={formatMessage('widgets.inboxEmailAddressCopyLink.ariaLabel', {
        email: inboxEmailAddress,
      })}
      onCopy={onEmailCopy}
    >
      <NakedButton
        variant="secondary"
        label={formatMessage('activities.payDashboard.billsTab.emptyState.copyEmailButton')}
      />
    </CopyToClipboardWidget>
  );

  const getCopyEmailTextPartial = () => {
    const inboxEmailAddress = accountData?.company.inboxEmailAddress;

    return formatMessage(
      inboxEmailAddress
        ? 'activities.payDashboard.billsTab.emptyState.description.withCopyEmailButton'
        : 'activities.payDashboard.billsTab.emptyState.description.withoutCopyEmailButton'
    );
  };

  const getCopyEmailAriaLabelPartial = () => {
    const inboxEmailAddress = accountData?.company.inboxEmailAddress;

    if (inboxEmailAddress) {
      return formatMessage('activities.payDashboard.billsTab.emptyState.description.withCopyEmailButtonText', {
        email: inboxEmailAddress,
      });
    } else {
      return formatMessage('activities.payDashboard.billsTab.emptyState.description.withoutCopyEmailButton');
    }
  };

  const descriptionCTA = accountData?.company.inboxEmailAddress && (
    <Box display="flex" gap="xxs" flexWrap="wrap" justifyContent="center">
      <Link
        href={`mailto:${accountData?.company.inboxEmailAddress}`}
        label={accountData?.company.inboxEmailAddress}
        newTab
      />
      {getCopyLinkButton(accountData?.company.inboxEmailAddress)}
    </Box>
  );

  const emptyStateText = formatMessage('activities.payDashboard.billsTab.emptyState.description', {
    accountingSoftware: getAccountingPlatformTextPartial(),
    gmail: getGmailTextPartial(),
    copyEmail: getCopyEmailTextPartial(),
  });

  const emptyStateAriaLabel = formatMessage('activities.payDashboard.billsTab.emptyState.description', {
    accountingSoftware: getAccountingPlatformTextPartial(),
    gmail: getGmailTextPartial(),
    copyEmail: getCopyEmailAriaLabelPartial(),
  });

  return { emptyStateText, emptyStateAriaLabel, descriptionCTA };
};
