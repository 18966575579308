import { Form, Group, Modal, Text, useFormSubmissionController, useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useVendor, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useEffect } from 'react';
import { object, SchemaOf, string } from 'yup';

import { useTaxPayerInfoRequestWithSubscription } from './useTaxPayerInfoRequestWithSubscription';

type VendorTaxDetailsModalFields = {
  email: string;
};

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    email: string()
      .required(formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.modal.fields.email.validations.empty'))
      .email(formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.modal.fields.email.validations.invalid')),
  }) as SchemaOf<VendorTaxDetailsModalFields>;
};

type VendorTaxDetailsModalProps = {
  vendor: Vendor;
  isOpen: boolean;
  onClose: () => void;
  onFeatureIsBlockedCb: () => void;
};

export const RequestVendorTaxDetailsModal = ({
  vendor,
  isOpen,
  onClose,
  onFeatureIsBlockedCb,
}: VendorTaxDetailsModalProps) => {
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();
  const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<VendorTaxDetailsModalFields>();

  const { onRequestTaxPayerInfoDetailsWithSubscriptionCheck, isMutating } = useTaxPayerInfoRequestWithSubscription(
    vendor.id
  );

  const { update: updateVendor, isLoading } = useVendor({
    id: vendor.id,
    enabled: false,
  });

  const { track, createTrackHandler } = useAnalytics();

  const trackActionClick = createTrackHandler('Vendor', 'Click');

  useEffect(() => {
    if (isOpen) {
      track('Vendor', 'View', {
        PageName: 'request-taxpayer',
        Flow: 'add-vendor-1099',
        Intent: 'add-vendor-email',
        TaxId: 'email',
        IsContractor: true,
      });
    }
  }, [isOpen, track]);

  const handleSubmit = async (data: VendorTaxDetailsModalFields) => {
    trackActionClick({
      Cta: 'send-w9-request',
      PageName: 'request-taxpayer',
      Flow: 'add-vendor-1099',
      Intent: 'add-vendor-email',
    });
    try {
      await updateVendor({
        contact: { email: data.email },
      });
      onClose();
      onRequestTaxPayerInfoDetailsWithSubscriptionCheck(data.email, onFeatureIsBlockedCb);
    } catch (e) {
      showMessage({
        type: 'error',
        title: formatMessage('widgets.editVendor.toast.error', { companyName: vendor.name }),
      });
    }
  };

  const { formProps, registerField } = useMelioForm<VendorTaxDetailsModalFields>({
    onSubmit: handleSubmit,
    onSubmissionStateChange,
    schema: useSchema(),
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        trackActionClick(
          { Cta: 'exit', PageName: 'request-taxpayer', Flow: 'add-vendor-1099', Intent: 'add-vendor-email' },
          () => onClose()
        );
      }}
      isLoading={isMutating || isLoading}
      data-testid="vendor-request-tax-details-modal"
      header={formatMessage('widgets.taxPayerInfo.modal.title', {
        vendorName: vendor.name || '',
      })}
      primaryButton={{
        label: formatMessage('widgets.taxPayerInfo.modal.submit'),
        variant: 'primary',
        isLoading: isMutating || isLoading,
        ...submitButtonProps,
      }}
    >
      <Group variant="vertical">
        <Text>{formatMessage('widgets.taxPayerInfo.modal.description')}</Text>
        <Form data-component="VendorRequestTaxDetailsModalForm" size="large" {...formProps}>
          <Form.TextField
            autoFocus
            {...registerField('email')}
            data-testid="vendor-request-tax-details-modal-email"
            labelProps={{ label: formatMessage('widgets.taxPayerInfo.modal.fields.email.title') }}
            placeholder={formatMessage('widgets.taxPayerInfo.modal.fields.email.placeholder')}
          />
        </Form>
      </Group>
    </Modal>
  );
};
