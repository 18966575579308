import { MessageKey, useMelioIntl } from '@melio/ar-domain';
import { useMemo } from 'react';

import { ShareMode } from '../../../types';
import { TabsNames } from '../types';
type UseScreenLabelsProps = {
  shareMode?: ShareMode;
  shouldSend?: boolean;
};

export const useScreenLabels = ({ shareMode = 'createInvoice', shouldSend = false }: UseScreenLabelsProps = {}) => {
  const { formatMessage } = useMelioIntl();

  const headerText = useMemo(() => {
    const headerTexts: Record<ShareMode, string> = {
      reminder: formatMessage('ar.invoiceLifecycle.activities.issueInvoice.form.header.sendReminder.label'),
      createInvoice: formatMessage('ar.invoiceLifecycle.activities.issueInvoice.form.header.shareInvoice.label'),
      updateInvoice: formatMessage('ar.invoiceLifecycle.activities.issueInvoice.form.header.shareInvoice.label'),
    };

    return headerTexts[shareMode];
  }, [shareMode, formatMessage]);
  const previewHeaderText = useMemo(
    () => formatMessage('ar.invoiceLifecycle.activities.issueInvoice.preview.header'),
    [formatMessage]
  );
  const formHeaderText = useMemo(
    () => formatMessage('ar.invoiceLifecycle.activities.issueInvoice.form.header'),
    [formatMessage]
  );
  const primaryButtonLabel = useMemo(() => formatMessage(getMessages(shouldSend).primary), [formatMessage, shouldSend]);
  const secondaryButtonLabel = useMemo(() => formatMessage(getMessages().secondary), [formatMessage]);
  const tertiaryButtonLabel = useMemo(() => formatMessage(getMessages().tertiary), [formatMessage]);

  const tabs = useMemo<{ name: TabsNames; label: string }[]>(
    () => [
      { name: 'PDF', label: formatMessage(`ar.invoiceLifecycle.activities.issueInvoice.preview.tabs.PDF.name`) },
      { name: 'Email', label: formatMessage(`ar.invoiceLifecycle.activities.issueInvoice.preview.tabs.Email.name`) },
    ],
    [formatMessage]
  );

  return {
    tabs,
    headerText,
    primaryButtonLabel,
    secondaryButtonLabel,
    tertiaryButtonLabel,
    previewHeaderText,
    formHeaderText,
  };
};

type Keys = 'primary' | 'secondary' | 'tertiary';

const getMessages = (shouldSend?: boolean): Record<Keys, MessageKey> => ({
  primary: shouldSend
    ? 'ar.invoiceLifecycle.activities.issueInvoice.form.footer.buttons.primary.shareInvoice'
    : 'ar.invoiceLifecycle.activities.issueInvoice.form.footer.buttons.primary.done',
  secondary: 'ar.invoiceLifecycle.activities.issueInvoice.form.footer.buttons.editInvoice',
  tertiary: 'ar.invoiceLifecycle.activities.issueInvoice.form.footer.buttons.cancel',
});
