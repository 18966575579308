import { isEbill } from '@melio/ap-domain';
import { useOneTimeEffect } from '@melio/platform-utils';
import { useState } from 'react';

import { defaultEBillPayOption, EbillPayOption } from '../../edit-ebill-payment-option';
import { SchedulePaymentIntent } from '../types';

export const useEbillAmountOption = ({
  paymentIntentsWithDerivatives,
}: {
  paymentIntentsWithDerivatives: SchedulePaymentIntent[] | undefined;
}) => {
  const [paymentIntentToEbillOption, setPaymentIntentToEbillOption] = useState<Record<string, EbillPayOption>>({});

  useOneTimeEffect(
    () => {
      const options = paymentIntentsWithDerivatives
        ?.filter((payment) => payment.bills[0] && isEbill(payment.bills[0]))
        .reduce<Record<string, EbillPayOption>>((acc, payment) => {
          acc[payment.paymentIntent.id] = defaultEBillPayOption;
          return acc;
        }, {});

      setPaymentIntentToEbillOption(options || {});
    },
    () => !!paymentIntentsWithDerivatives,
    [paymentIntentsWithDerivatives]
  );

  const updatePaymentEbillOption = (paymentId: string, value: EbillPayOption) => {
    setPaymentIntentToEbillOption((prevState) => ({
      ...prevState,
      [paymentId]: value,
    }));
  };

  return { paymentIntentToEbillOption, updatePaymentEbillOption };
};
