import { useToast } from '@melio/penny';
import { useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { DeliveryPreference, FundingSource, PaymentIntent, usePayment, usePaymentIntent } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useDateUtils } from '@melio/platform-utils';
import { useEffect, useRef, useState } from 'react';

import { MonitoredAction } from '../../../monitoring';
import { useExtendedPaymentIntent } from '../../../utils/pay-flow/useExtendedPaymentIntent';
import { useCreatePaymentIntentFromPayment } from '../../../utils/useCreatePaymentIntentFromPayment';
import { FundingSourceSelectionActivity } from '../../funding-sources';
import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { DeliveryDateActivity } from '../DeliveryDate';
import { ReviewAndConfirmActivity } from '../ReviewAndConfirm';
import { checkHasDeliveryFastOption } from '../utils/checkHasDeliveryFastOption';
import { getDifferenceKeysByValue } from '../utils/getDifferenceKeysByValue';
import { FailedToCollectPaymentFlowActivityProps } from './types';
import { useFailedToCollectActivityStep } from './useFailedToCollectActivityStep';

export const FailedToCollectPaymentActivity = withAnalyticsContext<FailedToCollectPaymentFlowActivityProps>(
  ({ paymentId, onDone, onBack, onError, onClose }: FailedToCollectPaymentFlowActivityProps) => {
    const { formatMessage } = useMelioIntl();
    const { createDate } = useDateUtils();
    const { endAction } = useMonitoring<MonitoredAction>();
    const { toast } = useToast();

    const [isLoadingReviewAndConfirmButton, setIsLoadingReviewAndConfirmButton] = useState<boolean>(false);

    const handleFail = (error: PlatformError) => {
      toast({ type: 'error', title: error.message });
      onError?.(error);
    };
    const {
      paymentIntentId,
      fundingSourceTypesOptions,
      isLoading: isLoadingPaymentIntentFromPayment,
    } = useCreatePaymentIntentFromPayment({
      paymentId,
      onError: handleFail,
    });

    const { isMutating: isUpdatingPayment, retry: retryFailedPayment } = usePayment({ id: paymentId, enabled: false });
    const oneTimeUpdateRef = useRef(false);
    const [originalPaymentIntent, setOriginalPaymentIntent] = useState<PaymentIntent>();
    const paymentIntentModel = usePaymentIntent({ id: paymentIntentId });

    const {
      data: basicPaymentIntent,
      isLoading: isLoadingPaymentIntent,
      isUpdating: isUpdatingPaymentIntent,
      update: updatePaymentIntent,
    } = paymentIntentModel;
    const paymentIntent = useExtendedPaymentIntent(basicPaymentIntent);

    useEffect(() => {
      if (!oneTimeUpdateRef.current && paymentIntent?.id) {
        oneTimeUpdateRef.current = true;
        setOriginalPaymentIntent(paymentIntent);
      }
    }, [paymentIntent]);

    const { currentStep, goToStep, goToPreviousStep } = useFailedToCollectActivityStep({
      updatedPaymentIntent: paymentIntent,
      originalPaymentIntent,
      onFirstStepBack: onBack,
    });

    const failedDate = paymentIntent?.payments?.[0]?.history.updatedAt;

    useAnalyticsContext({
      globalProperties: {
        PaymentMethodId: basicPaymentIntent?.fundingSourceId,
        PaymentId: paymentId,
        PageEntryPoint: 'RecoverFailedToCollect',
        IsEditFlow: 'true',
        OriginalPaymentMethodId: originalPaymentIntent?.payments?.[0]?.fundingSourceId,
        FailedDestination: 'failedToCollect',
        Reason: originalPaymentIntent?.payments?.[0]?.paymentFailure?.failureReason,
        FundingSourceType: basicPaymentIntent?.fundingSource?.type,
        EligibilityType:
          basicPaymentIntent?.selectedDeliveryPreferenceType === 'check' ? 'ExpressCheck' : 'ExpeditedACH',
      },
    });

    if (
      isLoadingPaymentIntent ||
      !paymentIntent ||
      !paymentIntentId ||
      !originalPaymentIntent ||
      isUpdatingPayment ||
      isUpdatingPaymentIntent ||
      isLoadingPaymentIntentFromPayment
    ) {
      return <NewSinglePaymentStepLayout isLoading />;
    }

    const handleConfirm = (fxQuoteToken?: string) => {
      setIsLoadingReviewAndConfirmButton(true);
      retryFailedPayment({
        fundingSourceId: paymentIntent.fundingSourceId,
        deliveryPreferenceType: paymentIntent.selectedDeliveryPreferenceType,
        fxQuoteToken,
      })
        .then(({ id }) => {
          endAction('review_and_confirm');
          onDone(id);
        })
        .then(() => {
          const toastFormattedMessage = formatMessage(
            'activities.reviewAndConfirm.screens.reviewAndConfirmUpdatePayment.successToastMessage'
          );
          toast({ type: 'success', title: toastFormattedMessage });
        })
        .catch(handleFail)
        .finally(() => setIsLoadingReviewAndConfirmButton(false));
    };

    const handleSetFundingSource = ({ fundingSourceId }: { fundingSourceId?: FundingSource['id'] }) => {
      updatePaymentIntent({
        fundingSourceId,
        scheduledDate: createDate(),
      })
        .then((paymentIntent) => {
          if (checkHasDeliveryFastOption(paymentIntent, originalPaymentIntent)) {
            goToStep('DELIVERY_SPEED_DATE_SELECTION');
          } else {
            goToStep('REVIEW_AND_CONFIRM');
          }
        })
        .catch(handleFail);
    };

    const handleSetDeliveryPreferenceType = (
      selectedDeliveryPreferenceType: DeliveryPreference['type'],
      scheduledDate: Date
    ) => {
      updatePaymentIntent({ selectedDeliveryPreferenceType, scheduledDate })
        .then(() => {
          goToStep('REVIEW_AND_CONFIRM');
        })

        .catch(handleFail);
    };

    const totalSteps = checkHasDeliveryFastOption(paymentIntent, originalPaymentIntent) ? 3 : 2;

    const selectedFundingSource =
      originalPaymentIntent.payments?.[0]?.fundingSourceId !== paymentIntent?.fundingSourceId
        ? paymentIntent.fundingSourceId
        : undefined;

    switch (currentStep) {
      case 'FUNDING_SOURCE_SELECTION':
      default:
        return (
          <FundingSourceSelectionActivity
            step={1}
            totalSteps={totalSteps}
            selectedId={selectedFundingSource || void 0}
            vendorId={paymentIntent.vendorId}
            paymentAmount={paymentIntent?.amountToPay || 0}
            onBack={goToPreviousStep}
            onClose={onClose}
            onError={onError}
            onDone={handleSetFundingSource}
            failedDate={failedDate}
            originalFundingSourceId={originalPaymentIntent.payments?.[0]?.fundingSourceId || void 0}
            fundingSourceTypesOptions={fundingSourceTypesOptions}
            billId={paymentIntent.billInfo.id}
          />
        );

      case 'DELIVERY_SPEED_DATE_SELECTION':
        return (
          <DeliveryDateActivity
            step={2}
            totalSteps={totalSteps}
            paymentIntentId={paymentIntentId}
            onBack={goToPreviousStep}
            onClose={onClose}
            origin="failedToCollect"
            onError={handleFail}
            onDone={handleSetDeliveryPreferenceType}
          />
        );

      case 'REVIEW_AND_CONFIRM':
        return (
          <ReviewAndConfirmActivity
            step={totalSteps}
            totalSteps={totalSteps}
            paymentIntentId={paymentIntentId}
            onEditFundingSource={() => goToStep('FUNDING_SOURCE_SELECTION')}
            onEditDate={
              checkHasDeliveryFastOption(paymentIntent, originalPaymentIntent)
                ? () => goToStep('DELIVERY_SPEED_DATE_SELECTION')
                : undefined
            }
            onBack={goToPreviousStep}
            onClose={onClose}
            onError={handleFail}
            onDone={handleConfirm}
            updatedFields={getDifferenceKeysByValue(paymentIntent, originalPaymentIntent)}
            isLoadingButton={isLoadingReviewAndConfirmButton}
            enableLateTag={!!failedDate}
          />
        );
    }
  }
);
