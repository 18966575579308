import { Logger } from '@melio/platform-logger';

import { useSubscriptionContext } from '../context';
import { FeatureRequest, getIsFeatureInPlan, QuantityBasedFeatureRequest } from '../utils/planFeatures.utils';
import { useIsSubscriptionsEnabled } from './useIsSubscriptionsEnabled';

type FeatureEligibilityData = {
  isEligible: boolean;
};

type QuantityBasedFeatureEligibilityData = FeatureEligibilityData & {
  quota: { freeUnitsLimit: number; excessUnitFee?: number };
};

type BooleanBasedFeatureEligibilityData = FeatureEligibilityData;

export function useIsFeatureInSubscription<T extends FeatureRequest>(
  feature: T
): T extends QuantityBasedFeatureRequest ? QuantityBasedFeatureEligibilityData : BooleanBasedFeatureEligibilityData;

export function useIsFeatureInSubscription(
  feature: FeatureRequest
): QuantityBasedFeatureEligibilityData | BooleanBasedFeatureEligibilityData {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const {
    plans,
    subscription,
    gracePeriod: { isEligibleForGracePeriod, isTodayInGracePeriod },
  } = useSubscriptionContext();

  if (!isSubscriptionsEnabled || (isEligibleForGracePeriod && isTodayInGracePeriod)) {
    const featureName = feature.featureName;
    const isEligible = featureName !== 'achTPlusTwo' && featureName !== 'requestCallbackSupport';
    return {
      isEligible,
      quota: { freeUnitsLimit: isEligible ? Infinity : 0 },
    };
  }

  if (!subscription) {
    return { isEligible: false, quota: { freeUnitsLimit: 0 } };
  }

  const currentPlan = plans.find((plan) => plan.id === subscription.planId);

  if (!currentPlan) {
    const error = `currentPlan not found. planId: ${subscription.planId}`;
    Logger.log(error, 'error');
    throw new Error(error);
  }

  const eligibilityData = getIsFeatureInPlan(currentPlan, feature);

  if ('quota' in eligibilityData) {
    const excessUnitFee = eligibilityData.quota.excessFee?.[subscription.planCyclePeriod];
    const freeUnitsLimit = eligibilityData.quota.freeUnitsLimit;

    return { isEligible: eligibilityData.isEligible, quota: { freeUnitsLimit, excessUnitFee } };
  }

  return { isEligible: eligibilityData.isEligible };
}
