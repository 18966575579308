import { getIsVoidAndRefundEnabled } from '@melio/ap-domain';
import { Container, Group } from '@melio/penny';
import { ApprovalDecision, PaymentFullyExpanded } from '@melio/platform-api';
import { RefObject } from 'react';

import { isPaymentScheduledVoidToResend } from '../../../../../utils/pay-flow/Payment.utils';
import { usePaymentDescription } from '../../../hooks/usePaymentDescription';
import { ResendedCheckBanner } from '../completed/components/ResendedCheckBanner';
import { UndepositedCheckBanner } from '../completed/components/UndepositedCheckBanner';
import { PaymentDescription } from '../PaymentDescription';
import { PaymentDetailsTop } from '../PaymentDetailsTop';
import { NoteToSelfSection } from '../sections/NoteToSelfSection';
import { PaidToSection } from '../sections/PaidToSection';
import { PayFromSection } from '../sections/PayFromSection';
import { PaymentApprovalDecisionSection } from '../sections/PaymentApprovalDecisionSection';
import { PaymentScheduledBySection } from '../sections/PaymentScheduledBySection';
import { SectionWrapper } from '../sections/SectionWrapper';
import { useSections } from '../sections/useSections';
import { VendorReceivesSection } from '../sections/VendorReceivesSection';

export const PaymentInProgress = ({
  payment,
  billDetailsRef,
  approvalDecisions,
}: {
  payment: PaymentFullyExpanded;
  billDetailsRef: RefObject<HTMLDivElement>;
  approvalDecisions?: ApprovalDecision[];
}) => {
  const { vendor, createdBy, history } = payment;

  const { showScheduledBySection, showApprovalDecisionSection } = useSections({ payment, approvalDecisions });

  const isVoidAndRefundEnabled = getIsVoidAndRefundEnabled(payment);
  const isVoidingToResend = isPaymentScheduledVoidToResend(payment);
  const description = usePaymentDescription(payment);

  return (
    <>
      {description ? <PaymentDescription description={description} /> : null}
      <Container data-testid="pay-dashboard-payment-in-progress">
        <Group variant="vertical" spacing="m">
          <SectionWrapper>
            {isVoidAndRefundEnabled && <UndepositedCheckBanner payment={payment} />}
            {isVoidingToResend && <ResendedCheckBanner payment={payment} />}
            <PaymentDetailsTop payment={payment} />
          </SectionWrapper>

          <SectionWrapper>
            <PaidToSection payment={payment} billDetailsRef={billDetailsRef} />
          </SectionWrapper>

          {showApprovalDecisionSection || showScheduledBySection ? (
            <SectionWrapper>
              <Group variant="vertical" hasDivider spacing="m">
                {showApprovalDecisionSection && approvalDecisions ? (
                  <PaymentApprovalDecisionSection approvalDecisions={approvalDecisions} />
                ) : null}

                {showScheduledBySection && createdBy ? (
                  <PaymentScheduledBySection scheduledDate={history.createdAt} user={createdBy} />
                ) : null}
              </Group>
            </SectionWrapper>
          ) : null}

          <NoteToSelfSection userNote={payment.userNote} />

          <SectionWrapper>
            <PayFromSection payment={payment} />
          </SectionWrapper>

          <SectionWrapper>
            <VendorReceivesSection vendorName={vendor.name} payment={payment} />
          </SectionWrapper>
        </Group>
      </Container>
    </>
  );
};
