import { SectionBanner } from '@melio/penny';
import { TaxpayerInfo } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  taxPayerInfo?: TaxpayerInfo;
};

export const TINVerificationStatusBanner = ({ taxPayerInfo }: Props) => {
  const { formatMessage } = useMelioIntl();

  if (!taxPayerInfo) {
    return null;
  }

  if (taxPayerInfo.taxIdVerified) {
    return (
      <SectionBanner
        data-testid="tin-verification-success"
        description={formatMessage('widgets.taxPayerInfo.tinVerificationStatus.sucess')}
        variant="success"
      />
    );
  }

  if (!taxPayerInfo.taxIdVerified && !taxPayerInfo.taxIdNotVerifiedCause) {
    return (
      <SectionBanner
        data-testid="tin-verification-not-verified"
        variant="warning"
        description={formatMessage('widgets.taxPayerInfo.tinVerificationStatus.notVerified')}
      />
    );
  }

  if (!taxPayerInfo.taxIdVerified && taxPayerInfo.taxIdNotVerifiedCause === 'MISSING_DETAILS') {
    return (
      <SectionBanner
        data-testid="tin-verification-missing-details"
        variant="critical"
        description={formatMessage('widgets.taxPayerInfo.tinVerificationStatus.missingDetails')}
      />
    );
  }

  return null;
};
