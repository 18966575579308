import { AccountingPlatformSlug, useAccountingPlatforms } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';

export const useVendorUniqueNameEnabled = () => {
  const [isPlatformMultipleVendorsWithTheSameNameEnabled] = usePartnerFeature(
    'PlatformMultipleVendorsWithTheSameName',
    false
  );
  const { activeAccountingPlatform } = useAccountingPlatforms();
  const isQuickBooksOnline = activeAccountingPlatform?.accountingSlug === AccountingPlatformSlug.QuickBooksOnline;
  const isVendorUniqueNameEnabled = isPlatformMultipleVendorsWithTheSameNameEnabled && isQuickBooksOnline;

  return isVendorUniqueNameEnabled;
};
