import { StatusIconSolid } from '@melio/penny';
import {
  TodoOverdueIn7DaysInboxItems,
  TodoOverdueIn7DaysInboxItemsTypeEnum,
  TodoOverdueInMoreThan7DaysInboxItems,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useActivitiesNavigate } from '../../../../utils';
import { PayDashboardTabs } from '../../../PayDashboard';
import { useTodosDescriptions } from '../../hooks/useTodosDescriptions';
import { EnrichedTodo, isEmptySection, normalizeInboxItems } from '../../utils';
import { TodoSection } from '../TodoSection';

export type TodoSectionOverdueInFutureInboxItemsProps = {
  overdueIn7Days?: EnrichedTodo<TodoOverdueIn7DaysInboxItems>;
  overdueInMoreThan7Days?: EnrichedTodo<TodoOverdueInMoreThan7DaysInboxItems>;
};

export const TodoSectionOverdueInFutureInboxItems = ({
  overdueIn7Days,
  overdueInMoreThan7Days,
}: TodoSectionOverdueInFutureInboxItemsProps) => {
  const { formatMessage } = useMelioIntl();
  const { navigateToTab } = useActivitiesNavigate();
  const { createAmountDescription, createViewBillsAction, createDueDateDescription } = useTodosDescriptions();

  if (isEmptySection(overdueIn7Days) && isEmptySection(overdueInMoreThan7Days)) {
    return null;
  }

  const overdueIn7DaysTotalItems = overdueIn7Days?.items?.pagination?.totalCount ?? 0;
  const overdueInMoreThan7DaysTotalItems = overdueInMoreThan7Days?.items?.pagination?.totalCount ?? 0;
  const totalItems = overdueIn7DaysTotalItems + overdueInMoreThan7DaysTotalItems;
  const data = [...normalizeInboxItems(overdueIn7Days), ...normalizeInboxItems(overdueInMoreThan7Days)];
  const allItemsAreScannedInvoice = data.every((d) => d.type === 'scannedInvoice');

  const calcTitle = () => {
    if (allItemsAreScannedInvoice) {
      return formatMessage('widgets.todosDrawer.section.overdueInTheFuture.scannedInvoices.title', {
        count: totalItems,
      });
    } else if (totalItems === 1) {
      if (overdueIn7DaysTotalItems > 0) {
        return formatMessage('widgets.todosDrawer.section.overdueInWeekBills.singular.title', {
          vendorName: data[0]?.vendorName ?? '',
        });
      } else {
        return formatMessage('widgets.todosDrawer.section.overdueInFutureBills.singular.title', {
          vendorName: data[0]?.vendorName ?? '',
        });
      }
    } else {
      return formatMessage('widgets.todosDrawer.section.overdueInTheFuture.title', {
        total: totalItems,
      });
    }
  };

  const calcDescription = () => {
    const description = [...createAmountDescription(totalItems, data)];
    if (totalItems === 1 && data[0]?.dueDate) {
      description.push(...createDueDateDescription(totalItems, data.length, data[0].dueDate, 'dueDate'));
    }
    return description;
  };

  const title = calcTitle();
  const description = calcDescription();
  const newItemsCount =
    (overdueIn7Days?.enrichment.newItemsCount ?? 0) + (overdueInMoreThan7Days?.enrichment.newItemsCount ?? 0);

  const onClickAction = () => {
    const firstItem =
      overdueIn7Days && overdueIn7Days?.items.data.length > 0
        ? overdueIn7Days?.items.data[0]
        : overdueInMoreThan7Days?.items.data[0];

    navigateToTab(PayDashboardTabs.Bills, totalItems === 1 && firstItem ? { entityId: firstItem.payload?.id } : {});
  };
  const action = createViewBillsAction(totalItems, onClickAction);

  return (
    <TodoSection
      key={`section-${TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems}`}
      icon={<StatusIconSolid variant="informative" size="medium" />}
      title={title}
      description={description}
      action={action}
      type={TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems}
      newItemsCount={newItemsCount}
    />
  );
};
