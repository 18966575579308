import { Button, Form, Group, Modal, RadioOption, Text, useFormSubmissionController, useMelioForm } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { useIsSubscriptionsEnabled } from '../../../../../hooks';
import { useFeedbackModalSchema } from './schema';
import { FeedbackModalFormTypes } from './types';

type FeedbackReason = 'usage' | 'need' | 'discovery' | 'billed' | 'alternative' | 'expensive' | 'other';

const feedbackReasons: FeedbackReason[] = ['usage', 'need', 'discovery', 'billed', 'alternative', 'expensive', 'other'];

export type SubscriptionCancelFeedbackModalScreenProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

export const SubscriptionCancelFeedbackModalScreen = withAnalyticsContext<SubscriptionCancelFeedbackModalScreenProps>(
  ({ isOpen, onSuccess, onClose, setAnalyticsProperties }) => {
    setAnalyticsProperties({
      PageName: 'before-you-go',
      Flow: 'settings',
      Intent: 'subscription-canceled-feedback',
    });
    const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    const { submitButtonProps, onSubmissionStateChange, reset } = useFormSubmissionController<FeedbackModalFormTypes>();
    const { registerField, formProps, formState } = useMelioForm<FeedbackModalFormTypes>({
      onSubmit: (fieldValues) => {
        track('Organization', 'Click', {
          Cta: 'submit',
          CancelationReason: fieldValues.reason,
          IsFilledAdditionalFeedback: !!fieldValues.feedback,
          AdditionalFeedback: fieldValues.feedback || '',
        });
        reset?.();
        onSuccess ? onSuccess() : onClose();
      },
      schema: useFeedbackModalSchema(),
      defaultValues: {
        reason: '',
        feedback: '',
      },
      onSubmissionStateChange,
    });

    useAnalyticsView('Organization', isOpen, true);

    const onCloseHandler = (closeType: string) => {
      reset?.();
      track('Organization', 'Click', {
        Cta: closeType,
      });
      onClose();
    };

    if (!isSubscriptionsEnabled) {
      return null;
    }

    const getFeedbackReasonMessage = (reason: FeedbackReason) =>
      formatMessage(`activities.subscription.feedbackModal.options.${reason}`);

    const feedbackOptions: RadioOption[] = feedbackReasons.map((reason) => ({
      value: reason,
      mainLabelProps: { label: getFeedbackReasonMessage(reason) },
      id: reason,
    }));

    return (
      <Modal
        isOpen={isOpen}
        onClose={() => onCloseHandler('exit')}
        header={formatMessage('activities.subscription.feedbackModal.title')}
        footer={
          <Group justifyContent="space-between">
            <Button
              variant="tertiary"
              label={formatMessage('activities.subscription.feedbackModal.secondaryButton')}
              onClick={() => onCloseHandler('cancel')}
              data-testid="subscription-cancel-feedback-modal-cancel"
            />
            <Button
              label={formatMessage('activities.subscription.feedbackModal.primaryButton')}
              {...submitButtonProps}
              isDisabled={!formState.isValid}
              data-testid="subscription-cancel-feedback-modal-submit"
            />
          </Group>
        }
        data-testid="subscription-cancel-feedback-modal-screen"
      >
        <Text>{formatMessage('activities.subscription.feedbackModal.description')}</Text>
        <Form {...formProps}>
          <Form.RadioGroup
            marginTop="m"
            paddingBottom="none"
            {...registerField('reason')}
            variant="vertical"
            options={feedbackOptions}
          />
          <Form.TextArea
            {...registerField('feedback')}
            placeholder={formatMessage('activities.subscription.feedbackModal.textarea')}
            aria-label={formatMessage('activities.subscription.feedbackModal.textarea')}
          />
        </Form>
      </Modal>
    );
  }
);
