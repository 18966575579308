import { validateNotContainsEmail } from '@melio/ap-domain';
import { validateUniqueNameForSync } from '@melio/ap-domain/src/hooks/vendors/vendor-name-constraints/utils';
import { useVendors } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { object, SchemaOf, string } from 'yup';

export type FormFields = {
  uniqueName: string;
};

export const useFormSchema = ({ vendorId, isManaged }: { vendorId?: string; isManaged: boolean }) => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      vendor: {
        createVendor: { uniqueNameFormatRegexp, companyNameMaxLength, companyNameMinLength },
      },
    },
  } = useConfig();
  const [vendorNameConstraintsEnabled] = useDevFeature(FeatureFlags.PlatformVendorNameConstraints, false);
  const { data: vendors } = useVendors({ enabled: true });

  return object().shape({
    uniqueName: string()
      .trim()
      .required(formatMessage('widgets.vendorNameInQBOModal.form.companyNameInQBO.validation.required'))
      .when({
        is: () => companyNameMaxLength,
        then: (schema) =>
          schema.max(
            companyNameMaxLength as number,
            formatMessage('widgets.vendorNameInQBOModal.form.companyNameInQBO.validation.maxLength')
          ),
      })
      .when({
        is: () => companyNameMinLength,
        then: (schema) =>
          schema.min(
            companyNameMinLength as number,
            formatMessage('widgets.vendorNameInQBOModal.form.companyNameInQBO.validation.minLength')
          ),
      })
      .when({
        is: () =>
          uniqueNameFormatRegexp ? new RegExp(uniqueNameFormatRegexp.pattern, uniqueNameFormatRegexp.flags) : undefined,
        then: (schema) =>
          schema.matches(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            new RegExp(uniqueNameFormatRegexp!.pattern, uniqueNameFormatRegexp!.flags),
            formatMessage('widgets.vendorNameInQBOModal.form.companyNameInQBO.validation.format')
          ),
      })
      .test(
        'is-name-for-sync-unique',
        formatMessage('widgets.vendorNameInQBOModal.form.companyNameInQBO.validation.alreadyExists'),
        validateUniqueNameForSync(vendors, vendorId)
      )
      .test(
        'not-contains-email',
        formatMessage('form.vendorSelect.constraints.email'),
        validateNotContainsEmail({ isEnabled: vendorNameConstraintsEnabled, isManaged })
      ),
  }) as SchemaOf<FormFields>;
};
