import { ModelError } from '@melio/platform-api';
import { MessageKey } from '@melio/platform-i18n';

export const getSubmitErrorMessageKey = (error: ModelError): MessageKey => {
  const submitErrorDefaultMessageKey = 'activities.ebillsSubscriptionForm.form.errors.submit';
  const errorCode = error.data?.[0]?.errorCode;

  if (!errorCode) {
    return submitErrorDefaultMessageKey;
  }

  switch (errorCode) {
    case 'account_number_in_use':
      return 'activities.ebillsSubscriptionForm.form.submit.apiErrors.account_number_in_use';
    case 'invalid_account_number':
      return 'activities.ebillsSubscriptionForm.form.submit.apiErrors.invalid_account_number';
    case 'ebill_already_active':
      return 'activities.ebillsSubscriptionForm.form.submit.apiErrors.ebill_already_active';
    case 'account_was_in_trial_period':
      return 'activities.ebillsSubscriptionForm.form.submit.apiErrors.account_was_in_trial_period';
    case 'active_with_another_subscriber':
      return 'activities.ebillsSubscriptionForm.form.submit.apiErrors.active_with_another_subscriber';
    default:
      return submitErrorDefaultMessageKey;
  }
};
