import { useMelioIntl, useSystemMessage, useTaxRate } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { DeleteIEntityDialogScreen } from '../shared';

type DeleteTaxRateDialogActivityProps = {
  taxRateId?: string;
  onDone: VoidFunction;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
};

export const DeleteTaxRateDialogActivity = forwardRef<DeleteTaxRateDialogActivityProps>(
  ({ taxRateId, onDone: onSuccess, onClose, onError, ...props }, ref) => {
    const { data, delete: deleteTaxRate, isLoading, isMutating } = useTaxRate({ id: taxRateId, onError });

    const { formatMessage } = useMelioIntl();
    const { triggerMessage } = useSystemMessage();

    const onSubmit = () =>
      deleteTaxRate()
        .then(() => {
          onSuccess();
          triggerMessage({
            type: 'informative',
            title: formatMessage('ar.settings.activities.deleteRaxRate.toast.onDelete.label', { name: data?.name }),
          });
        })
        .catch(onError);

    return (
      <DeleteIEntityDialogScreen
        data-testid="delete-tax-rate-dialog"
        id={taxRateId}
        onSubmit={onSubmit}
        onClose={onClose}
        isDeleting={isMutating}
        isLoading={isLoading}
        header={formatMessage('ar.settings.activities.deleteRaxRate.dialog.header')}
        description={formatMessage('ar.settings.activities.deleteRaxRate.dialog.description', { name: data?.name })}
        primaryButtonLabel={formatMessage('ar.settings.activities.deleteRaxRate.dialog.primaryButton.label')}
        secondaryButtonLabel={formatMessage('ar.settings.activities.deleteRaxRate.dialog.secondaryButton.label')}
        {...props}
        ref={ref}
      />
    );
  }
);
DeleteTaxRateDialogActivity.displayName = 'DeleteTaxRateActivity';
