import { ApprovalWorkflowRequest, useApprovalWorkflow } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useSystemMessage } from '@melio/platform-utils';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MonitoredAction } from '../../../monitoring';
import { ApprovalWorkflowForm } from '../ApprovalWorkflowFormScreen/ApprovalWorkflowForm.screen';
import { ApprovalWorkflowFormV2 } from '../ApprovalWorkflowFormScreenV2/ApprovalWorkflowFormV2.screen';
import { useAnalyticsTracker } from '../useAnalyticsTracker';
import { ConfirmApprovalWorkflowChangesModal } from './ConfirmApprovalWorkflowChangesModal/ConfirmApprovalWorkflowChangesModal';

type Props = {
  onClose: VoidFunction;
  onBack: VoidFunction;
  onDone: () => void;
};
export function UpdateApprovalWorkflowActivity(props: Props) {
  const [isApprovalWorkflowsV2] = useDevFeature(FeatureFlags.PlatformApprovalWorkflowsV2, false, {
    shouldTrack: true,
  });
  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useMelioIntl();
  const { routeReady, startAction, endAction } = useMonitoring<MonitoredAction>();
  const { trackClick, trackView, track, isLoading } = useAnalyticsTracker({ intent: 'edit-approval-workflow' });
  const { showMessage } = useSystemMessage();
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] = useState(false);
  const editedWorkflow = useRef<ApprovalWorkflowRequest>();
  const {
    data,
    isMutating,
    isLoading: isLoadingApprovalWorkflow,
    update,
  } = useApprovalWorkflow({
    id,
    onUpdate: () => {
      endAction('approval_workflow_create_submit');
      showMessage({
        type: 'informative',
        title: formatMessage('activities.update-approval-workflows.toast.success.save'),
      });
      track('Settings', 'Status', {
        PageName: 'workflow-updated',
        Flow: 'approval-workflow',
        Intent: 'approval-workflow',
        Status: 'success',
        workflowId: id,
      });
      props.onDone();
    },
    onUpdateError: (e) => {
      showMessage({ type: 'error', title: formatMessage('activities.update-approval-workflows.toast.failed.save') });
      track('User', 'Status', {
        PageName: 'workflow-updated',
        Flow: 'approval-workflow',
        Intent: 'approval-workflow',
        Status: 'failure',
        workflowId: id,
        ErrorType: e.message,
      });
    },
  });

  useEffect(() => {
    if (isConfirmChangesModalOpen) {
      if (!editedWorkflow.current) {
        throw Error('edited workflow is not defined');
      }
      trackView({ pageName: 'changes-may-affect-some-payments', workflow: editedWorkflow.current, workflowId: id });
    }
  }, [isConfirmChangesModalOpen, id, trackView]);

  useEffect(() => {
    trackView({ pageName: 'edit-payment-approval-workflow' });
  }, [trackView]);

  const handleConfirmChanges = () => {
    if (!editedWorkflow.current) {
      throw Error('edited workflow is not defined');
    }

    const workflow = editedWorkflow.current;
    trackClick({ cta: 'confirm-changes', pageName: 'changes-may-affect-some-payments', workflow, workflowId: id });
    startAction('approval_workflow_edit_submit');
    update(editedWorkflow.current);
  };
  const handleCloseChanges = (intent: 'exit' | 'cancel') => {
    if (!editedWorkflow.current) {
      throw Error('edited workflow is not defined');
    }

    const workflow = editedWorkflow.current;
    trackClick({ cta: intent, pageName: 'changes-may-affect-some-payments', workflow, workflowId: id });
    setIsConfirmChangesModalOpen(false);
  };

  if (isLoading || !data) {
    return null;
  }

  const currentWorkflow = { criteria: data.criteria, actions: data.actions };

  const renderForm = () => {
    if (isApprovalWorkflowsV2) {
      return (
        <ApprovalWorkflowFormV2
          ref={routeReady}
          title={formatMessage('activities.edit-approval-workflows.title')}
          onClose={(workflow) => {
            trackClick({ cta: 'cancel', pageName: 'edit-payment-approval-workflow', workflow, workflowId: id });
            props.onClose();
          }}
          onBack={(workflow) => {
            trackClick({ cta: 'back', pageName: 'edit-payment-approval-workflow', workflow, workflowId: id });
            props.onBack();
          }}
          defaultValues={currentWorkflow}
          isSaving={isMutating}
          isLoading={isLoadingApprovalWorkflow || isLoading}
          onSubmit={(workflow) => {
            trackClick({ cta: 'save-workflow', pageName: 'edit-payment-approval-workflow', workflow, workflowId: id });
            editedWorkflow.current = workflow;
            setIsConfirmChangesModalOpen(true);
          }}
        />
      );
    }

    return (
      <ApprovalWorkflowForm
        ref={routeReady}
        title={formatMessage('activities.edit-approval-workflows.title')}
        onClose={(workflow) => {
          trackClick({ cta: 'cancel', pageName: 'edit-payment-approval-workflow', workflow });
          props.onClose();
        }}
        onBack={(workflow) => {
          trackClick({ cta: 'back', pageName: 'edit-payment-approval-workflow', workflow });
          props.onBack();
        }}
        defaultValues={currentWorkflow}
        isSaving={isMutating}
        isLoading={isLoadingApprovalWorkflow || isLoading}
        onSubmit={(workflow) => {
          trackClick({ cta: 'save-workflow', pageName: 'edit-payment-approval-workflow', workflow });
          editedWorkflow.current = workflow;
          setIsConfirmChangesModalOpen(true);
        }}
      />
    );
  };
  return (
    <>
      {renderForm()}
      <ConfirmApprovalWorkflowChangesModal
        onConfirm={handleConfirmChanges}
        onClose={handleCloseChanges}
        isOpen={isConfirmChangesModalOpen}
        isSaving={isMutating}
      />
    </>
  );
}
