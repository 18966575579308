import { StatusModal, Text } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { Plan } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { useSubscriptionContext } from '../../../context';

export type RejectAccountingClientDiscountProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onClaimDiscountClick: VoidFunction;
  onContinue: VoidFunction;
  selectedPlan?: Plan;
  selectedAccountingClientPlan?: Plan;
};

export const RejectAccountingClientDiscountModal = withAnalyticsContext<RejectAccountingClientDiscountProps>(
  ({
    isOpen,
    onClose,
    onClaimDiscountClick,
    onContinue,
    setAnalyticsProperties,
    selectedPlan,
    selectedAccountingClientPlan,
  }) => {
    const { formatMessage } = useMelioIntl();
    const { clientPlanDiscountPercent } = useSubscriptionContext();
    const { track } = useAnalytics();

    setAnalyticsProperties({
      PageName: 'continue-without-discount',
      Flow: 'subscription',
      Intent: 'choose-plan',
      PlanChosen: selectedPlan?.tier,
      SelectedPlanByAccountingFirm: selectedAccountingClientPlan?.tier,
      Discount: clientPlanDiscountPercent,
      DiscountType: '%',
    });

    useEffect(() => {
      if (isOpen) {
        track('Organization', 'View');
      }
    }, [track, isOpen]);

    const handlePrimaryButtonClick = () => {
      track('Organization', 'Click', { Cta: 'continue' });
      onContinue();
    };

    const handleSecondaryButtonClick = () => {
      track('Organization', 'Click', { Cta: 'claim-discount-instead' });
      onClaimDiscountClick();
    };

    const handleClose = () => {
      track('Organization', 'Click', { Cta: 'close' });
      onClose();
    };

    return (
      <StatusModal
        data-testid="claim-accounting-client-discount-modal"
        isOpen={isOpen}
        onClose={handleClose}
        header={formatMessage('activities.subscription.rejectAccountingClientDiscount.modal.title')}
        variant="warning"
        primaryButton={{
          label: formatMessage('activities.subscription.rejectAccountingClientDiscount.modal.primary.button.label'),
          onClick: handlePrimaryButtonClick,
          variant: 'primary',
        }}
        secondaryButton={{
          label: formatMessage('activities.subscription.rejectAccountingClientDiscount.modal.secondary.button.label'),
          onClick: handleSecondaryButtonClick,
          variant: 'tertiary',
        }}
      >
        <Text>
          {formatMessage('activities.subscription.rejectAccountingClientDiscount.modal.content', {
            discountPercentage: clientPlanDiscountPercent,
          })}
        </Text>
      </StatusModal>
    );
  }
);
