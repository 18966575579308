import { ApiQueryKey, useMelioQueryClient, useNonEntityMutation } from '@melio/api-client';
import { ReceivingMethodsApiClient } from '@melio/ar-api-axios-client';

export const useReceivingMethodsMutations = (queryKey: ApiQueryKey) => {
  const queryClient = useMelioQueryClient();

  const createFromFundingSourceMutation = useNonEntityMutation(
    ReceivingMethodsApiClient.postDeliveryMethodFromFundingSource,
    queryKey,
    {
      onSuccess: () => queryClient.invalidateQueries('ReceivingMethodsApi'),
    }
  );

  return {
    createFromFundingSource: createFromFundingSourceMutation.mutateAsync,
  };
};
