import { useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccount, useDeliveryMethods, useOrganizationBeneficialOwners, useVendor } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMissingCompanyLegalInfoFields } from '@melio/platform-kyc';
import { useEffect, useState } from 'react';

import { AddInternationalBusinessInfoActivity } from '../../business-legal-info/AddInternationalBusinessInfo';
import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { AddOrganizationBeneficialOwnersActivity, InternationalBankDetailsActivity } from './steps';
import { InternationalBankDetailsFormFields } from './steps/international-bank-details/screens';
import { InternationalVendorDetailsActivity } from './steps/international-vendor-details/InternationalVendorDetails.activity';
import { AddInternationalDeliveryMethodActivityProps, AddInternationalDeliveryMethodAnalyticsDataType } from './types';
import { useAddInternationalDeliveryMethodHandlers } from './useAddInternationalDeliveryMethodHandlers';

export const AddInternationalDeliveryMethodActivity = withAnalyticsContext(
  ({
    vendorId,
    onBack: onFirstStepBack,
    onClose,
    isLoading,
    onDone,
    origin = 'payment',
  }: AddInternationalDeliveryMethodActivityProps) => {
    const [isMissingLegalDetails, setIsMissingLegalDetails] = useState<boolean>(false);
    const [isMissingOrganizationBeneficialOwnersDetails, setIsMissingOrganizationBeneficialOwnersDetails] =
      useState<boolean>(false);

    const { data: account, missingUserLegalInfoFields, isLoading: isAccountLoading } = useAccount({ id: 'me' });

    const { missingCompanyLegalInfoFields } = useMissingCompanyLegalInfoFields() || {};

    const { data: organizationBeneficialOwners, isMutating: isUboLoading } = useOrganizationBeneficialOwners();
    const [isInternationalDeliveryMethodSupported] = useDevFeature(
      FeatureFlags.IsInternationalDeliveryMethodSupported,
      false
    );
    const { isMutating: isDeliveryMethodsLoading } = useDeliveryMethods({
      vendorId,
      enabled: false,
    });
    const { data: vendor, isLoading: isVendorLoading } = useVendor({ id: vendorId });
    const [analyticsData, setAnalyticsData] = useState<AddInternationalDeliveryMethodAnalyticsDataType>();

    useAnalyticsContext({ globalProperties: { DeliveryMethodType: 'international', ...analyticsData } });

    useEffect(() => {
      if (missingCompanyLegalInfoFields?.length || missingUserLegalInfoFields?.length) {
        setIsMissingLegalDetails(true);
      }
      if (account?.company.taxInfo?.type === 'EIN') {
        setIsMissingOrganizationBeneficialOwnersDetails(!organizationBeneficialOwners?.length);
      }
    }, [missingCompanyLegalInfoFields, missingUserLegalInfoFields, account, organizationBeneficialOwners]);

    const {
      currentStep,
      goToPreviousStep,
      onBankDetailsDone,
      onVendorDetailsDone,
      onBusinessInfoDone,
      onOwnersDone,
      vendorDetailsDefaultValues,
      bankDetailsDefaultValues,
      setDefaultCountryDetails,
      shouldShowFailedCreationBanner,
      setShouldShowFailedCreationBanner,
    } = useAddInternationalDeliveryMethodHandlers({
      vendorId,
      onDone,
      onFirstStepBack,
      isMissingLegalDetails,
      isMissingOrganizationBeneficialOwnersDetails,
      organizationBeneficialOwners,
    });

    if (isLoading || isAccountLoading || isUboLoading || isDeliveryMethodsLoading || isVendorLoading) {
      return <NewSinglePaymentStepLayout isLoading />;
    }

    // don't enter activity if:
    //   - international is not supported
    //   - vendor is already domestic
    //   - vendor is already international
    if (!isInternationalDeliveryMethodSupported || vendor?.businessGeography) {
      return null;
    }

    const onInternationalBankDetailsDone = (data: InternationalBankDetailsFormFields) => {
      setAnalyticsData({
        DeliveryCountry: data.selectedInternationalCountries.name,
        CountryRiskScore: data.selectedInternationalCountries.risk,
        BankIdentificationType: data.ibanCode ? 'IBAN' : data.swiftCode ? 'SWIFT' : null,
      });
      setDefaultCountryDetails(data.selectedInternationalCountries);
      onBankDetailsDone(data);
    };

    switch (currentStep) {
      case 'INTERNATIONAL_BANK_DETAILS':
      default:
        return (
          <InternationalBankDetailsActivity
            onClose={() => {
              setShouldShowFailedCreationBanner(false);
              onClose();
            }}
            onBack={() => {
              setShouldShowFailedCreationBanner(false);
              goToPreviousStep();
            }}
            onDone={(data) => {
              setShouldShowFailedCreationBanner(false);
              onInternationalBankDetailsDone(data);
            }}
            vendorId={vendorId}
            defaultValues={bankDetailsDefaultValues}
            shouldShowFailedCreationBanner={shouldShowFailedCreationBanner}
          />
        );

      case 'INTERNATIONAL_VENDOR_DETAILS':
        return (
          <InternationalVendorDetailsActivity
            onClose={onClose}
            onBack={goToPreviousStep}
            onDone={onVendorDetailsDone}
            origin={origin}
            vendorId={vendorId}
            isMissingLegalDetails={isMissingLegalDetails}
            isMissingOrganizationBeneficialOwnersDetails={isMissingOrganizationBeneficialOwnersDetails}
            defaultValues={vendorDetailsDefaultValues}
          />
        );

      case 'BUSINESS_LEGAL_DETAILS':
        return (
          <AddInternationalBusinessInfoActivity
            onClose={onClose}
            onBack={goToPreviousStep}
            onDone={onBusinessInfoDone}
            origin={origin}
          />
        );

      case 'ADD_ORGANIZATION_BENEFICIAL_OWNERS':
        return (
          <AddOrganizationBeneficialOwnersActivity
            onClose={onClose}
            onBack={goToPreviousStep}
            onDone={onOwnersDone}
            origin={origin}
          />
        );
    }
  }
);

AddInternationalDeliveryMethodActivity.displayName = 'AddInternationalDeliveryMethodActivity';
