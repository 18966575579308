import { useMelioIntl } from '@melio/ar-domain';
import { Chip, Group, NakedButton, SearchBar as DSSearchBar, Text } from '@melio/penny';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { forwardRef } from '@melio/platform-utils';
import { isEmpty } from 'lodash';

import { useFiltersLabel } from '../hooks';
import { Filters, QueryFilter } from '../types';
import { DateRangeFilter } from './DateRangeFilter';
import { InvoiceStatusFilter } from './InvoiceStatusFilter';

export type FilterBarProps = {
  selectedFilters: Filters;
  onSelectFilters: (filters: Filters) => void;
  onClearFilters: () => void;
  search?: string;
  onSearch: (value: string) => void;
};

export const FiltersToolBar = forwardRef<FilterBarProps>(
  ({ onSelectFilters, selectedFilters, onClearFilters, onSearch, search, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const filtersLabel = useFiltersLabel();
    const [isNewInvoiceFilters] = useDevFeature(FeatureFlags.ARNewInvoiceFilters, false);
    const orderedFilters: QueryFilter[] = ['all', 'draft', 'open', 'overdue', 'paid', 'canceled'];

    return (
      <Group
        variant="horizontal"
        justifyContent="flex-start"
        alignItems="center"
        width="full"
        spacing="s"
        data-component="FiltersToolBar"
        {...props}
        ref={ref}
      >
        <DSSearchBar
          data-testid="invoice-table-search-input"
          value={search}
          onChange={(e) => onSearch(e.target.value)}
          label={formatMessage('ar.dashboard.activities.invoiceTable.searchInput.label')}
          placeholder={formatMessage('ar.dashboard.activities.invoiceTable.searchInput.placeholder')}
          onClear={() => onSearch('')}
          aria-label={formatMessage('ar.dashboard.activities.invoiceTable.searchInput.aria-label')}
          instructionsText={formatMessage('ar.dashboard.activities.invoiceTable.searchInput.instructions')}
        />
        {isNewInvoiceFilters ? (
          <>
            <Text {...{ minWidth: 'fit-content' }} textStyle="body3Semi" color="global.neutral.900">
              {formatMessage('ar.dashboard.activities.invoiceTable.filters.advanced.title')}
            </Text>
            <Group
              variant="horizontal"
              justifyContent="flex-start"
              alignItems="center"
              width="full"
              allowOverflowX
              spacing="s"
              ref={ref}
            >
              <InvoiceStatusFilter
                onApply={(filter) => onSelectFilters({ invoiceStatus: filter })}
                selectedStatusFilters={selectedFilters.invoiceStatus ? selectedFilters.invoiceStatus : undefined}
              />
              <DateRangeFilter
                type="updatedAtRange"
                selectedFilter={selectedFilters.updatedAtRange}
                onApply={(dateRange) => onSelectFilters({ updatedAtRange: dateRange })}
              />
              <DateRangeFilter
                selectedFilter={selectedFilters.dueDateRange}
                type="dueDateRange"
                onApply={(dateRange) => onSelectFilters({ dueDateRange: dateRange })}
              />
              {!isEmpty(selectedFilters) && (
                <NakedButton
                  data-testid="invoice-table-clear-filters"
                  variant="primary"
                  label={formatMessage('ar.dashboard.activities.invoiceTable.filters.advanced.clear.label')}
                  onClick={onClearFilters}
                />
              )}
            </Group>
          </>
        ) : (
          <>
            <Text {...{ minWidth: 'fit-content' }} textStyle="body3Semi" color="global.neutral.900">
              {formatMessage('ar.dashboard.activities.invoiceTable.filters.label')}
            </Text>
            <Group
              variant="horizontal"
              justifyContent="flex-start"
              alignItems="center"
              width="full"
              spacing="s"
              allowOverflowX
              ref={ref}
            >
              {orderedFilters.map((filter) => (
                <Chip
                  key={filter}
                  onClick={() =>
                    onSelectFilters(filter === 'all' ? { invoiceStatus: undefined } : { invoiceStatus: [filter] })
                  }
                  label={filtersLabel[filter]}
                  isSelected={
                    filter === 'all'
                      ? !selectedFilters.invoiceStatus?.[0]
                      : selectedFilters.invoiceStatus?.[0] === filter
                  }
                  data-testid={`ar-dashboard-filter-${filter}`}
                />
              ))}
            </Group>
          </>
        )}
      </Group>
    );
  }
);
FiltersToolBar.displayName = 'FiltersToolBar';
