import { Currency, FieldValidationData, RequiredFields } from '@melio/international-payments-utils';
import { Form, Group, RadioOption, UseMelioFormResults, useWatch } from '@melio/penny';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';

import { getRequireFieldsForBankDetails } from './fxBankDetailsForm.utils';
import { FxVendorAccountFieldsFormFields } from './types';

const tooltip_per_currency: Partial<Record<keyof FxVendorAccountFieldsFormFields, Array<Currency | undefined>>> = {
  institutionNumber: [Currency.CAD],
  transitNumber: [Currency.CAD],
  iban: [Currency.EUR, Currency.GBP, Currency.GEL, Currency.ILS, Currency.PLN, Currency.SEK],
  accountNumber: [Currency.HKD],
  ifscCode: [Currency.INR],
};

const RequireField = ({
  field,
  form,
}: {
  field: FieldValidationData;
  form: UseMelioFormResults<FxVendorAccountFieldsFormFields>;
}) => {
  const { control, registerField } = form;
  const { foreignCurrency } = useWatch(form);
  const { formatMessage } = useMelioIntl();

  const label = formatMessage(
    `activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.${field.key}.label` as MessageKey
  );
  switch (field.fieldType) {
    case 'DROPDOWN': {
      const placeOrder = formatMessage(
        `activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.${field.key}.placeHolder` as MessageKey
      );
      return (
        <Form.SelectNew
          {...registerField(field.key as keyof FxVendorAccountFieldsFormFields)}
          control={control}
          name={field.key}
          labelProps={{ label }}
          options={field.options?.map((x) => ({ value: x.key, label: x.name })) || []}
          isRequired
          placeholder={placeOrder}
        />
      );
    }
    case 'TEXT':
      return (
        <Form.TextField
          {...registerField(field.key as keyof FxVendorAccountFieldsFormFields)}
          labelProps={{
            label,
            tooltipProps: tooltip_per_currency[field.key as keyof FxVendorAccountFieldsFormFields]?.includes(
              foreignCurrency
            )
              ? {
                  content: (
                    <>
                      {formatMessage(
                        `activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.${field.key}.tooltip` as MessageKey
                      )}{' '}
                      tooltip
                      {formatMessage(
                        `activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.${field.key}.tooltip` as MessageKey
                      )}
                    </>
                  ),
                }
              : undefined,
          }}
          isRequired
          placeholder={field.placeholder}
          data-private
        />
      );
    case 'RADIOBUTTON':
      return (
        <Form.RadioGroup
          {...registerField(field.key as keyof FxVendorAccountFieldsFormFields)}
          labelProps={{
            label,
          }}
          isRequired
          options={
            field.options?.map(
              (option) =>
                ({
                  label: option.name,
                  value: option.key,
                } as RadioOption)
            ) || []
          }
        />
      );
    default:
      //TODO: not sure about it.
      throw new Error(`Field ${field.key} not supported ,type ${field.fieldType?.toString() || ''}`);
  }
};

export const RequiredFieldsComponent = ({
  requiredFields,
  form,
}: {
  requiredFields: RequiredFields[];
  form: UseMelioFormResults<FxVendorAccountFieldsFormFields>;
}) => (
  <Group variant="vertical" height="full">
    {getRequireFieldsForBankDetails(requiredFields)?.fields.map((field) => (
      <RequireField key={field.key} field={field} form={form} />
    ))}
  </Group>
);
