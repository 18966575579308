import { Form, Group, Modal, StatusModal, Text, useFormSubmissionController, useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { isVendorBankAccountNotCreated, useVendors, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { withSystemMessageProvider } from '@melio/platform-utils';
import { ComponentProps, useState } from 'react';

import { FiservBankAccountDetailsFields, InvalidVendorBankAccountDetails } from './types';
import { useVendorBankAccountSchema } from './useVendorBankAccountFormSchema';

type Props = Pick<ComponentProps<typeof Modal>, 'onClose' | 'isOpen'> & {
  invalidVendorBankAccountDetails: InvalidVendorBankAccountDetails;
  onSuccess: (vendorName: string) => void;
};

export const InvalidVendorBankAccountModal = withSystemMessageProvider(
  ({ isOpen, onClose, invalidVendorBankAccountDetails, onSuccess }: Props) => {
    const { track } = useAnalytics();

    const { formatMessage } = useMelioIntl();

    const { bankAccountNumber, fullVendorName, vendorId, vendorName } = invalidVendorBankAccountDetails;

    const { submitButtonProps, onSubmissionStateChange } =
      useFormSubmissionController<FiservBankAccountDetailsFields>();
    const { update: updateVendor, isMutating: isUpdatingVendor } = useVendors({ enabled: false });
    const [formError, setFormError] = useState<{ title?: string | undefined; description: string } | undefined>(
      undefined
    );

    const handleSuccess = (updatedVendor: Pick<Vendor, 'id' | 'warnings'>) => {
      const isBankAccountNotCreated = isVendorBankAccountNotCreated(updatedVendor.warnings);

      if (isBankAccountNotCreated) {
        track('DeliveryMethod', 'Status', {
          PageName: 'update-bank-account',
          Flow: 'edit-vendor',
          status: 'failure',
          ErrorType: 'invalid-account-details',
        });

        setFormError({
          title: formatMessage('widgets.invalidVendorBankAccountModal.banners.bankAccountIsNotElectronic.title'),
          description: formatMessage(
            'widgets.invalidVendorBankAccountModal.banners.bankAccountIsNotElectronic.description'
          ),
        });
      } else {
        track('DeliveryMethod', 'Status', {
          PageName: 'update-bank-account',
          Flow: 'edit-vendor',
          status: 'success',
        });

        onSuccess(fullVendorName);
      }
    };

    const handleFailure = () => {
      track('DeliveryMethod', 'Status', {
        PageName: 'update-bank-account',
        Flow: 'edit-vendor',
        status: 'failure',
        ErrorType: 'failed-to-save-bank-account',
      });

      setFormError({
        description: formatMessage('widgets.invalidVendorBankAccountModal.error'),
      });
    };

    const bankAccountForm = useMelioForm<FiservBankAccountDetailsFields>({
      schema: useVendorBankAccountSchema(),
      onSubmit: (submittedData) => {
        track('DeliveryMethod', 'Click', {
          PageName: 'update-bank-account',
          Flow: 'edit-vendor',
          Cta: 'update-account',
        });

        setFormError(undefined);

        return updateVendor(vendorId, {
          bankAccount: {
            routingNumber: submittedData.routingNumber,
            accountNumber: submittedData.accountNumber,
          },
        })
          .then((data) => handleSuccess(data))
          .catch(() => handleFailure());
      },
      onSubmissionStateChange,
      defaultValues: {
        routingNumber: '',
        accountNumber: '',
      },
    });

    const handleClose = () => {
      track('DeliveryMethod', 'Click', {
        PageName: 'update-bank-account',
        Flow: 'edit-vendor',
        Cta: 'close',
      });

      onClose();
    };

    return (
      <StatusModal
        variant="warning"
        isOpen={isOpen}
        data-testid="invalid-vendor-bank-account-modal"
        onClose={handleClose}
        header={formatMessage('widgets.invalidVendorBankAccountModal.header.title')}
        primaryButton={{
          ...submitButtonProps,
          label: formatMessage('widgets.invalidVendorBankAccountModal.footer.buttons.save.label'),
          variant: 'primary',
          isLoading: isUpdatingVendor,
        }}
        secondaryButton={{
          label: formatMessage('widgets.invalidVendorBankAccountModal.footer.buttons.cancel.label'),
          onClick: handleClose,
          variant: 'tertiary',
        }}
      >
        <Group variant="vertical">
          <Text>
            {formatMessage('widgets.invalidVendorBankAccountModal.header.subtitle.firstLine', {
              vendorName,
              accountNumberLast4Digits: bankAccountNumber.slice(-4),
            })}
          </Text>
          <Group variant="vertical" spacing="m">
            <Text>{formatMessage('widgets.invalidVendorBankAccountModal.header.subtitle.secondLine')}</Text>
            <Form {...bankAccountForm.formProps} data-testid="bank-account-form" columns={2} error={formError}>
              <Form.TextField
                {...bankAccountForm.registerField('routingNumber')}
                type="number"
                isDisabled={isUpdatingVendor}
                colSpan={1}
                labelProps={{
                  label: formatMessage('widgets.invalidVendorBankAccountModal.form.routingNumber.label'),
                }}
                data-private
              />
              <Form.TextField
                data-private
                {...bankAccountForm.registerField('accountNumber')}
                type="number"
                isDisabled={isUpdatingVendor}
                colSpan={1}
                labelProps={{
                  label: formatMessage('widgets.invalidVendorBankAccountModal.form.accountNumber.label'),
                }}
              />
            </Form>
          </Group>
        </Group>
      </StatusModal>
    );
  }
);
