import { EBillAttachment } from '@melio/ap-widgets';
import { Form, Group } from '@melio/penny';
import { Bill, useBillAttachments } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import {
  defaultEBillPayOption,
  EbillOptionRenderer,
  EbillPayOption,
  EbillValueOptionRender,
  useEditAmountFormOptions,
} from '../../../../../../edit-ebill-payment-option';
import { AmountInputProps } from '../types';
import { BasicAmountInput } from './BasicAmountInput';

type EbillAmountDropdownProps = AmountInputProps & {
  bill: Bill;
};

type EbillAmountDropdownOption = { value: EbillPayOption; label: string; description: string };
export const EbillAmountDropdown = ({ form, onChange, bill }: EbillAmountDropdownProps) => {
  const { formatMessage } = useMelioIntl();
  const [selectedOption, setSelectedOption] = useState<EbillPayOption>(defaultEBillPayOption);
  const { data: attachments, isLoading: isLoadingBillAttachments } = useBillAttachments({ id: bill.id });

  const amounts: Record<EbillPayOption, number> = {
    amountDue: bill.amount,
    minimumAmount: bill.minimumAmount ?? 0,
    accountBalance: bill.billerAccountBalance ?? 0,
    customAmount: bill.amount,
  };

  const options: Array<EbillAmountDropdownOption> = useEditAmountFormOptions({ bill });
  return (
    <Group width="full" variant="vertical" spacing="s" data-testid="payment-flow-ebill-amount-dropdown-container">
      <Form.SelectNew
        {...form.registerField('amountToPay')}
        data-testid="payment-flow-ebill-amount-dropdown"
        value={selectedOption}
        labelProps={{ label: formatMessage('activities.paymentFlow.form.content.amountToPay.ebill.label') }}
        options={options}
        valueRenderer={EbillValueOptionRender}
        optionRenderer={EbillOptionRenderer}
        onChange={(event) => {
          const value = event.target.value as EbillPayOption;
          onChange(amounts[value].toString());
          setSelectedOption(value);
        }}
        size="large"
        shouldHideClearButton
      />
      {selectedOption === 'customAmount' ? (
        <BasicAmountInput bill={bill} form={form} onChange={onChange} hideHelperText />
      ) : null}

      <Group alignItems="flex-start">
        <EBillAttachment
          isLoading={isLoadingBillAttachments}
          url={attachments?.[0]?.url ?? ''}
          showFileAttachment={false}
          showViewIcon
        />
      </Group>
    </Group>
  );
};
