import { isEbill, useVendorNotPermitted } from '@melio/ap-domain';
import { EbillCellBadge, LockedVendorIcon } from '@melio/ap-widgets';
import { Avatar, Container, Group, Icon, Text, Typography, useBreakpoint } from '@melio/penny';
import { Bill, Vendor, VendorEBillStatusEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { identity } from 'lodash';

export type VendorsTabListItemProps = { vendor: Vendor; unpaidBills: Bill[] };

export const VendorsTabListItem = ({ vendor, unpaidBills }: VendorsTabListItemProps) => {
  const { isExtraSmallScreen } = useBreakpoint();
  const { formatCurrency, formatMessage } = useMelioIntl();
  const [isEBillLegalDisclaimer] = usePartnerFeature('EBillLegalDisclaimer', false);
  const isVendorNotPermitted = useVendorNotPermitted(vendor);
  const openBalance = unpaidBills.reduce((acc, bill) => acc + bill.balance, 0);
  const noUnpaidBills = unpaidBills.length === 0;
  const unpaidBillsText = noUnpaidBills
    ? formatMessage('activities.payDashboard.vendorsTab.listItem.unpaidBills.none')
    : formatMessage('activities.payDashboard.vendorsTab.listItem.unpaidBills.multiple', {
        billsCount: unpaidBills.length,
        plural: unpaidBills.length > 1 && 's',
      });
  const vendorDescription = [vendor?.nickname, vendor?.accountNumber].filter(identity).join(' | ');
  const unpaidEbill = unpaidBills.find((bill) => isEbill(bill));
  const billerAccountBalance =
    unpaidEbill?.billerAccountBalance !== undefined ? unpaidEbill.billerAccountBalance : null;

  return (
    <Container paddingX="m" overflow="initial" data-testid="vendors-tab-list-item">
      <Group>
        <Avatar name={vendor.name} size="medium" aria-hidden />
        <Group variant="vertical" spacing="xxs" width="full">
          <Group variant="horizontal" spacing="xxs" alignItems="center">
            {isVendorNotPermitted ? <LockedVendorIcon /> : null}
            <Typography.MainLabel label={vendor.name} variant="bold" aria-label={vendor.name} />
            {vendor.eBillStatus !== VendorEBillStatusEnum.NotCapable && <EbillCellBadge vendor={vendor} />}
          </Group>
          <Text shouldSupportEllipsis color="global.neutral.900" textStyle="body3">
            {vendorDescription}
          </Text>
          <Group variant="vertical" spacing="xxxs">
            <Text textStyle={isExtraSmallScreen ? 'body3Semi' : 'body4Semi'} color="global.neutral.900">
              {formatMessage('activities.payDashboard.vendorsTab.listItem.openBalance', {
                openBalance: formatCurrency(openBalance, vendor.currency ?? ''),
              })}
            </Text>
            {isEBillLegalDisclaimer && billerAccountBalance != null ? (
              <Text
                textStyle={isExtraSmallScreen ? 'body3' : 'body4'}
                color="global.neutral.900"
                data-testid="statement-balance-disclaimer"
              >
                {formatMessage('activities.payDashboard.eBills.disclaimers.statementBalance', {
                  eBillTotalBalance: formatCurrency(billerAccountBalance),
                })}
              </Text>
            ) : null}
            <Text
              textStyle={isExtraSmallScreen ? 'body3' : 'body4'}
              color={noUnpaidBills ? 'global.neutral.700' : 'global.neutral.900'}
            >
              {unpaidBillsText}
            </Text>
          </Group>
        </Group>
        <Icon type="chevron-right" size="small" />
      </Group>
    </Container>
  );
};
