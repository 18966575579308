import { useCardExpired } from '@melio/ap-domain';
import { NakedButton, SelectionCardProps, Text } from '@melio/penny';
import { CardFundingSource, FundingSource } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { FormattedMessage, useMelioIntl } from '@melio/platform-i18n';
import { useFormatExpirationDate } from '@melio/platform-utils';

import { useFundingSourceFeeValueFormatting } from '../../utils';

type AccountNameAndDigits = { accountName: string; accountNumber: string };
type FundingSourceNameAndDigits = (fundingSource: FundingSource) => AccountNameAndDigits;
type GetDisplayText = (fundingSource: FundingSource, formattedExpirationDate?: string) => string;
type GetHelperTextProps = (props: {
  fundingSource: FundingSource;
  onVerify: VoidFunction;
  isVerifying?: boolean;
  showAddedBy?: boolean;
  isBillingMethod?: boolean;
  isFirmBillingFeeMethod?: boolean;
  formattedDate?: string;
  feeString?: string;
  canUpdateFundingSource?: boolean;
}) => SelectionCardProps['descriptionProps'];

export const extractNameAndAccountDigits: FundingSourceNameAndDigits = (fundingSource) => {
  const { displayName = '', type } = fundingSource;

  if (type === 'bank-account') {
    const accountNumber = fundingSource.details.accountNumber.slice(-4) || '';
    const accountName = displayName;

    return { accountName, accountNumber };
  } else if (type === 'card') {
    const { network, lastFourDigits } = fundingSource.details;

    return { accountName: network === 'other' ? '' : network, accountNumber: lastFourDigits };
  }

  return { accountName: displayName, accountNumber: '' };
};

const useTypeCardText = () => {
  const { formatExpirationDate } = useFormatExpirationDate();
  const { isCardExpired } = useCardExpired();
  const { formatMessage } = useMelioIntl();
  const [isVirtualCardSupported] = useDevFeature(FeatureFlags.IsVirtualCardSupported, false);

  const getText = (
    fundingSource: CardFundingSource,
    feeString: string,
    addedByTitle?: string,
    isBillingMethod?: boolean
  ) => {
    const { type: cardType } = fundingSource.details;
    const expirationDate = formatExpirationDate({
      month: fundingSource.details.expirationMonth,
      year: fundingSource.details.expirationYear,
    });

    if (isCardExpired(fundingSource)) {
      return formatMessage(`widgets.fundingSources.selectFundingSourceCard.${cardType ?? 'card'}.expiredText`, {
        expirationDate,
        addedBy: addedByTitle,
      });
    }

    if (isBillingMethod) {
      return formatMessage(
        `widgets.fundingSources.selectFundingSourceCard.bankAccount.helperText.billingMethod.card.${fundingSource.details.type}`
      );
    }

    return feeString
      ? formatMessage(
          `widgets.fundingSources.selectFundingSourceCard.${cardType ?? 'card'}.${
            isVirtualCardSupported ? 'virtualCard.helperTextWithFee' : 'helperTextWithFee'
          }`,
          {
            feeString,
            addedBy: addedByTitle,
          }
        )
      : formatMessage(`widgets.fundingSources.selectFundingSourceCard.${cardType ?? 'card'}.helperText`, {
          addedBy: addedByTitle,
        });
  };
  return { getText };
};

export const useFundingSourceCardDisplayText = () => {
  const { formatExpirationDate } = useFormatExpirationDate();
  const { isCardExpired } = useCardExpired();
  const { formatMessage } = useMelioIntl();

  const getDisplayText: GetDisplayText = (fundingSource) => {
    const { type: fundingType, displayName } = fundingSource;
    const { accountName } = extractNameAndAccountDigits(fundingSource);
    const formattedExpirationDate =
      fundingSource.type === 'card'
        ? formatExpirationDate({
            month: fundingSource.details.expirationMonth,
            year: fundingSource.details.expirationYear,
          })
        : undefined;

    if (fundingType === 'bank-account') {
      // https://linear.app/meliopayments/issue/PLA-551/re-add-4-digits-in-the-funding-source-display-name
      // When ready, re-add the account number
      return formatMessage('widgets.fundingSources.selectFundingSourceCard.bankAccount.content', { accountName });
    }

    if (fundingType === 'flex-account') {
      return displayName || formatMessage('widgets.fundingSources.selectFundingSourceCard.flexAccount.content');
    }

    const expirationDateText = (
      <Text color="inherit">
        <FormattedMessage
          id="widgets.fundingSources.selectFundingSourceCard.card.expirationDate"
          values={{ formattedExpirationDate }}
        />
      </Text>
    );

    const expirationDate = isCardExpired(fundingSource) ? null : expirationDateText;

    // https://linear.app/meliopayments/issue/PLA-551/re-add-4-digits-in-the-funding-source-display-name
    // When ready, re-add the account number
    return formatMessage('widgets.fundingSources.selectFundingSourceCard.card.content', {
      displayName,
      expirationDate,
    });
  };
  return { getDisplayText };
};

export const useFundingSourceCardHelperText = () => {
  const { formatFeeValue } = useFundingSourceFeeValueFormatting();
  const { getText } = useTypeCardText();
  const { formatMessage } = useMelioIntl();

  const getHelperTextProps: GetHelperTextProps = ({
    fundingSource,
    onVerify,
    isVerifying,
    showAddedBy = false,
    isBillingMethod = false,
    isFirmBillingFeeMethod = false,
    canUpdateFundingSource,
  }) => {
    const fullName = [fundingSource.createdBy?.firstName, fundingSource.createdBy?.lastName].join(' ').trim();
    const addedByTitle = showAddedBy
      ? formatMessage('widgets.fundingSources.selectFundingSourceCard.addedBy', {
          creator: fullName,
        })
      : undefined;

    const feeString = formatFeeValue(
      fundingSource.type,
      fundingSource.type === 'card' ? fundingSource.details.type : undefined
    );
    const hideVerifyButton = !canUpdateFundingSource || (isBillingMethod && isFirmBillingFeeMethod);

    if (fundingSource.type === 'bank-account') {
      if (fundingSource.isBlocked) {
        return {
          label: formatMessage('widgets.fundingSources.selectFundingSourceCard.bankAccount.helperText.blocked', {
            addedBy: addedByTitle,
          }),
        };
      }
      if (isBillingMethod && fundingSource.isVerified) {
        return {
          label: formatMessage(
            'widgets.fundingSources.selectFundingSourceCard.bankAccount.helperText.billingMethod.ach'
          ),
        };
      }
      if (fundingSource.isVerified) {
        return {
          label: formatMessage('widgets.fundingSources.selectFundingSourceCard.bankAccount.helperText.verified', {
            addedBy: addedByTitle,
            accountType: fundingSource.details.accountType,
          }),
        };
      }
      return {
        label: formatMessage('widgets.fundingSources.selectFundingSourceCard.bankAccount.helperText.unverified', {
          verify: !hideVerifyButton ? (
            <NakedButton
              variant="secondary"
              onClick={onVerify}
              label={formatMessage('widgets.fundingSources.selectFundingSourceCard.bankAccount.helperText.verify')}
              data-testid={`verify-${fundingSource.id}-funding-source-link`}
              isDisabled={isVerifying}
            />
          ) : null,
          addedBy: addedByTitle,
        }),
      };
    }

    if (fundingSource.type === 'flex-account') {
      return {
        label: formatMessage('widgets.fundingSources.selectFundingSourceCard.flex.helperText'),
      };
    }

    return fundingSource.details.type
      ? { label: getText(fundingSource, feeString, addedByTitle, isBillingMethod) }
      : {
          label: `Missing Description for fundingType: ${fundingSource.type}, cardType: ${
            fundingSource.details.type as string
          }`,
        };
  };
  return { getHelperTextProps };
};
