// TODO: the text should be per plan and not just per feature. waiting for product answer https://www.figma.com/design/iFQTSLomTaSVgSdDxpiOej?node-id=951-86552#800202620
// for example: the "1teamMember" is not correct for all current plan. it may be the the current plan is the paid plan with 3 seats and it doesn't support the feature.
export const currentPlanConfigPerFeature = {
  collaborators: {
    capabilities: [
      'activities.subscription.contextualPlans.collaborators.current.capabilities.1teamMember',
      'activities.subscription.contextualPlans.collaborators.current.capabilities.ownerRole',
    ],
  },
  batchPayments: {
    capabilities: [
      'activities.subscription.contextualPlans.batchPayments.current.capabilities.paySingleBills',
      'activities.subscription.contextualPlans.batchPayments.current.capabilities.noCombinedPayments',
    ],
    actionButton: 'activities.subscription.contextualPlans.batchPayments.current.actionButtonLabel',
  },
  approvalWorkflows: {
    capabilities: [],
    actionButton: 'activities.subscription.contextualPlans.approvalWorkflows.current.actionButtonLabel',
  },
  advancedApprovalWorkflows: {
    capabilities: [],
    actionButton: 'activities.subscription.contextualPlans.advancedApprovalWorkflows.current.actionButtonLabel',
  },
  accountingPlatformQbdt: { capabilities: [] },
  lineItems: {
    capabilities: [
      'activities.subscription.contextualPlans.lineItems.current.capabilities.limitedFreeTransfers',
      'activities.subscription.contextualPlans.lineItems.current.capabilities.limitedFreeSyncs',
    ],
  },
  w9: {
    capabilities: [
      'activities.subscription.contextualPlans.w9.current.capabilities.fiveFreeACHTransfers',
      'activities.subscription.contextualPlans.w9.current.capabilities.sync',
    ],
    actionButton: 'activities.subscription.contextualPlans.w9.current.actionButtonLabel',
  },
  tax1099: { capabilities: [] },
  classesAndLocations: { capabilities: [] },
  ar: { capabilities: [] },
  requestCallbackSupport: { capabilities: [] },
  achTPlusTwo: { capabilities: [] },
  freeAchPayments: { capabilities: [] },
  syncedPayments: { capabilities: [] },
} as const;

export const upgradedPlanConfigPerFeature = {
  collaborators: {
    capabilities: [
      'activities.subscription.contextualPlans.collaborators.upgrade.capabilities.unlimitedCollaborators',
      'activities.subscription.contextualPlans.collaborators.upgrade.capabilities.multipleRoles',
    ],
  },
  batchPayments: {
    capabilities: [
      'activities.subscription.contextualPlans.batchPayments.upgrade.capabilities.multipleBills',
      'activities.subscription.contextualPlans.batchPayments.upgrade.capabilities.combinedBillPayments',
    ],
  },
  approvalWorkflows: {
    capabilities: [
      'activities.subscription.contextualPlans.approvalWorkflows.upgrade.capabilities.paymentAmount',
      'activities.subscription.contextualPlans.approvalWorkflows.upgrade.capabilities.userOrRole',
      'activities.subscription.contextualPlans.approvalWorkflows.upgrade.capabilities.multiApprovers',
    ],
  },
  advancedApprovalWorkflows: {
    capabilities: [
      'activities.subscription.contextualPlans.advancedApprovalWorkflows.upgrade.capabilities.coreFeatures',
      'activities.subscription.contextualPlans.advancedApprovalWorkflows.upgrade.capabilities.specificApprovers',
      'activities.subscription.contextualPlans.advancedApprovalWorkflows.upgrade.capabilities.multiLayeredApprovals',
      'activities.subscription.contextualPlans.advancedApprovalWorkflows.upgrade.capabilities.specificVendor',
    ],
  },
  accountingPlatformQbdt: {
    capabilities: [
      'activities.subscription.contextualPlans.accountingPlatformQbdt.upgrade.capabilities.freeAchTransfers',
      'activities.subscription.contextualPlans.accountingPlatformQbdt.upgrade.capabilities.syncWithAccountingSoftware',
      'activities.subscription.contextualPlans.accountingPlatformQbdt.upgrade.capabilities.advancedApprovalWorkflows',
      'activities.subscription.contextualPlans.accountingPlatformQbdt.upgrade.capabilities.prioritySupport',
    ],
  },
  lineItems: {
    capabilities: [
      'activities.subscription.contextualPlans.lineItems.upgrade.capabilities.unlimitedSyncs',
      'activities.subscription.contextualPlans.lineItems.upgrade.capabilities.approvalWorkflows',
      'activities.subscription.contextualPlans.lineItems.upgrade.capabilities.lineItems',
      'activities.subscription.contextualPlans.lineItems.upgrade.capabilities.batchPayments',
    ],
  },
  w9: {
    capabilities: [
      'activities.subscription.contextualPlans.w9.upgrade.capabilities.w9Collection1099Efiling',
      'activities.subscription.contextualPlans.w9.upgrade.capabilities.1099reports',
      'activities.subscription.contextualPlans.w9.upgrade.capabilities.twoWaySync',
      'activities.subscription.contextualPlans.w9.upgrade.capabilities.batchPay',
    ],
  },
  tax1099: { capabilities: [] },
  classesAndLocations: {
    capabilities: [
      'activities.subscription.contextualPlans.classesAndLocations.upgrade.capabilities.lineItems',
      'activities.subscription.contextualPlans.classesAndLocations.upgrade.capabilities.feesCreditDiscounts',
      'activities.subscription.contextualPlans.classesAndLocations.upgrade.capabilities.accountingSoftware',
      'activities.subscription.contextualPlans.classesAndLocations.upgrade.capabilities.classesLocations',
    ],
  },
  ar: { capabilities: [] },
  requestCallbackSupport: { capabilities: [] },
  achTPlusTwo: { capabilities: [] },
  freeAchPayments: { capabilities: [] },
  syncedPayments: { capabilities: [] },
} as const;
