import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { ApprovalsTabEmptyStateV1 } from './ApprovalsTabEmptyStateV1';
import { ApprovalsTabEmptyStateV2 } from './ApprovalsTabEmptyStateV2';

export const ApprovalsTabEmptyStateWrapper = ({ onLinkClick }: { onLinkClick: VoidFunction }) => {
  const [isAddCollaboratorEnabled] = useDevFeature(FeatureFlags.GrowthAddCollaboratorsEnabled, false, {
    shouldTrack: true,
  });

  return isAddCollaboratorEnabled ? (
    <ApprovalsTabEmptyStateV2 onLinkClick={onLinkClick} />
  ) : (
    <ApprovalsTabEmptyStateV1 onLinkClick={onLinkClick} />
  );
};
