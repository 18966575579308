import { DeliveryPreferenceType } from '@melio/platform-api';

export type PaymentDraft = {
  note?: string;
  fundingSourceId?: string;
  scheduledDate?: Date;
  billId?: string;
  deliveryMethodId?: string;
  deliveryPreferenceType?: DeliveryPreferenceType;
};

export type GuestPayorFundingSourceTypes = 'bank' | 'card';
export type GuestPayorPaymentOptions = GuestPayorFundingSourceTypes | 'custom';

export const ErrorType = {
  EXPIRED: 'expired',
  INVOICE_CANCELLED: 'invoice-cancelled',
  GENERAL_ERROR: 'general-error',
} as const;
export type ErrorType = (typeof ErrorType)[keyof typeof ErrorType];

export const PaymentRequestStatus = {
  UNPAID: 'unpaid',
  UPDATED: 'updated',
  PAID: 'paid',
  CANCELLED: 'cancelled',
  NO_PAYMENT_OPTION: 'no-payment-option',
  ERROR: 'error',
} as const;
export type PaymentRequestStatus = (typeof PaymentRequestStatus)[keyof typeof PaymentRequestStatus];
