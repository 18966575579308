import { InvoicePreferencesApiClient, PatchInvoicePreferencesRequest } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getInvoicePreference = (_id: string) => InvoicePreferencesApiClient.getInvoicePreferences();
const updateInvoicePreference = (_id: string, data: PatchInvoicePreferencesRequest) =>
  InvoicePreferencesApiClient.updateInvoicePreferences(data);

export type UseInvoicePreferenceProps = UseModelProps<typeof getInvoicePreference>;

export const useInvoicePreference = (props: UseInvoicePreferenceProps = {}) => {
  const { data, ...query } = useModel({
    ...props,
    id: 'invoice-preferences',
    queryKey: 'InvoicePreferencesApi',
    queryFn: getInvoicePreference,
    updateFn: updateInvoicePreference,
    cacheTime: 0,
  });

  return {
    ...query,
    data,
  };
};

export type InvoicePreferenceModel = ReturnType<typeof useInvoicePreference>;
