import { Group, Modal, SectionBanner, Text } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { useSubscriptionsPreview } from '../../../../api';
import { usePlanInfo, usePlansTiers, useSubscription } from '../../../../hooks';
import { PlanUpgradeProps } from '../types';
import { useUpgradeSubscription } from '../useUpgradeSubscription';

type SubscriptionUpgradeWithoutTrialModalScreenProps = {
  isOpen: boolean;
  onDismiss?: () => void;
  onClose: () => void;
  planUpgradeProps: PlanUpgradeProps;
};

export const SubscriptionUpgradeWithoutTrialModalScreen = ({
  isOpen,
  onClose,
  onDismiss,
  planUpgradeProps,
}: SubscriptionUpgradeWithoutTrialModalScreenProps) => {
  const { formatMessage, formatCurrency, formatDate } = useMelioIntl();
  const subscription = useSubscription();
  const formatDateRange = (date: string) => formatDate(date, { month: 'long', day: 'numeric', year: 'numeric' });

  useAnalyticsView('Organization', isOpen, true);

  const { billingCycle, planId, currentPlanId } = planUpgradeProps;

  const { data: preview, isFetching: isPreviewFetching } = useSubscriptionsPreview({
    enabled: isOpen,
    params: {
      planCycle: billingCycle,
      planId: planUpgradeProps.planId,
      unitQuantity: subscription?.measurementInfo?.quantity || 0,
      fundingSourceId: subscription?.fundingSourceId,
    },
  });
  const { upgradeError, isUpgradingSubscription, upgradeSubscription, dismiss } = useUpgradeSubscription({
    ...planUpgradeProps,
    onClose,
    onDismiss,
  });

  const { planName } = usePlanInfo(planId);
  const { planName: currentPlanName } = usePlanInfo(currentPlanId);
  const { getPlan } = usePlansTiers();

  const currentPlan = subscription?.planId ? getPlan(subscription.planId) : undefined;

  const currencyFormatter = (value: number) =>
    formatCurrency(value, undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    });

  if ((!isPreviewFetching && !preview) || !currentPlan) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => dismiss('exit')}
      isLoading={isPreviewFetching}
      header={formatMessage('activities.subscription.upgradeModal.title', { planName })}
      primaryButton={{
        isLoading: isUpgradingSubscription,
        label: formatMessage('activities.subscription.upgradeModal.primaryButton'),
        onClick: () => upgradeSubscription(),
        variant: 'primary',
      }}
      secondaryButton={{
        label: formatMessage('activities.subscription.upgradeModal.secondaryButton'),
        onClick: () => dismiss('cancel'),
        variant: 'tertiary',
        isDisabled: isUpgradingSubscription,
      }}
      data-testid="subscription-upgrade-modal-activity"
    >
      <Group variant="vertical" spacing="m">
        <Group role="status">
          {upgradeError && (
            <SectionBanner
              description={formatMessage('activities.subscription.upgradeModal.error')}
              variant="critical"
              data-testid="subscription-upgrade-modal-error"
              data-role="alert"
            />
          )}
        </Group>
        <Group variant="vertical" spacing="m" hasDivider>
          <Group variant="vertical" spacing="s">
            <Text data-testid="upgrade-billing-cycle-description">
              {formatMessage(`activities.subscription.upgradeModal.description`, { planName, currentPlanName })}
            </Text>
            <Group variant="vertical" spacing="s" hasDivider>
              <Group spacing="none" variant="vertical" width="full">
                <Text data-testid="upgrade-billing-from-plan">
                  {formatMessage('activities.subscription.upgradeModal.fromPlan', {
                    currentPlanName,
                  })}
                </Text>
                <Group width="full" justifyContent="space-between">
                  <Text color="global.neutral.800" textStyle="body3">
                    {formatMessage('activities.settings.subscriptionPlans.upgradeModal.basePrice')}
                  </Text>
                  <Text color="global.neutral.800" textStyle="body3">
                    {formatMessage(`activities.subscription.upgradeModal.${billingCycle}.basePricePeriod`, {
                      price: currencyFormatter(currentPlan?.cycles[billingCycle]?.basePrice || 0),
                    })}
                  </Text>
                </Group>
              </Group>

              <Group spacing="none" variant="vertical" width="full">
                <Text data-testid="upgrade-billing-to-plan">
                  {formatMessage('activities.subscription.upgradeModal.toPlan', {
                    planName,
                  })}
                </Text>
                <Group width="full" justifyContent="space-between">
                  <Text color="global.neutral.800" textStyle="body3">
                    {formatMessage('activities.settings.subscriptionPlans.upgradeModal.basePrice')}
                  </Text>
                  <Text color="global.neutral.800" textStyle="body3">
                    {formatMessage(`activities.subscription.upgradeModal.${billingCycle}.basePricePeriod`, {
                      price: currencyFormatter(preview?.amountWithoutTax || 0),
                    })}
                  </Text>
                </Group>
              </Group>
            </Group>
          </Group>
          <Group justifyContent="space-between" alignItems="flex-start">
            <Text textStyle="body3" color="global.neutral.800">
              {formatMessage(`activities.subscription.upgradeModal.${billingCycle}.information`, {
                nextBillingDate: formatDateRange(preview?.billingItems[0]?.serviceEndDate || ''),
              })}
            </Text>
          </Group>
        </Group>
      </Group>
    </Modal>
  );
};
