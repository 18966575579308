/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { usePayments } from '@melio/platform-api';

import { failedPaymentsSearchParams } from './usePaymentsTabQueryParams';

export const useFailedPayments = ({ limit }: { limit: number }) =>
  usePayments({
    refetchOnMount: 'always',
    params: {
      limit,
      search: failedPaymentsSearchParams,
      expand: 'none',
    },
  });
