import { useInvoice, useInvoicePaymentDetails, useMelioIntl, useSystemMessage } from '@melio/ar-domain';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { MarkAsPaidFormField } from '../../components';
import { UpdateMarkInvoiceAsPaidScreen } from './screens';

type UpdateMarkInvoiceAsPaidActivityProps = {
  onClose: VoidFunction;
  onDone: VoidFunction;
  isOpen: boolean;
  onError?: ARErrorFunction;
  invoiceId: string;
};

export const UpdateMarkInvoiceAsPaidActivity = withAnalyticsContext<UpdateMarkInvoiceAsPaidActivityProps>(
  forwardRef(({ setAnalyticsProperties, onClose, onDone, onError, isOpen, invoiceId, ...props }, ref) => {
    const {
      data: receivablePaymentDetails,
      isFetching,
      isMutating,
      update,
    } = useInvoicePaymentDetails({ id: invoiceId });

    const { data: invoice } = useInvoice({ id: invoiceId, onError });

    const { formatMessage } = useMelioIntl();
    const { triggerMessage } = useSystemMessage();

    setAnalyticsProperties({ InvoiceId: invoiceId });

    const handleSubmit = ({ paymentDate, paymentMethod, note, referenceNumber }: MarkAsPaidFormField) => {
      update({
        paidAt: paymentDate,
        method: paymentMethod ? paymentMethod : 'other',
        note,
        referenceNumber,
      })
        .then(() => {
          triggerMessage({
            type: 'success',
            title: formatMessage('ar.dashboard.activities.invoiceTable.actions.updateMarkAsPaid.toast.success.label', {
              InvoiceNumber: invoice?.invoiceNumber,
            }),
          });
          onDone();
        })
        .catch(onError);
    };

    return (
      <UpdateMarkInvoiceAsPaidScreen
        onClose={onClose}
        onSubmit={handleSubmit}
        isOpen={isOpen}
        isLoading={isFetching}
        isSaving={isMutating}
        receivablePaymentDetails={receivablePaymentDetails}
        ref={ref}
        {...props}
      />
    );
  })
);

UpdateMarkInvoiceAsPaidActivity.displayName = 'UpdateMarkInvoiceAsPaidActivity';
