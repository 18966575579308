import { OnboardingStatusApiClient } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

export type UseOrganizationEnrollmentStatusProps = UseModelProps<typeof OnboardingStatusApiClient.getOnboardingStatus>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getOnboardingStatus = (_id: string) => OnboardingStatusApiClient.getOnboardingStatus();

export const useOrganizationEnrollmentStatus = (props: UseOrganizationEnrollmentStatusProps = {}) =>
  useModel({
    ...props,
    queryKey: 'OnboardingStatusApi',
    queryFn: getOnboardingStatus,
    id: 'onboarding-status',
  });

export type OrganizationEnrollmentStatusModel = ReturnType<typeof useOrganizationEnrollmentStatus>;
