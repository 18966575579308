import { Box } from '@chakra-ui/react';
import { FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Button, Group, Layout, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

export type CustomPaymentScreenProps = {
  onViewInvoice: VoidFunction;
  customPayInstructions?: string;
};

export const CustomPaymentInstructions = forwardRef<CustomPaymentScreenProps>(
  ({ onViewInvoice, customPayInstructions }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Group variant="vertical" spacing="l" ref={ref}>
        {customPayInstructions && (
          <Layout
            backgroundColor="lightest"
            paddingContent="s"
            data-layout={CustomPaymentInstructions.displayName}
            ref={ref}
          >
            <Group variant="vertical" spacing="xxs" data-testid="custom-payment-instructions">
              <Text textStyle="body4Semi" color="global.neutral.800">
                <FormattedMessage id="ar.guestPayment.customInstructionsPayment.title.text" />
              </Text>
              <Box textStyle="body3" whiteSpace="pre-line">
                {customPayInstructions}
              </Box>
            </Group>
          </Layout>
        )}
        <Button
          data-testid="view-invoice-button"
          variant="tertiary"
          onClick={onViewInvoice}
          label={formatMessage('ar.guestPayment.previewInvoice.button.label')}
        />
      </Group>
    );
  }
);

CustomPaymentInstructions.displayName = 'CustomPaymentScreen';
