import { useMelioIntl } from '@melio/ar-domain';
import { Button, Icon, SortDropdownMenu } from '@melio/penny';
import { forwardRef, useBoolean } from '@melio/platform-utils';

import { InvoiceSortFieldsEnum, OnSort, Order, Sort, SortParams } from '../types';

type SortMenuMobileProps = {
  onSort: OnSort;
  sortParams?: SortParams;
};

const invertSortOrder = (order?: Order): Order => (order === 'Asc' ? 'Desc' : 'Asc');
const convertSortOrderToSortDirection = (order?: Order) => (order === 'Asc' ? 'asc' : 'desc');

export const SortMenuMobile = forwardRef<SortMenuMobileProps>(({ onSort, sortParams }, ref) => {
  const [isOpen, toggle] = useBoolean(false);
  const { formatMessage } = useMelioIntl();

  return (
    <SortDropdownMenu
      ref={ref}
      isOpen={isOpen}
      trigger={
        <Button
          leftElement={<Icon size="small" type="sort" color="inherit" aria-hidden />}
          size="medium"
          isFullWidth
          variant="tertiary"
          label={formatMessage('ar.dashboard.activities.invoiceTable.mobile.sort.triggerButton.label')}
          data-testid="mobile-sort-button"
          onClick={toggle.toggle}
        />
      }
      items={Object.values(InvoiceSortFieldsEnum).map((sortField) => ({
        key: sortField,
        label: Sort[sortField],
        onClick: () => {
          onSort(sortField, sortParams?.field === sortField ? invertSortOrder(sortParams.order) : 'Asc');
          setTimeout(() => {
            toggle.off();
          }, 1000);
        },
        dataTestId: `table-header-cell-${sortField}`,
      }))}
      selectedItemIndex={Object.values(InvoiceSortFieldsEnum).indexOf(
        sortParams?.field || InvoiceSortFieldsEnum['updatedAt']
      )}
      onOpenChange={toggle.toggle}
      sortDirection={convertSortOrderToSortDirection(sortParams?.order)}
      title={formatMessage('ar.dashboard.activities.invoiceTable.mobile.sort.title')}
    />
  );
});
